import { useState } from 'react';
import { useFormik } from 'formik';

// Material UI
import { Tabs, Tab, Box } from '@mui/material';
import Swal from 'sweetalert2';

// Componentes
import { TabPanel, a11yProps } from '@components/Maps/Varius/TabComponent';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Create from '@components/MapsModules/Routes/Create';
import Search from '@components/MapsModules/Routes/Search';
import Edit from '@components/MapsModules/Routes/Edit';

// Datos
import { MapAddPolygonInterface, MapEditPolygonInterface } from '@data/interfaces/MapInterfaces';
import { MapAddPolygonSchema, MapEditPolygonSchema } from '@data/schemas/MapSchemas';

// Servicios
import RouteService from '@services/MapServices/RouteServices';

// Utilidades
import { handleAttrs } from '@utils/Utilities';
import { isEmptyOrNullObject } from '@utils/validations';
import { setVars, getVars } from '@utils/global';

const PolygonsCard = (props) => {
  const {
    permissions,
    selectedSectionsProperties,
    polygons,
    setPolygons,
    handleRefreshShape,
    handleEmptyProperties,
  } = props;

  const [value, setValue] = useState(getVars('valueTab'));
  const [initialPolygons] = useState(polygons);

  // LoadingForm
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  const formik = useFormik({
    initialValues: MapAddPolygonInterface,
    validationSchema: MapAddPolygonSchema,
    onSubmit: (values) => {
      const newValues = handleAttrs(values);
      if (!isEmptyOrNullObject(newValues)) handleSave(newValues);
    },
  });

  const formikEdit = useFormik({
    initialValues: MapEditPolygonInterface,
    validationSchema: MapEditPolygonSchema,
    onSubmit: (values) => {
      const newValues = handleAttrs(values);
      if (!isEmptyOrNullObject(newValues)) handleSaveEdit(newValues);
    },
  });

  const handleChange = (event, newValue) => {
    handleEmptyProperties();

    if (newValue !== 0) clearCreate();

    if (newValue !== 2) {
      setVars('itemEdit', null);
      setPolygons({ ...initialPolygons, tab: newValue });
      clearEdit();
    }

    setVars('valueTab', newValue);
    setValue(newValue);
  };

  const clearCreate = () => formik.setValues(MapAddPolygonInterface);
  const clearEdit = () => formikEdit.setValues(MapEditPolygonInterface);

  const handleSave = async (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);

    const params = {
      ...values,
      idUsuarioRG: values.idUsuarioRG > 0 ? values.idUsuarioRG : null,
      idUsuarioAbogado: values.idUsuarioAbogado > 0 ? values.idUsuarioAbogado : null,
    };

    try {
      const result = await RouteService.setRoute(params);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success' });
        clearCreate();
        handleEmptyProperties();
        handleRefreshShape();
      } else Swal.fire({ title: message, icon: 'warning' });
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleSaveEdit = async (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);

    const prevParams = getVars('itemEdit').Casillas || [];
    const prevIds = prevParams.map((item) => item.id);

    const params = {
      ...values,
      idUsuarioRG: values.idUsuarioRG > 0 ? values.idUsuarioRG : null,
      idUsuarioAbogado: values.idUsuarioAbogado > 0 ? values.idUsuarioAbogado : null,
    };

    try {
      const result = await RouteService.updateRoute(params);
      const { success, results, message } = result;

      if (success && results) {
        const equalCasillas = equalArrays(params.Casillas, prevIds); // Verifica si las casillas son iguales

        Swal.fire({
          title: message,
          text: !equalCasillas ? 'Recargando mapa' : '',
          icon: 'success',
        });
        handleChange({}, 1);

        if (!equalCasillas) handleRefreshShape(); // Refresca el mapa
      } else Swal.fire({ title: message, icon: 'warning' });
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleDelete = (_itemEdit) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar la ruta ${_itemEdit.Ruta}?`,
      text: 'Se eliminará por completo, esta acción no se puede revertir.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, Eliminar!',
      confirmButtonColor: '#14A52E',
    }).then((result) => {
      if (result.isConfirmed) {
        RouteService.deleteRoute({ id: _itemEdit.id })
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire({ title: 'Eliminado!', icon: 'success' });
              handleChange({}, 1);
              handleRefreshShape(); // Refresca el mapa
            } else Swal.fire({ title: res.message, icon: 'warning' });
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: 'warning' });
          });
      }
    });
  };

  const handleSearchEdit = (item) => {
    const sectionsIds = item.Secciones.split(',').map((item) => parseInt(item));

    const itemFormatted = {
      ...item,
      Secciones: sectionsIds,
      Descripcion: item.Descripcion || '',
      Casillas: item.casillas || [],
      idUsuarioRG: item.idUsuarioRG || 0,
      idUsuarioAbogado: item.idUsuarioAbogado || 0,
      NombreRG: item.NombreRG || '',
      NombreAbogado: item.NombreAbogado || '',
    };

    setVars('itemEdit', itemFormatted);
    handleChange({}, 2);
  };

  const handleSelectedCasillas = (values, action, type, seccion, formikV) => {
    let newValues = values;
    const typeCasillas = type === 'create' ? formik.values.Casillas : formikEdit.values.Casillas;

    switch (action) {
      case 'addAll':
        const newCasillasSetAll = new Set([...typeCasillas, ...values]);
        const newCasillasAll = Array.from(newCasillasSetAll);
        newValues = newCasillasAll;
        break;
      case 'deleteAll':
        newValues = values;
        break;
      case 'agregar':
        const newCasillasSetAdd = new Set([...typeCasillas, values]);
        const newCasillasAdd = Array.from(newCasillasSetAdd);
        newValues = newCasillasAdd;
        break;
      case 'eliminar':
        const newCasillasDel = typeCasillas.filter((value) => !values.includes(value));
        newValues = newCasillasDel;
        break;

      default:
        break;
    }

    switch (type) {
      case 'create':
        formik.setValues({ ...formik.values, Casillas: newValues });
        break;
      case 'edit':
        if (formikV) formikEdit.setValues(formikV);
        else formikEdit.setValues({ ...formikEdit.values, Casillas: newValues });
        break;
      default:
        break;
    }
  };

  // Verifica si 2 arrays contienen los mismos valores
  const equalArrays = (array1, array2) => {
    if (array1.length !== array2.length) return false; // Verificar las longitudes
    const sorted1 = array1.slice().sort();
    const sorted2 = array2.slice().sort();
    return sorted1.every((item, index) => item === sorted2[index]); // Verificar los elementos
  };

  return (
    <>
      <LoadingForm
        loadinMessage={'Cargando datos...'}
        successMessage="¡Cargado con exito!"
        isLoading={isLoadingForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />

      <Box
        sx={{
          width: '100%',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs"
          variant="scrollable"
          scrollButtons={false}
          /* allowScrollButtonsMobile */
          sx={{ width: 'fit-content' }}
        >
          {permissions.Agregar ? <Tab disabled={value === 0} label="Crear" {...a11yProps(0)} /> : <></>}

          <Tab disabled={value === 1} label="Buscar" {...a11yProps(1)} />

          {permissions.Editar ? <Tab disabled label="Editar" {...a11yProps(2)} /> : <></>}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {permissions.Agregar ? (
          <Create
            formik={formik}
            selectedProperties={selectedSectionsProperties}
            handleSelectedCasillas={handleSelectedCasillas}
          />
        ) : (
          <></>
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Search handleEdit={handleSearchEdit} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        {permissions.Editar ? (
          <Edit
            formik={formikEdit}
            polygons={polygons}
            setPolygons={setPolygons}
            selectedProperties={selectedSectionsProperties}
            handleDelete={handleDelete}
            handleSelectedCasillas={handleSelectedCasillas}
          />
        ) : (
          <></>
        )}
      </TabPanel>
    </>
  );
};

export default PolygonsCard;
