import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/SnippetFolder';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { blue } from '@mui/material/colors';
import { Icon, IconButton, Tooltip } from '@mui/material';

import { isEmptyOrInvalidArray } from '@utils/validations';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    buttonDownload,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    handleClick,
    handleDownload,
    ...other
  } = props;

  const styleProps = {
    '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
    '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          {buttonDownload ? (
            <Tooltip title="Descargar todos los documentos" placement="right-end">
              <IconButton aria-label="download" size="small" onClick={handleDownload} color="inherit">
                <Icon>download</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      }
      onClick={handleClick}
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

export default function TreeDrive({ data, handleClick, handleDownload }) {
  const renderTree = (node) => {
    return (
      <StyledTreeItem
        key={node.id}
        itemId={node.id}
        labelText={node.label}
        labelIcon={FolderIcon}
        buttonDownload={node.hasPermissionDownload}
        bgColor={blue[50]}
        handleClick={() => handleClick(node)}
        handleDownload={(e) => handleDownload(e, node)}
      >
        {!isEmptyOrInvalidArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
      </StyledTreeItem>
    );
  };

  return (
    <SimpleTreeView
    /*     aria-label="drive"
      defaultExpanded={["1"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: "auto" }} */
    >
      {data && data.map((item) => renderTree(item))}
    </SimpleTreeView>
  );
}
