import { Public, ProtectedRoute } from '@middlewares';
import Pregistration from '../../pages/Events/Pregistration';
import Encuesta from '../../pages/Encuestas/Encuesta.jsx';

const EncuestasRoutes = [
  {
    path: '/encuesta',
    element: (
      <Public>
        <Encuesta />
      </Public>
    ),
  },
];

export default EncuestasRoutes;
