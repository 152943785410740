import ExcelJS from 'exceljs';
//import Swal from "sweetalert2";

export const processExcelFile = async (file, startRow, sheet = 0) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    const loadExcel = () => {
      return new Promise((resolve, reject) => {
        reader.onload = async (event) => {
          try {
            const buffer = event.target.result;
            await workbook.xlsx.load(buffer);
            const worksheet = workbook.worksheets[sheet];

            const jsonData = [];
            let headers = [];
            const error = [];

            const requiredFields = ['Linea 1', 'Nombres', 'Duración', 'Lugar', 'FechaTexto', 'Fecha'];
            const columnLimit = 'J';

            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
              if (rowNumber === startRow - 1) {
                headers = row.values.slice(1, 11)?.map((header) => header.trim()); // Limitar a las primeras 10 columnas (A-J)
              } else if (rowNumber >= startRow) {
                const rowData = {};
                let paterno = '';
                let materno = '';
                let filledFieldsCount = 0;

                headers.forEach((header) => {
                  rowData[header] = '';
                });

                row.eachCell({ includeEmpty: false, to: columnLimit }, (cell, colNumber) => {
                  const header = headers[colNumber - 1];
                  let cellValue = cell.value ? cell.value.toString().trim() : '';

                  // Verificar si el número de columna está dentro del rango A-J y hay datos en esa celda
                  if (colNumber <= 10 && cellValue.length > 0) {
                    filledFieldsCount++;

                    if (header === 'Linea 1' || header === 'Lugar') {
                      if (cellValue.length === 0) {
                        error.push({
                          row: rowNumber,
                          column: header,
                          message: `El campo '${header}' es obligatorio.`,
                        });
                      } else if (cellValue.length > 50) {
                        error.push({
                          row: rowNumber,
                          column: header,
                          message: `El campo '${header}' debe tener una longitud máxima de 50 caracteres.`,
                        });
                      }
                      rowData[header] = cellValue;
                    } else if (header === 'Linea 2' || header === 'Linea 3') {
                      if (cellValue.length > 0 && cellValue.length < 5) {
                        error.push({
                          row: rowNumber,
                          column: header,
                          message: `El campo '${header}' debe tener una longitud mínima de 5 caracteres si se proporciona.`,
                        });
                      } else if (cellValue.length > 50) {
                        error.push({
                          row: rowNumber,
                          column: header,
                          message: `El campo '${header}' debe tener una longitud máxima de 50 caracteres.`,
                        });
                      }
                      rowData[header] = cellValue;
                    } else if (header === 'Nombres') {
                      if (cellValue.length === 0) {
                        error.push({
                          row: rowNumber,
                          column: 'Nombres',
                          message: `El campo 'Nombre' es obligatorio.`,
                        });
                      }
                      rowData[header] = cellValue;
                    } else if (header === 'Paterno') {
                      paterno = cellValue;
                      rowData[header] = cellValue;
                    } else if (header === 'Materno') {
                      materno = cellValue;
                      rowData[header] = cellValue;
                    } else if (header === 'Duración') {
                      if (cellValue.length === 0) {
                        error.push({
                          row: rowNumber,
                          column: 'Duración',
                          message: `El campo 'Duración' es obligatorio.`,
                        });
                      }
                      rowData[header] = cellValue;
                    } else if (header === 'FechaTexto') {
                      if (cellValue.length === 0) {
                        error.push({
                          row: rowNumber,
                          column: 'FechaTexto',
                          message: `El campo 'FechaTexto' es obligatorio.`,
                        });
                      }
                      rowData[header] = cellValue;
                    } else if (header === 'Fecha') {
                      if (cellValue.length === 0) {
                        error.push({
                          row: rowNumber,
                          column: 'Fecha',
                          message: `El campo 'Fecha' es obligatorio.`,
                        });
                      } else {
                        const date = new Date(cellValue);

                        // Verificar si la fecha es válida
                        if (isNaN(date)) {
                          error.push({
                            row: rowNumber,
                            column: header,
                            message: `El campo '${header}' contiene una fecha no válida.`,
                          });
                        } else {
                          // Convertir la fecha a un formato consistente, si es necesario
                          const formattedDate = date.toISOString().split('T')[0];
                          rowData[header] = formattedDate; // Guarda la fecha formateada o el valor original
                        }
                      } /* {
                        const date = new Date(cellValue);
                        const formattedDate = date.toISOString().split("T")[0];

                        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                        if (!dateRegex.test(formattedDate)) {
                          error.push({
                            row: rowNumber,
                            column: header,
                            message: `El campo '${header}' debe tener el formato 'YYYY-MM-DD'.`,
                          });
                        } else {
                          rowData[header] = formattedDate;
                        }
                      }
                      const date = new Date(cellValue);
                      const formattedDate = date.toISOString().split("T")[0];
                      rowData[header] = cellValue ? formattedDate : cellValue; */
                    }
                  }
                });

                // Validar que al menos uno de los campos "Paterno" o "Materno" esté lleno solo si hay más de un campo con datos
                if (filledFieldsCount > 1) {
                  if (!paterno && !materno) {
                    const errorMessage =
                      "Debe especificarse al menos uno de los campos 'Paterno' o 'Materno'.";
                    error.push(
                      { row: rowNumber, column: 'Paterno', message: errorMessage },
                      { row: rowNumber, column: 'Materno', message: errorMessage }
                    );
                  }

                  // Asegurarse de que todos los campos requeridos estén presentes y no vacíos
                  requiredFields.forEach((field) => {
                    if (!(field in rowData) || rowData[field].length < 1) {
                      error.push({
                        row: rowNumber,
                        column: field,
                        message: `El campo '${field}' es obligatorio y se agregó con un valor vacío.`,
                      });
                    }
                  });

                  jsonData.push(rowData);
                }
              }
            });

            resolve({ data: jsonData, error });
          } catch (error) {
            console.error('Error al procesar el archivo Excel:', error);
            reject({ data: [], error: [{ message: 'Error al procesar las filas del archivo Excel' }] });
          }
        };

        reader.onerror = (error) => {
          console.error('Error al leer el archivo:', error);
          reject({ data: [], error: [{ message: 'Error al leer el archivo' }] });
        };

        reader.readAsArrayBuffer(file);
      });
    };

    return await loadExcel();
  } catch (error) {
    console.error('Error al procesar el archivo:', error);
    return { data: [], error: [{ message: 'Error al procesar el archivo' }] };
  }
};
