import { ApiExec } from '@utils/ApiExec';

class AportacionesServices {
  getAportantes = (data) => ApiExec(data, 'cdeg/aportaciones/get-aportantes', 'POST');
  createAportante = (data) => ApiExec(data, 'cdeg/aportaciones/create-aportante', 'POST');
  updateAportante = (data) => ApiExec(data, 'cdeg/aportaciones/update-aportante', 'POST');
  deleteAportante = (data) => ApiExec(data, 'cdeg/aportaciones/delete-aportante', 'POST');

  getServicios = (data) => ApiExec(data, 'cdeg/aportaciones/get-servicios', 'POST');
  createServicio = (data) => ApiExec(data, 'cdeg/aportaciones/create-servicio', 'POST');
  updateServicio = (data) => ApiExec(data, 'cdeg/aportaciones/update-servicio', 'POST');
  deleteServicio = (data) => ApiExec(data, 'cdeg/aportaciones/delete-servicio', 'POST');

  getPerfiles = (data) => ApiExec(data, 'cdeg/aportaciones/get-perfiles', 'POST');
  createPerfil = (data) => ApiExec(data, 'cdeg/aportaciones/create-perfil', 'POST');
  updatePerfil = (data) => ApiExec(data, 'cdeg/aportaciones/update-perfil', 'POST');
  deletePerfil = (data) => ApiExec(data, 'cdeg/aportaciones/delete-perfil', 'POST');

  getPercepciones = (data) => ApiExec(data, 'cdeg/aportaciones/get-percepciones', 'POST');
  createPercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/create-percepcion', 'POST');
  updatePercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/update-percepcion', 'POST');
  deletePercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/delete-percepcion', 'POST');

  getCargos = (data) => ApiExec(data, 'cdeg/aportaciones/get-cargos', 'POST');
  createCargo = (data) => ApiExec(data, 'cdeg/aportaciones/create-cargo', 'POST');
  updateCargo = (data) => ApiExec(data, 'cdeg/aportaciones/update-cargo', 'POST');
  deleteCargo = (data) => ApiExec(data, 'cdeg/aportaciones/delete-cargo', 'POST');

  getPagos = (data) => ApiExec(data, 'cdeg/aportaciones/get-pagos', 'POST');
  createPago = (data) => ApiExec(data, 'cdeg/aportaciones/create-pago', 'POST');
  updatePago = (data) => ApiExec(data, 'cdeg/aportaciones/update-pago', 'POST');
  deletePago = (data) => ApiExec(data, 'cdeg/aportaciones/delete-pago', 'POST');
}

const service = new AportacionesServices();
export default service;
