import { useState, useEffect } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';
import { Box, Button, Card, CardContent, Container, Icon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import moment from 'moment';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import PersonalInformationForm from '@components/Compromisos/PersonalInformationForm';
//import ContactInformationForm from "@components/Compromisos/ContactInformationForm";
import AddressForm from '@components/Compromisos/AddressForm';
import AddressFormExtranjero from '@components/Compromisos/AddressFormExtranjero';
//import VolunteerForm from "@components/Compromisos/VolunteerForm";
import ResponsibleList from '@components/Compromisos/ResponsibleList';
import ContactInformationFormExtranjero from '@components/Compromisos/ContactInformationFormExtranjero';

import { getVars } from '@utils/global';
import CompromisoServices from '@services/CompromisoServices';
import { CompromisoAddExtranjeroInterface } from '@data/interfaces/CompromisoInterfaces';
import { CompromisoExtranjeroAddSchema } from '@data/schemas/CompromisoSchemas';
import { isEmptyOrNullObject, isNullOrUndefined } from '@utils/validations';
import { validaINE, handleAttrs } from '@utils/Utilities';
import { VIGENCIA_INE, VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL } from '@data/constants';
import { EXPECTED_ROUTES } from '@data/constants/Compromisos';

const CompromisosExtranjeroAdd = () => {
  const catalogsOptions = [
    { id: 'generos' },
    { id: 'paises' },
    { id: 'estados_paises' },
    { id: 'entidades' },
    { id: 'municipios' },
    { id: 'voluntarios' },
    { id: 'tipo_vialidad' },
    //{ id: "mis_segmentos" },
    { id: 'partidos' },
    { id: 'medios_contacto' },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const { user, menus } = getVars('Token');
  const getFindCommitment = location.state ? location.state : null;

  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [previousPage, setPreviousPage] = useState(-1);

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  useEffect(() => {
    formik.setFieldValue('idUsuarioPropietario', user.id, false);

    const _menus = menus
      .map((item) => item.submenus)
      .flat()
      .filter((item) => EXPECTED_ROUTES.includes(item.Ruta));

    if (_menus.length < 2) {
      setPreviousPage('/compromisos-ext');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (!isEmptyOrNullObject(getFindCommitment) && catalogsFiltered.tipo_vialidad.length > 0) {
      let vialidad = getFindCommitment.CalleVive.split(' ');
      let CalleVive = vialidad.slice(1, vialidad.length);
      let found = catalogsFiltered.tipo_vialidad.find((item) => item.value === vialidad[0]);
      let formatedData = {
        ...getFindCommitment,
        CalleVive: CalleVive.join(' '),
        TipoVialidad: found ? vialidad[0] : '',
        Segmentos: getFindCommitment.Segmentos
          ? getFindCommitment.Segmentos.map((item) => parseInt(item))
          : [],
        idVoluntario: getFindCommitment.idVoluntario ? getFindCommitment.idVoluntario : '',
        idMedioContacto: getFindCommitment.idMedioContacto ? getFindCommitment.idMedioContacto : '',
        TieneAuto: !isNullOrUndefined(getFindCommitment.TieneAuto) ? getFindCommitment.TieneAuto : '',
        TieneExperiencia: !isNullOrUndefined(getFindCommitment.TieneExperiencia)
          ? getFindCommitment.TieneExperiencia
          : '',
        AceptaCuidarOtra: !isNullOrUndefined(getFindCommitment.AceptaCuidarOtra)
          ? getFindCommitment.AceptaCuidarOtra
          : '',
      };

      formik.setValues(formatedData);
    }
    // eslint-disable-next-line
  }, [getFindCommitment, catalogsFiltered.tipo_vialidad]);

  const formik = useFormik({
    initialValues: CompromisoAddExtranjeroInterface,
    validationSchema: CompromisoExtranjeroAddSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(values.idVoluntario) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: 'Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso',
            icon: 'info',
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };

  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
    Object.keys(CompromisoAddExtranjeroInterface).forEach((key) => {
      data[key] = values[key];
    });

    if (getFindCommitment) {
      data = {
        ...data,
        idCompromisoUnico: getFindCommitment.idCompromisoUnico,
      };
    }

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format('YYYY-MM-DD'),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
    };
    delete data.TipoVialidad;

    if (!isEmptyOrNullObject(getFindCommitment)) {
      data = { id: getFindCommitment.id, ...data };

      CompromisoServices.updateForeign(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                navigate('/historial-ext-captura');
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    } else {
      let EsNuevo = !isNullOrUndefined(getFindCommitment) ? getFindCommitment : 0;
      data = { ...data, EsNuevo };

      CompromisoServices.createCompromisoExtranjero(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                if (!isNullOrUndefined(getFindCommitment)) navigate('/pase-lista');
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    }
  };

  const clear = () => {
    setFinished(false);
    formik.setValues({
      ...formik.values,
      //idVoluntario: "",
      INE: '',
      VigenciaINE: '',
      Nombre: '',
      Paterno: '',
      Materno: '',
      idPais: '',
      idEstadoExtranjero: '',
      State: '',
      FechaNacimiento: '',
      idSexo: '',
      Line1: '',
      Line2: '',
      ZipCode: '',
      idEstadoNacimiento: '',
      CalleVive: '',
      TipoVialidad: 'C',
      NumExtVive: '',
      NumIntVive: '',
      idMunicipioVive: '',
      idLocalidadVive: '',
      ColoniaVive: '',
      CPVive: '',
      SeccionVota: '',
      Celular: '',
      Telefono: '',
      TelMensajes: '',
      Correo: '',
      Facebook: '',
      Instagram: '',
      Twitter: '',
      TikTok: '',
      //EsVoluntario: 1,
      idPartido: '',
      //Segmentos: [],
      //idMedioContacto: "",
      //TieneAuto: "",
      //TieneExperiencia: "",
      //AceptaCuidarOtra: "",
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Captura de compromisos en el extranjero"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(previousPage)}
        />
        <Card className="card-primary">
          <CardContent>
            <LoadingForm
              loadinMessage={'Cargando datos...'}
              successMessage="¡Cargado con exito!"
              isLoading={isLoadigForm}
              success={isSuccessFormSave}
              isOpen={openLinearProgress}
              setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            />

            <ResponsibleList
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              finished={finished}
            />

            <PersonalInformationForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              NotSeccion={true}
              NotSimple={true}
            />

            <AddressFormExtranjero
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              domiciliotext={'Domicilio en el extranjero'}
            />
            <ContactInformationFormExtranjero
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              handleChange={formik.handleChange}
            />

            <AddressForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              domiciliotext={'Domicilio en Guanajuato'}
            />

            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="button"
                  color="primaryDark"
                  variant="contained"
                  size="small"
                  onClick={formik.submitForm}
                  startIcon={<Icon>save</Icon>}
                >
                  Guardar
                </Button>
              </Box>
            </Grid2>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default CompromisosExtranjeroAdd;
