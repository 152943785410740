import { useState, useEffect, Fragment } from 'react';
import { TableRow, TableCell, Icon, IconButton, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import { MenuAccess } from '@interfaces/MenuInterfaces';
import { MENUS_PRIVILEGES } from '@data/constants';

const mapItem = (item) => {
  return {
    idMenu: item.idMenu,
    Ver: item.Ver,
    Agregar: item.Agregar,
    Editar: item.Editar,
    Eliminar: item.Eliminar,
    Exportar: item.Exportar,
    Importar: item.Importar,
    Autorizar: item.Autorizar,
    Visible: item.Visible,
  };
};

const columns = MENUS_PRIVILEGES;

const MenuRow4Edit = (props) => {
  const { menu = MenuAccess, handleSwitch, canDelete = false, handleDelete } = props;

  const [open, setOpen] = useState(false);
  const [menu4Select, setMenu4Select] = useState(MenuAccess);
  const [submenus, setSubMenus] = useState([]);

  useEffect(() => {
    setMenu4Select(mapItem(menu));
    setSubMenus(() => {
      if (menu.submenus && menu.submenus.length > 0) {
        return menu.submenus.map((item) => {
          let row = mapItem(item);
          row.checked = false;
          return row;
        });
      }
      return [];
    });
  }, [menu]);

  return (
    <Fragment>
      <TableRow>
        <TableCell width="5%">
          {menu.submenus && menu.submenus.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {canDelete && (
            <IconButton aria-label="expand row" size="small" color="error" onClick={() => handleDelete(menu)}>
              <Icon>clear</Icon>
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {menu.Icono ? <Icon>{menu.Icono}</Icon> : menu.Siglas ? menu.Siglas : ''}
        </TableCell>
        <TableCell width="25%">
          {menu.Menu} <br />
          <small>{menu.Ruta}</small>
        </TableCell>
        {columns.map((col, i) => (
          <TableCell align="center" key={`col_${i}`} width="7.5%">
            {menu[`_${col}`] === 1 ? (
              <Switch
                aria-label={`Switch ${col}_${menu.id}`}
                onChange={(e) => handleSwitch(e, menu4Select.idMenu, col, menu4Select)}
                checked={menu4Select[col] === 1 ? true : false}
              />
            ) : (
              '-'
            )}
          </TableCell>
        ))}
      </TableRow>
      {submenus &&
        submenus.length > 0 &&
        menu.submenus.map((submenu, j) => (
          <TableRow key={`c_${j}`} sx={{ display: `${open ? '' : 'none'}` }}>
            <TableCell align="right" colSpan={2} width="10%">
              {canDelete && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  color="error"
                  onClick={() => handleDelete(submenu)}
                >
                  <Icon>clear</Icon>
                </IconButton>
              )}
            </TableCell>
            <TableCell align="right" width="5%">
              {submenu.Icono ? <Icon>{submenu.Icono}</Icon> : submenu.Siglas ? submenu.Siglas : ''}
            </TableCell>
            <TableCell width="25%" sx={{ pr: 0 }}>
              {submenu.Menu} <br />
              <small>{submenu.Ruta}</small>
            </TableCell>
            {columns.map((col, j) => {
              return (
                <TableCell align="center" key={`${submenu.id}_${j}`} width="7.5%">
                  {submenu[`_${col}`] === 1 ? (
                    <Switch
                      aria-label={`Switch ${col}_${submenu.id}`}
                      onChange={(e) => handleSwitch(e, submenu.idMenu, col, mapItem(submenu))}
                      checked={submenu[col] === 1 ? true : false}
                    />
                  ) : (
                    '-'
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
    </Fragment>
  );
};

MenuRow4Edit.propTypes = {
  menu: PropTypes.object.isRequired,
  handleSwitch: PropTypes.func,
  canDelete: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
};

export default MenuRow4Edit;
