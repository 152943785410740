import { useState } from 'react';

// Material UI
import { Typography, Button } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ExampleLayout from '@components/_Examples/ExampleLayout';
import FloatingButton from '@components/Button/FloatingButton';

import AdaptiveCardExamples from '@components/_Examples/AdaptiveCardExamples';
import AdaptiveCard from '@components/Card/AdaptiveCard';

import DrawerRelativeExamples from '@components/_Examples/DrawerRelativeExamples';
import DrawerRelative from '@components/Containers/DrawerRelative';

import CustomTabsExamples from '@components/_Examples/CustomTabsExamples';
import CustomTabs from '@components/Tabs/CustomTabs';

import CustomTablesExamples from '@components/_Examples/CustomTablesExamples';
import CustomTable from '@components/Tables/CustomTable';

import BasicListDobleExamples from '@components/_Examples/BasicListDobleExamples';
import BasicListDoble from '@components/Lists/BasicListDoble';

// Servicios
import UserService from '@services/UserServices';

// Utilidades
import { isTypePhone } from '@utils/validations';

const ExamplesPage = () => {
  const [option, setOption] = useState('');

  const handleOption = (value) => {
    if (option === value) setOption('');
    else setOption(value);
  };

  //  DrawerRelative      ----------------------------------------
  const [drawerOpen, setDrawerOpen] = useState(true);

  //  CustomTabs          ----------------------------------------
  const [tabValue, setTabValue] = useState('0');

  return (
    <AdminLayout sx={{ px: 4, py: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <FloatingButton onClick={() => handleOption('')} icon="refresh" label="Ocultar todo" />

      <Typography variant="h2" align="center" sx={{ fontWeight: 800 }}>
        Cards
      </Typography>

      {/* AdaptiveCard */}
      <ExampleLayout
        title="Adaptive Card"
        button={{ sm: 6 }}
        example={{ sm: 6 }}
        onClick={() => handleOption(0)}
        page={option === 0 && <AdaptiveCardExamples />}
      >
        <AdaptiveCard
          title="Titulo del grafico"
          icon="people"
          config={{ data: 43000, percent: 100, type: 'linear' }}
          rightConfig={{ title: 'Derecha', data: 17000, percent: 30 }}
          leftConfig={{ title: 'Izquierda', data: 2000, percent: 10 }}
          fixed
          adjust
        />
      </ExampleLayout>

      <Typography variant="h2" align="center" sx={{ fontWeight: 800 }}>
        Drawer
      </Typography>

      {/* DrawerRelative */}
      <ExampleLayout
        title="Drawer Relative"
        button={{ sm: 6 }}
        example={{ sm: 6 }}
        onClick={() => handleOption(1)}
        page={option === 1 && <DrawerRelativeExamples />}
      >
        <DrawerRelative
          anchor="right"
          open={drawerOpen}
          setOpen={() => setDrawerOpen(false)}
          title="Filtrar"
          icon="person_search"
          sxPaper={{ borderRadius: 3 }}
          className="card-primary"
          isSticky
          screenHeight="md"
        >
          <div style={{ padding: 16 }}> Hola soy un drawer y puedo ser relativo y sticky a la vez</div>
        </DrawerRelative>

        {!drawerOpen && <Button onClick={() => setDrawerOpen(true)}>Abrir Drawer</Button>}
      </ExampleLayout>

      <Typography variant="h2" align="center" sx={{ fontWeight: 800 }}>
        Tabs
      </Typography>

      {/* CustomTabs */}
      <ExampleLayout
        title="Custom Tabs"
        button={{ sm: 6 }}
        example={{ sm: 6 }}
        onClick={() => handleOption(2)}
        page={option === 2 && <CustomTabsExamples />}
      >
        <CustomTabs
          value={tabValue}
          setValue={setTabValue}
          tabs={[
            { icon: 'settings', label: 'Tab 1', value: '0', component: <>Componente 1</> },
            { icon: 'people', iconPosition: 'end', label: 'Tab 2', value: '1', component: <>Componente 2</> },
            { label: 'Tab 3', value: '2', component: <>Componente 3</> },
          ]}
        />
      </ExampleLayout>

      <Typography variant="h2" align="center" sx={{ fontWeight: 800 }}>
        Tables
      </Typography>

      {/* Tables */}
      <ExampleLayout
        title="Custom Table"
        button={{ sm: 6 }}
        example={{ sm: 6 }}
        onClick={() => handleOption(3)}
        page={option === 3 && <CustomTablesExamples />}
      >
        <CustomTable
          rows={[
            { row1: 12345, row2: 'texto1', row3: 'Boton 1', row4: 'block' },
            { row1: 22.2222, row2: 'texto2', row3: 'Boton 2', row4: 'done' },
          ]}
          columns={[
            { id: 'row1', label: 'Numeros', type: 'number', fixed: 2 },
            { id: 'row2', label: 'Texto', type: 'text' },
            { id: 'row3', label: 'Boton', type: 'button', sort: false },
            { id: 'row4', label: 'Icono', type: 'iconButton', disablePadding: true, sort: false },
          ]}
        />
      </ExampleLayout>

      <Typography variant="h2" align="center" sx={{ fontWeight: 800 }}>
        Lists
      </Typography>

      {/* BasicListDoble */}
      <ExampleLayout
        title="Basic List Doble"
        button={{ sm: 6 }}
        example={{ sm: 6 }}
        onClick={() => handleOption(4)}
        page={option === 4 && <BasicListDobleExamples />}
      >
        <BasicListDoble
          API={UserService.getUsers}
          APIKeys={[{ id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno)", filter: 'LIKE' }]}
          config={{ minHeight: 100, maxHeight: 200 }}
          // itemComponent={DefaultListItem} // Se puede omitir
          label="Encuentra al usuario"
          labelNote="Nota: Debe ingresar un nombre"
          handleClick={handleOption}
          inputValidation={{ input2: isTypePhone }}
          // initialSearch
          // emptySearch
          // doubleSearch
        />
      </ExampleLayout>
    </AdminLayout>
  );
};

export default ExamplesPage;
