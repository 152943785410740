import React, { useState, useEffect } from 'react';

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Icon,
  ListItemText,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';

//components
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Compromisos/FilterForeign';
import PlacementColumns from '@components/Charts/DashChart';
import BasicTable from '@components/Tables/BasicTable';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';
import CompromisoServices from '@services/CompromisoServices';
import CardStats from '@components/Card/CardStats';
//Plugins
import Swal from 'sweetalert2';
import moment from 'moment';
import { getVars } from '@utils/global';
import { numberWithCommas } from '@utils/Utilities';
import middleware from '@middlewares/middleware';

const CompromisosExtranjero = () => {
  //variables
  const columns = [
    { id: 'Pais', label: 'País', align: 'center' },
    { id: 'Estado', label: 'Estado' },
    { id: 'NombreCompleto', label: 'Nombre' },
    { id: 'Municipio', label: 'MunicipioGTO', align: 'center' },
    { id: 'TieneUsuario', label: 'TieneUsuario', align: 'center' },
  ];
  const {
    user: { ambito_perfil },
  } = getVars('Token');

  const isUsuarioEstatal = ambito_perfil.some((item) => item.idAmbitoVisualizacion === 1);

  //States
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [commitments, setCommitments] = useState([]);
  const [dataChart, setDataChart] = useState({
    title: '',
    date: '',
    categories: [],
    series1: [],
    series2: [],
  });
  const [summary, setSummary] = useState({
    captured: '',
    commitments: '',
    volunteers: '',
    last_updated: '',
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: 'compromisos_unicos_extranjero.FechaCreo', value: 'DESC' }],
  });
  const [groupByChart, setGroupByChart] = useState({
    groupBy: 'PA',
    filtered: [],
  });

  //  console.log();
  //Functions Api's
  const getCommitments = () => {
    setLoading(true);
    CompromisoServices.getListForeign(data)
      .then((res) => {
        if (res.results) {
          const commitments = res.response.data.map((commitment) => {
            return {
              ...commitment,
              NombreCompleto: (
                <ListItemText
                  primary={
                    <Typography variant="div" fontWeight={700} fontSize={15} color={blue[900]}>
                      {commitment.Nombre} {commitment.Paterno}
                    </Typography>
                  }
                  secondary={`${commitment.Edad} Años`}
                />
              ),
              isVoluntario: commitment.isVoluntario === 1 ? 'Si' : 'No',
              Responsabilidad: CellResponsibility(commitment.Responsabilidad),
            };
          });

          setCommitments(commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };

  const CellResponsibility = (value) => {
    if (value === null) return 'Sin Responsabiliad';

    return (
      <>
        {value.split('|').map((item) => (
          <Typography>{item}</Typography>
        ))}
      </>
    );
  };

  const getSummary = () => {
    setLoading(true);
    const filtered = data.filtered.length > 0 ? data.filtered : groupByChart.filtered;
    CompromisoServices.getSummaryForeign({ filtered: filtered })
      .then((res) => {
        if (res.results) {
          const { CapturadosHoy, TotalCompromisos, Voluntarios, UltimaActualizacion } = res.response;

          setSummary({
            ...summary,
            captured: CapturadosHoy !== null ? numberWithCommas(CapturadosHoy) : 0,
            commitments: TotalCompromisos !== null ? numberWithCommas(TotalCompromisos) : 0,
            volunteers: Voluntarios !== null ? numberWithCommas(Voluntarios) : 0,
            last_updated: moment(UltimaActualizacion).format('DD/MM/YYYY H:mm'),
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };

  const getChart = () => {
    setIsLoadingChart(true);
    CompromisoServices.getChartForeign(groupByChart)
      .then((res) => {
        if (res.results) {
          const { title, date, categories, series1, series2 } = res.response;
          setDataChart({
            ...dataChart,
            title,
            date,
            categories,
            series1,
            series2,
          });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'waring' }))
      .finally(() => setIsLoadingChart(false));
  };

  useEffect(() => {
    getCommitments();
    getSummary();
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getChart();

    //eslint-disable-next-line
  }, [groupByChart]);

  //Functions and Handlers
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleExport = () => {
    setIsDownloading(true);
    setTimeout(() => {
      setIsDownloading(false);
      setShowMessage(true);
      setSuccessDownload(true);
    }, 3000);
  };

  const handleChangeFilter = (type) => {
    const { Celular, ClaveElector, NombreCompleto, Calle, Colonia, GroupBy } = type;

    let filtered = [];
    let filteredChart = [];
    let groupBy = '';

    if (Celular.length > 0) {
      filtered.push({
        id: 'compromisos_unicos_extranjero.Celular',
        filter: 'LIKE',
        value: Celular,
      });
    }

    if (ClaveElector.length > 0) {
      filtered.push({
        id: 'compromisos_unicos_extranjero.INE',
        filter: '=',
        value: ClaveElector,
      });
    }

    if (NombreCompleto.length > 0) {
      filtered.push({
        id: [
          'compromisos_unicos_extranjero.Nombre',
          'compromisos_unicos_extranjero.Paterno',
          'compromisos_unicos_extranjero.Materno',
        ],
        filter: 'LIKE',
        value: NombreCompleto,
      });
    }

    if (Calle.length > 0) {
      filtered.push({
        id: 'compromisos_unicos_extranjero.CalleVive',
        filter: 'LIKE',
        value: Calle,
      });
    }

    if (Colonia.length > 0) {
      filtered.push({
        id: 'compromisos_unicos_extranjero.ColoniaVive',
        filter: 'LIKE',
        value: Colonia,
      });
    }

    if (GroupBy.length > 0) {
      groupBy = GroupBy;
    }

    setGroupByChart({
      ...groupByChart,
      groupBy: groupBy,
      filtered: filteredChart,
    });

    setData({
      ...data,
      filtered: filtered,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Compromiso en el extranjero'} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'fact_check'}
              title={'Capturados Hoy'}
              total={summary.captured}
              subIcon={'calendar_today'}
              subtitle={summary.last_updated}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'groups'}
              title={'Compromisos'}
              total={summary.commitments}
              subIcon={'sync'}
              subtitle={'Actualización'}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            {isLoadingChart ? (
              <CircularProgress />
            ) : (
              <PlacementColumns
                titulo={{ title: dataChart.title }}
                subtitle={{
                  subtitle: moment(dataChart.date).format('DD/MM/YYYY H:mm'),
                }}
                datos={{
                  categories: dataChart.categories,
                  series: [
                    {
                      name: 'Meta',
                      color: 'rgba(0,65,165,1)',
                      data: dataChart.series1,
                      pointPadding: 0.3,
                      pointPlacement: -0.2,
                    },
                    {
                      name: 'Avance',
                      color: 'rgba(147,220,236,.9)',
                      data: dataChart.series2,
                      pointPadding: 0.4,
                      pointPlacement: -0.2,
                    },
                  ],
                }}
                loading={isLoadingChart}
              />
            )}
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            <Filter
              onChangeFilter={(e) => handleChangeFilter(e)}
              data={data}
              setData={setData}
              setGroupByChart={setGroupByChart}
              isUsuarioEstatal={isUsuarioEstatal}
            />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            {isDownload ? (
              <Download format={'xlsx'} isDownload={isDownload} />
            ) : (
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ mb: 2 }}
                >
                  Exportar
                </Button>
              </Box>
            )}

            {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}

            <BasicTable
              rows={commitments}
              hcolumns={columns}
              pageProp={data.page}
              pageSize={data.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default CompromisosExtranjero;
