import { useState, useEffect } from 'react';

// Material UI
import { Chip, Box } from '@mui/material';

// Componentes
import AdvancedSelect from '@components/Selects/AdvancedSelect';

const CasillasSelected = (props) => {
  const { seccion, allCasillas, selectedCasillas, handleCasillas, type } = props;

  const casillas = allCasillas;
  const [casillasFilter, setCasillasFilter] = useState(selectedCasillas);
  const [prevCasillasFilter, setPrevCasillasFilter] = useState([]);

  useEffect(() => {
    setPrevCasillasFilter(casillasFilter);
  }, [casillasFilter]);

  const handleChangeCasilla = (value) => {
    const removedElement = prevCasillasFilter.filter((prev) => !value.includes(prev));
    const addedElement = value.length && !removedElement.length ? value[value.length - 1] : undefined;

    const accion = removedElement.length ? 'eliminar' : 'agregar';
    setCasillasFilter(value);
    handleCasillas(removedElement.length ? removedElement : addedElement, accion, type, seccion);
  };

  const label =
    'Sección ' +
    seccion +
    ' - ' +
    casillasFilter.length +
    ' casilla' +
    (casillasFilter.length !== 1 ? 's' : '');

  return (
    <Box sx={{ display: 'flex', gap: 1, marginBottom: 1, alignItems: 'center' }} key={'key_' + seccion}>
      <Chip label={seccion} />
      <AdvancedSelect
        id={seccion + ''}
        label={label}
        placeholder="Buscar casilla"
        value={casillasFilter}
        options={casillas}
        onChange={handleChangeCasilla}
        multiple
        disableCloseOnSelect
      />
    </Box>
  );
};

export default CasillasSelected;
