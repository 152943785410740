// Material UI
import { Box, Typography, Tooltip } from '@mui/material';

// Map Things
import { roundTo } from '@components/Maps/Auxiliars/MathFunctions';

/* Ejemplo de elements
    const elements = [
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "Avance:",
      name: "avance",
      type: "int",
    },
    {
      title: "Porcentaje de Avance:",
      name: "avance_porcentual",
      type: "percent",
    },
  ]; */

const CardInfoMini = (props) => {
  const { properties, elements, title = null, width = '100%', toolTip = false } = props;

  // Estructura de la tabla
  const tableItems = elements.map((item) => ({
    title: item.title,
    value: properties
      ? item.type === 'text'
        ? properties[item.name] || properties[item.name] === 0
          ? properties[item.name]
          : '--'
        : item.type === 'int'
          ? new Intl.NumberFormat('en-EN').format(properties[item.name])
          : item.type === 'percent'
            ? properties[item.name] === null
              ? '--'
              : roundTo(properties[item.name], 2) + '%'
            : properties[item.name]
      : null,
  }));
  return (
    <>
      {properties && (
        <Box sx={{ paddingY: 1, width: width }}>
          {title && (
            <Box sx={{ backgroundColor: '#EBEBEB', borderRadius: '4px 4px 0 0', paddingY: '2px' }}>
              <Typography variant="h5" fontSize={12} align="center">
                {title}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexWrap="wrap">
            {tableItems.map((row, index) =>
              toolTip ? (
                <Tooltip key={index} title={row.value} disableInteractive arrow>
                  <Box
                    sx={{
                      flex: 1,
                      borderRadius: 1,
                      maxWidth: width,
                      paddingX: 1,
                      paddingY: '2px',
                      '&:hover': { backgroundColor: '#C7D8EA' },
                    }}
                  >
                    <Typography variant="h5" fontSize={12} fontWeight={400} align="center" noWrap>
                      <strong>{row.title}</strong> {row.value}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  key={index}
                  sx={{
                    flex: 1,
                    borderRadius: 1,
                    maxWidth: width,
                    paddingX: 1,
                    paddingY: '2px',
                    '&:hover': { backgroundColor: '#C7D8EA' },
                  }}
                >
                  <Typography variant="h5" fontSize={12} fontWeight={400} align="center" noWrap>
                    <strong>{row.title}</strong> {row.value}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CardInfoMini;
