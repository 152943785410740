import React from 'react';
import { Alert, AlertTitle, Collapse, Icon, IconButton, Typography } from '@mui/material';

const MessageAlert = ({ showMessage, successDownload, setShowMessage, rest }) => {
  return (
    <Collapse in={showMessage}>
      <Alert
        sx={{ mb: 2 }}
        severity={successDownload ? 'success' : 'error'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowMessage(false);
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        }
      >
        <AlertTitle>{successDownload ? 'Éxito' : 'Error'}</AlertTitle>
        {successDownload ? (
          <Typography variant="body2">El documento ha sido descargado exitosamente</Typography>
        ) : (
          <Typography variant="body2">
            El documento no pudo ser descargado —{' '}
            <strong>Ocurrio un problema, ¡Contacte con el administrador!</strong>
          </Typography>
        )}
      </Alert>
    </Collapse>
  );
};

export default MessageAlert;
