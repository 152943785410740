import { useEffect, useState } from 'react';

import { Alert, Button, Card, CardContent, Icon, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import BasicSelect from '@components/Selects/BasicSelect';

import sije from '@services/SijeService';
import Swal from 'sweetalert2';

const FilterMunicipal = ({ onChangeFilter, onClearFilter }) => {
  //CONSTANTS
  const catalogsOptions = [{ id: 'regiones' }, { id: 'municipios_reportes' }];
  const defaultOption = { value: 0, label: 'TODOS' };

  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    Region: 0,
    Municipio: 0,
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: [defaultOption, ...catalogs[filterKey].filter((item) => item[filterType] === filterValue)],
    }));
  };

  const handleChangeRegion = (e) => {
    const idRegion = e.target.value;

    if (idRegion > 0) {
      handleCatalogFilterChange({
        filterKey: 'municipios_reportes',
        filterType: 'idRegion',
        filterValue: idRegion,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
      }));
    }
  };

  const handleClearFilter = () => {
    setFilterData((prevState) => ({
      ...prevState,
      Region: 0,
      Municipio: 0,
    }));
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    onClearFilter();
  };

  const handleSearch = (e) => {
    const filters = [
      {
        id: 'id',
        key: 'Municipio',
      },
    ];

    const filtered = filters
      .filter((filter) => filterData[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: '=',
        value: filterData[filter.key],
      }));

    onChangeFilter({ filters: filtered });
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <Stack direction={'row'} spacing={1} marginBottom={2} alignItems={'center'}>
          <Icon>filter_list</Icon>
          <Typography variant="body2" fontWeight={600}>
            FILTROS
          </Typography>
        </Stack>
        <Alert severity="info" sx={{ mb: 2 }}>
          Elija un municipio para acceder a la información de la estructura.
        </Alert>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={5} lg={5}>
            <BasicSelect
              name="Region"
              label="Región"
              options={catalogsFiltered.regiones}
              value={filterData.Region}
              onChange={(e) => {
                handleChangeFilter(e);
                handleChangeRegion(e);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} md={5} lg={5}>
            <BasicSelect
              name="Municipio"
              label="Municipio"
              options={catalogsFiltered.municipios_reportes}
              value={filterData.Municipio}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} md={2} lg={2}>
            <Stack direction={'row'} spacing={1}>
              <Button color="primaryDark" onClick={handleClearFilter}>
                Limpiar
              </Button>
              <Button
                variant="contained"
                color="primaryDark"
                disabled={!filterData.Municipio}
                onClick={handleSearch}
              >
                Filtrar
              </Button>
            </Stack>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default FilterMunicipal;
