export const LUN = 1;
export const MAR = 2;
export const MIE = 3;
export const JUE = 4;
export const VIE = 5;
export const SAB = 6;
export const DOM = 7;
export const ACCESO_WEB = 1;
export const ACCESO_APP = 2;
export const time_regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

export const months = [
  { value: '01', label: 'Enero' },
  { value: '02', label: 'Febrero' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Mayo' },
  { value: '06', label: 'Junio' },
  { value: '07', label: 'Julio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Septiembre' },
  { value: '10', label: 'Octubre' },
  { value: '11', label: 'Noviembre' },
  { value: '12', label: 'Diciembre' },
];
