import { useState, useEffect } from 'react';

// Material UI
import { Grid, Button, Stack } from '@mui/material';
import Swal from 'sweetalert2';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';

// Otros
import CatalogService from '@services/CatalogServices';
import { setVars, getVars } from '@utils/global';

const Filter = ({ catalogs, setCatalogs, loadingCatalogs, handleFilter, width = '300px' }) => {
  const [isLoadingCatDFED, setIsLoadingCatDFED] = useState(false);
  const [isLoadingCatDLOC, setIsLoadingCatDLOC] = useState(false);
  const [filter, setFilter] = useState({
    idMunicipioReportes: '',
    idDF: '',
    idDL: '',
  });

  useEffect(() => {
    const filter_saved = getVars('filterRoutes');
    setFilter(filter_saved);
    // eslint-disable-next-line
  }, []);

  const loadCatalogsByMunicipio = (idMunicipioReportes) => {
    const catalogsParams = [
      { id: 'df', filtered: [{ id: 'idMunicipioReportes', filter: '=', value: idMunicipioReportes }] },
      { id: 'dl', filtered: [{ id: 'idMunicipioReportes', filter: '=', value: idMunicipioReportes }] },
    ];

    setIsLoadingCatDFED(true);
    setIsLoadingCatDLOC(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs((prevState) => {
            return {
              ...prevState,
              DF: res.response.catalogs.df ?? [],
              DL: res.response.catalogs.dl ?? [],
            };
          });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingCatDFED(false);
        setIsLoadingCatDLOC(false);
      });
  };

  const loadCatalogsByDFED = (idDF) => {
    const catalogsParams = [
      {
        id: 'dl',
        filtered: [
          { id: 'idMunicipioReportes', filter: '=', value: filter.idMunicipioReportes },
          { id: 'idDF', filter: '=', value: idDF },
        ],
      },
    ];

    setIsLoadingCatDLOC(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs((prevState) => {
            return {
              ...prevState,
              DL: res.response.catalogs.dl ?? [],
            };
          });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setIsLoadingCatDLOC(false));
  };

  const handleChangeMunicipio = (e) => {
    const value = e.target.value;
    setFilter({
      idMunicipioReportes: value,
      idDF: '',
      idDL: '',
    });

    loadCatalogsByMunicipio(value);
  };

  const handleChangeDFED = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    loadCatalogsByDFED(e.target.value);
  };

  const handleChangeDLOC = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let filter_data = [];
    if (filter.idMunicipioReportes && filter.idMunicipioReportes !== '') {
      filter_data.push({
        id: 'cat_secciones.idMunicipioReportes',
        filter: '=',
        value: filter.idMunicipioReportes,
      });
    }
    if (filter.idDF && filter.idDF !== '') {
      filter_data.push({ id: 'cat_secciones.idDF', filter: '=', value: filter.idDF });
    }
    if (filter.idDL && filter.idDL !== '') {
      filter_data.push({ id: 'cat_secciones.idDL', filter: '=', value: filter.idDL });
    }
    if (filter.idMunicipioReportes !== '' && filter.idDF /*  !== "" && filter.idDL !== "" */) {
      setVars('flagCreatePolygon', true);
    } else setVars('flagCreatePolygon', false);

    handleFilter(filter_data);
    setVars('filterRoutes', {
      idMunicipioReportes: filter.idMunicipioReportes,
      idDF: filter.idDF,
      idDL: filter.idDL,
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} sx={{ width: { xs: '100%', sm: width } }}>
        <Grid item xs={12}>
          <BasicSelect
            name="idMunicipioReportes"
            label="Muncipio *"
            options={catalogs.municipios}
            value={filter.idMunicipioReportes}
            onChange={handleChangeMunicipio}
            isLoading={loadingCatalogs}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            name="idDF"
            label="DFED *"
            options={catalogs.DF}
            value={filter.idDF}
            onChange={handleChangeDFED}
            isLoading={isLoadingCatDFED}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            name="idDL"
            label="DLOC *"
            options={catalogs.DL}
            value={filter.idDL}
            onChange={handleChangeDLOC}
            isLoading={isLoadingCatDLOC}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={filter.idMunicipioReportes === '' && filter.idDF === '' && filter.idDL === ''}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};
export default Filter;
