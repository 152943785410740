export const COLUMNS_RCO_MOVILIZATION = [
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'Poligono', label: 'POLÍGONO' },
  { id: 'Seccion', label: 'SECCIÓN' },
  { id: 'MetaCompromisos', label: 'META' },
  { id: 'AvanceCompromisosUnicos', label: 'AVANCE' },
  { id: 'PorcentajeAvance', label: '% AVANCE' },
  { id: 'NoBingo', label: 'No BINGO' },
  { id: 'MetaBingo', label: 'BINGO' },
  { id: 'AvanceBingo', label: 'AVANCE BINGO' },
  { id: 'DiferenciaBingo', label: 'POR MOVILIZAR' },
];

const COLUMNS_RCO_GRAL = [
  { id: 'MetaEstructura', label: 'META' },
  { id: 'AvanceEstructura', label: 'AVANCE' },
  { id: 'NombreCompleto', label: 'NOMBRE' },
  { id: 'MetaManzanaP1', label: 'META MZANA P1' },
  { id: 'AvanceManzanaP1', label: 'AVANCE MZANA P1' },
  { id: 'MetaManzanaP2', label: 'META MZANA P2' },
  { id: 'AvanceManzanaP2', label: 'AVANCE MZANA P2' },
  { id: 'MetaManzanaP3', label: 'META MZANA P3' },
  { id: 'AvanceManzanaP3', label: 'AVANCE MZANA P3' },
  { id: 'MetaCompromisos', label: 'META COMPROMISOS' },
  { id: 'AvanceCompromisosUnicos', label: 'AVANCE COMPROMISOS' },
];

export const COLUMNS_RCO_POLYGONAL = [{ id: 'Seccion', label: 'SECCIÓN' }, ...COLUMNS_RCO_GRAL];

export const COLUMNS_RCO_MUNICIPAL = [{ id: 'Poligono', label: 'POLÍGONO' }, ...COLUMNS_RCO_GRAL];

export const COLUMNS_RCO_REGIONAL = [{ id: 'Municipio', label: 'MUNICIPIO' }, ...COLUMNS_RCO_GRAL];

export const COLUMNS_RCO_ESTATAL = [
  { id: 'idDF', label: 'DF' },
  { id: 'idDL', label: 'DL' },
  { id: 'Region', label: 'REGIÓN' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'Poligono', label: 'POLÍGONO' },
  { id: 'Seccion', label: 'SECCIÓN' },
  { id: 'LNOM', label: 'LNOM', align: 'right' },
  { id: 'MetaSeccionales', label: 'META SECCIONALES', align: 'right', type: 'number' },
  { id: 'MetaEstructura', label: 'META ESTRUCTURA', align: 'right', type: 'number' },
  { id: 'AvanceEstructura', label: 'AVANCE ESTRUCTURA', align: 'right', type: 'number' },
  { id: 'AvanceEstructuraMunicipio', label: 'AVANCE ESTRUCTURA MUNICIPIO', align: 'right', type: 'number' },
  { id: 'AvanceEstructuraPoligono', label: 'AVANCE ESTRUCTURA POLÍGONO', align: 'right', type: 'number' },
  { id: 'AvanceEstructuraRegion', label: 'AVANCE ESTRUCTURA REGIÓN', align: 'right', type: 'number' },
  {
    id: 'AvanceEstructuraSeccionales',
    label: 'AVANCE ESTRUCTURA SECCIONALES',
    align: 'right',
    type: 'number',
  },
  { id: 'PorcentajeAvanceEstructura', label: '% AVANCE ESTRUCTURA', align: 'center', type: 'circular' },
  { id: 'MetaCompromisos', label: 'META COMPROMISOS', align: 'right', type: 'number' },
  { id: 'AvanceCompromisos', label: 'AVANCE COMPROMISOS', align: 'right', type: 'number' },
  { id: 'PorcentajeAvanceCompromisos', label: '% AVANCE COMPROMISOS', align: 'center', type: 'circular' },
  //{ id: "PorcentajeAvanceEstructura", label: "% AVANCE ESTRUCTURA", type: "circular" },
  {
    id: 'PorcentajeAvanceEstructuraMunicipio',
    label: '% AVANCE ESTRUCTURA MUNICIPIO',
    align: 'center',
    type: 'circular',
  },
  {
    id: 'PorcentajeAvanceEstructuraPoligono',
    label: '% AVANCE ESTRUCTURA POLÍGONO',
    align: 'center',
    type: 'circular',
  },
  {
    id: 'PorcentajeAvanceEstructuraRegion',
    label: '% AVANCE ESTRUCTURA REGIÓN',
    align: 'center',
    type: 'circular',
  },
  {
    id: 'PorcentajeAvanceEstructuraSeccionales',
    label: '% AVANCE ESTRUCTURA SECCIONALES',
    align: 'center',
    type: 'circular',
  },
  { id: 'MetaEstructuraMunicipio', label: 'META ESTRUCTURA MUNICIPIO', align: 'right', type: 'number' },
  { id: 'MetaEstructuraPoligono', label: 'META ESTRUCTURA POLÍGONO', align: 'right', type: 'number' },
  { id: 'MetaEstructuraRegion', label: 'META ESTRUCTURA REGIÓN', align: 'right', type: 'number' },
  { id: 'MetaManzanaP1', label: 'META MANZANA P1', align: 'right', type: 'number' },
  { id: 'AvanceManzanaP1', label: 'AVANCE MANZANA P1', align: 'right', type: 'number' },
  { id: 'PorcentajeAvanceManzanaP1', label: '% AVANCE MANZANA P1', align: 'center', type: 'circular' },
  { id: 'MetaManzanaP2', label: 'META MANZANA P2', align: 'right', type: 'number' },
  { id: 'AvanceManzanaP2', label: 'AVANCE MANZANA P2', align: 'right', type: 'number' },
  { id: 'PorcentajeAvanceManzanaP2', label: '% AVANCE MANZANA P2', align: 'center', type: 'circular' },
  { id: 'MetaManzanaP3', label: 'META MANZANA P3', align: 'right', type: 'number' },
  { id: 'AvanceManzanaP3', label: 'AVANCE MANZANA P3', align: 'right', type: 'number' },
  { id: 'PorcentajeAvanceManzanaP3', label: '% AVANCE MANZANA P3', align: 'center', type: 'circular' },
];

export const COLUMNS_REGION_MUNICIPIO = [
  { id: 'Region', label: 'REGIÓN' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'MetaCompromisos', label: 'META', align: 'right' },
  { id: 'AvanceCompromisosUnicos', label: 'COMPROMISOS HOY', align: 'right' },
  { id: 'PorcentajeAvance', label: '% AVANCE', align: 'center' },
  { id: 'AvanceSemanaPasada', label: 'AVANCE SEMANA PASADA', align: 'right' },
  { id: 'Diferencia', label: 'DIFERENCIA', align: 'right' },
  { id: 'PorcentajeDiferencia', label: '% DIFERENCIA', align: 'center' },
];

export const COLUMNS_MUNICIPIO_POLIGONO = [
  { id: 'Region', label: 'REGIÓN' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'Poligono', label: 'POLÍGONO' },
  { id: 'MetaCompromisos', label: 'META', align: 'right' },
  { id: 'AvanceCompromisosUnicos', label: 'COMPROMISOS HOY', align: 'right' },
  { id: 'PorcentajeAvance', label: '% AVANCE', align: 'center' },
  { id: 'AvanceSemanaPasada', label: 'AVANCE SEMANA PASADA', align: 'right' },
  { id: 'Diferencia', label: 'DIFERENCIA', align: 'right' },
  { id: 'PorcentajeDiferencia', label: '% DIFERENCIA', align: 'center' },
];

export const PROGRESS_KEYS = [
  'PorcentajeAvanceEstructura',
  'PorcentajeAvanceCompromisos',
  'PorcentajeAvanceEstructuraMunicipio',
  'PorcentajeAvanceEstructuraPoligono',
  'PorcentajeAvanceEstructuraRegion',
  'PorcentajeAvanceEstructuraSeccionales',
  'PorcentajeAvance',
  'PorcentajeDiferencia',
  'PorcentajeAvanceManzanaP1',
  'PorcentajeAvanceManzanaP2',
  'PorcentajeAvanceManzanaP3',
];

export const CATALOG_OPTIONS = [
  { id: 'regiones', getAll: false },
  { id: 'secciones', getAll: false },
  { id: 'poligonos', getAll: false },
  { id: 'municipios_reportes', getAll: false },
];

export const CATALOGS_OPTIONS_REGION_MUNICIPIO = [
  { id: 'regiones', getAll: false },
  { id: 'municipios_reportes', getAll: false },
];
