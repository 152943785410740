import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const headerCellStyle = {
  backgroundColor: grey[100],
  fontWeight: 'bold',
};

const TableHeader = ({ isAction = true }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            NOMBRE
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            CONTACTO
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            SECCIÓN
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            CASILLA
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>FOLIO BINGO</TableCell>
        {isAction && (
          <TableCell style={{ ...headerCellStyle }}>
            <Typography variant="subtitle2" fontWeight={600}>
              ACCIÓN
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
