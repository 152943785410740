import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import {
  Card,
  CardContent,
  Container,
  Button,
  Grid,
  Box,
  Icon,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTableOficios from '@components/Tables/CustomTableOficios';
import { getVars } from '@utils/global';

import OficialiaServices from '@services/OficialiaServices';
import Swal from 'sweetalert2';
import FilterOficialia from '@components/Oficialia/Filter';
import middleware from '@middlewares/middleware';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';
import CardStatsOficialia from '@components/Card/CardStatsOficialia';

import './OficialiaPartes.css';

const OficiliaPartes = () => {
  const { user } = getVars('Token');
  const navigate = useNavigate();
  const [statsOficios, setStatsOficios] = useState({
    EnTransito: 0,
    TotalOficios: 0,
    TotalOficiosActivos: 0,
    OficiosExpirados: 0,
    ProximosAExpirar: 0,
  });
  const [data, setData] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: middleware.checkMenuActionId('admin')
      ? []
      : [
          {
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and idUsuarioRecibe=${user.id} and FechaElimino is null)`,
            filterQuery: true,
          },
        ],
    sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  });

  const [isLoading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);

  useEffect(() => {
    getStatsTransito();
  }, []);

  const columns = [
    //{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
    { id: 'Fecha', label: 'Fecha', type: 'html', minWidth: 100, maxWidth: 180 },
    {
      id: 'Oficio',
      label: 'Oficio',
      type: 'html',
      icon: 'reply',
      iconColor: '#000F9F',
      iconSize: 20,
      minWidth: 150,
      maxWidth: 400,
    },
    { id: 'DeQuien', label: 'De' },
    { id: 'ParaQuien', label: 'Para' },
    {
      id: 'Estatus',
      label: 'Estatus',
    },
  ];

  // useEffect(() => {
  //   console.log('=>Con Seguimiento: ', showMore);
  //   console.log('=>params: ', JSON.stringify(params));

  //   setParams((prevParams) => {
  //     if (showMore) {
  //       console.log('=>showMore true');
  //       const newFiltered = [...prevParams.filtered];

  //       console.log('=>newFiltered: ', newFiltered.length);
  //       if (newFiltered.length > 0) {
  //         console.log('=>existen filtros: ', newFiltered);

  //         const newFilter = {
  //           id: ' and o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
  //           filterQuery: true,
  //         };

  //         const filterExists = newFiltered.some((filter) => filter.id === newFilter.id && filter.filterQuery);

  //         console.log('=>filterExists: ', filterExists);

  //         if (!filterExists) {
  //           newFiltered.push(newFilter); // Agrega el nuevo filtro si no existe
  //         }

  //         return {
  //           ...prevParams,
  //           page: 0,
  //           pageSize: 25,
  //           filtered: [
  //             {
  //               id: ' where o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
  //               filterQuery: true,
  //             },
  //           ],
  //           sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  //         };
  //       } else {
  //         console.log('=>showMore false');
  //         return {
  //           ...prevParams,
  //           page: 0,
  //           pageSize: 25,
  //           filtered: [
  //             {
  //               id: ' where o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
  //               filterQuery: true,
  //             },
  //           ],
  //           sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  //         };
  //       }
  //     } else {
  //       // Si `showMore` es false, eliminar el subquery
  //       const newFiltered = prevParams.filtered.filter(
  //         (filter) =>
  //           filter.id !==
  //           ' and o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)'
  //       );

  //       const newFiltered2 = newFiltered.filter(
  //         (filter) =>
  //           filter.id !==
  //           ' where o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)'
  //       );

  //       return {
  //         ...prevParams,
  //         page: 0,
  //         pageSize: 25,
  //         filtered: newFiltered2,
  //         sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  //       };
  //     }
  //   });
  // }, [showMore]);

  const getData = async () => {
    try {
      setLoading(true);
      console.log('=>getOficialia: ', JSON.stringify(params));

      const res = await OficialiaServices.getOficialia(params);
      const { results, response, message } = res;

      console.log('=>res: ', res);

      if (results) {
        let data = response.data.map((item) => {
          return {
            ...item,
            Estatus: (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'smaller',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                >
                  Estatus: &nbsp;
                  <span style={{ fontSize: 'smaller', cursor: 'pointer' }}>{item.Estatus}</span>
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: 'small' }}>{item.TiempoRestante}</span>
                  <br />
                  <span
                    style={{
                      color: '#0041A0',
                      fontWeight: 'bold',
                      fontSize: 'small',
                    }}
                  >
                    Recibe: {item.UsuarioRecibe}
                  </span>
                </span>
              </div>
            ),
            Oficio: (
              <div>
                {item.TieneAdjuntos ? <Icon>attachment</Icon> : null}
                {item.NumOficio ? (
                  <>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: 'smaller',
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                    >
                      Num Oficio: &nbsp;
                      <span style={{ fontSize: 'smaller', cursor: 'pointer' }}>{item.NumOficio}</span>
                      <br />
                      <span style={{ fontWeight: 'normal', fontSize: 'medium' }}>{item.Asunto}</span>
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontSize: 'medium',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                  >
                    {item.Asunto}
                  </span>
                )}
              </div>
            ),
            ParaQuien: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>{item.PersonaRecibe}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.CargoPersonaRecibe}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.EmpresaRecibe}</span>
              </div>
            ),
            DeQuien: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>{item.PersonaEnvia}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.CargoPersonaEnvia}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.EmpresaEnvia}</span>
              </div>
            ),
            Fecha: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Oficio:</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.FechaOficio}</span>
                <br />
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'smaller',
                  }}
                >
                  Recibe:
                </span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.FechaRecibido}</span>
              </div>
            ),
          };
        });
        setData(data);
        setDataTotal(response.total);
      } else {
        setData([]);
        setDataTotal(0);
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    console.log('=>params: ', params);
    getData();
    // getTotales();
  }, [params]);

  const getStatsTransito = async () => {
    try {
      setLoading(true);
      const res = await OficialiaServices.getStatsEnTransito({
        page: 0,
        pageSize: 99999,
        filtered: middleware.checkMenuActionId('admin')
          ? [
              {
                id: 'r.idEstatus',
                value: 1,
                filter: '=',
              },
            ]
          : [
              {
                id: ` and r.idEstatus=1 and ( r.idParaQuien = ${user.id} or r.idDeQuien = ${user.id})`,
                filterQuery: true,
              },
            ],
      });
      const { results, response, message } = res;

      if (results) {
        console.log('=>response.data: ', response.data);
        setStatsOficios(response.data);
      } else {
        setStatsOficios({
          EnTransito: 0,
          TotalOficios: 0,
          TotalOficiosActivos: 0,
          OficiosExpirados: 0,
          ProximosAExpirar: 0,
        });
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const handlePagination = ({ page, pageSize }) => {
    const params = {
      ...params,
      page: page,
      pageSize: pageSize,
    };
    console.log('=>handlePagination: ', params);
    setParams(params);
  };

  const handleSort = (e) => {
    /*       setOrderBy(e.orderBy);
        setOrder(e.order); */

    var OrdandoPor = [];

    if (e.orderBy === 'DeQuien') {
      OrdandoPor.push({ id: 'de.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'ParaQuien') {
      OrdandoPor.push({ id: 'dr.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'Fecha') {
      OrdandoPor.push({ id: 'o.FechaOficio', value: e.order });
    }

    if (e.orderBy === 'Oficio') {
      OrdandoPor.push({ id: 'o.Asunto', value: e.order });
    }

    const paramsNew = {
      ...params,
      sorted: OrdandoPor,
    };

    setParams(paramsNew);
  };

  const handleExport = () => {
    setIsDownloading(true);
    OficialiaServices.exportMecanismos({
      page: 0,
      pageSize: 99999999999999,
      filtered: params.filtered,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const handleConTermino = async (e) => {
    setShowMore(e);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Grid container spacing={2}>
          {/* <Grid item xs={2}>
						<CardStatsOficialia
							icon={"fact_check"}
							title={"Turnados hoy"}
							//total={`${totalUnicosSegmento}`}
							total={`${1} de ${2}`}
							// subtitle={"Únicos de General"}
							loading={isLoading}
						/>
					</Grid> */}

          <Grid item xs={4}>
            <CardStatsOficialia
              icon={'lightbulb'}
              iconColor={'#DC0019'}
              title={'Expiran'}
              //total={`${totalUnicosSegmento}`}
              total={`${parseInt(statsOficios.OficiosExpirados)} de ${
                parseInt(statsOficios.OficiosExpirados) + parseInt(statsOficios.ProximosAExpirar)
              }`}
              subtitle={`expirados`}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <CardStatsOficialia
              icon={'done_all'}
              iconColor={'#000F9F'}
              title={'Oficios'}
              //total={`${totalUnicosSegmento}`}
              total={`${
                parseInt(statsOficios.TotalOficios) - parseInt(statsOficios.TotalOficiosActivos)
              } de ${parseInt(statsOficios.TotalOficios)}`}
              subtitle={'concluidos'}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={4}>
            <CardStatsOficialia
              icon={'repeat'}
              iconColor={'#000F9F'}
              title={'En tránsito'}
              //total={`${totalUnicosSegmento}`}
              total={`${parseInt(statsOficios.EnTransito)}`}
              // subtitle={"Únicos de General"}
              loading={isLoading}
              onClick={() => navigate(`/oficialia-en-transito`)}
            />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FilterOficialia
                      showMore={showMore}
                      setFiltros={(e) => {
                        setParams({ ...params, page: 0, filtered: e });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {showMessage && (
                      <Box marginTop={2}>
                        <MessageAlert
                          showMessage={showMessage}
                          successDownload={successDownload}
                          setShowMessage={setShowMessage}
                        />
                      </Box>
                    )}
                    {isDownloading && <Download isDownload={isDownloading} format="xlsx" />}
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <FormControlLabel
                        control={
                          <Switch checked={showMore} onChange={(e) => handleConTermino(e.target.checked)} />
                        }
                        label="Sin seguimento"
                      />
                      <Box sx={{ marginLeft: 'auto' }}>
                        {' '}
                        {/* Esto empuja los botones a la derecha */}
                        {middleware.checkMenuActionId('Agregar') && (
                          <>
                            {isDownloading ? (
                              <></>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primaryDark"
                                startIcon={<Icon>add_box</Icon>}
                                size="small"
                                onClick={() => navigate('/oficialia-captura')}
                                sx={{ borderRadius: '10px', ml: 1 }} // Añade margen entre botones
                              >
                                Nuevo
                              </Button>
                            )}
                          </>
                        )}
                        {middleware.checkMenuActionId('Exportar') && (
                          <>
                            {isDownloading ? (
                              <></>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primaryDark"
                                startIcon={<Icon>download</Icon>}
                                size="small"
                                onClick={handleExport}
                                sx={{ borderRadius: '10px', ml: 1 }} // Añade margen entre botones
                              >
                                Exportar
                              </Button>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <DataGridTmp data={data} /> */}
                    <CustomTableOficios
                      rows={data}
                      columns={columns}
                      total={dataTotal}
                      page={params.page}
                      pageSize={params.pageSize}
                      isLoading={isLoading}
                      handlePagination={handlePagination}
                      handleSort={handleSort}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default OficiliaPartes;
