import { useState } from 'react';

// Material UI
import { Grid, Typography, Button, Icon } from '@mui/material';

// Componentes
import CustomTabs from '@components/Tabs/CustomTabs';

const CustomTabsExamples = () => {
  const [tabValue, setTabValue] = useState('0');

  const [color, setColor] = useState('primary');
  const [orientation, setOrientation] = useState('horizontal');
  const [disabled, setDisabled] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const [disableCentered, setDisableCentered] = useState(false);
  const [disableFullWidth, setDisableFullWidth] = useState(false);

  const handleColor = () => setColor(color === 'primary' ? 'secondary' : 'primary');
  const handleOrientation = () => setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal');
  const handleDisableScroll = () => setDisableScroll(!disableScroll);
  const handleDisableCentered = () => setDisableCentered(!disableCentered);
  const handleDisableFullWidth = () => setDisableFullWidth(!disableFullWidth);
  const handleDisabled = () => setDisabled(!disabled);

  return (
    <Grid container spacing={2} rowSpacing={5} sx={{ p: 1 }}>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleColor}>
          Color: {color}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleOrientation}>
          Orientación :{orientation}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleDisableScroll}>
          {disableScroll ? 'Agregar Scroll' : 'Quitar Scroll'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleDisableCentered}>
          {disableCentered ? 'Agregar Centrado' : 'Quitar Centrado'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleDisableFullWidth}>
          {disableFullWidth ? 'Agregar FullWidth' : 'Quitar FullWidth'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant="outlined" onClick={handleDisabled}>
          {disabled ? 'Habilitar' : 'Deshabilitar'}
        </Button>
      </Grid>

      {/* Tabs iconos */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tab iconos
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTabs
          value={tabValue}
          setValue={setTabValue}
          tabs={[
            {
              icon: <Icon>settings</Icon>,
              label: 'Tab 1',
              value: '0',
              component: <>Componente 1</>,
              disabled: disabled,
            },
            {
              icon: 'people',
              iconPosition: 'bottom',
              label: 'Tab 2',
              value: '1',
              component: <>Componente 2</>,
              disabled: disabled,
            },
            { label: 'Tab 3', value: '2', component: <>Componente 3</>, disabled: disabled },
          ]}
          color={color}
          disableCentered={disableCentered}
          disableScroll={disableScroll}
          disableFullWidth={disableFullWidth}
          orientation={orientation}
        />
      </Grid>
      {/* Tabs secundario */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tab SX
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTabs
          value={tabValue}
          setValue={setTabValue}
          tabs={[
            {
              icon: <Icon>settings</Icon>,
              iconPosition: 'end',
              label: 'Tab 1',
              value: '0',
              component: <>Componente 1</>,
              sxTab: { backgroundColor: '#5d2350' },
              sxContent: { backgroundColor: '#4cb74f', p: 2 },
              disabled: disabled,
            },
            {
              icon: 'people',
              iconPosition: 'top',
              label: 'Tab 2',
              value: '1',
              component: <>Componente 2</>,
              sxContent: { textAlign: 'center' },
              disabled: disabled,
            },
            { icon: 'delete', label: 'Tab 3', value: '2', component: <>Componente 3</>, disabled: disabled },
          ]}
          color={color}
          disableCentered={disableCentered}
          disableScroll={disableScroll}
          disableFullWidth={disableFullWidth}
          orientation={orientation}
        />
      </Grid>
    </Grid>
  );
};

export default CustomTabsExamples;
