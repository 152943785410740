import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  Select,
  MenuItem,
  TableSortLabel,
  Box,
  Card,
  Icon,
  Typography,
} from '@mui/material';
import { CircularProgress } from '@mui/material';

const StickyTable = ({ columns, rows, pageSize, page, loading }) => {
  const [pageState, setPageState] = useState(page);
  const [data, setData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('asc');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = data.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  const handleChangeData = (id, key, value) => {
    if (key === 'EstatusContacto') {
      const updatedData = data.map((row) =>
        row.id === id
          ? {
              ...row,
              ContactoEstatus: value,
              EstatusMedio: value !== 2 ? 0 : row.EstatusMedio,
            }
          : row
      );
      setData(updatedData);
    } else if (key === 'MedioContacto') {
      const updatedData = data.map((row) => (row.id === id ? { ...row, EstatusMedio: value } : row));
      setData(updatedData);
    } else {
      const updatedData = data.map((row) => (row.id === id ? { ...row, [key]: value } : row));
      setData(updatedData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageState(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageState(0);
  };

  const getStripedStyle = (index, row) => {
    return row.style && row.style.background ? row.style : { background: index % 2 ? '#E4ECFA' : 'white' };
  };

  const renderSelect = (id, column, rowData, options) => {
    let select = [];
    let isDisabled = false;

    const rowArray = [rowData];
    const contactoEstatusArray = rowArray.map((item) => item.ContactoEstatus);
    const estatusMedioArray = rowArray.map((item) => item.EstatusMedio);

    if (column.id === 'EstatusContacto') {
      select = contactoEstatusArray;
    } else {
      select = estatusMedioArray;
      isDisabled = contactoEstatusArray[0] !== 2;
    }

    return (
      <Select
        value={select}
        size="small"
        onChange={(event) => handleChangeData(id, column.id, event.target.value)}
        disabled={isDisabled}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderCellContent = (row, column) => {
    if (column.select) {
      return (
        <FormControl variant="standard" size="small" fullWidth>
          {renderSelect(row.id, column, row, column.options)}
        </FormControl>
      );
    } else {
      return row[column.id];
    }
  };

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      <Card sx={{ width: '100%', mb: 2, p: 2 }} variant="outlined" className="card-secondary">
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead sx={{ backgroundColor: 'rgba(189, 189, 189, 0.3)' }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align ? column.align : 'left'}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === column.id ? order : false}
                    width={column.width}
                    sx={{ backgroundColor: 'rgba(189, 189, 189, 0.3)' }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                      <CircularProgress size={50} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : sortedData.length > 0 ? (
                sortedData
                  .slice(pageState * rowsPerPage, pageState * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align ? column.align : 'left'}
                          sx={{ ...getStripedStyle(index, row) }}
                        >
                          {renderCellContent(row, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Icon fontSize={'large'}>info</Icon>
                    <Typography>Sin Registros</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data.length}
          page={pageState}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

export default StickyTable;
