import { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import Map from '@components/Maps/CustomComponents/GenericMap';
import Filter from '@components/MapsModules/Prep/FilterTabs';
import Colors from '@components/Maps/InternalComponents/Cards/Colors';
import TableInfo from '@components/Maps/InternalComponents/Cards/TableInfo';
import CardInfoMini from '@components/Maps/InternalComponents/Cards/CardInfoMini';

// Servicios y Utils
import CatalogServices from '@services/CatalogServices';
import PrepServices from '@services/MapServices/PrepServices';
import { setVars, getVars, deleteVars } from '@utils/global';
import { ELECCION_GOBERNADOR } from '@data/constants/Prep';

// Estilos de mapas
import { style_original_data, style_original_sub_data } from '@components/Maps/Auxiliars/ColorFeature';

const commonElements = [
  { title: 'Total de Votos: ', name: 'TotalVotos', type: 'int' },
  { title: '1ra Fuerza: ', name: 'PrimeraFuerza', type: 'text' },
  { title: 'Votos 1ra Fuerza: ', name: 'VotosPrimeraFuerza', type: 'int' },
  { title: '2da Fuerza: ', name: 'SegundaFuerza', type: 'text' },
  { title: 'Votos 2da Fuerza: ', name: 'VotosSegundaFuerza', type: 'int' },
  { title: '3ra Fuerza: ', name: 'TerceraFuerza', type: 'text' },
  { title: 'Votos 3ra Fuerza: ', name: 'VotosTerceraFuerza', type: 'int' },
];

const elements = {
  entidad: [{ title: 'Entidad: ', name: 'entidad', type: 'text' }, ...commonElements],
  region: [{ title: 'Region: ', name: 'region', type: 'text' }, ...commonElements],
  municipio: [{ title: 'Municipio: ', name: 'municipio', type: 'text' }, ...commonElements],
  dfed: [{ title: 'DFED: ', name: 'DistritoFederal', type: 'text' }, ...commonElements],
  dloc: [{ title: 'DLOC: ', name: 'DistritoLocal', type: 'text' }, ...commonElements],
  seccion: [{ title: 'Sección: ', name: 'Seccion', type: 'text' }, ...commonElements],
};

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: 'FeatureCollection', features: [] });

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState({ municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState('entidad');

  const [colorPercent, setColorPercent] = useState([]);

  const getEmptyMap = () => {
    setTable(null);
    setCurrentFeature(null);
    setPolygons({ type: 'FeatureCollection', features: [] });
  };

  const handleFilterExect = async (filter = [], shp = 'entidad') => {
    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { page: 0, pageSize: 10, filtered: filter, shp };

      const result = await PrepServices.getShapes(params);
      const { results, response, message } = result;

      if (results) {
        const features = response?.data?.features?.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  const loadCatalogs = useCallback(async () => {
    const params = [
      { id: 'tipo_eleccion', getAll: false },
      { id: 'municipios_reportes', getAll: false },
      { id: 'df', getAll: false },
      { id: 'dl', getAll: false },
      { id: 'regiones', getAll: false },
      { id: 'secciones', getAll: false },
    ];

    setIsLoadingCatalogs(true);

    try {
      const result = await CatalogServices.getCatalogs(params);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: 'Algunos catálogos NO pudieron ser cargados. Contacte al administrador',
            icon: 'warning',
          });
        }

        const { municipios_reportes, df, dl, regiones, tipo_eleccion, secciones } = response.catalogs;
        const options = (value, label) => (value ? [{ value: 0, label: `TOD${label}S` }].concat(value) : []);

        const avance = [
          { value: 1, label: '0% - 25%' },
          { value: 2, label: '25.01% - 50%' },
          { value: 3, label: '50.01% - 75%' },
          { value: 4, label: '75.01% - 100%' },
        ];

        const all = {
          tipo_eleccion,
          municipios_reportes: options(municipios_reportes, 'O'),
          municipios_df: options(df, 'O'),
          municipios_dl: options(dl, 'O'),
          regiones: options(regiones, 'A'),
          secciones: options(secciones, 'A'),
          avance: options(avance, 'O'),
        };

        setCatalogs(all);
        setVars('allCatalogsMap', all);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  const loadColors = async () => {
    try {
      const result = await PrepServices.getColoresPartidos({});
      const { results, response, message } = result;

      if (results) {
        setColorPercent(response.data.local);
        setVars('allColorsMap', response.data.local);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    const prepMapFlag = getVars('prepMapFlag', '');

    if (prepMapFlag === '') {
      deleteVars();

      setVars('filterSavedMap', {
        idTipoEleccion: '',
        idRegion: '',
        idMunicipio: '',
        idDF: '',
        idDL: '',
        Seccion: '',
        porAvance: '',
      });
      setVars('mapCatalogMunicipio', '');
      getEmptyMap();
      loadCatalogs();
      loadColors();
      setVars('prepMapFlag', true);
      handleFilterExect([]);
    } else {
      const shpMap = getVars('filteredShapeMap', {
        filtered: [],
        tab: getVars('valueTab', 0),
        shp: 'entidad',
      });
      const catalogs = getVars('allCatalogsMap', '');
      const allColorsMap = getVars('allColorsMap', '');
      setCatalogs(catalogs);
      setColorPercent(allColorsMap);
      handleFilterExect(shpMap.filtered, shpMap.shp);
    }

    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter
        catalogs={catalogs}
        loadingCatalogs={isLoadingCatalogs}
        handleFilter={handleFilterExect}
        electionType
        region
        section
      />
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={elements[shpInfoGeneral]}
          title={'INFORMACIÓN'}
          toolTip
        />
      )}
      {table && <TableInfo properties={table} elements={elements[shpInfoGeneral]} />}
    </>
  );

  const handleClickViewDetail = async (e) => {
    // Mandar a la capa inferior con el parametro solicitado
    const tab = getVars('valueTab', 0);

    let filterValues = getVars('filterSavedMap', {
      idTipoEleccion: ELECCION_GOBERNADOR,
      idRegion: [],
      idMunicipio: [],
      idDF: [],
      idDL: [],
      Seccion: [],
      porAvance: 0,
    });
    const shapeMap = getVars('filteredShapeMap', {
      filtered: [],
      tab: 0,
      shp: 'entidad',
    });

    let shp = 'entidad';
    let id = 'idMunicipio';
    let filter = [];

    let values;

    switch (e.shp) {
      case 'entidad':
        if (tab === 0) {
          shp = 'region';
          id = 'idRegion';
        }
        if (tab === 1) {
          shp = 'dfed';
          id = 'idDF';
        }
        if (tab === 2) {
          shp = 'dloc';
          id = 'idDL';
        }
        break;

      case 'region':
        shp = 'municipio';
        id = 'idMunicipio';
        filterValues.idRegion = [e.idRegion];
        const catMunicipios = catalogs.municipios_reportes.filter(
          (item) => item.idRegion === e.idRegion || item.value === 0
        );
        values = catMunicipios.map((item) => item.value).filter((item) => item !== 0);

        setVars('mapCatalogMunicipio', catMunicipios);
        filter.push({ id: 'tb.id', filter: 'IN', value: values });
        break;

      case 'dfed':
        shp = 'seccion';
        id = 'Seccion';
        filterValues.idDF = [e.idDF];
        const catDFSecciones = catalogs.secciones.filter((item) => item.idDF === e.idDF || item.value === 0);
        values = catDFSecciones.map((item) => item.value).filter((item) => item !== 0);

        setVars('mapCatalogSeccion', catDFSecciones);
        filter.push({ id: 'tb.Seccion', filter: 'IN', value: values });
        break;

      case 'dloc':
        shp = 'seccion';
        id = 'Seccion';
        filterValues.idDF = [e.idDL];
        const catDLSecciones = catalogs.secciones.filter((item) => item.idDL === e.idDL || item.value === 0);
        values = catDLSecciones.map((item) => item.value).filter((item) => item !== 0);

        setVars('mapCatalogSeccion', catDLSecciones);
        filter.push({ id: 'tb.Seccion', filter: 'IN', value: values });
        break;

      case 'municipio':
      default:
        shp = 'seccion';
        id = 'Seccion';
        filterValues.idMunicipio = [e.idMunicipioReportes];
        const catMunSecciones = catalogs.secciones.filter(
          (item) => item.idMunicipioReportes === e.idMunicipioReportes || item.value === 0
        );
        values = catMunSecciones.map((item) => item.value).filter((item) => item !== 0);

        setVars('mapCatalogSeccion', catMunSecciones);
        filter.push({ id: 'tb.Seccion', filter: 'IN', value: values });
        break;
    }

    setVars('filterSavedMap', {
      ...filterValues,
      [id]: [0],
    });
    setVars('filteredShapeMap', {
      filtered: filter,
      tab: shapeMap.tab,
      shp: shp,
    });

    setShpInfoGeneral(shp);

    try {
      setPolygons(null);
      const params = { page: 0, pageSize: 10, filtered: filter, shp };

      const result = await PrepServices.getShapes(params);
      const { results, response, message } = result;

      if (results) {
        const features = response.data.features.map((item) => item.properties);
        setTable(features);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);
    if (!properties[properties.shp]) resetHighlight(e);

    setCurrentFeature(properties);
    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties[properties.shp]) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_data(layer.feature, true));
    }
  };

  const cardComponent = {
    component: <Colors elements={colorPercent} height={20} spacing={0.5} />,
    title: 'Partidos',
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350, lg: 400 } }}
        cardInfo={{
          initialValues: { title: 'INFORMACIÓN', elements: elements[shpInfoGeneral] },
          button: { handleClick: handleClickViewDetail, invalidShp: ['seccion'] },
        }}
        cardComponent={colorPercent?.length ? cardComponent : null}
        highlightFeature={highlightFeature}
        centerByFeatures
        styleProp={{
          data: style_original_data,
          subData: style_original_sub_data,
          onEachFeature: style_original_data,
          highlightFeature: style_original_data,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
