import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Grid,
} from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/Android';
import IPhoneIcon from '@mui/icons-material/PhoneIphone';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import UserUpdateContext from './UserUpdateContext';

const UserDevices = (props) => {
  const { idUsuario, API, APIDELETE } = props;
  const { setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm } = useContext(UserUpdateContext);

  const fontHeader = { fontWeight: 'bold', mb: 2, fontSize: 18, textAlign: 'center' };
  const [dispositivos, setDispositivos] = useState([]);

  const loadDispositivos = async () => {
    if (!idUsuario) {
      return;
    }

    await API(idUsuario)
      .then((response) => {
        if (response.status === 200) {
          setDispositivos(response.response.data);
        } else {
          Swal.fire('Error', 'Hubo un error al cargar los dispositivos.', 'error');
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Hubo un error al cargar los dispositivos.', 'error');
      });
  };

  useEffect(() => {
    loadDispositivos();
    if (!idUsuario) {
      setDispositivos([]);
    }
    // eslint-disable-next-line
  }, [idUsuario]);

  const handleDesvincularClick = async (dispositivoId, dispositivoNombre) => {
    const desvincularData = {
      Device: dispositivoId,
      idUsuario: idUsuario,
    };

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres desvincular el dispositivo "${dispositivoNombre}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, desvincular',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff0000',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (result.isConfirmed) {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      try {
        const res = await APIDELETE(desvincularData);
        const { results, message } = res;

        if (results) {
          Swal.fire({
            title: message,
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
          }).then((res) => {
            if (res) {
              loadDispositivos();
            }
          });
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
          setIsSuccessForm(false);
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
        setIsSuccessForm(false);
      } finally {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      }
    }
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" sx={fontHeader}>
            Dispositivos Registrados
          </Typography>
          <List>
            {Array.isArray(dispositivos) && dispositivos.length > 0 ? (
              dispositivos.map((dispositivo) => {
                const isDispositivoValido = dispositivo.DeviceID && dispositivo.DeviceID.length > 0;
                return (
                  <ListItem key={dispositivo.Device}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={2} sm={1}>
                        <ListItemIcon>
                          {dispositivo.DeviceOS === 'android' ? (
                            <PhoneAndroidIcon sx={{ color: 'green' }} />
                          ) : dispositivo.DeviceOS === 'ios' ||
                            dispositivo.DeviceOS === 'IOS' ||
                            dispositivo.DeviceOS === 'Apple' ? (
                            <IPhoneIcon sx={{ color: 'navy' }} />
                          ) : (
                            <Box sx={{ color: 'rgba(255, 0, 0, 0.7)', fontSize: '28px' }}>
                              <InfoIcon />
                            </Box>
                          )}
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={10} sm={7}>
                        <ListItemText
                          primary={dispositivo.DeviceOS || 'No especificado'}
                          secondary={dispositivo.DeviceID || 'No especificado'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDesvincularClick(dispositivo.Device, dispositivo.DeviceOS)}
                          disabled={!isDispositivoValido}
                        >
                          Desvincular
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })
            ) : (
              <ListItem>
                <ListItemIcon>
                  <Box sx={{ color: 'rgba(255, 0, 0, 0.7)', fontSize: '28px' }}>
                    <InfoIcon />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="No hay dispositivos registrados" />
              </ListItem>
            )}
          </List>
          <Typography variant="body2" color="textSecondary" mt={2} ml={2}>
            Mostrando {dispositivos.length} dispositivos registrados
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserDevices;
