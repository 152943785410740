import { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

//MUI
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

//Compoents
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import { Button } from '@mui/material';

const Filter = ({ handleSearch, handleClear }) => {
  const catalogsOptions = [
    { id: 'df', getAll: false },
    { id: 'dl', getAll: false },
    { id: 'municipios_reportes', getAll: false },
    { id: 'municipios_dl', getAll: false },
    { id: 'municipios_df', getAll: false },
    { id: 'secciones', getAll: false },
  ];
  const defaultOption = { value: 0, label: 'TODOS' };

  const [catalogsFiltered, setCatalogsFiltered] = useState({});
  const [filterData, setFilterData] = useState({
    Df: 0,
    Dl: 0,
    Municipio: 0,
    Seccion: 0,
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: true,
  });

  const handleCatalogFilterChange = ({ cat_key, filterKey, filterValue, filterType }) => {
    if (filterType) {
      const _filteredData = [defaultOption].concat(
        catalogs[filterKey].filter((item) => item[filterType] === filterValue)
      );

      setCatalogsFiltered((prevState) => ({
        ...prevState,
        [cat_key]: _filteredData,
      }));
    } else {
      setCatalogsFiltered((prevState) => ({
        ...prevState,
        [cat_key]: catalogs[filterKey],
      }));
    }
  };

  const handleChangeFilter = ({ name, value }) => setFilterData({ ...filterData, [name]: value });

  const handleChangeDf = (e) => {
    const idDf = e.value;

    if (idDf > 0) {
      handleCatalogFilterChange({
        cat_key: 'municipios_reportes',
        filterKey: 'municipios_df',
        filterType: 'idDF',
        filterValue: idDf,
      });
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: 'idDF',
        filterValue: idDf,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      handleCatalogFilterChange({
        cat_key: 'municipios_reportes',
        filterKey: 'municipios_reportes',
        filterType: null,
        filterValue: null,
      });
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: null,
        filterValue: null,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeDl = (e) => {
    const idDl = e.value;

    if (idDl > 0) {
      handleCatalogFilterChange({
        cat_key: 'municipios_reportes',
        filterKey: 'municipios_dl',
        filterType: 'idMunicipio',
        filterValue: idDl,
      });
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: 'idDL',
        filterValue: idDl,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      handleCatalogFilterChange({
        cat_key: 'municipios_reportes',
        filterKey: 'municipios_reportes',
        filterType: null,
        filterValue: null,
      });
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: null,
        filterValue: null,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.value;

    if (idMunicipality > 0) {
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: 'idMunicipioReportes',
        filterValue: idMunicipality,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    } else {
      handleCatalogFilterChange({
        cat_key: 'secciones',
        filterKey: 'secciones',
        filterType: null,
        filterValue: null,
      });

      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    }
  };

  const handleChangeSearch = (e) => {
    const filters = [
      {
        id: 'idDF',
        key: 'Df',
      },
      {
        id: 'idDL',
        key: 'Dl',
      },
      {
        id: 'idMunicipioReportes',
        key: 'Municipio',
      },

      {
        id: 'Seccion',
        key: 'Seccion',
      },
    ];

    const filtered = filters
      .filter((filter) => filterData[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: '=',
        value: filterData[filter.key],
      }));

    handleSearch({ filters: filtered });
  };

  const handleChangeClear = () => {
    setFilterData({
      Df: 0,
      Dl: 0,
      Municipio: 0,
      Seccion: 0,
    });
    setCatalogsFiltered(catalogs);
    handleClear();
  };

  useEffect(() => {
    if (!load) setCatalogsFiltered(catalogs);
  }, [load]);

  return (
    <Grid2 container spacing={2} marginBottom={1}>
      <Grid2 xs={12} md={3} lg={3}>
        <AdvancedSelect
          name="Df"
          label="Distrito Federal"
          options={catalogsFiltered.df}
          value={catalogsFiltered.df?.length === 1 ? catalogsFiltered.df[0].value : filterData.Df}
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
            handleChangeDf(e);
          }}
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.df?.length <= 1}
          sx={{ width: '100%' }}
        />
      </Grid2>
      <Grid2 xs={12} md={2} lg={2}>
        <AdvancedSelect
          name="Dl"
          label="Distrito Local"
          options={catalogsFiltered.dl}
          value={catalogsFiltered.dl?.length === 1 ? catalogsFiltered.dl[0].value : filterData.Dl}
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
            handleChangeDl(e);
          }}
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.dl?.length <= 1}
          sx={{ width: '100%' }}
        />
      </Grid2>
      <Grid2 xs={12} md={3} lg={3}>
        <AdvancedSelect
          name="Municipio"
          label="Municipio"
          options={catalogsFiltered.municipios_reportes}
          value={
            catalogsFiltered.municipios_reportes?.length === 1
              ? catalogsFiltered.municipios_reportes[0].value
              : filterData.Municipio
          }
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
            handleChangeMunicipality(e);
          }}
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.municipios_reportes?.length <= 1}
          sx={{ width: '100%' }}
        />
      </Grid2>
      <Grid2 xs={12} md={2} lg={2}>
        <AdvancedSelect
          name="Seccion"
          label="Sección"
          options={catalogsFiltered.secciones}
          value={
            catalogsFiltered.secciones?.length === 1
              ? catalogsFiltered.secciones[0].value
              : filterData.Seccion
          }
          onChange={(e) => {
            handleChangeFilter({ name: e.name, value: e.value });
          }}
          isSearchable
          isLoading={load}
          disabled={catalogsFiltered.secciones?.length <= 1}
          sx={{ width: '100%' }}
        />
      </Grid2>
      <Grid2 xs={12} md={2} lg={2} sx={{ display: 'flex', gap: 1 }}>
        <Button variant="contained" color="primaryDark" size="small" onClick={handleChangeSearch}>
          Filtrar
        </Button>
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleChangeClear}>
          Limpiar
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default Filter;
