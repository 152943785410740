// Importa los componentes de MUI y React
import { Box, Card, CardContent, CardHeader, Typography, LinearProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState, useEffect, useRef } from 'react';
import TrackingService from '@services/TrackingServices';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Define el componente Redirect
const Redirect = () => {
  const { id } = useParams();
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(2);
  const [loagindg, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(5);
      } else {
        const diff = Math.random() * 5;
        const diff2 = Math.random() * 5;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    TrackingService.getTracking({ uuid: id })
      .then((res) => {
        if (res.results) {
          window.location.href = `${res.response.data.Redireccion}`;
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <Grid2
      container
      justifyContent="center"
      alignItems="center"
      height="100vh"
      style={{
        backgroundColor: '#f0f0f0',
        maxWidth: '100%',
      }}
    >
      <Grid2 item>
        <Card sx={{ width: '200%' }}>
          <CardHeader
            title={
              <Typography variant="h5" style={{ fontFamily: 'cursive', color: '#2196F3' }}>
                Redireccionando
              </Typography>
            }
          />
          <CardContent>
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={buffer}
              sx={{
                height: 20,
                borderRadius: 10,
                backgroundColor: '#fffff',
                '& .MuiLinearProgress-barBuffer': {
                  backgroundColor: '#0011BB',
                },
              }}
            />
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default Redirect;
