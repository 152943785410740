import ApiExec, { downloadFile, downloadFilePreview /*ApiExecPublic*/ } from '@utils/ApiExec';
import { encryptVars, DEBUG } from '@utils/global';
//import { handleAttrs } from "@utils/Utilities";

class MovilizationServices {
  validateSection = (Seccion) => {
    const section = DEBUG ? Seccion : encryptVars(Seccion);
    return ApiExec({}, `movilization/validate-section/${section}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBingosList = (params) => {
    return ApiExec(params, 'movilization/get-bingos-list', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilization = (params) => {
    return ApiExec(params, 'movilization/get-attendance', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedListByView = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-list-by-view', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartByView = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-by-view', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedByScheduleChart = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-by-schedule-chart', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setMovilization = (params) => {
    return ApiExec(params, 'movilization/set-attendance', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  saveMobilizedBySchedule = (params) => {
    return ApiExec(params, 'movilization/save-mobilized-by-schedule', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteBingo = (params) => {
    return ApiExec(params, 'bingos/delete-bingo', 'DELETE')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getSchedules = (params) => {
    return ApiExec(params, 'movilization/get-schedules', 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDetailMobilizedByHour = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-by-schedule', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  resetMobilizedBySchedule = (params) => {
    return ApiExec(params, 'movilization/reset-mobilized-by-schedule', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardsTerritorial = (params) => {
    return ApiExec(params, 'movilization/get-cards-territorial', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartTerritorialCumulative = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-territorial-cumulative', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartTerritorial = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-territorial', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartTerritorialBySchedule = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-territorial-by-schedule', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovAdvanceTerritorialDetails = (params) => {
    return ApiExec(params, 'movilization/get-mov-advance-territorial-details', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportMovAdvanceTerritorialDetails = (params) => {
    return downloadFile({
      api: 'movilization/export-mov-advance-territorial-details',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportMovAdvanceElectoralDetails = (params) => {
    return downloadFile({
      api: 'movilization/export-mov-advance-electoral-details',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAmbitoAgrupadorMovilizacion = (params) => {
    return ApiExec(params, 'movilization/get-ambito-agrupador-movilizacion', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilizedChartTerritorialDesiredProgress = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-territorial-desired-progress', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilizedChartElectoralDesiredProgress = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-electoral-desired-progress', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getPaseListaChart = (params) => {
    return ApiExec(params, 'movilization/get-pase-lista-chart', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosMovElectoral = (params) => {
    return ApiExec(params, 'bingos/get-bingos-name', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  saveMovilizacionElectoral = (params) => {
    return ApiExec(params, 'movilization/save-movilizacion-electoral', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getSummaryAttendance = (params) => {
    return ApiExec(params, 'movilization/get-summary-attendance', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListResponsibles = (params) => {
    return ApiExec(params, 'movilization/get-list-responsibles', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovAdvanceTerritorialReport = (params) => {
    return ApiExec(params, 'movilization/get-mov-advance-territorial-report', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListMobilizers = (params) => {
    return ApiExec(params, 'movilization/get-list-mobilizers', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovAdvanceElectoralDetails = (params) => {
    return ApiExec(params, 'movilization/get-mov-advance-electoral-details', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosMovElectoralChart = (params) => {
    return ApiExec(params, 'movilization/get-compromisos-mov-electoral-chart', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardsElectoral = (params) => {
    return ApiExec(params, 'movilization/get-cards-electoral', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartElectoralCumulative = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-chart-electoral-cumulative', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosBingosChart = (params) => {
    return ApiExec(params, 'movilization/get-compromisos-bingos-chart', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProfiles = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `movilization/get-profiles/${_id}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizationLimit = (params) => {
    return ApiExec(params, 'movilization/get-mobilization-limit', 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportDocuments = ({ params, api }) => {
    return downloadFile({
      api: `movilization/${api}`,
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  previewDocument = (params) => {
    return downloadFilePreview({
      data: params,
      api: 'movilization/generate-mobilization-list',
      method: 'POST',
    })
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getPaseListaTable = (params) => {
    return ApiExec(params, 'movilization/get-pase-lista-table', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedByScheduleHist = (params) => {
    return ApiExec(params, 'movilization/get-mobilized-by-schedule-hist', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovSummary = (params) => {
    return ApiExec(params, 'movilization/get-mov-summary', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportMovSummary = (params) => {
    return downloadFile({
      api: 'movilization/export-mov-summary',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  /* Simulacro */

  getMobilizersList = (params) => {
    return ApiExec(params, 'movilization/simulacro/get-mobilizers-list', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getSummaryMobilizers = (params) => {
    return ApiExec(params, 'movilization/simulacro/get-summary-mobilizers', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  saveMobilizedSimulacrum = (params) => {
    return ApiExec(params, 'movilization/simulacro/save-mobilized-simulacrum', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportDocumentsSimulacro = ({ params }) => {
    return downloadFile({
      api: `movilization/simulacro/export-mobilizers-list`,
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  exportPendingMovilization = (params) => {
    return downloadFile({
      api: 'movilization/generate-pending-mobilization-list',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  /* Bingos */
  verifyStatus = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `bingos/verify-status/${_id}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosBingosList = (params) => {
    return ApiExec(params, 'bingos/get-compromisos-bingos-list', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setParamsBingo = (params) => {
    return ApiExec(params, 'bingos/set-bingo', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setBingoNumeration = (params) => {
    return ApiExec(params, 'bingos/set-bingo-numeration', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateStatusBingo = (params) => {
    return ApiExec(params, 'bingos/update-status-bingo', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateName = (params) => {
    return ApiExec(params, 'bingos/update-name', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressList = (params) => {
    return ApiExec(params, 'bingos/get-progress-list-details', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getProgressGraph = (params) => {
    return ApiExec(params, 'bingos/get-progress-graph', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBookletProgress = (params) => {
    return ApiExec(params, 'bingos/get-booklets-advance', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCatalogsForStats = (catalogs) => {
    return ApiExec({ catalogs: catalogs }, 'bingos/get-catalogs-for-stats', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updateNotFound = (params) => {
    return ApiExec(params, 'bingos/update-not-found', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getCapturistAdvanceToday = (params) => {
    return ApiExec(params, 'bingos/get-capturist-advance-today', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCapturistAdvanceAcumulativo = (params) => {
    return ApiExec(params, 'bingos/get-capturist-advance', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatesNotFound = (params) => {
    return ApiExec(params, 'bingos/get-stats-not-found', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportProgressListDetails = (params) => {
    return downloadFile({
      api: 'bingos/export-progress-list-details',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  //Módulo Consulta Usuario
  getStatsByUser = (params) => {
    return ApiExec(params, 'bingos/get-stats-by-user', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getStatsByUserDetails = (params) => {
    return ApiExec(params, 'bingos/get-stats-by-user-details', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  exportStatsByUser = (params) => {
    return downloadFile({
      api: 'bingos/export-stats-by-user',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportStatsByUserDetails = (params) => {
    return downloadFile({
      api: 'bingos/export-stats-by-user-details',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteAssignedFolio = (params) => {
    return ApiExec(params, 'bingos/delete-assigned-folio', 'DELETE')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getGralStat = (params) => {
    return ApiExec(params, 'bingos/get-gral-stat', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  exportGralStat = (params) => {
    return downloadFile({
      api: 'bingos/export-gral-stat',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserStat = (params) => {
    return ApiExec(params, 'bingos/get-user-stat', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  exportUserStat = (params) => {
    return downloadFile({
      api: 'bingos/export-user-stat',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const movilizacion = new MovilizationServices();
export default movilizacion;
