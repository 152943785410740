import { Badge, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
import GaugeChart from '@components/Charts/GaugeChart';
import useWindowDimensions from '@hooks/useWindowDimensions';
import sije from '@services/SijeService';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { blue, indigo } from '@mui/material/colors';

const CardCloseCasilla = ({ loading }) => {
  const { height } = useWindowDimensions();
  const [dataChart, setDataChart] = useState({
    series: [],
  });
  const [dataTotales, setDataTotales] = useState({
    casilla: { total: null, TotalCasilla: null },
    votacion: { total: null, TotalVotacion: null },
  });
  const [loadingChart, setLoadingChart] = useState(false);

  const getCardCierreCasilla = async () => {
    try {
      setLoadingChart(true);
      const result = await sije.getCardCierreCasilla({});
      const { response, message, results } = await result;
      if (results) {
        setDataChart({
          series: [
            {
              name: 'Cierre Casilla',
              data: [
                {
                  color: blue[800],
                  radius: '112%',
                  innerRadius: '88%',
                  y: parseInt(response.CierreCasilla.data[0].Porcentaje),
                },
              ],
            },
            {
              name: 'Cierre Votación',
              data: [
                {
                  color: indigo[800],
                  radius: '87%',
                  innerRadius: '65%',
                  y: parseInt(response.CierreVotacion.data[0].Porcentaje),
                },
              ],
            },
          ],
        });
        setDataTotales({
          casilla: {
            total: response.CierreCasilla.data[0].Total,
            TotalCasilla: response.CierreCasilla.data[0].totalCierreCasilla,
          },
          votacion: {
            total: response.CierreVotacion.data[0].Total,
            TotalVotacion: response.CierreVotacion.data[0].totalCierreVotacion,
          },
        });
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    getCardCierreCasilla();
  }, [loading]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography variant="body2" fontWeight={600}>
          CASILLAS
        </Typography>
        <GaugeChart options={{ title: '', height: height * 0.28, plotHeight: 3 }} series={dataChart.series} />

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: blue[800],
                  width: 14,
                  height: 14,
                },
              }}
              variant="dot"
            />
            <Typography variant="body2" fontWeight={400}>
              Cierre Casilla
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={0} alignItems={'center'}>
            {loadingChart ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <Typography variant="body2" fontWeight={600}>
                  {dataTotales.casilla.TotalCasilla}
                </Typography>
                /
                <Typography variant="body2" fontWeight={600}>
                  {dataTotales.casilla.total}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: indigo[800],
                  width: 14,
                  height: 14,
                },
              }}
              variant="dot"
            />
            <Typography variant="body2" fontWeight={400}>
              Cierre Votación
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={0} alignItems={'center'}>
            {loadingChart ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <Typography variant="body2" fontWeight={600}>
                  {dataTotales.votacion.TotalVotacion}
                </Typography>
                /
                <Typography variant="body2" fontWeight={600}>
                  {dataTotales.votacion.total}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardCloseCasilla;
