import { ApiExec, ApiExecPublic, ApiExecDecrypted } from '@utils/ApiExec';

class PublicacionesServices {
  getPublicaciones = (data) => ApiExec(data, 'foca/get-publicaciones', 'POST');

  setPublicacion = (data) => ApiExecDecrypted(data, 'foca/set-publicacion', 'POST', false);

  updatePublicacion = (data) => ApiExecDecrypted(data, 'foca/update-publicacion', 'POST', false);

  deletePublicacion = (data) => ApiExec(data, 'foca/delete-publicacion', 'POST');

  getPublicFile = (params) => ApiExecPublic(params, 'app/foca/public-get-file', 'POST');
}

const service = new PublicacionesServices();
export default service;
