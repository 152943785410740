import { Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import BasicLine from '@components/Charts/BasicLine';

const ChartDesiredProgress = ({ desired, loadingModal }) => {
  const [data, setData] = useState({
    series: [],
    categories: [],
    title: '',
  });

  useEffect(() => {
    setData({
      series: desired.series,
      categories: desired.categories,
      title: desired.title,
    });
  }, [desired]);

  return (
    <Card className="card-primary">
      <CardContent>
        {loadingModal ? (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <CircularProgress />
            <Typography sx={{ fontWeight: 600, variant: 'subtitle2', marginY: 1 }}>Cargando...</Typography>
          </Box>
        ) : data.series && data.categories && data.series.length > 0 && data.categories.length > 0 ? (
          <BasicLine
            title={{
              primary: data.title,
            }}
            legend={{
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            }}
            series={data.series}
            categories={data.categories}
            initialPosition
          />
        ) : (
          <Typography>No hay datos disponibles</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ChartDesiredProgress;
