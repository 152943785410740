// Material UI
import { Grid, Box } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';

// Utilidades
import { isEmptyOrNullObject } from '@utils/validations';

const SectionSelected = (props) => {
  const { section, mobilizers } = props;

  const columnsResponsables = [
    { id: 'label', label: 'Responsables', type: 'text' },
    { id: 'total', label: 'Total', type: 'number', align: 'right' },
  ];

  const columnsCompromisos = [
    { id: 'label', label: 'Compromisos', type: 'text' },
    { id: 'total', label: 'Total', type: 'number', align: 'right' },
  ];

  const totalResponsables = mobilizers?.totalsResponsables?.reduce((result, item) => result + item.total, 0);
  const totalCompromisos = mobilizers?.totalsCompromisos?.reduce((result, item) => result + item.total, 0);

  const summaryResponsables = [0, { value: totalResponsables, align: 'right' }];
  const summaryCompromisos = [0, { value: totalCompromisos, align: 'right' }];

  return (
    <Box sx={{ px: { xs: 2, sm: 0 } }}>
      {section > 0 && !isEmptyOrNullObject(mobilizers) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTable
              rows={mobilizers?.totalsResponsables ?? []}
              columns={columnsResponsables}
              summary={summaryResponsables}
              maxHeight={250}
              disableCardType
              stickyHeader
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomTable
              rows={mobilizers?.totalsCompromisos ?? []}
              columns={columnsCompromisos}
              summary={summaryCompromisos}
              maxHeight={250}
              disableCardType
              stickyHeader
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SectionSelected;
