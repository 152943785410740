import { useState, useEffect } from 'react';

// Material UI
import {
  Checkbox,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  LinearProgress,
  Button,
  Icon,
  Paper,
  InputBase,
  IconButton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ExpandMore } from '@mui/icons-material';

import InfiniteScroll from 'react-infinite-scroll-component';

const Seleccion = (props) => {
  const {
    initialData,
    prevData,
    setNewData,
    handleSearch,
    fetchHasMore,
    hasMore,
    total,
    setDisable = () => {}, // Deshabilita el componente 2 (si es que existe)
    disable = false, // El componente 2 cambiaba este dato
    usedData = 0, // El total de datos en el componente 2
    title = 'Titulo',
    error = false, // Cambia el color del texto en caso de error (formik o algo)
    open = false, // Abre o cierra el acordeon
    loading = false, // No permite cambiar el estado del acordeon mientras sea true
    loadingMore = false, // Deshabilita los checkAll si hay una peticion
  } = props;

  const [data, setData] = useState(initialData);
  const [showedItems, setShowedItems] = useState(initialData.length);
  const [checkAll, setCheckAll] = useState(0);
  const [fetchHasMoreExecuted, setFetchHasMoreExecuted] = useState(false);
  const [more, setHasMore] = useState(hasMore);
  const [accordionExpanded, setAccordionExpanded] = useState(open);

  // Verifica que si loading cambia
  useEffect(() => {
    setAccordionExpanded(open);
  }, [loading]);

  const [config] = useState({
    title: 'Listado de Roles',
    icon: 'people',
    height: 400,
    endMessage: 'No hay más registros para mostrar',
  });

  const [searchInput, setSearchInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchInput);
  };

  const handleChangeInputSearch = (value) => {
    setSearchInput(value);
    if (value.length < 1) {
      handleSearch(value);
    }
  };

  /* useEffect(() => {
    const newData = initialData.map((item) => {
      // Verifica si el elemento actual de initialData existe en prevData
      const existsInPrevData = prevData.some(
        (prevItem) => prevItem.id === item.id
      );

      // Si existe en prevData, establece check en 1, de lo contrario, en 0
      return { ...item, check: existsInPrevData ? 1 : 0 };
    });
    setShowedItems(initialData.length);
    setData(newData);
  }, [initialData]); */

  useEffect(() => {
    const newData = initialData.map((item) => {
      const existsInPrevData = prevData.some((prevItem) => prevItem.id === item.id);
      return { ...item, check: existsInPrevData ? 1 : 0 };
    });
    setShowedItems(newData.length);
    setData(newData.filter((item) => item.check === 0)); // Filtrar roles no seleccionados
  }, [initialData, prevData]);

  // Pone todos los elementos con "check" igual a 1
  const handleCheckAll = () => {
    setDisable(true);
    const newData = data.map((item) => ({
      ...item,
      check: 1,
    }));
    setData(newData);
    setCheckAll(0);
    // Datos que se envian al componente principal
    // Se regresan solo los que tengan check y ya sin el campo de check
    setNewData(filterData(newData));
    if (more) fetchHasMore();
  };

  // Pone todos los elementos con "check" igual a 0
  const handleUncheckAll = () => {
    setDisable(true);
    const newData = data.map((item) => ({
      ...item,
      check: 0,
    }));
    setData(newData);
    setCheckAll(1);
    // Datos que se envian al componente principal
    // Se regresan solo los que tengan check y ya sin el campo de check
    setNewData(filterData(newData, 0));
  };

  const filterData = (data, value) => {
    // Filtrar los elementos con "check" igual a 1
    const filteredData = data.filter((item) => item.check === 1);

    /* // Elimina la propiedad "check" de todos los elementos
    const newData = filteredData.map((item) => {
      const { check, ...rest } = item;
      return rest;
    }); */

    // Elimina elementos de prevData si tienen "check" igual a 0 en data
    const updatedPrevData = prevData.filter((prevItem) => {
      const matchingDataItem = data.find((dataItem) => dataItem.id === prevItem.id);
      return !matchingDataItem || matchingDataItem.check === 1;
    });

    if (value === 0) {
      updatedPrevData.length = 0; // Elimina todos los elementos de updatedPrevData
    }

    if (value > 0) {
      const indexToRemove = updatedPrevData.findIndex((item) => item.id === value);
      if (indexToRemove !== -1) {
        updatedPrevData.splice(indexToRemove, 1); // Elimina el elemento con id igual a value
      }
    }

    // Agrega los elementos seleccionados previamente
    const allData = [
      ...updatedPrevData,
      ...filteredData.filter((item) => !prevData.some((prevItem) => prevItem.id === item.id)),
    ];

    return allData;
  };

  // Cambia el estado del elemento de check 1 o 0
  const handleCheck = (value) => () => {
    setDisable(true);
    const newData = data.map((item) =>
      item.id === value ? { ...item, check: item.check === 0 ? 1 : 0 } : item
    );

    setData(newData); // Datos locales
    // Datos que se envian al componente principal
    // Se regresan solo los que tengan check y ya sin el campo de check
    setNewData(filterData(newData, value));
  };

  // Filtrado de datos (estan con check o no)
  const uncheckedData = data.filter((item) => item.check === 0);

  // Verifica si ya no hay campos con check para actualizar CheckAll
  useEffect(() => {
    !uncheckedData.length ? setCheckAll(0) : !prevData.length ? setCheckAll(1) : setCheckAll(2);
  }, [uncheckedData.length, prevData.length]);

  useEffect(() => {
    const newDataLengthDifference = initialData.length - prevData.length;

    if (newDataLengthDifference < 10 && !fetchHasMoreExecuted) {
      fetchHasMore();
      setFetchHasMoreExecuted(true);
    }

    const newData = initialData.map((item) => {
      const existsInPrevData = prevData.some((prevItem) => prevItem.id === item.id);
      return { ...item, check: existsInPrevData ? 1 : 0 };
    });
    setShowedItems(newData.length);
    setData(newData.filter((item) => item.check === 0));
  }, [initialData, prevData, fetchHasMore, fetchHasMoreExecuted]);

  useEffect(() => {
    if (more && hasMore) {
      const dataL = prevData.length + uncheckedData.length === total;
      if (dataL) setHasMore(false);
      else setHasMore(true);
    }
  }, [uncheckedData]);

  useEffect(() => {
    setHasMore(hasMore);
  }, [hasMore]);

  const handleAccordionChange = () => {
    setAccordionExpanded(!accordionExpanded); // Cambia el estado de apertura/cierre del Accordion
  };

  return (
    <>
      <Grid2 xs={12}>
        <Accordion
          elevation={0}
          variant="outlined"
          expanded={accordionExpanded}
          onChange={loading ? () => {} : handleAccordionChange}
        >
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-content" id="panel-header">
            <Typography variant="body1" color={error ? '#d32f2f' : 'textSecondary'}>
              {title + ' (' + prevData.length + ' / ' + (total - usedData) + ')'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 1 }}>
            <Grid2 container spacing={1}>
              <Grid2 xs={12} md={6}>
                <Card
                  elevation={0}
                  sx={{
                    minHeight: '100%',
                    // boxShadow: { xs: 0 /* , sm: 1 */ },
                    backgroundColor: {
                      xs: 'transparent' /* , sm: "white" */,
                    },
                    borderTop: { xs: '1px solid #ccc' },
                    borderRight: {
                      xs: '1px solid #ccc',
                      sm: 0,
                      md: '1px solid #ccc',
                    },
                    borderBottom: { xs: '1px solid #ccc', md: 0 },
                    borderLeft: { xs: '1px solid #ccc', sm: 0 },
                  }}
                >
                  <CardHeader
                    sx={{ px: 2, py: 1 }}
                    avatar={
                      <Checkbox
                        disabled={checkAll === 0 || disable || loadingMore}
                        checked={checkAll === 0}
                        onClick={disable ? () => {} : handleCheckAll}
                        inputProps={{
                          'aria-label': 'all items selected',
                        }}
                      />
                    }
                    title={'Seleccionar todos'}
                    subheader={`${uncheckedData.length}/${total - usedData - prevData.length} disponibles`}
                  />
                  <Divider />
                  <Paper
                    component="form"
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Buscar perfil"
                      inputProps={{ 'aria-label': 'Buscar usuario' }}
                      value={searchInput}
                      onChange={(e) => handleChangeInputSearch(e.target.value)}
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                      <Icon>search</Icon>
                    </IconButton>
                  </Paper>
                  <CardContent>
                    <InfiniteScroll
                      dataLength={showedItems}
                      next={fetchHasMore}
                      hasMore={more}
                      loader={
                        <>
                          <LinearProgress color="secondary" sx={{ marginBottom: 1 }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              color={'primaryDark'}
                              variant={'contained'}
                              size={'small'}
                              onClick={() => {
                                fetchHasMore();
                              }}
                            >
                              Cargar más
                            </Button>
                          </div>
                        </>
                      }
                      height={config.height}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>
                            {config.endMessage !== undefined
                              ? config.endMessage
                              : '¡Final de la lista de registros!'}
                          </b>
                        </p>
                      }
                    >
                      <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {uncheckedData.map((item) => {
                          const labelId = `transfer-list-all-item-${item.id}-label`;

                          return (
                            <ListItem
                              key={item.id}
                              role="listitem"
                              button
                              onClick={disable ? () => {} : handleCheck(item.id)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  checked={item.check === 1}
                                  tabIndex={-1}
                                  disabled={disable}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={`${item.Perfil}`} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </InfiniteScroll>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Card
                  elevation={0}
                  sx={{
                    minHeight: '100%',
                    // boxShadow: { xs: 0 /* , sm: 1 */ },
                    backgroundColor: {
                      xs: 'transparent' /* , sm: "white" */,
                    },
                    borderTop: {
                      xs: '1px solid #ccc',
                    },
                    borderLeft: {
                      xs: '1px solid #ccc',
                      sm: 0,
                      md: '1px solid #ccc',
                    },
                    borderBottom: { xs: '1px solid #ccc', sm: 0 },
                    borderRight: { xs: '1px solid #ccc', sm: 0 },
                  }}
                >
                  <CardHeader
                    sx={{ px: 2, py: 1 }}
                    avatar={
                      <Checkbox
                        disabled={checkAll === 1 || disable || loadingMore}
                        checked={checkAll === 1}
                        onClick={handleUncheckAll}
                        inputProps={{
                          'aria-label': 'all items selected',
                        }}
                      />
                    }
                    title={'Deseleccionar todos'}
                    subheader={`${prevData.length}/${total - usedData} seleccionados`}
                  />
                  <Divider />
                  <CardContent>
                    <List
                      sx={{
                        width: '100%',
                        maxHeight: 400,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                      }}
                      dense
                      component="div"
                      role="list"
                    >
                      {prevData.map((item) => {
                        const labelId = `transfer-list-all-item-${item.id}-label`;

                        return (
                          <ListItem
                            key={item.id}
                            role="listitem"
                            button
                            onClick={disable ? () => {} : handleCheck(item.id)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={item.check === 1}
                                tabIndex={-1}
                                disabled={disable}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item.Perfil}`} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
    </>
  );
};

export default Seleccion;
