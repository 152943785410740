import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { CircularProgress } from '@mui/material';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const GaugeChart = (props) => {
  const { series, options, loading } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: 'solidgauge',
        height: options.height, //110%
      },
      title: {
        text: options.title,
        style: {
          fontSize: '24px',
        },
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '12px',
        },
        valueSuffix: '%',
        pointFormat:
          '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: this.chart.plotHeight / options.plotHeight + 15, //15
          };
        },
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for Move
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for Exercise
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1]).setOpacity(0.3).get(),
            borderWidth: 0,
          },
          /* {
            // Track for Stand
            outerRadius: "62%",
            innerRadius: "38%",
            backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
              .setOpacity(0.3)
              .get(),
            borderWidth: 0,
          }, */
        ],
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },

      series: series,
    });
  }, [series, options, loading]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default GaugeChart;
