import { useState } from 'react';

import { Alert, Box, Card, CircularProgress, Icon, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import UserSearchForm from '@components/Sije/CaptureForm/UserSearchForm';
import RollCallForm from '@components/Sije/CaptureForm/RollCallForm';
import ListCasillas from '@components/Sije/CaptureForm/ListCasillas';
import CaptureRollCallForm from '@components/Sije/CaptureForm/CaptureRollCallForm';
import Switch from '../CaptureForm/Switch';
import CardCloseCasilla from '@components/Sije/OperationCasilla/CardCloseCasilla';

import Swal from 'sweetalert2';
import sije from '@services/SijeService';

const Casilla = (props) => {
  const { nameTab, setOpenLinearProgress, setIsSuccessFormSave, setIsLoadingForm, isLoadigForm, reload } =
    props;

  const [listCasillas, setListCasillas] = useState([]);
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState({});
  const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  const [page, setPage] = useState(0);
  const [totalList, setTotalList] = useState(0);
  const [filterData, setFilterData] = useState({
    Celular: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
  });
  const [idUser, setIdUser] = useState(null);
  const [resultsFalse, setResultsFalse] = useState('');

  const getCasillasUser = async ({ idUser }) => {
    try {
      setLoadingListCasilla((prevState) => !prevState);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = result;
      if (results) {
        setListCasillas(response.data);
        setResultsFalse('');
      } else {
        const { data } = result;
        setResultsFalse(data);
        setListCasillas([]);

        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoadingListCasilla((prevState) => !prevState);
    }
  };

  const handleSetValuesForm = (values) => {
    const { casilla, idUser, hora } = values;
    handleSaveForm({
      casillas: [{ idCasilla: casilla, FechaHora: hora }],
      idUser,
    });
  };

  const handleSetValuesFormMassive = ({ casillas, FechaHora }) => {
    const _casillas = casillas
      .filter((item) => item.isCheked !== 1)
      .map((item) => ({
        idCasilla: item.idCasilla,
        FechaHora,
      }));

    handleSaveForm({
      casillas: _casillas,
      idUser: user.idUsuario,
    });
  };

  const handleSaveForm = async (body) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setCierreCasilla({
        ...body,
        OrigenCaptura: 'web',
      });
      const { results, message } = await result;
      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        });
        // setUser({});
        // setOptions([]);
        /* setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        }); */
        getCasillasUser({ idUser });
        setPage(0);
        setTotalList(0);
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    setIdUser(user.value);
    getCasillasUser({ idUser: user.value });
    setUser(user);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12} md={6} lg={6}>
        <UserSearchForm
          title="Encuentra y elige al usuario para registrar el cierre de la casilla."
          page={page}
          total={totalList}
          setPage={setPage}
          setTotal={setTotalList}
          filterData={filterData}
          options={options}
          setFilterData={setFilterData}
          setOptions={setOptions}
          setUser={setUser}
          handleClickItem={handleClickItem}
        />
      </Grid2>
      <Grid2 xs={12} md={6} lg={6}>
        <CardCloseCasilla loading={isLoadigForm} />
        {Object.entries(user).length === 0 && (
          <Box
            className="card-primary"
            component={Card}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'400px'}
          >
            <Stack
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Box
                sx={{ backgroundColor: grey[200] }}
                width={'80px'}
                height={'80px'}
                padding={2}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'50%'}
              >
                <Icon fontSize={'large'} sx={{ color: grey[700] }}>
                  post_add
                </Icon>
              </Box>
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                Busca y selecciona al usuario para poder hacer el registro de cierre de casilla.
              </Typography>
            </Stack>
          </Box>
        )}

        {loadingListCasillas ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'600px'}>
            <CircularProgress />
          </Box>
        ) : resultsFalse === null && listCasillas.length === 0 ? (
          <Box component={Card} className="card-primary">
            <Typography textAlign={'center'} variant="body2" fontWeight={600} marginTop={1} marginBottom={1}>
              PASE DE LISTA - RG
            </Typography>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'30vh'}>
              <Alert severity="warning">El RG no está dentro de tu ámbito, favor de verificar.</Alert>
            </Box>
          </Box>
        ) : (
          <Switch test={true}>
            <CaptureRollCallForm
              idUser={user.idUsuario}
              title={`${nameTab} - OTRO`}
              handleSave={handleSetValuesForm}
              flag={listCasillas.length === 0 && Object.entries(user).length > 0}
            />
            <RollCallForm
              title={`${nameTab} - RC`}
              itemUser={user}
              setItemUser={setUser}
              options={options}
              handleSave={handleSetValuesForm}
              loading={isLoadigForm}
              flag={listCasillas.length === 1 && Object.entries(user).length > 0}
            />
            <ListCasillas
              title={`${nameTab} - RGS`}
              typeCheck="esCierreCasilla"
              labelHora={'* Hora de Cierre Casilla (Formato: 24 hrs, ejemplo: 13:30)'}
              data={listCasillas}
              handleSave={handleSetValuesFormMassive}
              flag={listCasillas.length > 1 && Object.entries(user).length > 0}
            />
          </Switch>
        )}
      </Grid2>
    </Grid2>
  );
};

export default Casilla;
