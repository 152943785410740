import { useState } from 'react';

// Material UI
import { Grid, Button, Typography } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';

const CustomTablesExamples = () => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [sticky, setSticky] = useState(false); // maxHeight
  const [bgColor, setBgColor] = useState(null);
  const [disableCardType, setDisableCardType] = useState(false);
  const [disableHover, setDisableHover] = useState(false);
  const [disableStriped, setDisableStriped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nullData, setNullData] = useState(false);

  const clg = (e) => console.log('Ocurrió una Acción: ', e);
  const clgPlus = (a, b, c) => {
    console.log('value: ', a);
    console.log('idColumna: ', b);
    console.log('Fila: ', c);
  };

  const handleResetOrderBy = () => {
    setOrderBy('1');
    setTimeout(() => setOrderBy(''), 500);
  };

  const handleDisableButtons = () => setDisableButtons(!disableButtons);

  // Normales
  const rowsNormal = [
    { row1: 12345, row2: 'texto 1', row3: 1, row4: 'chipsito' },
    { row1: 22.2222, row2: 'texto 2', row3: 2.76, row4: 'Soy' },
    { row1: '2.3', row2: 'texto 3', row3: 3, row4: 'un' },
    { row1: { value: '1,111' }, row2: { value: 'texto 4' }, row3: { value: 4 }, row4: { value: 'chip' } },
    { row1: { value: '.1' }, row2: { value: 'texto 5' }, row3: { value: 90.5459 }, row4: { value: 'hola' } },
    { row1: { value: '5111.2' }, row2: { value: 'texto 6' }, row3: { value: 6 }, row4: { value: 'ultimo' } },
  ];
  const columnsNormal = [
    { id: 'row1', label: 'Numeros', type: 'number', fixed: 2 },
    { id: 'row2', label: 'Texto', type: 'text' },
    { id: 'row3', label: 'Porcentaje', type: 'percent', fixed: 3 },
    { id: 'row4', label: 'Chip', type: 'chip', align: 'center', fixed: 3 },
  ];
  const subColumnsNormal = [
    { label: 'Textos', colspan: 2, align: 'right' },
    { label: 'Graficas', colspan: 1, align: 'left' },
    { label: 'Con Otro', colspan: 1, align: 'center' },
  ];
  const summaryNormal = [{ value: 0 }, { value: 2 }, { value: 3 }, { value: 4 }];

  // Porcentajes
  const rowsPercent = [
    { row1: 10, row2: 20, row3: 30 },
    { row1: 40, row2: 50, row3: 60 },
    { row1: 70, row2: 80, row3: 90 },
    { row1: 100, row2: 10, row3: 20 },
    { row1: 30, row2: 40, row3: 50 },
    { row1: 60, row2: 70, row3: 80 },
    { row1: 90, row2: 100, row3: 70 },
    { row1: 50, row2: 30, row3: 10 },
  ];
  const columnsPercent = [
    { id: 'row1', label: 'ChipPercent', type: 'chipPercent', fixed: 2 },
    { id: 'row2', label: 'Circular', type: 'circular' },
    { id: 'row3', label: 'Linear', type: 'linear', fixed: 3 },
  ];

  // Botones
  const rowsButtons = [
    {
      row1: 'click',
      row2: 'Boton 1 (trae todo)',
      row3: 'Boton 2',
      row4: 'Boton 3',
      row5: { value: 'edit', id: 1 },
    },
    {
      row1: 'click',
      row2: { value: 'Objeto (trae todo)', id: 2 },
      row3: { value: 'Objeto', id: 2 },
      row4: 'Boton 3',
      row5: 'block',
    },
    {
      row1: { value: 'Objeto', id: 3 },
      row2: 'Boton 1 (trae todo)',
      row3: 'Boton 2',
      row4: 'Boton 3',
      row5: 'delete',
    },
    {
      row1: 'click',
      row2: 'Boton 1 (trae todo)',
      row3: 'Boton 2',
      row4: { value: 'Objeto', id: 4 },
      row5: 'done',
    },
  ];
  const columnsButtons = [
    { id: 'row1', label: 'Click', align: 'center', onClick: disableButtons ? null : clg },
    {
      id: 'row2',
      label: 'no borde',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#F9912A',
      variant: 'text',
      onClick: disableButtons ? null : clgPlus,
    },
    {
      id: 'row3',
      label: 'outlined',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#EF4624',
      variant: 'outlined',
      onClick: disableButtons ? null : clg,
    },
    {
      id: 'row4',
      label: 'contained',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#76B947',
      variant: 'contained',
      onClick: disableButtons ? null : clg,
    },
    {
      id: 'row5',
      label: 'Icono',
      sort: false,
      type: 'iconButton',
      align: 'center',
      color: '#EF4624',
      onClick: disableButtons ? null : clg,
    },
  ];

  // De todo un poco
  const rows = [
    {
      row1: 12345,
      row2: 'texto1',
      row3: 1,
      row4: 10,
      row5: 10,
      row6: 10,
      row7: 1,
      row8: 'Boton 1',
      row9: 'Boton 1',
      row10: 'Boton 1',
      row11: 'block',
    },
    {
      row1: 22.2222,
      row2: 'texto2',
      row3: 2.76,
      row4: 30.43,
      row5: 30.43,
      row6: 30.43,
      row7: 2,
      row8: 'Boton 2',
      row9: 'Boton 2',
      row10: 'Boton 2',
      row11: 'done',
    },
    {
      row1: '2.3',
      row2: 'texto3',
      row3: 3,
      row4: 50,
      row5: 50,
      row6: 50,
      row7: 3,
      row8: 'Boton 3',
      row9: 'Boton 3',
      row10: 'Boton 3',
      row11: 'edit',
    },
    {
      row1: '1,111',
      row2: 'texto4',
      row3: 4,
      row4: 70.439,
      row5: 70.439,
      row6: 70.439,
      row7: 4,
      row8: 'Boton 4',
      row9: 'Boton 4',
      row10: 'Boton 4',
      row11: 'delete',
    },
    {
      row1: '.1',
      row2: 'texto5',
      row3: '1234',
      row4: '90',
      row5: '90',
      row6: '90',
      row7: '1234',
      row8: 'Boton 5',
      row9: 'Boton 5',
      row10: 'Boton 5',
      row11: 'mood',
    },
    {
      row1: { value: '5111.2' },
      row2: { value: 'texto6' },
      row3: { value: 6 },
      row4: { value: 100 },
      row5: { value: 100 },
      row6: { value: 100 },
      row7: { value: 6, click: 'me dieron click' },
      row8: { value: 'Boton 6', click: 'me dieron click' },
      row9: { value: 'Boton 6', click: 'me dieron click' },
      row10: { value: 'Boton 6', click: 'me dieron click' },
      row11: { value: 'block', click: 'me dieron click' },
    },
  ];
  const columns = [
    { id: 'row1', label: 'Numeros', type: 'number', sort: false, disablePaddingHead: true, fixed: 2 },
    { id: 'row2', label: 'Texto', type: 'text', disablePaddingBody: true },
    { id: 'row3', label: 'Porcen', type: 'percent', disablePadding: true },
    { id: 'row4', label: 'Chip', type: 'chip', iconChip: true, fixed: 2 },
    { id: 'row4', label: 'ChipPercent', type: 'chipPercent', fixed: 2 },
    { id: 'row5', label: 'Circular', type: 'circular', disableFontColor: true, fixed: 1 },
    { id: 'row6', label: 'Linear', type: 'linear', fixed: 2, fontSize: 14 },
    { id: 'row7', label: 'Click', type: 'number', align: 'center', onClick: disableButtons ? null : clg },
    {
      id: 'row8',
      label: 'no borde',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#F9912A',
      variant: 'text',
      onClick: disableButtons ? null : clg,
    },
    {
      id: 'row9',
      label: 'outlined',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#EF4624',
      variant: 'outlined',
      onClick: disableButtons ? null : clg,
    },
    {
      id: 'row10',
      label: 'contained',
      type: disableButtons ? 'text' : 'button',
      sort: false,
      align: 'center',
      color: '#76B947',
      variant: 'contained',
      onClick: disableButtons ? null : clg,
    },
    {
      id: 'row11',
      label: 'Icono',
      sort: false,
      type: 'iconButton',
      align: 'center',
      color: '#EF4624',
      onClick: disableButtons ? null : clg,
    },
  ];
  const subColumns = [
    { id: 'subTitulo1', label: 'Textos', colspan: 4, align: 'right', disablePadding: true },
    { label: 'Graficas', colspan: 3, align: 'left' },
    { label: 'Botones', colspan: 5, align: 'center' },
  ];
  const summary = [{ value: 0, align: 'center' }, { value: 1 }, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const handleSticky = () => setSticky(!sticky);
  const handleBgColor = () => setBgColor(bgColor === null ? '#ff92f6' : null);
  const handleDisableCardType = () => setDisableCardType(!disableCardType);
  const handleDisableHover = () => setDisableHover(!disableHover);
  const handleDisableStriped = () => setDisableStriped(!disableStriped);
  const handleIsLoading = () => setIsLoading(!isLoading);
  const handleNullData = () => setNullData(!nullData);

  return (
    <Grid container spacing={2} rowSpacing={5} sx={{ p: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
          Si se añade la propiedad "handlePagination" o "handleSort", dejara de hacerlo localmente
        </Typography>
      </Grid>

      <Grid container spacing={2} sx={{ p: 2, pb: 0 }}>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleSticky}>
            {sticky ? 'Quitar stickyHeader' : 'Poner stickyHeader'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleBgColor}>
            {bgColor ? 'Quitar BG' : 'Poner BG'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleDisableCardType}>
            {!disableCardType ? 'Quitar Tipo Card' : 'Poner Tipo Card'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleDisableHover}>
            {!disableHover ? 'Quitar Hover' : 'Poner Hover'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleDisableStriped}>
            {!disableStriped ? 'Quitar Striped' : 'Poner Striped'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleIsLoading}>
            {isLoading ? 'Quitar Loading' : 'Poner Loading'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleNullData}>
            {!nullData ? 'Quitar Data' : 'Poner Data'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={handleResetOrderBy}>
            Reset Order
          </Button>
        </Grid>
      </Grid>

      {/* Normal */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tabla normal
        </Typography>
        <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
          Ordenamiento y paginación local
        </Typography>
        <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
          {`uso de values: (row1: "") o objetos: (row1: { value: "" })`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rowsNormal}
          columns={columnsNormal}
          orderBy={orderBy}
          stickyHeader={sticky}
          maxHeight={150}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>

      {/* Porcentajes */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tabla con porcentajes
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rowsPercent}
          columns={columnsPercent}
          orderBy={orderBy}
          stickyHeader={sticky}
          maxHeight={200}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>

      {/* Botones */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tabla con botones
        </Typography>
        <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
          Algunos retornan value como objeto, otros retornan 3 cosas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth variant="outlined" onClick={handleDisableButtons}>
          {disableButtons ? 'Agregar botones' : 'Quitar botones'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rowsButtons}
          columns={columnsButtons}
          orderBy={orderBy}
          stickyHeader={sticky}
          maxHeight={200}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>

      {/* SubColumnas */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tabla con subColumnas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rowsNormal}
          columns={columnsNormal}
          subColumns={subColumnsNormal}
          orderBy={orderBy}
          stickyHeader={sticky}
          maxHeight={150}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>

      {/* Summary */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Tabla con summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rowsNormal}
          columns={columnsNormal}
          summary={summaryNormal}
          orderBy={orderBy}
          stickyHeader={sticky}
          maxHeight={200}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>

      {/* De todo un poco */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          De todo un poco
        </Typography>
        <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
          Variacion de padding, index del Titulo de Summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          rows={nullData ? [] : rows}
          columns={columns}
          subColumns={subColumns}
          orderBy={orderBy}
          summary={summary}
          summarySettings={{ start: 1, title: 'Totales: ', align: 'right' }}
          stickyHeader={sticky}
          maxHeight={270}
          backgroundColor={bgColor}
          disableCardType={disableCardType}
          disableHover={disableHover}
          disableStriped={disableStriped}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default CustomTablesExamples;
