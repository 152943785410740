import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Box, Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@src/theme/index';

import FormCategorie from './FormCategorie';

import user from '@services/UserServices';
import Swal from 'sweetalert2';

const Form = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  //const [activeStep, setActiveStep] = useState(0);
  //const [steps] = useState(["Validación de cuenta", "Tipo de solicitud"]);
  /* const [userSupport, setUserSupport] = useState({
    Celular: "",
  }); */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('validaterequest')
        .then((token) => {
          const bodyData = {
            ...values,
            token,
          };
          onSubmitSupport(bodyData);
        })
        .catch((error) =>
          Swal.fire({
            title: error,
            icon: 'warning',
            customClass: { container: 'modal-alert' },
          })
        );
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );
  const onSubmitSupport = async (data) => {
    try {
      setLoading(true);
      const res = await user.saveSupportRequest(data);
      const { results, message } = res;
      if (!results) {
        throw new Error(message);
      }
      Swal.fire({
        title: message,
        icon: 'success',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setSuccess(true);
        }
      });
      //setActiveStep(0);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  /* const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }; */

  return (
    <ThemeProvider theme={theme}>
      <Box marginTop={4}>
        {/* <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper> */}

        <Container
          sx={{
            width: '100%',
            minHeight: '40vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormCategorie
            loading={loading}
            success={success}
            setSuccess={setSuccess}
            onSubmitSupport={onChangeCaptcha}
          />
          {/* {steps[activeStep] && activeStep === 0 && (
            <FormVerification
              setUserSupport={setUserSupport}
              handleNext={handleNext}
            />
          )} */}

          {/* {steps[activeStep] && activeStep === 1 && (
            <FormCategorie
              loading={loading}
              onSubmitSupport={onChangeCaptcha}
            />
          )} */}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Form;
