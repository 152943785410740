// Librerías
import React, { useEffect, useState } from 'react';
import { Container, Card, Grid, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import UserActionsHistory from '@components/Users/UserActionsHistory';
import UserSearchHistory from '@components/Users/UserSearchHistory';

// Servicios
import SupportServices from '@services/SupportServices';

function UserActions() {
  const [isLoading, setIsLoading] = useState(false);

  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');

  const [page, setPage] = useState(0);
  const pageSize = 10;

  const [filtered, setFiltered] = useState([]);

  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);

  const [sorted, setSorted] = useState([{ id: 'Fecha', value: 'desc' }]);

  const [message, setMessage] = useState('Usuario sin actividad registrada');

  const [messageUserNotFound, setmessageUserNotFound] = useState('');

  const handleFilter = (action, params) => {
    const formattedStartDate = params?.FechaInicio ? format(params.FechaInicio, 'yyyy-MM-dd') : null;
    const formattedEndDate = params?.FechaFinal ? format(params.FechaFinal, 'yyyy-MM-dd') : null;

    if (action !== 'clear') {
      const filter = [];

      if (formattedStartDate) {
        filter.push({
          id: 'Fecha',
          filter: '>=',
          value: formattedStartDate,
        });
      }

      if (formattedEndDate) {
        filter.push({
          id: 'Fecha',
          filter: '<=',
          value: formattedEndDate,
        });
      }

      setFiltered([...filtered.slice(0, 1), ...filter]);

      const params = {
        page: 0,
        filtered: [...filtered.slice(0, 1), ...filter],
        sorted,
      };
      setPage(0);

      fetchInitialData(params);
    } else {
      const params = {
        page: 0,
        filtered: [...filtered.slice(0, 1)],
        sorted: [{ id: 'Fecha', value: 'desc' }],
      };

      setPage(0);
      setFiltered([...filtered.slice(0, 1)]);
      setSorted([{ id: 'Fecha', value: 'desc' }]);

      fetchInitialData(params);
    }
  };

  const handleUserIdChange = (id) => {
    setUserId(id);

    if (id) {
      const filter = [
        {
          id: 'idUsuario',
          filter: '=',
          value: id,
        },
      ];

      setPage(0);
      setFiltered(filter);
      setSorted([{ id: 'Fecha', value: 'desc' }]);

      const params = {
        page: 0,
        filtered: filter,
        sorted: [{ id: 'Fecha', value: 'desc' }],
      };

      fetchInitialData(params, id);
    } else {
      // Limpiar datos solo si se deseleccionó un usuario
      setData([]);
      setTotal(0);
      setMessage('Usuario sin actividad registrada');
    }
  };

  const handleUserNameChange = (name) => setUserName(name);

  const handleSort = (newOrder) => {
    setPage(0);
    setSorted([{ id: 'Fecha', value: newOrder }]);

    const params = {
      page: 0,
      filtered,
      sorted: [{ id: 'Fecha', value: newOrder }],
    };

    fetchInitialData(params);
  };

  const fetchInitialData = async (params, id) => {
    const idFetch = id ?? userId;
    setMessage('Cargando actividad del usuario');
    setIsLoading(true); // Establecer isLoading en true antes de la llamada

    if (!idFetch) return;

    const localParams = {
      ...params,
      page: 0,
      pageSize,
    };

    setData([]);
    setTotal(0);

    try {
      const result = await SupportServices.getUserLogs(localParams);
      const { results, response } = result;

      if (results) {
        setTotal(response.total);
        setData(response.data);
      } else {
        setMessage('Usuario sin actividad registrada');
        Swal.fire({
          title: 'No hay actividad registrada',
          icon: 'warning',
        });
      }
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false); // Establecer isLoading en false después de obtener los datos
    }
  };

  const fetchMoreData = async (page) => {
    if (!userId) return;

    const params = {
      page,
      pageSize,
      filtered,
      sorted,
    };

    try {
      const result = await SupportServices.getUserLogs(params);
      const { results, response } = result;

      if (results) {
        setTotal(response.total);
        setData((prevData) => [...prevData, ...response.data]);
      } else {
        Swal.fire({ title: 'No hay actividad registrada', icon: 'warning' });
      }
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    if (page > 0) fetchMoreData(page);
    // eslint-disable-next-line
  }, [page]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Cronología actividad del usuario" isAction={false} iconButton="arrow_back" />

        <Box sx={{ mt: -2, mb: 1 }}>
          <Grid container>
            <Grid item xs={12} lg={4} sx={{ pr: { lg: 1 }, mt: 2 }}>
              <UserSearchHistory
                userId={userId}
                handleUserIdChange={handleUserIdChange}
                handleUserNameChange={handleUserNameChange}
                setmessageUserNotFound={setmessageUserNotFound}
                setMessage={setMessage}
              />
            </Grid>

            <Grid item xs={12} lg={8} sx={{ pl: { lg: 1 }, mt: 2 }}>
              <Card className="card-primary" sx={{ height: '100%', padding: 2 }}>
                <UserActionsHistory
                  userId={userId}
                  userName={userName}
                  total={total}
                  data={data}
                  handleFilter={handleFilter}
                  handleSort={handleSort}
                  setPage={setPage}
                  message={message}
                  isLoading={isLoading}
                  messageUserNotFound={messageUserNotFound}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default UserActions;
