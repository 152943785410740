import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  Icon,
  CardHeader,
  Paper,
  InputBase,
  CardActions,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import InfiniteScroll from 'react-infinite-scroll-component';

/* Armado de los datos [
    { id: "1", label: "Item 1", subtitle: "Ali Connors" },
    { id: "2", label: "Item 2" },
    { id: "3", label: "Item 3" },
    { id: "4", label: "Item 4" },
  ] */

const ListMove = ({
  data = [],
  count = 0,
  configParams,
  fetchHasMore,
  hasMore,
  Header = false,
  search,
  handleSearchItems,
  colorTitle = '#0F3A58',
  colorSubtitle = '#9EC2E2',
}) => {
  const [items, setItems] = useState(data);
  const [showedItems, setShowedItems] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [config, setConfig] = useState({
    title: 'Listado',
    icon: 'list_alt',
    height: 400,
    endMessage: 'No hay registros para mostrar',
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setItems(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const removedIndex = e.dataTransfer.getData('index');
    let newItems = [...items];
    const [removedItem] = newItems.splice(removedIndex, 1);
    newItems.splice(e.currentTarget.dataset.index, 0, removedItem);
    setItems(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchItems(searchInput);
  };

  const handleChangeInputSearch = (value) => {
    setSearchInput(value);
    if (value.length < 1) {
      search(value);
    }
  };

  return (
    <Card>
      {Header && (
        <CardHeader
          avatar={<Icon sx={{ fontSize: '25px' }}>{config.icon}</Icon>}
          title={config.title}
          titleTypographyProps={{ fontSize: '20px' }}
        />
      )}
      <CardContent sx={{ pt: 0 }}>
        {search && (
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Buscar usuario"
              inputProps={{ 'aria-label': 'Buscar usuario' }}
              value={searchInput}
              onChange={(e) => handleChangeInputSearch(e.target.value)}
            />
            <IconButton
              type="submit"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={() => search(searchInput)}
            >
              <Icon>search</Icon>
            </IconButton>
          </Paper>
        )}
        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>
                {config.endMessage !== undefined ? config.endMessage : '¡Final de la lista de registros!'}
              </b>
            </p>
          }
        >
          <List>
            {items.map((info, index) => (
              <div
                key={info.id}
                draggable
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-index={index}
              >
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      color: colorTitle,
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                    primary={info.label}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          style={{
                            color: colorSubtitle,
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          {info.subtitle}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end">
                      <DragHandleIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            ))}
          </List>
        </InfiniteScroll>
      </CardContent>
      <CardActions>
        <small>
          Mostrando {showedItems} de {count}
        </small>
      </CardActions>
    </Card>
  );
};

export default ListMove;
