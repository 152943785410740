import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Icon,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import Navbar from '../Navbar';
import Toast from '../Toast';

import moment from 'moment/moment';
import 'moment/locale/es';
import esLocale from 'date-fns/locale/es';
import { getVars } from '@utils/global';
import { useMobileServices } from '@hooks/MobileDiaD/MobileServices';
import { WhatsApp } from '@mui/icons-material';
import { URL_WHATSAPP } from '@data/constants/MobileDiaD';

const CardContentCasilla = ({ rc, handleSetPaseLista }) => {
  //const defaultTime = moment();
  const [hour, setHour] = useState(null);

  const handleSetHour = (newValue) => {
    setHour(newValue);
  };

  const handleOkButtonClick = () => {
    handleSetPaseLista({
      //idRc: rc.id,
      idCasilla: rc.idCasilla,
      idTipoPropietario: rc.idCalidadRepresentate,
      FechaHora: moment(hour).format('HH:mm'),
    });
  };

  return (
    <Box marginBottom={2}>
      <Stack direction={'column'} marginBottom={1}>
        <Typography variant="subtitle1" fontWeight={600} sx={{ color: 'primary.main' }}>
          {rc.NombreCompleto}
        </Typography>
        <Typography variant="subtitle1" fontWeight={600} sx={{ color: 'primary.main' }}>
          {rc.Seccion} {rc.NombreCasilla} {rc.CalidadRepresentante}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} marginBottom={2}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Icon fontSize="small">phone</Icon>
          <Typography component={'a'} href={`tel:${rc.Celular}`} color={'inherit'}>
            {rc.Celular}
          </Typography>
        </Box>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <WhatsApp fontSize="small" />
          <Typography
            component={'a'}
            href={`${URL_WHATSAPP}${rc.Celular} &text=Hola ${rc.NombreCompleto} `}
            target={'_blank'}
            color={'inherit'}
          >
            {rc.Celular}
          </Typography>
        </Box>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
          localeText={{
            timePicker24Hour: '24 horas',
            okButtonLabel: 'Aceptar',
            cancelButtonLabel: 'Cancelar',
          }}
        >
          <MobileTimePicker
            label={'Pase de lista'}
            value={hour}
            onChange={handleSetHour}
            onAccept={() => handleOkButtonClick()}
            renderInput={(params) => (
              <TextField size="small" variant="outlined" fullWidth sx={{ width: '100%' }} {...params} />
            )}
          />
        </LocalizationProvider>
      </Stack>
    </Box>
  );
};

const ScreenPaseRuta = ({ setTypeScreen }) => {
  const { loading, snackbar, setSnackbar, setPaseListaMasivo } = useMobileServices();

  const {
    ruta_rg: { Ruta, Descripcion, Casillas },
    user: { ambito_perfil },
  } = getVars('Token');

  /* const [rcsCasillas, setRcsCasillas] = useState([]);
  const [totals, setTotals] = useState({
    checks: 0,
    total: 0,
  }); */

  const handleSetPaseLista = async (params) => {
    // handleCount(params);
    //delete params.idRc;

    setPaseListaMasivo({
      idUsuario: ambito_perfil[0].idUsuario,
      casillas: [params],
    });
  };

  /*   const handleCount = (params) => {
    const updatedData = Casillas.map((ruta) => {
      return {
        ...ruta,
        rcs: ruta.rcs.map((rc) => {
          if (rc.id === params.idRc) {
            return {
              ...rc,
              HoraRepresentante: params.FechaHora,
            };
          }
          return rc;
        }),
      };
    });
    console.log(updatedData);
    setRcsCasillas(updatedData);
  }; */
  /* 
  useEffect(() => {
    if (!loading) {
      const totalChecks = rcsCasillas.reduce(
        (count, casilla) =>
          count + casilla.rcs.filter((rc) => rc.HoraRepresentante).length,
        0
      );

      const total = rcsCasillas.reduce(
        (total, casilla) => total + casilla.rcs.length,
        0
      );

      setTotals({ checks: totalChecks, total });
    }
  }, [rcsCasillas, loading]); */

  return (
    <>
      <Toast open={snackbar.show} message={snackbar.message} setOpen={setSnackbar} />
      <Navbar
        isScreen
        screenBack={'rgs'}
        title={'Pase Lista Ruta RG'}
        setTypeScreen={setTypeScreen}
        color="black"
      />

      <Container sx={{ mt: 4 }}>
        <Typography textAlign={'center'} fontWeight={600} marginBottom={2}>
          ¿El RC está en su casilla?
        </Typography>
        <Stack direction={'column'} spacing={2}>
          <Box textAlign={'center'}>
            <Typography variant="subtitle1" fontWeight={600}>
              {Ruta}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Descripcion}
            </Typography>
            {/* <Typography>
              Pase de lista a {totals.checks} de {totals.total} casilleros
            </Typography> */}
            {loading && <LinearProgress sx={{ width: '100%' }} />}
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Box height={'80vh'} overflow={'auto'}>
            {Casillas &&
              Casillas.map((casilla) => (
                <Card key={casilla.idCasilla} className="card-secondary" sx={{ mb: 4 }}>
                  <CardContent>
                    <Stack direction={'row'} spacing={1} marginBottom={1}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {casilla.Seccion}
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {casilla.NombreCasilla}
                      </Typography>
                    </Stack>

                    <Box>
                      {casilla.rcs && casilla.rcs.length > 0 ? (
                        casilla.rcs.map((rc) => (
                          <div key={rc.id}>
                            <CardContentCasilla key={rc.id} rc={rc} handleSetPaseLista={handleSetPaseLista} />
                            {rc.id !== casilla.rcs[casilla.rcs.length - 1].id && <Divider sx={{ mb: 2 }} />}
                          </div>
                        ))
                      ) : (
                        <Typography>No tiene rcs</Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              ))}
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default ScreenPaseRuta;
