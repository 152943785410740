import { useEffect, useState } from 'react';

//Mui
import {
  Alert,
  Container,
  Typography,
  Box,
  Icon,
  Card,
  CardContent,
  Stack,
  Divider,
  ListItemText,
  List,
  ListItem,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { green, grey, red } from '@mui/material/colors';

import Swal from 'sweetalert2';

//Components
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import UploadSingleFile from '@components/Files/UploadSingleExcelFile';
import { processExcelFile } from '@components/Certificacion_ppm_foca/excelProcessor';
import ChipResume from '@components/Certificacion_ppm_foca/ChipResume';
import DownloadButton from '@components/Certificacion_ppm_foca/DownloadButton';
import BasicSelect from '@components/Selects/BasicSelect';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomTable from '@components/Tables/CustomTable';

//UTILS
import { numberWithCommas } from '@utils/Utilities/';
import { columns_required, columns } from '@data/constants/reconocimientos';

//SERVICES
import ReconocimientosServices from '@services/ReconocimientosServices';

const UploadInformation = () => {
  const [errorData, setErrorData] = useState([]);
  const [fileDataWithError, setFileDataWithError] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [resetUpload, setResetUpload] = useState(false);
  const [selectValue, setValueSelect] = useState('');
  const [dataToUpload, setDataToUpload] = useState([]);
  const [cat_plantilla, setCatalog] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(100);
  const excelRowStart = 2;

  const stylesIcons = {
    false: { icon: 'check_circle', color: green[700] },
    true: { icon: 'warning', color: red[700] },
    null: { icon: 'info', color: grey[500] },
  };
  const [isLoadingCat, setIsLoadingCat] = useState([]);
  const [isLoadingImport, setIsLoadingImport] = useState({
    message: 'Cargando',
    successMessage: 'Cargado con exito!',
    isLoading: false,
    open: false,
  });

  const resetParam = () => {
    setErrorData([]);
    setFileData([]);
    setPage(0);
  };

  const catalogs = async () => {
    try {
      setIsLoadingCat(true);
      const result = await ReconocimientosServices.getCatPlantillas();
      const { results, message, response } = result;
      if (results) {
        setCatalog(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingCat(false);
    }
  };

  const validExcel = async (file) => {
    try {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: true,
        open: true,
      });
      resetParam();

      const { data, error } = await processExcelFile(file, excelRowStart, 0);

      if (error.length > 0) {
        setErrorData(error);
        setFileDataWithError(data);
        return;
      }

      if (data.length === 0 && error.length === 0) {
        setResetUpload((prev) => !prev);
        return;
      }

      const uploadData = data.map((row) => {
        const { Duración, ...rest } = row;
        return { ...rest, Duracion: Duración };
      });

      setDataToUpload(uploadData);

      if (data.length > 0 && error.length === 0) {
        setFileData(uploadData);
      }
    } catch (error) {
      Swal.fire({ title: 'Error al validar el excel.', icon: 'warning' });
    } finally {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: false,
        open: false,
      });
    }
  };

  const handleSave = async () => {
    try {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: true,
        open: true,
      });

      const localdata = dataToUpload.map((row) => {
        const { Duracion, ...rest } = row;
        return { ...rest, Duración: Duracion };
      });

      const result = await ReconocimientosServices.importExcelData({
        idPlantilla: selectValue,
        data: dataToUpload,
      });
      const { success, results, message, errors } = result;

      if (results) {
        resetParam();
        Swal.fire({ title: message, icon: 'success' });
      } else {
        if (success && !results) {
          const errorsWithDetails = errors.map((error) => {
            return {
              row: error.path[1] + excelRowStart,
              column: error.path[2],
              message: error.message,
            };
          });

          setErrorData(errorsWithDetails);
          setFileDataWithError(localdata);
        }
      }
      if (!success) throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: false,
        open: false,
      });
    }
  };

  useEffect(() => {
    catalogs();
  }, []);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Carga de información" />
        <LoadingForm
          isLoading={isLoadingImport.isLoading}
          isOpen={isLoadingImport.isLoading}
          setIsOpen={() =>
            setIsLoadingImport({
              ...isLoadingImport,
              isLoading: false,
              open: false,
            })
          }
          loadinMessage={'Cargando...'}
        />
        <Card className="card-primary">
          <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ p: 2 }}>
            <Icon fontSize="small">upload</Icon>
            <Typography variant="body2" fontWeight={600}>
              Importar información
            </Typography>
          </Stack>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={3}>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Bienvenido al proceso de carga de archivos. Primero, seleccione la plantilla del menú
                  desplegable arriba. Luego, cargue el archivo en el campo a la derecha con la información
                  requerida.
                </Alert>

                <Typography variant="body2" fontWeight={600} marginBottom={2}>
                  Columnas requeridas
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <List sx={{ mb: 2 }}>
                  {columns_required.map((column) => {
                    const columnErrors = errorData.filter((error) => error.column === column.name);
                    const hasError = columnErrors.length > 0;
                    const shouldShowInfo = errorData.length === 0 && fileData.length === 0;

                    return (
                      <ListItem
                        key={column.id}
                        secondaryAction={
                          <Icon
                            fontSize="small"
                            sx={{
                              color: shouldShowInfo
                                ? stylesIcons[null].color
                                : hasError
                                  ? stylesIcons[true].color
                                  : stylesIcons[false].color,
                              animation: isLoadingImport.isLoading ? 'spin 1s linear infinite' : 'none',
                            }}
                          >
                            {isLoadingImport.isLoading
                              ? 'autorenew'
                              : shouldShowInfo
                                ? stylesIcons[null].icon
                                : hasError
                                  ? stylesIcons[true].icon
                                  : stylesIcons[false].icon}
                          </Icon>
                        }
                        disablePadding
                      >
                        <ListItemText primary={<Typography variant="body2">{column.name}</Typography>} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid xs={12} md={9}>
                <BasicSelect
                  label="Plantilla"
                  name="idPlantilla"
                  id="idPlantilla"
                  options={cat_plantilla}
                  value={selectValue}
                  onChange={(e) => setValueSelect(e.target.value)}
                  sx={{ width: '100%', marginBottom: 2 }}
                  isLoading={isLoadingCat}
                />
                <UploadSingleFile
                  handleFileUpload={validExcel}
                  error={errorData.length && !isLoadingImport.isLoading}
                  fileName="Excel"
                  acceptFiles=".xlsx"
                  height={
                    errorData.length > 0 && !isLoadingImport.isLoading
                      ? 320
                      : isLoadingImport.isLoading
                        ? 280
                        : 375
                  }
                  reset={resetUpload}
                />
                {errorData.length > 0 && !isLoadingImport.isLoading ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6} lg={4}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} gap={2}>
                        <Box>
                          <DownloadButton
                            text="Descargar Formato con errores"
                            fileData={fileDataWithError}
                            errorData={errorData}
                            rowStart={excelRowStart}
                            headers={columns_required}
                          />
                        </Box>
                        <Box>
                          <ChipResume
                            text={'Errores encontrados: ' + numberWithCommas(errorData.length)}
                            color={errorData ? '#E55F5F' : '#77BB99'}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                <Box display="flex" justifyContent="center" mt={4}>
                  <Button
                    variant="contained"
                    color="primaryDark"
                    size="small"
                    startIcon={<Icon>save</Icon>}
                    onClick={handleSave}
                    sx={{ mb: 1 }}
                    disabled={errorData.length > 0 || selectValue === '' || fileData.length === 0}
                  >
                    Guardar datos
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box marginTop={2}>
              <Divider sx={{ mb: 2 }} />
              <Stack direction={'row'} alignItems={'center'} justifyContent="space-between" marginBottom={2}>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <Icon fontSize="small">info</Icon>
                  <Typography variant="body2" fontWeight={600}>
                    Información cargada
                  </Typography>
                </Stack>
                {/* <Button
                  variant="outlined"
                  color="primaryDark"
                  size="small"
                  startIcon={<Icon>download</Icon>}
                  // onClick={formik.handleSubmit}
                  sx={{ mb: 1 }}
                >
                  Exportar Reconocimientos
                </Button> */}
              </Stack>
              <Divider sx={{ mb: errorData.length > 0 ? null : 2 }} />
              {errorData.length > 0 && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Alert severity="info" sx={{ mt: 2, maxWidth: 'fit-content' }}>
                    No se pueden cargar los datos debido a que existen errores. Se sugiere corregir el archivo
                    Excel y volver a subirlo, o subir solo los datos que cumplan con la validación.
                  </Alert>
                </Box>
              )}
              <CustomTable
                rows={fileData}
                columns={columns}
                page={page}
                pageSize={pageSize}
                stickyHeader={true}
                isLoading={isLoadingImport.isLoading}
                maxHeight={400}
                disableCardType
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UploadInformation;
