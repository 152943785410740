import { PrivilegedRoute } from '@middlewares';

import TableElectoral from '@pages/Electoral/TableElectoral';
import AvanceCasillas from '@pages/Electoral/AvanceCasillas';
import StructElectoral from '@pages/Electoral/StructElectoral';
import {
  PERFIL_RC,
  PERFIL_RG,
  PERFIL_ABOGADO,
  PERFIL_CASA,
  PERFIL_FiL,
  PERFIL_OBSERVER,
} from '@data/constants';
import ResponsablesElectoral from '@pages/Electoral/ResponsablesElectoral';
import AvanceResponsables from '@pages/Electoral/AvanceResponsables';
import AvanceMeta from '@pages/Electoral/AvanceMeta';
import Insaculado from '@pages/Electoral/Insaculado';
import ExportRCS from '@pages/Electoral/ExportRCS';
import ExportRGS from '@pages/Electoral/ExportRGS';
import ListCapacitacion from '@pages/Electoral/ListCapacitacion';
import Eventos from '@pages/Events/Eventos';

const ElectoralRoutes = [
  {
    path: '/rcs',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_RC} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rgs',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_RG} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/abogados',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_ABOGADO} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/casa_azul',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_CASA} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/primero-fila',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_FiL} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/observador',
    element: (
      <PrivilegedRoute>
        <TableElectoral idPerfil={PERFIL_OBSERVER} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/AvanceCasillas',
    element: (
      <PrivilegedRoute>
        <AvanceCasillas />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/estructura-electoral',
    element: (
      <PrivilegedRoute>
        <StructElectoral />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/directorio-electoral',
    element: (
      <PrivilegedRoute>
        <ResponsablesElectoral />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/avance-estructura',
    element: (
      <PrivilegedRoute>
        <AvanceResponsables />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/avance-meta',
    element: (
      <PrivilegedRoute>
        <AvanceMeta />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/insaculado',
    element: (
      <PrivilegedRoute>
        <Insaculado />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/export-rcs',
    element: (
      <PrivilegedRoute>
        <ExportRCS />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/capacitaciones',
    element: (
      <PrivilegedRoute>
        <Eventos id="capacitaciones" URL="/eventos/" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/export-rgs',
    element: (
      <PrivilegedRoute>
        <ExportRGS />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/capacitacion-asistencia',
    element: (
      <PrivilegedRoute>
        <ListCapacitacion />
      </PrivilegedRoute>
    ),
  },
];

export default ElectoralRoutes;
