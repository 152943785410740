import React, { Fragment } from "react";
import { Button } from "@mui/material";

const ButtonCancelarRecibir = ({ btnDiferente, btnIgual, label, item }) => {
	return (
		<Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={() =>
					label === "Cancelar" ? btnDiferente(item) : btnIgual(item)
				}
			>
				{label}
			</Button>
		</Fragment>
	);
};

export default ButtonCancelarRecibir;
