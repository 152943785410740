export const style_spinner = {
  height: '500pt',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const style_spinner_loading = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  minWidth: '100vh',
};
