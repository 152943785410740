import { useState, useEffect } from 'react';

// Material UI
import { Button, Grid, Box, Stack, Divider } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

import { AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL } from '@data/constants';

const Filter = (props) => {
  const { onSearch, isLoadingCatalogs, catalogs } = props;

  const clearFilter = { region: 0, municipio: 0, df: 0, dl: 0, seccion: 0, casilla: 0 };

  const [filter, setFilter] = useState(clearFilter);
  const [tabValue, setTabValue] = useState(AMBITO_REGIONAL);
  const [municipios, setMunicipios] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [casillas, setCasillas] = useState([]);

  useEffect(() => {
    if (catalogs?.municipios_reportes) setMunicipios(catalogs.municipios_reportes);
    if (catalogs?.secciones) setSecciones(catalogs.secciones);
    if (catalogs?.casillas) setCasillas([{ value: 0, label: 'TODAS' }]);
    // eslint-disable-next-line
  }, [catalogs]);

  const handleChangeFilter = ({ name, value }) => setFilter((prev) => ({ ...prev, [name]: value }));

  const handleGlobal = ({ name, value }) => {
    switch (name) {
      case 'region':
        if (value !== 0) {
          const newMun = catalogs.municipios_reportes.filter((item) => item.idRegion === value);
          setMunicipios([{ label: 'TODOS', value: 0 }, ...newMun]);

          const newSec = catalogs.secciones.filter((item) => item.idRegion === value);
          setSecciones([{ label: 'TODAS', value: 0 }, ...newSec]);
        } else {
          setMunicipios(catalogs.municipios_reportes);
          setSecciones(catalogs.secciones);
        }

        handleChangeFilter({ name: 'municipio', value: 0 });
        handleChangeFilter({ name: 'seccion', value: 0 });
        handleChangeFilter({ name: 'casilla', value: 0 });
        break;
      case 'df':
        if (value !== 0) {
          const newSec = catalogs.secciones.filter((item) => item.idDF === value);
          setSecciones([{ label: 'TODAS', value: 0 }, ...newSec]);
        } else setSecciones(catalogs.secciones);

        handleChangeFilter({ name: 'seccion', value: 0 });
        handleChangeFilter({ name: 'casilla', value: 0 });
        break;
      case 'dl':
        if (value !== 0) {
          const newSec = catalogs.secciones.filter((item) => item.idDL === value);
          setSecciones([{ label: 'TODAS', value: 0 }, ...newSec]);
        } else setSecciones(catalogs.secciones);

        handleChangeFilter({ name: 'seccion', value: 0 });
        handleChangeFilter({ name: 'casilla', value: 0 });
        break;
      case 'municipio':
        if (value !== 0) {
          const newSec = catalogs.secciones.filter((item) => item.idMunicipioReportes === value);
          setSecciones([{ label: 'TODAS', value: 0 }, ...newSec]);
        } else setSecciones(catalogs.secciones);

        handleChangeFilter({ name: 'seccion', value: 0 });
        handleChangeFilter({ name: 'casilla', value: 0 });
        break;
      case 'seccion':
        if (value !== 0) {
          const newCas = catalogs.casillas.filter((item) => item.Seccion === value);
          setCasillas([{ label: 'TODAS', value: 0 }, ...newCas]);
        } else setCasillas(catalogs.casillas);

        handleChangeFilter({ name: 'casilla', value: 0 });
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    const filters = [];

    switch (tabValue) {
      case '0':
        if (filter.municipio > 0) filters.push({ id: 'rutas_mrt.idMunicipioReportes', key: 'municipio' });
        else if (filter.region > 0) {
          const allMun = municipios.slice(1).map((item) => item.value);
          filters.push({ id: 'rutas_mrt.idMunicipioReportes', key: 'region', value: allMun, filter: 'IN' });
        }
        break;
      case '1':
        filters.push({ id: 'rutas_mrt.idDF', key: 'df' });
        break;
      case '2':
        filters.push({ id: 'rutas_mrt.idDL', key: 'dl' });
        break;
      default:
        break;
    }

    filters.push(
      { id: 'rutas_casillas_mrt.Seccion', key: 'seccion' },
      { id: 'rutas_casillas_mrt.idCasilla', key: 'casilla' }
    );

    const filtered = filters
      .filter((item) => filter[item.key] > 0)
      .map((item) => ({ id: item.id, filter: item.filter ?? '=', value: item.value ?? filter[item.key] }));

    onSearch(filtered);
  };

  const handleClear = () => {
    setFilter(clearFilter);
    setMunicipios(catalogs.municipios_reportes);
    setSecciones(catalogs.secciones);
    setCasillas([{ value: 0, label: 'TODAS' }]);
    onSearch([]);
  };

  const handleAgrupado = (value) => {
    if (!isLoadingCatalogs) {
      switch (value) {
        case AMBITO_REGIONAL:
          break;
        case AMBITO_DF:
          break;
        case AMBITO_DL:
          break;
        default:
          break;
      }
      if (catalogs.secciones.length !== secciones.length) setSecciones(catalogs.secciones);
      if (catalogs.casillas.length !== casillas.length) setCasillas(catalogs.casillas);
      setTabValue(value);
    }
  };

  const isIncluded = (value) => value.includes(tabValue);

  return (
    <Box sx={{ paddingX: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BasicSelect
            name="agrupador"
            label="Agrupar por"
            options={[
              { value: AMBITO_REGIONAL, label: 'Región' },
              { value: AMBITO_DF, label: 'DFED' },
              { value: AMBITO_DL, label: 'DLOC' },
            ]}
            value={tabValue}
            onChange={(e) => handleAgrupado(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {isIncluded([AMBITO_REGIONAL]) && (
          <>
            <Grid item xs={12}>
              <BasicSelect
                name="region"
                label="Región"
                options={catalogs?.regiones}
                value={filter.region}
                onChange={(e) => {
                  handleGlobal({ name: e.target.name, value: e.target.value });
                  handleChangeFilter({ name: e.target.name, value: e.target.value });
                }}
                isLoading={isLoadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicSelect
                name="municipio"
                label="Municipio"
                options={municipios}
                value={filter.municipio}
                onChange={(e) => {
                  handleGlobal({ name: e.target.name, value: e.target.value });
                  handleChangeFilter({ name: e.target.name, value: e.target.value });
                }}
                isLoading={isLoadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid>
          </>
        )}

        {isIncluded([AMBITO_DF]) && (
          <Grid item xs={12}>
            <BasicSelect
              name="df"
              label="DFED"
              options={catalogs?.df}
              value={filter.df}
              onChange={(e) => {
                handleGlobal({ name: e.target.name, value: e.target.value });
                handleChangeFilter({ name: e.target.name, value: e.target.value });
              }}
              isLoading={isLoadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}

        {isIncluded([AMBITO_DL]) && (
          <Grid item xs={12}>
            <BasicSelect
              name="dl"
              label="DLOC"
              options={catalogs?.dl}
              value={filter.dl}
              onChange={(e) => {
                handleGlobal({ name: e.target.name, value: e.target.value });
                handleChangeFilter({ name: e.target.name, value: e.target.value });
              }}
              isLoading={isLoadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <AdvancedSelect
            name="seccion"
            label="Sección"
            options={secciones}
            value={filter.seccion}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
              handleGlobal({ name: e.name, value: e.value });
            }}
            isSearchable
            isLoading={isLoadingCatalogs}
            disabled={secciones?.length <= 1}
          />
        </Grid>
        <Grid item xs={12}>
          <AdvancedSelect
            name="casilla"
            label="Casilla"
            options={casillas}
            value={filter.casilla}
            onChange={(e) => {
              handleChangeFilter({ name: e.name, value: e.value });
              handleGlobal({ name: e.name, value: e.value });
            }}
            isSearchable
            isLoading={isLoadingCatalogs}
            disabled={casillas?.length <= 1 || filter.seccion === 0}
          />
        </Grid>
      </Grid>

      <Stack direction={'row'} spacing={1} marginTop={2} justifyContent={'flex-end'}>
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
          Limpiar
        </Button>
        <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
          Filtrar
        </Button>
      </Stack>
    </Box>
  );
};

export default Filter;
