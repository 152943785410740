import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { Grid, CardContent, Card } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import TableScroll from '@components/Electoral/TableScroll';

// Servicios
import electoral from '@services/ElectoralServices';

const ListCapacitacion = () => {
  const [dataCard, setDataCard] = useState({
    Total: 0,
    Avance: 0,
    PorcentajeAvance: 0,
    Pendiente: 0,
    PorcentajePendiente: 0,
    Contactado: 0,
    PorcentajeContactado: 0,
    NoLocalizado: 0,
    PorcentajeNoLocalizado: 0,
  });
  const [loadingCard, setLoadingCard] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getCards([]);
  }, [refresh]);

  const getCards = async (filtered) => {
    try {
      setLoadingCard(true);

      const result = await electoral.getStatsCapacitacion({ filtered });
      const { results, response, message } = result;

      if (results) setDataCard(response.data[0]);
      else throw new Error(message);
    } catch (e) {
      setDataCard({});
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingCard(false);
    }
  };

  const handleFilter = (data) => getCards(data);

  return (
    <AdminLayout>
      <ActionHeader title={'Pase de lista capacitación'} />
      <Grid container spacing={2} sx={{ flexDirection: { sm: 'column-reverse', md: 'row' } }}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          <Card>
            <CardContent>
              <TableScroll onFilter={handleFilter} refresh={(e) => setRefresh(!refresh)} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <Grid container spacing={2}>
            {/*            <Grid item xs={12} sm={6} md={12}>
              <AdaptiveCard
                title="Participación"
                icon="people"
                config={{ percent: dataCard?.PorcentajeAvance || 0 }}
                leftConfig={{ title: "Avance", data: dataCard?.Avance || 0 }}
                rightConfig={{ title: "Total", data: dataCard?.Total || 0 }}
                fixed
                adjust
                loading={loadingCard}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={12}>
              <AdaptiveCard
                config={{
                  title: 'Capacitados',
                  data: dataCard?.Capacitados || 0,
                }}
                leftConfig={{
                  title: 'RCS',
                  data: dataCard?.RCS || 0,
                  percent: dataCard['%RCS'] || 0,
                }}
                rightConfig={{
                  title: 'RGS',
                  data: dataCard?.RGS || 0,
                  percent: dataCard['%RGS'] || 0,
                }}
                fixed
                adjust
                loading={loadingCard}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
export default ListCapacitacion;
