import React, { useState } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import icopdf from '../../assets/img/iconos/pdf.png';
import icodocx from '../../assets/img/iconos/docx.png';
import icoxlsx from '../../assets/img/iconos/xlsx.png';
import icoimg from '../../assets/img/iconos/img.png';
import icorar from '../../assets/img/iconos/rar.png';
import icopptx from '../../assets/img/iconos/pptx.png';
import icounknow from '../../assets/img/iconos/unknow.png';
import icozip from '../../assets/img/iconos/zip.png';

const tiposMimePermitidos = {
  'application/pdf': icopdf,
  'application/msword': icodocx,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': icodocx,
  'application/vnd.ms-excel': icoxlsx,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': icoxlsx,
  'application/vnd.ms-powerpoint': icopptx,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': icopptx,
  'application/x-rar-compressed': icorar,
  'application/zip': icozip,
  'image/jpeg': icoimg,
  'image/png': icoimg,
  'image/gif': icoimg,
};

const FileUpload = ({ title, onFilesChange }) => {
  const [archivosOficios, setArchivosOficio] = useState([]);

  const validarMimeType = (archivo) => {
    return tiposMimePermitidos.hasOwnProperty(archivo.type);
  };

  const obtenerIconoArchivo = (archivo) => {
    const { type, name } = archivo;

    // Verificar primero si el tipo MIME coincide con los permitidos
    if (tiposMimePermitidos[type]) {
      return tiposMimePermitidos[type];
    }

    // Si el MIME-Type no es confiable, usar la extensión como respaldo
    const extension = name.split('.').pop().toLowerCase();
    return obtenerIconoPorExtension(extension);
  };

  const obtenerIconoPorExtension = (extension) => {
    switch (extension) {
      case 'pdf':
        return icopdf;
      case 'doc':
      case 'docx':
        return icodocx;
      case 'xls':
      case 'xlsx':
        return icoxlsx;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return icoimg;
      case 'rar':
        return icorar;
      case 'ppt':
      case 'pptx':
        return icopptx;
      case 'zip':
        return icozip;
      default:
        return icounknow;
    }
  };

  const handleFileChange = (event) => {
    const nuevosArchivos = Array.from(event.target.files);
    const archivosValidados = nuevosArchivos.filter((archivo) => validarMimeType(archivo));

    if (archivosValidados.length !== nuevosArchivos.length) {
      alert('Algunos archivos no son válidos. Verifique el tipo de archivo.');
    }

    const newFiles = archivosValidados.map((archivo) => ({
      url: URL.createObjectURL(archivo),
      name: archivo.name,
      mimeType: archivo.type,
      size: archivo.size,
    }));

    console.log('=>newFiles: ', newFiles);

    //setArchivosOficio([...archivosOficios, ...newFiles]);

    //const newFiles = [...archivosOficios, ...archivosValidados];
    const allFilesNew = [...archivosOficios, ...newFiles];

    setArchivosOficio(allFilesNew);
    onFilesChange(allFilesNew);
  };

  const eliminarArchivo = (index) => {
    const newFiles = archivosOficios.filter((_, i) => i !== index);
    setArchivosOficio(newFiles);
    onFilesChange(newFiles);
  };

  const formatoTamaño = (size) => {
    return size >= 1048576 ? `${(size / 1048576).toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button variant="contained" component="label">
          {title}
          <input type="file" multiple hidden onChange={handleFileChange} />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {archivosOficios.map((archivo, index) => (
            <Grid item key={index}>
              <Tooltip title={archivo.name}>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '80px',
                    height: '100px',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    cursor: 'pointer',
                    padding: '8px',
                  }}
                >
                  <img
                    src={obtenerIconoArchivo(archivo)}
                    alt={archivo.name}
                    style={{ width: '30px', height: '40px' }}
                  />
                  <Typography variant="body2" align="center" sx={{ marginTop: '4px' }}>
                    {formatoTamaño(archivo.size)}
                  </Typography>
                  <IconButton
                    size="small"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                    }}
                    onClick={() => eliminarArchivo(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
