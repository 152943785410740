import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Button, TextField, InputAdornment, IconButton, Icon, Tooltip } from '@mui/material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';

// Componentes
import BasicTable from '@components/Tables/BasicTable';

// Servicios
import PolygonService from '@services/MapServices/PolygonServices';

const Search = ({ handleGetShapePolygon }) => {
  const columns = [
    { id: 'Poligono', label: 'Polígono', numeric: true },
    { id: 'Secciones', label: 'Secciones', numeric: true },
    { id: 'Lnom', label: 'LNOM', numeric: true },
  ];
  const [polygons, setPolygons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    page: 0,
    pageSize: 5,
    filtered: [],
    sorted: [],
  });

  useEffect(() => {
    getPolygons();
    // eslint-disable-next-line
  }, [data]);
  const getPolygons = () => {
    setLoading(true);
    PolygonService.getPolygons(data)
      .then((res) => {
        if (res.results) {
          const polygonsData = res.response.data.map((item) => {
            return {
              Poligono: (
                <Button
                  variant="text"
                  color="primaryDark"
                  size="small"
                  style={{ fontSize: '10px' }}
                  onClick={() => handleEdit(item)}
                >
                  {item.Poligono}
                  <br />
                  {item.Municipio} - {'DF:' + item.idDF} - {'DL:' + item.idDL}
                </Button>
              ),
              Secciones: (
                <div style={{ fontSize: '10px' }}>
                  <b>T:</b>[{item.TotalSecciones}]<br />
                  {item.Secciones}
                </div>
              ),
              Lnom: (
                <div style={{ fontSize: '10px' }}>
                  <b>LNOM:</b> {item.Lnom}
                  <br />
                  <b>LNOMM:</b> {item.LnomM}
                  <br />
                  <b>LNOMH:</b> {item.LnomH}
                </div>
              ),
            };
          });

          setPolygons(polygonsData);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = (e) => {
    if (e.key === 'Enter' && e.target.value.length !== 0) {
      const _isNaN = !isNaN(e.target.value);
      if (_isNaN)
        setData({
          ...data,
          filtered: [
            {
              id: 'cat_secciones.Seccion',
              filter: '=',
              value: parseInt(e.target.value),
            },
          ],
        });
      else
        setData({
          ...data,
          filtered: [
            {
              id: 'concat(creacion_poligonos.Poligono,cat_municipios.Municipio)',
              filter: 'LIKE',
              value: e.target.value,
            },
          ],
        });
    }
  };

  const handleClean = () => {
    setSearch('');
    setData({
      ...data,
      filtered: [],
    });
  };

  const handleEdit = (item) => {
    handleGetShapePolygon(item);
  };
  return (
    <>
      <Grid container rowSpacing={0}>
        {/* <Grid item sm={12}>
         
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            sx={{ width: '100%' }}
            label="Buscar"
            type="text"
            name="Buscar"
            placeholder="Buscar por seccion, polígono, etc."
            onChange={handleChange}
            onKeyDown={handleSearch}
            value={search.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Buscar">
                    <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="label"
                      sx={{ m: -1 }}
                      onClick={() => handleSearch({ key: 'Enter', target: { value: search } })}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Limpiar">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={() => handleClean()}
                    >
                      <CleaningServicesOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <BasicTable
            rows={polygons}
            hcolumns={columns}
            pageProp={data.page}
            pageSize={data.pageSize}
            total={total}
            handleChangePagination={handleChangePagination}
            isLoading={loading}
            stickyHeader={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Search;
