import React from 'react';
import {
  DialogTitle,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  Divider,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

function Respuestas(props) {
  const { onClose, open, data } = props;
  let respuestas = [
    'a) Estructura Electoral (Representantes en OPLEs, RGs, RCs, Jurídico, Observadores, Primero en Fila, Guardianes de Sección, Recolección, etc.)',
    'b) Estructura Promoción (Territorial)',
    'c) Estructura Social (Segmentos, PPM o AJ)',
    'd) Campaña (Candidaturas, Equipo de campaña o integrante de CDM)',
    'e) Capacitación',
    'f) Ninguna',
  ];
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  const getIconAndColor = (value) => {
    if (value >= 9) {
      return {
        icon: <SentimentVerySatisfied fontSize="large" />,
        color: theme.palette.success.main,
      };
    } else if (value >= 7) {
      return {
        icon: <SentimentSatisfiedAlt fontSize="large" />,
        color: theme.palette.success.light,
      };
    } else if (value >= 5) {
      return {
        icon: <SentimentSatisfied fontSize="large" />,
        color: theme.palette.warning.main,
      };
    } else if (value >= 3) {
      return {
        icon: <SentimentDissatisfied fontSize="large" />,
        color: theme.palette.error.light,
      };
    } else {
      return {
        icon: <SentimentVeryDissatisfied fontSize="large" />,
        color: theme.palette.error.main,
      };
    }
  };

  const getIconAndColorEScala = (value) => {
    if (value === 'MUY BUENO') {
      return {
        icon: <SentimentVerySatisfied fontSize="large" />,
        color: theme.palette.success.main,
        value: 'MUY BUENO',
      };
    } else if (value === 'BUENO') {
      return {
        icon: <SentimentSatisfiedAlt fontSize="large" />,
        color: theme.palette.success.light,
        value: 'BUENO',
      };
    } else if (value === 'REGULAR') {
      return {
        icon: <SentimentSatisfied fontSize="large" />,
        color: theme.palette.warning.main,
        value: 'REGULAR',
      };
    } else if (value === 'MALO') {
      return {
        icon: <SentimentDissatisfied fontSize="large" />,
        color: theme.palette.error.light,
        value: 'MALO',
      };
    } else {
      return {
        icon: <SentimentVeryDissatisfied fontSize="large" />,
        color: theme.palette.error.main,
        value: 'MUY MALO',
      };
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={{ textAlign: 'center' }}>
      <DialogTitle
        sx={{
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.h4.fontSize,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        Mecanismo Reflexivo 2023-2024
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: -2,
          top: -2,
          color: 'red',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: theme.spacing(3) }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              Del método de selección que fue acordado conforme a la normatividad interna del PAN, ¿Con que
              calificación lo evalúas?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColorEScala(data.Pregunta1).color,
              }}
            >
              {getIconAndColorEScala(data.Pregunta1).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {getIconAndColorEScala(data.Pregunta1).value}
              </Typography>
            </Box>
          </Grid>

          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />

          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              Menciona dos motivos por el cual consideras tu calificación para la selección de candidaturas:
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Motivo 1:
            </Typography>
            <Typography variant="body1" color="primary" sx={{ mb: theme.spacing(2) }}>
              {data.Motivo1}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Motivo 2:
            </Typography>
            <Typography variant="body1" color="primary">
              {data.Motivo2}
            </Typography>
          </Grid>

          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />

          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              Para evaluar el alcance de las propuestas y mensajes de campaña, contesta las siguientes
              preguntas:
            </Typography>
            {[
              { label: 'ESTATAL', value: 'LemaEstatal' },
              { label: 'NACIONAL', value: 'LemaNacional' },
              { label: 'AYUNTAMIENTO', value: 'PropuestaAyuntamiento' },
              { label: 'GOBERNATURA', value: 'PropuestaGobernatura' },
              { label: 'DLOC', value: 'PropuestaDLOC' },
              { label: 'DFED', value: 'PropuestaDFED' },
              { label: 'SENADO PRESIDENCIA', value: 'PropuestaSenadoPresidencia' },
            ].map((categoria, index) => (
              <Grid container key={index} sx={{ mb: theme.spacing(2) }}>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    {categoria.label}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Paper
                    elevation={0}
                    sx={{
                      padding: theme.spacing(1),
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Typography variant="body1" color="primary">
                      {data[categoria.value]}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de campaña de tú Comité Directivo Estatal?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta4).color,
              }}
            >
              {getIconAndColor(data.Pregunta4).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta4}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de campaña de tú Comité Directivo Municipal?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta5).color,
              }}
            >
              {getIconAndColor(data.Pregunta5).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta5}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la Gubernatura?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta6).color,
              }}
            >
              {getIconAndColor(data.Pregunta6).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta6}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Ayuntamiento de tu
              municipio?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta7).color,
              }}
            >
              {getIconAndColor(data.Pregunta7).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta7}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación local de tu
              municipio?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta8).color,
              }}
            >
              {getIconAndColor(data.Pregunta8).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta8}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación federal de
              tu municipio?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta9).color,
              }}
            >
              {getIconAndColor(data.Pregunta9).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta9}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Presidencia de la
              República?.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta10).color,
              }}
            >
              {getIconAndColor(data.Pregunta10).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta10}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas el trabajo de quienes encabezaron la candidatura del PAN al Senado?.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: theme.spacing(2),
                color: getIconAndColor(data.Pregunta11).color,
              }}
            >
              {getIconAndColor(data.Pregunta11).icon}
              <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                {data.Pregunta11}
              </Typography>
            </Box>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }}>12</Divider>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas tú trabajo en el Proceso Electoral 2023-2024?.
            </Typography>
            <Grid container sx={{ mb: theme.spacing(2) }}>
              <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">{`A) Calificación:`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: theme.spacing(2),
                    color: getIconAndColor(data.Pregunta12).color,
                  }}
                >
                  {getIconAndColor(data.Pregunta12).icon}
                  <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                    {data.Pregunta12}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mb: theme.spacing(2) }}>
              <Grid item xs={4}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                >{`B) ¿En qué consistió tú participación electoral?`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: theme.spacing(1),
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  <Typography variant="body1" color="primary">
                    {data.Pregunta12option}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', my: theme.spacing(2) }} />
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                mb: theme.spacing(1),
                color: theme.palette.text.primary,
              }}
            >
              ¿Cómo calificas tú trabajo en el Proceso Electoral 2023-2024?.
            </Typography>
            {[
              { label: 'a) Acciones del Gobierno Municipal', value: 'Pregunta13a' },
              { label: 'b) Acciones del Gobierno Estatal', value: 'Pregunta13b' },
              { label: 'c) Acciones del Gobierno Federal', value: 'Pregunta13c' },
              { label: 'd) Estructuras Electorales', value: 'Pregunta13d' },
            ].map((item) => {
              return (
                <Grid container sx={{ mb: theme.spacing(2) }}>
                  <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">
                      {item.label}:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: theme.spacing(2),
                        color: getIconAndColor(data[item.value]).color,
                      }}
                    >
                      {getIconAndColor(data[item.value]).icon}
                      <Typography variant="h4" sx={{ ml: theme.spacing(1), fontWeight: 'bold' }}>
                        {data[item.value]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Respuestas;
