import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Paper,
  InputBase,
  IconButton,
  List,
  LinearProgress,
  Typography,
  Button,
} from '@mui/material';
import Select from 'react-select';
import { useCatalogs } from '@hooks/useCatalogs';
import Icon from '@mui/material/Icon';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alert from '@mui/material/Alert';
import Grid2 from '@mui/material/Unstable_Grid2';

const BasicList = (props) => {
  const {
    Header = true,
    search,
    configParams = {
      title: 'Listado',
      icon: 'list_alt',
      height: 400,
      endMessage: 'No hay más registros para mostrar',
    },
    data,
    fetchHasMore,
    totalItems = 0,
    itemComponent,
    hasMore,
    handleClickItem = () => {},
    finder = true,
    handleDeleteItem = () => {},
    handleUploadItem,
    handleValidatedItem,
    alertUser = false,
    onClick = () => {},
    df = false,
    estatus = false,
  } = props;

  const catalogsOptions = [{ id: 'df' }, { id: 'estatus_certificacion_ine' }];
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [searchInput, setSearchInput] = useState({
    Nombre: '',
    DF: { value: 0, label: 'TODOS' },
    Estatus: { value: 0, label: 'TODOS' },
  });

  const [config, setConfig] = useState({
    title: 'Listado',
    icon: 'list_alt',
    height: 400,
    placeholder: 'Buscar usuario',
    endMessage: 'No hay más registros para mostrar',
    alertMessage: 'Reportar',
    buttonName: 'Submit',
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setListingData(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const handleSelectedIndex = (index, data) => {
    setSelectedIndex(index);
    handleClickItem(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    search(searchInput);
  };

  const handleChangeInputSearch = (type, value) => {
    let filtro = {};

    if (type === 1) {
      if (value.length > 0) {
        setSearchInput({
          ...searchInput,
          Nombre: value,
        });
        filtro = {
          ...filtro,
          Nombre: value,
        };
      }
      if (value.length < 1) {
        setSearchInput({
          ...searchInput,
          Nombre: '',
        });
      }
    }
    if (type !== 1 && searchInput.Nombre.length > 0) {
      filtro = {
        ...filtro,
        Nombre: searchInput.Nombre,
      };
    }
    if (type === 2) {
      if (value.value > 0) {
        setSearchInput({
          ...searchInput,
          DF: value,
        });
        filtro = {
          ...filtro,
          DF: value.value,
        };
      }
      if (value.value === 0) {
        setSearchInput({
          ...searchInput,
          DF: value,
        });
      }
    }
    if (type !== 2 && searchInput.DF.value > 0) {
      filtro = {
        ...filtro,
        DF: searchInput.DF.value,
      };
    }
    if (type === 3) {
      if (value.value > 0) {
        setSearchInput({
          ...searchInput,
          Estatus: value,
        });
        filtro = {
          ...filtro,
          Estatus: value.value,
        };
      }
      if (value.value === 0) {
        setSearchInput({
          ...searchInput,
          Estatus: value.value,
        });
      }
    }
    if (type !== 3 && searchInput.Estatus.value > 0) {
      filtro = {
        ...filtro,
        Estatus: searchInput.Estatus.value,
      };
    }

    search(filtro);
  };

  return (
    <Card sx={{ width: '100%' }} className="card-secondary">
      {Header && <CardHeader avatar={<Icon>{config.icon}</Icon>} title={config.title} />}
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 xs={6}>
            {df && (
              <Select
                options={[{ value: 0, label: 'TODOS' }].concat(catalogs.df)}
                className="react-select primary"
                classNamePrefix="react-select"
                value={searchInput.DF}
                onChange={(e) => handleChangeInputSearch(2, e)}
                placeholder="Distrito Federal"
                //isLoading={isLoading}
              />
            )}
          </Grid2>
          <Grid2 xs={6}>
            {estatus && (
              <Select
                options={[{ value: 0, label: 'TODOS' }].concat(catalogs.estatus_certificacion_ine)}
                className="react-select primary"
                classNamePrefix="react-select"
                value={searchInput.Estatus}
                onChange={(e) => handleChangeInputSearch(3, e)}
                placeholder="Estatus"
                //isLoading={isLoading}
              />
            )}
          </Grid2>
          <Grid2 xs={12}>
            {finder && (
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onSubmit={(e) => handleSubmit(e)}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={config.placeholder}
                  inputProps={{ 'aria-label': config.placeholder }}
                  value={searchInput.Nombre}
                  onChange={(e) => handleChangeInputSearch(1, e.target.value)}
                />
                <IconButton
                  type="submit"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => handleChangeInputSearch(4)}
                >
                  <Icon>search</Icon>
                </IconButton>
              </Paper>
            )}
          </Grid2>
        </Grid2>
        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>
                {config.endMessage !== undefined ? config.endMessage : '¡Final de la lista de registros!'}
              </b>
            </p>
          }
        >
          <List dense={true}>
            {listingData.map((i, index) => (
              <div key={index}>
                {itemComponent({
                  item: i,
                  selectedIndex: selectedIndex,
                  handleSelectedIndex: handleSelectedIndex,
                  index: index,
                  handleDeleteItem: handleDeleteItem,
                  handleUpload: handleUploadItem,
                  handelValidete: handleValidatedItem,
                })}
              </div>
            ))}
          </List>
        </InfiniteScroll>

        {alertUser && (
          <Alert
            severity="warning"
            style={{
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid2 style={{ display: 'flex', alignItems: 'center' }}>
              <Typography mr={2}>{config.alertMessage}</Typography>
              <Button variant="contained" size="small" color="primaryDark" onClick={onClick}>
                {config.buttonName}
              </Button>
            </Grid2>
          </Alert>
        )}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="body2" sx={{ color: '#888888' }}>
          Mostrando {showedItems} de {totalItems}
        </Typography>
      </CardActions>
    </Card>
  );
};

BasicList.propTypes = {
  data: PropTypes.array.isRequired,
  fetchHasMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  itemComponent: PropTypes.any.isRequired,
  totalItems: PropTypes.number.isRequired,
  configParams: PropTypes.object,
  handleClickItem: PropTypes.func,
  finder: PropTypes.bool,
  handleDeleteItem: PropTypes.func,
};

export default BasicList;
