import { useEffect, useState } from 'react';

import { Button, Card, CardContent, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import BasicSelect from '@components/Selects/BasicSelect';

import CatalogServices from '@services/CatalogServices';
import Swal from 'sweetalert2';
import { CATALOGS_OPTIONS_REGION_MUNICIPIO } from '@data/constants/Rco';

const FilterHistory = ({ onChangeFilter, onClearFilter }) => {
  const defaultOption = { value: 0, label: 'TODOS' };

  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    Object.keys(CATALOGS_OPTIONS_REGION_MUNICIPIO).forEach((key) => {
      CATALOGS_OPTIONS_REGION_MUNICIPIO[key.id] = [];
    });

    return newObject;
  });
  const [catalogs, setCatalogs] = useState([]);
  const [filterValue, setFilterValue] = useState({
    region: 0,
    municipio: 0,
  });

  const getCatalogs = async () => {
    setLoadingCatalogs(true);
    try {
      const result = await CatalogServices.getCatalogs(CATALOGS_OPTIONS_REGION_MUNICIPIO);
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeFilter = ({ name, value }) => {
    setFilterValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: [defaultOption, ...catalogs[filterKey].filter((item) => item[filterType] === filterValue)],
    }));
  };

  const handleChangeRegion = ({ event }) => {
    const _idRegion = event.target.value;
    if (_idRegion > 0) {
      handleCatalogFilterChange({
        filterKey: 'municipios_reportes',
        filterType: 'idRegion',
        filterValue: _idRegion,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
      }));
    }
  };

  const handleSearch = () => {
    const filters = [
      {
        id: 'idRegion',
        key: 'region',
      },
      {
        id: 'idMunicipioReportes',
        key: 'municipio',
      },
    ];

    const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: '=',
        value: filterValue[filter.key],
      }));

    onChangeFilter({ filtereds: filtered });
  };

  const handleClear = () => {
    setFilterValue((prevState) => ({
      ...prevState,
      region: 0,
      municipio: 0,
    }));
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    onClearFilter();
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography variant="subtitle2" fontWeight={600} marginBottom={2}>
          FILTROS
        </Typography>
        <Grid2 container spacing={2} width={'40%'}>
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <BasicSelect
              name="region"
              label="Región"
              options={catalogsFiltered.regiones}
              value={
                catalogsFiltered.regiones && catalogsFiltered.regiones?.length === 2
                  ? catalogsFiltered.regiones[1]?.value
                  : filterValue.region
              }
              onChange={(e) => {
                handleChangeFilter({
                  name: e.target.name,
                  value: e.target.value,
                });
                handleChangeRegion({ event: e });
              }}
              isLoading={loadingCatalogs}
              disabled={catalogsFiltered.regiones?.length === 2}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={6}>
            <BasicSelect
              name="municipio"
              label="Municipio"
              options={catalogsFiltered.municipios_reportes}
              value={
                catalogsFiltered.municipios_reportes && catalogsFiltered.municipios_reportes?.length === 2
                  ? catalogsFiltered.municipios_reportes[1]?.value
                  : filterValue.municipio
              }
              onChange={(e) => {
                handleChangeFilter({
                  name: e.target.name,
                  value: e.target.value,
                });
                //handleChangeMunicipality({ event: e });
              }}
              isLoading={loadingCatalogs}
              disabled={catalogsFiltered.municipios_reportes?.length === 2}
              sx={{ width: '100%' }}
            />
          </Grid2>

          {!(
            catalogsFiltered.regiones?.length === 2 && catalogsFiltered.municipios_reportes?.length === 2
          ) && (
            <Grid2 xs={12} sm={12} md={3} lg={3} display={'flex'} alignItems={'center'} gap={2}>
              <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
                Limpiar
              </Button>
              <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
                Filtrar
              </Button>
            </Grid2>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default FilterHistory;
