import { useEffect, useState } from 'react';
import ActionHeader from '@components/Containers/ActionHeader';
import AdminLayout from '@components/MainPage/AdminLayout';
import { Container, Box, Tab, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import TreeStructure from '../../components/Structure/TreeStructure';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SocialResponsible = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Mis Responsables'} />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Árbol" {...a11yProps(0)} />
              {/* <Tab label="Resumen" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TreeStructure />
          </CustomTabPanel>
          {/*  <CustomTabPanel value={value} index={1}>
       <TableStructureResponsable flag = {value}/>
      </CustomTabPanel> */}
        </Box>
      </Container>
    </AdminLayout>
  );
};
export default SocialResponsible;
