import { useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Cancel, Save } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import LoadingForm from '@components/LinearProgress/LoadingForm';

import { URL_IMAGES } from '@utils/global';
import prep from '@services/PrepServices';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const DraggableListItem = ({ item, index }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });
  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
      {(provided, snapshot) => (
        <ListItem
          key={item.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          secondaryAction={
            <Chip
              label={index + 1}
              color="primary"
              variant="outlined"
              size="large"
              sx={{
                fontWeight: '700',
              }}
            />
          }
        >
          <ListItemAvatar>
            <Avatar src={`${URL_IMAGES}/${item.RutaLogo}`} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="div" fontWeight={700} fontSize={15}>
                {item.Partido}
              </Typography>
            }
            secondary={item.Siglas}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const PriorityOrderForm = (props) => {
  const { data } = props;
  const [rows, setRows] = useState([]);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  useEffect(() => {
    if (data) {
      getFormDetail(data);
    }
  }, [data]);

  const getFormDetail = (data) => {
    const params = {
      idTipoEleccion: data.idTipoEleccion,
      AmbitoValor: data.AmbitoValor,
    };

    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    prep
      .getOrderedList(params)
      .then((res) => {
        if (res.results) {
          setRows(res.response.data);
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedRows = reorder(rows, result.source.index, result.destination.index);
    setRows(orderedRows);
  };

  const handleClearForm = () => {
    getFormDetail(data);
  };

  const handleUpdate = async () => {
    const params = {
      idTipoEleccion: data.idTipoEleccion,
      AmbitoValor: data.AmbitoValor,
      Partidos: rows.map((item, index) => {
        return {
          idPartido: item.id,
          OrdenPrelacion: index + 1,
        };
      }),
    };

    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const result = await prep.updateOrderForm(params);
      const { message, results } = await result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };
  return (
    <Box component={'div'}>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={'Cargando...'}
      />

      <Divider sx={{ mb: 2 }} />
      <Typography align="end" component={'div'}>
        <strong>Nota: </strong>Arrastre el elemento de la lista al orden correspondiente
      </Typography>

      <DragDropContext onDragEnd={onDragEnd} sx={{ overflow: 'auto' }}>
        <Droppable droppableId="list">
          {(provided) => (
            <List
              sx={{ width: '100%', overflow: 'auto', ml: 0, mr: 0 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {rows.map((item, index) => (
                <DraggableListItem item={item} index={index} key={item.id} />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid2 container spacing={2}>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            color="error"
            variant="contained"
            size="small"
            onClick={(e) => handleClearForm()}
            startIcon={<Cancel />}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            color="primaryDark"
            variant="contained"
            size="small"
            onClick={(e) => handleUpdate()}
            startIcon={<Save />}
          >
            Guardar
          </Button>
        </Box>
      </Grid2>
    </Box>
  );
};

export default PriorityOrderForm;
