import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, Card, CardHeader, CardContent, Button, Typography } from '@mui/material';
import { Add, ChevronLeft } from '@mui/icons-material';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Prep/FormFilter';
import FormSummaryTable from '@components/Prep/FormSummaryTable';
import PriorityOrderForm from '@components/Prep/PriorityOrderForm';

import middleware from '@middlewares/middleware';

const Planillas = () => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState([]);
  const [flagOrderForm, setFlagOrderForm] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleOrder = (item) => {
    setFormData(item);
    setFlagOrderForm(true);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Administración de Planillas" />

        <Card
          sx={{
            variant: 'elevation',
            borderRadius: '12px',
            padding: 0,
            width: '100%',
          }}
          className="card-primary"
        >
          <CardHeader
            title={
              !flagOrderForm ? (
                'Planillas por tipo de elección'
              ) : (
                <Box component="div">
                  <Typography variant="h5">Configurar Orden de Prelación</Typography>
                  <Typography variant="h6">Elección para {formData.TipoEleccion}</Typography>
                  <Typography component="div">{formData.Ambito}</Typography>
                </Box>
              )
            }
            action={
              middleware.checkMenuAction('Agregar') && !flagOrderForm ? (
                <Button
                  color="primaryDark"
                  size="medium"
                  variant="contained"
                  onClick={(e) => {
                    navigate('/prep-candidatos');
                  }}
                  startIcon={<Add />}
                  sx={{ m: 2 }}
                >
                  Agregar
                </Button>
              ) : (
                flagOrderForm && (
                  <Button
                    color="primaryDark"
                    size="medium"
                    variant="contained"
                    onClick={(e) => setFlagOrderForm(false)}
                    startIcon={<ChevronLeft />}
                    sx={{ m: 2 }}
                  >
                    Regresar
                  </Button>
                )
              )
            }
          />
          <CardContent className="p-0">
            {!flagOrderForm ? (
              <>
                <Filter onChangeFilter={setFiltered} />
                <FormSummaryTable filtered={filtered} handleOrder={handleOrder} />
              </>
            ) : (
              <PriorityOrderForm data={formData} />
            )}
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Planillas;
