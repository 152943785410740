import { useEffect, useState } from 'react';

import Modal from '@components/Modal/Dataview';

import movilizacion from '@services/MovilizationServices';
import { Box, Icon, Skeleton, Typography } from '@mui/material';

const PreviewDocument = ({ open, setOpen, params, title }) => {
  const [urlPreview, setUrlPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const previewDocument = async () => {
    try {
      setLoading(true);
      const res = await movilizacion.previewDocument(params);
      const { success, url } = res;
      setUrlPreview(url);

      if (!success) setError(true);
    } catch (error) {
      setError(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      previewDocument();
    } else {
      setUrlPreview('');
      setError(false);
      setLoading(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hasButtonClose={true}
      headerTitle={title}
      maxWidth="md"
      sxContent={{ p: 0, overflow: 'hidden' }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {loading ? (
          <Skeleton variant="rounded" width={840} height={800} />
        ) : error ? (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            height="80vh"
            alignItems="center"
            flexDirection="column"
          >
            <Icon sx={{ color: 'error.main', fontSize: '100px' }}>hide_image</Icon>
            <Typography variant="body2" fontWeight={600}>
              No se pudo cargar el archivo, contacte al administrador.
            </Typography>
          </Box>
        ) : (
          <object
            data={urlPreview}
            type="application/pdf"
            width="100%"
            style={{
              height: 'calc(100vh - 43px)',
              borderRadius: '12px',
              overflow: 'auto',
            }}
            aria-label="This object displays an PDF file"
          />
        )}
      </Box>
    </Modal>
  );
};

export default PreviewDocument;
