import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import UserServices from '@services/UserServices';
import background_lines from '@assets/img/lines.svg';
import background_pan from '@assets/img/SIAN.png';

let currentOTPIndex = 0;

const Confirmation = () => {
  const { id } = useParams();
  //Variables
  const linearGradient = 'linear-gradient(310deg, rgb(0, 65, 160), rgb(26,102,160))!important';

  // States
  const inputRef = useRef(null);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('=>searchParams[id]: ', id);
  }, [id]);

  //Handles
  const handleChangeOTP = (e) => {
    const { value } = e.target;
    if (isNaN(value)) return false;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);

    /* if (newOTP[5] !== "") {
      handleSendCodeOTP();
    } */
  };

  const handleKeyDown = (e, index) => {
    currentOTPIndex = index;
    if (e.key === 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
  };

  const handleSendCodeOTP = () => {
    setLoading(true);
    const code = otp.join('');
    if (code.length === 6) {
      UserServices.confirmarCodigo({ code, c: id })
        .then((res) => {
          if (res.success && res.results) {
            Swal.fire({
              title: res.message,
              icon: 'success',
              customClass: {
                container: 'modal-alert',
              },
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: e.message,
            icon: 'warning',
            customClass: {
              container: 'modal-alert',
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Swal.fire({
        title: 'Código incorrecto',
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={11} sm={8} md={6} lg={6} xl={3} sx={{ margin: '0 auto' }}>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100vh'}>
            <Box paddingTop={'24px'} paddingRight={'24px'} paddingLeft={'24px'}>
              <Box
                //paddingRight={"40px"}
                //paddingLeft={"40px"}
                textAlign={'start'}
              >
                <Typography
                  variant="h5"
                  fontWeight={700}
                  marginBottom={'8px'}
                  sx={{ color: 'rgb(52, 71, 103)' }}
                >
                  Confirmación en 2 Pasos
                </Typography>

                <Typography
                  variant={'body2'}
                  sx={{ color: 'rgb(52, 71, 103)' }}
                  //textAlign={"start"}
                >
                  Enviamos un código de verificación a tu móvil. Introduce el código del móvil en el campo de
                  abajo
                </Typography>
              </Box>
            </Box>

            <Box padding={'16px'}>
              <Box component="form">
                {/* Inputs */}
                <Box marginBottom={'16px'}>
                  <Grid container spacing={1}>
                    {otp.map((_, index) => (
                      <Grid item xs={true} key={index}>
                        <TextField
                          inputRef={index === activeOTPIndex ? inputRef : null}
                          inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center' },
                          }}
                          autoComplete="off"
                          name={`otp-${index}`}
                          value={otp[index]}
                          onChange={handleChangeOTP}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Button */}
                <Box marginBottom={'16px'}>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    disabled={otp.join('').length !== 6}
                    onClick={handleSendCodeOTP}
                    sx={{
                      backgroundImage: linearGradient,
                      boxShadow:
                        'rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem',
                      backgroundSize: '150%',
                      backgroundPositionX: '25%',
                      width: '100%',
                    }}
                  >
                    Enviar Código
                  </LoadingButton>
                </Box>

                <Box marginBottom={'16px'} textAlign="center">
                  <Typography componet="span" sx={{ color: 'rgb(52, 71, 103)' }}>
                    ¿No lo has recibido?
                    <Typography component="a" fontWeight={700} href="#" ml={1}>
                      Reenviar un nuevo código
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            paddingLeft={'104px'}
            paddingRight={'104px'}
            margin={'16px'}
            sx={{
              background: linearGradient,
              borderRadius: '0.75rem',
              width: 'calc(100% - 2rem)',
              height: 'calc(100% - 2rem)',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              component={'img'}
              src={background_lines}
              sx={{
                opacity: '0.4',
                position: 'absolute',
                width: '120rem',
                left: '0px',
              }}
            />

            <Box
              component={'img'}
              src={background_pan}
              sx={{
                maxWidth: '31.25rem',
                width: '260px',
                height: '260px',
                borderRadius: '18px !important',
                boxShadow:
                  'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                //transform: "rotateX(8deg) rotateY(30deg)"
              }}
            />

            <Box marginTop={'48px'} marginBottom={'8px'}>
              <Typography variant="h4" fontWeight={700} fontSize={'1.5rem'} sx={{ color: '#fff' }}>
                PAN GTO Sí Le Sabe
              </Typography>
            </Box>

            {/* <Box marginBottom={"8px"}>
              <Typography
                variant="body2"
                fontWeight={400}
                fontSize={"1rem"}
                sx={{ color: "#fff" }}
              >
                Unidos y Fuerte Para Unir a México
              </Typography>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Confirmation;
