import React, { useState, useCallback, useEffect } from "react";

import {
	Box,
	TextField,
	Chip,
	Icon,
	Typography,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Check } from "@mui/icons-material";
import TipoDocumentoAutocomplete from "./TipoDocumentoAutocomplete";
import EmpresaAutocomplete from "./EmpresaAutocomplete";
import EmpresaDirectorioAutocomplete from "./EmpresaDirectorioAutocomplete";
import DirectorioCDEAutocomplete from "./DirectorioCDEAutocomplete";
import Autocomplete from "./Autocomplete";
import PickerFecha from "./PickerFecha";
import PickerFechaHora from "./PickerFechaHora";
import FileUpload from "./FileUpload";
import AdjuntosFormulario from "./AdjuntosFormulario";
import dayjs from "dayjs";

const OficiolInformationForm = (props) => {
	const { errors, touched, values, setValues, handleChange } = props;

	const [state, setState] = useState({
		Empresa: { id: null, Empresa: "", parentid: 0 },
		Directorio: { id: null, NombreCompleto: "", Cargo: "", idEmpresa: 0 },
		ParaQuien: { id: null, NombreCompleto: "", Cargo: "", idEmpresa: 0 },
		FechaOficio: null,
		FechaTermino: null,
		FolioOficio: null,
		FolioRecepcion: dayjs(),
		TipoDocumento: { id: null, TipoDocumento: "", Clave: "" },
		Asunto: "",
		Prioridad: { id: 1, Prioridad: "Normal" },
		SolicitaRespuesta: 0,
		Adjuntos: [],
	});

	const [tieneAdjuntos, setTieneAdjuntos] = useState(false);
	const [comentario, setComentario] = useState("");
	const [fotosAdjuntos, setFotosAdjuntos] = useState([]);

	const dataPrioridad = [
		{ id: 1, Prioridad: "Normal" },
		{ id: 2, Prioridad: "Medio" },
		{ id: 3, Prioridad: "Alto" },
	];

	const handleFechaTermino = (fecha) => {
		console.log("Fecha: ", fecha);
		setState({ ...state, FechaTermino: fecha });
		handleChange({
			target: {
				name: "FechaTermino",
				value: fecha,
			},
		});
	};

	const handleFechaRecepcion = (fecha) => {
		console.log("handleFechaRecepcion: ", fecha);
		setState({ ...state, Recepcion: fecha });
		handleChange({
			target: {
				name: "FechaRecibido",
				value: fecha,
			},
		});
	};

	const handleFechaOficio = (fecha) => {
		console.log("Fecha: ", fecha);
		setState({ ...state, FechaOficio: fecha });
		handleChange({
			target: {
				name: "FechaOficio",
				value: fecha,
			},
		});
	};

	const handlePrioridad = (selectedValue) => {
		console.log("=<selectedValue: ", selectedValue);
		setState({
			...state,
			Prioridad: selectedValue,
		});

		if (selectedValue) {
			handleChange({
				target: {
					name: "idPrioridad",
					value: selectedValue.id,
				},
			});
		} else {
			handleChange({
				target: {
					name: "idPrioridad",
					value: null,
				},
			});
		}
	};

	const handleBlur = (e) => {
		const { name } = e.target;
		setValues((prevValues) => ({
			...prevValues,
			[name]: state[name],
		}));
	};

	const handleFilesChange = (files) => {
		console.log("Oficio Adjuntos:", files.length);
		setValues((prevValues) => ({
			...prevValues,
			OficioAdjuntos: files,
		}));
	};

	return (
		<Box sx={{ mt: 1, mb: 2 }}>
			<Grid2 xs={12} sm={12} md={12} lg={12} display="flex">
				<Chip
					icon={<Icon>edit_document</Icon>}
					label="Datos del oficio recibido"
				/>
			</Grid2>

			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TipoDocumentoAutocomplete
						value={state}
						setValue={setState}
						handleChange={handleChange}
					/>
				</Grid2>

				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TextField
						label="FolioOficio"
						value={state.FolioOficio}
						name="FolioOficio"
						onChange={(e) => {
							const { name, value } = e.target;
							// Actualiza solo el estado local primero
							setState((prev) => ({
								...prev,
								[name]: value,
							}));
						}}
						onBlur={handleBlur}
						sx={{ width: "100%" }}
						type="text"
						variant="outlined"
						size="small"
						className="fixed-input"
					/>
				</Grid2>

				<Grid2 xs={12} sm={12} md={5} lg={5}>
					<FileUpload
						title="Adjuntar el documento escaneado"
						onFilesChange={handleFilesChange}
						tipo={state.TipoDocumento}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={4} sm={12} md={3} lg={3}>
					<PickerFecha
						label={"Fecha oficio"}
						requerido={false}
						helperText="Este campo es obligatorio"
						value={state.FechaOficio}
						handleChange={handleFechaOficio}
					/>
					{errors.FechaOficio && (
						<Typography variant="caption" color="error">
							{errors.FechaOficio}
						</Typography>
					)}
				</Grid2>
				<Grid2 xs={4} sm={12} md={3} lg={3}>
					<PickerFechaHora
						label={"Fecha término"}
						value={state.FechaTermino}
						handleChange={handleFechaTermino}
					/>
				</Grid2>
				<Grid2 xs={4} sm={12} md={3} lg={3}>
					<PickerFechaHora
						label={"Fecha recepción"}
						value={state.Recepcion}
						handleChange={handleFechaRecepcion}
						error={errors.FechaRecibido}
						helperText={errors.FechaRecibido ? "FechaHora recibido" : ""}
					/>
				</Grid2>
			</Grid2>

			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={10} lg={10}>
					<TextField
						label="Asunto"
						value={state.Asunto}
						name="Asunto"
						error={errors.Asunto}
						helperText={errors.Asunto ? "Asunto requerido" : ""}
						onChange={(e) => {
							const { name, value } = e.target;
							// Actualiza solo el estado local primero
							setState((prev) => ({
								...prev,
								[name]: value,
							}));
						}}
						onBlur={handleBlur}
						sx={{ width: "100%" }}
						type="text"
						variant="outlined"
						size="small"
						className="fixed-input"
						multiline
						minRows={3}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<FormControlLabel
						checked={values.SolicitaRespuesta === 1}
						name="SolicitaRespuesta"
						onChange={() => {
							setState({
								...state,
								SolicitaRespuesta: state.SolicitaRespuesta === 1 ? 0 : 1,
							});
							handleChange({
								target: {
									name: "SolicitaRespuesta",
									value: state.SolicitaRespuesta === 1 ? 0 : 1,
								},
							});
						}}
						control={<Checkbox checkedIcon={<Check />} />}
						label={`Requiere respuesta (${
							state.SolicitaRespuesta === 1 ? "Si" : "No"
						})`}
					/>
				</Grid2>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<Autocomplete
						data={dataPrioridad}
						name="Prioridad"
						value={state.Prioridad} // Pasamos solo el objeto Prioridad
						label="Prioridad"
						onChange={handlePrioridad}
						OptionLabel="Prioridad"
					/>
				</Grid2>
			</Grid2>

			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={5} lg={5}>
					<DirectorioCDEAutocomplete
						value={state}
						touched={touched}
						values={values}
						setValue={setState}
						setValueF={setValues}
						handleChange={handleChange}
						formikerror={errors}
					/>
				</Grid2>
			</Grid2>

			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={5} lg={5}>
					<EmpresaAutocomplete
						dato={state.Empresa}
						value={state}
						setValue={setState}
					/>
				</Grid2>
				<Grid2 xs={12} sm={12} md={5} lg={5}>
					<EmpresaDirectorioAutocomplete
						value={state}
						setValue={setState}
						values={values}
						setValueF={setValues}
						handleChange={handleChange}
						formikerror={errors}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={12} lg={12}>
					<AdjuntosFormulario
						fotosAdjuntos={fotosAdjuntos}
						setFotosAdjuntos={setFotosAdjuntos}
						comentario={comentario}
						setComentario={setComentario}
						tieneAdjuntos={tieneAdjuntos}
						setTieneAdjuntos={setTieneAdjuntos}
						setValues={setValues}
					/>
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default OficiolInformationForm;
