import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableContainer,
  Card,
  Typography,
  Box,
  Alert,
  Stack,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import TableBody from '@components/Activation/Bingo/BingoCorrections/TableBody';
import TableHeader from '@components/Activation/Bingo/BingoCorrections/TableHead';

import Swal from 'sweetalert2';
import MovilizacionServices from '@services/MovilizationServices';
import { ESTATUS_NO_ENCONTRADO } from '@data/constants/Bingo';
import { isEmptyOrInvalidString } from '@utils/validations';

const BingoCorrectionsTable = ({ onClean, filtered, setLoading, seccionLocation }) => {
  /* const location = useLocation();
  const seccion = location.state ? location.state : null; */

  const tableRef = useRef(null);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [dataFound, setDataFound] = useState(true);

  const [dataValue, setDataValue] = useState({
    Seccion: '',
    Casilla: '',
    Folio: '',
  });
  const [catCasillas, setCatCasillas] = useState([]);
  const [loadingCasillas, setLoadingCasillas] = useState(false);
  const [rowIdx, setRowIdx] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  const resetData = () => {
    setData([]);
    setTotal(0);
    setDataFound(true);
  };

  const ApiListUser = async (value) => {
    setLoading(true);
    setLoadingSave(true);

    try {
      const params = {
        Seccion: seccionLocation ? seccionLocation : value.Seccion,
        idEstatus: ESTATUS_NO_ENCONTRADO,
      };

      const result = await MovilizacionServices.getCompromisosBingosList(params);
      const { results, response, success, message } = result;
      if (!results && !success) throw new Error(message);

      if (results && success) {
        setData(response.data);
        setTotal(response.total);
        setDataFound(true);
      } else {
        setData([]);

        setDataFound(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
      setLoadingSave(false);
    }
  };

  const handleChangeData = (event, index) => {
    const { name, value } = event.target;
    setDataValue((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [name]: value,
      },
    }));
  };

  const handleKeyPressSearch = (event, index) => {
    const isEnterKey = event.key === 'Enter';
    const isTabKey = event.key === 'Tab';
    if (event.type === 'keydown' && (isEnterKey || isTabKey)) {
      const value = event.target.value;
      if (!isEmptyOrInvalidString(value)) {
        validateSeccion(value, index);
      }
    }
  };

  const validateSeccion = async (value, index) => {
    try {
      setLoadingCasillas((prev) => ({ ...prev, [index]: true }));
      setRowIdx(index);
      const result = await MovilizacionServices.validateSection(value);
      const { results, message, response } = result;
      if (results) {
        let casillas = response.casillas.map((item) => ({
          value: item.idCasilla,
          label: item.NombreCasilla,
        }));
        setCatCasillas(casillas);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        iconc: 'warning',
      });
    } finally {
      setLoadingCasillas((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleSaveBingo = async (index) => {
    if (isEmptyOrInvalidString(dataValue[index])) {
      return;
    }

    const { Seccion, Casilla, Folio } = dataValue[index];
    const idCompromisoUnico = index;

    if (Folio > 750) {
      Swal.fire({
        title: '¡Alerta!',
        text: 'Hay un valor de Folio Bingo mayor a 750. Por favor, corríjalo antes de continuar.',
        icon: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const { results, success, message } = await MovilizacionServices.updateNotFound({
        idCompromisoUnico,
        idCasilla: Casilla,
        Seccion,
        FolioBingo: Folio,
        allowDuplicate: allowDuplicate,
      });

      if (!results && !success) throw new Error(message);

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            ApiListUser(filtered);
            setDataValue((prevData) => ({
              ...prevData,
              [index]: {
                ...prevData[index],
                Seccion: '',
                Casilla: '',
                Folio: '',
              },
            }));
            setCatCasillas([]);
            setAllowDuplicate(false);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAllowDuplicates = (e) => {
    setAllowDuplicate(e.target.checked ? true : false);
  };

  useEffect(() => {
    if (data.length > 0) {
      const newDataValue = {};
      data.forEach((row, index) => {
        newDataValue[index] = {};
        Object.keys(row).forEach((key) => {
          newDataValue[index][key] = '';
        });
      });
      setDataValue(newDataValue);
    }
  }, [data]);

  useEffect(() => {
    resetData();
    //eslint-disable-next-line
  }, [onClean]);

  useEffect(() => {
    if (filtered && !seccionLocation) {
      ApiListUser(filtered);
    }

    if (!filtered && seccionLocation !== null) {
      ApiListUser(seccionLocation);
    }
    //eslint-disable-next-line
  }, [filtered, seccionLocation]);

  return (
    <Card className="card-primary">
      <Box m={2}>
        {showMessage && (
          <Alert
            severity="info"
            sx={{
              mb: 1,
            }}
            onClose={() => {
              setShowMessage(false);
            }}
          >
            <p>
              En esta pantalla puede consultar por <strong>Sección</strong> los compromosos que quedaron sin
              folio posterior a la revisión de cuadernillos. Por lo que se recomienda visitar primero la
              página de <a href="./compromisos-sin-folio">Compromisos Sin Folio</a>
            </p>
            Para capturar el bingo, primero busca la sección y presiona Enter para obtener las casillas.
            Luego, escribe el folio bingo y, finalmente, presiona el botón <strong>"Guardar"</strong>.
          </Alert>
        )}

        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Compromisos
          </Typography>
          <FormControlLabel
            label=<Typography variant="body2" fontWeight={600}>
              Permitir Duplicados
            </Typography>
            control={
              <Checkbox
                color="primary"
                onChange={handleAllowDuplicates}
                checked={allowDuplicate}
                size="small"
              />
            }
          />
        </Stack>
        <TableContainer
          ref={tableRef}
          sx={{
            maxHeight: '70vh',
            height: 'auto',
            overflow: 'auto',
            borderRadius: 3,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHeader />
            <TableBody
              data={data}
              dataFound={dataFound}
              catCasillas={catCasillas}
              rowIdx={rowIdx}
              loadingCasillas={loadingCasillas}
              loading={loadingSave}
              handleChangeData={handleChangeData}
              handleKeyPressSearch={handleKeyPressSearch}
              handleSaveBingo={handleSaveBingo}
            />
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: '#888888' }}>
            Mostrando {Math.min(data.length, total)} de {total}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default BingoCorrectionsTable;
