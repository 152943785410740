import React, { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Icon,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import WebCam from '../WebCam';
import Toast from '../Toast';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

import { useMobileServices } from '@hooks/MobileDiaD/MobileServices';
import { URL_IMAGES } from '@utils/global';
import { isTypePhone } from '@utils/validations';
import { PREP_RESULTADOS, ORIGEN_CAPTURA } from '@data/constants/MobileDiaD';

const FormReporteResultados = ({ Seccion, idCasilla, NombreCasilla, setShowForm }) => {
  //tipo_eleccion

  const { loading, loadingGet, snackbar, setSnackbar, getForm, setForm } = useMobileServices();

  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: 'tipo_eleccion', getAll: false }],
    putDefaultOption: false,
  });

  const [image, setImage] = useState(null);
  const [listParties, setListParties] = useState([]);
  const [totalVotos, setTotalVotos] = useState(0);
  const [tipoEleccion, setTipoEleccion] = useState('');

  const handleGetListParties = async (idTipoEleccion) => {
    const response = await getForm({
      idTipoEleccion,
      idCasilla: idCasilla,
    });

    if (response) {
      let _listParties = response.data.map((item) => ({
        ...item,
        Votos: '',
      }));

      _listParties = [
        ..._listParties,
        {
          id: 'NoReg',
          Votos: '',
          RutaLogo: 'prep_partidos/noreg.png',
          siglas: 'No reg',
        },
        {
          id: 'Nulos',
          Votos: '',
          RutaLogo: 'prep_partidos/nulos.png',
          siglas: 'Nulos',
        },
      ];

      setListParties(_listParties);
    } else {
      setListParties([]);
    }
  };

  const handleChangeTipoEleccion = (value) => {
    setTipoEleccion(value);
    handleGetListParties(value);
  };

  const handleVotes = (e, idx) => {
    const value = e.target.value;
    const _politicalParties = parseJson(listParties, value, idx);
    setListParties(_politicalParties);
    calculateVotes(_politicalParties);
  };

  const parseJson = (obj, value, idx) => {
    let _politicalParties = JSON.parse(JSON.stringify(obj));
    _politicalParties[idx].Votos = value;

    return _politicalParties;
  };

  const calculateVotes = (_politicalParties) => {
    const value = _politicalParties
      .map((item) => {
        return isNaN(item.Votos) || item.Votos.length === 0 ? 0 : parseInt(item.Votos);
      })
      .reduce((accum, current) => {
        return accum + current;
      });

    setTotalVotos(value);
  };

  const validateVotes = () => {
    let hasError = false;

    const emptyVotes = listParties.filter((item) => item.Votos < 0 || item.Votos === '');

    if (emptyVotes.length > 0) {
      setSnackbar({
        show: true,
        message: 'Es necesario llenar todos los votos. 0 (cero) en caso de no haber votos.',
        severity: 'error',
      });
      hasError = true;
    }
    return !hasError;
  };

  const formatResponse = (_politicalParties) => {
    let votosFormated = [];
    _politicalParties.forEach((item) => {
      if (item.id !== 'NoReg' && item.id !== 'Nulos') {
        votosFormated = [
          ...votosFormated,
          {
            field: `Dato${item.id}`,
            value: parseInt(item.Votos),
          },
        ];
      } else if (item.id !== 'NoReg') {
        votosFormated = [...votosFormated, { field: 'NoReg', value: parseInt(item.Votos) }];
      } else {
        votosFormated = [...votosFormated, { field: 'Nulos', value: parseInt(item.Votos) }];
      }
    });

    return votosFormated;
  };

  const handleSetForm = async () => {
    if (!validateVotes()) {
      return;
    }

    const votes = formatResponse(listParties);

    const params = {
      idTipoEleccion: tipoEleccion,
      idCasilla,
      Resultados: votes,
      Origen: ORIGEN_CAPTURA,
    };

    if (image) params.files = [{ file: image, classificationId: PREP_RESULTADOS }];

    const results = await setForm(params);

    if (results) {
      setImage(null);
    }
  };

  useEffect(() => {
    if (!load) {
      const idTipoEleccion = catalogs?.tipo_eleccion[0]?.value;

      setTipoEleccion(idTipoEleccion);
      handleGetListParties(idTipoEleccion);
    }
  }, [load]);

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
        severity={snackbar.severity}
      />

      <Container>
        <Stack direction={'column'}>
          <Stack
            direction="row"
            alignItems={'center'}
            //justifyContent={"center"}
          >
            <IconButton onClick={() => setShowForm(false)}>
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography variant="body1" textAlign={'center'}>
              Resultados Preliminares
            </Typography>
          </Stack>

          <Box marginBottom={2} marginTop={2}>
            <Typography variant="body2" marginBottom={1}>
              Tipo de resultado
            </Typography>
            <AdvancedSelect
              name="tipoResulatado"
              label="Buscar el tipo de resultado"
              options={catalogs.tipo_eleccion}
              value={tipoEleccion}
              onChange={(e) => handleChangeTipoEleccion(e.value)}
              isSearchable
              isLoading={load}
            />
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">Sección</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Seccion}
            </Typography>
            <Typography variant="body2">Casilla</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {NombreCasilla}
            </Typography>
          </Box>

          {loadingGet ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              marginBottom={2}
              height={'40vh'}
            >
              Cargando listado de partidos...
            </Box>
          ) : (
            listParties.length > 0 && (
              <Box>
                {listParties.map((item, idx) => (
                  <Stack key={item.id} direction={'row'} alignItems={'center'} spacing={4} marginBottom={2}>
                    <Box
                      component="img"
                      src={`${URL_IMAGES}/${item.RutaLogo}`}
                      sx={{
                        display: 'inline-block',
                        width: '16%',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '4px',
                      }}
                    />
                    <TextField
                      name="Votos"
                      size="small"
                      value={item.Votos}
                      variant="standard"
                      fullWidth
                      onChange={(e) =>
                        handleVotes(
                          {
                            target: {
                              name: 'otra',
                              value: isTypePhone(e.target.value) ? e.target.value : item.Votos,
                            },
                          },
                          idx
                        )
                      }
                      error={item.Votos && item.Votos > 0 && parseInt(item.Votos) > 2000}
                      helperText={
                        item.Votos &&
                        item.Votos > 0 &&
                        parseInt(item.Votos) > 2000 &&
                        'El voto no puede ser mayor a 2000'
                      }
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Stack>
                ))}
              </Box>
            )
          )}

          <WebCam label="Foto de los resultados" image={image} setImage={setImage} />

          <Button
            variant="contained"
            onClick={handleSetForm}
            disabled={loading || listParties.some((item) => item.Votos > 2000)}
            sx={{ mb: 2 }}
          >
            {loading ? 'Guardando...' : 'Guardar'}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default FormReporteResultados;
