import React, { useState, useEffect } from "react";
import {
	Autocomplete,
	TextField,
	Button,
	IconButton,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Card,
	CircularProgress,
	CardContent,
	Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import OficialiaServices from "@services/OficialiaServices"; // Importar el servicio

const EmpresaDirectorioAutocomplete = ({
	value,
	values,
	setValue,
	setValueF,
	formikerror,
	handleChange,
}) => {
	const [data, setData] = useState([]);
	const [openModal, setOpenModal] = useState(false); // Estado para controlar el modal
	const [nuevoValor, setNuevoValor] = useState(""); // Estado para el valor de la nueva empresa
	const [cargo, setCargo] = useState(null); // Estado para seleccionar empresa padre
	const [loading, setLoading] = useState(false); // Estado para manejar el loading al guardar
	const [errors, setErrors] = useState("");
	const [cargoErrors, setCargoErrors] = useState("");

	useEffect(() => {
		const fetchEmpresas = async () => {
			setLoading(true);
			try {
				const results = await OficialiaServices.getOficialiaDirectorioEmpresas({
					page: 0,
					pageSize: 400,
					filtered: [
						{
							id: "cde_oficialia_directorio.idEmpresa",
							value: value.Empresa.id,
							filter: "=",
						},
					],
					sorted: [
						{ id: "cde_oficialia_directorio.NombreCompleto", value: "ASC" },
					],
				});

				if (results.success) {
					// Si la respuesta es exitosa, actualizar el estado con los datos
					setData(results.response.data);
				} else {
					// Manejar el caso en que no se puedan obtener los datos
					setErrors(results.message || "Error al obtener directorio");
				}
				setLoading(false);
			} catch (error) {
				console.error("Error al obtener directorio:", error);
				setErrors("Error al cargar directorio");
			}
		};

		fetchEmpresas();
	}, [value.Empresa]);

	// Función para guardar la nueva empresa
	const handleSave = async () => {
		setLoading(true);
		try {
			const results = await OficialiaServices.insertOficialiaDirectorioEmpresa({
				Cargo: cargo,
				idEmpresa: value.Empresa.id,
				NombreCompleto: nuevoValor,
			});

			if (!results.success) {
				//Swal.fire({ title: results.message, icon: "error" });
				setErrors(results.message);
				return true;
			} else {
				setErrors("");
			}

			const nuevaEmpresa = results.response.data; // Obtener el objeto retornado por la API

			// Agregar la nueva empresa a la lista de opciones y seleccionarla en el Autocomplete
			data.push(nuevaEmpresa);
			setValue({ ...value, Directorio: nuevaEmpresa });

			setValueF({
				...values,
				idPersonaEnvia: nuevaEmpresa.id,
				NombrePersonaEnvia: `${nuevoValor} - ${cargo}`,
				EmpresaCargoPersonasEnvia: value.Empresa.Empresa,
			});

			// Cerrar el modal
			setOpenModal(false);
			setNuevoValor("");
			setCargo(null);
		} catch (error) {
			console.error("Error al agregar persona:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box>
			{loading ? (
				<CircularProgress
					variant={loading ? "indeterminate" : "determinate"}
					sx={{
						color: (theme) =>
							theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
					}}
					size={40}
					thickness={4}
					value={100}
				/>
			) : (
				<Autocomplete
					value={value.Directorio || null}
					onChange={(event, newValue) => {
						setValue({ ...value, Directorio: newValue });
						if (newValue) {
							handleChange({
								target: {
									name: "idPersonaEnvia",
									value: newValue.id,
								},
							});
						} else {
							handleChange({
								target: {
									name: "idPersonaEnvia",
									value: null,
								},
							});
						}
					}}
					options={data}
					getOptionLabel={(option) => option.NombreCargo || ""}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Persona que envía el oficio"
							error={formikerror.idPersonaEnvia}
							helperText={
								formikerror.idPersonaEnvia ? "Para quien es requerido" : ""
							}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<IconButton onClick={() => setOpenModal(true)}>
										<AddIcon />
									</IconButton>
								),
							}}
						/>
					)}
					fullWidth
				/>
			)}

			{/* Modal para agregar nueva empresa */}
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>Agregar persona</DialogTitle>
				<DialogContent>
					<Card>
						<CardContent>
							{/* Input para ingresar nueva empresa */}
							<TextField
								label="Nueva persona"
								value={nuevoValor}
								onChange={(e) => {
									setErrors("");
									setNuevoValor(e.target.value);
								}}
								fullWidth
								sx={{ mb: 2 }}
							/>
							{errors && (
								<Typography
									variant="caption"
									color="error"
									style={{ marginVertical: 10 }}
								>
									{errors ? errors : ""}
									<br />
								</Typography>
							)}
							{/* Autocomplete para seleccionar empresa padre */}
							<TextField
								label="Cargo"
								value={cargo}
								onChange={(e) => {
									setCargoErrors("");
									setCargo(e.target.value);
								}}
								fullWidth
								sx={{ mb: 2 }}
							/>
							{cargoErrors && (
								<Typography
									variant="caption"
									color="error"
									style={{ marginVertical: 10 }}
								>
									{errors ? errors : ""}
									<br />
								</Typography>
							)}
						</CardContent>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)} color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
						disabled={loading || !nuevoValor}
					>
						{loading ? "Guardando..." : "Guardar"}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default EmpresaDirectorioAutocomplete;
