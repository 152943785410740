import Yup from '@utils/Yupi18n';

export const PublicacionesSchema = Yup.object().shape({
  idTipo: Yup.number().required(),
  Nombre: Yup.string().min(5).max(50).required(),
  Descripcion: Yup.string().min(5).max(100).required().label('Descripción'),
  Sinopsis: Yup.string().min(5).max(255).required(),
  files: Yup.array().min(1).required().label('PDF'),
  fileName: Yup.string()
    .min(1, 'PDF debe tener al menos 1 elementos')
    .max(100, 'El nombre del PDF debe tener máximo 100 caracteres')
    .required()
    .label('PDF'),
  fileNameImg: Yup.string()
    .max(100, 'El nombre de la imagen debe tener máximo 100 caracteres')
    .label('Imagen'),
  FechaPublicacion: Yup.date().required().label('Fecha de Publicación'),
});

export const EditPublicacionesSchema = Yup.object().shape({
  id: Yup.number().required(),
  idTipo: Yup.number().required(),
  Nombre: Yup.string().min(5).max(50).required(),
  Descripcion: Yup.string().min(5).max(100).required().label('Descripción'),
  Sinopsis: Yup.string().min(5).max(255).required(),
  files: Yup.array().optional().label('PDF'),
  fileName: Yup.string()
    .min(1, 'PDF debe tener al menos 1 elementos')
    .max(100, 'El nombre del PDF debe tener máximo 100 caracteres')
    .required()
    .label('PDF'),
  fileNameImg: Yup.string()
    .max(100, 'El nombre de la imagen debe tener máximo 100 caracteres')
    .label('Imagen'),
  fileURL: Yup.string().required(),
  fileURLImg: Yup.string(),
  FechaPublicacion: Yup.date().required().label('Fecha de Publicación'),
});
