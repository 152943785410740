import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CircularProgress, Card, CardContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

const Grafica = (props) => {
  const { title, filtered, type, change, API } = props;
  const { height, width } = useWindowDimensions();

  const [actualDate, setActualDate] = useState(new Date().toLocaleString());
  const [loading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const categorias = ['Region', 'DistritoFederal', 'DistritoLocal'];

  useEffect(() => {
    const ApiResponse = async (filtered) => {
      try {
        setIsLoading(true);
        const result = type === 'text' ? API : await API(filtered);
        const { results, message, response } = result;
        if (results) {
          const newData = response.data;
          const newCategories = newData.map((item) => {
            const category = categorias.find((cat) => item[cat]);
            return category ? item[category] : null;
          });
          let newSeriesData;
          if (type === 'total') {
            newSeriesData = {
              p1: newData.map((item) => parseInt(item.P1)),
              p2: newData.map((item) => parseInt(item.P2)),
              p3: newData.map((item) => parseInt(item.P3)),
            };
          } else {
            newSeriesData = {
              LN1: newData.map((item) => parseInt(item.LN1)),
              LN2: newData.map((item) => parseInt(item.LN2)),
              LN3: newData.map((item) => parseInt(item.LN3)),
            };
          }

          setCategories(newCategories);
          setSeriesData(newSeriesData);
        } else {
          Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
          });
          setCategories([]);
          setSeriesData([]);
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
        setCategories([]);
        setSeriesData([]);
      } finally {
        setActualDate(new Date().toLocaleString());
        setIsLoading(false);
      }
    };
    ApiResponse(filtered);
  }, [filtered, change]);

  const options = {
    chart: {
      type: 'column',
      height: width > 1440 ? height * 0.4 : 'auto',
    },
    title: {
      text: `${title}`,
      align: 'right',
      style: {
        color: '#999',
        fontSize: width > 1440 ? width * 0.012 : '18px',
      },
    },
    subtitle: {
      text: `${actualDate}`,
      align: 'right',
      style: {
        fontSize: width > 1440 ? width * 0.0085 : '12px',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : '14px',
        },
      },
    },
    yAxis: {
      title: '',
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : '14px',
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Prioridad 1',
        data: type === 'total' ? seriesData.p1 : seriesData.LN1,
        style: {
          fontSize: width > 1440 ? width * 0.0085 : '12px',
        },
      },
      {
        name: 'Prioridad 2',
        data: type === 'total' ? seriesData.p2 : seriesData.LN2,
        color: 'rgba(39, 72, 160)',
        style: {
          fontSize: width > 1440 ? width * 0.0085 : '12px',
        },
      },
      {
        name: 'Prioridad 3',
        data: type === 'total' ? seriesData.p3 : seriesData.LN3,
        color: 'rgba(83, 208, 236)',
        style: {
          fontSize: width > 1440 ? width * 0.0085 : '12px',
        },
      },
    ],
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default Grafica;
