import React, { useEffect, useState } from 'react';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  TableSortLabel,
  IconButton,
  TablePagination,
  Box,
  Icon,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

import movilization from '@services/MovilizationServices';
import { descending } from '@utils/Utilities';
import { blue, grey } from '@mui/material/colors';

const BingoTable = (props) => {
  const { box, setIsLoadingForm, setOpenLinearProgress, lastInsert } = props;
  const { height } = useWindowDimensions();

  const [highlightedRow, setHighlightedRow] = useState(-1);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      if (box) {
        await getBingos(box);
      }
    };

    loadData();
    // eslint-disable-next-line
  }, [box]);

  useEffect(() => {
    if (lastInsert) {
      if (!data.some((item) => parseInt(item.FolioBingo) === parseInt(lastInsert.FolioBingo))) {
        setData(() => {
          let newData = [...data, lastInsert].sort(descending((item) => parseInt(item.FolioBingo)));
          return mapData(newData);
        });
      }
    }
    // eslint-disable-next-line
  }, [lastInsert]);

  const getBingos = async (idCasilla) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setData([]);

    try {
      const result = await movilization.getBingosList({ idCasilla });
      const { results, response, message } = result;

      if (results) {
        setData(mapData(response.data));
      } else {
        Swal.fire({
          icon: 'warning',
          title: message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: error.message,
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const cellBorderStyle = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
  };

  const headerCellStyle = {
    backgroundColor: blue[900],
    color: 'white',
    fontWeight: 'bold',
  };

  const mapData = (data) =>
    data.map((item, index) => ({
      accion: (
        <IconButton onClick={() => handleDeleteClick(item)} sx={{ m: 0, p: 0 }} color="error">
          <DeleteIcon />
        </IconButton>
      ),
      ...item,
    }));

  const handleSort = (field) => {
    if (orderBy === field && order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }
    setOrderBy(field);
  };

  const handleDeleteClick = (item) => {
    Swal.fire({
      title: 'Advertencia!',
      text: '¿Está seguro que desea eliminar el registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'No, Cancelar',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Implementa aquí la lógica para eliminar la fila en el estado 'data'
        // setHighlightedRow(-1);
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // );
        deleteBingo(item);
      }
    });
  };

  const deleteBingo = async (item) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setData([]);

    const params = {
      idCasilla: item.idCasilla,
      FolioBingo: item.FolioBingo,
    };

    try {
      const result = await movilization.deleteBingo(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((res) => {
          getBingos(item.idCasilla);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: error.message,
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Ordenar números
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      // Ordenar cadenas
      const aStr = aValue?.toString() || '';
      const bStr = bValue?.toString() || '';
      return order === 'asc' ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }}>
      <CardContent>
        <Typography variant="subtitle1" marginBottom={2}>
          Bingos
        </Typography>
        <TableContainer sx={{ minHeight: height * 0.5 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>ACCIÓN</TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Seccion'}
                    direction={order}
                    onClick={() => handleSort('Seccion')}
                  >
                    SECCIÓN
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'NombreCasilla'}
                    direction={order}
                    onClick={() => handleSort('NombreCasilla')}
                  >
                    CASILLA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Hora'}
                    direction={order}
                    onClick={() => handleSort('Hora')}
                  >
                    HORA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'FolioBingo'}
                    direction={order}
                    onClick={() => handleSort('FolioBingo')}
                  >
                    BINGO
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Usuario'}
                    direction={order}
                    onClick={() => handleSort('Usuario')}
                  >
                    USUARIO
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.length > 0 ? (
                sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: highlightedRow === index ? 'rgba(0, 0, 0, 0.05)' : 'initial',
                    }}
                    onMouseEnter={() => setHighlightedRow(index)}
                    onMouseLeave={() => setHighlightedRow(-1)}
                  >
                    <TableCell style={cellBorderStyle}>{row.accion}</TableCell>
                    <TableCell style={cellBorderStyle}>{row.Seccion}</TableCell>
                    <TableCell style={cellBorderStyle}>{row.NombreCasilla}</TableCell>
                    <TableCell style={cellBorderStyle}>{row.Hora}</TableCell>
                    <TableCell style={cellBorderStyle}>{row.FolioBingo}</TableCell>
                    <TableCell style={cellBorderStyle}>{row.Usuario}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      sx={{ minHeight: height * 0.5 }}
                    >
                      <Icon sx={{ fontSize: '5rem', color: grey[500] }}>person_search</Icon>
                      <Typography fontWeight={600} variant="h6" marginTop={1}>
                        Datos No Disponible
                      </Typography>
                      <Typography variant="caption" marginTop={1}>
                        Utiliza el formulario a tu izquierda para realizar la captura de bingos.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 350, 500]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            mt: 1,
          }} // Estilo para hacerlo fijo en la parte inferior
        />
      </CardContent>
    </Card>
  );
};

export default BingoTable;
