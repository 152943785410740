import Yup from '@utils/Yupi18n';

const UserRoleAddSchema = Yup.object({
  Perfil: Yup.string().required().min(4),
  Descripcion: Yup.string().required(),
  Menus: Yup.array().required().min(1),
});

const AgregarPerfilSchema = Yup.object({
  TipoPerfil: Yup.number().required().label('Tipo de perfil'), // Select
  AmbitoCargo: Yup.array().required().min(1, 'Debe haber por lo menos un ambito de cargo'), // Select
  NombrePerfil: Yup.string().min(5, 'Debe tener al menos 5 caracteres').required().label('Nombre del perfil'), // Input de texto
  EtiquetaCargo: Yup.string()
    .min(3, 'Debe tener al menos 3 caracteres')
    .required()
    .label('Etiqueta del cargo'), // Input de texto
  Descripcion: Yup.string()
    .min(5, 'Debe tener al menos 5 caracteres')
    .required()
    .label('Descripción del perfil'), // Input de texto

  PerfilHijo: Yup.array().required().min(1, 'Debe haber por lo menos un perfil que puede asignar'), // Lista seleccionable
  PerfilPadre: Yup.array().required().min(1, 'Debe haber por lo menos un perfil que lo puede asignar'), // Lista seleccionable
});

export { UserRoleAddSchema, AgregarPerfilSchema };
