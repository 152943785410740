import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';

// Material UI
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Icon,
  Stack,
  Divider,
  Tooltip,
  Link,
  TextField,
} from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import CustomTabs from '@components/Tabs/CustomTabs';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import Download from '@components/Downloads/Download';

// Services y utils
import EventsServices from '@services/EventsServices';
import { Swal, SwalQR } from '@utils/alerts';

// Middleware
import middleware from '@middlewares/middleware';

const CardEvent = (props) => {
  const { post, show, setShow = () => {}, cat, isLoadingCat, URL = '' } = props;

  const navigate = useNavigate();

  const basicColumns = [
    { id: 'municipio', label: 'Municipio' },
    { id: 'nombre', label: 'Nombre' },
    { id: 'celular', label: 'Celular' },
    { id: 'email', label: 'Correo' },
  ];

  const [columns, setColumns] = useState(basicColumns);
  const [newColumns, setNewColumns] = useState({});

  const [rowsAttended, setRowsAttended] = useState([]);
  const [rowsInvited, setRowsInvited] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const [search1, setSearch1] = useState({ nombre: '', celular: '', idMunicipio: 0, municipio: '' });
  const [search2, setSearch2] = useState({ nombre: '', celular: '', idMunicipio: 0, municipio: '' });

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const formatColumns = (array) => array?.map((item) => ({ ...item, maxWidth: 200, tooltip: true }));

  useEffect(() => {
    if (newColumns.invitedQuestions || newColumns.attendedQuestions) {
      if (show + '' === '0')
        setColumns([...basicColumns, ...formatColumns(newColumns.invitedQuestions ?? [])]);
      else setColumns([...basicColumns, ...formatColumns(newColumns.attendedQuestions ?? [])]);
    }
    // eslint-disable-next-line
  }, [show, newColumns]);

  const handleShare = () => window.open(post.URL, '_blank', 'noopener,noreferrer');

  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await EventsServices.getEventosDetails({ idEvento: post.id });
      const { results, response, message } = result;

      if (results) {
        setRowsAttended(response.attended);
        setRowsInvited(response.invited);
        setNewColumns({
          invitedQuestions: response.invitedQuestions,
          attendedQuestions: response.attendedQuestions,
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const exportData = async () => {
    try {
      setIsDownload(true);
      const result = await EventsServices.exportEventosDetails({
        idEvento: post.id,
        Actividad: post.Actividad,
        FechaInicio: post.FechaInicio,
      });
      const { results, message } = result;

      if (results) Swal.fire({ title: message, icon: 'success' });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsDownload(false);
    }
  };

  const filter = (value, search) => {
    const normalizedValue = ('' + value)?.toLowerCase().trim().toLowerCase().replace(/\s+/g, '');
    const normalizedSearch = ('' + search)?.toLowerCase().trim().toLowerCase().replace(/\s+/g, '');
    const regex = new RegExp(normalizedSearch, 'i');
    return regex.test(normalizedValue);
  };

  const filteredRowsInvited = useMemo(() => {
    return rowsInvited.filter(({ nombre, municipio, celular }) => {
      return (
        filter(nombre, search1.nombre) &&
        filter(celular, search1.celular) &&
        (search1.idMunicipio > 0 ? filter(municipio, search1.municipio) : true)
      );
    });
  }, [rowsInvited, search1]);

  const filteredRowsAttended = useMemo(() => {
    return rowsAttended.filter(({ nombre, municipio, celular }) => {
      return (
        filter(nombre, search2.nombre) &&
        filter(celular, search2.celular) &&
        (search2.idMunicipio > 0 ? filter(municipio, search2.municipio) : true)
      );
    });
  }, [rowsAttended, search2]);

  const hoy = new Date();
  const fechaHoy = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate());

  const fechaFin = new Date(post.FechaFin);
  const fechaPost = new Date(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate());

  const YaPasoDia = fechaPost < fechaHoy;

  return (
    <Grid container spacing={2} textAlign={'center'}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography gutterBottom variant="h5" component="div">
          {post.Actividad}
        </Typography>
      </Grid>

      {post.Descripcion && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>notes</Icon>
            <Typography variant="body1" align="left">
              {post.Descripcion}
            </Typography>
          </Stack>
        </Grid>
      )}

      {post.NombreLugar && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>location_on</Icon>
            <Typography variant="h6" align="left">{`${post.NombreLugar.trim()}, ${post.Calle} #${
              post.NumExt
            }, ${post.Colonia}`}</Typography>
          </Stack>
        </Grid>
      )}

      {post.LigaVirtual && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Icon>link</Icon>
            <Link href={post.LigaVirtual} target="_blank" rel="noopener noreferrer" underline="none">
              <Typography variant="h6" align="left">
                {post.LigaVirtual}
              </Typography>
            </Link>
          </Stack>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
        }}
      >
        <Box>
          {post.FechaInicio === post.FechaFin ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                <strong>Fecha del evento:</strong> {post.FechaInicio}
              </Typography>
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Inicio del evento:</strong> {post.FechaInicio}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Fin del evento:</strong> {post.FechaFin}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title="Exportar datos" disableInteractive arrow>
            <IconButton size="small" aria-label="edit" color="primary" onClick={exportData}>
              <Icon>download</Icon>
            </IconButton>
          </Tooltip>

          <Tooltip title="Ver Dashboard" disableInteractive arrow>
            <IconButton
              size="small"
              aria-label="edit"
              color="secondary"
              onClick={() => navigate(`${URL}dashboard/${post.id}`)}
            >
              <Icon>bar_chart</Icon>
            </IconButton>
          </Tooltip>

          {isSameDay(new Date(post.FechaFin), new Date()) && (
            <>
              <Tooltip title="Ver QR del evento" disableInteractive arrow>
                <IconButton
                  size="small"
                  aria-label="edit"
                  color="primary"
                  onClick={() => SwalQR({ title: 'QR del Evento', QR: post.UUID })}
                >
                  <Icon>qr_code_scanner</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Asistencia Manual" disableInteractive arrow>
                <IconButton
                  size="small"
                  aria-label="edit"
                  color="info"
                  onClick={() => navigate(`${URL}pase-lista/${post.id}`)}
                >
                  <Icon>front_hand</Icon>
                </IconButton>
              </Tooltip>
            </>
          )}

          {middleware.checkMenuAction('Agregar') && !YaPasoDia && (
            <Tooltip title="Agregar Staff" disableInteractive arrow>
              <IconButton
                size="small"
                aria-label="edit"
                color="secondary"
                onClick={() => navigate(`${URL}staff/${post.id}`)}
              >
                <Icon>groups</Icon>
              </IconButton>
            </Tooltip>
          )}

          {middleware.checkMenuAction('Agregar') && !YaPasoDia && !post.TienePreregistro && (
            <Tooltip title="Agregar invitados" disableInteractive arrow>
              <IconButton
                size="small"
                aria-label="edit"
                color="secondary"
                onClick={() => navigate(`${URL}invitados/${post.id}`)}
              >
                <Icon>add</Icon>
              </IconButton>
            </Tooltip>
          )}

          {post.TienePreregistro === 1 && !YaPasoDia && (
            <Tooltip
              title={post.TienePreregistro ? 'Compartir Preregistro' : 'No tiene Preregistro'}
              disableInteractive
              arrow
            >
              <IconButton
                size="small"
                aria-label="shares"
                onClick={post.TienePreregistro ? handleShare : () => {}}
                disabled={!post.TienePreregistro}
              >
                <Icon>share</Icon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Grid>

      {isDownload && (
        <Grid item xs={12}>
          <Box marginBottom={2}>
            <Download format={'xlsx'} isDownload={isDownload} />
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <CustomTabs
          value={show}
          setValue={setShow}
          tabs={[
            {
              icon: 'group',
              label: 'Registrados',
              value: 0,
              component: (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={search1.nombre}
                      onChange={(e) => setSearch1({ ...search1, nombre: e.target.value })}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Celular"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={search1.celular}
                      onChange={(e) => setSearch1({ ...search1, celular: e.target.value })}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <AdvancedSelect
                      label="Municipio"
                      options={cat.municipios}
                      value={search1.idMunicipio}
                      onChange={(e) => setSearch1({ ...search1, idMunicipio: e.value, municipio: e.label })}
                      isSearchable
                      isLoading={isLoadingCat}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTable
                      rows={filteredRowsInvited}
                      columns={columns}
                      pageSize={10}
                      isLoading={isLoading}
                      stickyHeader
                      maxHeight={400}
                    />
                  </Grid>
                </Grid>
              ),
            },
            {
              icon: 'groups',
              label: 'Asistieron',
              value: 1,
              component: (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={search2.nombre}
                      onChange={(e) => setSearch2({ ...search2, nombre: e.target.value })}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Celular"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={search2.celular}
                      onChange={(e) => setSearch2({ ...search2, celular: e.target.value })}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <AdvancedSelect
                      label="Municipio"
                      options={cat.municipios}
                      value={search2.idMunicipio}
                      onChange={(e) => setSearch2({ ...search2, idMunicipio: e.value, municipio: e.label })}
                      isSearchable
                      isLoading={isLoadingCat}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTable
                      rows={filteredRowsAttended}
                      columns={columns}
                      pageSize={10}
                      isLoading={isLoading}
                      stickyHeader
                      maxHeight={400}
                    />
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

CardEvent.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default CardEvent;
