import middleware from '@middlewares/middleware';
import { Navigate } from 'react-router-dom';
import NotAuthorized from '@pages/auth/NotAuthorized';
import { getVars } from '@utils/global';

export const Public = ({ children }) => {
  return !middleware.getValidToken() ? children : <Navigate to={getVars('Token').user.DefaultPage} />;
};

export const AlreadyLoggedIn = ({ children }) => {
  return middleware.getValidToken() ? (
    <Navigate to={getVars('Token').user.DefaultPage} />
  ) : (
    <Navigate to={'/login'} />
  );
};

export const ProtectedRoute = ({ children }) => {
  return middleware.getValidToken() ? children : <Navigate to={'/login'} />;
};

export const PrivilegedRoute = ({ children }) => {
  return middleware.checkMenuAccess() ? children : <NotAuthorized />;
};
