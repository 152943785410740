import { useState, useEffect } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';
import Swal from 'sweetalert2';
//import { useFormik } from "formik";

// Material UI
import { Box, Button, CardContent, Icon } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Servicios
import MovilizationServices from '@services/MovilizationServices';

/* import { MovilizationByHourInterface } from "@data/interfaces/MovilizationInterfaces";
import { HourMovilizationSchema } from "@data/schemas/MovilizationSchemas";
import { isEmptyOrInvalidString } from "@utils/validations"; */

const Filter = ({ tab, setParamsFilter, onChange, onClear }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: 'municipios_reportes', getAll: false },
    { id: 'poligonos', getAll: false },
    { id: 'secciones', getAll: false },
  ];
  const showFilterHours = [0, 2];
  const tabsRequired = [0];
  const MunRequired = [2];

  const showLista_MovReportada = 2;

  const options_Yes_No = [
    { value: 0, label: 'TODOS' },
    { value: 'SI', label: 'SI' },
    { value: 'NO', label: 'NO' },
  ];

  /*   const formik = useFormik({
    initialValues: MovilizationByHourInterface,
    validationSchema: HourMovilizationSchema,
    enableReinitialize: false,
    onSubmit: (values, actions) => {
      onChange({ ...values, Seccion: parseInt(values.Seccion) });
      handleSearch({
        municipio: filterData.municipio,
        poligono: filterData.poligono,
        seccion: values.Seccion,
      });
    },
  }); */

  //States
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catSchedules, setCatSchedules] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [filterData, setFilterData] = useState({
    municipio: '',
    poligono: '',
    hora: '',
    seccion: '',
    TienePaseLista: 0,
    MovilizacionReportada: 0,
  });
  const [errors, setErrors] = useState({ hora: false, seccion: false, municipio: false });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await MovilizationServices.getSchedules({});
      const { results, response, message } = result;

      if (results) setCatSchedules(response.data);
      else throw new Error(message);
    } catch (error) {
      setCatSchedules([]);
      Swal.fire({ title: error, icon: 'warning' });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const handleChangeFilter = (e) => {
    const key = e?.target ? e.target.name : e.name;
    const value = e?.target ? e.target.value : e.value;

    setFilterData((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    setCatalogFiltered((prev) => ({
      ...prev,
      poligonos: catalogs.poligonos.filter(
        ({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality
      ),
      secciones: catalogs.secciones.filter(({ idMunicipio }) => idMunicipio === _idMunicipality),
    }));
    setFilterData((prev) => ({ ...prev, poligono: '', seccion: '' }));
    //formik.setValues({ ...formik.values, Seccion: "" });
  };

  const handleChangePolygon = (e) => {
    const _idPolygon = e.target.value;
    setCatalogFiltered((prev) => ({
      ...prev,
      secciones: catalogs.secciones.filter(({ idPoligono }) => idPoligono === _idPolygon),
    }));

    setFilterData((prev) => ({ ...prev, seccion: '' }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitFilter();
      e.preventDefault();
    }
  };

  const handleSubmitFilter = () => {
    setParamsFilter((prev) => ({
      ...prev,
      seccion: filterData.seccion,
      hora: filterData.hora,
    }));

    onChange(filterData);
  };

  const handleClear = () => {
    setCatalogFiltered(catalogs);
    setFilterData((prev) => ({
      ...prev,
      municipio: '',
      poligono: '',
      seccion: '',
      hora: '',
      TienePaseLista: 0,
      MovilizacionReportada: 0,
    }));
    setParamsFilter((prev) => ({ ...prev, seccion: '', hora: '' }));
    setErrors({ hora: false, seccion: false, municipio: false });
    onClear();
    /*   formik.setValues({
      ...formik.values,
      Seccion: "",
      Hora: "",
    }); */
  };

  useEffect(() => {
    if (showFilterHours.includes(tab)) {
      // hora y sección requerida en tab "CAPTURA DE MOV"
      if (tabsRequired.includes(tab)) {
        if (filterData.hora === '') {
          setErrors((prev) => ({ ...prev, hora: true }));
        } else setErrors((prev) => ({ ...prev, hora: false }));

        if (filterData.seccion === '') setErrors((prev) => ({ ...prev, seccion: true }));
        else setErrors((prev) => ({ ...prev, seccion: false }));
      } else setErrors((prev) => ({ ...prev, hora: false, seccion: false }));

      // Municipio requerido en tab "REPORTE"
      if (MunRequired.includes(tab)) {
        if (filterData.municipio === '') {
          setErrors((prev) => ({ ...prev, municipio: true }));
        } else setErrors((prev) => ({ ...prev, municipio: false }));
      } else setErrors((prev) => ({ ...prev, municipio: false }));
    } else setErrors({ hora: false, seccion: false, municipio: false });
    // eslint-disable-next-line
  }, [tab, filterData.hora, filterData.seccion, filterData.municipio]);

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      setFilterData((prev) => ({
        ...prev,
        municipio: catalogs.municipios_reportes?.length === 1 ? catalogs.municipios_reportes[0].value : '',
        poligono: catalogs.poligonos?.length === 1 ? catalogs.poligonos[0].value : '',
      }));
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <CardContent>
      <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <BasicSelect
          name="municipio"
          label="Municipio"
          options={catalogsFiltered.municipios_reportes}
          value={
            catalogsFiltered.municipios_reportes?.length === 1
              ? catalogsFiltered.municipios_reportes[0].value
              : filterData.municipio
          }
          disabled={catalogsFiltered.municipios_reportes?.length <= 1}
          error={errors.municipio}
          errorMessage={errors.municipio ? 'Municipio es requerido' : ''}
          onChange={(e) => {
            handleChangeFilter(e);
            handleChangeMunicipality(e);
          }}
          isLoading={load}
          sx={{ width: '100%' }}
        />

        <BasicSelect
          name="poligono"
          label="Polígono"
          options={catalogsFiltered.poligonos}
          value={
            catalogsFiltered.poligonos?.length === 1
              ? catalogsFiltered.poligonos[0].value
              : filterData.poligono
          }
          disabled={catalogsFiltered.poligonos?.length <= 1}
          onChange={(e) => {
            handleChangeFilter(e);
            handleChangePolygon(e);
          }}
          isLoading={load}
          sx={{ width: '100%' }}
        />

        {showFilterHours.includes(tab) && !MunRequired.includes(tab) && (
          <BasicSelect
            error={errors.hora}
            errorMessage={errors.hora ? 'El campo hora es requerido' : ''}
            name="hora"
            label="Hora de Reporte"
            options={catSchedules}
            value={filterData.hora}
            onChange={handleChangeFilter}
            isLoading={loadingCatalogs}
            sx={{ width: '100%' }}
          />
        )}

        <AdvancedSelect
          name="seccion"
          label="Sección"
          options={catalogsFiltered.secciones?.map((item) => ({
            value: item.value,
            label: String(item.label),
          }))}
          value={filterData.seccion}
          onChange={handleChangeFilter}
          onKeyDown={handleKeyPress}
          error={errors.seccion}
          helperText={errors.seccion ? 'El campo sección es requerido' : ''}
          isSearchable
          isLoading={loadingCatalogs}
          sx={{ width: '100%' }}
        />

        {showLista_MovReportada === tab && (
          <>
            <BasicSelect
              name="TienePaseLista"
              label="Tiene Pase de Lista"
              options={options_Yes_No}
              value={filterData.TienePaseLista}
              onChange={handleChangeFilter}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />

            <BasicSelect
              name="MovilizacionReportada"
              label="Tiene Movilización Reportada"
              options={options_Yes_No}
              value={filterData.MovilizacionReportada}
              onChange={(e) => {
                if (e.target.value === 0)
                  setFilterData((prev) => ({ ...prev, MovilizacionReportada: 0, hora: '' }));
                else handleChangeFilter(e);
              }}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </>
        )}

        {showFilterHours.includes(tab) &&
          MunRequired.includes(tab) &&
          filterData.MovilizacionReportada !== 0 && (
            <BasicSelect
              error={errors.hora}
              errorMessage={errors.hora ? 'El campo hora es requerido' : ''}
              name="hora"
              label="Hora de Reporte"
              options={catSchedules}
              value={filterData.hora}
              onChange={handleChangeFilter}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          )}
      </Box>

      <Box>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search</Icon>}
          onClick={handleSubmitFilter}
          disabled={Object.values(errors).includes(true)}
          sx={{ width: '100%', mb: 1 }}
        >
          buscar
        </Button>
        <Button
          variant="outlined"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search_off</Icon>}
          disabled={Object.values(errors).includes(true)}
          onClick={handleClear}
          sx={{ width: '100%' }}
        >
          Limpiar
        </Button>
      </Box>
    </CardContent>
  );
};

export default Filter;
