import React from 'react';

import { Box, Card, CardContent, Chip, Divider, Icon, Stack, Typography } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';

import Modal from '../../Modal/Dataview';

const IncidentDetail = ({ open, data, setOpen }) => {
  /* const CardHeader = ({ title }) => {
    return (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={2}
        spacing={1}
      >
        <Typography variant="h6" fontWeight={600}>
          {title}
        </Typography>
      </Stack>
    );
  }; */
  const CardBody = ({ title, icon, children }) => {
    return (
      <Box marginBottom={2}>
        <Stack direction={'row'} alignItems={'center'} marginBottom={1} spacing={2}>
          <Icon
            sx={{
              color: grey[500],
              padding: 1,
              borderRadius: '50%',
              width: 34,
              height: 34,
              backgroundColor: grey[100],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </Icon>
          <Typography variant="subtitle2" fontWeight={600}>
            {title}
          </Typography>
        </Stack>
        <Divider sx={{ marginBottom: 2 }} />
        <Box padding={1}>{children}</Box>
      </Box>
    );
  };

  return (
    <Modal open={open} setOpen={setOpen} headerTitle={'Detalles de la Incidencia'}>
      <Card elevation={0}>
        <CardContent>
          <CardBody icon={'pin_drop'} title={'Descripción General'}>
            <Stack direction={'column'} spacing={1} justifyContent={'start'}>
              <Typography variant="body2">Distrito Federal: {data.DistritoFederal}</Typography>
              <Typography variant="body2">Distrito Local: {data.DistritoLocal}</Typography>
              <Typography variant="body2">Municipio: {data.Municipio}</Typography>
              <Typography variant="body2">Sección: {data.Seccion}</Typography>
            </Stack>
          </CardBody>

          <CardBody title={'Datos de la incidencia'} icon={'report'}>
            <Stack direction={'column'} spacing={1}>
              <Typography variant="body2">Incidencia: {data.Incidencia}</Typography>
              <Typography variant="body2">Tipo: {data.Tipo?.toUpperCase()}</Typography>
              <Typography variant="body2">
                Descripcion Incidencia: {data.DescripcionIncidencia?.toUpperCase()}
              </Typography>
            </Stack>
          </CardBody>
          <CardBody title={'Datos de la resolución de la incidencia'} icon={'rule'}>
            <Stack direction={'column'} spacing={1}>
              <Typography variant="body2">
                {data.esResuelto ? (
                  <Chip
                    size="small"
                    label={'Incidencia Resuelta a las - ' + data.HoraSolucion}
                    sx={{ color: green[800], backgroundColor: green[50] }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label="Incidencia No Resuelta"
                    sx={{ color: red[800], backgroundColor: red[50] }}
                  />
                )}
              </Typography>

              {data.esResuelto === 1 && <Typography variant="body2">{data.ComoSeResolvio}</Typography>}
            </Stack>
          </CardBody>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default IncidentDetail;
