import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  Modal,
  Card,
  CardContent,
  Divider,
  CardHeader,
  IconButton,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  Icon,
} from '@mui/material';
import Swal from 'sweetalert2';
import Grid2 from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { orange } from '@mui/material/colors';

import UserServices from '@services/UserServices';
import BasicList from '@components/Lists/BasicList';
import { isTypePhone } from '@utils/validations';
import SocialService from '@services/SocialServices';

const Item = ({ item, selectedIndex, handleSelectedIndex, handleDeleteItem }) => {
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  return (
    <ListItem key={item.value ? item.value : Math.random()} disablePadding>
      <ListItemButton
        selected={selectedIndex === item.value}
        onClick={(event) => handleListItemClick(event, item.value)}
      >
        <ListItemText
          primary={`${item.NombreCompleto} - ${item.Cargo}`}
          /* (${item.Edad} años) secondary={`Loc: ${item.Localidad} - Col. ${item.ColoniaVive}`} */
        />
      </ListItemButton>
    </ListItem>
  );
};

const ModalResponsibleList = (props) => {
  const { openModal, setOpenModal, setResponsible, titulo = 'Responsable', idEstructura } = props;
  const config = {
    title: 'Listado',
    icon: 'people',
    height: 400,
    endMessage: 'No hay más registros para mostrar',
  };
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [options, setOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterData, setFilterData] = useState({
    Celular: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
  });
  const [errors, setErrors] = useState({
    Celular: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
  });

  useEffect(() => {
    if (openModal) {
      handleClearSearch();
    }
  }, [openModal]);

  const handleFilter = () => {
    let filtered = [
      {
        id: 'idPadre',
        filter: '=',
        value: idEstructura,
      },
    ];

    if (filterData.Nombre.length > 0) {
      filtered.push({
        id: 'NombreCompleto',
        filter: 'LIKE',
        value: `%${filterData.Nombre.trim()}%`,
      });
    }
    if (filterData.Celular.length > 0) {
      filtered.push({
        id: 'Celular',
        filter: 'LIKE',
        value: filterData.Celular.trim(),
      });
    }

    return filtered;
  };

  const handleChangeFilter = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'Celular') {
      setFilterData((prevState) => ({
        ...prevState,
        [name]: isTypePhone(value) ? value : filterData[name],
      }));
    } else {
      setFilterData((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? value.toUpperCase() : '',
      }));
    }
  };

  const customValidate = () => {
    let valid = false;

    if (filterData.Celular.length > 0) {
      if (filterData.Celular.length < 10 || filterData.Celular.length > 10) {
        setErrors((prevState) => ({
          ...prevState,
          Celular: 'Ingrese un número de celular a 10 dígitos',
        }));
        valid = false;
      } else {
        setErrors({
          Celular: '',
          Nombre: '',
          Paterno: '',
          Materno: '',
        });
        valid = true;
      }
    } else {
      if (filterData.Nombre.length < 1) {
        setErrors((prevState) => ({
          ...prevState,
          Nombre: 'El Nombre del Responsable es requerido',
        }));
        valid = false;
      } else {
        setErrors({
          Celular: '',
          Nombre: '',
        });
        valid = true;
      }
    }

    return valid;
  };

  const handleSearch = () => {
    let filtered = handleFilter();
    let valid = customValidate();
    if (!valid) return;

    setHasMore(true);
    SocialService.getResponsibles({ page: 0, pageSize, filtered })
      .then((res) => {
        if (res.results) {
          setOptions(res.response.data);
          setTotal(res.response.total);

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
            customClass: {
              container: 'modal-alert',
            },
          });
          setHasMore(false);
        }
      })
      .catch((error) => {
        setHasMore(false);
        Swal.fire({
          title: error.message,
          icon: 'warning',
          customClass: {
            container: 'modal-alert',
          },
        });
      });
  };

  const handleClearSearch = () => {
    setFilterData({
      Nombre: '',
      Celular: '',
    });

    setOptions([]);
    setPage(0);
    setTotal(0);
  };

  const fetchHasMore = () => {
    let filtered = handleFilter();
    UserServices.getResponsibles({ page: page + 1, pageSize, filtered })
      .then((res) => {
        if (res.results) {
          setOptions(options.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
            customClass: {
              container: 'modal-alert',
            },
          });
          setHasMore(false);
        }
      })
      .catch((error) => {
        setHasMore(false);
        Swal.fire({
          title: error.message,
          icon: 'warning',
          customClass: {
            container: 'modal-alert',
          },
        });
      });

    setPage(page + 1);
  };

  const handleClickItem = (id) => {
    const responsible = options.find((item) => item.value === id);
    setResponsible(responsible);
    setOpenModal(false);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={(e) => setOpenModal(false)}
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          position: 'relative',
          /*     top: "50%",
                left: "50%",
                width: "60%", 
                transform: "translate(-50%, -50%)",*/
          overflowY: 'auto',
          maxHeight: '100%',
          boxShadow: 24,
          p: 2,
        }}
      >
        <CardHeader
          title={titulo}
          action={
            <IconButton aria-label="cancel" onClick={() => setOpenModal(false)}>
              <CancelIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box>
            <Grid2 container spacing={3}>
              <Grid2 xs={6} sm={6} md={6} lg={6}>
                <TextField
                  label="Nombre del Responsable"
                  value={filterData.Nombre}
                  name="Nombre"
                  onChange={(e) => handleChangeFilter(e)}
                  error={errors.Nombre}
                  helperText={errors.Nombre.length > 1 && errors.Nombre}
                  sx={{ width: '100%' }}
                  type="text"
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                  onKeyUp={handleOnKeyPress}
                />
              </Grid2>
              <Grid2 xs={6} sm={6} md={6} lg={6}>
                <TextField
                  label="Celular"
                  value={filterData.Celular}
                  name="Celular"
                  onChange={(e) => handleChangeFilter(e)}
                  error={errors.Celular}
                  helperText={errors.Celular.length > 1 && errors.Celular}
                  sx={{ width: '100%' }}
                  type="text"
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                  onKeyUp={handleOnKeyPress}
                />
              </Grid2>
            </Grid2>

            <Grid2 container spacing={1} sx={{ mt: 2 }}>
              <Grid2 item xs={12} sm={5} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={(e) => handleClearSearch()}
                  fullWidth
                >
                  Limpiar
                </Button>
              </Grid2>
              <Grid2 item xs={12} sm={5} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="primaryDark"
                  endIcon={<SearchIcon />}
                  onClick={(e) => handleSearch()}
                  fullWidth
                >
                  Buscar
                </Button>
              </Grid2>
            </Grid2>
          </Box>
          <Divider sx={{ mt: 2, mb: 3 }} />

          <Grid2 container spacing={3}>
            <BasicList
              configParams={config}
              data={options}
              fetchHasMore={fetchHasMore}
              totalItems={total}
              itemComponent={Item}
              hasMore={hasMore}
              handleClickItem={handleClickItem}
              finder={false}
            />
          </Grid2>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ModalResponsibleList;
