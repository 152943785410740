import Yup from '@utils/Yupi18n';
import { isBefore } from 'date-fns';

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const patternINE = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/;
const patternCURP = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]\d$/;

/* Aportantes */

const schemaAportantes = {
  RNM: Yup.string()
    .label('Registro Nacional de Militante')
    .nullable()
    .test('RNM-required-if-FechaAltaRNM', 'El campo RNM es requerido', function (value) {
      const { FechaAltaRNM } = this.parent;
      return FechaAltaRNM ? !!value : true;
    }),

  FechaAltaRNM: Yup.date()
    .label('Fecha de Alta RNM')
    .nullable()
    .test('FechaAltaRNM-required-if-RNM', 'El campo Fecha de Alta RNM es requerido', function (value) {
      const { RNM } = this.parent;
      return RNM ? !!value : true;
    })
    .test('FechaAltaRNM-is-in-the-past', 'La fecha debe ser anterior a hoy', function (value) {
      return value ? isBefore(value, new Date()) : true;
    }),

  CURP: Yup.string().matches(patternCURP, 'CURP no es válido').min(18).max(18).required().label('CURP'),
  INE: Yup.string()
    .matches(patternINE, 'Clave de Elector no es válida')
    .min(18)
    .max(18)
    .required()
    .label('Clave de Elector'),
  SeccionVota: Yup.number().required().min(1).max(3277).label('Seccion Vota'),

  Nombre: Yup.string().required().min(3),
  Paterno: Yup.string().required().min(2),
  Materno: Yup.string().nullable().min(2),
  Sexo: Yup.string().nullable(),

  idMunicipio: Yup.number().required().label('Municipio'),
  Colonia: Yup.string().required().min(3),
  Calle: Yup.string().required().min(3),
  NumExt: Yup.string().optional().nullable(),
  NumInt: Yup.string().optional().nullable(),
  CP: Yup.number().optional().nullable(),

  Correo: Yup.string().email('Correo inválido').required().label('Correo'),
  Celular: Yup.string()
    .min(10, 'Celular debe contener 10 dígitos')
    .max(10, 'Celular debe contener 10 dígitos')
    .required()
    .label('Celular'),
  Facebook: Yup.string()
    .test('valid-url', 'URL inválida', (value) => !value || isValidUrl(value))
    .nullable()
    .label('Facebook'),
  X: Yup.string()
    .test('valid-url', 'URL inválida', (value) => !value || isValidUrl(value))
    .nullable()
    .label('X'),
  Tiktok: Yup.string()
    .test('valid-url', 'URL inválida', (value) => !value || isValidUrl(value))
    .nullable()
    .label('Tiktok'),
  Instagram: Yup.string()
    .test('valid-url', 'URL inválida', (value) => !value || isValidUrl(value))
    .nullable()
    .label('Instagram'),
};

const AportantesSchema = Yup.object().shape(schemaAportantes);

const EditAportantesSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador de aportante'),
  ...schemaAportantes,
});

/* Servicios */

const schemaServicios = {
  NombreServicio: Yup.string().required().min(3).max(100).label('Nombre del servicio'),
  Descripcion: Yup.string().required().min(5).label('Descripción'),
  PorcentajeCargo: Yup.number()
    .min(0.01)
    .max(99.99)
    .test('is-valid-decimal', 'Porcentaje del cargo puede tener hasta dos decimales', (value) => {
      if (value === undefined || value === null) return false;
      const strValue = value.toString();
      return /^(\d{1,2}(\.\d{1,2})?)$/.test(strValue);
    })
    .required()
    .label('Porcentaje del cargo'),
};

const ServiciosSchema = Yup.object().shape(schemaServicios);

const EditServiciosSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador de servicio'),
  ...schemaServicios,
});

/* Perfil */

const schemaPerfil = {
  PerfilAportador: Yup.string().required().min(3).max(100).label('Nombre del perfil'),
};

const PerfilSchema = Yup.object().shape(schemaPerfil);

const EditPerfilSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador del perfil'),
  ...schemaPerfil,
});

/* Percepciones */

const schemaPercepciones = {
  idAportante: Yup.number().min(1).required().label('Aportante'),
  idPerfilAportante: Yup.number().min(1).required().label('Perfil'),
  Cargo: Yup.string().required().min(3).max(200).label('Cargo'),
  Dependencia: Yup.string().required().min(3).max(200).label('Dependencia'),
  MontoNeto: Yup.string()
    .test('is-valid-number', 'Monto neto debe ser un número válido entre 1 y 99,999,999', (value) => {
      const numericValue = parseFloat(value?.replace(/,/g, '') || '');
      return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 99999999.99;
    })
    .required()
    .label('Monto neto'),
};

const PercepcionesSchema = Yup.object().shape(schemaPercepciones);

const EditPercepcionesSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador de percepción'),
  ...schemaPercepciones,
});

/* Cargos */

const schemaCargos = {
  idAportante: Yup.number().min(1).required().label('Aportante'),
  idServicio: Yup.number().min(1).required().label('Servicio'),
  Qna: Yup.number().min(1).required().label('Qna'),
  MontoCargo: Yup.string()
    .test('is-valid-number', 'Monto cargo debe ser un número válido entre 1 y 99,999,999', (value) => {
      const numericValue = parseFloat(value?.replace(/,/g, '') || '');
      return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 99999999.99;
    })
    .required()
    .label('Monto cargo'),
  FechaCargo: Yup.date()
    .required()
    .label('Fecha de cargo')
    .test('FechaCargo-is-in-the-past', 'La fecha debe ser anterior a hoy', function (value) {
      return value ? isBefore(value, new Date()) : true;
    }),
  LineaCaptura: Yup.string().required().min(3).max(50).label('Linea Captura'),
  idEstatusPago: Yup.number().min(1).required().label('Estatus del Pago'),
  isPagado: Yup.number().min(0).max(1).required().label('Pagado'),
  FechaPago: Yup.date()
    .when('isPagado', {
      is: 1,
      then: Yup.date().required().label('Fecha de pago'),
      otherwise: Yup.date().optional(),
    })
    .test('FechaPago-is-in-the-past', 'La fecha debe ser anterior a hoy', function (value) {
      return value ? isBefore(value, new Date()) : true;
    }),
};

const CargosSchema = Yup.object().shape(schemaCargos);

const EditCargosSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador de cargo'),
  ...schemaCargos,
});

/* Pagos */

const schemaPagos = {
  idAportante: Yup.number().min(1).required().label('Aportante'),
  idCargo: Yup.number().min(1).required().label('Cargo'),
  MontoPagado: Yup.string()
    .test('is-valid-number', 'Monto cargo debe ser un número válido entre 1 y 99,999,999', (value) => {
      const numericValue = parseFloat(value?.replace(/,/g, '') || '');
      return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 99999999.99;
    })
    .required()
    .label('Monto pagado'),
  FechaPago: Yup.date()
    .required()
    .label('Fecha de pago')
    .test('FechaPago-is-in-the-past', 'La fecha debe ser anterior a hoy', function (value) {
      return value ? isBefore(value, new Date()) : true;
    }),
  idTipoPago: Yup.number().min(1).required().label('Tipo pago'),
  Banco: Yup.string().required().min(2).max(50).label('Banco'),
};

const PagosSchema = Yup.object().shape(schemaPagos);

const EditPagosSchema = Yup.object().shape({
  id: Yup.number().min(1).required().label('Identificador de pago'),
  ...schemaPagos,
});

export { AportantesSchema, EditAportantesSchema };
export { ServiciosSchema, EditServiciosSchema };
export { PerfilSchema, EditPerfilSchema };
export { PercepcionesSchema, EditPercepcionesSchema };
export { CargosSchema, EditCargosSchema };
export { PagosSchema, EditPagosSchema };
