import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress, Card, CardContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { numberWithCommas } from '@utils/Utilities/';

const Grafica = (props) => {
  const { title, actualDate, loading, data } = props;

  const { height, width } = useWindowDimensions();
  const categories = data.label;
  const maleData = data.hombre;
  const femaleData = data.mujer.map((item) => -Math.abs(item));

  const maxDataValue = Math.max(
    Math.max(...maleData.map((val) => Math.abs(val))),
    Math.max(...femaleData.map((val) => Math.abs(val)))
  );

  const yAxisOptions = {
    title: {
      text: null,
    },
    max: Math.ceil(maxDataValue / 10) * 10,
    min: -Math.ceil(maxDataValue / 10) * 10,
    labels: {
      formatter: function () {
        return numberWithCommas(Math.abs(this.value));
      },
      style: {
        fontSize: width > 1400 ? width * 0.01 : '14px',
      },
    },
    accessibility: {
      description: 'Population',
      rangeDescription: `Range: -${Math.ceil(maxDataValue / 10) * 10} to ${
        Math.ceil(maxDataValue / 10) * 10
      }`,
    },
  };

  const options = {
    chart: {
      type: 'bar',
      height: width > 1440 ? height * 0.4 : 'auto',
    },
    title: {
      text: `${title}`,
      align: 'right',
      style: {
        color: '#999',
        fontSize: width > 1440 ? width * 0.012 : '18px',
      },
    },
    subtitle: {
      text: `${actualDate}`,
      align: 'right',
      style: {
        fontSize: width > 1440 ? width * 0.0085 : '12px',
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.',
      },
    },
    xAxis: [
      {
        categories: categories,
        reversed: false,
        labels: {
          step: 1,
        },
        accessibility: {
          description: 'Edad (Masculino)',
        },
      },
      {
        opposite: true,
        reversed: false,
        categories: categories,
        linkedTo: 0,
        labels: {
          step: 1,
        },
        accessibility: {
          description: 'Edad (Femenino)',
        },
      },
    ],
    yAxis: yAxisOptions,
    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: '1%',
      },
    },
    tooltip: {
      formatter: function () {
        const isNegative = this.y < 0;
        const formattedValue = isNegative ? Math.abs(this.y) : this.y;
        return `<b>Edad: ${this.point.category}</b><br/><span style="color:${
          this.color
        }">\u25CF</span> ${this.series.name}: <b>${numberWithCommas(formattedValue)}</b><br/>`;
      },
    },
    series: [
      {
        name: 'Masculino',
        data: maleData,
        color: '#37a3eb',
      },
      {
        name: 'Femenino',
        data: femaleData,
        color: '#FF6486',
      },
    ],
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default Grafica;
