import { useState } from 'react';
import { Container, Card, CardContent, Box } from '@mui/material';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BingoForm from '@components/Activation/Bingo/BingoForm';
import BingoTable from '@components/Activation/Bingo/BingoTable';
import Grid2 from '@mui/material/Unstable_Grid2';

const Bingo = () => {
  const [box, setBox] = useState(null);
  const [lastInsert, getLastInsert] = useState(null);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Cotejo / Captura Bingo" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />
        <Box sx={{ mt: 1, mb: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <BingoForm handleSetBox={setBox} getLastInsert={getLastInsert} />
            </Grid2>
            <Grid2 xs={12} sm={12} md={9} lg={9}>
              <BingoTable
                box={box}
                setIsLoadingForm={setIsLoadingForm}
                setOpenLinearProgress={setOpenLinearProgress}
                lastInsert={lastInsert}
              />
            </Grid2>
          </Grid2>
        </Box>
        {/*                 <Card className="card-primary">
                    <CardContent>
                        <Box sx={{ mt: 1, mb: 2 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 xs={12} sm={12} md={3} lg={3}>
                                    <BingoForm
                                        handleSetBox={setBox}
                                        getLastInsert={getLastInsert}
                                    />
                                </Grid2>
                                <Grid2 xs={12} sm={12} md={9} lg={9}>
                                    <BingoTable
                                        box={box}
                                        setIsLoadingForm={setIsLoadingForm}
                                        setOpenLinearProgress={setOpenLinearProgress}
                                        lastInsert={lastInsert}
                                    />
                                </Grid2>
                            </Grid2>
                        </Box>
                    </CardContent>
                </Card> */}
      </Container>
    </AdminLayout>
  );
};

export default Bingo;
