import { useState } from 'react';
import { useLocation } from 'react-router-dom';

//MUI
import { Container, Box } from '@mui/material';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BingoFolioFilter from '@components/Activation/Bingo/BingoCorrections/Filter';
import BingoFolioTable from '@components/Activation/Bingo/BingoCorrections/BingoCorrectionsTable';
import CustomTabs from '@components/Tabs/CustomTabs';
import BingoCorregidos from '@components/Activation/Bingo/BingoCorrections/BingoCorregidos';

const BingoCorrection = () => {
  const location = useLocation();

  const [filtered, setFiltered] = useState(null);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [userList, setUserList] = useState([]);
  const [total, setTotal] = useState(0);
  const [clean, setClean] = useState(false);
  const [tabValue, setTabValue] = useState('0');
  const [seccionLocation, setSeccionLocation] = useState(location.state ? location.state : null);

  const handleClear = () => {
    setUserList([]);
    setTotal(0);
    setClean((prevState) => !prevState);
    setFiltered(null);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Bingo: Corrección de casilla y folio" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />

        <Box sx={{ mt: 1, mb: 2 }}>
          <BingoFolioFilter
            seccionLocation={seccionLocation}
            onChange={setFiltered}
            onClear={handleClear}
            setSeccionLocation={setSeccionLocation}
          />
          <CustomTabs
            value={tabValue}
            setValue={setTabValue}
            tabs={[
              {
                label: 'Captura',
                value: '0',
                component: (
                  <BingoFolioTable
                    filtered={filtered}
                    data={userList}
                    dataFound={true}
                    seccionLocation={seccionLocation}
                    onClean={clean}
                    total={total}
                    setLoading={(e) => {
                      setIsLoadingForm(e);
                      setOpenLinearProgress(e);
                    }}
                  />
                ),
              },
              {
                label: 'Bingos corregidos',
                value: '1',
                component: (
                  <BingoCorregidos
                    params={filtered}
                    seccionLocation={seccionLocation}
                    setLoading={(e) => {
                      setIsLoadingForm(e);
                      setOpenLinearProgress(e);
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default BingoCorrection;
