import { useEffect, useState } from 'react';
import { useSorted } from '@hooks/useSorted';
import { useLocation } from 'react-router-dom';
import { COLUMNS_INCIDENTS } from '@data/constants/Sije';
import sije from '@services/SijeService';
import Swal from 'sweetalert2';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
//import BasicTable from "@components/Tables/BasicTable";
import Filter from '@components/Sije/Incidents/Filter';
import CaptureIncindets from '@components/Sije/CaptureForm/CaptureIncindets';
import Download from '@components/Downloads/Download';
import ModalPreviewImages from '@components/Sije/OperationCasilla/ModalPreviewImages';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomTable from '@components/Tables/CustomTable';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue, green } from '@mui/material/colors';
import IncidentDetail from '../../components/Sije/Incidents/IncidentDetail';
import UpdateIncidentStatus from '../../components/Sije/Incidents/UpdateIncidentStatus';

const Incidents = () => {
  const location = useLocation();
  const idCasilla = location.state ? location.state : null;

  const STATUS = {
    Nueva: { color: blue[900], bgColor: blue[50] },
    Atendido: { color: green[900], bgColor: green[50] },
  };

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [total, setTotal] = useState(10);
  const [dataListIncidents, setDataListIncidents] = useState([]);
  const [loading, setLoading] = useState({
    isLoading: false,
    isOpen: false,
  });
  const [flagForm, setFlagForm] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [title, setTitle] = useState('Reporte de Incidencias');
  const [preview, setOpenPreview] = useState(false);
  const [dataImage, setDataImage] = useState([]);
  const [loadingDataImage, setLoadingDataImage] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [dataDetailIncident, setDataDetailIncident] = useState({});
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [idIncident, setIdIncident] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { handleRequestSort } = useSorted({ total: total, setData });

  const getListSijeIncidents = async (data) => {
    try {
      setLoading({ ...loading, isLoading: true, isOpen: true });
      const result = await sije.getListSijeIncidents(data);
      const { response, message, results } = await result;
      if (results) {
        setDataListIncidents(
          response.data.map((item) => {
            return {
              ...item,
              Accion: (
                <ButtonGroup>
                  <IconButton size="small" onClick={() => handleShowDetail(item)}>
                    <Tooltip title="Ver detalles">
                      <Icon>visibility</Icon>
                    </Tooltip>
                  </IconButton>
                </ButtonGroup>
              ),
              Estatus: (
                <div>
                  <Chip
                    label={item.Estatus}
                    size="small"
                    sx={{
                      color: STATUS[item.Estatus].color,
                      backgroundColor: STATUS[item.Estatus].bgColor,
                    }}
                  />
                  {!item.esResuelto && (
                    <IconButton size="small" onClick={() => handleShowUpdateStatus(item)}>
                      <Tooltip title="Actualizar Estatus">
                        <Icon>autorenew</Icon>
                      </Tooltip>
                    </IconButton>
                  )}
                </div>
              ),
              tieneFoto: item.tieneFoto ? (
                <IconButton color="primaryDark" onClick={() => getListImage({ idCasilla: item.idCasilla })}>
                  <Icon>photo_camera</Icon>
                </IconButton>
              ) : null,
            };
          })
        );
        setTotal(response.total);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoading({ ...loading, isLoading: false, isOpen: false });
      setRefresh(false);
    }
  };

  const getListImage = ({ idCasilla }) => {
    setOpenPreview(true);
    getPicturesListSije({ idCasilla });
  };

  const getPicturesListSije = async ({ idCasilla }) => {
    try {
      setLoadingDataImage(true);
      const result = await sije.getPicturesListSije({
        filtered: [
          {
            id: 'sije_casillas_fotos.idCasilla',
            filter: '=',
            value: idCasilla,
          },
          {
            id: 'sije_clasificacion_etapas.id',
            filter: '=',
            value: 10,
          },
        ],
      });
      const { results, response, message } = result;
      if (results) {
        setDataImage(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoadingDataImage(false);
    }
  };

  const handleClickAdd = () => {
    if (flagForm) {
      setFlagForm(false);
      setTitle('Reporte de Incidencias');
      getListSijeIncidents(data);
    } else {
      setTitle('Captura de Incidencia');
      setFlagForm(true);
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const onChangeFilter = (filtros) => setData((prevState) => ({ ...prevState, page: 0, filtered: filtros }));

  const handleExportListSijeIncidents = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: { filtered: data.filtered },
        api: 'sije/export-list-sije-incidencias',
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: 'Descargado con éxito.',
          icon: 'success',
        });
      } else {
        throw new Error('Ocurrió un error, contacte al administrador.');
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const handleShowDetail = (data) => {
    setOpenDetail(true);
    setDataDetailIncident(data);
  };

  const handleShowUpdateStatus = (idIncident) => {
    setOpenUpdateStatus(true);
    setIdIncident(idIncident);
  };

  useEffect(() => {
    if (data || refresh) {
      getListSijeIncidents(data);
    }
    // eslint-disable-next-line
  }, [data, refresh]);

  useEffect(() => {
    if (idCasilla !== null) {
      setData((prevState) => ({
        ...prevState,
        filtered: [
          {
            id: 'idCasilla',
            filter: '=',
            value: idCasilla,
          },
        ],
      }));
    }
  }, [idCasilla]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={'Cargando...'}
        isLoading={loading.isLoading}
        isOpen={loading.isOpen}
        setIsOpen={() => setLoading((prevState) => ({ ...prevState, isOpen: false }))}
      />
      <Container maxWidth={false}>
        <ActionHeader
          title={title}
          titleButton={flagForm ? 'Regresar' : 'Capturar'}
          iconButton={flagForm ? 'chevron_left' : 'add'}
          isAction={true}
          handleclick={() => handleClickAdd()}
        />

        <Card className="card-primary" sx={{ width: { md: flagForm ? '60%' : '100%' }, margin: '0 auto' }}>
          <CardContent>
            {flagForm ? (
              <CaptureIncindets />
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={600}>
                    INCIDENCIAS
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    size="small"
                    startIcon={<Icon>download</Icon>}
                    disabled={isDownload}
                    onClick={handleExportListSijeIncidents}
                  >
                    Exportar
                  </Button>
                </Stack>
                <Box marginBottom={3}>
                  <Filter onChangeFilter={onChangeFilter} />
                </Box>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={'xlsx'} isDownload={isDownload} />
                  </Box>
                )}
                <CustomTable
                  rows={dataListIncidents}
                  columns={COLUMNS_INCIDENTS}
                  total={total}
                  page={data.page}
                  pageSize={data.pageSize}
                  stickyHeader
                  maxHeight={'100%'}
                  handlePagination={handleChangePagination}
                  handleSort={handleRequestSort}
                />
              </>
            )}
          </CardContent>
        </Card>

        <ModalPreviewImages
          images={dataImage}
          openModal={preview}
          loading={loadingDataImage}
          setOpenModal={setOpenPreview}
        />

        <IncidentDetail open={openDetail} data={dataDetailIncident} setOpen={setOpenDetail} />

        <UpdateIncidentStatus
          idIncident={idIncident}
          open={openUpdateStatus}
          setOpen={setOpenUpdateStatus}
          setRefresh={setRefresh}
        />
      </Container>
    </AdminLayout>
  );
};

export default Incidents;
