import { useState } from 'react';

// Material UI
import { Grid, Button, Typography, Box } from '@mui/material';

// Componentes
import DrawerRelative from '@components/Containers/DrawerRelative';

const AdaptiveCardExamples = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);

  const handleOpen1 = () => setIsOpen(!isOpen);
  const handleOpen2 = () => setIsOpen2(!isOpen2);

  return (
    <Grid container spacing={2} rowSpacing={5} sx={{ p: 1 }}>
      {/* Drawer sticky */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Drawer sticky
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DrawerRelative anchor="left" open={true} sxPaper={{ borderRadius: 3, p: 1 }} isSticky>
          <Button fullWidth variant="outlined" onClick={handleOpen1}>
            {isOpen ? 'Cerrar' : 'Abrir'}
          </Button>
        </DrawerRelative>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Box
          sx={{
            height: 1250,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>Inicio</Box>
          <Box>Final</Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DrawerRelative
          anchor="right"
          open={isOpen}
          setOpen={() => handleOpen1(false)}
          title="Titulo"
          icon="person_search"
          sxPaper={{ borderRadius: 3 }}
          className="card-primary"
          isSticky
          screenHeight="md" // Permite que tenga un maxHeight de la pantalla actual cuando llegue a uno inferior a ese break
        >
          <Box sx={{ p: 2 }}> Hola soy un drawer sticky</Box>
        </DrawerRelative>
      </Grid>

      {/* Drawer sin sticky de toda la pantalla */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Drawer normal
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DrawerRelative
          anchor="left"
          open={isOpen2}
          setOpen={() => handleOpen2(false)}
          title="Titulo"
          icon="settings"
          sxPaper={{ borderRadius: 3 }}
          className="card-primary"
        >
          <Box sx={{ p: 2 }}> Hola soy un drawer normal</Box>
        </DrawerRelative>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Box
          sx={{
            height: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>Inicio</Box>

          <Box>Final</Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DrawerRelative anchor="right" open={true} sxPaper={{ borderRadius: 3, p: 1 }} isSticky>
          <Button fullWidth variant="outlined" onClick={handleOpen2}>
            {isOpen2 ? 'Cerrar' : 'Abrir'}
          </Button>
        </DrawerRelative>
      </Grid>

      {/* Drawer variacion del title */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Drawer con variación del titulo
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DrawerRelative
          anchor="left"
          open={true}
          title="Titulo 1"
          titleComponent={<Box>componente aqui</Box>}
          icon="people"
          sxPaper={{ borderRadius: 3 }}
          className="card-primary"
        >
          <Box sx={{ p: 2 }}> Hola soy un drawer con un componente entre el titulo y el boton</Box>
        </DrawerRelative>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DrawerRelative
          anchor="left"
          open={true}
          title="Titulo 1"
          titleComponent={
            <Box alignSelf="center" mb={1}>
              componente aqui
            </Box>
          }
          titleComponentPlace="under"
          icon="check"
          sxPaper={{ borderRadius: 3 }}
          className="card-primary"
        >
          <Box sx={{ p: 2 }}> Hola soy un drawer con un componente abajo del titulo y el boton</Box>
        </DrawerRelative>
      </Grid>
    </Grid>
  );
};

export default AdaptiveCardExamples;
