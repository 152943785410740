import { useFormik } from 'formik';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { isEmptyOrInvalidString, isTypePhone } from '@utils/validations';
import { TypeRequest } from '@data/interfaces/UserInterfaces';
import { TypeRequestSchema } from '@data/schemas/UserSchemas';
import { useEffect } from 'react';

//let currentOTPIndex = 0;
function FormCategorie({ loading, success, setSuccess, onSubmitSupport }) {
  //const inputRef = useRef(null);
  //const [otp, setOtp] = useState(new Array(6).fill(""));
  //  const [activeOTPIndex, setActiveOTPIndex] = useState(0);

  const formik = useFormik({
    initialValues: TypeRequest,
    validationSchema: TypeRequestSchema,
    onSubmit: (values) => requestSupport(values),
  });

  const requestSupport = () => {
    onSubmitSupport({
      Comentarios: formik.values.Comentarios.toUpperCase(),
      idSolicitudSoporte: +formik.values.Categoria,
      Celular: formik.values.Celular,
      Nombre: formik.values.Nombre.toUpperCase(),
    });
  };

  useEffect(() => {
    if (success) {
      formik.resetForm();
      setSuccess(false);
    }
  }, [success]);

  /* const handleChangeOTP = (e) => {
    const { value } = e.target;
    if (isNaN(value)) return false;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
  }; */

  /* const handleKeyDown = (e, index) => {
    currentOTPIndex = index;
    if (e.key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  }; */

  /* useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]); */

  return (
    <Box padding={'16px'}>
      <Stack spacing={4} width={'100%'} marginBottom={3}>
        <TextField
          label="Número Celular"
          name="Celular"
          required
          value={formik.values.Celular}
          disabled={loading}
          onChange={(e) => {
            formik.handleChange({
              target: {
                name: 'Celular',
                value: isTypePhone(e.target.value) ? e.target.value : formik.values.Celular,
              },
            });
          }}
          error={formik.touched.Celular && !isEmptyOrInvalidString(formik.errors.Celular)}
          helperText={formik.touched.Celular && formik.errors.Celular && formik.errors.Celular}
          sx={{ width: '100%' }}
        />
        <TextField
          label="Nombre Completo"
          name="Nombre"
          required
          value={formik.values.Nombre.toUpperCase()}
          disabled={loading}
          onChange={formik.handleChange}
          error={formik.touched.Nombre && !isEmptyOrInvalidString(formik.errors.Nombre)}
          helperText={formik.touched.Nombre && formik.errors.Nombre && formik.errors.Nombre}
          sx={{ width: '100%' }}
        />
      </Stack>

      <FormControl
        error={formik.touched.Categoria && !isEmptyOrInvalidString(formik.errors.Categoria)}
        disabled={loading}
      >
        <FormLabel id="categoria-radio-buttons-group">
          <Typography fontWeight={600} variant="subtitle1" sx={{ color: 'text.secondary' }}>
            * Por favor, elige la categoría que mejor se ajuste a tu consulta o necesidad para que podamos
            proporcionarte la ayuda adecuada.
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="categoria-radio-buttons-group"
          name="Categoria"
          value={formik.values.Categoria}
          onChange={formik.handleChange}
          sx={{ marginTop: 2 }}
        >
          <FormControlLabel value={1} control={<Radio />} label="Registro" />
          <FormControlLabel value={2} control={<Radio />} label="Capacitación" />
          <FormControlLabel value={3} control={<Radio />} label="Dar de baja la cuenta" />
          <FormControlLabel value={4} control={<Radio />} label="Otro" />
        </RadioGroup>
        <FormHelperText variant="outlined">{formik.errors.Categoria}</FormHelperText>
      </FormControl>

      <Stack direction={'row'} marginTop={3}>
        <TextField
          label="Comentarios Adicionales"
          name="Comentarios"
          multiline
          rows={4}
          required
          value={formik.values.Comentarios.toUpperCase()}
          disabled={loading}
          onChange={formik.handleChange}
          error={formik.touched.Comentarios && !isEmptyOrInvalidString(formik.errors.Comentarios)}
          helperText={formik.touched.Comentarios && formik.errors.Comentarios && formik.errors.Comentarios}
          sx={{ width: '100%' }}
        />
      </Stack>

      <Stack direction={'row'} justifyContent={'flex-end'} marginTop={4}>
        <LoadingButton
          variant="contained"
          size="large"
          color="primaryDark"
          loading={loading}
          disabled={loading}
          onClick={formik.handleSubmit}
        >
          Solicitar Soporte
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default FormCategorie;
