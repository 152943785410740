import { useEffect, useState } from 'react';

//MUI

import { Icon, InputAdornment, Stack, TextField, IconButton } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';

// Services, Libreries
import Swal from 'sweetalert2';
import sije from '@services/SijeService';
import moment from 'moment';

const RollCallRgs = ({ data, user, reload }) => {
  const [hour, setHour] = useState(moment().format('HH:mm'));
  const [validHour, setValidHour] = useState(true);
  const [catCasillas, setCatCasillas] = useState([]);
  const [valueCasilla, setValueCasilla] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeValueHour = (inputHour) => {
    const rgx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    setValidHour(rgx.test(inputHour));
    setHour(inputHour);
  };

  const handleChangeCasilla = (e) => {
    setValueCasilla(e.target.value);
  };

  const handleMassRollCall = async () => {
    try {
      setLoading(true);
      const result = await sije.setPaseListaMasivo({
        idUsuario: user.idUsuario,
        casillas: [{ idCasilla: valueCasilla, idTipoPropietario: 0, FechaHora: hour }],
        OrigenCaptura: 'web',
      });
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        });
        setValueCasilla('');
        setHour(moment().format('HH:mm'));
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      const _catCasillas = data.map((casilla) => ({
        label: casilla.NombreCasilla + ' - ' + casilla.Seccion,
        value: casilla.id,
      }));

      setCatCasillas(_catCasillas);
    }
  }, [data]);

  return (
    <>
      <Stack direction={'row'} spacing={2} alignItems={'center'} marginBottom={2}>
        <BasicSelect
          label={!valueCasilla ? 'Selecciona una casilla' : 'Casilla'}
          options={catCasillas}
          value={valueCasilla}
          onChange={handleChangeCasilla}
          sx={{ width: '100%' }}
        />

        <TextField
          label={'* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)'}
          name="hora"
          value={hour}
          onChange={(e) => handleChangeValueHour(e.target.value)}
          size="small"
          sx={{ width: '100%' }}
          InputProps={{
            size: 'small',
            endAdornment: (
              <InputAdornment position="end">
                <Icon>schedule</Icon>
              </InputAdornment>
            ),
          }}
          error={!validHour}
          helperText={!validHour && 'Ingrese una hora válida'}
        />

        <IconButton
          disabled={hour.length === 0 || !validHour || !valueCasilla}
          color="primaryDark"
          onClick={handleMassRollCall}
        >
          {loading ? <Icon sx={{ animation: 'spin 1s linear infinite' }}>autorenew</Icon> : <Icon>send</Icon>}
        </IconButton>
      </Stack>
    </>
  );
};

export default RollCallRgs;
