import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@src/theme/index';
import { LoadingButton } from '@mui/lab';

import logo_sian from '@assets/img/SIAN.png';
import { UserRecoveryPasswd } from '@data/interfaces/UserInterfaces';
import { UserRecoveryPasswdSchema } from '@data/schemas/UserSchemas';
import { isEmptyOrInvalidString, isTypePhone } from '@utils/validations';
import UserServices from '@services/UserServices';
import Swal from 'sweetalert2';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepet, setShowRepet] = useState(false);
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const checkPasswordStrength = (password) => {
    const strengthResults = [
      /[A-Z]/.test(password),
      /[0-9]/.test(password),
      /[!-/:-@[-`{-~]/.test(password),
      password.length >= 8,
    ];

    return strengthResults.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const getPasswordStrengthMessage = (strength) => {
    switch (strength) {
      case 0:
        return 'Contraseña muy débil';
      case 1:
        return 'Contraseña débil';
      case 2:
      case 3:
        return 'Contraseña medianamente segura';
      case 4:
        return 'Contraseña segura';
      default:
        return '';
    }
  };

  const formik = useFormik({
    initialValues: UserRecoveryPasswd,
    validationSchema: UserRecoveryPasswdSchema,
    onSubmit: (values) => {
      onChangeCaptcha({
        UUID: id,
        username: values.Celular,
        Password: values.Contraseña,
        Confirm: values.Confirmar,
      });
    },
  });

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('recoverypasswd')
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          console.log(data);
          setRecoveryPasswd(data);
        })
        .catch((error) => {
          Swal.fire({
            title: error.message,
            icon: 'warning',
            customClass: { container: 'modal-alert' },
          });
        });
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const setRecoveryPasswd = async (values) => {
    setLoading(true);
    try {
      const { results, message } = await UserServices.setRecoveryPasswd(values);
      if (!results) {
        throw new Error(message || 'Ocurrió un error, contacte al administrador');
      }
      Swal.fire({
        title: message,
        icon: 'success',
        customClass: {
          container: 'modal-alert',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate('/login');
        }
      });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const passwordStrength = checkPasswordStrength(formik.values.Contraseña);

  return (
    <ThemeProvider theme={theme}>
      <Box padding={'64px'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Avatar alt="logo_sian" src={logo_sian} sx={{ height: 32, width: 32 }} />
          <Typography variant="subtitle2" letterSpacing={0.5}>
            SIAN{' '}
            <Typography variant="subtitlte2" fontWeight={900} color={blue[900]}>
              GTO
            </Typography>
          </Typography>
        </Stack>
        <Typography variant="h6" fontWeight={600} marginTop={3}>
          Recuperar Contraseña
        </Typography>
        <Box marginTop={3}>
          <TextField
            label="Celular"
            name="Celular"
            size="small"
            value={formik.values.Celular}
            onChange={(e) => {
              formik.handleChange({
                target: {
                  name: 'Celular',
                  value: isTypePhone(e.target.value) ? e.target.value : formik.values.Celular,
                },
              });
            }}
            error={formik.touched.Celular && !isEmptyOrInvalidString(formik.errors.Celular)}
            helperText={formik.touched.Celular && formik.errors.Celular && formik.errors.Celular}
            sx={{ width: '100%', mb: 3 }}
          />
          <TextField
            label="Contraseña"
            name="Contraseña"
            size="small"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.Contraseña}
            onChange={formik.handleChange}
            error={formik.touched.Contraseña && !isEmptyOrInvalidString(formik.errors.Contraseña)}
            helperText={formik.touched.Contraseña && formik.errors.Contraseña && formik.errors.Contraseña}
            sx={{ width: '100%', mb: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box marginBottom={2}>
            <LinearProgress
              variant="determinate"
              value={(passwordStrength / 4) * 100}
              color={passwordStrength === 4 ? 'success' : passwordStrength >= 2 ? 'warning' : 'error'}
              sx={{ height: 8 }}
            />
            <Typography variant="caption" sx={{ textAlign: 'end' }}>
              <strong>{getPasswordStrengthMessage(passwordStrength)}</strong>
            </Typography>
          </Box>
          <TextField
            label="Confirmar Contraseña"
            name="Confirmar"
            size="small"
            type={showRepet ? 'text' : 'password'}
            value={formik.values.Confirmar}
            onChange={formik.handleChange}
            error={formik.touched.Confirmar && !isEmptyOrInvalidString(formik.errors.Confirmar)}
            helperText={formik.touched.Confirmar && formik.errors.Confirmar && formik.errors.Confirmar}
            sx={{ width: '100%', mb: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowRepet(!showRepet);
                    }}
                  >
                    {showRepet ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText
            sx={{ textAlign: "center", color: "red", fontSize: "15px" }}
          >
            La contraseña debe contener mínimo 8 carácteres, una letra
            mayúscula, número y símbolo especial (* _ . @)
          </FormHelperText> */}
          <br />
          <LoadingButton
            color="primaryDark"
            variant="contained"
            sx={{ width: '100%', mb: 3 }}
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Actualizar Contraseña
          </LoadingButton>
          <Button
            color="primaryDark"
            sx={{ width: '100%', mb: 3 }}
            startIcon={<Icon>chevron_left</Icon>}
            onClick={() => navigate('/login')}
          >
            Regresar al login
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Form;
