import { PrivilegedRoute, ProtectedRoute } from '@middlewares';

import Colmena from '@pages/Movilizacion/Colmena';

const MovilizacionRoutes = [
  {
    path: '/colmena-movilizacion',
    element: (
      <ProtectedRoute>
        <Colmena />
      </ProtectedRoute>
    ),
  },
];

export default MovilizacionRoutes;
