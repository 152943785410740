import { useEffect, useState } from 'react';

// Material UI
import {
  CircularProgress,
  Card,
  CardContent,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

// Utilidades
import { numberWithCommas } from '@utils/Utilities/';

// Hooks
import useWindowDimensions from '@hooks/useWindowDimensions';

const TextCard = (props) => {
  const { dataAPI, title = 'titulo', filtered, change } = props;

  const [localTitle, setLocalTitle] = useState(title);
  const [data, setData] = useState([]);
  const [actualDate, setActualDate] = useState(new Date().toLocaleString());
  const [loading, setLoading] = useState(true);

  const { height, width } = useWindowDimensions();

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await dataAPI(filtered);
      const { results, message, response } = result;

      if (results) {
        const dataAPI = response.data[0];
        const dataTextCard = Object.keys(dataAPI).map((key) => ({
          Nombre: key,
          Total: dataAPI[key] === null ? '-' : dataAPI[key],
        }));

        setData(dataTextCard);
      } else {
        Swal.fire({
          title: title,
          text: message,
          icon: 'warning',
        });
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: 'warning',
      });
      setData([]);
    } finally {
      setLocalTitle(title);
      setActualDate(new Date().toLocaleString());
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [change]);

  // Titulos de la tabla
  const columns = [
    { id: 'Nombre', label: 'Nombre' },
    { id: 'Total', label: 'Total', align: 'right' },
  ];

  const paddingCell = width > 1440 ? '2px 20px' : '6px 20px';
  const fontSize = width > 1440 ? width * 0.0085 : 14;

  return (
    <Card className="card-primary">
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{
            minHeight: {
              xs: '100%',
              sm: width > 1440 ? height * 0.4 : '400px',
            },
          }}
        >
          <Typography
            variant="h5"
            fontSize={width > 1440 ? width * 0.01 : 18}
            fontWeight={400}
            align="right"
            color="#999"
          >
            {localTitle.toUpperCase()}
          </Typography>

          <Typography
            variant="h5"
            fontSize={width > 1440 ? width * 0.008 : 12}
            fontWeight={400}
            align="right"
            color="#666"
            letterSpacing={1.3}
          >
            {actualDate}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: 'rgba(189, 189, 189, 0.3)' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        padding: paddingCell,
                        fontSize: fontSize,
                        textAlign: column.align,
                      }}
                    >
                      {column.label.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? ( // Mostrar CircularProgress si loading es true
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center" height="200px">
                      <CircularProgress size={50} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      key={row.Nombre}
                      hover
                      tabIndex={-1}
                      sx={{
                        padding: 0,
                        backgroundColor: index % 2 ? '#E4ECFA' : 'white',
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: paddingCell,
                          fontSize: fontSize,
                        }}
                      >
                        {row.Nombre}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: paddingCell,
                          fontSize: fontSize,
                          textAlign: 'right',
                        }}
                      >
                        {numberWithCommas(row.Total)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TextCard;
