import { useEffect, useState } from 'react';
import Tree from '@components/TreeView/TreeView';
import BasicTable from '@components/Tables/BasicTable';
import CardCommitments from '@components/Card/StatesCard';
import { numberWithCommas } from '@utils/Utilities';
import CardStats from '@components/Card/CardStats';
import {
  Card,
  CardContent,
  Grid,
  Box,
  Tab,
  Icon,
  Typography,
  Stack,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tabs,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import SocialService from '@services/SocialServices';
import Swal from 'sweetalert2';
import { isTypePhone } from '@utils/validations';
import { isEmptyOrNullObject } from '@utils/validations';
import CardInfoSocial from './CardInfoSocial';
import StatsUser from '../../pages/Social/StatsUser';

const TreeStructure = () => {
  const [loadingTree, setLoadingTree] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingCommitments, setLoadingCommitments] = useState(false);
  const [loadingSegmento, setLoadingSegmento] = useState(false);
  const [valueTab, setValueTab] = useState('1');
  const [tree, setTree] = useState([]);
  const [treeFound, setTreeFound] = useState({});
  const [nameUser, setNameUser] = useState('');
  const [totalCommitments, setTotalCommitments] = useState(0);
  const [commitments, setCommitments] = useState([]);
  const [commitmentsSegmento, setCommitmentsSegmento] = useState([]);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [data, setData] = useState({
    idUsuario: 0,
    page: 0,
    pageSize: 10,
    unique: false,
    nested: false,
    filtered: [],
  });
  const [dataParams, setDataParams] = useState({
    page: 0,
    pageSize: 10,
  });
  const [dataParamsCompromisos, setDataParamsCompromisos] = useState({
    page: 0,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [totalUnicosSegmento, setTotalUnicosSegmento] = useState(0);
  const [totalEtiquetado, setTotalEtiquetado] = useState(0);
  const [phone, setPhone] = useState('');
  const [params, setParams] = useState({});
  const [paramsSegmento, setParamsSegmento] = useState('');
  const [dataChart, setDataChart] = useState({});
  const columns = [
    { id: 'Municipio', label: 'Municipio', align: 'center' },
    { id: 'NombreCompleto', label: 'Nombre', align: 'center' },
    { id: 'Celular', label: 'Celular', align: 'center' },
    { id: 'EsVoluntario', label: 'Voluntario', align: 'center' },
    { id: 'Capturador', label: 'Capturado Por', align: 'center' },
    { id: 'Segmento', label: 'Etiqueta', align: 'center' },
  ];

  useEffect(() => {
    if (sortedData.length > 0) {
      let data = {
        ...dataParams,
        sorted: sortedData,
      };

      setDataParams(data);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    const fetchTree = async () => {
      try {
        setLoadingTree(true);
        const res = await SocialService.getResponsables(params);
        if (res.results) {
          const treeData = [res.response];
          setTree(treeData);
          setTreeFound(treeData);
          handleItemTree(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      } catch (err) {
        Swal.fire({ title: err, icon: 'warning' });
      } finally {
        setLoadingTree(false);
      }
    };

    fetchTree();
  }, [params]);

  useEffect(() => {
    if (dataParams.idUsuario && dataParams.idUsuario !== 0) {
      setValueTab('1');
      // getCommitments(dataParamsCompromisos);
      // getCommitmentsSegmentos(dataParams);
    } else if (dataParams.idUsuario && dataParams.idUsuario === 0) {
      setValueTab('2');
    }
  }, []);

  useEffect(() => {
    if (dataParams.idUsuario && dataParams.idUsuario !== 0 && valueTab === '4') {
      getCommitments(dataParamsCompromisos);
    }
  }, [dataParamsCompromisos, valueTab]);

  useEffect(() => {
    if (dataParams.idUsuario && dataParams.idUsuario !== 0 && valueTab === '3') {
      getCommitmentsSegmentos(dataParams);
    }
  }, [dataParams, valueTab]);

  useEffect(() => {
    if (dataParams.idUsuario && dataParams.idUsuario !== 0) {
      getCommitments(dataParamsCompromisos);
      hadleStats();
    } else if (dataParams.idSegmento && dataParams.idUsuario === 0) {
      getCharts();
    }
  }, [dataParams]);

  const getCharts = () => {
    if (loadingStats) {
      return false;
    }
    setLoadingStats(true);

    SocialService.getTotalesSocial({
      idEstructura: dataParams.idSegmento,
    })
      .then((res) => {
        if (res.results) {
          setDataChart(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingStats(false));
  };

  const hadleStats = () => {
    if (loadingStats) {
      return false;
    }
    setLoadingStats(true);
    SocialService.getStatsResponsables({
      idUsuario: dataParams.idUsuario,
      idSegmento: dataParams.idSegmento,
    })
      .then((res) => {
        if (res.results) {
          setTotalUnicosSegmento(res.response?.TotalCompromisosSegmentosUnicos);
          setTotalEtiquetado(res.response?.TotalCompromisosSegmentos);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingStats(false));
  };

  const handleItemTree = (e) => {
    if (!loadingCommitments && !loadingSegmento && !loadingStats && !loadingTree) {
      if (e !== null) {
        const { idUsuario, label, Cargo } = e;
        setNameUser(`${label} ${Cargo}`);
        if (e.idUsuario === 0) {
          setParamsSegmento(e.NombreCompleto);
        }

        setDataParams({
          ...dataParams,
          page: 0,
          idSegmento: e.id
            ? Array.isArray(e.id)
              ? e.id
              : [e.id]
            : Array.isArray(e.idPadre)
              ? e.idPadre
              : [e.idPadre],
          idUsuario: e.idUsuario,
        });
        setDataParamsCompromisos({
          ...dataParamsCompromisos,
          page: 0,
          // idSegmento: e.id ?  Array.isArray(e.id) ? e.id : [e.id] : Array.isArray(e.idPadre) ? e.idPadre : [e.idPadre],
          idUsuario: e.idUsuario,
        });
        setData({ ...data, idUsuario: idUsuario });
      } else {
        setNameUser('');
      }
    }
  };

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const handleChangePagination = (pagination) => {
    setDataParams({ ...dataParams, ...pagination });
  };

  const handleChangePaginationCompromisos = (pagination) => {
    setDataParamsCompromisos({ ...dataParamsCompromisos, ...pagination });
  };

  const getCommitmentsSegmentos = (params) => {
    if (loadingSegmento) {
      return false;
    }
    setLoadingSegmento(true);
    delete params.idSegmento;
    SocialService.getListCommitmentsSegmento(params)
      .then((res) => {
        if (res.results) {
          setCommitmentsSegmento(
            res.response.data.map((item) => {
              return {
                ...item,
                EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO',
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingSegmento(false));
  };

  const getCommitments = (params) => {
    if (loadingCommitments) {
      return false;
    }
    setLoadingCommitments(true);
    SocialService.getListCommitments(params)
      .then((res) => {
        if (res.results) {
          setCommitments(
            res.response.data.map((item) => {
              return {
                ...item,
                EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO',
              };
            })
          );
          setTotalCommitments(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingCommitments(false));
  };

  const handleClearSearch = () => {
    setPhone('');
    setShowButtonCancel(false);
    setParams({
      filtered: [],
    });
    // getTree();
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSearch = () => {
    if (phone === '') {
      return;
    }

    setParamsSegmento('General');
    setParams({
      filtered: [{ id: 'Celular', value: phone, filter: '=' }],
    });
    // const founds = searchTree(treeFound[0], "Celular", phone);
    // console.log(founds);
    // setTree(founds !== null ? [founds] : []);
    // setShowButtonCancel(true);
    // handleItemTree(founds);
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: 'cat_municipios.id',
      NombreCompleto: 'CONCAT_WS( " ", compromisos.Nombre, compromisos.Paterno, compromisos.Materno )',
      Celular: 'compromisos.Celular',
      EsVoluntario: 'compromisos.EsVoluntario',
      Segmento: 'cat_social.NombreSocial ',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card className="card-primary" sx={{ height: '1200px', overflow: 'auto' }}>
          <CardContent>
            <Box marginBottom={'1rem'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1} marginBottom={'0.5rem'}>
                <Icon>person_search</Icon>
                <Typography fontWeight={600} variant={'body2'} marginBottom={'0.5rem'}>
                  Buscar por número de celular
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  label="Celular"
                  size="small"
                  name="phone"
                  value={phone}
                  onChange={(e) =>
                    handleChangePhone({
                      target: {
                        name: 'phone',
                        value: isTypePhone(e.target.value) ? e.target.value : phone,
                      },
                    })
                  }
                  onKeyUp={handleOnKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <Icon color="error">clear</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%' }}
                />

                <Button variant="contained" color="primaryDark" sx={{ width: '25%' }} onClick={handleSearch}>
                  Buscar
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ marginBottom: '1rem' }} />
            <Tree
              data={tree}
              defaultExpanded={tree[0]}
              typeData={{
                value: 'dato',
                label: 'label',
                S_label: null,
                T_label: 'Celular',
              }}
              handleClick={handleItemTree}
              loading={loadingTree}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={8}>
        {parseInt(data.idUsuario) !== 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CardCommitments
                icon={'feed'}
                title={'Compromisos (Gral)'}
                total={`${numberWithCommas(totalCommitments)}`}
                subtitle1={'Compromisos Etiquetados Únicos'}
                subtotal1={numberWithCommas(totalUnicosSegmento)}
                subtitle2={'Compromisos Etiquetados'}
                subtotal2={numberWithCommas(totalEtiquetado)}
                subtitle3={'Compromisos (Sin Etiquetar)'}
                subtotal3={numberWithCommas(totalCommitments - totalEtiquetado)}
                // subtitle4={"P2"}
                //subtotal4={numberWithCommas(dataCardR.representantes.PP)}
                // subIcon={"update"}
                //  subFooter={today.toLocaleDateString()}
                loading={loadingCommitments}
              />
            </Grid>
            {/*         <Grid item xs={12} md={4}>
              <CardStats
                icon={"fact_check"}
                title={"Compromisos Etiquetados Únicos"}
                //total={`${totalUnicosSegmento}`}
                total={`${totalUnicosSegmento} de ${totalCommitments}`}
               // subtitle={"Únicos de General"}
                loading={loadingStats}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardStats
                icon={"fact_check"}
                title={"Compromisos Etiquetados"}
                //total={`${totalUnicosSegmento}`}
                total={`${totalEtiquetado}`}
               // subtitle={"Únicos de General"}
                loading={loadingStats}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardStats
                icon={"feed"}
                title={"Compromisos (Gral)"}
                total={totalCommitments}
                loading={loadingStats}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardStats
                icon={"feed"}
                title={"Compromisos (Sin Etiquetar)"}
                total={totalCommitments - totalEtiquetado}
                loading={loadingStats}
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <CardStats
                icon={'fact_check'}
                title={`Compromisos Etiquetados`}
                total={`${dataChart.compromisos?.TotalCompromisos}`}
                loading={dataChart.compromisos?.TotalCompromisos ? loadingStats : true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardStats
                icon={'fact_check'}
                title={'Responsables'}
                total={`${dataChart.responsables?.TotalResponsables}`}
                loading={dataChart.responsables?.TotalResponsables ? loadingStats : true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardStats
                icon={'feed'}
                title={'Enlaces'}
                total={dataChart.enlaces?.TotalEnlaces}
                loading={dataChart.enlaces?.TotalEnlaces ? loadingStats : true}
              />
            </Grid>
          </Grid>
        )}

        <Card className="card-primary">
          <CardContent>
            {nameUser !== '' && (
              <>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <Icon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>account_circle</Icon>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {nameUser}
                  </Typography>
                </Stack>
                <Divider />
              </>
            )}
            <Box marginTop={'1rem'} sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={valueTab} variant="scrollable" scrollButtons="auto">
                {parseInt(data.idUsuario) > 0 && (
                  <Box>
                    <Tabs onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                      {parseInt(data.idUsuario) > 0 && (
                        <Tab icon={<Icon>badge</Icon>} iconPosition="start" label="Información" value="1" />
                      )}
                      {parseInt(data.idUsuario) > 0 && (
                        <Tab
                          icon={<Icon>badge</Icon>}
                          iconPosition="start"
                          label="Compromisos Etiquetados Graf"
                          value="2"
                        />
                      )}
                      {parseInt(data.idUsuario) > 0 && (
                        <Tab
                          icon={<Icon>badge</Icon>}
                          iconPosition="start"
                          label="Compromisos Etiquetados"
                          value="3"
                        />
                      )}
                      {parseInt(data.idUsuario) > 0 && (
                        <Tab
                          icon={<Icon>diversity_3</Icon>}
                          iconPosition="start"
                          label="Compromisos"
                          value="4"
                        />
                      )}
                    </Tabs>
                  </Box>
                )}
                {parseInt(data.idUsuario) > 0 && (
                  <TabPanel value="1">
                    <CardInfoSocial idUsuario={parseInt(data.idUsuario)} />
                  </TabPanel>
                )}
                {parseInt(data.idUsuario) > 0 && (
                  <TabPanel value="2">
                    <StatsUser
                      idUsuario={data.idUsuario}
                      AvanceSegmentos={totalCommitments}
                      AvanceEtiquetados={totalEtiquetado}
                    />
                  </TabPanel>
                )}
                {parseInt(data.idUsuario) > 0 && (
                  <TabPanel value="3">
                    <BasicTable
                      rows={commitmentsSegmento}
                      hcolumns={columns}
                      pageProp={data.page}
                      pageSize={data.pageSize}
                      total={total}
                      handleChangePagination={handleChangePagination}
                      isLoading={loadingSegmento}
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </TabPanel>
                )}
                {parseInt(data.idUsuario) > 0 && (
                  <TabPanel value="4">
                    <BasicTable
                      rows={commitments}
                      hcolumns={columns}
                      pageProp={data.page}
                      pageSize={data.pageSize}
                      total={totalCommitments}
                      handleChangePagination={handleChangePaginationCompromisos}
                      isLoading={loadingCommitments}
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default TreeStructure;
