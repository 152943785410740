import AdminLayout from '@components/MainPage/AdminLayout';
import { Container, Card, CardContent } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectSearch from '../../components/Selects/SelectSearch';
import { useCatalogs } from '@hooks/useCatalogs';
import { BingofaInterface } from '../../data/interfaces/FrenteAmplioInterfaces';
import { BingoFASchema } from '../../data/schemas/FrenteAmplioSchemas';
import Grid from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { isEmptyOrInvalidString, isEmptyOrNullObject } from '@utils/validations';
import SaveIcon from '@mui/icons-material/Save';
import BasicTable from '@components/Tables/BasicTable';
import { useEffect, useState } from 'react';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ModalSearch from '../../components/Mesa/ModalSearch';
import './styleAlert.css';
import { defaultOptions } from 'highcharts';

const Mesa = () => {
  const colums = [
    {
      label: 'Municipio',
      id: 'Municipio',
      orderBy: 'Municipio',
      columnAction: false,
      width: 100,
    },
    {
      label: 'Mesa',
      id: 'FolioBingo',
      columnAction: false,
      width: 50,
      orderBy: false,
    },
    {
      label: 'Nombre',
      id: 'NombrePersonaVoto',
      columnAction: false,
      width: 250,
      orderBy: false,
    },
    {
      label: 'Fecha Capturo',
      id: 'Hora',
      columnAction: false,
      width: 100,
      orderBy: false,
    },
    {
      label: 'Capturado Por',
      id: 'UsuarioCapturo',
      columnAction: false,
      width: 350,
      orderBy: false,
    },
  ];
  const catalogsParams = [{ id: 'municipios' }];
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [open, setOpen] = useState(false);
  const [flagAlert, setFlagAlert] = useState(false);
  const formik = useFormik({
    initialValues: BingofaInterface,
    validationSchema: BingoFASchema,
    onSubmit: (values) => {
      handleSend(values);
    },
  });

  useEffect(() => {
    if (flagAlert) {
      setOpen(true);
      setFlagAlert(false);
    }
  }, [flagAlert]);

  const List = (info) => {
    if (isLoading) {
      return false;
    }

    setIsLoading(true);
    setParams(info);
    FrenteAmplioServices.getBingos(info)
      .then((res) => {
        if (res.results) {
          setData(res.response.data);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
          setData([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
        setData([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: 'cat_municipios.Municipio',
      NombreCompleto:
        "CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno, compromisos_unicos_fa.Materno)",
      Celular: 'compromisos_unicos_fa.Celular',
      Correo: 'compromisos_unicos_fa.Correo',
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleSend = (value) => {
    if (isLoadingSend) {
      return false;
    }
    setIsLoadingSend(true);
    setOpen(false);
    let send = {
      idMunicipio: value.idMunicipio.value,
      FolioBingo: value.Bingo,
      OrigenCaptura: 'web',
    };
    FrenteAmplioServices.setBingo(send)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: res.response.message,
            showConfirmButton: true,
            preConfirm: (login) => {
              setOpen(true);
            },
          });
          let filter = catalogs.municipios.find((item) => item.value === send.idMunicipio);
          handleMunicipio(filter);
          // List({ ...params, idMunicipio: send.idMunicipio });
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: (login) => {
              setOpen(true);
            },
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (login) => {
            setOpen(true);
          },
        });
      })
      .finally(() => {
        setIsLoadingSend(false);
      });
  };

  const handleChangePagination = (pagination) => {
    setParams({ ...params, ...pagination });
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const onKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita el comportamiento predeterminado (como agregar saltos de línea)
      formik.handleSubmit(); // Envía el formulario
    }
  };

  const handleMunicipio = (e) => {
    if (e.value !== 0) {
      formik.handleChange({
        target: {
          name: 'idMunicipio',
          value: e,
        },
      });
      List({ ...params, idMunicipio: e.value });
    } else {
      formik.handleChange({
        target: {
          name: 'idMunicipio',
          value: { value: 0, label: 'TODOS' },
        },
      });
    }
  };

  const handleOpen = (e) => {
    setOpen(e);
  };

  const handleData = (e) => {
    formik.handleChange({
      target: {
        name: 'idMunicipio',
        value: e.idMunicipio,
      },
    });
    formik.handleChange({
      target: {
        name: 'Bingo',
        value: e.Bingo,
      },
    });
    handleSend({
      idMunicipio: e.idMunicipio,
      Bingo: e.Bingo,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        {open && (
          <ModalSearch
            open={open}
            setOpen={(e) => handleOpen(e)}
            onChange={(e) => handleData(e)}
            catalogs={catalogs}
            formik={formik}
            handleMunicipio={handleMunicipio}
            load={load}
          />
        )}
        <Card>
          <CardContent
            onKeyPress={(e) => {
              onKey(e);
            }}
          >
            <Grid container spacing={2}>
              {/*   <Grid item sm={12} xs={12} md={2} lg={2}>
                <SelectSearch
                  multiple={false}
                  placeholder="Municipio *"
                  name="idMunicipio"
                  closeMenuOnSelect={true}
                  value={
                    Object.keys(catalogs).length > 0 &&
                    catalogs.municipios.length === 1
                      ? catalogs.municipios[0]
                      : formik.values.idMunicipio
                  }
                  onChange={(e) =>handleMunicipio(e)}
                  options={catalogs.municipios}
                  disabled={
                    Object.keys(catalogs).length > 0 &&
                    catalogs.municipios.length === 1
                      ? true
                      : false
                  }
                  isLoading={load}
                  error={
                    formik.touched.idMunicipio &&
                    !isEmptyOrInvalidString(formik.errors.idMunicipio)
                  }
                  helperText={
                    formik.touched.idMunicipio &&
                    formik.errors.idMunicipio &&
                    formik.errors.idMunicipio.values
                  }
                />
              </Grid> */}
              {/*   <Grid item sm={12} xs={12} md={2} lg={2}>
                <TextField
                  error={
                    formik.touched.Hoja &&
                    !isEmptyOrInvalidString(formik.errors.Hoja)
                  }
                  helperText={
                    formik.touched.Hoja &&
                    formik.errors.Hoja &&
                    formik.errors.Hoja
                  }
                  sx={{ width: "100%" }}
                  required
                  label="Hoja"
                  type="number"
                  name="Hoja"
                  onChange={formik.handleChange}
                  value={formik.values.Hoja}
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid> */}
              {/*    <Grid item sm={12} xs={12} md={2} lg={2}>
                <TextField
                  error={
                    formik.touched.Bingo &&
                    !isEmptyOrInvalidString(formik.errors.Bingo)
                  }
                  helperText={
                    formik.touched.Bingo &&
                    formik.errors.Bingo &&
                    formik.errors.Bingo
                  }
                  sx={{ width: "100%" }}
                  required
                  label="Bingo"
                  type="number"
                  name="Bingo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Bingo}
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid> */}
              {/*    <Grid item sm={12} xs={12} md={1} lg={1}>
                <LoadingButton
                  sx={{ mt: 1 }}
                  loading={isLoadingSend}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  size="small"
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </LoadingButton>
              </Grid> */}
              <Grid item sm={12} xs={12} md={1} lg={1}>
                <LoadingButton
                  sx={{ mt: 1 }}
                  loadingPosition="end"
                  endIcon={<PersonSearchIcon />}
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => setOpen(true)}
                >
                  Buscar
                </LoadingButton>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <br />
                <BasicTable
                  rows={data}
                  hcolumns={colums}
                  handleChangePagination={handleChangePagination}
                  isLoading={isLoading}
                  total={total}
                  pageProp={params.page}
                  pageSize={params.pageSize}
                  stickyHeader={true}
                  handleManualSort={handleRequestSort}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default Mesa;
