import { useEffect, useState } from 'react';
import { ACTIONS_SPEED, COLUMNS_APERTURA } from '@data/constants/Sije';
import { useSorted } from '@hooks/useSorted';
import Swal from 'sweetalert2';
import sije from '@services/SijeService';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { blue } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import BasicTable from '@components/Tables/BasicTable';
import AttendanceCard from '@components/Attendance/AttendanceCard';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Download from '@components/Downloads/Download';
import CardRepresentative from '@components/Sije/CaptureForm/CardRepresentative';
import RollCall from '@components/Sije/StartCapture/RollCall';
import Installation from '@components/Sije/StartCapture/Installation';
import Opening from '@components/Sije/StartCapture/Opening';
import ModalPreviewImages from '@components/Sije/OperationCasilla/ModalPreviewImages';
import ModalDetailsCasilla from '@components/Sije/Directory/ModalDetailsCasilla';
import Filter from '@components/Sije/FilterDetail/Filter';

const StartCapture = () => {
  //States
  const [valueTab, setValueTab] = useState('1');
  const [nameTab, setNameTab] = useState('PASE DE LISTA');
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [loadingRg, setLoadingRg] = useState(false);
  const [loadingCasilla, setLoadingCasilla] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [loadingRc, setLoadingRc] = useState(false);
  const [dataRg, setDataRg] = useState({
    contentData: '',
    leftFooterData: '',
    rightFooterData: '',
  });
  const [dataCasillas, setDataCasillas] = useState({
    contentData: '',
    leftFooterData: '',
    rightFooterData: '',
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [dataAgendaCasilla] = useState({
    page: 0,
    pageSize: 10,
  });
  const [dataListApertura, setDataListApertura] = useState([]);
  const [total, setTotal] = useState(10);
  const [dataRC, setDataRC] = useState({
    avance: [],
    total: [],
  });
  const [dataImage, setDataImage] = useState([]);
  const [loadingDataImage, setLoadingDataImage] = useState(false);
  const [preview, setOpenPreview] = useState(false);
  const [itemCasilla, setItemCasilla] = useState({});
  const [loadingModalCasilla, setLoadingModalCasilla] = useState(false);
  const [openModalCasilla, setOpenModalCasilla] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const handleChangeTab = (event, newValue) => {
    setNameTab(event.target.innerText);
    setValueTab(newValue);
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const paseListaRg = async () => {
    try {
      setLoadingRg((prevState) => !prevState);
      const result = await sije.getChartPaseListaRG({});
      const { response, results } = await result;
      if (results) {
        setDataRg({
          contentData: response.Porcentaje,
          leftFooterData: response.Meta,
          rightFooterData: response.Avance,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingRg((prevState) => !prevState);
    }
  };

  const getCardAperturadasInstaladas = async () => {
    try {
      setLoadingCasilla((prevState) => !prevState);
      const result = await sije.getCardAperturadasInstaladas({});
      const { response, results } = await result;
      if (results) {
        setDataCasillas({
          contentData: response.data[0].Porcentaje,
          leftFooterData: response.data[0].totalInstaladas,
          rightFooterData: response.data[0].totalAperturadas,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCasilla((prevState) => !prevState);
    }
  };

  const getRepresentantesCasillas = async () => {
    try {
      setLoadingRc(true);
      const result = await sije.getChartPaseListaRcs({});
      const { response, results } = await result;
      if (results) {
        setDataRC({
          avance: response.series2,
          total: response.series1,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingRc(false);
    }
  };

  const getListSijeApertura = async (data) => {
    try {
      setLoadingDetail((prevState) => !prevState);
      const result = await sije.getListSijeApertura(data);
      const { results, response } = await result;
      if (results) {
        const _data = response.data.map((item) => {
          return {
            ...item,
            NombreCasilla: (
              <Button
                variant="outlined"
                size="small"
                sx={{ width: '100%' }}
                color="primaryDark"
                onClick={() => handleModalDetailsCasilla({ item })}
              >
                {item.NombreCasilla}
              </Button>
            ),
            Conectividad: (
              <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                <Icon fontSize="small" color={item.ConectividadInternet ? 'primary' : 'disabled'}>
                  wifi
                </Icon>
                <Icon fontSize="small" color={item.ConectividadTelefono ? 'primary' : 'disabled'}>
                  phone_iphone
                </Icon>
              </Stack>
            ),
            tieneFoto: item.tieneFoto ? (
              <IconButton color="primaryDark" onClick={() => getListImage({ idCasilla: item.idCasilla })}>
                <Icon>photo_camera</Icon>
              </IconButton>
            ) : null,
            TipoTransmision: (
              <Box>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={item.TipoTransmision.total}
                  direction="right"
                  sx={{
                    '& .MuiFab-primary': { width: 32, height: 32 },
                  }}
                >
                  {ACTIONS_SPEED.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      sx={{
                        width: 32,
                        height: 32,
                        color: item.TipoTransmision[action.type] && blue[700],
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            ),
          };
        });
        setDataListApertura(_data);
        setTotal(response.total);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingDetail((prevState) => !prevState);
    }
  };

  const getListImage = ({ idCasilla }) => {
    setOpenPreview(true);
    getPicturesListSije({ idCasilla });
  };

  const getPicturesListSije = async ({ idCasilla }) => {
    try {
      setLoadingDataImage(true);
      const result = await sije.getPicturesListAperturaSije({
        filtered: [
          {
            id: 'sije_casillas_fotos.idCasilla',
            filter: '=',
            value: idCasilla,
          },
        ],
      });
      const { results, response, message } = result;
      if (results) {
        setDataImage(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoadingDataImage(false);
    }
  };

  const handleExportListSijeApertura = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: {},
        api: 'sije/export-list-sije-apertura',
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: 'Descargado con éxito.',
          icon: 'success',
        });
      } else {
        throw new Error('Ocurrió un error, contacte al administrador.');
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const reload = () => {
    paseListaRg();
    getCardAperturadasInstaladas();
    getRepresentantesCasillas();
    getListSijeApertura(data);
  };

  const handleModalDetailsCasilla = async ({ item }) => {
    setOpenModalCasilla(true);

    try {
      setLoadingModalCasilla(true);
      const { results, success, response, message } = await sije.getListSijeAgenda({
        ...dataAgendaCasilla,
        filtered: [
          {
            id: 'idCasilla',
            filter: '=',
            value: item.idCasilla,
          },
        ],
      });

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setItemCasilla(response.data[0]);
      } else {
        setItemCasilla({});
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoadingModalCasilla(false);
    }
  };

  const handleSearch = ({ filters }) => {
    setData({ ...data, filtered: filters });
  };

  const handleClear = () => {
    setData({ ...data, filtered: [] });
  };

  useEffect(() => {
    paseListaRg();
    getCardAperturadasInstaladas();
    getRepresentantesCasillas();
  }, []);

  useEffect(() => {
    getListSijeApertura(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={nameTab} />
        <LoadingForm
          loadinMessage={'Guardando datos...'}
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />

        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="PASE DE LISTA" value="1" />
              <Tab label="INSTALACIÓN DE CASILLAS" value="2" />
              <Tab label="APERTURA CASILLA" value="3" />
              <Tab label="DETALLE" value="4" />
            </TabList>
          </Box>
          <Container maxWidth={false}>
            <Grid2 container spacing={2} marginTop={2}>
              <Grid2 xs={12} md={4}>
                <CardRepresentative dataRC={dataRC} loading={loadingRc} />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <AttendanceCard
                  titlecontent={'RGS'}
                  data={{
                    contentData: String(dataRg.contentData),
                    leftFooterData: String(dataRg.leftFooterData),
                    rightFooterData: String(dataRg.rightFooterData),
                  }}
                  config={{
                    leftFooterTitle: 'Total',
                    rightFooterTitle: 'Pase Lista',
                  }}
                  loading={loadingRg}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <AttendanceCard
                  titlecontent={'% CASILLAS APERTURADAS'}
                  data={{
                    contentData: String(dataCasillas.contentData),
                    leftFooterData: String(dataCasillas.leftFooterData),
                    rightFooterData: String(dataCasillas.rightFooterData),
                  }}
                  config={{
                    leftFooterTitle: 'Instaladas',
                    rightFooterTitle: 'Aperturadas',
                  }}
                  loading={loadingCasilla}
                />
              </Grid2>
            </Grid2>
          </Container>
          <TabPanel value="1">
            <RollCall
              nameTab={nameTab}
              isLoadigForm={isLoadigForm}
              setIsLoadingForm={setIsLoadingForm}
              setOpenLinearProgress={setOpenLinearProgress}
              setIsSuccessFormSave={setIsSuccessFormSave}
              reload={reload}
            />
          </TabPanel>
          <TabPanel value="2">
            <Installation
              nameTab={nameTab}
              isLoadigForm={isLoadigForm}
              setIsLoadingForm={setIsLoadingForm}
              setOpenLinearProgress={setOpenLinearProgress}
              setIsSuccessFormSave={setIsSuccessFormSave}
              reload={reload}
            />
          </TabPanel>
          <TabPanel value="3">
            <Opening
              nameTab={nameTab}
              isLoadigForm={isLoadigForm}
              setIsLoadingForm={setIsLoadingForm}
              setOpenLinearProgress={setOpenLinearProgress}
              setIsSuccessFormSave={setIsSuccessFormSave}
              reload={reload}
            />
          </TabPanel>
          <TabPanel value="4">
            <Card className="card-primary">
              <CardContent>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={700}>
                    DETALLE
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload}
                    onClick={handleExportListSijeApertura}
                  >
                    Exportar
                  </Button>
                </Stack>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={'xlsx'} isDownload={isDownload} />
                  </Box>
                )}
                <Filter handleSearch={handleSearch} handleClear={handleClear} />
                <BasicTable
                  rows={dataListApertura}
                  hcolumns={COLUMNS_APERTURA}
                  total={total}
                  pageProp={data.page}
                  pageSize={data.pageSize}
                  isLoading={loadingDetail}
                  minHeight="0px"
                  stickyHeader={true}
                  handleChangePagination={handleChangePagination}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </TabPanel>
        </TabContext>

        <ModalPreviewImages
          images={dataImage}
          openModal={preview}
          loading={loadingDataImage}
          setOpenModal={setOpenPreview}
        />

        <ModalDetailsCasilla
          loading={loadingModalCasilla}
          openModal={openModalCasilla}
          setOpenModal={setOpenModalCasilla}
          data={itemCasilla}
        />
      </Container>
    </AdminLayout>
  );
};

export default StartCapture;
