import { Checkbox, Stack, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  backgroundColor: grey[100],
}));

const TableHeader = ({ data = [], selectAll, handleSelectAll, indeterminate }) => {
  return (
    <TableHead sx={{ backgroundColor: grey[100] }}>
      <TableRow>
        <TableCellStyled>
          <Stack direction={'row'} alignItems={'center'}>
            {data.length > 0 && (
              <Checkbox checked={selectAll} onChange={handleSelectAll} indeterminate={indeterminate} />
            )}
            <Typography variant="subtitle2" fontWeight={600}>
              ASISTENCIA
            </Typography>
          </Stack>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            NOMBRE
          </Typography>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            RESPONSABILIDAD
          </Typography>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            SECCIÓN
          </Typography>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            CONTACTO
          </Typography>
        </TableCellStyled>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
