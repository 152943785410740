import { useNavigate } from 'react-router-dom';

import { Box, Button, Card, CardContent, TextField, Typography, Icon } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@src/theme/index';

import logo_sian from '@assets/img/SIAN.png';

const UnsubscribedRequest = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
        sx={{
          backgroundImage: `url(${require('@assets/img/back02.png')})`,
        }}
        className="section-image"
      >
        <Card className="card-primary" sx={{ padding: '40px 20px', maxWidth: 450 }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box component={'img'} src={logo_sian} width={150} marginBottom={4} />
            <Typography variant="h6" fontWeight={600} marginBottom={1} textAlign={'center'}>
              ¿Desea dar de baja su cuenta de usuario?
            </Typography>
            <Typography variant="caption" marginBottom={3} textAlign={'center'}>
              Para solicitar la baja de su cuenta, por favor ingrese su número de teléfono celular como parte
              del proceso de verificación.
            </Typography>
            <TextField label="Celular" size="small" sx={{ width: '100%', mb: 3 }} />
            <Button
              variant="contained"
              color="primaryDark"
              sx={{ width: '100%', mb: 2 }}
              //onClick={formik.handleSubmit}
            >
              Dar de baja
            </Button>
            <Button
              color="primaryDark"
              sx={{ width: '100%', mb: 2 }}
              startIcon={<Icon>chevron_left</Icon>}
              onClick={() => navigate('/login')}
            >
              Regresar al login
            </Button>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default UnsubscribedRequest;
