import { useState, useEffect } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';
import useScrollToTop from '@hooks/useScrollToTop';

import { Box, Button, Card, CardContent, Container, Icon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import moment from 'moment';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import PersonalInformationForm from '@components/Compromisos/PersonalInformationForm';
import ContactInformationForm from '@components/Compromisos/ContactInformationForm';
import AddressForm from '@components/Compromisos/AddressForm';
import VolunteerForm from '@components/Compromisos/VolunteerForm';
import SocialForm from '@components/Compromisos/SocialForm4Capture';
import ResponsibleList from '@components/Compromisos/ResponsibleList';

import { getVars } from '@utils/global';
import CompromisoServices from '@services/CompromisoServices';
import { CompromisoAddInterface } from '@data/interfaces/CompromisoInterfaces';
import { CompromisoAddSchema } from '@data/schemas/CompromisoSchemas';
import { isEmptyOrNullObject, isNullOrUndefined } from '@utils/validations';
import { validaINE, handleAttrs } from '@utils/Utilities';
import { VIGENCIA_INE, VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL } from '@data/constants';
import { EXPECTED_ROUTES } from '@data/constants/Compromisos';

const RegisterForm = () => {
  const catalogsOptions = [
    { id: 'generos' },
    { id: 'entidades' },
    { id: 'municipios' },
    { id: 'voluntarios' },
    { id: 'tipo_vialidad' },
    { id: 'mis_segmentos' },
    { id: 'partidos' },
    { id: 'medios_contacto' },
  ];
  useScrollToTop();

  const location = useLocation();
  const navigate = useNavigate();
  const { user, menus } = getVars('Token');
  const getFindCommitment = location.state ? location.state : null;

  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [previousPage, setPreviousPage] = useState(-1);
  const [socialSegments, setSocialSegments] = useState([]);
  const [tmpLabels, setTmpLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingLabels, setLoadingLabels] = useState(true);
  const [assignedTags, setAssignedTags] = useState([]);

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  const formik = useFormik({
    initialValues: CompromisoAddInterface,
    validationSchema: CompromisoAddSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });

  useEffect(() => {
    formik.setFieldValue('idUsuarioPropietario', user.id, false);

    const _menus = menus
      .map((item) => item.submenus)
      .flat()
      .filter((item) => EXPECTED_ROUTES.includes(item.Ruta));

    if (_menus.length < 2) {
      if (!isEmptyOrNullObject(getFindCommitment)) {
        setPreviousPage('/historial-captura');
      } else {
        setPreviousPage('/compromisos');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      if (catalogs.mis_segmentos && Array.isArray(catalogs.mis_segmentos)) {
        setTmpLabels(catalogs.mis_segmentos);
      }
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (!isEmptyOrNullObject(getFindCommitment) && catalogsFiltered.tipo_vialidad.length > 0) {
      let vialidad = getFindCommitment.CalleVive.split(' ');
      let CalleVive = vialidad.slice(1, vialidad.length);
      let found = catalogsFiltered.tipo_vialidad.find((item) => item.value === vialidad[0]);
      if (getFindCommitment.Etiquetas) {
        setAssignedTags(getFindCommitment.Etiquetas);
        setSocialSegments(getFindCommitment.Etiquetas);
        delete getFindCommitment.Etiquetas;
      }

      let formatedData = {
        ...getFindCommitment,
        FechaNacimiento: moment(getFindCommitment.FechaNacimiento, 'YYYY-MM-DD'),
        CalleVive: CalleVive.length > 0 ? CalleVive.join(' ') : getFindCommitment.CalleVive,
        TipoVialidad: found ? vialidad[0] : '',
        Segmentos: getFindCommitment.Segmentos
          ? getFindCommitment.Segmentos.map((item) => parseInt(item))
          : [],
        idVoluntario: getFindCommitment.idVoluntario ? getFindCommitment.idVoluntario : '',
        idMedioContacto: getFindCommitment.idMedioContacto ? getFindCommitment.idMedioContacto : '',
        TieneAuto: !isNullOrUndefined(getFindCommitment.TieneAuto) ? getFindCommitment.TieneAuto : '',
        TieneExperiencia: !isNullOrUndefined(getFindCommitment.TieneExperiencia)
          ? getFindCommitment.TieneExperiencia
          : '',
        AceptaCuidarOtra: !isNullOrUndefined(getFindCommitment.AceptaCuidarOtra)
          ? getFindCommitment.AceptaCuidarOtra
          : '',
      };
      formik.setValues(formatedData);
    }
    // eslint-disable-next-line
  }, [getFindCommitment, catalogsFiltered.tipo_vialidad]);

  useEffect(() => {
    if (formik.values.idUsuarioPropietario) {
      getUserSegments(formik.values.idUsuarioPropietario);
    } else if (formik.values.idUsuarioResponsable) {
      getUserSegments(formik.values.idUsuarioResponsable);
    } else {
      getUserSegments(user.id);
    }
    // eslint-disable-next-line
  }, [formik.values.idUsuarioPropietario, formik.values.idUsuarioResponsable]);

  const getUserSegments = (id) => {
    if (user.id === id) {
      setTmpLabels(catalogs.mis_segmentos);
      return;
    }

    const params = { idUsuario: id };
    setLoadingLabels(true);
    CompromisoServices.getUserSegments(params)
      .then((res) => {
        if (res.results) {
          setTmpLabels(res.response.data);
        } else {
          setTmpLabels([]);
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoadingLabels(false);
      });
  };

  const changeOwner = (segments, message) => {
    Swal.fire({
      title: '¡Advertencia!',
      icon: 'warning',
      text: message,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Continuar!',
      cancelButtonText: 'No, Cancelar',
    }).then((res) => {
      if (res.isConfirmed) {
        setLabels(segments);
      } else {
        setLabels(catalogs.mis_segmentos);
        formik.setValues({
          ...formik.values,
          idCompromisoPropietario: null,
          idUsuarioResponsable: null,
          idUsuarioPropietario: getFindCommitment.idUsuarioPropietario,
        });
      }
    });
  };

  useEffect(() => {
    let warning_message = '';

    if (!isEmptyOrNullObject(getFindCommitment)) {
      //EDICION DE REGISTRO

      handleSetUserSegments().then((res) => {
        const { segments } = res;

        if (segments) {
          const len_segments = segments.length;
          let items = [];
          let not_found = [];

          switch (len_segments) {
            case 0:
              if (assignedTags.length > 0) {
                warning_message = `La(s) siguiente(s) etiqueta(s) se perderán ( ${assignedTags
                  .map((x) => x.label)
                  .join(', ')} ) ya que no corresponden al responsable seleccionado`;
                changeOwner([], warning_message);
              } else {
                setLabels(segments);
              }
              break;

            case 1:
              if (assignedTags.length > 0) {
                items = tmpLabels.map((item) => parseInt(item.value));
                not_found = assignedTags.filter((item) => !items.includes(parseInt(item.value)));
                warning_message = `La(s) siguiente(s) etiqueta(s) se perderán ( ${not_found
                  .map((x) => x.label)
                  .join(', ')} ) ya que no corresponden al responsable seleccionado`;
                if (not_found.length > 0) {
                  changeOwner(segments, warning_message);
                } else {
                  setLabels(segments);
                }
              } else {
                setLabels(segments);
              }
              break;

            default:
              if (getFindCommitment.idUsuarioPropietario === user.id) {
                setLabels(catalogs.mis_segmentos);
              } else {
                if (assignedTags.length > 0) {
                  items = tmpLabels.map((item) => parseInt(item.value));
                  not_found = assignedTags.filter((item) => !items.includes(parseInt(item.value)));
                  warning_message = `La(s) siguiente(s) etiqueta(s) se perderán ( ${not_found
                    .map((x) => x.label)
                    .join(', ')} ) ya que no corresponden al responsable seleccionado`;
                  if (not_found.length > 0) {
                    changeOwner(segments, warning_message);
                  } else {
                    setLabels(segments);
                  }
                } else {
                  setLabels(segments);
                }
              }

              break;
          }
        }
      });
    } else {
      //REGISTRO NUEVO
      handleSetUserSegments().then((res) => {
        const { segments } = res;
        if (segments) {
          setLabels(segments);
        }
      });
    }
    // eslint-disable-next-line
  }, [tmpLabels]);

  const handleSetUserSegments = () => {
    return new Promise((resolve, reject) => {
      try {
        if (formik.values.idUsuarioPropietario) {
          if (formik.values.idUsuarioPropietario === user.id) {
            // console.log('Usuario LOGUEADO')
            resolve({
              segments: catalogs.mis_segmentos,
            });
          } else {
            // console.log('idUsuarioPropietario')
            resolve({
              segments: tmpLabels,
            });
          }
        } else if (formik.values.idUsuarioResponsable) {
          if (formik.values.idUsuarioResponsable === user.id) {
            // console.log('Usuario LOGUEADO RESPONSABLE')
            resolve({
              segments: catalogs.mis_segmentos,
            });
          } else {
            // console.log('idUsuarioResponsable')
            resolve({
              segments: tmpLabels,
            });
          }
        } else {
          // console.log('Usuario LOGUEADO DEFAULT')
          resolve({
            segments: catalogs.mis_segmentos,
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(values.idVoluntario) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: 'Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso',
            icon: 'info',
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              socialValidation(values);
            }
          });
        } else {
          socialValidation(values);
        }
      }
    }
  };

  const socialValidation = (values) => {
    if (socialSegments.length > 0 && labels.length > 1 && values.idUsuarioResponsable) {
      let message = `La(s) etiqueta(s) seleccionadas únicamente se registrarán al responsable del 1xN`;
      Swal.fire({
        title: '¡Advertencia!',
        icon: 'info',
        text: message,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Continuar!',
        cancelButtonText: 'No, Cancelar',
      }).then((res) => {
        if (res.isConfirmed) {
          handleSave(values);
        }
      });
    } else {
      handleSave(values);
    }
  };

  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
    Object.keys(CompromisoAddInterface).forEach((key) => {
      data[key] = values[key];
    });

    if (getFindCommitment) {
      data = {
        ...data,
        idCompromisoUnico: getFindCommitment.idCompromisoUnico,
      };
    }

    const segments_ids = socialSegments.map((x) => parseInt(x.value));
    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format('YYYY-MM-DD'),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
      Segmentos: labels.length > 0 ? segments_ids : [],
    };
    delete data.TipoVialidad;

    if (!isEmptyOrNullObject(getFindCommitment)) {
      data = { id: getFindCommitment.id, ...data };

      CompromisoServices.updateCommitments(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                navigate('/historial-captura');
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    } else {
      let EsNuevo = !isNullOrUndefined(getFindCommitment) ? getFindCommitment : 0;
      data = { ...data, EsNuevo };

      CompromisoServices.createCompromiso(data)
        .then((res) => {
          if (res.success && res.results) {
            setFinished(true);
            Swal.fire({
              title: res.message,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                if (!isNullOrUndefined(getFindCommitment)) navigate('/pase-lista');
              }
            });
          } else {
            setFinished(false);
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          setFinished(false);
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    }
  };

  const clear = () => {
    setFinished(false);
    formik.setValues({
      ...formik.values,
      idVoluntario: '',
      INE: '',
      VigenciaINE: '',
      Nombre: '',
      Paterno: '',
      Materno: '',
      FechaNacimiento: '',
      idSexo: '',
      idEstadoNacimiento: '',
      CalleVive: '',
      TipoVialidad: 'C',
      NumExtVive: '',
      NumIntVive: '',
      idMunicipioVive: '',
      idLocalidadVive: '',
      ColoniaVive: '',
      CPVive: '',
      SeccionVota: '',
      Celular: '',
      Telefono: '',
      TelMensajes: '',
      Correo: '',
      Facebook: '',
      Instagram: '',
      Twitter: '',
      TikTok: '',
      EsVoluntario: 1,
      idPartido: '',
      Segmentos: [],
      idMedioContacto: '',
      TieneAuto: '',
      TieneExperiencia: '',
      AceptaCuidarOtra: '',
    });
  };

  const showCaptureModal = () => {
    console.log('=>Abrir modal');
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Captura de compromisos"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(previousPage)}
        />
        <Card className="card-primary">
          <CardContent>
            <LoadingForm
              loadinMessage={'Cargando datos...'}
              successMessage="¡Cargado con exito!"
              isLoading={isLoadigForm}
              success={isSuccessFormSave}
              isOpen={openLinearProgress}
              setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            />

            <ResponsibleList
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              finished={finished}
            />

            <PersonalInformationForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              showCaptureModal={showCaptureModal}
              setLoading={(e) => {
                setIsLoadingForm(e);
                setOpenLinearProgress(e);
              }}
            />

            <ContactInformationForm
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              handleChange={formik.handleChange}
            />

            <AddressForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
            />

            <VolunteerForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
            />

            {labels && labels.length > 0 && (
              <SocialForm
                action={formik.values?.id ? 'edit' : 'add'}
                options={labels}
                loadigCatalogs={loadingLabels}
                assignedTags={assignedTags}
                setValues={setSocialSegments}
              />
            )}

            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="button"
                  color="primaryDark"
                  variant="contained"
                  size="small"
                  onClick={formik.submitForm}
                  startIcon={<Icon>save</Icon>}
                >
                  Guardar
                </Button>
              </Box>
            </Grid2>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default RegisterForm;
