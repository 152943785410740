import { Badge, ListItemText, Stack, Typography } from '@mui/material';

const BadgeLabel = ({ total, label, color }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Badge
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: color,
          },
        }}
      />
      <ListItemText
        primary=<Typography variant="body2">
          <strong>{total}</strong> {label}
        </Typography>
      />
    </Stack>
  );
};

export default BadgeLabel;
