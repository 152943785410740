export const registrarInterface = {
  NombreCompleto: '',
  idResponsabilidad: '',
  Ubicacion: '',
  imageName: '',
  files: [
    {
      file: '',
    },
  ],
};
