import { useState, useEffect } from 'react';

// Material UI
import { Box } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomModal from '@components/Modal/CustomModal';
import CustomTabs from '@components/Tabs/CustomTabs';
import CardEvent from '@components/Events/CardEvent';
import QrReader from '@components/Containers/QrReader';
import PreguntasRespuestasEventos from '@components/Events/PreguntasRespuestasEventos';

// Servicios
import EventsServices from '@services/EventsServices';
import { getVars } from '@utils/global';

// Utilidades
import { isNullOrUndefined } from '@utils/validations';
import { Swal, SwalImage } from '@utils/alerts';

const EventsAttendance = () => {
  const user = getVars('Token', {})?.user;

  const columns = [
    { id: 'Actividad', label: 'Evento', type: 'text' },
    { id: 'TipoActividad', label: 'Tipo', type: 'text' },
    { id: 'FechaInicio', label: 'Fecha', align: 'center' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [rows, setRows] = useState([]);

  const [event, setEvent] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [questions, setQuestions] = useState([]);

  const [tab, setTab] = useState('0');

  const [qr, setQR] = useState('');

  useEffect(() => {
    if (user?.id) {
      getEventsUsers(user?.id, tab);
    } // eslint-disable-next-line
  }, [tab]);

  const getEventsUsers = async (id, tab) => {
    let API = EventsServices.getEventsUsers;
    if (tab === '1') API = EventsServices.getEventsUsersInvited;

    try {
      setRows([]);
      setIsLoading(true);

      const params = {
        page: 0,
        pageSize: 9999,
        filtered: [{ id: 'usuarios.id', filter: '=', value: id }],
        sorted: [{ id: 'eventos.FechaInicio', value: 'desc' }],
      };

      const result = await API(params);
      const { success, results, response, message } = await result;

      if (results)
        setRows(
          response.data.map((item) => ({
            ...item,
            Detalle: 'Ver detalles',
          }))
        );
      else setRows([]);
      if (!success) throw new Error(message);
    } catch (error) {
      setRows([]);
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const setAttendance = async (UUID) => {
    try {
      setQuestions([]);
      setEvent({});
      setOpenModal(false);

      setIsLoadingForm(true);

      const result = await EventsServices.setAttendance({ UUID });
      const { results, response, message } = await result;

      if (results) {
        if (response?.data?.length) {
          setQuestions(response.data);
          setEvent({ ...response.event, UUID });
          setOpenModal(true);
        } else {
          setQR(UUID);
          getEventsUsers(user?.id, tab);
          Swal.fire({ title: message, icon: 'success' });
        }
      } else throw new Error(message);
    } catch (e) {
      setQR('');
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
    }
  };

  const setAttendanceWithAnswers = async (answers) => {
    try {
      setIsLoadingForm(true);

      const result = await EventsServices.setAttendance({ answers, UUID: event.UUID });
      const { results, message } = await result;

      if (results) {
        setQR(event.UUID);
        getEventsUsers(user?.id, tab);
        Swal.fire({ title: message, icon: 'success' });
        setQuestions([]);
        setEvent({});
        setOpenModal(false);
      } else throw new Error(message);
    } catch (e) {
      setQR('');
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
    }
  };

  const handleVerDetalle = (a, b, c) => {
    setOpenModal(true);
    setEvent(c);
  };

  const questionFlag = questions.length > 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <LoadingForm
        isLoading={isLoadingForm}
        isOpen={isLoadingForm}
        setIsOpen={() => {}}
        loadinMessage={'Registrando...'}
      />

      <QrReader setResult={setAttendance} result={qr} />

      <CustomTabs
        value={tab}
        setValue={setTab}
        tabs={[
          {
            value: '0',
            icon: 'event_available',
            label: 'Asistido',
            component: (
              <CustomTable
                rows={rows ?? []}
                columns={columns.concat([{ id: 'FechaAsistio', label: 'Asistencia', align: 'center' }])}
                stickyHeader
                maxHeight={230}
                disableCardType
                isLoading={isLoading}
              />
            ),
          },
          {
            value: '1',
            icon: 'edit_calendar',
            label: 'Invitado',
            component: (
              <CustomTable
                rows={rows ?? []}
                columns={columns.concat([
                  {
                    id: 'Detalle',
                    label: 'Detalles',
                    type: 'button',
                    onClick: handleVerDetalle,
                    sort: false,
                  },
                ])}
                stickyHeader
                maxHeight={230}
                disableCardType
                isLoading={isLoading}
              />
            ),
          },
        ]}
      />

      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        disableClose={questionFlag}
        fullWidth
        image={questionFlag ? {} : { src: event.imagen, title: event.Actividad }}
        displayImage={questionFlag ? false : !isNullOrUndefined(event.imagen)}
        buttons={
          questionFlag
            ? []
            : [
                {
                  icon: 'image',
                  title: 'Abrir imagen',
                  action: () => SwalImage({ image: event.imagen, title: event.Actividad }),
                },
              ]
        }
      >
        {questionFlag ? (
          <PreguntasRespuestasEventos
            titulo={event.Actividad}
            subtitulo="asistencia"
            preguntas={questions}
            handleChange={setAttendanceWithAnswers}
          />
        ) : (
          <CardEvent post={event} />
        )}
      </CustomModal>
    </Box>
  );
};

export default EventsAttendance;
