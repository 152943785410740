import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  LinearProgress,
  TableFooter,
  Typography,
  Stack,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import InputSelect from '@components/Selects/BasicSelect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Swal from 'sweetalert2';

const TableMovilización = (props) => {
  const {
    colums = [],
    count = 0,
    rows = [],
    showPagination = false,
    pageProp,
    pageSize,
    onDeselectAll,
    onDeselectOne,
    onPageChange,
    hasMore,
    onSelectAll,
    onSelectOne,
    isLoading,
    config = {
      height: 500,
      endMessage: 'Lista Completa',
    },
    selected = [],
    onChange = () => {},
    handleChangePagination = () => {},
    optionComent = null,
    dataOnchange = [],
    load = false,
    headerBackgroud = '#94C2E5',
    headerLetter = '000000',
    loadingUpdate,
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const selectedSome = selected.length > 0 && selected.length < rows.length;
  const selectedAll = rows.length > 0 && selected.length === rows.length;
  const [flagOnchange, setFlagOnchange] = useState([]);
  const [flagLoading, setFlagLoading] = useState([]);
  const [actualizados, setActualizados] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(pageProp);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: headerBackgroud,
      color: headerLetter,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    setData(rows);
    setShowedItems(rows ? rows.length : 0);
  }, [rows]);

  useEffect(() => {}, [flagOnchange]);
  useEffect(() => {
    let validate = flagLoading.filter((item) => item !== loadingUpdate.id);
    let updateValidate = actualizados;
    delete updateValidate[loadingUpdate.id];
    let validateOnchange = flagOnchange.filter((item) => item !== loadingUpdate.id);
    setActualizados(updateValidate);
    setFlagLoading(validate);
    setFlagOnchange(validateOnchange);
  }, [loadingUpdate]);

  const activateButton = (item, estatus) => {
    const updatedRows = [...rows];

    const index = updatedRows.findIndex((row) => row.id === item.id);

    if (index !== -1) {
      updatedRows[index] = { ...updatedRows[index], valueOnchange: estatus };

      setActualizados({ ...actualizados, [updatedRows[index].id]: estatus });
      const updatedData = [...data];
      updatedData[index] = { ...updatedData[index], valueOnchange: estatus };
      setData(updatedData);
      setFlagOnchange([...flagOnchange, item.id]);
    }
  };

  const infoValue = (row) => {
    let validation = Object.keys(actualizados).find((item) => parseInt(item) === row.id);
    if (validation) {
      return actualizados[row.id];
    }
    return row.valueOnchange;
  };

  const UpdateChange = (row) => {
    let dataSend = actualizados[row.id];
    setFlagLoading([...flagLoading, row.id]);
    if (optionComent === dataSend) {
      Swal.fire({
        title: 'Comentario',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Guardar',
        showLoaderOnConfirm: true,
        preConfirm: (comment) => {
          let send = {
            ...row,
            valueOnchange: dataSend,
            Comentario: comment,
          };
          onChange(send);
        },
      });
    } else {
      let send = {
        ...row,
        valueOnchange: dataSend,
        Comentario: '',
      };
      onChange(send);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    setPage(0);
    handleChangePagination({ page: 0, pageSize });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleChangePagination({ page: newPage, pageSize: rowsPerPage });
  };

  const TablaContent = (rows) => {
    return (
      <TableContainer sx={{ borderRadius: '6px' }}>
        <Table>
          <TableBody>
            {rows.map((row) => {
              const isSelected = selected.includes(row.id);

              return (
                <StyledTableRow hover key={row.id} selected={isSelected}>
                  {colums.map((item) => {
                    return item.isCheck ? (
                      <StyledTableCell padding="checkbox" style={{ width: item.width }} align={item.align}>
                        <Checkbox
                          checked={isSelected}
                          onChange={(event) => {
                            if (event.target.checked) {
                              onSelectOne?.(row.id);
                            } else {
                              onDeselectOne?.(row.id);
                            }
                          }}
                        />
                      </StyledTableCell>
                    ) : item.isOnChange ? (
                      <StyledTableCell padding="checkbox" style={{ width: item.width }} align={item.align}>
                        <Grid2 container>
                          <Grid2 item xs={12} sm={12} md={10} lg={10}>
                            <InputSelect
                              label="Estatus"
                              options={dataOnchange}
                              name="Estatus"
                              value={infoValue(row)}
                              onChange={(e) => activateButton(row, e.target.value)}
                              sx={{ width: '100%' }}
                              isLoading={load}
                            />
                          </Grid2>
                          {flagOnchange.includes(row.id) && (
                            <Grid2 item xs={2} sm={2} md={2} lg={2}>
                              {flagLoading.includes(row.id) ? (
                                <CircularProgress size={30} color="secondary" />
                              ) : (
                                <Tooltip title="Guardar cambio" placement="right-start">
                                  <IconButton
                                    aria-label="fingerprint"
                                    color="secondary"
                                    onClick={() => UpdateChange(row)}
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid2>
                          )}
                        </Grid2>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell style={{ width: item.width }} align={item.align}>
                        {row[item.id]}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TableContainer sx={{ flexGrow: 1, borderRadius: '6px', overflow: 'auto' }} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow>
              {colums.map((colum) => {
                return colum.isCheck ? (
                  <StyledTableCell padding="checkbox" style={{ width: colum.width }} align={colum.align}>
                    <Checkbox
                      checked={selectedAll}
                      indeterminate={selectedSome}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onSelectAll?.();
                        } else {
                          onDeselectAll?.();
                        }
                      }}
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell style={{ width: colum.width }} align={colum.align}>
                    {colum.label}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <div style={{ flexGrow: 1, overflow: 'auto', position: 'relative' }}>
        {!showPagination && (
          <InfiniteScroll
            dataLength={showedItems}
            next={onPageChange}
            hasMore={hasMore}
            loader={<LinearProgress color="primary" />}
            height={config.height}
            width="100%"
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>
                  {config.endMessage !== undefined ? config.endMessage : '¡Final de la lista de registros!'}
                </b>
              </p>
            }
          >
            {TablaContent(rows)}
          </InfiniteScroll>
        )}
        {showPagination && isLoading && <LinearProgress color="primary" />}
        {showPagination && !isLoading && TablaContent(rows)}
      </div>
      {showPagination ? (
        <TablePagination
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <TableFooter style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" color={'#0D314B'}>
              {rows.length}
            </Typography>
            <Typography color={'#0D314B'}>de</Typography>
            <Typography variant="subtitle2" color={'#0D314B'}>
              {count}
            </Typography>
          </Stack>
        </TableFooter>
      )}
    </div>
  );
};

TableMovilización.propTypes = {
  count: PropTypes.number,
  rows: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
};

export default TableMovilización;
