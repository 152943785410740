import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numberWithCommas } from '@utils/Utilities';
import { URL_IMAGES } from '@utils/global';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

//HC_exporting(Highcharts);

const ChartColumnComparison = ({ Series, Title, Subtitle, tipoVista, percent, openDrawer }) => {
  const mapData = (data) => {
    return data.map(function (item, i) {
      return {
        name: item.name,
        y: item.p,
        label: numberWithCommas(item.y),
        color: item.color,
      };
    });
  };

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: Title,
    },
    subtitle: {
      text: Subtitle,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat: `<span style="font-size: 15px">{point.point.name}</span><br/>`,
      pointFormat: `<span style="color:{point.color}">\u25CF</span> <b>{point.label}</b><br/>`,
    },
    xAxis: {
      type: 'category',
      categories: Series,
      labels: {
        useHTML: true,
        animate: true,
        formatter: function () {
          let output = '<div style="font-size: 0.75em; text-align: center;">';
          if (this.value.logo !== null || this.value.logo !== '') {
            output += `<br><img src="${URL_IMAGES}/${this.value.logo}" style="width: 35px; height: 35px;"/><br>`;
          }
          if (tipoVista === 1) {
            output += `<span style="color: black;">${this.value.name}</span></div>`;
          } else {
            if (this.value.logo === 'partidos/nada.png') {
              output += `<span style="color: black;">${this.value.name}</span></div>`;
            } else {
              output += `<span style="color: white;">--------</span></div>`;
            }
          }

          return output;
        },
      },
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        dataLabels: [
          {
            enabled: true,
            inside: false,
            format: `{point.y}${percent ? '%' : ''}`,
            style: {
              fontSize: '1.2em',
            },
          },
        ],
        data: mapData(Series),
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} type="column" key={openDrawer} />;
};

export default ChartColumnComparison;
