import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useCatalogs } from '@hooks/useCatalogs';

import { Box, Stack, TextField, Button, FormControl, FormHelperText } from '@mui/material';
import { Cancel, Save } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Swal from 'sweetalert2';

import LoadingForm from '@components/LinearProgress/LoadingForm';
import UploadProfilePicture from '@components/Files/UploadProfilePicture';
import ColorPicker from '@components/Prep/ColorPicker';
import InputSelect from '@components/Selects/BasicSelect';

import {
  ELECCION_DIPUTADO_FEDERAL,
  ELECCION_DIPUTADO_LOCAL,
  ELECCION_PRESIDENTE_MUNICIPAL,
} from '@data/constants/Prep';
import { AddCandidateInterface } from '@data/interfaces/PrepInterface';
import { AddCandidateSchema } from '@data/schemas/PrepSchema';
import { URL_IMAGES } from '@utils/global';
import { isEmptyOrInvalidString } from '@utils/validations';
import { convertBase64 } from '@utils/Utilities';
import prep from '@services/PrepServices';

const CandidateForm = (props) => {
  const { data4Edit } = props;
  const initColor = '#b71c1c';
  const catalogsOptions = [
    { id: 'dl' },
    { id: 'df' },
    { id: 'municipios' },
    { id: 'tipo_eleccion' },
    { id: 'partidos', filtered: [{ id: 'id', filter: '<>', value: 9999 }] },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const formik = useFormik({
    initialValues: AddCandidateInterface,
    validationSchema: AddCandidateSchema,
    onSubmit: async (values, actions) => await handleSave(values, actions),
  });

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);
  const [catScope, setCatScope] = useState([]);
  const [labelScope, setLabelScope] = useState([]);
  const [color, setColor] = useState(initColor);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (!load) {
      handleClearForm();
    }
    // eslint-disable-next-line
  }, [data4Edit, load]);

  useEffect(() => {
    formik.setFieldValue('Color', color, false);
    // eslint-disable-next-line
  }, [color]);

  const handleChangeElectionType = (value) => {
    formik.setValues((prevState) => ({
      ...prevState,
      idTipoEleccion: value,
      AmbitoValor: '',
    }));

    switch (value) {
      case ELECCION_DIPUTADO_FEDERAL:
        setCatScope(catalogs.df);
        setLabelScope('Distrito Federal');
        break;
      case ELECCION_DIPUTADO_LOCAL:
        setCatScope(catalogs.dl);
        setLabelScope('Distrito Local');
        break;
      case ELECCION_PRESIDENTE_MUNICIPAL:
        setCatScope(catalogs.municipios);
        setLabelScope('Municipio');
        break;
      default:
        setCatScope([]);
        setLabelScope('');
        break;
    }
  };

  const handleSave = async (values, actions) => {
    let files = [];
    if (photo instanceof File) {
      files = [
        {
          file: await convertBase64(photo),
        },
      ];
    }

    let params = {
      idTipoEleccion: values.idTipoEleccion,
      idPartido: values.idPartido,
      Color: values.Color,
      AmbitoValor: values.AmbitoValor,
      NombreCandidato: values.NombreCandidato.toUpperCase().trim(),
      files: files,
    };

    if (values.idCandidato) {
      updateCandidate({ ...params, id: values.idCandidato }, actions);
    } else {
      saveCandidate(params, actions);
    }
  };

  const updateCandidate = (params, actions) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    prep
      .updateCandidate(params)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const saveCandidate = (params, actions) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    prep
      .saveCandidate(params)
      .then((res) => {
        if (res.results) {
          Swal.fire({
            title: res.message,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((r) => {
            actions.resetForm();
            setPhoto(null);
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleClearForm = () => {
    if (data4Edit) {
      formik.setValues(data4Edit);

      if (data4Edit.Color) {
        setColor(data4Edit.Color);
      }
      if (data4Edit.Logo) {
        setPhoto(`${URL_IMAGES}/${data4Edit.Logo}`);
      }

      switch (data4Edit.idTipoEleccion) {
        case ELECCION_DIPUTADO_FEDERAL:
          setCatScope(catalogs.df);
          setLabelScope('Distrito Federal');
          break;
        case ELECCION_DIPUTADO_LOCAL:
          setCatScope(catalogs.dl);
          setLabelScope('Distrito Local');
          break;
        case ELECCION_PRESIDENTE_MUNICIPAL:
          setCatScope(catalogs.municipios);
          setLabelScope('Municipio');
          break;
        default:
          setCatScope([]);
          setLabelScope('');
          break;
      }
    } else {
      setColor(initColor);
      formik.setValues({
        idTipoEleccion: '',
        idPartido: '',
        NombreCandidato: '',
        Color: initColor,
        AmbitoValor: '',
      });
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <LoadingForm
        loadinMessage={'Enviando'}
        successMessage="¡Consultado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />

      <Grid2 container spacing={2}>
        <Grid2 item xs={12} sm={12} md={6}>
          <Stack spacing={2} direction={'column'} justifyContent={'center'} sx={{ overflow: 'scroll', p: 2 }}>
            <UploadProfilePicture file={photo} setFile={setPhoto} accept="image/*" />

            <FormControl>
              <InputSelect
                label="Tipo de elección"
                error={formik.touched.idTipoEleccion && !isEmptyOrInvalidString(formik.errors.idTipoEleccion)}
                errorMessage={formik.errors.TipoVialidad}
                name="idTipoEleccion"
                options={catalogs.tipo_eleccion}
                value={formik.values.idTipoEleccion}
                onChange={(e) => handleChangeElectionType(e.target.value)}
                sx={{ width: '100%' }}
                isLoading={load}
              />
              <FormHelperText error>{formik.errors.idTipoEleccion}</FormHelperText>
            </FormControl>

            <FormControl>
              <InputSelect
                label="Partido"
                error={formik.touched.idPartido && !isEmptyOrInvalidString(formik.errors.idPartido)}
                errorMessage={formik.errors.TipoVialidad}
                name="idPartido"
                options={catalogs.partidos}
                value={formik.values.idPartido}
                onChange={formik.handleChange}
                sx={{ width: '100%' }}
                isLoading={load}
              />
              <FormHelperText error>{formik.errors.idPartido}</FormHelperText>
            </FormControl>

            {catScope && catScope.length > 0 && (
              <FormControl>
                <InputSelect
                  label={labelScope}
                  error={formik.touched.AmbitoValor && !isEmptyOrInvalidString(formik.errors.AmbitoValor)}
                  errorMessage={formik.errors.TipoVialidad}
                  name="AmbitoValor"
                  options={catScope}
                  value={formik.values.AmbitoValor}
                  onChange={formik.handleChange}
                  sx={{ width: '100%' }}
                  isLoading={load}
                />
                <FormHelperText error>{formik.errors.AmbitoValor}</FormHelperText>
              </FormControl>
            )}

            <FormControl>
              <TextField
                label="Nombre del Candidato"
                id="NombreCandidato"
                name="NombreCandidato"
                // size="small"
                value={
                  formik.values.NombreCandidato &&
                  formik.values.NombreCandidato.length > 0 &&
                  formik.values.NombreCandidato.toUpperCase()
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.NombreCandidato &&
                  (!isEmptyOrInvalidString(formik.errors.NombreCandidato) ||
                    formik.values.NombreCandidato === '')
                }
                sx={{ width: '100%' }}
              />
              <FormHelperText error>{formik.errors.NombreCandidato}</FormHelperText>
            </FormControl>
          </Stack>
        </Grid2>

        <Grid2 item xs={12} sm={12} md={6}>
          <ColorPicker
            initColor={color}
            getColor={setColor}
            title={'Establezca un color para el candidato'}
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            color="error"
            variant="contained"
            size="small"
            onClick={(e) => handleClearForm()}
            startIcon={<Cancel />}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            color="primaryDark"
            variant="contained"
            size="small"
            onClick={formik.submitForm}
            startIcon={<Save />}
          >
            Guardar
          </Button>
        </Box>
      </Grid2>
    </Box>
  );
};

export default CandidateForm;
