/**
 * Interfaces
 */

const UserRoleAddInterface = {
  id: null,
  Perfil: '',
  Descripcion: null,
  idTipo: '',
  idAmbitoCargo: '',
  EtiquetaCargo: '',
};

const UserRoleInfo = {
  id: null,
  Descripcion: '',
  Estatus: '',
  FechaActualizo: '',
  FechaCreo: '',
  FechaElimino: null,
  Menus: [],
  Perfil: '',
  UsuarioActualizo: '',
  UsuarioCreo: '',
  UsuarioElimino: '',
  idEstatus: 1,
  idUsuarioActualizo: null,
  idUsuarioCreo: null,
  idUsuarioElimino: null,
};

const AgregarPerfilInterface = {
  TipoPerfil: '',
  AmbitoCargo: [],
  NombrePerfil: '',
  EtiquetaCargo: '',
  Descripcion: '',
  PerfilHijo: [],
  PerfilPadre: [],
};

export { UserRoleAddInterface, UserRoleInfo, AgregarPerfilInterface };
