import Yup from '@utils/Yupi18n';
import moment from 'moment';

const pattern = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/g;

const UserAddSchemaV2 = Yup.object({
  password: Yup.string().required().min(6).label('Contraseña'),
  Confirm: Yup.string()
    .required()
    .oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden')
    .label('Confirmar contraseña'),
  idVoluntario: Yup.number()
    .when('isVoluntario', {
      is: 1,
      then: Yup.number().required(),
      otherwise: Yup.number().optional().nullable(true),
    })
    .label('Responsabilidad'),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, 'Ingrese una Clave de Elector válida')
    .required()
    .label('Clave de Elector'),
  VigenciaINE: Yup.number()
    .required()
    .min(Number(moment().format('YYYY')), 'la Vigencia no debe ser menor al año actual')
    .label('Vigencia INE')
    .typeError('Ingrese una Vigencia INE válida'),
  Nombre: Yup.string().required().min(3).label('Nombre'),
  Paterno: Yup.string().required().min(2).label('Apellido Paterno'),
  Materno: Yup.string().required().min(2).label('Apellido Materno'),
  FechaNacimiento: Yup.date()
    .required()
    .label('Fecha de nacimiento')
    .typeError('Ingrese una Clave de Elector válida'),
  idSexo: Yup.number().required().label('Sexo').typeError('Ingrese una Clave de Elector válida'),
  idEstadoNacimiento: Yup.number()
    .required()
    .label('Estado de nacimiento')
    .typeError('Ingrese una Clave de Elector válida'),
  CalleVive: Yup.string().required().min(3).label('Calle donde vive'),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label('Municipio'),
  idLocalidadVive: Yup.number().required().label('Localidad'),
  ColoniaVive: Yup.string().required().min(3).label('Colonia'),
  CPVive: Yup.number().optional().nullable(true),
  SeccionVota: Yup.number().required().min(1).max(3180).label('Sección Vota'),
  Celular: Yup.string().required().min(10).max(10),
  Telefono: Yup.string().optional().nullable(true).min(10).max(10),
  TelMensajes: Yup.string().optional().nullable(true).min(10).max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  EsVoluntario: Yup.number().required(),
});

const UserAddSchema = Yup.object({
  email: Yup.string().email().required().label('Correo Electrónico'),
  password: Yup.string().required().min(6).label('Contraseña'),
  confirm: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    .label('Confirmar contraseña'),
  Nombre: Yup.string().required(),
  Paterno: Yup.string().required(),
  Materno: Yup.string().required(),
  Celular: Yup.string().required().length(10),
  idDependencia: Yup.number().required().label('Dependencia'),
  idPerfil: Yup.number().required().label('Perfil'),
  idCentroGto: Yup.number().required().label('Centro Gto Contigo Sí'),
  Cargo: Yup.string().required().label('Cargo'),
  DefaultPage: Yup.string().required(),
  Telefono: Yup.string().optional(),
  Extension: Yup.string().optional(),
});

const UserUpdateSchema = Yup.object({
  id: Yup.number().required().label('Usuario'),
  email: Yup.string().email().required().label('Correo Electrónico'),
  Nombre: Yup.string().required(),
  Paterno: Yup.string().required(),
  Materno: Yup.string().required(),
  Celular: Yup.string().required().length(10),
  idDependencia: Yup.number().required().label('Dependencia'),
  idPerfil: Yup.number().required().label('Perfil'),
  idCentroGto: Yup.number().required().label('Centro Gto Contigo Sí'),
  Cargo: Yup.string().required().label('Cargo'),
  DefaultPage: Yup.string().required(),
  Telefono: Yup.string().optional(),
});

const UserUpdateProfileSchema = Yup.object({
  id: Yup.number().required().label('Usuario'),
  Nombre: Yup.string().required(),
  Paterno: Yup.string().required(),
  Materno: Yup.string().required(),
  Celular: Yup.string().required().length(10),
  Correo: Yup.string().email().required().label('Correo Electrónico'),
  idFotoPerfil: Yup.number().required().label('Foto de Perfil'),
});

const UserPasswordSchema = Yup.object({
  Password: Yup.string()
    .password(
      'La contraseña debe contener mínimo 8 carácteres, una letra mayúscula, número y símbolo especial'
    )
    .required()
    .min(8)
    .label('Contraseña'),
  Confirm: Yup.string()
    .required()
    .oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden')
    .label('Confirmar contraseña'),
});

const UserPinSchema = Yup.object({
  PIN: Yup.string().required().length(5).label('Pin'),
  Confirm: Yup.string()
    .required()
    .oneOf([Yup.ref('PIN'), null], 'Los códigos no coinciden')
    .label('Confirmar Pin'),
});

const UserStatusSchema = Yup.object({
  idEstatus: Yup.number().required().label('Acción'),
  Motivo: Yup.string().required().label('Motivo'),
});

const UserRecoveryPasswdSchema = Yup.object({
  Celular: Yup.string().required(),
  //Contraseña: Yup.string().required().min(6).label("Contraseña"),
  Contraseña: Yup.string()
    .password(
      'La contraseña debe contener mínimo 8 carácteres, una letra mayúscula, número y símbolo especial'
    )
    .required()
    .min(8)
    .label('Contraseña'),
  Confirmar: Yup.string()
    .required()
    .oneOf([Yup.ref('Contraseña'), null], 'Las contraseñas no coinciden')
    .label('Confirmar contraseña'),
});

export const updateUsernameSchema = Yup.object({
  idUsuario: Yup.number().required(),
  CelularAnterior: Yup.string().phone('Ingrese un número de teléfono válido').required().min(10).max(10),
  CelularNuevo: Yup.string()
    .phone('Ingrese un número de teléfono válido')
    .required()
    .min(10)
    .max(10)
    .label('Celular')
    .notOneOf([Yup.ref('CelularAnterior')], 'No puede repetir el mismo número'),
  Confirm: Yup.string()
    .phone('Ingrese un número de teléfono válido')
    .required()
    .min(10)
    .max(10)
    .oneOf([Yup.ref('CelularNuevo'), null], 'Los números de celular no coinciden')
    .label('Confirmar Celular'),
});

export const AccountVerifySchema = Yup.object({
  Celular: Yup.string().phone('Ingrese un número de teléfono válido').required().min(10).max(10),
  Nombre: Yup.string()
    .required()
    .matches(/^[A-Za-z\s]+$/, 'El nombre solo puede contener letras y espacios')
    .min(10)
    .label('Nombre Completo'),
});
export const TypeRequestSchema = Yup.object({
  Celular: Yup.string().phone('Ingrese un número de teléfono válido').required().min(10).max(10),
  Nombre: Yup.string()
    .required()
    .matches(/^[A-Za-z\s]+$/, 'El nombre solo puede contener letras y espacios')
    .min(10)
    .label('Nombre Completo'),
  Categoria: Yup.number().required().label('Categoría'),
  Comentarios: Yup.string().required(),
});

export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};
export const handleKeyPress2 = (event) => {
  const inputValue = event.key;
  const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/; // Expresión regular para permitir letras con acentos, la letra "ñ" y espacios

  if (!regex.test(inputValue)) {
    event.preventDefault();
  }
};
export const PhoneNumber = Yup.string()
  .required()
  .label('Celular')
  .length(10)
  .matches(/^\d{10}$/, 'El número de teléfono debe tener 10 dígitos');

export {
  UserAddSchemaV2,
  UserAddSchema,
  UserUpdateSchema,
  UserPasswordSchema,
  UserPinSchema,
  UserStatusSchema,
  UserUpdateProfileSchema,
  UserRecoveryPasswdSchema,
};
