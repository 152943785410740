import React from "react";

import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const PickerFechaHora = ({ label, value, handleChange }) => {
	// Función para validar si un valor es null o undefined
	const isNullOrUndefined = (value) => value === null || value === undefined;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
			<DateTimePicker
				label={label}
				onChange={(e) => {
					handleChange(isNullOrUndefined(e) ? "" : e);
				}}
				value={value}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						variant="outlined"
						sx={{ width: "100%" }}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{params.InputProps.endAdornment}
									{value && (
										<IconButton
											onClick={() => {
												handleChange(null);
											}}
										>
											<ClearIcon />
										</IconButton>
									)}
								</>
							),
						}}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default PickerFechaHora;
