import { useRoutes, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Routes } from './routes';
import { ProtectedRoute, AlreadyLoggedIn } from '@middlewares';

import Welcome from '@pages/Welcome/Welcome';
import NotFound from '@pages/auth/NotFound';
import Coaliciones from '@pages/auth/Coaliciones';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
    navigate('/login');
  }, [navigate]);

  return null;
};

const Router = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <AlreadyLoggedIn />,
    },
    {
      path: '/welcome',
      element: (
        <ProtectedRoute>
          <Welcome />
        </ProtectedRoute>
      ),
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/salir',
      element: <Logout />,
    },
    {
      path: '/coaliciones2024',
      element: <Coaliciones />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
    ...Routes,
  ]);

  return routes;
};

export default Router;
