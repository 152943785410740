import { useEffect, useState } from 'react';
import * as ExcelJS from 'exceljs';

// Material UI
import { Container, Box, Card, CardContent, Divider, Chip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import FilterData from '@components/Metas/FilterData';
import BasicTable from '@components/Tables/BasicTable';
import BarraCarga from '@components/Metas/BarraCarga';
import InfoCard from '@components/Metas/InfoCard';
import Download from '@components/Downloads/Download';

// Utilidades
import { numberWithCommas, convertBase64 } from '@utils/Utilities/';

// Servicios
import MetasServices from '@services/MetasServices';

const MetasCompromisos = () => {
  const [loading, setLoading] = useState({
    isLoading: false,
    isOpen: false,
    isSuccess: false,
    loadingMessage: 'Validando...',
    successMessage: '¡Consultado con éxito!',
  });
  const [filter, setFilter] = useState(true);

  // Datos para el filtro ----------------------------------------------------
  const [params, setParams] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
  });
  const [filtered] = useState({
    filtered: [],
  });

  const handleFilter = (action) => {
    setFilter(!filter);
    filtered.filtered = [];

    if (action !== 'clear') {
      if (params.Region) {
        filtered.filtered.push({
          id: 'cat_secciones.idRegion',
          filter: '=',
          value: params.Region,
        });
      }

      if (params.Municipio) {
        filtered.filtered.push({
          id: 'cat_secciones.idMunicipio',
          filter: '=',
          value: params.Municipio,
        });
      }

      if (params.Poligono.length > 0) {
        filtered.filtered.push({
          id: 'cat_secciones.idPoligono',
          filter: 'IN',
          value: params.Poligono,
        });
      }

      if (params.Seccion.length > 0) {
        filtered.filtered.push({
          id: 'cat_secciones.Seccion',
          filter: 'IN',
          value: params.Seccion,
        });
      }
    }
  };

  // Estilos de los card
  const card = {
    overflow: 'visible',
    boxShadow: 1,
    borderRadius: 2,
    backgroundColor: 'white',
  };
  const cardContent = {
    padding: 2,
    paddingBottom: '16px !important',
  };

  // Tabla ----------------------------------------------------
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');

  // Llamada a la API
  const fetchData = async (params) => {
    setLoadingTable(true);
    try {
      const result = await MetasServices.getGoalsBySection(params);
      const { results, message, response } = result;

      if (results) {
        setTotal(response.total);
        setData(response.data);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: 'warning',
      });
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    setPage(0);

    const params = {
      page: 0,
      pageSize: rowsPerPage,
      filtered: filtered.filtered,
    };

    // Comprueba si esta ordenada
    if (orderBy) {
      params.sorted = [{ id: orderBy, value: order }];
    }

    setTotal(0);
    setData([1]);

    fetchData(params);
    //eslint-disable-next-line
  }, [filter]);

  const columnas = [
    { id: 'idRegion', label: 'Región' },
    { id: 'idMunicipio', label: 'Municipio' },
    { id: 'idPoligono', label: 'Polígono' },
    { id: 'Seccion', label: 'Sección' },
    { id: 'MetaCompromisos', label: 'Meta', align: 'right' },
  ];

  // Paginación remota de la tabla
  const handleChangePagination = ({ page, pageSize }) => {
    setPage(page);
    setRowsPerPage(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered.filtered,
    };

    // Comprueba si esta ordenada
    if (orderBy) {
      params.sorted = [{ id: orderBy, value: order }];
    }

    fetchData(params);
  };

  // Ordenamiento remoto de la tabla
  const handleChangeSort = (e) => {
    let name = e.orderBy !== 'MetaCompromisos' ? 'cat_secciones.' + e.orderBy : e.orderBy;

    setOrderBy(name);
    setOrder(e.order);

    const params = {
      page: page,
      pageSize: rowsPerPage,
      filtered: filtered.filtered,
      sorted: [{ id: name, value: e.order }],
    };

    fetchData(params);
  };

  // Se estructura la tabla, y agrega comas a los miles
  const rowsRefactored = data.map((item) => ({
    idRegion: item.Region,
    idMunicipio: item.Municipio,
    idPoligono: item.Poligono,
    Seccion: item.Seccion,
    MetaCompromisos: numberWithCommas(item.Meta),
  }));

  // Exportar / Importar datos -----------------------------
  const [isDownload, setIsDownloading] = useState(false);
  const [timer, setTimer] = useState(false);
  const [state, setState] = useState({
    error: false,
    message: false,
  });

  // Mostrar mensajes por 3 segundos
  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        setTimer(false);
      }, 3000);
    }
  }, [timer]);

  // Al poner un mensaje nuevo
  useEffect(() => {
    setTimer(true);
  }, [state]);

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const params = {
        filtered: filtered.filtered,
      };

      // Comprueba si esta ordenada
      if (orderBy) {
        params.sorted = [{ id: orderBy, value: order }];
      }

      const result = await MetasServices.exportGoalsBySection(params);
      const { success } = result;
      if (success) {
        Swal.fire({
          title: 'Descargado con éxito.',
          icon: 'success',
        });
      } else {
        throw new Error('Ocurrió un error, contacte al administrador.');
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleImport = (e) => {
    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const file = e.target.files[0];

    if (file) {
      // Valida si es archivo tipo excel
      if (allowedTypes.includes(file.type)) {
        setLoading({
          isLoading: true,
          isOpen: true,
          isSuccess: false,
          loadingMessage: 'Validando...',
          successMessage: '¡Consultado con éxito!',
        });

        let flagError = true;

        try {
          const reader = new FileReader();

          // Procesado del excel
          reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(data);

            const worksheet = workbook.getWorksheet(1); // Seleccionar la primera hoja
            const fila = worksheet.getRow(11); // Obtén la fila 11.

            // Si el nombre inicia con Errores_, entonces tiene 2 columnas extras
            const ColLength = file.name.startsWith('Errores_') ? columnas.length + 2 : columnas.length;

            // Recorre las celdas de la fila 11.
            fila.eachCell((cell) => {
              if (cell.value) {
                flagError = false;
                return false; // Lo detiene si se encuentra una celda no vacía.
              }
            });

            // Mira si esta vacia la fila 11, y asume que todo esta vacio.
            if (flagError) {
              setState({
                error: true,
                message: 'El archivo está vacío (fila 11 vacia)',
              });
            }

            // Comprueba si no es el mismo numero de encabezados y de columnas en la fila 10
            else if (worksheet.getRow(10).cellCount !== ColLength) {
              setState({
                error: true,
                message: 'La plantilla no coincide a la tabla',
              });
              flagError = true;
            }

            // Validar si las columnas D y E están vacías o son números válidos desde la fila 11
            else {
              // Se borran inicialmente las columnas de errores
              worksheet.spliceColumns(6, 2);

              // Almacena valores únicos de la columna D
              const valuesCD = new Set();

              for (let i = 11; i <= worksheet.rowCount; i++) {
                const cellD = worksheet.getCell(`D${i}`);
                const cellE = worksheet.getCell(`E${i}`);
                const repetido = valuesCD.has(cellD.value); // Comprueba si ya existe

                // Validaciones
                if (!cellD || isNaN(cellD.value) || cellD.value <= 0 || repetido) {
                  flagError = true;
                  const cellF = worksheet.getCell(`F${i}`);
                  cellF.value = repetido ? 'Sección repetida' : 'La Sección no es válida';
                  cellF.font = {
                    bold: true,
                    color: { argb: 'B71C1C' },
                  };
                } else {
                  valuesCD.add(cellD.value);
                }

                if (!cellE || isNaN(cellE.value) || cellE.value <= 0) {
                  flagError = true;
                  const cellG = worksheet.getCell(`G${i}`);
                  cellG.value = 'La Meta no es valida';
                  cellG.font = {
                    bold: true,
                    color: { argb: 'B71C1C' },
                  };
                }
              }

              if (flagError) {
                // Titulo principal de errores
                const border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                };
                const font = { bold: true, color: { argb: 'B71C1C' } };
                const fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFCDD2' },
                };

                // Encabezados de las columnas de errores
                const cellTitle = worksheet.getCell(`F9`);
                const cellF = worksheet.getCell(`F10`);
                const cellG = worksheet.getCell(`G10`);

                cellTitle.value = 'ERRORES';
                cellF.value = 'ERROR EN SECCIÓN';
                cellG.value = 'ERROR EN META';

                cellTitle.border = border;
                cellF.border = border;
                cellG.border = border;

                cellTitle.font = font;
                cellF.font = font;
                cellG.font = font;

                cellTitle.fill = fill;
                cellF.fill = fill;
                cellG.fill = fill;

                cellTitle.alignment = {
                  horizontal: 'center',
                  vertical: 'middle',
                };

                worksheet.unMergeCells(9, 6, 9, 7);
                worksheet.mergeCells(9, 6, 9, 7);

                // Establecer ancho específico para las columnas F y G
                worksheet.getColumn('F').width = 20;
                worksheet.getColumn('G').width = 20;

                setState({
                  error: true,
                  message: `Se encontraron errores.`,
                });
                // Guardar el archivo modificado
                const buffer = await workbook.xlsx.writeBuffer(); // Obtener el contenido del archivo
                const modifiedFile = new Blob([buffer], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                // Verifica si el archivo ya inicia con "Errores_"
                let nuevoNombre = file.name.startsWith('Errores_') ? file.name : `Errores_${file.name}`;

                nuevoNombre = nuevoNombre.replace(/\s*\([^)]*\)/, '');

                // Crear un enlace de descarga para el archivo modificado
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(modifiedFile);
                downloadLink.download = nuevoNombre; // Nombre del archivo modificado

                // Simular un clic en el enlace para descargar el archivo modificado
                downloadLink.click();
              }
            }
          };

          reader.readAsArrayBuffer(file);
        } catch (error) {
          Swal.fire({
            title: error,
            icon: 'warning',
          });
        } finally {
          setTimeout(() => {
            setLoading({
              ...loading,
              isLoading: false,
              isOpen: false,
            });

            // No se encontraron errores en el archivo
            if (!flagError) uploadFile(file);
          }, 1000);
        }
      } else {
        setTimer(true);
        setState({
          error: true,
          message: 'No es un archivo de Excel válido',
        });
      }
    }
  };

  // Se sube el archivo al estar validado
  const uploadFile = async (file) => {
    setLoading({
      isLoading: true,
      isOpen: true,
      isSuccess: false,
      loadingMessage: 'Subiendo...',
      successMessage: 'Subido con éxito!',
    });

    const params = {
      files: [{ file: await convertBase64(file) }],
    };

    try {
      const result = await MetasServices.importGoalsBySection(params);
      const { success, results, message } = result;

      if (message === '¡Descargado con éxito!') {
        Swal.fire({
          title: 'Se encontraron errores.\nConsulte el archivo descargado.',
          icon: 'warning',
        });
      } else {
        Swal.fire({
          title: message,
          icon: results && results ? 'success' : 'warning',
        });

        // Se actualiza la tabla al estar correcto.
        if (success && results) setFilter(!filter);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading({
        ...loading,
        isLoading: false,
        isOpen: false,
      });
    }
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth={false} sx={{ paddingX: { xs: 0, sm: 2, md: 3 } }}>
          <ActionHeader title="Metas Compromisos" />
          <LoadingForm
            loadinMessage={loading.loadingMessage}
            successMessage={loading.successMessage}
            isLoading={loading.isLoading}
            success={loading.isSuccess}
            isOpen={loading.isOpen}
            setIsOpen={() => {}}
          />
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} sm={6} md={5} lg={3}>
                <Card sx={{ ...card, height: '100%' }}>
                  <CardContent sx={{ ...cardContent, height: '100%' }}>
                    <InfoCard filter={filter} filtered={filtered} />
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 xs={12} sm={6} md={7} lg={9}>
                <Card sx={{ ...card, height: '100%' }}>
                  <CardContent sx={{ ...cardContent, height: '100%' }}>
                    <BarraCarga filter={filter} filtered={filtered} />
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 xs={12}>
                <Card sx={card}>
                  <CardContent sx={cardContent}>
                    <Chip
                      label={'Configuración Meta Promoción (Simpatías)'}
                      sx={{
                        backgroundColor: 'transparent',
                        fontSize: 16,
                        justifyContent: 'left',
                        mb: 1,
                      }}
                    />

                    <FilterData
                      params={params}
                      setParams={setParams}
                      handleFilter={handleFilter}
                      isLoading={loadingTable}
                      handleExport={handleExport}
                      handleImport={handleImport}
                    />
                    <Divider sx={{ my: 2 }} />

                    {/* Mensajes al importar archivo */}
                    {timer && state.message && (
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{
                          marginBottom: 2,
                          backgroundColor: state.error ? '#9A3939' : '#5BA681',
                          color: 'white',
                          padding: '10px',
                        }}
                      >
                        {state.message}
                      </Typography>
                    )}

                    {/* Descargar archivo exportado */}
                    {isDownload && (
                      <Box marginBottom={2}>
                        <Download format={'xlsx'} isDownload={isDownload} />
                      </Box>
                    )}

                    <BasicTable
                      rows={rowsRefactored}
                      hcolumns={columnas}
                      total={total}
                      stickyHeader={true}
                      pageSize={rowsPerPage}
                      pageProp={page}
                      handleChangePagination={handleChangePagination}
                      isLoading={loadingTable}
                      showPagination={true}
                      manual={true}
                      handleManualSort={handleChangeSort}
                      cardType={false}
                    />
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </AdminLayout>
    </>
  );
};

export default MetasCompromisos;
