import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const SelectSearch = (props) => {
  const {
    value,
    onChange,
    options = [],
    error = false,
    disabled = false,
    id = 'SELECT',
    isLoading = false,
    placeholder,
    multiple = false,
    name,
    variant,
  } = props;

  const [autocompleteOptions, setAutocompleteOptions] = useState(options);

  useEffect(() => {
    setAutocompleteOptions(options);
  }, [options]);

  const handleSelect = (selectedValues) => {
    if (multiple) {
      onChange(selectedValues);
    } else {
      let newData = options.find((item) => item.label.toUpperCase() === selectedValues.label.toUpperCase());
      if (newData) {
        onChange(newData);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          multiple={multiple}
          id={id}
          name={name}
          value={value}
          options={autocompleteOptions}
          onChange={(event, selectedValues) => handleSelect(selectedValues)}
          // onBlur={(e, selectedValues) => handleSelect(selectedValues)}
          getOptionLabel={(option) => option.label}
          disabled={disabled}
          size={'small'}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              error={error}
              variant={variant}
              placeholder={placeholder}
            />
          )}
        />
      )}
    </>
  );
};

SelectSearch.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  sx: PropTypes.object,
};

export default SelectSearch;
