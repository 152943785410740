import { useState, useEffect } from 'react';

// Material UI
import { Grid, Box, TextField, Button, Typography } from '@mui/material';

// Componentes
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import CustomTable from '@components/Tables/CustomTable';

// Utils
import { validateNumber } from '@utils/validations';
import { convertToNumber } from '@utils/Utilities';
import { setVars } from '@utils/global';

const AsignCompromisos = (props) => {
  const { ids, mobilizers, assignment, setAssignment, limit } = props;

  const [valueM, setValueM] = useState({ value: '', label: '' });
  const [valueC, setValueC] = useState({ value: '', label: '' });
  const [valueText, setValueText] = useState('');

  const [total, setTotal] = useState(0);

  useEffect(() => {
    const mov = mobilizers.data.filter((item) => ids.includes(item.value))[0];
    const com = mobilizers.totalsCompromisos[0];

    setValueM({ value: mov.value, label: mov.label });
    setValueC({ value: com.value, label: com.label });
    // eslint-disable-next-line
  }, []);

  const handleMovilizador = ({ value, label }) => {
    setValueText('');
    setValueM({ value, label });
  };

  const handleCompromiso = ({ value, label }) => {
    setValueText('');
    setValueC({ value, label });
  };

  const handleTotal = (e) => {
    const value = e.target.value;

    if (validateNumber(value) && convertToNumber(value) <= total) {
      setValueText(convertToNumber(value));
    }
  };

  const handleSave = () => {
    const filteredMov = assignment.filter(
      (item) => item.idMovilizador === valueM.value && item.idCompromiso === valueC.value
    );

    const params = {
      idMovilizador: valueM.value,
      idCompromiso: valueC.value,
      labelM: valueM.label,
      labelC: valueC.label,
      total: filteredMov.length ? filteredMov[0].total + valueText : valueText,
      action: 'delete',
    };

    const filtered = assignment.filter(
      (item) => !(item.idMovilizador === valueM.value && item.idCompromiso === valueC.value)
    );
    filtered.unshift(params);

    setAssignment(filtered);
    setVars('MobAsignAssignment', filtered);
    setValueText('');
  };

  const handleDelete = (a, b, c) => {
    const filtered = assignment.filter(
      (item) => !(item.idMovilizador === c.idMovilizador && item.idCompromiso === c.idCompromiso)
    );
    setAssignment(filtered);
    setVars('MobAsignAssignment', filtered);
  };

  const getTotal = (valueM, valueC) => {
    // Busca todos los que sean del idCompromismo pero de diferente movilizador
    const filtered = assignment.filter((item) => item.idCompromiso === valueC);

    const totalMax = mobilizers.totalsCompromisos.filter((item) => item.value === valueC)[0];

    if (filtered.length) {
      const totalSum = filtered.reduce((acc, cv) => acc + cv.total, 0);
      setTotal(totalMax?.total - totalSum);
    } else setTotal(totalMax?.total);
  };

  useEffect(() => {
    if (valueM.value !== '' && valueC.value !== '') getTotal(valueM.value, valueC.value);
    // eslint-disable-next-line
  }, [valueM, valueC, assignment]);

  const columns = [
    { id: 'labelM', label: 'Movilizador', type: 'text' },
    { id: 'labelC', label: 'Colonia', type: 'text' },
    { id: 'total', label: 'Compromisos a movilizar', type: 'number' },
    {
      id: 'action',
      label: '',
      type: 'iconButton',
      sort: false,
      align: 'center',
      color: '#EF4624',
      disablePadding: true,
      onClick: handleDelete,
    },
  ];

  return (
    <Grid container spacing={1} rowSpacing={2}>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography align="center">No. Compromisos sugeridos por Movilizador: {limit}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdvancedSelect
          name="movilizador"
          label="Movilizador"
          options={mobilizers.data.filter((item) => ids.includes(item.value))}
          value={valueM.value}
          onChange={handleMovilizador}
          isSearchable
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AdvancedSelect
          name={`compromisos`}
          label={'Compromisos'}
          options={mobilizers.totalsCompromisos}
          value={valueC.value}
          onChange={handleCompromiso}
          isSearchable
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          id="compromisos"
          size="small"
          label={total === 0 ? 'No quedan compromisos' : `Cantidad de compromisos (max. ${total})`}
          helperText={total === 0 ? '' : `Máximo ${total} compromisos`}
          variant="outlined"
          onChange={handleTotal}
          value={valueText}
          disabled={total === 0}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={12} display="flex" justifyContent="right">
        <Box>
          <Button variant="outlined" onClick={handleSave} disabled={valueText === '' || valueText === 0}>
            Guardar
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomTable rows={assignment} columns={columns} disableCardType />
      </Grid>
    </Grid>
  );
};

export default AsignCompromisos;
