import { createTheme } from '@mui/material';
import { esES } from '@mui/material/locale';

export const theme = createTheme(
  {
    palette: {
      primaryDark: {
        main: '#0041a0',
        contrastText: '#fff',
      },
    },

    /*  components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "#0041a0",
          },
        },
      },
    }, */

    typography: {
      button: {
        fontWeight: 600,
      },
      fontFamily:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.75,
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase',
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
      },
      h1: {
        fontWeight: 700,
        fontSize: '3.5rem',
        lineHeight: 1.375,
      },
      h2: {
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: 1.375,
      },
      h3: {
        fontWeight: 700,
        fontSize: '2.25rem',
        lineHeight: 1.375,
      },
      h4: {
        fontWeight: 700,
        fontSize: '2rem',
        lineHeight: 1.375,
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.375,
      },
      h6: {
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: 1.375,
      },
    },
  },
  esES
);

export const styledGridx4 = {
  display: 'grid',
  gap: '24px',
  '@media (min-width: 1200px)': {
    gridTemplateColumns: 'repeat(4, 1fr) !important',
  },
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (min-width: 0px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
};

export const styledGridx3 = {
  display: 'grid',
  gap: '24px',
  '@media (min-width: 1200px)': {
    gridTemplateColumns: 'repeat(3, 1fr) !important',
  },
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (min-width: 0px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
};

export const styledGridx3Mobile = {
  display: 'grid',
  gap: '24px',
  '@media (min-width: 1200px)': {
    gridTemplateColumns: 'repeat(3, 1fr) !important',
  },
  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 0px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};

export const styleTextFieldNumber = {
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
};
