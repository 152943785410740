import { useState } from 'react';
import useSearchData from '@hooks/useSearchData';

import {
  Table,
  TableContainer,
  Card,
  Typography,
  Button,
  CardContent,
  Alert,
  Divider,
  CardActions,
  Stack,
  TextField,
  Icon,
  Container,
  Box,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableEmpty from './TableEmpty';

import esLocale from 'date-fns/locale/es';
import moment from 'moment';

const ListMobilization = ({
  data,
  users,
  dataFound,
  selectAll,
  hour,
  setUsers,
  setSelectAll,
  setMovilization,
  handleChangeHour,
}) => {
  const { searchValue, filteredData, handleSearch } = useSearchData(data, () => {}, 'NombreCompleto');

  const handleSelectAll = () => {
    if (users.length === 0) {
      if (!selectAll) {
        const notSelectedRows = data.filter((row) => row.esPaseLista !== 1);
        const newSelectedRows = notSelectedRows.map((row) => ({
          id: row.idUsuario,
          FechaHora: moment().format('HH:mm'),
        }));

        setUsers(newSelectedRows);
        setSelectAll(true);
      } else {
        setUsers([]);
        setSelectAll(false);
      }
    } else {
      setSelectAll(false);
      setUsers([]);
    }
  };

  const handleSelectRow = (idUsuario) => {
    const selectedRow = users.find((row) => row.id === idUsuario);

    if (selectedRow) {
      setUsers((prevSelectedRows) => prevSelectedRows.filter((row) => row.id !== idUsuario));
      setSelectAll(users.length <= 0);
    } else {
      setUsers((prevSelectedRows) => [
        ...prevSelectedRows,
        { id: idUsuario, FechaHora: moment().format('HH:mm') },
      ]);
      setSelectAll(users.length > 0);
    }
  };

  const handleSetMobilization = () => {
    setMovilization({ body: users });
  };

  return (
    <Card className="card-primary">
      <Container sx={{ p: 2 }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginBottom={2}
          spacing={3}
        >
          <Typography fontWeight={600} variant="subtitle2">
            USUARIOS
          </Typography>
          <Box display={'flex'} gap={1} width={'80%'}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <TimePicker
                label="Hora de pase de lista"
                value={hour}
                onChange={handleChangeHour}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              placeholder="Buscar por nombre"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              InputProps={{
                startAdornment: (
                  <Icon fontSize="small" color="action">
                    search
                  </Icon>
                ),
                sx: { borderRadius: 2 },
              }}
              sx={{ width: '75%' }}
            />
          </Box>
        </Stack>
        <Divider />
        {users.length > 0 && (
          <Alert severity="info" sx={{ mt: 1 }}>
            {users.length} usuarios seleccionados
          </Alert>
        )}
      </Container>
      <CardContent>
        <TableContainer
          sx={{
            maxHeight: 440,
            borderRadius: 3,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead
              data={filteredData && filteredData.length > 0 ? filteredData : data}
              handleSelectAll={handleSelectAll}
              selectAll={selectAll}
              indeterminate={users.length > 0 && users.length < data.length}
            />

            {filteredData && filteredData.length === 0 && searchValue.length > 0 ? (
              <TableEmpty />
            ) : (
              <TableBody
                data={filteredData && filteredData.length > 0 ? filteredData : data}
                dataFound={dataFound}
                users={users}
                handleSelectRow={handleSelectRow}
              />
            )}
          </Table>
        </TableContainer>
      </CardContent>

      {data.length > 0 && (
        <CardActions sx={{ justifyContent: 'center', marginTop: 2 }}>
          <Button variant="contained" color="primaryDark" onClick={handleSetMobilization}>
            Guardar
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ListMobilization;
