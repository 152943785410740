import { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

//Components
import InputSelect from '@components/Selects/BasicSelect';

//Mui
import { Box, Button, Icon, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { getVars } from '@utils/global';
import prep from '@services/PrepServices';
import { AMBITO_DL, AMBITO_MPAL, AMBITO_MPIO_DL } from '@data/constants';
import {
  ELECCION_DIPUTADO_LOCAL, //5
  ELECCION_PRESIDENTE_MUNICIPAL, //6
  ELECCION_GOBERNADOR, //4
} from '@data/constants/Prep';

const Filter = (props) => {
  const { onChangeFilter, disabledButtons } = props;
  const { user } = getVars('Token');
  const catalogsOptions = [
    { id: 'df', getAll: false },
    { id: 'dl', getAll: false },
    { id: 'municipios', getAll: false },
    { id: 'municipios_dl', getAll: false },
    {
      id: 'tipo_eleccion',
      filtered: [
        {
          id: 'id',
          filter: 'IN',
          value: [4, 5, 6],
        },
      ],
    },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [catalog, setCatalog] = useState(() => {
    let newObject = { ambito_agrupador: [] };
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [catalogsFiltered, setCatalogsFiltered] = useState(() => {
    let newObject = { ambito_agrupador: [] };
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    idTipoEleccion: '',
    ValorAmbito: '',
  });
  const [initFilter, setInitFilter] = useState({});
  const [catScope, setCatScope] = useState([
    {
      value: '',
      label: 'Seleccione una opción',
    },
  ]);

  useEffect(() => {
    setDefaultOptions([{ value: 0, label: 'TODOS' }]);
    //eslint-disable-next-line
  }, []);

  const setCatalogs = async (data) => {
    try {
      setLoadingCatalogs(true);
      const result = await prep.getElectionScope({});
      const { results, response, message } = await result;

      if (results) {
        setCatalog({ ...data, ambito_agrupador: response.data });
        setCatalogsFiltered({ ...data, ambito_agrupador: response.data });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  useEffect(() => {
    if (!load) {
      setCatalogs(catalogs);
    }
    //eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (catalog.tipo_eleccion.length > 0 && catalog.ambito_agrupador.length > 0) {
      handleSetInitFilter(catalog.tipo_eleccion[0].value);
    }
    //eslint-disable-next-line
  }, [catalog]);

  const handleSetInitFilter = (idTipoEleccion) => {
    let grouper = getDefaultGrouperFromElection(idTipoEleccion);
    let scope = getScopeFromElection(idTipoEleccion);
    setCatalogsFiltered((prevState) => ({
      ...prevState,
      ambito_agrupador: catalog.ambito_agrupador.filter((item) => item.idTipoEleccion === idTipoEleccion),
    }));

    setFilterData({
      idTipoEleccion: idTipoEleccion,
      idAmbitoAgrupador: grouper,
      ValorAmbito: scope,
    });

    if (grouper && scope !== '') {
      onChangeFilter({
        idTipoEleccion: idTipoEleccion,
        idAmbitoAgrupador: grouper,
        ValorAmbito: scope,
      });
    }

    setInitFilter({
      idTipoEleccion: idTipoEleccion,
      idAmbitoAgrupador: grouper,
      ValorAmbito: scope,
    });
  };

  const getDefaultGrouperFromElection = (idTipoEleccion) => {
    let grouper = { value: -1, label: 'Sin seleccionar' };

    switch (idTipoEleccion) {
      case ELECCION_DIPUTADO_LOCAL:
        setCatalogsFiltered((prevState) => ({
          ...prevState,
          ambito_agrupador: catalog.ambito_agrupador.filter((item) => item.idTipoEleccion === idTipoEleccion),
        }));
        grouper = catalog.ambito_agrupador.find((item) => item.value === AMBITO_DL);
        break;
      case ELECCION_PRESIDENTE_MUNICIPAL:
        setCatalogsFiltered((prevState) => ({
          ...prevState,
          ambito_agrupador: catalog.ambito_agrupador.filter((item) => item.idTipoEleccion === idTipoEleccion),
        }));
        grouper = catalog.ambito_agrupador.find((item) => item.value === AMBITO_MPAL);
        break;
      default:
        setCatalogsFiltered((prevState) => ({ ...prevState, ambito_agrupador: catalog.ambito_agrupador }));
        grouper = catalog.ambito_agrupador[0];
        break;
    }

    return grouper?.value || '';
  };

  const getScopeFromElection = (tipoEleccion) => {
    let scope = { value: -1, label: 'Sin seleccionar' };

    switch (tipoEleccion) {
      case ELECCION_DIPUTADO_LOCAL:
        scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.dl[0];
        setCatScope(defaultOptions.concat(catalog.dl));
        setCatalogsFiltered((prevState) => ({
          ...prevState,
        }));
        break;
      case ELECCION_PRESIDENTE_MUNICIPAL:
        scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
        setCatScope(defaultOptions.concat(catalog.municipios));
        setCatalogsFiltered((prevState) => ({
          ...prevState,
        }));
        break;
      default:
        // scope = { value: 0, label: 'TODOS' };
        if (user.ambito_perfil.some((item) => item.idAmbito === AMBITO_MPIO_DL)) {
          setCatScope(defaultOptions.concat(catalog.municipios_dl));
          scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios_dl[0];
        } else {
          setCatScope(defaultOptions.concat(catalog.municipios));
          scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
        }
        setCatalogsFiltered((prevState) => ({
          ...prevState,
        }));
        break;
    }
    return scope && scope.value !== undefined ? scope.value : '';
  };

  const handleChangeElectionType = (value) => {
    let grouper = getDefaultGrouperFromElection(value);
    let scope = getScopeFromElection(value);
    setCatalogsFiltered((prevState) => ({
      ...prevState,
      ambito_agrupador: catalog.ambito_agrupador.filter((item) => item.idTipoEleccion === value),
    }));

    setFilterData({
      ...filterData,
      idTipoEleccion: value,
      idAmbitoAgrupador: grouper,
      ValorAmbito: scope,
    });
  };

  const handleChangeScope = (value) => {
    setFilterData({
      ...filterData,
      ValorAmbito: value,
    });
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    onChangeFilter(filterData);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilterData(initFilter);
    onChangeFilter(initFilter);
  };

  return (
    <Box sx={{ mt: 2, paddingX: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <Icon>filter_list</Icon>
        <Typography variant="body2" fontWeight={600}>
          Filtros
        </Typography>
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={6} md={12}>
          <InputSelect
            label="Tipo de elección"
            options={catalogsFiltered.tipo_eleccion}
            name="idTipoEleccion"
            value={catalog.tipo_eleccion.length > 0 ? filterData.idTipoEleccion : ''}
            onChange={(e) => {
              handleChangeElectionType(e.target.value);
            }}
            disabled={catalog.tipo_eleccion.length < 2}
            sx={{ width: '100%' }}
            isLoading={loadingCatalogs}
          />
        </Grid2>
        {filterData.idTipoEleccion !== ELECCION_GOBERNADOR && (
          <Grid2 xs={12} sm={6} md={12}>
            <InputSelect
              label="Ámbito de elección"
              options={catScope}
              name="ValorAmbito"
              value={filterData.ValorAmbito}
              onChange={(e) => handleChangeScope(e.target.value)}
              disabled={catScope.length < 2}
              sx={{ width: '100%' }}
              isLoading={loadingCatalogs}
            />
          </Grid2>
        )}
      </Grid2>

      <Grid2 container spacing={1} sx={{ mt: 2 }} justifyContent={'end'} alignItems={'end'}>
        <Grid2 xs={6} sm={3} md={6} lg={4}>
          <Button
            variant="outlined"
            color="primaryDark"
            onClick={handleClearFilter}
            fullWidth
            disabled={disabledButtons}
          >
            Limpiar
          </Button>
        </Grid2>
        <Grid2 xs={6} sm={3} md={6} lg={4}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={handleApplyFilter}
            fullWidth
            disabled={disabledButtons}
          >
            Filtrar
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

Filter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default Filter;
