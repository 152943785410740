import { Button, Card, Container, Grid, Icon, Typography, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { FilterFrente } from '@data/interfaces/FrenteAmplioInterfaces';
import { FilterFrenteSchema } from '@data/schemas/FrenteAmplioSchemas';
import { useCatalogs } from '@hooks/useCatalogs';
import { isTypePhone } from '@utils/validations';
import InputSelect from '@components/Selects/BasicSelect';
import { useEffect, useState } from 'react';
import { limpiarTelefono } from '../../utils/global';

const FilterAMovilizar = ({ onChangeFilter }) => {
  const defaultOption = [{ value: 0, label: 'TODOS' }];
  const [catalog, setCatalog] = useState([]);
  const [filtro, setFiltro] = useState({
    Estatus: 0,
    Celular: '',
    Nombre: '',
  });
  const catalogsParams = [{ id: 'estatus_movilizacion' }];
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  useEffect(() => {
    if (load === false) {
      setCatalog(defaultOption.concat(catalogs.estatus_movilizacion));
    }
    // eslint-disable-next-line
  }, [load]);

  const handleFilter = (values) => {
    const { Celular, Nombre, Estatus } = values;
    console.log(values);
    let filtered = [];

    if (Celular.length > 0) {
      filtered.push({
        id: 'compromisos_unicos_fa.Celular',
        filter: 'LIKE',
        value: `%${Celular.toUpperCase().replace(/ /g, '%')}%`,
      });
    }

    if (Nombre.length > 0) {
      filtered.push({
        id: "CONCAT_WS(' ',compromisos_unicos_fa.Nombre,compromisos_unicos_fa.Paterno,compromisos_unicos_fa.Materno)",
        filter: 'LIKE',
        value: `%${Nombre.toUpperCase().replace(/ /g, '%')}%`,
      });
    }

    if (Estatus > 0) {
      filtered.push({
        id: 'fam_listas_movilizador.idEstatus',
        filter: '=',
        value: Estatus,
      });
    }

    onChangeFilter(filtered);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilter(filtro);
    }
  };

  const handleClearFilter = () => {
    setFiltro({
      ...filtro,
      Celular: '',
      Nombre: '',
      Estatus: 0,
    });

    onChangeFilter([]);
  };

  return (
    <Card className="card-primary">
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InputSelect
              label="Estatus"
              options={catalog}
              name="Estatus"
              value={filtro.Estatus}
              onChange={(e) => setFiltro({ ...filtro, Estatus: e.target.value })}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              sx={{ width: '100%' }}
              label="Celular"
              type="text"
              name="celular"
              value={filtro.Celular.toUpperCase()}
              onChange={(e) => {
                setFiltro({ ...filtro, Celular: limpiarTelefono(e.target.value) });
              }}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              sx={{ width: '100%' }}
              label="Nombre"
              type="text"
              name="nombre"
              value={filtro.Nombre.toUpperCase()}
              onChange={(e) => setFiltro({ ...filtro, Nombre: e.target.value.toUpperCase() })}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button variant="contained" color="primaryDark" onClick={() => handleFilter(filtro)}>
              Filtrar
            </Button>
            <Button variant="text" color="primaryDark" onClick={handleClearFilter} sx={{ ml: 1 }}>
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

FilterAMovilizar.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default FilterAMovilizar;
