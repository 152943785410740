import { Fragment } from 'react';

// Material UI
import { ListItem, ListItemButton, ListItemAvatar, Icon, ListItemText, Divider } from '@mui/material';

const DefaultListItem = ({ item, index, selected, handleSelected }) => {
  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton selected={selected === index} onClick={() => handleSelected(item, index)}>
          <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Icon color="disabled" sx={{ fontSize: 40 }}>
              events
            </Icon>
          </ListItemAvatar>

          <ListItemText primary={`${item.Actividad}`} secondary={item.email} />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

export default DefaultListItem;
