import { useState } from 'react';

const useToggleChecked = (data) => {
  const [checkedCasillas, setCheckedCasillas] = useState([]);

  const handleToggleAllChecked = (e, isChecked) => {
    e.stopPropagation();
    let updatedCasillas = [];

    if (isChecked) {
      data.forEach((item) => {
        /* updatedCasillas.push({
          idCasilla: item.id,
          idTipoPropietario: item.idTipoPropietario ?? null,
          idUsuario: item.idUsuario ?? null,
        }); */

        item.Propietarios.forEach((propietario) => {
          updatedCasillas.push({
            idCasilla: item.id,
            idTipoPropietario: propietario.idTipoPropietario,
            idUsuario: propietario.idUsuario,
            esPaseLista: propietario.esPaseLista,
          });
        });
      });
    }

    setCheckedCasillas(updatedCasillas);
  };

  const handleToggle = (e, isChecked, item, propietario = null) => {
    e.stopPropagation();

    let updatedCasillas;

    if (propietario) {
      updatedCasillas = isChecked
        ? [
            ...checkedCasillas,
            {
              idCasilla: item.id,
              idTipoPropietario: propietario.idTipoPropietario,
              idUsuario: propietario.idUsuario,
            },
          ]
        : checkedCasillas.filter((casilla) => casilla.idUsuario !== propietario.idUsuario);
    } else {
      // Para el elemento principal
      updatedCasillas = isChecked
        ? [
            ...checkedCasillas,
            //  {
            //   idCasilla: item.id,
            //   idTipoPropietario: item.idTipoPropietario ?? null,
            //   idUsuario: item.idUsuario ?? null,
            // },
            ...item.Propietarios.map((propietario) => ({
              ...propietario,
              idCasilla: item.id,
              // idTipoPropietario: propietario.idTipoPropietario,
              // idUsuario: propietario.idUsuario,
              //esPaseLista: propietario.esPaseLista,
            })),
          ]
        : checkedCasillas.filter((casilla) => casilla.idCasilla !== item.id);
    }

    setCheckedCasillas(updatedCasillas);
  };

  const isChecked = (item, propietario = null) => {
    if (propietario) {
      return checkedCasillas.some(
        (casilla) =>
          casilla.idCasilla === item.id &&
          casilla.idTipoPropietario === propietario.idTipoPropietario &&
          casilla.idUsuario === propietario.idUsuario
      );
    }

    return (
      checkedCasillas.some((casilla) => casilla.idCasilla === item.id) &&
      (!item.Propietarios ||
        item.Propietarios.every((propietario) =>
          checkedCasillas.some(
            (casilla) =>
              casilla.idCasilla === item.id &&
              casilla.idTipoPropietario === propietario.idTipoPropietario &&
              casilla.idUsuario === propietario.idUsuario
          )
        ))
    );
  };

  const isCheckedAll = () => {
    return (
      data.length > 0 &&
      checkedCasillas.length === data.length + data.reduce((acc, item) => acc + item.Propietarios.length, 0)
    );
  };

  const indeterminate = () => {
    const totalChecked = checkedCasillas.length;
    return (
      totalChecked > 0 &&
      totalChecked < data.length + data.reduce((acc, item) => acc + item.Propietarios.length, 0)
    );
  };

  return {
    checkedCasillas,
    setCheckedCasillas,
    handleToggle,
    handleToggleAllChecked,
    isChecked,
    isCheckedAll,
    indeterminate,
  };
};

export default useToggleChecked;
