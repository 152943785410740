import { useEffect, useState } from 'react';
import { useSorted } from '@hooks/useSorted';

import { Box, Button, Card, CardContent, Container, Icon, Stack, Typography } from '@mui/material';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import FilterPolygonal from '@components/Rcos/FilterPolygonal';
import DetailPolygonal from '@components/Rcos/Detail';
import Download from '@components/Downloads/Download';
import DataTableReport from '@components/Tables/DataTableReport';

import { COLUMNS_RCO_POLYGONAL } from '@data/constants/Rco';
import rco from '@services/RcosServices';
import Swal from 'sweetalert2';
import middleware from '@middlewares/middleware';

const Polygonal = () => {
  const [total, setTotal] = useState(10);
  const [dataPolygonal, setDataPolygonal] = useState({
    Info: {},
    Poligonales: [],
    data: [],
  });
  const [loadingPolygonal, setLoadingPolygonal] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [isDownload, setIsDownloading] = useState(false);
  const [summary, setSummary] = useState([]);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
    id: "CONCAT_WS(' ', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
  });

  const getInfoRcosPoligonos = async (body) => {
    try {
      setLoadingPolygonal(true);
      const result = await rco.getInfoRcosPoligonos(body);
      const { response, message, results } = result;
      if (!results) {
        throw new Error(message);
      }
      const { Info, Poligonales, data } = response.data;
      setDataPolygonal({
        ...dataPolygonal,
        Info,
        Poligonales,
        data,
      });
      setTotal(response.data.total);
      setSummary(Object.values(response.data.totals));
    } catch (e) {
      Swal.fire({
        title: e,
        icon: 'warning',
      });
    } finally {
      setLoadingPolygonal(false);
    }
  };

  const onChangeFilter = ({ filters }) => {
    if (filters.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filters,
      }));
    }
  };

  const onClearFilter = () => {
    if (data.filtered.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: [],
        sorted: [],
      }));
    }
  };

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const result = await rco.export({
        api: 'rcos/export-rcos-poligonos',
        params: { filtered: data.filtered },
      });
      const { success } = result;

      if (!success) {
        throw new Error('Ocurrió un error en la descarga, consulte con el administrador');
      }

      Swal.fire({
        title: 'Descargado con éxito',
        icon: 'success',
      });
    } catch (e) {
      Swal.fire({
        title: e,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  useEffect(() => {
    if (data.filtered && data.filtered.length > 0) {
      getInfoRcosPoligonos(data);
    } else {
      setDataPolygonal({
        Info: {},
        Poligonales: [],
        data: [],
      });
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="RCO Polígonal" />
        <FilterPolygonal onChangeFilter={onChangeFilter} onClearFilter={onClearFilter} />
        <Box marginTop={2}>
          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuAction('Exportar') && (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginBottom={2}
                >
                  <Typography fontWeight={600} variant="body2">
                    SECCIONALES
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload || data.filtered.length === 0}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </Stack>
              )}

              {isDownload && (
                <Box marginBottom={2}>
                  <Download format={'pdf'} isDownload={isDownload} />
                </Box>
              )}
              <DetailPolygonal
                Info={dataPolygonal.Info}
                data={dataPolygonal.Poligonales}
                type={'POLIGONALES'}
                labelType={'SIN POLIGONALES'}
                loading={loadingPolygonal}
              />
              <DataTableReport
                rows={dataPolygonal.data}
                hcolumns={COLUMNS_RCO_POLYGONAL}
                total={total}
                summary={summary}
                startIdxReport={0}
                pageProp={data.page}
                pageSize={data.pageSize}
                minHeight="0px"
                stickyHeader={true}
                isLoading={loadingPolygonal}
                handleChangePagination={handleChangePagination}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Polygonal;
