import React, { useEffect, useState } from 'react';

// MUI
import { Grid } from '@mui/material';

// Services
import CompromisoServices from '@services/CompromisoServices';

// Components
import CardStats from '@components/Card/CardStats';

// Plugins
import Swal from 'sweetalert2';

function CardsHistory({ load }) {
  const [summaryData, setSummaryData] = useState(null);

  const getSummaryData = async () => {
    try {
      const res = await CompromisoServices.getMySummary();
      const { results, response, message } = res;

      if (results) {
        setSummaryData(response);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    getSummaryData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      {summaryData && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardStats
              icon={'fact_check'}
              title={'Capturados Hoy'}
              total={summaryData.CapturadosHoy}
              loading={load}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardStats
              icon={'groups'}
              title={'Total Compromisos'}
              total={summaryData.TotalCompromisos}
              loading={load}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardStats
              icon={'diversity_3'}
              title={'Voluntarios'}
              total={summaryData.Voluntarios}
              loading={load}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardStats
              icon={'bookmarkAdded'}
              title={'Etiquetados'}
              total={summaryData.Etiquetados}
              loading={load}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default CardsHistory;
