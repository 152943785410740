import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ClipboardCopyIcon } from '@utils/global';
// Material UI
import {
  Box,
  Grid,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Stack,
  Chip,
  Icon,
  TableHead,
  TableSortLabel,
  LinearProgress,
  Divider,
  Button,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Componentes
import FilterCollapse from '@components/Filters/FilterCollapse';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Servicios Middlewares y Utils
import SocialServices from '@services/SocialServices';
import CatalogServices from '@services/CatalogServices';
import middleware from '@middlewares/middleware';
import { isTypePhone } from '@utils/validations';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const TableRowComponent = ({ data, index, selected, handleUpdate, handleConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <LoadingForm
        loadinMessage={'Editando estatus...'}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            {data.Celular} <ClipboardCopyIcon copyText={data.Contacto} />
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data.Nombre}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{data.Seccion}</TableCell>
        <TableCell>{data.EstructurasAlias}</TableCell>
        <TableCell>
          {data.idEstatusContactoMovilizacion !== 1 ? (
            <Chip
              sx={{ bgcolor: data.Color }}
              label={data.Estatus}
              onDelete={() => handleConfirm(data, index, 1)}
            />
          ) : (
            <Chip sx={{ bgcolor: data.Color }} label={data.Estatus} />
          )}
        </TableCell>
        {
          /* middleware.checkMenuActionId("Agregar")  */ true && (
            <TableCell align="right">
              {data.idEstatusContactoMovilizacion === 1 && (
                <Button
                  variant="outlined"
                  startIcon={<Icon>task_alt</Icon>}
                  onClick={() => handleConfirm(data, index, 2)}
                  color="success"
                  fullWidth
                >
                  Contactado
                </Button>
              )}

              {data.idEstatusContactoMovilizacion === 1 && (
                <Button
                  variant="outlined"
                  startIcon={<Icon>cancel</Icon>}
                  onClick={() => handleConfirm(data, index, 3)}
                  color="error"
                  sx={{ whiteSpace: 'nowrap', mt: 1 }}
                  fullWidth
                >
                  No Localizado
                </Button>
              )}
            </TableCell>
          )
        }
      </TableRow>
    </>
  );
};

const TableHeadComponent = ({
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) => {
  const onSort = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ color: 'aqua' }}>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={headCell.orderBy ? headCell.orderBy : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              hideSortIcon
              active={headCell.orderBy}
              direction={
                orderBy.find((item) => item.id === headCell.id)
                  ? orderBy.find((item) => item.id === headCell.id).value
                  : 'asc'
              }
              onClick={(e) => {
                if (headCell.orderBy) {
                  onSort(headCell.orderBy);
                }
              }}
            >
              {headCell.label}
              {orderBy === headCell.orderBy ? (
                <Box sx={{ ...visuallyHidden }}>
                  {orderBy.find((item) => item.id === headCell.id).value === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const TableScroll = (props) => {
  const { config = { height: 588 }, onFilter } = props;

  const colums = [
    { id: 'Celular', label: 'Contacto', orderBy: 'Celular' },
    { id: 'Nombre', label: 'Nombre', orderBy: 'Nombre' },
    { id: 'seccion', label: 'Sección', orderBy: 'Seccion' },
    { id: 'EstructurasAlias', label: 'Etiquetas', orderBy: false },
    { id: 'Estatus', label: 'Estatus', orderBy: false },
    { id: '', label: 'Acción', orderBy: false },
  ];

  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [orderBy, setOrderBy] = useState([]);
  const [pageAnt, setPageAnt] = useState(1);
  const [selected, setSelected] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getList();
    // setHasMore(true);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    setParams({
      ...params,
      sorted: orderBy,
    });
    // setHasMore(true);
    // eslint-disable-next-line
  }, [orderBy]);

  const getList = async () => {
    if (loading) return true;
    setLoading(true);

    let filtros = { ...params };

    try {
      const result = await SocialServices.getListCompromisos(filtros);
      const { results, response, message } = result;

      if (results) {
        const formattedData = response.data.map((item) => ({
          ...item,
          NombreT: item.Nombre,
          Contacto: item.Celular,
          Celular: item.Celular ? (
            <a
              href={`https://api.whatsapp.com/send?phone=52${item.Celular}&text=Hola ${item.Nombre} te invito a que juntos vayamos a ganar con fuerza y corazón por gto`}
              target="_blank"
            >
              {<Chip color="success" icon={<WhatsAppIcon />} label={item.Celular} variant="outlined" />}
            </a>
          ) : (
            <Chip color="success" icon={<Icon>phone</Icon>} label={item.Celular} variant="outlined" />
          ),
          Nombre: <ListItemText primary={item.Nombre} secondary={item.Domicilio} />,
        }));
        setData(filtros.page === 0 ? formattedData : data.concat(formattedData));

        const end = (params.page + 1) * params.pageSize;
        if (end >= response.total) {
          setHasMore(false);
        } else {
          {
            setHasMore(true);
          }
        }

        setPageAnt(1);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = (item, index, idEstatus) => {
    const action = idEstatus === 2 ? 'CONTACTADO' : idEstatus === 1 ? 'PENDIENTE' : 'NO LOCALIZADO';
    Swal.fire({
      title: `¿ESTÁS SEGUR@ DE INDICAR COMO "${action}" A ${item.NombreT}?`,
      text: 'Esta acción no se podrá revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Sí, ${action} `,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) handleEdit(item, index, idEstatus);
    });
  };

  const handleEdit = async (item, index, idEstatus) => {
    const sendParams = { idEstatus, idCompromisoUnico: item.idCompromisoUnico };
    try {
      setLoading(true);
      const result = await SocialServices.updateContactoEstatus(sendParams);
      const { results, message } = result;
      if (results) {
        setHasMore(true);
        setPageAnt(params.page > 1 ? params.page : 1);
        setData([]);
        setParams({
          ...params,
          page: 0,
        });
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    let find = orderBy.find((item) => item.id === property);
    if (find) {
      let filter = orderBy.filter((item) => item.id !== property);

      setOrderBy([
        ...filter,
        {
          id: property,
          value: find.value === 'asc' ? 'desc' : 'asc',
        },
      ]);
    } else {
      setOrderBy([
        ...orderBy,
        {
          id: property,
          value: 'asc',
        },
      ]);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleFilter = (filtros) => {
    setData([]);
    setParams({ ...params, page: 0, filtered: filtros });
    onFilter(filtros);
  };

  return (
    <>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Filter handleFilter={handleFilter} />
        <Divider />
        <br />
        <InfiniteScroll
          scrollableTarget={'scrollableDiv'}
          next={() => setParams({ ...params, page: params.page + 1 })}
          hasMore={hasMore}
          scrollThreshold={0.7}
          dataLength={data.length}
          loader={loading ? <LinearProgress /> : <></>}
        >
          {isMobile ? (
            <Grid container spacing={2}>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <Grid item xs={12} key={index}>
                    <Box p={2} border={1} borderRadius={2}>
                      <Typography variant="h6">{row.Nombre}</Typography>
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        {row.Celular} <ClipboardCopyIcon copyText={row.Contacto} />
                      </Stack>
                      <Typography variant="body2">{row.Seccion}</Typography>
                      <Typography variant="body2">{row.EstructurasAlias}</Typography>
                      {row.idEstatusContactoMovilizacion !== 1 ? (
                        <Chip
                          sx={{ bgcolor: row.Color }}
                          label={row.Estatus}
                          onDelete={() => handleConfirm(row, index, 1)}
                        />
                      ) : (
                        <Chip sx={{ bgcolor: row.Color }} label={row.Estatus} />
                      )}
                      <Box mt={2}>
                        {row.idEstatusContactoMovilizacion === 1 && (
                          <Button
                            variant="outlined"
                            startIcon={<Icon>task_alt</Icon>}
                            onClick={() => handleConfirm(row, index, 2)}
                            color="success"
                            fullWidth
                          >
                            Contactado
                          </Button>
                        )}
                        {row.idEstatusContactoMovilizacion === 1 && (
                          <Button
                            variant="outlined"
                            startIcon={<Icon>cancel</Icon>}
                            onClick={() => handleConfirm(row, index, 3)}
                            color="error"
                            sx={{ whiteSpace: 'nowrap', mt: 1 }}
                            fullWidth
                          >
                            No Localizado
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} key={'error'}>
                  <Box p={2} border={1} borderRadius={2} textAlign="center" boxShadow={3}>
                    <Icon color="error" style={{ fontSize: 48 }}>
                      search_off
                    </Icon>
                    <Typography variant="h6" color="textSecondary">
                      No hay registros disponibles
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          ) : (
            <TableContainer sx={{ maxHeight: config.height }} id="scrollableDiv">
              <Table stickyHeader>
                <TableHeadComponent
                  orderBy={orderBy}
                  rowCount={data.length}
                  headLabel={colums}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.length > 0 ? (
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.NombreT);
                      return (
                        <TableRowComponent
                          key={index}
                          data={row}
                          index={index}
                          selected={isItemSelected}
                          handleUpdate={(index, idEstatus) => {
                            const newData = [...data];
                            newData[index].idEstatusContactoMovilizacion = idEstatus;
                            setData(newData);
                          }}
                          handleConfirm={handleConfirm}
                        />
                      );
                    })
                  ) : (
                    <Box
                      p={2}
                      border={1}
                      borderRadius={2}
                      textAlign="center"
                      justifyContent={'center'}
                      boxShadow={3}
                      sx={{ width: '300%' }}
                    >
                      <Icon color="error" style={{ fontSize: 48 }}>
                        search_off
                      </Icon>
                      <Typography variant="h6" color="textSecondary">
                        No hay registros disponibles
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </InfiniteScroll>
      </Box>
    </>
  );
};

const Filter = ({ handleFilter }) => {
  const [filter, setFilter] = useState({
    nombre: '',
    celular: '',
    idMunicipio: 0,
    idSeccion: 0,
    idEstatus: 0,
    idEtiqueta: 0,
  });

  const [catalogs, setCatalogs] = useState({});
  const [catSeccion, setCatSeccion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCatalogs();
  }, []);

  const getCatalogs = async () => {
    try {
      setIsLoading(true);

      const params = [
        { id: 'municipios', getAll: true },
        { id: 'secciones', getAll: true },
        { id: 'mis_segmentos', getAll: false },
      ];

      const result = await CatalogServices.getCatalogs(params);
      const { results, response, message } = result;

      if (results) {
        const { municipios, secciones, mis_segmentos } = response.catalogs;
        const todos = { value: 0, label: 'TODOS' };
        const todas = { value: 0, label: 'TODAS' };

        const newCat = {
          municipios: [todos, ...municipios],
          secciones: [todas, ...secciones],
          estatus: [
            todos,
            { value: 1, label: 'Pendiente' },
            { value: 2, label: 'Contactado' },
            { value: 3, label: 'No localizado' },
          ],
          etiquetas: [todas, ...mis_segmentos],
        };

        setCatalogs(newCat);
        setCatSeccion(newCat.secciones);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeGlobal = (localValue, name) => {
    let clear = {};

    switch (name) {
      case 'idMunicipio':
        clear = { idSeccion: 0 };
        let newSeccion;
        if (localValue > 0) {
          newSeccion = [
            { value: 0, label: 'TODAS' },
            ...catalogs.secciones.filter((item) => item.idMunicipioReportes === localValue),
          ];
        } else newSeccion = catalogs.secciones;

        setCatSeccion(newSeccion);
        break;

      default:
        break;
    }

    setFilter({ ...filter, ...clear, [name]: localValue });
  };

  const onFilter = () => {
    const { celular, nombre, idMunicipio, idSeccion, idEstatus, idEtiqueta } = filter;

    let filtro = [];

    if (celular.length > 0) {
      filtro.push({
        id: 'CONCAT(cat_seccion.Celular)',
        filter: 'LIKE',
        value: `${celular}`,
      });
    }
    if (nombre.length > 0) {
      filtro.push({
        id: 'CONCAT(cat_seccion.Nombre, cat_seccion.Paterno, cat_seccion.Materno)',
        filter: 'LIKE',
        value: `%${nombre.toUpperCase().replace(/ /g, '%')}%`,
      });
    }
    if (idMunicipio > 0) {
      filtro.push({
        id: 'cat_seccion.idMunicipio',
        filter: '=',
        value: `${idMunicipio}`,
      });
    }
    if (idSeccion > 0) {
      filtro.push({
        id: 'cat_seccion.Seccion',
        filter: '=',
        value: `${idSeccion}`,
      });
    }
    if (idEstatus > 0) {
      filtro.push({
        id: 'cat_seccion.idEstatusContactoMovilizacion',
        filter: '=',
        value: idEstatus,
      });
    }
    if (idEtiqueta > 0) {
      filtro.push({
        id: 'cat_seccion.idEstructuraSocial',
        filter: '=',
        value: idEtiqueta,
      });
    }

    handleFilter(filtro);
  };

  const onClear = () => {
    setFilter({
      nombre: '',
      celular: '',
      idMunicipio: 0,
      idSeccion: 0,
      idEstatus: 0,
      idEtiqueta: 0,
    });

    handleFilter([]);
  };

  return (
    <FilterCollapse>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Nombre"
            name="nombre"
            value={filter.nombre}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Celular"
            name="celular"
            value={filter.celular}
            onChange={(e) => {
              if (isTypePhone(e.target.value)) handleChangeGlobal(e.target.value, e.target.name);
            }}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <AdvancedSelect
            label="Municipio"
            name="idMunicipio"
            id="idMunicipio"
            options={catalogs.municipios}
            value={filter.idMunicipio}
            onChange={(e) => handleChangeGlobal(e.value, e.name)}
            isLoading={isLoading}
            isSearchable
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <AdvancedSelect
            label="Sección"
            name="idSeccion"
            id="idSeccion"
            options={catSeccion}
            value={filter.idSeccion}
            disabled={catSeccion.length === 0}
            onChange={(e) => handleChangeGlobal(e.value, e.name)}
            isLoading={isLoading}
            isSearchable
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <BasicSelect
            label="Estatus"
            name="idEstatus"
            id="idEstatus"
            options={catalogs.estatus}
            value={filter.idEstatus}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            isLoading={isLoading}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <BasicSelect
            label="Etiqueta"
            name="idEtiqueta"
            id="idEtiqueta"
            options={catalogs.etiquetas}
            value={filter.idEtiqueta}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            isLoading={isLoading}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            sx={{ paddingX: { xs: 5, sm: 'auto' } }}
            onClick={onClear}
            disabled={isLoading}
            size="small"
            color="primaryDark"
          >
            Limpiar
          </Button>
          <Button
            onClick={onFilter}
            variant="contained"
            sx={{ paddingX: { xs: 5, sm: 'auto' } }}
            disabled={isLoading}
            size="small"
            color="primaryDark"
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>
    </FilterCollapse>
  );
};

export default TableScroll;
