const VolunteerInfoInterface = {
  Nombre: '',
  Paterno: '',
  Materno: '',
  INE: '',
  Celular: '',
  Region: '',
  Municipio: '',
  Poligono: '',
  SeccionVota: '',
  ColoniaVive: '',
  CalleVive: '',
  NumExtVive: '',
  NumIntVive: null,
  CPVive: '',
};

const AddResponsibilityInterface = {
  idUsurioAutorizo: '',
  idCompromisoUnico: '',
  idPerfil: '',
  idAmbitoCargo: '',
  AmbitoValorCargo: '',
  ResponsabilidadEtiqueta: '',
  Prioridad: '',
  idPropietario: '',
  idMunicipio: '',
};

export { AddResponsibilityInterface, VolunteerInfoInterface };
