import { PrivilegedRoute, ProtectedRoute } from '@middlewares';

import AsignacionFolioBingo from '@pages/Bingos/BingoFolio';
import FolioUpdate from '@pages/Bingos/BingoCorrection';
import EstadisticasBingo from '@pages/Bingos/EstadisticasBingo';
import Bingo from '@pages/Bingos/Bingo';
import Movilizacion from '@pages/Movilizacion/CompromisosMovilizacion';
import BingoCapturistList from '@pages/Bingos/BingoCapturistList';
import CompromisoSinFolio from '@pages/Bingos/CompromisosSinFolio';
import BingosPostEleccion from '../../pages/Bingos/BingosPostEleccion';

const BingoRoutes = [
  {
    path: '/asignacion-folio-bingo',
    element: (
      <PrivilegedRoute>
        <AsignacionFolioBingo />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/correccion-folio-bingo',
    element: (
      <PrivilegedRoute>
        <FolioUpdate />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/estadisticas-folios-bingo',
    element: (
      <PrivilegedRoute>
        <EstadisticasBingo />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/captura-folio-bingo',
    element: (
      <PrivilegedRoute>
        <Bingo />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/captura-bingo',
    element: (
      <PrivilegedRoute>
        <Movilizacion />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/consulta-usuario',
    element: (
      <PrivilegedRoute>
        <BingoCapturistList />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/compromisos-sin-folio',
    element: (
      <PrivilegedRoute>
        <CompromisoSinFolio />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/bingos-post-eleccion',
    element: (
      <ProtectedRoute>
        <BingosPostEleccion />
      </ProtectedRoute>
    ),
  },
];

export default BingoRoutes;
