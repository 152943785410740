import ApiExec, { downloadFile, uploadFiles } from '@utils/ApiExec';
//import { encryptVars, DEBUG } from "@utils/global";

class SijeService {
  getListSije = (params) => {
    return ApiExec(params, 'sije/get-list-sije', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getCatalogs = (params) => {
    return ApiExec(params, 'sije/get-catalogs-sije', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getTotalesCards = (params) => {
    return ApiExec(params, 'sije/get-totales', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getChartPaseListaRcs = (params) => {
    return ApiExec(params, 'sije/get-chart-pase-lista', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getChartAperturaCierreSije = (params) => {
    return ApiExec(params, 'sije/get-chart-apertura-cierre-sije', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getChartPaseListaRG = (params) => {
    return ApiExec(params, 'sije/get-chart-pase-lista-rg', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getCasillasBySeccion = (params) => {
    return ApiExec(params, 'sije/get-casillas-by-seccion', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getRepresentantesCasillas = (params) => {
    return ApiExec(params, 'sije/get-representantes-casillas', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getCardAperturadasInstaladas = (params) => {
    return ApiExec(params, 'sije/get-card-aperturadas-instaladas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getListSijeApertura = (params) => {
    return ApiExec(params, 'sije/get-list-sije_apertura', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getListSijeCierre = (params) => {
    return ApiExec(params, 'sije/get-list-sije_cierre', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getListSijeIncidents = (params) => {
    return ApiExec(params, 'sije/get-list-sije-incidencias', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getCardCierreCasilla = (params) => {
    return ApiExec(params, 'sije/get-card-cierre-casilla', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getCardCierreVotacion = (params) => {
    return ApiExec(params, 'sije/get-card-cierre-votacion', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getListSijeAsistencia = (params) => {
    return ApiExec(params, 'sije/get-list-sije-asistencia', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getColumnasSijeAsistencia = (params) => {
    return ApiExec(params, 'sije/get-columnas-sije-asistencia', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getAmbitoAgrupadorSijeAsistencia = (params) => {
    return ApiExec(params, 'sije/get-ambito-agrupador-sije-asistencia', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getListSijeAgenda = (params) => {
    return ApiExec(params, 'sije/get-list-sije-agenda', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getSummary = (params) => {
    return ApiExec(params, 'prep/get-summary-for-sije', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getCasillasUsuario = (params) => {
    return ApiExec(params, 'sije/get-casillas-usuario', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getPicturesListSije = (params) => {
    return ApiExec(params, 'sije/get-pictures-list-sije', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getPicturesListAperturaSije = (params) => {
    return ApiExec(params, 'sije/get-pictures-list-apertura-sije', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getPicturesListCierreSije = (params) => {
    return ApiExec(params, 'sije/get-pictures-list-cierre-sije', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  validateUsername = (params) => {
    return ApiExec(params, 'sije/validate-username', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setPaseLista = (params) => {
    return ApiExec(params, 'sije/set-pase-lista', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setInstalacionCasillas = (params) => {
    return ApiExec(params, 'sije/set-instalacion-casillas', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setAperturaCasilla = (params) => {
    return ApiExec(params, 'sije/set-apertura-casillas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setCierreCasilla = (params) => {
    return ApiExec(params, 'sije/set-cierre-casillas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setCierreVotacion = (params) => {
    return ApiExec(params, 'sije/set-cierre-votacion', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setSijeIncidencias = (params) => {
    return ApiExec(params, 'sije/set-sije-incidencias', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setPaseListaMasivo = (params) => {
    return ApiExec(params, 'sije/set-pase-lista-masivo', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  exportSije = ({ params, api }) => {
    return downloadFile({
      api,
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  updatedSijeIncidencias = (params) => {
    return ApiExec(params, 'sije/update-sije-incidencias', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getCasillasRc = (params) => {
    return ApiExec(params, 'sije/get-casillas-rc', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getResponsibles = (params) => {
    return ApiExec(params, 'sije/get-responsibles', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getFuncionariosCasilla = (params) => {
    return ApiExec(params, 'sije/get-casillas-funcionarios', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  /* Mobile */
  setPaseListaMasivoMobile = (params) => {
    return uploadFiles(params, 'sije/set-pase-lista-masivo', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setInstalacionCasillasMobile = (params) => {
    return uploadFiles(params, 'sije/set-instalacion-casillas', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setAperturaCasillaMobile = (params) => {
    return uploadFiles(params, 'sije/set-apertura-casillas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setSijeIncidenciasMobile = (params) => {
    return uploadFiles(params, 'sije/set-sije-incidencias', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setCierreCasillaMobile = (params) => {
    return uploadFiles(params, 'sije/set-cierre-casillas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setCierreVotacionMobile = (params) => {
    return uploadFiles(params, 'sije/set-cierre-votacion', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setTransmision = (params) => {
    return uploadFiles(params, 'sije/set-transmision', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };
}
const sije = new SijeService();
export default sije;
