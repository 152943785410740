import { useState, useEffect } from 'react';

// Material UI
import { Grid, Button, Stack, Tabs, Tab } from '@mui/material';

// Componentes
import { TabPanel, a11yProps } from '@components/Maps/Varius/TabComponent';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import BasicSelect from '@components/Selects/BasicSelect';

// Servicios y Utils
import { setVars, getVars } from '@utils/global';
import { ELECCION_GOBERNADOR } from '@data/constants/Prep';
import '@components/Maps/Styles/TabPadding.css';

const Filter = (props) => {
  const {
    catalogs,
    loadingCatalogs,
    handleFilter,
    electionType = false,
    region = false,
    section = false,
    disableTabMunicipio = false,
    disableTabDFED = false,
    disableTabDLOC = false,
  } = props;

  const AMBITO_MPAL = 0;
  const AMBITO_DF = 1;
  const AMBITO_DL = 2;

  const [tabValue, setTabValue] = useState(getVars('valueTab', AMBITO_MPAL));

  const [catTipoEleccion, setCatTipoEleccion] = useState([]);
  const [catMunicipios, setCatMunicipios] = useState([{ value: 0, label: 'TODOS' }]);
  const [catSecciones, setCatSecciones] = useState([{ value: 0, label: 'TODAS' }]);

  const [filter, setFilter] = useState({
    idTipoEleccion: ELECCION_GOBERNADOR,
    idRegion: [],
    idMunicipio: [],
    idDF: [],
    idDL: [],
    Seccion: [],
    porAvance: 0,
  });

  const handleChange = (event, newValue) => {
    const shp = getVars('filteredShapeMap', { tab: null });

    if (shp.tab !== null && shp.tab === newValue) {
      const filterMap = getVars('filterSavedMap', {});
      setFilter(filterMap);
    } else {
      setFilter({
        idTipoEleccion: ELECCION_GOBERNADOR,
        idRegion: [],
        idMunicipio: [],
        idDF: [],
        idDL: [],
        Seccion: [],
        porAvance: 0,
      });
    }

    setVars('valueTab', newValue);
    setTabValue(newValue);
  };

  const getCatalogsWithStored = () => {
    const mapCatalogMunicipio = getVars('mapCatalogMunicipio', []);
    const mapCatalogSeccion = getVars('mapCatalogSeccion', []);
    const mapCatalogResponsabilidad = getVars('mapCatalogResponsabilidad', []);
    setCatMunicipios(mapCatalogMunicipio);
    if (section) setCatSecciones(mapCatalogSeccion);
    if (electionType) setCatTipoEleccion(mapCatalogResponsabilidad);
  };

  useEffect(() => {
    const filter_saved = getVars('filterSavedMap');
    if (filter_saved.idMunicipio !== '') {
      setFilter(filter_saved);
      getCatalogsWithStored();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const map = getVars('mapCatalogMunicipio', '');
    if (map === '' && catalogs?.municipios_reportes) {
      setCatMunicipios(catalogs.municipios_reportes);
      if (electionType) setCatTipoEleccion(catalogs?.tipo_eleccion);
    }
    // eslint-disable-next-line
  }, [catalogs]);

  const handleChangeGlobal = (localValue, name) => {
    const clear = { idMunicipio: [], idDF: [], idDL: [], Seccion: [], porAvance: 0 };

    switch (name) {
      case 'idTipoEleccion':
        setFilter({ ...filter, [name]: localValue, ...clear });
        break;
      case 'idRegion':
        if (localValue.includes(0) && localValue.length > 1) {
          if (localValue.indexOf(0) > 0) localValue = [0];
          else localValue = localValue.filter((value) => value !== 0);
        }
        const newCat = catalogs.municipios_reportes.filter(
          (item) => localValue.includes(item.idRegion) || item.value === 0
        );
        setCatMunicipios(newCat.length === 1 ? catalogs.municipios_reportes : newCat);
        setFilter({ ...filter, [name]: localValue, ...clear });
        break;
      case 'Seccion':
      case 'porAvance':
        setFilter({ ...filter, [name]: localValue });
        break;
      default: // idMunicipio, idDF y idDL
        if (localValue.includes(0) && localValue.length > 1) {
          if (localValue.indexOf(0) > 0) localValue = [0];
          else localValue = localValue.filter((value) => value !== 0);
        }

        let id = 'idMunicipioReportes';
        if (tabValue === AMBITO_DF) id = 'idDF';
        if (tabValue === AMBITO_DL) id = 'idDL';

        const newCatSec = catalogs.secciones.filter(
          (item) => localValue.includes(item[id]) || item.value === 0
        );
        setCatSecciones(newCatSec);
        setFilter({ ...filter, ...clear, [name]: localValue });

        break;
    }
  };

  const valid = (value, noValid) => {
    if (noValid !== undefined && Array.isArray(value))
      return value !== '' && value !== undefined && value.length !== noValid;
    if (noValid !== undefined) return value !== '' && value !== undefined && value !== noValid;
    return value !== '' && value !== undefined;
  };

  const isIncluded = (values) => values.includes(tabValue);

  const handleClick = (filter_saved) => {
    let filter_data = [];
    let shp = 'entidad';

    const same = { id: 'tb.id', filter: 'IN' };

    if (valid(filter.idTipoEleccion, 0) && electionType) {
      filter_data.push({ id: 'tb2.idTipoEleccion', filter: '=', value: filter.idTipoEleccion });
    }

    if (valid(filter.Seccion, 0) && section) {
      if (filter.Seccion.includes(0)) {
        const catSec = catSecciones.map((item) => item.value).filter((item) => item);
        filter_data.push({ ...same, id: 'tb.Seccion', value: catSec });
      } else filter_data.push({ ...same, id: 'tb.Seccion', value: filter.Seccion });
      shp = 'seccion';
    } else if (valid(filter.idMunicipio, 0)) {
      if (!filter.idMunicipio.includes(0)) filter_data.push({ ...same, value: filter.idMunicipio });

      // Trae todos los municipios de esa región
      if (valid(filter.idRegion, 0) && filter.idMunicipio.includes(0)) {
        const ids = catMunicipios.map((item) => item.value).filter((value) => value !== 0);
        filter_data.push({ ...same, value: ids });
      }

      shp = 'municipio';
    } else if (valid(filter.idDF, 0)) {
      if (!filter.idDF.includes(0)) filter_data.push({ ...same, value: filter.idDF });
      shp = 'dfed';
    } else if (valid(filter.idDL, 0)) {
      if (!filter.idDL.includes(0)) filter_data.push({ ...same, value: filter.idDL });
      shp = 'dloc';
    } else if (valid(filter.idRegion, 0) && !valid(filter.idMunicipio, 0) && region) {
      if (!filter.idRegion.includes(0)) filter_data.push({ ...same, value: filter.idRegion });
      shp = 'region';
    }
    if (valid(filter.porAvance, 0)) {
      filter_data.push({ id: 'avance', filter: '=', value: catalogs?.avance[filter.porAvance]?.label });
    }

    handleFilter(filter_data, shp);
    setVars('mapCatalogMunicipio', catMunicipios);
    if (section) setVars('mapCatalogSeccion', catSecciones);
    if (electionType) setVars('mapCatalogResponsabilidad', catTipoEleccion);

    const responsabilidad = electionType ? { idTipoEleccion: filter.idTipoEleccion } : {};

    setVars('filterSavedMap', {
      ...responsabilidad,
      idRegion: filter.idRegion,
      idMunicipio: filter.idMunicipio,
      idDF: filter.idDF,
      idDL: filter.idDL,
      Seccion: filter.Seccion,
      porAvance: filter.porAvance,
    });
    setVars('filteredShapeMap', {
      filtered: filter_data,
      tab: tabValue,
      shp: shp,
    });
  };

  const invalidSection = () => {
    let data = {
      value: false,
      message: `Selecciona un ${tabValue === AMBITO_MPAL ? 'municipio' : 'distrito'}`,
    };

    switch (tabValue) {
      case AMBITO_MPAL:
        data.value = filter.idMunicipio.includes(0) || !filter.idMunicipio.length;
        break;
      case AMBITO_DF:
        data.value = filter.idDF.includes(0) || !filter.idDF.length;
        break;
      case AMBITO_DL:
        data.value = filter.idDL.includes(0) || !filter.idDL.length;
        break;
      default:
        break;
    }

    if (data.value === false) data.message = '';

    return data;
  };

  return (
    <>
      <Grid container rowSpacing={1.5}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="Tabs"
            variant="scrollable"
            scrollButtons={false}
          >
            {!disableTabMunicipio && <Tab label="Municipio" {...a11yProps(AMBITO_MPAL)} sx={{ flex: 1 }} />}
            {!disableTabDFED && <Tab label="DFED" {...a11yProps(AMBITO_DF)} sx={{ flex: 1 }} />}
            {!disableTabDLOC && <Tab label="DLOC" {...a11yProps(AMBITO_DL)} sx={{ flex: 1 }} />}
          </Tabs>
        </Grid>

        {electionType && (
          <Grid item xs={12}>
            <BasicSelect
              label="Tipo de elección"
              name="idTipoEleccion"
              id="idTipoEleccion"
              options={catTipoEleccion}
              value={filter.idTipoEleccion}
              onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
              isLoading={loadingCatalogs}
              size="small"
              sx={{ width: '100%' }}
            />
          </Grid>
        )}

        {isIncluded([AMBITO_MPAL]) && region && (
          <Grid item xs={12}>
            <AdvancedSelect
              label="Región"
              name="idRegion"
              id="idRegion"
              disabled={catalogs?.regiones?.length <= 1}
              options={catalogs?.regiones}
              value={filter.idRegion}
              onChange={(e) => handleChangeGlobal(e, 'idRegion')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TabPanel value={tabValue} index={AMBITO_MPAL}>
            <AdvancedSelect
              label="Municipio"
              name="idMunicipio"
              id="idMunicipio"
              disabled={catMunicipios.length <= 1}
              options={catMunicipios}
              value={filter.idMunicipio}
              onChange={(e) => handleChangeGlobal(e, 'idMunicipio')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
          <TabPanel value={tabValue} index={AMBITO_DF}>
            <AdvancedSelect
              label="DFED"
              name="idDF"
              id="idDF"
              disabled={catalogs?.municipios_df?.length <= 1}
              options={catalogs?.municipios_df}
              value={filter.idDF}
              onChange={(e) => handleChangeGlobal(e, 'idDF')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
          <TabPanel value={tabValue} index={AMBITO_DL}>
            <AdvancedSelect
              label="DLOC"
              name="idDL"
              id="idDL"
              disabled={catalogs?.municipios_dl?.length <= 1}
              options={catalogs?.municipios_dl}
              value={filter.idDL}
              onChange={(e) => handleChangeGlobal(e, 'idDL')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
        </Grid>

        {section && (
          <Grid item xs={12}>
            <AdvancedSelect
              label="Sección"
              name="Seccion"
              id="Seccion"
              disabled={catSecciones.length <= 1 || invalidSection().value}
              helperText={invalidSection().message}
              options={catSecciones}
              value={filter.Seccion}
              onChange={(e) => handleChangeGlobal(e, 'Seccion')}
              isLoading={loadingCatalogs}
              isSearchable
              multiple
            />
          </Grid>
        )}

        {/* <Grid item xs={12}>
          <AdvancedSelect
            label="Porcentaje de Avance"
            name="porAvance"
            id="porAvance"
            options={catalogs?.avance}
            value={filter.porAvance}
            onChange={(e) => handleChangeGlobal(e.value, e.name)}
            isLoading={loadingCatalogs}
          />
        </Grid> */}
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={loadingCatalogs}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;
