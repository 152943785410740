import { useState, useEffect } from 'react';
import { numberWithCommas } from '@utils/Utilities';

import {
  Card,
  CardContent,
  Container,
  Typography,
  Box,
  Radio,
  RadioGroup,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Chip,
  Button,
  ButtonGroup,
} from '@mui/material';

import PieChart from '@components/Charts/PieChart';
import Swal from 'sweetalert2';
import 'moment/locale/es';
import BasicLine from '@components/Charts/BasicLine';
import BasicBarChart from '../../components/Charts/BasicBar';

import AdminLayout from '@components/MainPage/AdminLayout';
import BasicTable from '@components/Tables/BasicTableSelectable';
import ActionHeader from '@components/Containers/ActionHeader';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import { isEmptyOrNullObject } from '@utils/validations';
import CardsPorcent from '../../components/Card/CardsPorcent';
import CardTreeColumn from '../../components/Card/CardTreeColumn';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
//import Filter from "../../components/FrenteAmplio/FilterStats";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MovingIcon from '@mui/icons-material/Moving';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Estadisticas = () => {
  //Constantes
  const colums = [
    {
      label: 'Municipio',
      id: 'Municipio',
      columnAction: false,
      orderBy: 'Municipio',
      width: 200,
    },
    {
      label: 'Meta',
      id: 'Meta',
      columnAction: false,
      width: 200,
      orderBy: 'Meta',
    },
    { label: 'Avance Sian', id: 'Registros', orderBy: 'Registros', columnAction: false, width: 200 },
    { label: 'Avance SIAN-FAM', id: 'FAM', orderBy: 'FAM', columnAction: false, width: 200 },
    { label: '% Avance', id: '%Avance', orderBy: '%Avance', columnAction: false, width: 350 },
    {
      label: 'Sin Registro FAM',
      id: 'PendienteFAM',
      orderBy: 'PendienteFAM',
      columnAction: false,
      width: 350,
    },
    {
      label: '% Sin Registro FAM',
      id: '%Pendiente',
      orderBy: '%Pendiente',
      columnAction: false,
      width: 200,
    },
  ];
  const COLUMN2 = [
    {
      label: 'Municipio',
      id: 'Municipio',
      orderBy: 'Municipio',
      columnAction: false,
      width: 200,
    },
    { label: 'Meta', id: 'Meta2', orderBy: 'Meta2', columnAction: false, width: 200 },
    { label: 'Avance Sian', id: 'Registros', orderBy: 'Registros', columnAction: false, width: 200 },
    { label: 'Avance SIAN-FAM', id: 'FAM', orderBy: 'FAM', columnAction: false, width: 200 },
    { label: '% Avance', id: '%Avance2', orderBy: '%Avance2', columnAction: false, width: 350 },
    {
      label: 'Sin Registro FAM',
      id: 'PendienteFAM',
      orderBy: 'PendienteFAM',
      columnAction: false,
      width: 350,
    },
    {
      label: '% Sin Registro FAM',
      id: '%Pendiente',
      orderBy: '%Pendiente',
      columnAction: false,
      width: 200,
    },
  ];
  const columsLiderazgo = [
    {
      label: 'Municipio',
      id: 'Municipio',
      columnAction: false,
      orderBy: 'Municipio',
      width: 200,
    },
    {
      label: 'Liderazgo',
      id: 'Liderazgo',
      columnAction: false,
      orderBy: 'Liderazgo',
      width: 200,
    },
    { label: 'Registros', id: 'Registros', orderBy: 'Registros', columnAction: false, width: 200 },
    {
      label: 'Avance SIAN-FAM',
      id: 'SIANFAM',
      orderBy: 'SIANFAM',
      columnAction: false,
      width: 200,
    },
    {
      label: 'Pendientes FAM',
      id: 'PendientesFAM',
      orderBy: 'PendientesFAM',
      columnAction: false,
      width: 200,
    },
    {
      label: 'Efectividad',
      id: 'pEfectividad',
      orderBy: 'pEfectividad',
      columnAction: false,
      width: 200,
    },
  ];
  const [data, setData] = useState({
    page: 0,
    pageSize: 999999999,
    filtered: [],
    sorted: [{ id: 'FechaCreo', value: 'asc' }],
  });
  const [dataCandidatos, setDataCandidatos] = useState({
    filtered: [],
    sorted: [],
  });
  const [flagEffect, setFlagEffect] = useState(false);
  const [value, setValue] = useState('1');
  const [valueMeta, setValueMeta] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTotales, setIsLoadingTotales] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [dataListLiderazgo, setDataListLiderazgo] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [dataPie, setDataPie] = useState({
    height: 190,
    distance: -50,
    options: {
      title: null,
      subtitle: null,
    },
    loading: false,
    pieColors: ['#36A2EB', '#FF5733'],
    series: [
      {
        name: 'Avances',
        colorByPoint: true,
        data: [
          {
            name: 'Avance',
            y: 30483,
            sliced: true,
            selected: true,
          },
          {
            name: 'Meta',
            y: 500000 - 30483,
            sliced: true,
            selected: true,
          },
        ],
      },
    ],
  });
  const [dataTotales, setDataTotales] = useState({
    totalMeta: 0,
    Registros: 0,
    percentRegistro: 0,
    Fam: 0,
    percentFam: 0,
    SinFam: 0,
    percentSinFam: 0,
  });
  const [loadingChart, setLoadingChart] = useState(false);
  const [dataDay, setDataDay] = useState({
    categories: [],
    series: [],
    drilldown: {},
    title: {
      primary: 'Captura',
    },
  });
  const [dataBarLine, setDataBarLine] = useState({
    title: '',
    subtitle: '',
    textX: 'Resgistros',
    categories: [],
    series: [
      {
        name: 'Sian',
        data: [631, 727, 3202, 721],
      },
      {
        name: 'Frente Amplio',
        data: [814, 841, 3714, 726],
      },
    ],
  });
  const [params, setParams] = useState({
    page: 0,
    pageSize: 999999999,
    filtered: [],
    sorted: [{ id: 'SF.FAM', value: 'desc' }],
  });
  const getList = (totalData, params) => {
    setIsLoading(true);
    setIsLoadingTotales(true);
    FrenteAmplioServices.getLitStats(params)
      .then((res) => {
        if (res.results) {
          let dataOrder = res.response.fam;
          if (totalData === 1) {
            getTotales(dataOrder);
          } else {
            getTotalesTwo(dataOrder);
          }

          const Commitments = dataOrder.map((item) => {
            return {
              ...item,
              Municipio: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.Municipio}
                </Typography>
              ),
              Registros: item.Registros ? item.Registros : 0,
              RegistrosSinFAM: item.RegistrosSinFAM ? item.RegistrosSinFAM : 0,
              // FAM:item.FAM ? item.FAM : 0,
              '%Pendiente': (
                <Chip
                  variant="outlined"
                  color={parseFloat(item['%Pendiente']) >= 1 ? 'error' : 'success'}
                  icon={
                    parseFloat(item['%Pendiente']) >= 1 ? <ReportGmailerrorredIcon /> : <CheckCircleIcon />
                  }
                  label={item['%Pendiente'] ? `${parseFloat(item['%Pendiente']).toFixed(2)}%` : `0%`}
                />
              ),
              //item["%Pendiente"] ? `${parseFloat(item["%Pendiente"]).toFixed(2)}%` : `0%`,
              // item["%Avance"] ? `${parseFloat(item["%Avance"]).toFixed(2)}%` : `0%`,
              '%Avance': item['%Avance'] ? `${parseFloat(item['%Avance']).toFixed(2)}%` : `0%`,
              FAM: (
                <Chip
                  variant="outlined"
                  color={
                    parseFloat(item.FAM) >= item.Meta
                      ? 'success'
                      : parseFloat(item.FAM) > item.Meta / 2
                        ? 'warning'
                        : 'error'
                  }
                  icon={
                    parseFloat(item.FAM) >= item.Meta ? (
                      <TaskAltIcon />
                    ) : item.FAM >= item.Meta / 2 ? (
                      <MovingIcon />
                    ) : (
                      <TrendingDownIcon />
                    )
                  }
                  label={item.FAM ? item.FAM : 0}
                />
              ),
            };
          });
          setDataList(Commitments);
          setDataLisCommitments(Commitments);
          setTotal(46);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
          setDataList([]);
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
        setDataList([]);
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingTotales(false);
      });
    // eslint-disable-next-line
  };

  const getListLiderazgos = (params) => {
    setIsLoading(true);
    FrenteAmplioServices.getListLiderazgoz(params)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              Liderazgo: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.Liderazgo}
                </Typography>
              ),
              /*  "pEfectividad": (
                <Chip
                  variant="outlined"
                  color={
                    parseFloat(item["pEfectividad"]) <= 90? "error" : "success"
                  }
                  icon={
                    parseFloat(item["pEfectividad"]) <= 50 ? (
                      <ReportGmailerrorredIcon />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  label={
                    item["pEfectividad"]
                      ? `${parseFloat(item["pEfectividad"]).toFixed(2)}%`
                      : `0%`
                  }
                />
              ), */
            };
          });
          setDataListLiderazgo(Commitments);
          setTotal(0);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
          setDataListLiderazgo([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
        setDataListLiderazgo([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  };

  function combineDataWithNull(fechas, obj) {
    const combinedData = fechas.map((fecha) => {
      const item = obj.find((item) => item.FechaCreo === fecha);

      if (item) {
        return item.Registros;
      } else {
        return null;
      }
    });

    return combinedData;
  }

  const getChartCap = (data) => {
    setLoadingChart(true);
    FrenteAmplioServices.getChartCaptura(data)
      .then((res) => {
        if (res.results) {
          let daysFam = res.response.fam.map((item) => {
            return item.FechaCreo;
          });
          let daysSian = res.response.sian.map((item) => {
            return item.FechaCreo;
          });

          const fechas = [...new Set([...daysFam, ...daysSian])];
          const obj1 = res.response.fam;
          const obj2 = res.response.sian;

          const seriesDataObj1 = combineDataWithNull(fechas, obj1);
          const seriesDataObj2 = combineDataWithNull(fechas, obj2);

          setDataDay({
            ...dataDay,
            categories: fechas,
            series: [
              { name: 'Sian', data: seriesDataObj2, color: '#319CF0' },
              { name: 'Frente Amplio', data: seriesDataObj1, color: '#E13980' },
            ],
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
      })
      .finally(() => setLoadingChart(false));
    // eslint-disable-next-line
  };

  const getChartCandidatos = (data) => {
    setLoadingChart(true);

    FrenteAmplioServices.getChartCandidatos(data)
      .then((res) => {
        if (res.results) {
          let categories = res.response.candidatos.map((item) => {
            return item.ASPIRANTE;
          });

          const combinedData = categories.map((can) => {
            const item = res.response.candidatos.find((item) => item.ASPIRANTE === can);

            if (item) {
              return item.RegistrosSIAN;
            } else {
              return null;
            }
          });

          const combinedDataFrente = categories.map((can) => {
            const item = res.response.candidatos.find((item) => item.ASPIRANTE === can);

            if (item) {
              return item.RegistrosFAM;
            } else {
              return null;
            }
          });

          setDataBarLine({
            ...dataBarLine,
            categories: categories,
            series: [
              {
                name: 'Sian',
                data: combinedData,
                color: '#319CF0',
              },
              {
                name: 'Frente Amplio',
                data: combinedDataFrente,
                color: '#E13980',
              },
            ],
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
      })
      .finally(() => setLoadingChart(false));
    // eslint-disable-next-line
  };

  const getTotales = (list) => {
    let Meta = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.Meta;
    }, 0);

    let Registros = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.Registros;
    }, 0);

    let percentRegistro = (Registros / Meta) * 100;

    let Fam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.FAM;
    }, 0);

    let percentFam = (Fam / Registros) * 100;

    let SinFam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.PendienteFAM;
    }, 0);

    let percentSinFam = (SinFam / Registros) * 100;

    setDataTotales({
      totalMeta: Meta,
      Registros: Registros,
      percenteAvance: ((Registros / Meta) * 100).toFixed(2),
      percentRegistro: percentRegistro,
      Fam: Fam,
      percentFam: percentFam,
      SinFam: SinFam,
      percentSinFam: percentSinFam,
    });

    setDataPie({
      ...dataPie,
      series: [
        {
          name: 'Avances',
          colorByPoint: true,
          data: [
            {
              name: 'Avance',
              y: Registros,
              sliced: true,
              selected: true,
            },
            {
              name: 'Meta',
              y: Meta - Registros,
              sliced: true,
              selected: true,
            },
          ],
        },
      ],
    });
  };

  const getTotalesTwo = (list) => {
    let Meta = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.Meta2;
    }, 0);

    let Registros = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.Registros;
    }, 0);

    let percentRegistro = (Registros / Meta) * 100;

    let Fam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.FAM;
    }, 0);

    let percentFam = (Fam / Registros) * 100;

    let SinFam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.PendienteFAM;
    }, 0);

    let percentSinFam = (SinFam / Registros) * 100;

    setDataTotales({
      totalMeta: Meta,
      Registros: Registros,
      percenteAvance: ((Registros / Meta) * 100).toFixed(2),
      percentRegistro: percentRegistro,
      Fam: Fam,
      percentFam: percentFam,
      SinFam: SinFam,
      percentSinFam: percentSinFam,
    });

    setDataPie({
      ...dataPie,
      series: [
        {
          name: 'Avances',
          colorByPoint: true,
          data: [
            {
              name: 'Avance',
              y: Registros,
              sliced: true,
              selected: true,
            },
            {
              name: 'Meta',
              y: Meta - Registros,
              sliced: true,
              selected: true,
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    if (value === '1') {
      let param = {
        page: 0,
        pageSize: 999999999,
        filtered: [],
        sorted: [{ id: 'SF.FAM', value: 'desc' }],
      };
      setParams(param);
      getList(valueMeta, param);
    } else {
      let param = {
        page: 0,
        pageSize: 999999999,
        filtered: [],
        sorted: [{ id: 'SIANFAM', value: 'desc' }],
      };
      setParams(param);
      getListLiderazgos(param);
    }
  }, [value]);

  useEffect(() => {
    if (value === '1') {
      getList(valueMeta, params);
    } else {
      getListLiderazgos(params);
    }
  }, [params, value]);

  useEffect(() => {
    getChartCap(data);
    getChartCandidatos(dataCandidatos);
    // eslint-disable-next-line
  }, [data, dataCandidatos, flagEffect]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };
      setParams(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);
  /* 
  const handleChangeFilter = (filtered) => {
    setIsLoading(true);
    let region = filtered.find((item) => item.id === "cat_region.id");
    let municipio = filtered.find((item) => item.id === "cat_municipios.id");
    let listFilter = dataList;
    if (region) {
      listFilter = listFilter.filter(
        (data) => parseInt(data.Region) === region.value
      );
    }
    if (municipio) {
      listFilter = listFilter.filter(
        (data) => data.idMunicipio === municipio.value
      );
    }
    setDataLisCommitments(listFilter);
    getTotales(listFilter);
    setDataCandidatos((prevState) => ({ ...prevState, filtered }));
    setData((prevState) => ({ ...prevState, filtered }));
  }; */

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: 'M.Municipio',
      FAM: 'SF.FAM',
      PendienteFAM: '( S.Registros - SF.FAM )',
      Correo: 'compromisos_unicos_fa.Correo',
      Meta: 'M.Meta',
      Meta2: 'M.Meta2',
      Registros: value === 1 ? 'S.Registros' : 'sum(L.SIAN)',
      '%Pendiente': '((( S.Registros - SF.FAM )/ S.Registros )*100)',
      '%Avance2': '( (SF.FAM / M.Meta2 )*100)',
      '%Avance': '( (SF.FAM / M.Meta )*100)',
      Liderazgo: 'L.Liderazgo',
      SIANFAM: 'SIANFAM',
      PendientesFAM: 'L.PendientesFAM',
      pEfectividad: 'format(100*(sum(L.SIANFAM)/sum(L.SIAN)),2)',
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    setSortedData(handleSorted(sortData));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setData({ ...data, filtered: [] });
    setDataCandidatos({ ...dataCandidatos, filtered: [] });
    setFlagEffect(!flagEffect);
    if (e.target.value === '1') {
      getList();
    } else {
    }
  };

  useEffect(() => {}, [value]);

  const dataMeta = (e) => {
    setValueMeta(e);
    getList(e);
  };

  const buttons = [
    <Button variant={valueMeta === 1 ? 'contained' : 'outlined'} key={1} onClick={(e, i) => dataMeta(1)}>
      Meta 1
    </Button>,
    <Button variant={valueMeta === 2 ? 'contained' : 'outlined'} key={2} onClick={(e, i) => dataMeta(2)}>
      Meta 2
    </Button>,
  ];

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Estadística Frente Amplio"
          isLink={false}
          isComponent={true}
          component={
            <ButtonGroup size="large" aria-label="large button group">
              {buttons}
            </ButtonGroup>
          }
        />
        <Box>
          {/*   <Filter onChangeFilter={(e) => handleChangeFilter(e)} />  */}
          <Grid2 container spacing={2} justifyContent={'center'}>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <CardsPorcent
                icon={'flag'}
                title={'Avance'}
                total={`${numberWithCommas(
                  dataTotales.Registros
                )} de ${numberWithCommas(dataTotales.totalMeta)}`}
                subIcon={'percent'}
                subtitle={dataTotales.percenteAvance}
                loading={isLoadingTotales}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ maxHeight: '180px' }}>
                <CardContent>
                  <PieChart {...dataPie} />
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 item xs={12} sm={12} md={12} lg={12}>
              <CardTreeColumn
                icon={'groups'}
                title={'Sian'}
                total={`${numberWithCommas(dataTotales.Registros)}`}
                subIcon={'percent'}
                subtitle={dataTotales.percentRegistro.toFixed(2)}
                icon2={'flag'}
                title2={'Avance Frente Amplio'}
                total2={`${numberWithCommas(dataTotales.Fam)}`}
                subIcon2={'percent'}
                subtitle2={dataTotales.percentFam.toFixed(2)}
                icon3={'playlist_remove'}
                title3={'Sin Registro en Frente Amplio'}
                total3={`${numberWithCommas(dataTotales.SinFam)}`}
                subIcon3={'percent'}
                subtitle3={dataTotales.percentSinFam.toFixed(2)}
                loading={isLoadingTotales}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardContent>
                  <BasicBarChart {...{ ...dataBarLine, loading: loadingChart }} />
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardContent>
                  <BasicLine {...{ ...dataDay, loading: loadingChart }} />
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>

          <Card className="card-primary">
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl>
                    <FormLabel id="show">Vista de Avance</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="show"
                      name="show-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={1} control={<Radio />} label="Municipios" />
                      <FormControlLabel value={2} control={<Radio />} label="Liderazgos" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <BasicTable
                rows={value === '1' ? dataLisCommitments : dataListLiderazgo}
                hcolumns={value === '1' ? (valueMeta === 1 ? colums : COLUMN2) : columsLiderazgo}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={false}
                showPagination={false}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Estadisticas;
