import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Tooltip, Chip, Stack, FormHelperText } from '@mui/material';
import prep from '@services/PrepServices';
import { isEmptyOrNullObject } from '@utils/validations';

import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicTableCustomizing from '@components/Tables/BasicTableCustomizing';

const FormSummaryTable = (props) => {
  const { filtered, handleOrder } = props;
  const columns = [
    { id: 'TipoEleccion', label: 'Tipo de Elección', align: 'left' },
    { id: 'Ambito', label: 'Ámbito de la Elección', align: 'left' },
    { id: 'NumCandidatos', label: 'Número de candidatos', align: 'center' },
    { id: 'NumPartidos', label: 'Número de partidos', align: 'center' },
  ];
  const navigate = useNavigate();

  const [loadingTable, setLoadingTable] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const [dataTable, setDataTable] = useState({
    data: [],
    total: 0,
  });
  const [paramsTable, setParamsTable] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
  });
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  useEffect(() => {
    if (filtered) {
      setParamsTable((prevState) => ({ ...prevState, filtered: filtered, page: 0 }));
      getTable({ ...paramsTable, filtered: filtered, page: 0 });
    }
    //eslint-disable-next-line
  }, [filtered]);

  useEffect(() => {
    if (sortedData.length > 0) {
      setParamsTable((prevState) => ({ ...prevState, sorted: sortedData }));
      getTable({ ...paramsTable, sorted: sortedData });
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const mapDataTable = (data) => {
    return data.map((item) => {
      const { NumCandidatos, NumPartidos, ...params } = item;
      item.NumCandidatos = (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Stack spacing={1}>
            <Tooltip title="Click para ver el listado de candidatos">
              <Chip
                label={!item.NumCandidatos ? 0 : item.NumCandidatos}
                color="primary"
                variant="outlined"
                onClick={(e) => navigate('/prep-candidatos', { state: params })}
              />
            </Tooltip>
            <FormHelperText>Ver Candidatos</FormHelperText>
          </Stack>
        </Box>
      );
      item.NumPartidos = (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Stack spacing={1}>
            <Tooltip title="Click para ver el listado de partidos y el orden de prelación">
              <Chip
                label={!item.NumPartidos ? 0 : item.NumPartidos}
                color="success"
                variant="outlined"
                onClick={(e) => handleOrder(item)}
              />
            </Tooltip>
            <FormHelperText>Ver Planilla</FormHelperText>
          </Stack>
        </Box>
      );
      return item;
    });
  };

  const getTable = (params) => {
    setLoadingTable(true);
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    prep
      .getFormSummary(params)
      .then((res) => {
        if (res.results) {
          setDataTable({
            data: mapDataTable(res.response.data),
            total: res.response.total,
          });
        } else {
          setDataTable({
            data: [],
            total: 0,
          });
        }
      })
      .catch((error) => {
        setDataTable({
          data: [],
          total: 0,
        });
      })
      .finally(() => {
        setLoadingTable(false);
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleChangePagination = (pagination) => {
    setParamsTable({ ...paramsTable, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      switch (dataSorted.orderBy) {
        case 'Ambito':
          sorted.push({
            id: 'prep_planillas.AmbitoValor',
            value: dataSorted.order,
          });
          break;
        case 'NumCandidatos':
          sorted.push({
            id: 'COUNT(DISTINCT prep_planillas.idCandidato)',
            value: dataSorted.order,
          });
          break;
        case 'NumPartidos':
          sorted.push({
            id: 'COUNT(DISTINCT prep_planillas.idPartido)',
            value: dataSorted.order,
          });
          break;
        default:
          sorted.push({
            id: dataSorted.orderBy,
            value: dataSorted.order,
          });
          break;
      }
    }
    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (dataTable.total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <Box component={'div'}>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={'Cargando...'}
      />

      <BasicTableCustomizing
        rows={dataTable.data}
        hcolumns={columns}
        pageProp={paramsTable.page}
        pageSize={paramsTable.pageSize}
        total={dataTable.total}
        handleChangePagination={handleChangePagination}
        isLoading={loadingTable}
        stickyHeader={true}
        handleManualSort={handleRequestSort}
      />
    </Box>
  );
};

export default FormSummaryTable;
