import React, { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import {
	Container,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
} from "@mui/material";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { getVars } from "@utils/global";
import middleware from "@middlewares/middleware";
import ActionHeader from "@components/Containers/ActionHeader";
import OficialiaServices from "@services/OficialiaServices";
import Swal from "sweetalert2";
import ButtonCancelarRecibir from "@components/Oficialia/ButtonCancelarRecibir";

import "./OficialiaPartes.css";

const OficiliaTransito = () => {
	const { user } = getVars("Token");
	const idUser = user.id;
	const navigate = useNavigate();
	const [oficios, setOficios] = useState([]);

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		getOficiosEnTransito();
	}, []);

	const handleRecibirClick = (item) => {
		Swal.fire({
			title: "¿Confirmar recepción del documento?",
			text: "¿Desea recibir este documento?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sí, recibir",
			cancelButtonText: "Cancelar",
			customClass: {
				popup: "swal2-custom", // Aplica la clase personalizada
			},
		}).then((result) => {
			if (result.isConfirmed) {
				btnConfirmar(item);
			}
		});

		document.querySelector(".swal2-popup").style.zIndex = 1000;
	};

	const handleCancelarClick = (item) => {
		Swal.fire({
			title: "¿Confirmar cancelar envío del documento?",
			text: `¿Desea cancelar el envío del documento: ${item.Asunto}?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sí, cancelar",
			cancelButtonText: "Cancelar",
			customClass: {
				popup: "swal2-custom", // Aplica la clase personalizada
			},
		}).then((result) => {
			if (result.isConfirmed) {
				btnCancelarTransferencia(item);
			}
		});

		document.querySelector(".swal2-popup").style.zIndex = 1000;
	};

	const btnConfirmar = async (item) => {
		console.log("=>Recibir item: ", item);

		const res = await OficialiaServices.aceptarOficiosEnTransito({
			idMovimiento: item.id,
			idOficio: item.idOficio,
		});
		console.log("=>btnAceptarTransferenciaRS: ", res);
		const { results, response, message } = res;

		if (results) {
			setOficios(oficios.filter((item) => item.id !== response.data[0].id));
			Swal.fire({ title: message, icon: "success" });
		} else {
			Swal.fire({ title: message, icon: "warning" });
		}
	};

	const btnCancelarTransferencia = async (item) => {
		console.log("=>item: ", item);
		const res = await OficialiaServices.cancelOficiosEnTransito({
			idMovimiento: item.id,
			idOficio: item.idOficio,
		});
		console.log("=>btnCancelarTransferenciaRS: ", res);
		const { results, response, message } = res;

		if (results) {
			setOficios(oficios.filter((item) => item.id !== response.data[0].id));
			Swal.fire({ title: message, icon: "success" });
		} else {
			Swal.fire({ title: message, icon: "warning" });
		}
	};

	const getOficiosEnTransito = async () => {
		try {
			setLoading(true);
			console.log(
				"=>getOficiosEnTransito: ",
				JSON.stringify({
					page: 0,
					pageSize: 99999,
					filtered: middleware.checkMenuActionId("admin")
						? [
								{
									id: "r.idEstatus",
									value: 1,
									filter: "=",
								},
						  ]
						: [
								{
									id: `r.idEstatus=1 and r.idUsuarioRecibe is null and ( r.idParaQuien = ${user.id} or r.idDeQuien = ${user.id})`,
									filterQuery: true,
								},
						  ],
				})
			);
			const res = await OficialiaServices.getOficiosEnTransito({
				page: 0,
				pageSize: 99999,
				filtered: middleware.checkMenuActionId("admin")
					? []
					: [
							{
								id: ` and r.idUsuarioRecibe is null and ( r.idParaQuien = ${user.id} or r.idDeQuien = ${user.id})`,
								filterQuery: true,
							},
					  ],
			});
			const { results, response, message } = res;

			console.log("=>getOficiosEnTransitores: ", res);

			if (results) {
				setOficios(response.data);
			} else {
				setOficios([]);
				Swal.fire({ title: message, icon: "warning" });
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({ title: error.message, icon: "warning" });
		}
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<ActionHeader
					title="Recepción de oficios"
					isAction={true}
					titleButton="Regresar"
					iconButton="arrow_back"
					handleclick={() => navigate(-1)}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div
							style={{
								padding: 20,
								backgroundColor: "white",
								maxWidth: 600,
								margin: "auto",
								marginTop: "10%",
							}}
						>
							<h2>Oficios en tránsito</h2>
							{isLoading ? (
								<CircularProgress />
							) : oficios.length ? (
								<List>
									{oficios.map((item) => (
										<ListItem key={item.idOficio}>
											<ListItemText
												primary={parse(
													`<strong>De:</strong> ${
														item.DeQuien
													} <strong>Para:</strong> ${
														item.ParaQuien
													}<br/> <strong>Oficio:</strong> ${
														item.NumOficio
															? `Num: ${item.NumOficio} ${item.Asunto}`
															: item.Asunto
													}`
												)}
												secondary={parse(
													`<strong>Fecha Recibido:</strong> ${item.FechaRecibido} |  <strong>Firma:</strong> ${item.NombreCompleto}  <strong>Cargo:</strong> ${item.Cargo}`
												)}
											/>
											{/* Simplificamos para ver si la comparación es correcta */}
											{console.log(
												"Comparando:",
												item.idParaQuien,
												"con",
												user.id
											)}
											<ButtonCancelarRecibir
												Origen={item.idParaQuien.toString()}
												Destino={idUser.toString()}
												btnIgual={handleRecibirClick}
												btnDiferente={handleCancelarClick}
												label={
													item.idParaQuien.toString() === idUser.toString()
														? "Recibir"
														: "Cancelar"
												}
												item={item}
											/>
										</ListItem>
									))}
								</List>
							) : (
								<Typography variant="caption" color="error">
									No hay oficios en tránsito
								</Typography>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		</AdminLayout>
	);
};
export default OficiliaTransito;
