import { useState, useEffect } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

// Material UI
import { TextField, FormControl, FormHelperText } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

// Componentes
import InputSelect from '@components/Selects/BasicSelect';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

// Validaciones
import { isEmptyOrInvalidString } from '@utils/validations';

const Form = (props) => {
  const { errors, touched, values, setFieldValue, handleChange, formPrev } = props;

  const animatedComponents = makeAnimated();

  const catalogsOptions = [{ id: 'tipo_perfil' }, { id: 'ambito' }];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [ambitoCargo, setAmbitoCargo] = useState([]);

  // Agrega los valores previos de AmbitoCargo con su fomato completo
  useEffect(() => {
    if (formPrev && !load) {
      const prevCargo = catalogs.ambito.filter((item) => values.AmbitoCargo.includes(item.value));
      setAmbitoCargo(prevCargo);
    }
    // eslint-disable-next-line
  }, [formPrev, load]);

  // Actualiza el campo 'AmbitoCargo' del estado local
  const handleAmbitoCargo = (selectedOption) => setAmbitoCargo(selectedOption);

  // Actualiza el campo 'AmbitoCargo' del Formik
  useEffect(() => {
    setFieldValue('AmbitoCargo', ambitoCargo);
    // eslint-disable-next-line
  }, [ambitoCargo]);

  return (
    <>
      <Grid2 xs={12} sm={6}>
        <InputSelect
          label="Tipo de perfil"
          name="TipoPerfil"
          sx={{ width: '100%' }}
          value={values.TipoPerfil}
          options={catalogs.tipo_perfil}
          onChange={handleChange}
          onBlur={handleChange}
          error={touched.TipoPerfil && !isEmptyOrInvalidString(errors.TipoPerfil)}
          helperText={errors.TipoPerfil}
          isLoading={load}
        />
        {values.TipoPerfil === '' && !errors.TipoPerfil && (
          <FormHelperText> Seleccione el tipo de perfil</FormHelperText>
        )}
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <FormControl
          sx={{ width: '100%' }}
          error={touched.AmbitoCargo && !isEmptyOrInvalidString(errors.AmbitoCargo)}
        >
          <Select
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            placeholder="Ambito de cargo"
            value={ambitoCargo}
            options={catalogs.ambito}
            onChange={handleAmbitoCargo}
            components={animatedComponents}
            closeMenuOnSelect={false}
            isLoading={load}
            isMulti
          />
          {errors.AmbitoCargo && <FormHelperText error>{errors.AmbitoCargo}</FormHelperText>}
        </FormControl>

        {values.AmbitoCargo === '' && !errors.AmbitoCargo && (
          <FormHelperText> Seleccione el ambito de cargo</FormHelperText>
        )}
      </Grid2>
      <Grid2 xs={12} md={6}>
        <TextField
          fullWidth
          label="Nombre del Perfil (sistema)"
          name="NombrePerfil"
          onChange={handleChange}
          onBlur={handleChange}
          value={values.NombrePerfil}
          variant="outlined"
          size="small"
          className="fixed-input"
          error={
            touched.NombrePerfil &&
            (!isEmptyOrInvalidString(errors.NombrePerfil) || values.NombrePerfil === '')
          }
          helperText={errors.NombrePerfil}
        />
      </Grid2>
      <Grid2 xs={12} md={6}>
        <TextField
          fullWidth
          label="Etiqueta del cargo"
          name="EtiquetaCargo"
          onChange={handleChange}
          onBlur={handleChange}
          value={values.EtiquetaCargo}
          variant="outlined"
          size="small"
          className="fixed-input"
          error={
            touched.EtiquetaCargo &&
            (!isEmptyOrInvalidString(errors.EtiquetaCargo) || values.EtiquetaCargo === '')
          }
          helperText={errors.EtiquetaCargo}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Descripción"
          name="Descripcion"
          value={values.Descripcion}
          sx={{ width: '100%' }}
          type="text"
          variant="outlined"
          size="small"
          className="fixed-input"
          onChange={handleChange}
          onBlur={handleChange}
          error={
            touched.Descripcion && (!isEmptyOrInvalidString(errors.Descripcion) || values.Descripcion === '')
          }
          helperText={errors.Descripcion}
        />
      </Grid2>
    </>
  );
};

export default Form;
