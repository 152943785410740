import { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';

// Material UI
import { Card, Grid, IconButton, Icon, Box, CardContent, Typography } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Form from '@components/Aportaciones/AportantesForm';
import Filter from '@components/Aportaciones/Filter';
import CustomModal from '@components/Modal/CustomModal';

// Servicios
import services from '@services/AportacionesServices';
import { COLUMNS_APORTANTES } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';
import { Swal, SwalDelete } from '@utils/alerts';

const AportantesView = (props) => {
  const { catalogs, isLoadingCat } = props;

  const canEdit = middleware.checkMenuAction('Editar');
  const canDelete = middleware.checkMenuAction('Eliminar');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getData(params); // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    try {
      setIsLoading(true);

      const result = await services.getAportantes(params);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 14 };

        const format = response.data.map((item) => ({
          ...item,
          Nombre: (
            <Typography sx={{ maxWidth: 200, ...style }}>
              {(item.Nombre + ' ' + item.Paterno + ' ' + (item.Materno || ''))?.trim()}
            </Typography>
          ),

          actions: (
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
              {canEdit && (
                <IconButton
                  aria-label="edit"
                  color="success"
                  onClick={() =>
                    handleEdit({
                      ...item,
                      FechaAltaRNM: item.FechaAltaRNM ? parseISO(item.FechaAltaRNM) : '',
                    })
                  }
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}
              {canDelete && (
                <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </Box>
          ),
        }));

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  const handleEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };

  const handleIsEdited = () => setParams((prev) => ({ ...prev, page: 0 }));

  const deleteConfirmation = async ({ Nombre, Paterno, Materno, id }) => {
    SwalDelete({
      title: 'Eliminar Aportante',
      text: `¿Estas seguro de querer eliminar el aportante: ${(
        Nombre +
        ' ' +
        Paterno +
        ' ' +
        (Materno || '')
      )?.trim()}?`,
      buttonText: 'Eliminar aportante',
      onDelete: () => handleDelete({ id }),
    });
  };

  const handleDelete = async ({ id }) => {
    try {
      setIsDeleting(true);

      const result = await services.deleteAportante({ id });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
        handleClose();
        getData(params);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      RNM: 'ca.RNM',
      Municipio: 'mun.Municipio',
      Nombre: 'ca.Nombre',
      Celular: 'ca.Celular',
      Correo: 'ca.Correo',
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      <LoadingForm
        loadinMessage={'Elimindo Aportante'}
        successMessage="Aportante eliminada con exito!"
        success={false}
        isLoading={isDeleting}
        isOpen={isDeleting}
        setIsOpen={() => {}}
      />

      <CustomModal open={open} onClose={handleClose} title="Editar Aportante" fullWidth disableClose>
        <Form
          type="edit"
          data={currentData}
          handleDelete={deleteConfirmation}
          handleClose={handleClose}
          handleIsEdited={handleIsEdited}
          catalogs={catalogs}
          isLoadingCat={isLoadingCat}
        />
      </CustomModal>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter
              handleFilter={handleFilter}
              catalogs={catalogs}
              isLoadingCat={isLoadingCat}
              flags={{ nombre: true, celular: true, cat_municipio: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_APORTANTES(canEdit || canDelete)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AportantesView;
