import React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { isEmptyOrInvalidArray, isNullOrUndefined } from '@utils/validations';
import CircularProgress from '@mui/material/CircularProgress';

export default function Tree(props) {
  const {
    data,
    loading,
    handleClick,
    typeData = { value: 'id', label: 'label', S_label: '', T_label: '' },
    isSameIds = false,
    sameId = '',
  } = props;

  const generateItemId = (node, path) => {
    return `${path}_${node[typeData.value]}`;
  };

  const renderTree = (nodes, path) => (
    <TreeItem
      key={generateItemId(nodes, path)}
      itemId={generateItemId(nodes, path)}
      label={`${nodes[typeData.T_label] ? `${nodes[typeData.T_label]} -` : ''} ${nodes[typeData.label]} ${nodes[typeData.S_label] ? nodes[typeData.S_label] : ''}`}
      onClick={() => handleClick(nodes)}
    >
      {!isEmptyOrInvalidArray(nodes.children) &&
        nodes.children.map((node, index) => renderTree(node, `${path}_${index}`))}
    </TreeItem>
  );

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <SimpleTreeView>{data.map((item, index) => renderTree(item, `${index}`))}</SimpleTreeView>
      )}
    </>
  );
}
