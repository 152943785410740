import React from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  // IconButton,
  Typography,
} from '@mui/material';
// import Icon from "@mui/material/Icon";

// import middleware from "@middlewares/middleware";

const UserInformation = (props) => {
  const { data, /* setFlagForm, */ height /* , setActionForm */ } = props;

  return (
    <Box sx={{ p: 2, overflow: 'auto' }}>
      <TableContainer sx={{ height: height, padding: 0, margin: 0 }}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6" color="darkblue">
                  {data.Nombre} {data.Paterno} {data.Materno}
                  {/* {middleware.checkMenuAction('Editar') && (
                    <IconButton
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={e => {
                        setFlagForm(true);
                        setActionForm("edit");
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )} */}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <strong>Email: </strong>
              </TableCell>
              <TableCell align="left">{data.Correo ? data.Correo : '--'}</TableCell>
            </TableRow>
            <TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <strong>Celular: </strong>
              </TableCell>
              <TableCell align="left">{data.Celular ? data.Celular : '--'}</TableCell>
            </TableRow>
            <TableRow key={6} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <strong>Estatus: </strong>
              </TableCell>
              <TableCell align="left">{data.Estatus}</TableCell>
            </TableRow>
            <TableRow key={7} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <strong>Último acceso: </strong>
              </TableCell>
              <TableCell align="left">{data.UltimoAcceso}</TableCell>
            </TableRow>
            <TableRow key={8} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <strong>Tipo de acceso: </strong>
              </TableCell>
              <TableCell align="left">{data.TipoAcceso}</TableCell>
            </TableRow>
            {/* <TableRow
              key={9}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">
                <strong>Registrador Por: </strong>
              </TableCell>
              <TableCell align="left">{data.RegistradoPor}</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserInformation;
