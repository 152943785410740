import { useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
//Mui
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Skeleton,
  Grid,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { blue, grey } from '@mui/material/colors';

//Components
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import AttendanceCard from '@components/Attendance/AttendanceCard';
import Chart from '@components/Prep/ChartColumComparison';
import PrepFilter from '@components/Prep/PrepFilter';
import PrepTable from '@components/Prep/PrepTable';
import CardInfo from '@components/Prep/CardInformative';
import DrawerRelative from '@components/Containers/DrawerRelative';
import FloatingButton from '@components/Button/FloatingButton';

import prep from '@services/PrepServices';
import { AMBITO_CASILLA, AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_ESTATAL } from '@data/constants';
import {
  ELECCION_DIPUTADO_FEDERAL,
  ELECCION_DIPUTADO_LOCAL,
  ELECCION_PRESIDENTE_MUNICIPAL,
} from '@data/constants/Prep';
import { numberWithCommas } from '@utils/Utilities';

const Prep = () => {
  const [series, setSeries] = useState([]);
  const [vista, setVista] = useState(1);
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [summaryData, setSummaryData] = useState({
    TipoEleccion: '',
    TotalActas: 0,
    ActasRecibidas: 0,
    TotalVotos: 0,
    LNom: 0,
    FechaHora: moment().format('DD/MM/YYYY HH:mm'),
    PActas: 0,
    Participacion: 0,
    Ambito: '',
  });
  const [tableTitle, setTableTitle] = useState('');
  const [params, setParams] = useState();

  const [openDrawer, setOpenDrawer] = useState(true);

  const handleChangeRadio = (event) => {
    setVista(event.target.value);
  };

  const handleChangeFilter = (filtered) => {
    if (!filtered.idTipoEleccion || !filtered.idAmbitoAgrupador) {
      return Swal.fire({
        icon: 'info',
        title: 'Parámetros insuficiente',
      });
    }

    let params = {
      ...filtered,
      ValorAmbito: filtered.ValorAmbito ? filtered.ValorAmbito : 0,
      idTipoVista: parseInt(vista),
    };

    setParams(params);

    const { idTipoVista, ...paramsSummary } = params;
    getSummaryTotals(paramsSummary);
    handleTableTitle(filtered.idAmbitoAgrupador);

    if (filtered.idAmbitoAgrupador === AMBITO_CASILLA) {
      if (filtered.ValorAmbito === 0) {
        switch (filtered.idTipoEleccion) {
          case ELECCION_DIPUTADO_FEDERAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DF,
            });
            break;
          case ELECCION_DIPUTADO_LOCAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_DL,
            });
            break;
          case ELECCION_PRESIDENTE_MUNICIPAL:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_MPAL,
            });
            break;
          default:
            getChart({
              ...params,
              idAmbitoAgrupador: AMBITO_ESTATAL,
            });
            break;
        }
      } else {
        getChart(params);
      }
    } else {
      getChart(params);
    }
  };

  const handleTableTitle = (idAmbitoAgrupador) => {
    switch (idAmbitoAgrupador) {
      case AMBITO_DF:
        setTableTitle('Mostrando detalle por distrito federal');
        break;
      case AMBITO_DL:
        setTableTitle('Mostrando detalle por distrito local');
        break;
      case AMBITO_MPAL:
        setTableTitle('Mostrando detalle por municipio');
        break;
      case AMBITO_CASILLA:
        setTableTitle('Mostrando detalle por casilla');
        break;
      default:
        setTableTitle('');
        break;
    }
  };

  const getChart = (params) => {
    setLoadingChart(true);
    setLoadingFilter(true);
    prep
      .getChart(params)
      .then((res) => {
        const { results, response, message } = res;
        if (results) {
          setSeries(response.series);
        } else {
          setSeries([]);
          Swal.fire({
            title: message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        setSeries([]);
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      })
      .finally(() => {
        setLoadingChart(false);
        setLoadingFilter(false);
      });
  };

  const getSummaryTotals = (params) => {
    setLoadingCards(true);
    setLoadingFilter(true);
    prep
      .getSummary(params)
      .then((res) => {
        const { results, response, message } = res;
        if (results) {
          setSummaryData(response);
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      })
      .finally(() => {
        setLoadingCards(false);
        setLoadingFilter(false);
      });
  };

  const handleRefresh = () => {
    // setSummaryData((prev) => ({ ...prev }))
    // setParams((prev) => ({ ...prev }))
    // getChart(params)
    // const { idTipoVista, ...paramsSummary } = params;
    // getSummaryTotals(paramsSummary);
    handleChangeFilter(params);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Programa de Resultados Electorales Preliminares (PREP)" />
        <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }, mt: 2 }}>
          <Grid item xs={12} md={openDrawer ? 9 : 12}>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={12} lg={4}>
                <Box component={Card} className="card-primary" height={'91%'}>
                  <Stack direction={'row'} spacing={1} justifyContent="center" marginTop={2}>
                    <Icon sx={{ color: grey[600] }}>newspaper</Icon>
                    <Typography marginTop={2} fontWeight={600} variant="h6">
                      Elección
                    </Typography>
                  </Stack>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Box component={CardContent}>
                      <Box sx={{ display: { xs: '', sm: 'flex' } }} alignItems="center" gap={2}>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                          {openDrawer ? (
                            <></>
                          ) : (
                            <Box
                              width={'90px'}
                              height={'90px'}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              margin={'10px'}
                              sx={{ mr: 5, backgroundColor: blue[50], borderRadius: '50%' }}
                            >
                              <Icon sx={{ fontSize: '3rem', color: blue[800] }}>description</Icon>
                            </Box>
                          )}
                        </Box>
                        <CardInfo summaryData={summaryData} handleClick={handleRefresh} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
              <Grid2 xs={12} md={6} lg={4}>
                <AttendanceCard
                  titlecontent={''}
                  data={{
                    contentData: summaryData.PActas,
                    leftFooterData: numberWithCommas(summaryData.ActasRecibidas),
                    rightFooterData: numberWithCommas(summaryData.TotalActas),
                  }}
                  config={{
                    titleHeader: 'Actas Recibidas',
                    leftFooterTitle: 'Recibidas',
                    rightFooterTitle: 'Total de Actas',
                    icon: 'description',
                  }}
                  loading={loadingCards}
                />
              </Grid2>
              <Grid2 xs={12} md={6} lg={4}>
                <AttendanceCard
                  titlecontent={''}
                  data={{
                    contentData: summaryData.Participacion,
                    leftFooterData: numberWithCommas(summaryData.TotalVotos),
                    rightFooterData: numberWithCommas(summaryData.LNom),
                  }}
                  config={{
                    titleHeader: 'Participación Ciudadana',
                    leftFooterTitle: 'Votantes',
                    rightFooterTitle: 'Listado Nominal',
                    icon: 'group',
                  }}
                  loading={loadingCards}
                />
              </Grid2>
            </Grid2>

            <Box component={Card} className="card-primary">
              <Box component={CardContent}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={vista}
                  onChange={handleChangeRadio}
                  sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Vista por candidatos"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Vista por partidos"
                    labelPlacement="end"
                  />
                </RadioGroup>

                {loadingChart ? (
                  <Skeleton animation="wave" variant="rounded" width={'100%'} height={400} />
                ) : (
                  <Chart
                    Title={summaryData.TipoEleccion}
                    Subtitle={summaryData.Ambito}
                    Series={series}
                    tipoVista={vista}
                    loading={loadingChart}
                    percent={true}
                    openDrawer={openDrawer}
                  />
                )}
              </Box>
            </Box>

            <PrepTable title={tableTitle} params={params} setLoadingFilter={setLoadingFilter} />
          </Grid>

          <Grid item xs={12} md={3}>
            <DrawerRelative
              anchor="right"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={'Filtrar'}
              icon="person_search"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <PrepFilter onChangeFilter={handleChangeFilter} disabledButtons={loadingFilter} />
            </DrawerRelative>
          </Grid>
        </Grid>

        {!openDrawer && <FloatingButton onClick={() => setOpenDrawer(true)} label="Mostrar Filtros" />}
      </Container>
    </AdminLayout>
  );
};

export default Prep;
