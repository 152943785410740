import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import {
  Grid,
  Button,
  Stack,
  Chip,
  Icon,
  TextField,
  Box,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { ScatterPlot } from '@mui/icons-material';

// Componentes
import CardInfoMini from '@components/Maps/InternalComponents/Cards/CardInfoMini';
import CasillasSelected from '@components/MapsModules/Routes/CasillasSelected';
import InputSelect from '@components/Selects/BasicSelect';

// Servicios
import RouteService from '@services/MapServices/RouteServices';

// Utilidades
import { isEmptyOrInvalidString } from '@utils/validations';

const elements = [
  { title: 'LNOM:', name: 'lnom', type: 'int' },
  { title: 'Casilla:', name: 't_casillas', type: 'int' },
  { title: 'T2:', name: 'tipo2', type: 'int' },
  { title: 'T3:', name: 'tipo3', type: 'int' },
  { title: 'T4:', name: 'tipo4', type: 'int' },
];

const Create = (props) => {
  const { formik, selectedProperties, handleSelectedCasillas } = props;

  const [allCasillas, setAllCasillas] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [optionsRG, setOptionsRG] = useState([]);
  const [optionsAbogado, setOptionsAbogado] = useState([]);

  const [properties, setProperties] = useState({
    lnom: 0,
    tipo2: 0,
    tipo3: 0,
    tipo4: 0,
    t_casillas: 0,
  });

  // Recargar el componente
  const [isReload, setIsReload] = useState(true);
  const [isInitial, setIsInitial] = useState(true);

  // Reinicia los valores de formik
  useEffect(() => {
    formik.handleReset();
    setIsInitial(false);
    // eslint-disable-next-line
  }, []);

  // Reinicia los valores de formik
  useEffect(() => {
    if (!isInitial) fetchCatalogs();
    // eslint-disable-next-line
  }, [isInitial]);

  // Al seleccionar o deseleccionar una seccion, esta agrega o quita sus casillas
  useEffect(() => {
    if (!isInitial) {
      setIsReload(true);

      const newAllCasillas = selectedProperties.reduce((result, objeto) => {
        const seccion = objeto.Seccion;
        const tipoSeccion = objeto.TipoSeccion;
        const casillasArray = objeto.casillas.map((casilla) => ({
          value: casilla.id,
          label: casilla.NombreCasilla,
          Lnom: casilla.Lnom,
          TipoSeccion: tipoSeccion,
          seccion: seccion,
        }));

        // Añade las casillas de la sección actual al objeto resultante
        result[seccion] = casillasArray;
        return result;
      }, {});

      // Se ponen todas las casillas en un solo array
      const oldCasillas = Object.values(allCasillas).flat();
      const newCasillas = Object.values(newAllCasillas).flat();

      // Verifica si se agrego un nuevo poligono, se elimino o no paso nada
      const action =
        oldCasillas.length === newCasillas.length
          ? 'none'
          : oldCasillas.length < newCasillas.length
            ? 'add'
            : 'delete';

      setAllCasillas(newAllCasillas);

      switch (action) {
        case 'add':
          // Encontrar nuevos datos agregados en newAllCasillas
          const addedCasillas = newCasillas.filter(
            (newCasilla) => !oldCasillas.some((oldCasilla) => oldCasilla.value === newCasilla.value)
          );
          const casillasID = addedCasillas.map((item) => item.value); // array de ids
          handleSelectedCasillas(casillasID, 'addAll', 'create');
          break;
        case 'delete':
          // Filtrar los prevValues que se encuentran en newAllCasillas
          const filteredPrevValues = formik.values.Casillas.filter((value) =>
            newCasillas.some((casilla) => casilla.value === value)
          );
          handleSelectedCasillas(filteredPrevValues, 'deleteAll', 'create');
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [selectedProperties]);

  // Recarga el componente, para mostrarlo ordenado
  useEffect(() => {
    if (!isInitial) {
      setIsReload(true);
      setTimeout(() => setIsReload(false), 500);
    }
    // eslint-disable-next-line
  }, [allCasillas]);

  // Recopila las Propiedades
  useEffect(() => {
    if (!isInitial && !isReload) {
      const casillasFlat = Object.values(allCasillas).flat();

      let lnom = 0;
      let tipo2 = 0;
      let tipo3 = 0;
      let tipo4 = 0;
      let t_casillas = 0;

      casillasFlat.forEach((item) => {
        if (formik.values.Casillas.includes(item.value)) {
          lnom = lnom + item.Lnom;
          t_casillas = formik.values.Casillas.length;
          if (item.TipoSeccion === '2') tipo2 = tipo2 + 1;
          if (item.TipoSeccion === '3') tipo3 = tipo3 + 1;
          if (item.TipoSeccion === '4') tipo4 = tipo4 + 1;
        }
      });
      setProperties({ ...properties, lnom, tipo2, tipo3, tipo4, t_casillas });
    }
    // eslint-disable-next-line
  }, [formik.values.Casillas, isReload]);

  // Pone el texto en Mayusculas
  const onChangeUpperCase = (e) => {
    e.target.value = e.target.value.toUpperCase();
    formik.handleChange(e);
  };

  // Carga los catalogos
  const fetchCatalogs = async (item) => {
    setIsLoading(true);
    setOptionsAbogado([]);
    setOptionsRG([]);

    try {
      const params = {
        page: 0,
        pageSize: 6000,
        filtered: [],
      };

      const result = await RouteService.getRGs(params);
      const result2 = await RouteService.getAbogados(params);
      const { results, response, message } = result;
      const { results: results2, response: response2, message: message2 } = result2;

      if (results && results2) {
        const sinAsignar = { value: 0, label: 'Sin asignar' };

        // Filtra que el RG no tenga ruta
        const dataRG = response.data
          .map((item) =>
            !item.idRuta
              ? {
                  value: item.id,
                  label: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                }
              : null
          )
          .filter(Boolean);

        const dataAbogados = response2.data.map((item) => ({
          value: item.id,
          label: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
        }));

        setOptionsRG([sinAsignar, ...dataRG]);
        setOptionsAbogado([sinAsignar, ...dataAbogados]);
      } else Swal.fire({ title: !results ? message : message2, icon: 'warning' });
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} style={{ width: '100%' }}>
        <Grid item xs={12}>
          <CardInfoMini properties={properties} elements={elements} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            type="text"
            name="Description"
            label="Descripción"
            placeholder="Breve descripción (opcional)"
            value={formik.values.Description}
            onChange={onChangeUpperCase}
            fullWidth
            size="small"
            variant="outlined"
            error={formik.touched.Description && !isEmptyOrInvalidString(formik.errors.Description)}
            helperText={formik.errors.Description}
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            required
            label="RG"
            name="idUsuarioRG"
            value={formik.values.idUsuarioRG}
            options={optionsRG}
            onChange={formik.handleChange}
            error={formik.touched.idUsuarioRG && !isEmptyOrInvalidString(formik.errors.idUsuarioRG)}
            errorMessage={formik.touched.idUsuarioRG && formik.errors.idUsuarioRG}
            isLoading={isLoading}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            required
            label="Abogado"
            name="idUsuarioAbogado"
            value={formik.values.idUsuarioAbogado}
            options={optionsAbogado}
            onChange={formik.handleChange}
            error={formik.touched.idUsuarioAbogado && !isEmptyOrInvalidString(formik.errors.idUsuarioAbogado)}
            errorMessage={formik.touched.idUsuarioAbogado && formik.errors.idUsuarioAbogado}
            isLoading={isLoading}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item sm={12}>
            <Chip
              icon={
                <Icon>
                  <ScatterPlot />
                </Icon>
              }
              label={'Secciones Seleccionadas: ' + selectedProperties.length}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {selectedProperties.length ? (
            !isReload ? (
              <>
                {selectedProperties
                  .sort((a, b) => a.Seccion - b.Seccion)
                  .map((item, index) => {
                    let selected = [];
                    if (allCasillas[item.Seccion] && formik.values.Casillas.length) {
                      const filteredCasillas = allCasillas[item.Seccion]
                        .filter((casilla) => formik.values.Casillas.includes(casilla.value))
                        .map((casilla) => casilla.value);

                      selected = filteredCasillas;
                    }

                    return (
                      <CasillasSelected
                        key={index}
                        seccion={item.Seccion}
                        allCasillas={allCasillas[item.Seccion] ?? []}
                        selectedCasillas={selected}
                        handleCasillas={handleSelectedCasillas}
                        type="create"
                      />
                    );
                  })}

                {formik.errors.Casillas && <FormHelperText error>{formik.errors.Casillas}</FormHelperText>}
              </>
            ) : (
              <Box
                height={selectedProperties.length * 48.9}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} paddingBottom={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={selectedProperties.length === 0 || formik.errors.Casillas ? true : false}
          onClick={formik.submitForm}
        >
          Guardar
        </Button>
      </Stack>
    </>
  );
};

export default Create;
