import { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import Map from '@components/Maps/CustomComponents/GenericMap';
import Filter from '@components/MapsModules/Compromisos/Filter';
import Colors from '@components/Maps/InternalComponents/Cards/Colors';
import TableInfo from '@components/Maps/InternalComponents/Cards/TableInfo';
import CardInfoMini from '@components/Maps/InternalComponents/Cards/CardInfoMini';

// Servicios y utilidades
import CompromisosService from '@services/MapServices/CompromisoServices';
import CatalogService from '@services/CatalogServices';
import { getVars, setVars, deleteVars } from '@utils/global';

// Estilos de mapas
import { style_original_av_struct } from '@components/Maps/Auxiliars/ColorFeature';

const commonElements = [
  { title: 'Meta:', name: 'MetaEstructura', type: 'int' },
  { title: 'Av Estructura:', name: 'AvanceEstructura', type: 'int' },
  { title: '% Av Estructura:', name: 'PorcentajeAvanceEstructura', type: 'percent' },
  { title: 'LNOM:', name: 'Lnom', type: 'int' },
];

const elements = {
  entidad: [{ title: 'Entidad', name: 'Entidad', type: 'text' }, ...commonElements],
  region: [{ title: 'Region', name: 'Region', type: 'text' }, ...commonElements],
  municipio: [{ title: 'Municipio', name: 'Municipio', type: 'text' }, ...commonElements],
  poligono: [{ title: 'Poligono', name: 'Poligono', type: 'text' }, ...commonElements],
  seccion: [
    { title: 'Seccion:', name: 'seccion', type: 'text' },
    { title: 'DLOC:', name: 'DLOC', type: 'text' },
    { title: 'DFED:', name: 'DFED', type: 'text' },
    ...commonElements,
  ],
};

const subElements = [
  { title: 'Manzana:', name: 'manzana', type: 'int' },
  { title: 'Seccion:', name: 'Seccion', type: 'text' },
  { title: 'Localidad:', name: 'Localidad', type: 'int' },
  { title: 'Prioridad:', name: 'Prioridad', type: 'int' },
];

const infoGeneralElements = [
  { title: 'Meta:', name: 'MetaEstructura', color: '#000', type: 'text' },
  { title: 'Av Estructura:', name: 'AvanceEstructura', color: '#000', type: 'text' },
  { title: '% Av Estructura:', name: 'PorcentajeAvanceEstructura', color: '#000', type: 'percent' },
  { title: 'Total Man. P1:', name: 'TotalP1', color: '#000', type: 'text' },
  { title: 'Total Man. P2:', name: 'TotalP2', color: '#000', type: 'text' },
  { title: 'Total Man. P3:', name: 'TotalP3', color: '#000', type: 'text' },
];

const colorManzana = [
  { title: 'Prioridad 1', color: '#B2EBF0' },
  { title: 'Prioridad 2', color: '#FDF8C0' },
  { title: 'Prioridad 3', color: '#D4D4D4' },
];

const Maps = () => {
  const { user } = getVars('Token');
  const [polygons, setPolygons] = useState({ type: 'FeatureCollection', features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const [table, setTable] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({ regiones: [], municipios: [] });
  const [shpInfoGeneral, setShpInfoGeneral] = useState('Region');

  const [colorPercent, setColorPercent] = useState([]);

  const getEmptyMap = () => {
    setTable(null);
    setCurrentFeature(null);
    setPolygonsBlocks(null);
    setPolygons({ type: 'FeatureCollection', features: [] });
  };

  const handleFilterExect = async (_filter, _shpMap) => {
    setVars('filterArraySavedMap', { filter: _filter, shp: _shpMap });
    let shpMap = _shpMap;

    // if (_filter.length === 0) shpMap = SelectShapeType();

    const data_parameters = { shp: shpMap, page: 0, pageSize: 10, filtered: _filter };

    setPolygons(null);
    setPolygonsBlocks(null);

    try {
      const result = await CompromisosService.getShapes(data_parameters);
      const { results, response, message } = result;

      if (results) {
        setTable(response.table);
        setPolygons(response.data);
      } else throw new Error(message);
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: 'warning' });
    }

    const prevFilter = _filter?.length ? JSON.parse(JSON.stringify(_filter)) : [];
    let _filterModified = prevFilter.slice(-1);

    const FMLength = _filterModified.length !== 0;

    switch (shpMap) {
      case 'entidad':
        shpMap = 'region';
        setShpInfoGeneral('Region');
        if (FMLength) _filterModified[0] = { id: 'tb2.idRegion', filter: 'IN', value: [1, 2, 3, 4, 5, 6, 7] };
        break;
      case 'region':
        setShpInfoGeneral('Region');
        if (FMLength) _filterModified[0].id = 'tb2.idRegion';
        break;
      case 'municipio':
        setShpInfoGeneral('Municipio');
        if (FMLength) _filterModified[0].id = 'tb2.idMunicipioReportes';
        break;
      case 'poligono':
        setShpInfoGeneral('Poligono');
        if (FMLength) _filterModified[0].id = 'tb2.idPoligono';
        break;
      case 'seccion':
        setShpInfoGeneral('Seccion');
        if (FMLength) _filterModified[0].id = 'tb2.Seccion';
        break;
      default:
        shpMap = 'region';
        setShpInfoGeneral('Region');
    }

    try {
      const params = { page: 0, pageSize: 10, filtered: _filterModified, shp: shpMap };

      const result = await CompromisosService.getInformationGeneral(params);
      const { results, response, message } = result;

      if (results && response.data.length !== 0) {
        setValuesInfoGeneral(response.data[0]);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  const loadCatalogs = useCallback(async () => {
    const catalogsParams = [
      { id: 'regiones', getAll: false },
      { id: 'municipios_reportes', getAll: false },
      { id: 'poligonos', getAll: false },
      { id: 'secciones', getAll: false },
    ];
    setIsLoadingCatalogs(true);

    try {
      const result = await CatalogService.getCatalogs(catalogsParams);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: 'Algunos catálogos NO pudieron ser cargados. Contacte al administrador',
            icon: 'warning',
          });
        }

        const RSRe = response.catalogs?.regiones;
        const RSMUN = response.catalogs?.municipios_reportes;
        const RSPo = response.catalogs?.poligonos;
        const RSSe = response.catalogs?.secciones;

        const todas = RSRe?.length === 1 ? RSRe : [{ value: 0, label: 'TODAS' }].concat(RSRe);
        const todos = RSMUN?.length === 1 ? RSMUN : [{ value: 0, label: 'TODOS' }].concat(RSMUN);
        const todosRSPo = RSPo?.length === 1 ? RSPo : [{ value: 0, label: 'TODOS' }].concat(RSPo);
        const todosRSSe = RSSe?.length === 1 ? RSSe : [{ value: 0, label: 'TODAS' }].concat(RSSe);

        const all = {
          regiones: RSRe ? todas : [],
          municipios: RSMUN ? todos : [],
          poligonos: RSPo ? todosRSPo : [],
          secciones: RSSe ? todosRSSe : [],
        };

        setCatalogs(all);
        setVars('allCatalogs', all);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingCatalogs(false);
    }
  }, []);

  const loadColors = async () => {
    try {
      const result = await CompromisosService.getRangosColores({});
      const { results, response, message } = result;

      if (results) {
        setColorPercent(response.data.local);
        setVars('allColors', response.data.local);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  const SelectShapeType = () => {
    const scope = user.ambito_perfil.filter((item) => item.idTipo === 2 || item.idTipo === 3);
    const ids_ambitos = scope.map((item) => item.idAmbito);
    const unique_ids_ambitos = [...new Set(ids_ambitos)];
    let shapeType = 'entidad';
    for (let index = 0; index < unique_ids_ambitos.length; index++) {
      const element = unique_ids_ambitos[index];
      switch (element) {
        case 1:
          shapeType = 'entidad';
          break;
        case 2:
          shapeType = 'region';
          break;
        case 5:
          shapeType = 'municipio';
          break;
        case 6:
          shapeType = 'municipio';
          break;
        case 7:
          shapeType = 'poligono';
          break;
        default:
          shapeType = 'seccion';
          break;
      }
    }
    return shapeType;
  };

  useEffect(() => {
    const advanceStructureMapFlag = getVars('advanceStructureMapFlag', '');

    if (advanceStructureMapFlag === '') {
      deleteVars();

      setVars('filterSavedMap', { idRegion: '', idMunicipio: '', idPoligono: '', idSeccion: '' });
      loadCatalogs();
      loadColors();
      getEmptyMap();
      setVars('advanceStructureMapFlag', true);
      const shape_type = SelectShapeType();
      handleFilterExect([], shape_type);
    } else {
      const data = getVars('allCatalogs', {});
      const colors = getVars('allColors', []);
      const filters = getVars('filterArraySavedMap', { filter: [], shp: 'entidad' });
      setCatalogs(data);
      setColorPercent(colors);
      handleFilterExect(filters?.filter, filters?.shp);
    }
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} loadingCatalogs={isLoadingCatalogs} handleFilter={handleFilterExect} />
      {currentFeature && (
        <CardInfoMini
          properties={currentFeature}
          elements={polygonsBlocks ? subElements : elements[polygons?.shp ?? 'seccion']}
          title={'INFORMACIÓN'}
          toolTip
        />
      )}
      {table && (
        <TableInfo
          properties={table}
          elements={polygonsBlocks ? subElements : elements[polygons?.shp ?? 'seccion']}
        />
      )}
    </>
  );

  const handleViewDetail = async (e) => {
    const { shp } = e;

    const data = {
      entidad: { key: 'idEntidad', id: 'idEntidad', next: 'region', catalog: 'regiones' },
      region: { key: 'id', id: 'idRegion', next: 'municipio', catalog: 'municipios' },
      municipio: { key: 'id', id: 'idMunicipioReportes', next: 'poligono', catalog: 'poligonos' },
      poligono: { key: 'Seccion', id: 'idPoligono', next: 'seccion', catalog: 'secciones' },
      seccion: { key: 'Seccion', id: 'idPoligono', next: 'seccion', catalog: 'secciones' },
    };

    const { key, id, next, catalog } = data[shp] ?? data.seccion;

    const params = {
      shp: next,
      page: 0,
      pageSize: 10,
      filtered: [],
    };
    let _filterModified = [];
    let filterCat = [];

    if (shp !== 'entidad' && shp !== 'seccion') {
      const data = catalogs[catalog].filter((item) => item[id] === e[id]);
      filterCat = data;
      const ids = data.map((item) => item.value);
      params.filtered.push({ id: `tb.${key}`, filter: 'IN', value: ids });
      _filterModified.push({ id: `tb.${key}`, filter: 'IN', value: ids });
    } else if (shp === 'seccion') {
      params.filtered.push({ id: `tb.Seccion`, filter: '=', value: e.Seccion });
      _filterModified.push({ id: `tb.Seccion`, filter: '=', value: e.Seccion });
    }

    // Actualizar Filtro
    let catSavedMap = getVars('catSavedMap', {});
    let catPoligonos = [];
    let catSecciones = [];

    let newFilter = getVars('filterSavedMap', {
      idRegion: [],
      idMunicipio: [],
      idPoligono: [],
      idSeccion: [],
    });

    switch (next) {
      case 'region':
        newFilter.idRegion = [0];
        setShpInfoGeneral('Region');
        _filterModified[0] = { id: 'tb2.idRegion', filter: 'IN', value: [1, 2, 3, 4, 5, 6, 7] };
        break;
      case 'municipio':
        catPoligonos = catalogs.poligonos.filter((item) => item.idRegion === e.idRegion || item.value === 0);
        catSecciones = catalogs.secciones.filter((item) => item.idRegion === e.idRegion || item.value === 0);

        catSavedMap = {
          municipios: [catalogs.municipios[0], ...filterCat],
          poligonos: catPoligonos,
          secciones: catSecciones,
        };

        newFilter.idRegion = [e.idRegion];
        newFilter.idMunicipio = [0];
        setShpInfoGeneral('Municipio');
        _filterModified[0].id = 'tb2.idMunicipioReportes';
        break;
      case 'poligono':
        catSecciones = catalogs.secciones.filter(
          (item) => item.idMunicipioReportes === e.idMunicipioReportes || item.value === 0
        );

        catSavedMap = {
          ...catSavedMap,
          poligonos: [catalogs.poligonos[0], ...filterCat],
          secciones: catSecciones,
        };

        newFilter.idMunicipio = [e.idMunicipioReportes];
        newFilter.idPoligono = [0];
        setShpInfoGeneral('Poligono');
        _filterModified[0].id = 'tb2.idPoligono';
        break;
      case 'seccion':
      default:
        if (shp === 'seccion') newFilter.idSeccion = [e.Seccion];
        else {
          newFilter.idPoligono = [e.idPoligono];
          newFilter.idSeccion = [0];
          catSavedMap = { ...catSavedMap, secciones: [catalogs.secciones[0], ...filterCat] };
        }

        setShpInfoGeneral('Seccion');
        _filterModified[0].id = 'tb2.Seccion';
    }

    setPolygons(null);
    setPolygonsBlocks(null);

    try {
      // Traer lo Bloques
      if (shp === 'seccion') {
        const result = await CompromisosService.getShapes(params);
        const resultBlocks = await CompromisosService.getBlocks(params);

        const { results, response, message } = result;
        const { results: resultsB, response: responseB, message: messageB } = resultBlocks;

        if (results && resultsB) {
          setVars('filterSavedMap', newFilter);
          setVars('catSavedMap', catSavedMap);
          setPolygonsBlocks(responseB.data);
          setTable(responseB.table);
          setPolygons(response.data);
        } else throw new Error(!results ? message : messageB);
      } else {
        const result = await CompromisosService.getShapes(params);
        const { results, response, message } = result;

        if (results) {
          setVars('filterSavedMap', newFilter);
          setVars('catSavedMap', catSavedMap);
          setTable(response.table);
          setPolygons(response.data);
        } else throw new Error(message);
      }
    } catch (e) {
      getEmptyMap();
      Swal.fire({ title: e.message, icon: 'warning' });
    }

    try {
      const params = { page: 0, pageSize: 10, filtered: _filterModified, shp: next };

      const result = await CompromisosService.getInformationGeneral(params);
      const { results, response, message } = result;

      if (results && response.data.length !== 0) {
        setValuesInfoGeneral(response.data[0]);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    }
  };

  const onEachFeature = (feature, layer, click, params) => {
    const style = { permanent: true, direction: 'center' };
    const properties = feature.properties;
    const { subData } = params;

    if (properties[properties.shp] !== undefined) {
      if (properties.shp === 'seccion' && subData?.shp === 'manzana') return true;

      if (properties.shp === 'manzana') {
        layer.bindTooltip(
          'M:' +
            properties.manzana +
            ' L' +
            properties.Localidad +
            (properties.Prioridad ? ' P' + properties.Prioridad : ''),
          style
        );
      }

      layer.setStyle(style_original_av_struct);
      layer.on({ click: click });
    }
  };

  const highlightFeature = (e, params) => {
    const {
      geoJsonBlocksRef,
      resetHighlight,
      L,
      selectedFeature,
      setSelectedFeature,
      selectedSubFeature,
      setSelectedSubFeature,
      setSubFeature,
      setFeature,
    } = params;

    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);

    setCurrentFeature(properties);

    if (geoJsonBlocksRef.current) {
      if (selectedSubFeature) resetHighlight(selectedSubFeature);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_av_struct(layer.feature, true));

      setSubFeature(layer.feature);
      setSelectedSubFeature(e);
      return true;
    }

    resetHighlight(e);

    setFeature(layer.feature);
    setSelectedFeature(e);

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
    layer.setStyle(style_original_av_struct(layer.feature, true));
  };

  const cardComponent = {
    component: (
      <Colors
        elements={polygonsBlocks ? colorManzana : colorPercent}
        height={polygonsBlocks ? 24 : 20}
        spacing={polygonsBlocks ? 1 : 0.5}
      />
    ),
    title: 'Colores',
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        subData={polygonsBlocks}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350 } }}
        cardComponent={cardComponent}
        cardInfo={{
          initialValues: {
            title: 'INFORMACIÓN',
            elements: polygonsBlocks ? subElements : elements[polygons?.shp ?? 'seccion'],
          },
          button: {
            handleClick: handleViewDetail,
            validShp: ['entidad', 'region', 'municipio', 'poligono', 'seccion'],
          },
        }}
        infoGeneral={{
          values: valuesInfoGeneral,
          initialValues: {
            title: 'RESUMEN',
            elements: [
              { title: 'center', name: shpInfoGeneral, color: '#000', type: 'text' },
              ...infoGeneralElements,
            ],
          },
          compromisos: 0,
        }}
        order={['subData', 'data']}
        styleProp={style_original_av_struct}
        highlightFeature={highlightFeature}
        onEachFeature={onEachFeature}
        centerByFeatures
      />
    </AdminLayout>
  );
};

export default Maps;
