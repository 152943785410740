import React, { useState } from 'react';
import {
  Checkbox,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const AdjuntosForm = ({
  fotosAdjuntos,
  setFotosAdjuntos,
  comentario,
  setComentario,
  tieneAdjuntos,
  setTieneAdjuntos,
  setValues,
}) => {
  const [imagenAbierta, setImagenAbierta] = useState(null);

  const handleCheckboxChange = (event) => {
    setTieneAdjuntos(event.target.checked);
  };

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  const handleFileChange = (event) => {
    const archivos = Array.from(event.target.files);
    const imagenesValidas = archivos.filter((archivo) => archivo.type.startsWith('image/'));

    if (imagenesValidas.length !== archivos.length) {
      alert('Solo se permiten archivos de imagen.');
      return;
    }

    // Crear un array con objetos que contienen url, name y mimetype
    const newFiles = imagenesValidas.map((archivo) => ({
      url: URL.createObjectURL(archivo),
      name: archivo.name,
      mimeType: archivo.type,
      size: archivo.size,
    }));

    // Combinar los archivos nuevos con los existentes
    console.log('=>newFiles: ', newFiles);
    setFotosAdjuntos(newFiles);
    setValues((prevValues) => ({
      ...prevValues,
      FotosAdjuntos: newFiles,
    }));
  };

  const eliminarFoto = (index) => {
    const newFiles = fotosAdjuntos.filter((_, i) => i !== index);
    setFotosAdjuntos(newFiles);
    setValues((prevValues) => ({
      ...prevValues,
      FotosAdjuntos: newFiles,
    }));
  };

  const abrirImagen = (imagen) => {
    setImagenAbierta(imagen);
  };

  const cerrarDialogo = () => {
    setImagenAbierta(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">¿Tiene adjuntos?</Typography>
        <Checkbox checked={tieneAdjuntos} onChange={handleCheckboxChange} color="primary" />
      </Grid>

      {tieneAdjuntos && (
        <>
          <Grid item xs={12}>
            <TextField
              label="Comentario de Adjuntos"
              multiline
              rows={4}
              value={comentario}
              onChange={handleComentarioChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Adjuntar Fotos
              <input type="file" accept="image/*" multiple hidden onChange={handleFileChange} />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              {fotosAdjuntos.map((foto, index) => (
                <Grid item xs={2} sm={2} md={2} key={index}>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: '8px',
                      width: '140px',
                    }}
                  >
                    <img
                      src={foto.url}
                      alt={`Foto ${index + 1}`}
                      style={{
                        width: '100px',
                        height: 'auto',
                        cursor: 'pointer',
                      }}
                      onClick={() => abrirImagen(foto.url)}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                      }}
                      onClick={() => eliminarFoto(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}

      <Dialog open={!!imagenAbierta} onClose={cerrarDialogo}>
        <DialogContent>
          <img src={imagenAbierta} alt="Imagen ampliada" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={cerrarDialogo}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdjuntosForm;
