import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardContent, Container, Typography, Box, Grid } from '@mui/material';

import Swal from 'sweetalert2';
import 'moment/locale/es';

import AdminLayout from '@components/MainPage/AdminLayout';
import BasicTable from '@components/Tables/BasicTable';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/FrenteAmplio/FilterCapturista.jsx';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import { isEmptyOrNullObject } from '@utils/validations';
import CardStats from '@components/Card/CardStats';

const ListCaptura = () => {
  //Constantes
  const colums = [
    {
      label: 'Municipio',
      id: 'Municipio',
      columnAction: false,
      width: 300,
      orderBy: 'Municipio',
    },
    { label: 'Modulo', id: 'Modulo', columnAction: false, width: 350, orderBy: false },
    { label: 'Celular', id: 'Celular', columnAction: false, width: 350, orderBy: false },
    { label: 'Capturo', id: 'Capturo', columnAction: false, width: 400, orderBy: 'Capturo' },
    { label: 'Avance SIAN', id: 'SIAN', columnAction: false, width: 20, orderBy: 'SIAN' },
    { label: 'Avance SIAN-FAM', id: 'SIAN-FAM', columnAction: false, width: 40, orderBy: 'SIAN-FAM' },
    { label: 'Pendientes FAM', id: 'PorSubirAFAM', columnAction: false, width: 40, orderBy: 'SIAN-FAM' },
  ];

  let navigate = useNavigate();
  const [data, setData] = useState({
    page: 0,
    pageSize: 99999999999999,
    filtered: [],
    sorted: [{ id: 'SF.SIAN_FAM', value: 'desc' }],
  });

  const [dataTotales, setDataTotales] = useState({
    SIAN: 0,
    'SIAN-FAM': 0,
    Pendientes: 0,
  });

  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const getList = useCallback((data) => {
    setIsLoading(true);
    FrenteAmplioServices.getCapturistas(data)
      .then((res) => {
        if (res.results) {
          getTotales(res.response.data);
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              Capturo: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.Capturo}
                </Typography>
              ),
              SIAN: item.SIAN !== null ? item.SIAN : 0,
              'SIAN-FAM': item['SIAN-FAM'] !== null ? item['SIAN-FAM'] : 0,
              PorSubirAFAM: item.PorSubirAFAM !== null ? item.PorSubirAFAM : 0,
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    setData((prevState) => ({ ...prevState, page: 0, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: 'R.Municipio',
      NombreCompleto: 'R.Capturo',
      Modulo: 'Md.Modulo',
      SIAN: 'S.SIAN',
      'SIAN-FAM': 'SF.SIAN_FAM',
      PorSubirAFAM: '(S.SIAN- SF.SIAN_FAM)',
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const getTotales = (list) => {
    let Registros = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.SIAN;
    }, 0);

    let Fam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto['SIAN-FAM'];
    }, 0);

    let SinFam = list.reduce((acumulador, objeto) => {
      return acumulador + objeto.PorSubirAFAM;
    }, 0);

    setDataTotales({
      SIAN: Registros,
      'SIAN-FAM': Fam,
      Pendientes: SinFam,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Listado Avance Frente Amplio"
          isLink={true}
          titleLink="Ir a la Página Frente Amplio"
          link="https://frenteampliopormexico.org.mx/RegistroUniversal"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'groups'}
              title={'SIAN'}
              total={dataTotales.SIAN}
              subIcon={'sync'}
              subtitle={'Actualizado'}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'diversity_1'}
              title={'SIAN-FAM'}
              total={dataTotales['SIAN-FAM']}
              subIcon={'sync'}
              subtitle={'Actualizado'}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'format_list_bulleted_add'}
              title={'Pendientes'}
              total={dataTotales.Pendientes}
              subIcon={'sync'}
              subtitle={'Actualizado'}
              loading={isLoading}
            />
          </Grid>
        </Grid>
        <Box>
          <Filter onChangeFilter={(e) => handleChangeFilter(e)} correo={false} municipio={true} />
          <Card className="card-primary">
            <CardContent>
              <BasicTable
                rows={dataLisCommitments}
                hcolumns={colums}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default ListCaptura;
