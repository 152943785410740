import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//MUI
import { Container, Card, Box, Alert, Badge } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Filter from '@components/Activation/CompromisosFolio/Filter';
import CustomTable from '@components/Tables/CustomTable';

import MovServices from '@services/MovilizationServices';

const CompromisosMovilizacion = () => {
  const navigate = useNavigate();

  const [isLoadigForm, setIsLoadingForm] = useState(false);

  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [order, setOrder] = useState('');
  const [summary, setSummary] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  const baseColumns = [
    { id: 'idRegion', label: 'Región', align: 'right' },
    { id: 'idDF', label: 'DF', align: 'right' },
    { id: 'idDL', label: 'DL', align: 'right' },
    // { id: "idMunicipio", label: "idMunicipio", align: "right" },
    { id: 'Municipio', label: 'Municipio', align: 'right' },
    // { id: "idMunicipioReportes", label: "Municipio Reportes", align: "right" },
    { id: 'Seccion', label: 'Sección', align: 'right' },
    { id: 'TotalCuadernillos', label: 'Total Cuadernillos', align: 'right' },
    { id: 'CuadernillosRevisados', label: 'Cuadernillos Revisados', align: 'right' },
    {
      id: 'PorcentajeAvance',
      label: '% Avance de Cuadernillos Revisados',
      align: 'center',
      type: 'circular',
    },
    { id: 'TotalCompromisos', label: 'Total de Compromisos', align: 'right' },
    { id: 'FoliosAsignados', label: 'Folios Asignados', align: 'right' },
    { id: 'FoliosUnicosAsignados', label: 'Folios Únicos Asignados', align: 'right' },
    { id: 'CompromisosSinFolio', label: 'Compromisos sin Folio', align: 'right' },
  ];

  const handleChangePagination = (pagination) => {
    setPage(pagination.page);
    setPageSize(pagination.pageSize);
  };

  const handleRequestSort = (e) => {
    setOrderBy([{ id: e.orderBy, value: e.order }]);
    setOrder(e.orderBy);
  };

  const handleReload = () => {
    setFiltered([]);
    setOrderBy([]);
    setOrder('');
  };

  const getStatesNotFound = async () => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      let params = {
        page: page,
        pageSize: pageSize,
        filtered: filtered,
        sorted: orderBy,
      };
      const result = await MovServices.getStatesNotFound(params);
      const { results, message, response } = result;
      if (results) {
        const transformData = response.data.map((item) => ({
          ...item,
          CompromisosSinFolio: (
            <Box
              component={'a'}
              onClick={() => {
                navigate('/correccion-folio-bingo', { state: item.Seccion });
              }}
              sx={{ cursor: 'pointer', ml: 2, mr: 2 }}
            >
              <Badge badgeContent={item.CompromisosSinFolio} color={'warning'} max={9999} />
            </Box>
          ),
        }));
        setData(transformData);
        setTotal(response.total);
        let summaryValues = {};
        baseColumns.forEach((col) => {
          summaryValues[col.id] = response.totales[col.id];
        });

        const array_values = ['TOTALES', ...Object.values(summaryValues).splice(1)];
        setSummary(array_values);
      } else {
        setData([]);
        setTotal(0);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  useEffect(() => {
    if (page === 0 && orderBy.length === 0) {
      getStatesNotFound();
    } else {
      setPage(0);
      setOrderBy([]);
      setOrder('');
    }
  }, [filtered]);

  useEffect(() => {
    getStatesNotFound();
    //eslint-disable-next-line
  }, [page, pageSize, orderBy]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'Compromisos sin Folio'} />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />

        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={12}>
            {showMessage && (
              <Alert
                severity="info"
                sx={{ mb: 2 }}
                onClose={() => {
                  setShowMessage(false);
                }}
              >
                En esta pantalla se muestra por cada una de las secciones en las cuales se han realizado
                revisiones de cuadernillos, la cantidad de compromisos que no pudieron ser localizados.
                <br />
                Esto puede deberse a diferentes motivos:
                <ol>
                  <li>Error humano al momento de revisar el cuadernillo</li>
                  <li>Error de captura al ingresar los datos del compromiso</li>
                  <li>La sección con la que se ingresó el compromiso fue dividida en otras secciones</li>
                  <li>El compromiso se ingresó posterior a la fecha de revisión al cuadernillo</li>
                </ol>
              </Alert>
            )}
          </Grid2>
          <Grid2 item xs={12} md={12}>
            <Filter filtered={setFiltered} reset={handleReload} />
          </Grid2>
          <Grid2 item xs={12} md={12}>
            <Box component={Card} variant="outlined" borderRadius={2} padding={2} elevation={0}>
              <CustomTable
                rows={data}
                columns={baseColumns}
                total={total}
                page={page}
                pageSize={pageSize}
                orderBy={order}
                isLoading={isLoadigForm}
                stickyHeader={true}
                handlePagination={handleChangePagination}
                handleSort={handleRequestSort}
                summary={summary}
                summarySettings={{ start: 90, align: 'right' }}
                maxHeight={600}
                disableCardType
              />
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default CompromisosMovilizacion;
