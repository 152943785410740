import { ProtectedRoute, PrivilegedRoute } from '@middlewares';
import Assignments from '@pages/DiaD/Assignments';
import AssignMRT from '@pages/DiaD/AssignMRT';
import DashboardMRT from '@pages/DiaD/DashboardMRT';
import AssignRutas from '@pages/DiaD/AssignRutas';
import RoutesMRT from '@pages/DiaD/RoutesMRT';
import WelcomeDiaD from '@pages/MobileDiaD/WelcomeDiaD';
import AssignPerfil from '@pages/DiaD/AssignPerfil';

const DiaDRoutes = [
  {
    path: '/dia-d/asignacion-usuarios',
    element: (
      <PrivilegedRoute>
        <Assignments />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/asignacion-diad-ruta',
    element: (
      <PrivilegedRoute>
        <AssignRutas />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/asignacion-diad-mrt',
    element: (
      <ProtectedRoute>
        <AssignMRT />
      </ProtectedRoute>
    ),
  },
  {
    path: '/dashboard-diad-mrt',
    element: (
      <ProtectedRoute>
        <DashboardMRT />
      </ProtectedRoute>
    ),
  },
  {
    path: '/welcome-dia-d',
    element: (
      <PrivilegedRoute>
        <WelcomeDiaD />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rutas-mrt',
    element: (
      <ProtectedRoute>
        <RoutesMRT />
      </ProtectedRoute>
    ),
  },
  {
    path: '/asignacion-diad-perfil',
    element: (
      <ProtectedRoute>
        <AssignPerfil />
      </ProtectedRoute>
    ),
  },
];

export default DiaDRoutes;
