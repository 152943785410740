import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Box, Button, Card, CardContent, Icon, Stack, Grid, Typography, Tooltip } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import WarningIcon from '@mui/icons-material/Warning';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import Download from '@components/Downloads/Download';
import BasicSelect from '@components/Selects/BasicSelect';
// import EditingSchedules from "./EditingSchedules";
import ChartDesiredProgressModal from '@components/Activation/DashboardTerritorial/ChartDesiredProgressModal';
import UserSearchHistoryModal from '@components/Activation/DashboardTerritorial/UserSearchHistoryModal';

// Middlewares ,Servicios, Utils
import middleware from '@middlewares/middleware';
import movilizacion from '@services/MovilizationServices';
import { setDiynamicColumns } from '@utils/Utilities';

// Constantes
import { COLUMNS_DASH_MOBILIZATION_TERRITORIAL, HOURS } from '@data/constants/Mobilization';
import {
  AMBITO_REGIONAL,
  AMBITO_DF,
  AMBITO_DL,
  AMBITO_MPAL,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
} from '@data/constants';

// Hooks
import { useSorted } from '@hooks/useSorted';
import ModalSchedule from './ModalSchedule';

const TableMobilization = (props) => {
  const canEdit = middleware.checkMenuAction('Editar');

  const { data, catView, idAmbitoAgrupadorTable, ambitoUser, idFilter } = props;

  const porcentajes = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: '0 % < 25 %' },
    { value: 2, label: '25 % < 50 %' },
    { value: 3, label: '50 % < 75 %' },
    { value: 4, label: '76 % < 100 %' },
  ];

  const [porcentaje, setPorcentaje] = useState(0);
  const [porcentajeCasilla, setPorcentajeCasilla] = useState(0);

  const [localData, setLocalData] = useState(data);
  const [ambitoAgrupador, setAmbitoAgrupador] = useState(ambitoUser);

  const [catViewFiltered, setCatViewFiltered] = useState([]);

  const [isDownload, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [orderBy, setOrderBy] = useState('');

  const [total, setTotal] = useState(10);
  const [summary, setSummary] = useState([]);
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [dataMovilization, setDataMovilization] = useState([]);

  const { handleRequestSort } = useSorted({
    total: total,
    setData: setLocalData,
  });

  const [openEditingSchedules, setOpenEditingSchedules] = useState(false);
  const [dataSchedules, setDataSchedules] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [openModalS, setOpenModalS] = useState(false);

  const [dataModal, setDataModal] = useState({ filtered: [] });

  const [dataFieldValue, setDataFieldValue] = useState(null);
  const [value, setValue] = useState(0);

  const [refreshSchedule, setRefreshSchedule] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setValue(0);
    setOpenModal(false);
  };

  const handleOpenModalS = () => setOpenModalS(true);
  const handleCloseModalS = () => setOpenModalS(false);

  const handleChangePagination = (value) => {
    setLocalData((prev) => ({
      ...prev,
      page: value.page,
      pageSize: value.pageSize,
    }));
  };

  const getMovilization = async (data) => {
    setLoading(true);
    try {
      const res = await movilizacion.getMovAdvanceTerritorialDetails(data);
      const { results, response, message } = res;
      if (results) {
        setTotal(response.total);

        const summaryValues = Object.values(response.totals);
        setSummary(['', ...summaryValues]);

        handleMobilizationData(response);
      } else {
        setTotal(0);
        setSummary([]);
        setColumnsDinamics([]);
        setDataMovilization([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleModalsEdit = (hour, seccion) => {
    const params = {
      Hora: hour,
      Seccion: seccion,
    };
    setOpenEditingSchedules(true);
    setDataSchedules(params);
  };

  useEffect(() => {
    if (localData.sorted.length) {
      setOrderBy(localData.sorted[0].id);
    } else setOrderBy('');
  }, [localData]);

  useEffect(() => {
    const campos_filtro = {
      2: 'idRegion',
      3: 'idDF',
      4: 'idDL',
      5: 'idMunicipio',
      6: 'idMunicipioReportes',
      7: 'idPoligono',
      8: 'Seccion',
    };

    if (campos_filtro[ambitoAgrupador]) {
      if (value) {
        setDataModal({
          filtered: [
            {
              id: campos_filtro[ambitoAgrupador],
              filter: '=',
              value: value,
            },
          ],
        });
      }
    }
  }, [ambitoAgrupador, value]);

  const handleButtonClick = (id, value) => {
    setDataFieldValue({
      field: id,
      value: String(value),
    });
  };

  const handleMobilizationData = ({ data }) => {
    const dataWithHighlight = data.map((item) => {
      const avanceTotal = parseFloat(item.AvanceTotal);
      const meta = parseFloat(item.Meta);
      const avanceCasillas = parseFloat(item.AvanceCasillas);
      const metaCasillas = parseFloat(item.MetaCasillas);
      let tooltipText = '';

      if (avanceTotal > meta) {
        const diferencia = avanceTotal - meta;
        tooltipText = `El avance total está ${diferencia} unidades por encima de la meta.`;
        return {
          ...item,
          AvanceTotal: (
            <Tooltip title={tooltipText} arrow>
              <Typography sx={{ color: 'orange', fontSize: 14, fontWeight: 600 }}>
                <WarningIcon sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {item.AvanceTotal}
              </Typography>
            </Tooltip>
          ),
        };
      }

      if (avanceCasillas > metaCasillas) {
        const diferencia = avanceCasillas - metaCasillas;
        tooltipText = `El avance de casillas está ${diferencia} unidades por encima de la meta casillas.`;
        return {
          ...item,
          AvanceCasillas: (
            <Tooltip title={tooltipText} arrow>
              <Typography sx={{ color: 'orange', fontSize: 14, fontWeight: 600 }}>
                <WarningIcon sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {item.AvanceCasillas}
              </Typography>
            </Tooltip>
          ),
        };
      }

      return item;
    });

    const columns = setDiynamicColumns({
      data: dataWithHighlight[0],
      columns: [
        ...COLUMNS_DASH_MOBILIZATION_TERRITORIAL,
        {
          id: 'Region',
          label: 'REGION',
          type: 'button',
          variant: 'text',
          onClick: (a, b, c) => {
            handleButtonClick('Region', a);
            handleOpenModalS();
          },
        },
        {
          id: 'Municipio',
          label: 'MUNICIPIO',
          type: 'button',
          variant: 'text',
          onClick: (a, b, c) => {
            handleButtonClick('Municipio', a);
            handleOpenModalS();
          },
        },
        {
          id: 'Poligono',
          label: 'POLIGONO',
          type: 'button',
          variant: 'text',
          onClick: (a, b, c) => {
            handleButtonClick('Poligono', a);
            handleOpenModalS();
          },
        },
        {
          id: 'DistritoFederal',
          label: 'DISTRITO FEDERAL',
          type: 'button',
          variant: 'text',
          align: 'center',
          onClick: (a, b, c) => {
            handleButtonClick('DistritoFederal', a);
            handleOpenModalS();
          },
        },
        {
          id: 'DistritoLocal',
          label: 'DISTRITO LOCAL',
          type: 'button',
          variant: 'text',
          align: 'center',
          onClick: (a, b, c) => {
            handleButtonClick('DistritoLocal', a);
            handleOpenModalS();
          },
        },
        {
          id: 'Seccion',
          label: 'SECCIÓN',
          type: 'button',
          variant: 'text',
          onClick: (a, b, c) => {
            handleButtonClick('Seccion', a);
            handleOpenModalS();
          },
        },
      ],
    });

    const columnType = ambitoAgrupador === 8 && canEdit ? 'button' : 'number';

    const hoursColumns = HOURS.map((hour) => ({
      id: hour.id,
      label: hour.label,
      type: columnType,
      variant: 'text',
      align: 'center',
      onClick:
        columnType === 'button'
          ? (a, b, c) => {
              setDataSchedules({ Hora: hour.label, Seccion: c.id });
              setValue(c.id);
              handleModalsEdit(hour.label, c.id);
            }
          : null,
    }));

    const columnas = [
      {
        id: 'Button',
        label: 'SEGUIMIENTO',
        type: 'iconButton',
        sort: false,
        align: 'center',
        onClick: (a, b, c) => {
          setValue(c.id);
          handleOpenModal();
        },
      },
      ...columns,
      ...hoursColumns,
    ];

    setColumnsDinamics(columnas);
    setDataMovilization(dataWithHighlight.map((item) => ({ Button: <QueryStatsIcon />, ...item })));
  };

  useEffect(() => {
    // se asegura que ya haya cargado el ambito
    if (ambitoUser) {
      let values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
      let defaultValue = false;

      switch (idFilter) {
        case AMBITO_REGIONAL:
          values = [AMBITO_REGIONAL, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DF:
          values = [AMBITO_DF, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DL:
          values = [AMBITO_DL, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_MPAL:
          values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_POLIGONAL:
          values = [AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_SECCIONAL:
          values = [AMBITO_SECCIONAL];
          break;
        default:
          defaultValue = true;
          break;
      }

      // Si es no es default deja los items principales seleccionados
      let catalogs = [];
      if (!defaultValue) catalogs = catView.filter((item) => values.includes(item.value));
      else
        catalogs = catView.filter(
          (item) => item.value === idAmbitoAgrupadorTable || values.includes(item.value)
        );

      setCatViewFiltered(catalogs);

      const agrupadorLocal = catalogs[0]?.value ?? '';
      setAmbitoAgrupador(agrupadorLocal);
      setPorcentaje(0);

      setLocalData({
        ...data,
        page: 0,
        pageSize: 10,
        idAmbitoAgrupador: agrupadorLocal,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (ambitoUser) getMovilization(localData);
    // eslint-disable-next-line
  }, [localData]);

  // Actualiza el ambito agrupador local y el select
  useEffect(() => {
    if (ambitoUser) {
      const ambito = idAmbitoAgrupadorTable ?? ambitoUser;
      const values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
      const filtered = catView.filter((item) => item.value === ambito || values.includes(item.value));
      setCatViewFiltered(filtered);
      setAmbitoAgrupador(filtered[0]?.value ?? '');
    }
    // eslint-disable-next-line
  }, [idAmbitoAgrupadorTable]);

  const handleChangeAmbito = (e) => {
    const value = e.target.value;
    setAmbitoAgrupador(value);
    setLocalData({
      ...localData,
      page: 0,
      pageSize: 10,
      idAmbitoAgrupador: value,
      sorted: [],
    });
    setPorcentajeCasilla(0);
    setPorcentaje(0);
  };

  const handleChangePorcentaje = (e) => {
    const value = e.target.value;
    updateOrAddFilter('PorcentajeAvance', value);
    setPorcentaje(value);
  };

  const handleChangePorcentajeCasillas = (e) => {
    const value = e.target.value;
    updateOrAddFilter('PorcentajeAvanceCasillas', value);
    setPorcentajeCasilla(value);
  };

  const updateOrAddFilter = (filterId, value) => {
    const existingFilterIndex = localData.filtered.findIndex((filter) => filter.id === filterId);

    const updatedFilters = [...localData.filtered];
    if (existingFilterIndex !== -1) {
      // Si ya existe, actualizar el valor del filtro existente
      updatedFilters[existingFilterIndex].value = value;
    } else {
      // Si no existe, agregar un nuevo filtro
      updatedFilters.push({ id: filterId, filter: '=', value: value });
    }

    setLocalData({
      ...localData,
      filtered: updatedFilters,
      page: 0,
      pageSize: 10,
      idAmbitoAgrupador: ambitoAgrupador,
    });
  };

  const exportMovAdvanceTerritorialDetails = async () => {
    setIsDownloading(true);

    const { page, pageSize, ...params } = localData;

    try {
      const res = await movilizacion.exportMovAdvanceTerritorialDetails(params);
      const { results, message } = res;

      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleChangeSchedules = () => {
    setRefreshSchedule(true);
  };

  useEffect(() => {
    if (refreshSchedule) {
      getMovilization(localData);
      setRefreshSchedule(false);
    }
    // eslint-disable-next-line
  }, [refreshSchedule]);

  return (
    <>
      <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={2}>
            <Typography variant="body2" fontWeight={700}>
              MOVILIZACIÓN
            </Typography>

            {middleware.checkMenuAction('Exportar') && (
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload}
                onClick={exportMovAdvanceTerritorialDetails}
              >
                Exportar
              </Button>
            )}
          </Stack>

          {isDownload && (
            <Box marginBottom={2}>
              <Download format={'xlsx'} isDownload={isDownload} />
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="agrupador"
                label="Agrupar por"
                options={catViewFiltered}
                value={ambitoAgrupador}
                onChange={handleChangeAmbito}
                sx={{ width: '100%' }}
                disabled={catViewFiltered?.length <= 1}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="porcentaje"
                label="Porcentaje de Avance"
                options={porcentajes}
                value={porcentaje}
                onChange={handleChangePorcentaje}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="avanceCasillas"
                label="Porcentaje Avance de Casillas"
                options={porcentajes}
                value={porcentajeCasilla}
                onChange={handleChangePorcentajeCasillas}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>

          <br />

          <CustomTable
            rows={dataMovilization}
            columns={columnsDinamics}
            total={total}
            orderBy={orderBy}
            page={data.page}
            pageSize={data.pageSize}
            isLoading={loading}
            handlePagination={handleChangePagination}
            handleSort={handleRequestSort}
            summary={summary}
            summarySettings={{ start: 0, title: 'Totales: ', align: 'center' }}
            disableCardType
          />
        </CardContent>
      </Card>

      <ChartDesiredProgressModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        dataModal={dataModal}
      />

      <UserSearchHistoryModal
        openModalS={openModalS}
        handleCloseModalS={handleCloseModalS}
        dataFieldValue={dataFieldValue}
      />

      {/* <EditingSchedules open={openEditingSchedules} data={dataSchedules} setOpen={setOpenEditingSchedules} handleRefresh={handleRefresh} handleChangeSchedules={handleChangeSchedules} /> */}

      <ModalSchedule
        open={openEditingSchedules}
        data={dataSchedules}
        setOpen={setOpenEditingSchedules}
        handleChangeSchedules={handleChangeSchedules}
      />
    </>
  );
};

export default TableMobilization;
