import React from 'react';

import {
  Box,
  FormControlLabel,
  // FormGroup,
  // Switch,
  Chip,
  Icon,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isEmptyOrInvalidString } from '@utils/validations';
import BasicSelect from '@components/Selects/BasicSelect';
import { VOL_RC, VOL_RG, VOL_OBSERVER } from '@data/constants';

const VolunteerForm = (props) => {
  const {
    medioContacto = true,
    catalogs,
    loadigCatalogs,
    errors,
    touched,
    values,
    handleChange,
    setValues,
  } = props;

  // const handleChangeSwitch = (e) => {
  //     const value = e.target.checked;
  //     setValues({
  //         ...values,
  //         EsVoluntario: value === false ? 0 : 1,
  //     }
  //     );
  // };

  const handleChangeRadio = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 item xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>accessibility</Icon>} label="Voluntario" />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 item xs={12} sm={12} md={12} lg={12}>
          <FormControl>
            <FormLabel id="EsVoluntario">¿Desea ser voluntario?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="EsVoluntario"
              name="EsVoluntario"
              value={values.EsVoluntario}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value={1}
                checked={values.EsVoluntario === 1 ? true : false}
                control={<Radio />}
                label="SI"
              />
              <FormControlLabel
                value={0}
                checked={values.EsVoluntario === 0 ? true : false}
                control={<Radio />}
                label="NO"
              />
            </RadioGroup>
            {errors.EsVoluntario && <FormHelperText error>{errors.EsVoluntario}</FormHelperText>}
          </FormControl>
        </Grid2>
        {values.EsVoluntario === 1 && (
          <Grid2 item xs={12} sm={12} md={2} lg={2}>
            <BasicSelect
              error={touched.idVoluntario && !isEmptyOrInvalidString(errors.idVoluntario)}
              errorMessage={errors.idVoluntario}
              name="idVoluntario"
              label="Propuesta de Voluntariado *"
              options={catalogs.voluntarios}
              value={values.idVoluntario}
              onChange={handleChange}
              sx={{ width: '100%' }}
              isLoading={loadigCatalogs}
            />
          </Grid2>
        )}
        {values.idVoluntario && [VOL_RC, VOL_RG, VOL_OBSERVER].includes(values.idVoluntario) && (
          <>
            <Grid2 item xs={12} sm={12} md={3} lg={3}>
              <FormControl>
                <FormLabel id="TieneAuto">¿Tiene Auto?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="TieneAuto"
                  name="TieneAuto"
                  value={values.TieneAuto}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel value={1} control={<Radio />} label="SI" />
                  <FormControlLabel value={0} control={<Radio />} label="NO" />
                </RadioGroup>
                {errors.TieneAuto && <FormHelperText error>{errors.TieneAuto}</FormHelperText>}
              </FormControl>
            </Grid2>
            <Grid2 item xs={12} sm={12} md={3} lg={3}>
              <FormControl>
                <FormLabel id="TieneExperiencia">¿Tiene Experiencia?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="TieneExperiencia"
                  name="TieneExperiencia"
                  value={values.TieneExperiencia}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel value={1} control={<Radio />} label="SI" />
                  <FormControlLabel value={0} control={<Radio />} label="NO" />
                </RadioGroup>
                {errors.TieneExperiencia && <FormHelperText error>{errors.TieneExperiencia}</FormHelperText>}
              </FormControl>
            </Grid2>
            <Grid2 item xs={12} sm={12} md={3} lg={3}>
              <FormControl>
                <FormLabel id="AceptaCuidarOtra">¿Aceptaría cuidar otra casilla?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="AceptaCuidarOtra"
                  name="AceptaCuidarOtra"
                  value={values.AceptaCuidarOtra}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel value={1} control={<Radio />} label="SI" />
                  <FormControlLabel value={0} control={<Radio />} label="NO" />
                </RadioGroup>
                {errors.AceptaCuidarOtra && <FormHelperText error>{errors.AceptaCuidarOtra}</FormHelperText>}
              </FormControl>
            </Grid2>
          </>
        )}
        {medioContacto && values.EsVoluntario === 1 && (
          <Grid2 item xs={12} sm={12} md={3} lg={3}>
            <BasicSelect
              error={touched.idMedioContacto && !isEmptyOrInvalidString(errors.idMedioContacto)}
              errorMessage={errors.idMedioContacto}
              name="idMedioContacto"
              label="Medio de contacto del voluntario *"
              options={catalogs.medios_contacto}
              value={values.idMedioContacto}
              onChange={handleChange}
              sx={{ width: '100%' }}
              isLoading={loadigCatalogs}
            />
          </Grid2>
        )}
        {/* <Grid2 xs={12} sm={12} md={3} lg={3}>
                    <BasicSelect
                        error={touched.idPartido && !isEmptyOrInvalidString(errors.idPartido)}
                        errorMessage={errors.idPartido}
                        name="idPartido"
                        label="Selecciona una partido"
                        options={catalogs.partidos}
                        value={values.idPartido}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                        isLoading={loadigCatalogs}
                    />
                </Grid2> */}
      </Grid2>
    </Box>
  );
};

export default VolunteerForm;
