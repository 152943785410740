import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Card, CardContent, Typography } from '@mui/material';

// Componentes
import BarColumnTriplePercent from '@components/Charts/BarColumnTriplePercent';

// Servicios
import MovilizationServices from '@services/MovilizationServices';

const ChartBingoGoal = ({ body, setLoading, openDrawer, ambitoUser }) => {
  const [dataChart, setDataChart] = useState({ categories: [], series: [], title: '' });

  const getMobilizedChartByView = async (body) => {
    setLoading((prev) => ({ ...prev, isLoading: true, open: true }));

    try {
      const res = await MovilizationServices.getCompromisosMovElectoralChart(body);
      const { results, message, response } = res;

      if (results) {
        setDataChart({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
        });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading((prev) => ({ ...prev, isLoading: false, open: false }));
    }
  };

  useEffect(() => {
    if (ambitoUser) getMobilizedChartByView(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
      <CardContent key={`Bingo_${openDrawer}`}>
        <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
          {dataChart.title}
        </Typography>
        <BarColumnTriplePercent series={dataChart.series} categories={dataChart.categories} />
      </CardContent>
    </Card>
  );
};

export default ChartBingoGoal;
