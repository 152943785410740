import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardContent, Container, Icon, Typography, ListItemText, Box } from '@mui/material';

import { blue } from '@mui/material/colors';
import Swal from 'sweetalert2';

import moment from 'moment';
import 'moment/locale/es';

import AdminLayout from '@components/MainPage/AdminLayout';
import BasicTable from '@components/Tables/BasicTable';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Compromisos/HistoryForeignFilter';
import CompromisoServices from '@services/CompromisoServices';
import { isEmptyOrNullObject } from '@utils/validations';
import { COLUMNS_FOREIGNERS, TYPE_COUNTRY } from '@data/constants/Compromisos';
//import middleware from "@middlewares/middleware";

const HistoryForeigner = () => {
  let navigate = useNavigate();
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: 'compromisos_extranjero.FechaCreo', value: 'desc' }],
  });

  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const getCaptureHistory = useCallback((data) => {
    setIsLoading(true);
    CompromisoServices.getMyForeignRecords(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              FechaCreo: moment(item.FechaCreo).format('DD/MM/YYYY H:m'),
              idPais: <Box component={'img'} src={TYPE_COUNTRY[item.idPais]} alt="pais" width={40} />,
              NombreCompleto: (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ':hover': {
                      bgcolor: blue[900],
                      color: 'white',
                    },
                    p: '0 auto',
                    textAlign: 'left',
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) => handleEdit(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {item.NombreCompleto}
                      </Typography>
                    }
                    secondary={
                      <Typography fontWeight={500} fontSize={13} color="text.secondary">
                        {item.Edad} Años
                      </Typography>
                    }
                  />
                </Button>
              ),
              Municipio: item.Municipio?.toUpperCase(),
              ColoniaVive: item.ColoniaVive?.toUpperCase(),
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCaptureHistory(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getCaptureHistory(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleEdit = (commitment) => {
    const { id } = commitment;

    CompromisoServices.findMyForeignRecords(id)
      .then((res) => {
        if (res.results) {
          navigate('/compromisos-ext-registro', { state: res.response });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }));
  };

  const handleChangeFilter = (filtered) => {
    if (filtered.length > 0) {
      setData((prevState) => ({ ...prevState, filtered }));
    }
  };

  const handleChangeClearFilter = () => {
    if (data.filtered.length > 0) {
      setData((prevState) => ({ ...prevState, filtered: [] }));
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      NombreCompleto:
        "CONCAT_WS(' ',compromisos_extranjero.Nombre, compromisos_extranjero.Paterno, compromisos_extranjero.Materno)",
      FechaCreo: 'compromisos_extranjero.FechaCreo',
      Municipio: 'cat_municipios.Municipio',
      Estado: 'Estado',
      ColoniaVive: 'ColoniaVive',
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Historial de captura extranjero" />
        <Box>
          <Filter
            onChangeFilter={(e) => handleChangeFilter(e)}
            onChangeFilterClear={handleChangeClearFilter}
          />
          <Card className="card-primary">
            <CardContent>
              <BasicTable
                rows={dataLisCommitments}
                hcolumns={COLUMNS_FOREIGNERS}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default HistoryForeigner;
