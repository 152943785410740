import PropTypes from 'prop-types';
import moment from 'moment';
//Mui
import { Box, Card, CardContent, Icon, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { blue, grey } from '@mui/material/colors';

import PieProgressCard from '@components/Card/PieProgressCard';
import CardInfo from '@components/Prep/CardInformative';

const PrepSamplingHeaders = (props) => {
  const {
    summaryData = {
      TipoEleccion: '',
      TotalActas: 0,
      ActasRecibidas: 0,
      TotalVotos: 0,
      LNom: 0,
      FechaHora: moment().format('DD/MM/YYYY HH:mm'),
      PActas: 0,
      Participacion: 0,
      Ambito: '',
    },
    loadingCards,
    handleRefresh,
    openDrawer,
  } = props;

  return (
    <Box component={'div'}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={12} lg={4}>
          <Box
            component={Card}
            className="card-primary"
            height={'91%'}
            display="flex"
            flexDirection={'column'}
            alignContent={'center'}
          >
            <Stack direction={'row'} spacing={1} justifyContent="center" marginTop={2}>
              <Icon sx={{ color: grey[600] }}>newspaper</Icon>
              <Typography marginTop={2} fontWeight={600} variant="h6">
                Elección
              </Typography>
            </Stack>
            <Box display={'flex'} justifyContent={'center'}>
              <Box component={CardContent}>
                <Box sx={{ display: { xs: '', sm: 'flex' } }} alignItems="center" gap={2}>
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {openDrawer ? (
                      <></>
                    ) : (
                      <Box
                        width={'90px'}
                        height={'90px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        margin={'10px'}
                        sx={{ mr: 5, backgroundColor: blue[50], borderRadius: '50%' }}
                      >
                        <Icon sx={{ fontSize: '3rem', color: blue[800] }}>description</Icon>
                      </Box>
                    )}
                  </Box>
                  <CardInfo summaryData={summaryData} handleClick={handleRefresh} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={6} lg={4}>
          <PieProgressCard
            loading={loadingCards}
            data={summaryData.PActas}
            title={'Actas Recibidas'}
            config={{
              icon: 'description',
            }}
            styleSettings={{
              height: '91%',
            }}
          />
        </Grid2>
        <Grid2 xs={12} md={6} lg={4}>
          <PieProgressCard
            loading={loadingCards}
            data={summaryData.Participacion}
            title={'Participación Ciudadana'}
            config={{
              icon: 'group',
            }}
            styleSettings={{
              height: '91%',
            }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

PrepSamplingHeaders.propTypes = {
  summaryData: PropTypes.object.isRequired,
};

export default PrepSamplingHeaders;
