import React from 'react';
import { Navigate } from 'react-router-dom';

import { getVars } from '@utils/global';
import NotAuthorized from '@pages/auth/NotAuthorized';
import { MENUS_PRIVILEGES } from '@data/constants';

class Middleware {
  routeToDisplay(middlewares = [], component) {
    const mware = {
      public: (component) => this.public(component),
      alreadyLoggedIn: (component) => this.alreadyLoggedIn(),
      protectedRoute: (component) => this.protectedRoute(component),
      privilegedRoute: (component) => this.privilegedRoute(component),
    };

    let ret = null;
    try {
      for (let i = 0; i < middlewares.length; i++) {
        ret = mware[middlewares[i]](component);
        if (ret.status === false) {
          break;
        }
      }
      return ret.component;
    } catch (e) {
      //handle error here
      alert(e);
    }
  }

  getValidToken() {
    return getVars('Token').access_token ? getVars('Token').access_token : false;
  }

  getAllAccessMenus() {
    let menus = [];
    getVars('Token').menus.forEach((item) => {
      menus = [...menus, item];
      if (item.submenus.length > 0) {
        menus = [...menus, ...item.submenus];
      }
    });

    return menus.map((item) => {
      delete item.submenus;
      return item;
    });
  }

  checkMenuAccess() {
    if (!this.getValidToken()) return false;
    else if (!getVars('Token').menus) return false;
    const pathname = window.location.pathname;
    const found = this.getAllAccessMenus().find((item) => item.Ruta === pathname);
    if (!found) return false;
    return found.Ver ? true : false;
  }

  checkMenuAction(action) {
    if (!this.getValidToken()) return false;
    else if (!getVars('Token').menus) return false;
    else if (!MENUS_PRIVILEGES.includes(action)) return false;
    const pathname = window.location.pathname;
    const found = this.getAllAccessMenus().find((item) => item.Ruta === pathname);
    if (!found) return false;
    return found[action] ? true : false;
  }

  checkMenuActionId(action, id) {
    if (!this.getValidToken()) return false;
    else if (!getVars('Token').menus) return false;
    else if (!MENUS_PRIVILEGES.includes(action)) return false;
    const pathname = id ? id : window.location.pathname;
    const found = this.getAllAccessMenus().find((item) => (id ? item.idMenu === id : item.Ruta === pathname));
    if (!found) return false;
    return found[action] ? true : false;
  }

  _getRouteReturn(status, component) {
    return { status, component };
  }

  public(component) {
    return !this.getValidToken()
      ? this._getRouteReturn(true, component)
      : this._getRouteReturn(false, <Navigate to={getVars('Token').user.DefaultPage} />);
  }

  alreadyLoggedIn(pathname = '/') {
    return this.getValidToken()
      ? this._getRouteReturn(true, <Navigate to={getVars('Token').user.DefaultPage} />)
      : this._getRouteReturn(
          false,
          <Navigate
            to={{
              pathname: '/login',
              state: { from: pathname },
            }}
          />
        );
  }

  protectedRoute(component, pathname = '/') {
    return this.getValidToken()
      ? this._getRouteReturn(true, component)
      : this._getRouteReturn(
          false,
          <Navigate
            to={{
              pathname: '/login',
              state: { from: pathname },
            }}
          />
        );
  }

  privilegedRoute(component) {
    return this.checkMenuAccess()
      ? this._getRouteReturn(true, component)
      : this._getRouteReturn(false, <NotAuthorized />);
  }
}

const middleware = new Middleware();
export default middleware;
