export const CATALOGS_PUBLICACIONES = [
  { value: 1, label: 'Divulgación' },
  { value: 2, label: 'Téoricas' },
  { value: 3, label: 'Digitales' },
];

export const COLUMNS_PUBLICACIONES = (value) => {
  const data = [
    { id: 'Tipo', label: 'Tipo', type: 'text' },
    { id: 'FechaPublicacion', label: 'Publicación', type: 'text' },
    { id: 'Nombre', label: 'Nombre', type: 'text' },
    { id: 'Descripcion', label: 'Descripción', type: 'text' },
    { id: 'Sinopsis', label: 'Sinopsis', type: 'text' },
    { id: 'fileURLImg', label: 'Portada', type: 'text' },
    { id: 'fileName', label: 'Archivo', type: 'text' },
  ];

  if (value)
    data.push({ id: 'actions', label: 'Acciones', sort: false, align: 'center', disablePadding: true });

  return data;
};
