import { useState, useEffect, Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Icon,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';

// Utilidades
import { getVars, setVars } from '@utils/global';

// Hooks
import useWindowDimensions from '@hooks/useWindowDimensions';

const hiddenText = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

const AdminSidebar = ({ open, handleToggle = () => {}, refresh }) => {
  const navigate = useNavigate();
  const tMenus = getVars('Token')?.menus ?? [];
  const [menus, setMenus] = useState(tMenus.length > 0 ? tMenus : []);

  const [openMenuList, setOpenMenuList] = useState(['Inicio']);
  const historyMenu = getVars('sideBar');
  const [stateMenu] = useState(historyMenu?.clickMenu ?? '');

  const { width } = useWindowDimensions();
  const size = { xxs: width < 350, xs: width < 600 };
  const heightButtons = 45;

  const styleText = {
    margin: 0,
    maxWidth: size.xxs ? '100%' : 200,
    marginLeft: open ? 2 : 0,
    textWrap: 'nowrap',
    width: open ? (size.xxs ? 'calc( 100vw - 77px )' : 'auto') : 0,
    overflow: open ? 'hidden' : 'visible',
    opacity: open ? 1 : 0,
    transition: 'all 0.5s',
    color: '#fdfdfd',
  };

  useEffect(() => {
    if (refresh) {
      if (tMenus.length > 0) setMenus(tMenus);
      else setMenus([]);
    } // eslint-disable-next-line
  }, [refresh]);

  const setBreadcrumbs = (name, id) => {
    if (openMenuList.includes(name)) {
      const result = openMenuList?.filter((item) => item !== name);
      setOpenMenuList(result);
      setVars('sideBar', { clickMenu: stateMenu, caminoMenu: result, Menu: name });
    } else {
      setOpenMenuList([...openMenuList, name]);
      setVars('sideBar', { clickMenu: stateMenu, caminoMenu: [...openMenuList, name], Menu: name });
    }
  };

  const handleClickMenu = (item) => {
    navigate(item.Ruta);
    setBreadcrumbs(item.Menu);
    handleToggle(false);
  };

  useEffect(() => {
    const closeDrawerOnOutsideClick = (event) => {
      const sidebarButton = document.getElementById('sidebarButton');
      const sidebarDrawer = document.getElementById('sidebarDrawer');

      const buttonFlag = !sidebarButton.contains(event.target);
      const drawerFlag = sidebarDrawer.contains(event.target) || event.target === sidebarDrawer;

      if (open && buttonFlag && !drawerFlag) handleToggle(false);
    };
    document.addEventListener('click', closeDrawerOnOutsideClick);

    return () => document.removeEventListener('click', closeDrawerOnOutsideClick);
    // eslint-disable-next-line
  }, [open]);

  return (
    <Drawer
      variant={size.xs ? 'persistent' : 'permanent'}
      open={open}
      onMouseEnter={() => handleToggle(true)}
      onMouseLeave={() => handleToggle(false)}
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': { width: '6px' },
          background: 'linear-gradient(to bottom, #0343c6, #101e4c)',
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: '6px',
            mt: { xs: '60px', sm: '70px' },
            mb: '5px',
          },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '6px' },
          border: 0,
        },
      }}
    >
      <Box id="sidebarDrawer" sx={{ pt: '60px' }}>
        <List>
          {menus &&
            menus.map((item, index) => (
              <ListItem key={item.idMenu || index} disablePadding sx={{ display: 'block' }}>
                {item.Visible === 1 &&
                  (item.submenus.length < 1 ? (
                    <Item
                      item={item}
                      height={heightButtons}
                      styleText={styleText}
                      handleClickMenu={handleClickMenu}
                    />
                  ) : (
                    <ColapseItem
                      item={item}
                      height={heightButtons}
                      styleText={styleText}
                      handleClickSubMenu={handleClickMenu}
                      handleToggle={handleToggle}
                    />
                  ))}
              </ListItem>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};

const Item = (props) => {
  const { item, height, styleText, handleClickMenu } = props;

  return (
    <ListItemButton onClick={() => handleClickMenu(item)} sx={{ height }}>
      <ListItemIcon sx={{ minWidth: 0, width: 24, justifyContent: 'center', color: styleText.color }}>
        <Icon>{item.Icono}</Icon>
      </ListItemIcon>

      <ListItemText
        disableTypography
        sx={styleText}
        primary={
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Box sx={{ flex: 1, ...hiddenText }}>{item.Menu}</Box>
          </Box>
        }
      />
    </ListItemButton>
  );
};

const ColapseItem = (props) => {
  const { item, height, styleText, handleClickSubMenu, handleToggle } = props;

  const [isOpenSubmenus, setIsOpenSubmenus] = useState(false);
  const handleSubmenus = () => setIsOpenSubmenus((prevOpen) => !prevOpen);

  const handleClickMenu = () => {
    handleSubmenus();
    handleToggle(true);
  };

  const open = styleText.width !== 0;

  return (
    <Fragment key={item.idMenu}>
      <ListItemButton onClick={handleClickMenu} sx={{ height }}>
        <ListItemIcon sx={{ minWidth: 0, width: 24, justifyContent: 'center', color: styleText.color }}>
          <Icon>{item.Icono}</Icon>
        </ListItemIcon>
        <ListItemText
          disableTypography
          sx={styleText}
          primary={
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Box sx={{ flex: 1, ...hiddenText }}>{item.Menu}</Box>

              <ListItemIcon
                sx={{ minWidth: 0, justifyContent: 'center', marginLeft: 1, color: styleText.color }}
              >
                {isOpenSubmenus ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
              </ListItemIcon>
            </Box>
          }
        />
      </ListItemButton>

      <Collapse in={isOpenSubmenus} timeout="auto" unmountOnExit>
        <List sx={{ pt: 0 }}>
          {item.submenus.map((subItem, index) => {
            return (
              subItem.Visible === 1 && (
                <ListItemButton
                  onClick={() => handleClickSubMenu(subItem)}
                  key={subItem.idMenu || index}
                  sx={{ height: 37, pl: open ? 3.5 : 2 }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      width: 24,
                      // justifyContent: "center",
                      color: styleText.color,
                      fontSize: 14,
                    }}
                  >
                    {subItem.Siglas}
                    {/* subItem.Icono ? <Icon sx={{ fontSize: 20 }}>{subItem.Icono}</Icon> : subItem.Siglas*/}
                  </ListItemIcon>

                  <ListItemText
                    disableTypography
                    sx={{ ...styleText, width: open ? 'auto' : 0, fontSize: 14 }}
                    primary={<Box sx={hiddenText}>{subItem.Menu}</Box>}
                  />
                </ListItemButton>
              )
            );
          })}
        </List>
      </Collapse>
      {isOpenSubmenus && <Divider />}
    </Fragment>
  );
};

export default memo(AdminSidebar);
