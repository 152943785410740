import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';

const PieChart = (props) => {
  const { series, options, loading, pieColors, height = '100%', distance = 25, showInLegend = true } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: 'pie',
        height: height,
      },
      title: {
        text: options.title,
      },
      subtitle: {
        text: options.subtitle,
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            distance: distance,
            style: {
              fontSize: '16px',
            },
          },
          showInLegend: showInLegend,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b> del <b>{point.total}</b> total<br/>',
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">' +
            this.name +
            '</span>: <b>' +
            Highcharts.numberFormat(this.y, 1) +
            '</b> del <b>' +
            Highcharts.numberFormat(this.total, 0) +
            '</b> total<br/>'
          );
        },
      },
      credits: false,
      series: series,
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true,
      },
    });

    // eslint-disable-next-line
  }, [series, options, loading]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default PieChart;
