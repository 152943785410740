const actions = { id: 'actions', label: 'Acciones', sort: false, align: 'center', disablePadding: true };

export const COLUMNS_APORTANTES = (value) => {
  const data = [
    { id: 'RNM', label: 'RNM', type: 'text' },
    { id: 'Municipio', label: 'Municipio', type: 'text' },
    { id: 'Nombre', label: 'Nombre', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'Correo', label: 'Correo', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_SERVICIOS = (value) => {
  const data = [
    { id: 'NombreServicio', label: 'Nombre', type: 'text' },
    { id: 'Descripcion', label: 'Descripción', type: 'text' },
    { id: 'PorcentajeCargo', label: 'Porcentaje Cargo', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PERFIL = (value) => {
  const data = [{ id: 'PerfilAportador', label: 'Perfil', type: 'text' }];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PERCEPCIONES = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'PerfilAportador', label: 'Perfil', type: 'text' },
    { id: 'Cargo', label: 'Cargo', type: 'text' },
    { id: 'Dependencia', label: 'Dependencia', type: 'text' },
    { id: 'MontoNeto', label: 'Monto Neto', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_CARGOS = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'NombreServicio', label: 'Servicio', type: 'text' },
    { id: 'Qna', label: 'Qna', type: 'text' },
    { id: 'MontoCargo', label: 'Monto Cargo', type: 'text' },
    { id: 'FechaCargo', label: 'Fecha Cargo', type: 'text' },
    { id: 'LineaCaptura', label: 'Linea Captura', type: 'text' },
    { id: 'Estatus', label: 'Estatus', type: 'text' },
    { id: 'FechaPago', label: 'Fecha Pago', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PAGOS = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'NombreServicio', label: 'Servicio', type: 'text' },
    { id: 'FechaCargo', label: 'Fecha Cargo', type: 'text' },
    { id: 'Estatus', label: 'Estatus', type: 'text' },
    { id: 'MontoPagado', label: 'Monto Pagado', type: 'text' },
    { id: 'FechaPago', label: 'Fecha Pago', type: 'text' },
    { id: 'TipoPago', label: 'Tipo Pago', type: 'text' },
    { id: 'Banco', label: 'Banco', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};
