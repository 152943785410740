import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Button, Stack } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';

// Servicios y Utilidades
import CatalogService from '@services/CatalogServices';
import { setVars, getVars } from '@utils/global';

const Filter = ({ catalogs, loadingCatalogs, handleFilter, region, municipio, poligono, seccion }) => {
  const [catMunicipiosReportes, setCatMunicipiosReportes] = useState([]);
  const [catPoligonos, setCatPoligonos] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);
  const [isLoadingCatMunicipiosReportes, setIsLoadingMunicipiosReportes] = useState(false);
  const [isLoadingCatPoligonos, setIsLoadingCatPoligonos] = useState(false);
  const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);
  const [filter, setFilter] = useState({
    idRegion: '',
    idMunicipioReportes: '',
    idPoligono: '',
    Seccion: '',
  });

  const getCatalogsWithStored = () => {
    const filterSavedMapMunicipio = getVars('filterSavedMapMunicipio', []);
    const filterSavedMapPoligono = getVars('filterSavedMapPoligono', []);
    const filterSavedMapSeccion = getVars('filterSavedMapSeccion', []);
    setCatMunicipiosReportes(filterSavedMapMunicipio);
    setCatPoligonos(filterSavedMapPoligono);
    setCatSecciones(filterSavedMapSeccion);
  };

  useEffect(() => {
    let filter_saved = getVars('filterSavedMap', {
      idRegion: '',
      idMunicipioReportes: '',
      idPoligono: '',
      Seccion: '',
    });
    if (
      filter_saved.idRegion !== '' ||
      filter_saved.idMunicipioReportes !== '' ||
      filter_saved.idPoligono !== '' ||
      filter_saved.Seccion !== ''
    ) {
      setFilter(filter_saved);
      getCatalogsWithStored();
    }
    // eslint-disable-next-line
  }, []);

  const loadCatalogsByRegion = (idRegion = null) => {
    let catalogsParams = [
      {
        id: 'municipios_dl',
        getAll: false,
        filtered: [],
      },
      {
        id: 'poligonos',
        getAll: false,
        filtered: [],
      },
      {
        id: 'secciones',
        getAll: false,
        filtered: [],
      },
    ];
    if (idRegion) {
      catalogsParams = [
        {
          id: 'municipios_dl',
          getAll: false,
          filtered: [
            {
              id: 'cat_secciones.idRegion',
              filter: '=',
              value: idRegion,
            },
          ],
        },
        {
          id: 'poligonos',
          getAll: false,
          filtered: [
            {
              id: 'cat_secciones.idRegion',
              filter: '=',
              value: idRegion,
            },
          ],
        },
        {
          id: 'secciones',
          getAll: false,
          filtered: [
            {
              id: 'cat_secciones.idRegion',
              filter: '=',
              value: idRegion,
            },
          ],
        },
      ];
    }

    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);
    setIsLoadingMunicipiosReportes(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatMunicipiosReportes(
            [{ value: 0, label: 'TODOS' }].concat(res.response.catalogs.municipios_dl)
          );

          setCatPoligonos([{ value: 0, label: 'TODOS' }].concat(res.response.catalogs.poligonos));

          setCatSecciones([{ value: 0, label: 'TODAS' }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
        setIsLoadingMunicipiosReportes(false);
      });
  };

  const loadCatalogsByMunicipio = (idMunicipioReportes = null) => {
    let catalogsParams = [
      {
        id: 'poligonos',
        getAll: false,
        filtered: [{ id: 'cat_secciones.idRegion', filter: '=', value: filter.idRegion }],
      },
      {
        id: 'secciones',
        getAll: false,
        filtered: [{ id: 'cat_secciones.idRegion', filter: '=', value: filter.idRegion }],
      },
    ];
    if (idMunicipioReportes) {
      catalogsParams = [
        {
          id: 'poligonos',
          getAll: false,
          filtered: [
            {
              id: 'cat_secciones.idMunicipioReportes',
              filter: '=',
              value: idMunicipioReportes,
            },
          ],
        },
        {
          id: 'secciones',
          getAll: false,
          filtered: [
            {
              id: 'cat_secciones.idMunicipioReportes',
              filter: '=',
              value: idMunicipioReportes,
            },
          ],
        },
      ];
    }
    setIsLoadingCatPoligonos(true);
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatPoligonos([{ value: 0, label: 'TODOS' }].concat(res.response.catalogs.poligonos));
          setCatSecciones([{ value: 0, label: 'TODAS' }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingCatPoligonos(false);
        setIsLoadingCatSecciones(false);
      });
  };

  const loadCatalogsByPoligono = (idPoligono = null) => {
    let filter_temp = [];
    if (filter.idRegion !== '') {
      filter_temp.push({ id: 'cat_secciones.idRegion', filter: '=', value: filter.idRegion });
    }
    if (filter.idMunicipioReportes !== '') {
      filter_temp.push({
        id: 'cat_secciones.idMunicipioReportes',
        filter: '=',
        value: filter.idMunicipioReportes,
      });
    }
    let catalogsParams = [
      {
        id: 'secciones',
        getAll: false,
        filtered: filter_temp,
      },
    ];
    if (idPoligono) {
      catalogsParams = [
        {
          id: 'secciones',
          getAll: false,
          filtered: [{ id: 'cat_secciones.idPoligono', filter: '=', value: idPoligono }],
        },
      ];
    }

    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          setCatSecciones([{ value: 0, label: 'TODAS' }].concat(res.response.catalogs.secciones));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setFilter({
      idRegion: idRegion,
      idMunicipioReportes: '',
      idPoligono: '',
      Seccion: '',
    });
    if (idRegion > 0) loadCatalogsByRegion(idRegion);
    else loadCatalogsByRegion();
  };

  const handleChangeMunicipio = (e) => {
    const value = e.target.value;
    setFilter({
      idRegion: filter.idRegion,
      idMunicipioReportes: value,
      idPoligono: '',
      Seccion: '',
    });
    setVars('filterSavedMap', {
      idRegion: filter.idRegion,
      idMunicipioReportes: value,
      idPoligono: '',
      Seccion: '',
    });
    if (value > 0) loadCatalogsByMunicipio(value);
    else loadCatalogsByMunicipio();
  };

  const handleChangePoligono = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
      Seccion: '',
    });
    loadCatalogsByPoligono(e.target.value);
  };

  const handleChangeSeccion = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let filter_data = [];
    if (filter.idRegion !== '') {
      filter_data.push({
        id: 'cat_secciones.idRegion',
        filter: filter.idRegion === 0 ? 'IN' : '=',
        value: filter.idRegion === 0 ? [1, 2, 3, 4, 5, 6, 7] : filter.idRegion,
      });
    }
    if (filter.idMunicipioReportes !== '') {
      if (filter.idMunicipioReportes !== 0)
        filter_data.push({
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: filter.idMunicipioReportes,
        });
    }
    if (filter.idPoligono !== '') {
      if (filter.idPoligono !== 0)
        filter_data.push({
          id: 'cat_secciones.idPoligono',
          filter: '=',
          value: filter.idPoligono,
        });
    }
    if (filter.Seccion !== '') {
      if (filter.Seccion !== 0)
        filter_data.push({
          id: 'cat_secciones.Seccion',
          filter: '=',
          value: filter.Seccion,
        });
    }

    handleFilter(filter_data);
    setVars('filterSavedMapMunicipio', catMunicipiosReportes);
    setVars('filterSavedMapPoligono', catPoligonos);
    setVars('filterSavedMapSeccion', catSecciones);
    setVars('filterSavedMap', {
      idRegion: filter.idRegion,
      idMunicipioReportes: filter.idMunicipioReportes,
      idPoligono: filter.idPoligono,
      Seccion: filter.Seccion,
    });
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        {region ? (
          <Grid item xs={12}>
            <BasicSelect
              label="Región"
              name="region"
              options={catalogs.regiones}
              value={filter.idRegion}
              onChange={handleChangeRegion}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {municipio ? (
          <Grid item xs={12}>
            <BasicSelect
              name="idMunicipioReportes"
              label="Muncipio"
              options={catMunicipiosReportes}
              value={filter.idMunicipioReportes}
              onChange={handleChangeMunicipio}
              isLoading={loadingCatalogs || isLoadingCatMunicipiosReportes}
              sx={{ width: '100%' }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {poligono ? (
          <Grid item xs={12}>
            <BasicSelect
              name="idPoligono"
              label="Poligono"
              options={catPoligonos}
              value={filter.idPoligono}
              onChange={handleChangePoligono}
              isLoading={loadingCatalogs || isLoadingCatPoligonos}
              sx={{ width: '100%' }}
            />
          </Grid>
        ) : (
          <></>
        )}

        {seccion ? (
          <Grid item xs={12}>
            <BasicSelect
              name="Seccion"
              label="Sección"
              options={catSecciones}
              value={filter.Seccion}
              onChange={handleChangeSeccion}
              isLoading={loadingCatalogs || isLoadingCatSecciones}
              sx={{ width: '100%' }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={
            filter.idRegion === '' &&
            filter.idMunicipioReportes === '' &&
            filter.idPoligono === '' &&
            filter.Seccion === ''
          }
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;
