import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Icon,
  Box,
  CircularProgress,
  ListItem,
  ListItemText,
  Stack,
  Divider,
  Chip,
  ButtonGroup,
  Tooltip,
  IconButton,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import moment from 'moment';
import { ACTIONS_SPEED } from '@data/constants/Mobilization';

const TableBodyReport = ({ loading, data, dataFound, isChip = true }) => {
  return (
    <TableBody>
      {loading ? (
        <TableRow>
          <TableCell colSpan={4}>
            <Box display={'flex'} alignContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {data && data.length > 0 ? (
            data.map((row, index) => (
              <TableRow key={`${row.idUsuario}-${index}`}>
                <TableCell>{row.Seccion}</TableCell>
                <TableCell>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      primary=<Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                        <Typography variant="body2">{row.NombreCompleto}</Typography>
                        {isChip && (
                          <Chip
                            label={`PASE DE LISTA: ${row.FechaHoraPaseLista}`}
                            size="small"
                            sx={{
                              fontSize: 11,
                              backgroundColor: green[50],
                              color: green[900],
                            }}
                          />
                        )}
                      </Stack>
                      secondary={
                        isChip ? (
                          <Stack direction={'row'} fontSize={12} spacing={0.5}>
                            <div> Registrado por: {row.UsuarioReporto}</div>
                            <Divider flexItem orientation="vertical" sx={{ backgroundColor: grey[500] }} />
                            <div>
                              {moment(row.FechaRegistro, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}
                            </div>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                      sx={{ p: 0, m: 0 }}
                    />
                  </ListItem>
                </TableCell>
                <TableCell>{row.ResponsabilidadEtiqueta}</TableCell>
                <TableCell>
                  <ButtonGroup>
                    {ACTIONS_SPEED.map(
                      (action) =>
                        row[action.key] && (
                          <Tooltip key={action.key} title={row[action.key]} placement="left">
                            <IconButton
                              size="small"
                              href={
                                action.link &&
                                `${action.link}${row[action.key]}&text=Hola%20*${row.NombreCompleto}*`
                              }
                              target="_blank"
                            >
                              {action.icon}
                            </IconButton>
                          </Tooltip>
                        )
                    )}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <Box display={'flex'} alignContent={'center'} alignItems={'center'} flexDirection={'column'}>
                  <Icon sx={{ fontSize: '5rem', color: grey[500] }}>
                    {dataFound ? 'person_search' : 'search_off'}
                  </Icon>
                  <Typography fontWeight={600} variant="h6" marginTop={1}>
                    {dataFound ? 'Datos No Disponible' : 'No se econtraron resultados'}
                  </Typography>
                  <Typography variant="caption" marginTop={1}>
                    {dataFound
                      ? ' Utiliza el filtro a tu izquierda para realizar una búsqueda y obtener los resultados deseados.'
                      : 'Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales'}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </TableBody>
  );
};

export default TableBodyReport;
