import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Icon,
  Tab,
  Button,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import UserSearch from '@components/Users/UserSearch';

// Services
import SupportServices from '@services/SupportServices';

const MyUsers = () => {
  const navigate = useNavigate();
  const tabsStyle = {
    flex: 1,
    minHeight: 0,
    minWidth: 'fit-content',
    maxWidth: 'unset',
    whiteSpace: 'nowrap',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [prevIdUsuario, setPrevIdUsuario] = useState(null);
  const [idUsuario, setIdUsuario] = useState(null);
  const [userData, setUserData] = useState(null);
  const [prevIdPropietario, setPrevIdPropietario] = useState(null);
  const [idPropietario, setIdPropietario] = useState(null);
  const [propietarioData, setPropietarioData] = useState(null);
  const [motivo, setMotivo] = useState('');
  const [valueTab, setValueTab] = useState('0');
  const [externalOwner, setExternalOwner] = useState(0);

  useEffect(() => {
    if (idUsuario) {
      if (idUsuario !== prevIdUsuario) setPrevIdUsuario(idUsuario);
    } else setPrevIdUsuario(null);

    if (idPropietario) {
      if (idUsuario === idPropietario) {
        setIdPropietario(null);
        setPrevIdPropietario(null);
        setPropietarioData(null);
      }
    }

    // eslint-disable-next-line
  }, [idUsuario]);

  useEffect(() => {
    if (idPropietario) {
      if (idPropietario !== idUsuario && idPropietario !== prevIdPropietario) {
        setPrevIdPropietario(idPropietario);
      } else if (idPropietario === idUsuario) {
        setIdPropietario(null);
        setPrevIdPropietario(null);
        Swal.fire({ title: 'El padre no puede ser la misma persona', icon: 'warning' });
      }

      if (!propietarioData.EsMiRed) {
        Swal.fire({
          title: '¡Atención!',
          html: 'El responsable que intenta asignar, <strong>NO forma parte de su red</strong>. ¿Desea continuar?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, Continuar!',
          cancelButtonText: 'No, Cancelar',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((res) => {
          if (res.isConfirmed) {
            setExternalOwner(1);
          } else {
            setIdPropietario(null);
            setExternalOwner(0);
          }
        });
      } else {
        setExternalOwner(0);
      }
    } else setPrevIdPropietario(null);
    // eslint-disable-next-line
  }, [idPropietario]);

  const handleChangeTab = (e, newValue) => setValueTab(newValue);

  const handleSave = async () => {
    const params = { idUsuario, idPropietario, Motivo: motivo, PropietarioExterno: externalOwner };
    try {
      setIsLoading(true);
      const result = await SupportServices.updateOwner(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });
        setPrevIdUsuario(null);
        setIdUsuario(null);
        setUserData(null);

        setIdPropietario(null);
        setPrevIdPropietario(null);
        setPropietarioData(null);

        setMotivo('');
        setValueTab('0');
      } else Swal.fire({ title: message, icon: 'warning' });
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ paddingX: { xs: 0, sm: 2 } }}>
        <ActionHeader
          title={'Mis Usuarios'}
          isAction={true}
          titleButton="regresar"
          iconButton="chevron_left"
          handleclick={() => navigate('/mis-usuarios')}
        />
        <LoadingForm
          isLoading={isLoading}
          isOpen={isLoading}
          setIsOpen={() => {}}
          loadinMessage={'Actualizando...'}
        />
        <Card className="card-primary" sx={{ marginBottom: { xs: '0px!important' } }}>
          <CardContent sx={{ padding: { xs: 0, sm: 2 } }}>
            <Box sx={{ typography: 'body1' }}>
              <TabContext value={valueTab}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="tabs de control"
                  variant="scrollable"
                  textColor="secondary"
                  indicatorColor="secondary"
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                  scrollButtons={false}
                >
                  <Tab
                    icon={<Icon>person</Icon>}
                    iconPosition="start"
                    label="Usuario"
                    value="0"
                    sx={tabsStyle}
                  />
                  <Tab
                    icon={<Icon>switch_account</Icon>}
                    iconPosition="start"
                    label="Nuevo Responsable"
                    value="1"
                    disabled={idUsuario ? false : true}
                    sx={tabsStyle}
                  />
                </TabList>
                <TabPanel value="0" sx={{ paddingX: 0, paddingBottom: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={idUsuario ? 6 : 12}>
                      <UserSearch
                        API={SupportServices.getMyUsers}
                        setIdUser={setIdUsuario}
                        clickCard={setUserData}
                        label="Encuentra al usuario y selecciona a quién deseas cambiar de responsable"
                      />
                    </Grid>
                    {idUsuario && userData ? (
                      <Grid item xs={12} md={6}>
                        <Card className="card-primary">
                          <CardContent>
                            <Box display="flex" alignItems="center" mb={1}>
                              <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                person
                              </Icon>
                              <Typography>{userData.NombreCompleto}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb={1}>
                              <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                cake
                              </Icon>
                              <Typography>{userData.Edad} AÑOS</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                location_on
                              </Icon>
                              <Typography>{userData.Municipio}</Typography>
                            </Box>

                            <Box sx={{ marginTop: 2 }}>
                              <Button
                                variant="outlined"
                                endIcon={<Icon>chevron_right</Icon>}
                                disabled={idUsuario ? false : true}
                                onClick={() => setValueTab('1')}
                                fullWidth
                              >
                                Continuar
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value="1" sx={{ paddingX: 0, paddingBottom: 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <UserSearch
                        API={SupportServices.getOwnerList}
                        setIdUser={setIdPropietario}
                        clickCard={setPropietarioData}
                        label="Encuentra al usuario y selecciona el nuevo responsable"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={12} lg={6}>
                          <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
                            <CardContent>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography align="center" fontWeight={600} sx={{ flex: 1 }}>
                                  Usuario
                                </Typography>
                                <IconButton onClick={() => setValueTab('0')}>
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Box>
                              <Box display="flex" alignItems="center" mb={1}>
                                <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                  person
                                </Icon>
                                <Typography>{userData?.NombreCompleto}</Typography>
                              </Box>
                              <Box display="flex" alignItems="center" mb={1}>
                                <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                  cake
                                </Icon>
                                <Typography>{userData?.Edad} AÑOS</Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                  location_on
                                </Icon>
                                <Typography>{userData?.Municipio}</Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={6}>
                          <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
                            <CardContent>
                              <Typography align="center" fontWeight={600} mb={1}>
                                Responsable
                              </Typography>

                              {prevIdPropietario && propietarioData ? (
                                <>
                                  <Box display="flex" alignItems="center" mb={1}>
                                    <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                      person
                                    </Icon>
                                    <Typography>{propietarioData?.NombreCompleto}</Typography>
                                  </Box>
                                  <Box display="flex" alignItems="center" mb={1}>
                                    <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                      cake
                                    </Icon>
                                    <Typography>{propietarioData?.Edad} AÑOS</Typography>
                                  </Box>
                                  <Box display="flex" alignItems="center">
                                    <Icon fontSize="small" sx={{ marginRight: 1 }}>
                                      location_on
                                    </Icon>
                                    <Typography>{propietarioData?.Municipio}</Typography>
                                  </Box>
                                </>
                              ) : (
                                <Typography>Escoge a un responsable</Typography>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12}>
                          <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
                            <CardContent>
                              <TextField
                                label="Motivo del cambio"
                                multiline
                                rows={2}
                                value={motivo}
                                onChange={(e) => setMotivo(e.target.value)}
                                error={motivo.length > 0 && motivo.length < 6}
                                helperText={
                                  motivo.length > 0 &&
                                  motivo.length < 6 &&
                                  'El motivo debe ser mayor a 5 caracteres'
                                }
                                fullWidth
                                required
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<Icon>chevron_left</Icon>}
                            onClick={() => setValueTab('0')}
                            sx={{ flex: { xs: '100%', sm: '50%' } }}
                          >
                            Retroceder
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            endIcon={<Icon>check</Icon>}
                            disabled={idUsuario && prevIdPropietario && motivo.length > 5 ? false : true}
                            onClick={handleSave}
                            sx={{ width: { xs: '100%', sm: '50%' } }}
                          >
                            Actualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default MyUsers;
