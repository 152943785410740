import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Chip, Icon, TextField, InputAdornment, Divider, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import ModalResponsibleList from './ModalResponsibleList';
import ModalUserResponsibleList from '@components/Users/ModalUserResponsibleList';
import { getVars } from '@utils/global';
import middleware from '@middlewares/middleware';
import { isEmptyOrNullObject } from '@utils/validations';
import CompromisoServices from '@services/CompromisoServices';
import UserServices from '@services/UserServices';

const ResponsibleList = (props) => {
  const { errors, touched, values, handleChange, setValues, finished } = props;

  const { user } = getVars('Token');
  const location = useLocation();
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [responsible, setResponsible] = useState({
    value: user.id,
    label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
    TieneUsuario: 1,
  });
  const [userOwner, setUserOwner] = useState({
    value: user.id,
    label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
    TieneUsuario: 1,
  });
  const [disabled, setDisabled] = useState(false);
  const getFindRecord = location.state ? location.state : null;
  const [defaultOwner, setDefaultOwner] = useState({
    value: user.id,
    label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
    TieneUsuario: 1,
  });

  useEffect(() => {
    if (finished && isEmptyOrNullObject(getFindRecord)) {
      const filtered = [
        {
          id: "CONCAT_WS(' ', compromisos_unicos.Nombre, compromisos_unicos.Paterno, compromisos_unicos.Materno)",
          filter: '=',
          value: responsible.label,
        },
      ];
      CompromisoServices.getResponsibles({ page: 0, pageSize: 10, filtered })
        .then((res) => {
          if (res.results) {
            let data = res.response.data[0];
            if (data.TieneUsuario === 1) {
              setDisabled(true);
            }
          } else {
            setDisabled(false);
          }
        })
        .catch((error) => {
          setDisabled(false);
        });
    }
    // eslint-disable-next-line
  }, [finished]);

  useEffect(() => {
    if (!isEmptyOrNullObject(getFindRecord)) {
      if (getFindRecord.idUsuarioPropietario !== responsible.idUsuario) {
        const filtered = [{ id: 'usuarios.id', filter: '=', value: getFindRecord.idUsuarioPropietario }];
        UserServices.getResponsibles({ page: 0, pageSize: 10, filtered }).then((res) => {
          if (res.results) {
            let data = res.response.data[0];
            setResponsible(data);
            setDefaultOwner(data);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [getFindRecord]);

  useEffect(() => {
    if (!isEmptyOrNullObject(responsible)) {
      setDisabled(false);
      if (responsible.TieneUsuario) {
        setValues({
          ...values,
          idUsuarioPropietario: responsible.idUsuario,
          idUsuarioResponsable: null,
          idCompromisoPropietario: null,
        });
      } else {
        setValues({
          ...values,
          idUsuarioPropietario: null,
          idCompromisoPropietario: responsible.value,
          idUsuarioResponsable: values.idUsuarioResponsable ? values.idUsuarioResponsable : user.id,
        });
      }
    }
    // eslint-disable-next-line
  }, [responsible]);

  useEffect(() => {
    if (!isEmptyOrNullObject(userOwner) && values.idCompromisoPropietario) {
      setValues({
        ...values,
        idUsuarioResponsable: userOwner.idUsuario,
      });
    }
    // eslint-disable-next-line
  }, [userOwner]);

  useEffect(() => {
    if (values.id) {
      if (values.idUsuarioPropietario === defaultOwner.value) {
        setResponsible(defaultOwner);
      }
    }
    // eslint-disable-next-line
  }, [values.idUsuarioPropietario]);

  const resetResponsible = () => {
    setResponsible(defaultOwner);

    setUserOwner({
      value: user.id,
      idUsuario: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    });
  };

  const resetUserOwner = () => {
    setValues({
      ...values,
      idUsuarioResponsable: user.id,
    });

    setUserOwner({
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    });
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
        <Chip
          icon={<Icon>groups2</Icon>}
          label={`${middleware.checkMenuAction('Capturista') ? 'Seleccione al' : ''} Responsable`}
        />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 1 }}>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <TextField
            required={true}
            disabled={true}
            label="Nombre del Responsable del 1xN"
            value={responsible?.label}
            name="Nombre"
            sx={{ width: '100%' }}
            type="text"
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={
              middleware.checkMenuAction('Capturista') && {
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                    <Button color="warning" onClick={(e) => resetResponsible()} sx={{ ml: 0, pl: 0 }}>
                      <Icon>restore</Icon>
                    </Button>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Button color="info" onClick={(e) => setOpenModal1(true)} sx={{ mr: 0, pr: 0 }}>
                      <Icon>search</Icon>
                      <small>Buscar</small>
                    </Button>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid2>

        {responsible?.TieneUsuario === 0 && (
          <Grid2 xs={12} sm={12} md={4} lg={4}>
            <TextField
              required={true}
              disabled={true}
              label="Nombre del Coordinador Responsable"
              value={userOwner.label}
              name="Nombre"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                    <Button
                      color="warning"
                      onClick={(e) => resetUserOwner()}
                      sx={{ ml: 0, pl: 0 }}
                      disabled={disabled}
                    >
                      <Icon>restore</Icon>
                    </Button>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Button color="info" onClick={(e) => setOpenModal2(true)} disabled={disabled}>
                      <Icon>search</Icon>
                      <small>Buscar</small>
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
        )}
      </Grid2>

      <ModalResponsibleList
        openModal={openModal1}
        setOpenModal={setOpenModal1}
        setResponsible={setResponsible}
      />

      <ModalUserResponsibleList
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        setResponsible={setUserOwner}
      />
    </Box>
  );
};

export default ResponsibleList;
