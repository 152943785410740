import React, { useState, useEffect } from 'react';

//MUI
import { Box, Button, Grid, TextField, Slider, Typography } from '@mui/material';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import SelectSearch from './../Selects/SelectSearch';

//Components
import InputSelect from '@components/Selects/BasicSelect';
import CatalogServices from '@services/CatalogServices';
import Swal from 'sweetalert2';
import FilterCollapse from '@components/Filters/FilterCollapse';
import { isTypePhone } from '@utils/validations';
import SocialService from '@services/SocialServices';

const Filter = ({
  onChangeFilter,
  filterCompromiso = true,
  poligono = true,
  seccion = true,
  structure = true,
  mujeres = false,
  fathers = false,
}) => {
  //variables
  const animatedComponents = makeAnimated();
  const defaultOption = { value: 0, label: 'TODOS' };
  //States
  const [, setCatRegions] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catRegionsFilterMujeres, setCatRegionsFilterMujeres] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityMujeres, setCatMunicipalityMujeres] = useState([]);
  const [catMunicipalityFilterMujeres, setCatMunicipalityFilterMujeres] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catPolygon, setCatPolygon] = useState([]);
  const [catPolygonFilter, setCatPolygonFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [catGeneros, setCatGeneros] = useState([]);
  const [mySegmentos, setMySegmentos] = useState([defaultOption]);
  const [clean, setClean] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectSegmentos, setSelectSegmentos] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
    Sexo: 0,
    Segmento: [],
    Edad: [0, 101],
  });
  const [filter, setFilter] = useState({
    Celular: '',
    ClaveElector: '',
    NombreCompleto: '',
    Calle: '',
    Colonia: '',
    Ranking: '',
  });
  const [dataChart, setDataChart] = useState({
    title: '',
    date: '',
    categories: [],
    series1: [],
    series2: [],
  });

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleChange = (event, newValue) => {
    setDataCatalog({
      ...dataCatalog,
      Edad: newValue,
    });
  };
  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: 'regiones', getAll: false },
      { id: 'regiones_mujeres', getAll: false },
      { id: 'municipios', getAll: false },
      { id: 'municipios_mujeres', getAll: false },
      { id: 'poligonos', getAll: false },
      { id: 'secciones', getAll: false },
      { id: 'generos', getAll: false },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case 'regiones':
                setCatRegions([defaultOption].concat(res.response.catalogs[item.id]));
                setCatRegionsFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'regiones_mujeres':
                setCatRegionsFilterMujeres([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'municipios':
                setCatMunicipality([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipalityFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'municipios_mujeres':
                console.log(res.response.catalogs[item.id]);
                setCatMunicipalityMujeres([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipalityFilterMujeres([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'poligonos':
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;

              case 'secciones':
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
                break;

              case 'generos':
                setCatGeneros([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }));

    SocialService.catalogoStruct({})
      .then((res) => {
        if (res.results) {
          if (fathers) {
            let arrayall = res.response.map((item) => {
              return {
                value: item.id,
                label: item.NombreSocial,
                idPadre: item.idPadre,
                Nivel: item.Nivel,
              };
            });

            setMySegmentos(arrayall.filter((item) => item.Nivel === 1));
          } else {
            setMySegmentos(
              res.response.map((item) => {
                return {
                  value: item.id,
                  label: item.NombreSocial,
                  idPadre: item.idPadre,
                };
              })
            );
          }
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });

    /*   if (AllStructure) {
      SocialService.getStruct({})
        .then((res) => {
          if (res.results) {
            setMySegmentos(res.response);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        });
    } else {
      SocialService.catalogoStruct({
        filtered: [{ id: "cat_social.Nivel", value: 1, filter: "=" }],
      })
        .then((res) => {
          if (res.results) {
            setMySegmentos(
              res.response.map((item) => {
                return {
                  value: item.id,
                  label: item.NombreSocial,
                  idPadre: item.idPadre,
                };
              })
            );
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        });
    } */

    // eslint-disable-next-line
  }, []);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(catMunicipality.filter((item) => item.idRegion === idRegion))
      );
      setCatPolygonFilter(catPolygon.filter((item) => item.idRegion === idRegion));
      setCatSectionFilter(catSection.filter((item) => item.idRegion === idRegion));
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(catPolygon.filter((item) => item.idMunicipio === idMunicipality));
      setCatSectionFilter(catSection.filter((item) => item.idMunicipio === idMunicipality));
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangeRegionMujer = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(catMunicipalityMujeres.filter((item) => item.idRegion === idRegion))
      );
      setCatPolygonFilter(catPolygon.filter((item) => item.idRegion === idRegion));
      setCatSectionFilter(catSection.filter((item) => item.idRegion === idRegion));
    } else {
      setCatMunicipalityMujeres(catMunicipalityMujeres);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangePolygon = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Poligono: array_poligon,
    });

    if (array_poligon.length > 0) {
      setCatSectionFilter(catSection.filter((item) => array_poligon.some((id) => id === item.idPoligono)));
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Seccion: array_section,
    });
  };

  const handleChangeSegmentos = (e) => {
    setSelectSegmentos(e);
    setDataCatalog({
      ...dataCatalog,
      Segmento: e.map((item) => item.value),
    });
  };

  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Sexo: dataCatalog.Sexo,
      Celular: filter.Celular,
      ClaveElector: filter.ClaveElector,
      NombreCompleto: filter.NombreCompleto,
      Calle: filter.Calle,
      Colonia: filter.Colonia,
      Ranking: filter.Ranking,
      DistritoFederal: filter.DistritoFederal,
      DistritoLocal: filter.DistritoLocal,
      Segmento: dataCatalog.Segmento,
      Edad: dataCatalog.Edad,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
      Sexo: 0,
      Segmento: [],
      Edad: [0, 101],
      Celular: '',
      ClaveElector: '',
      NombreCompleto: '',
      Calle: '',
      Colonia: '',
      Ranking: '',
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatPolygonFilter(catPolygon);
    setCatSectionFilter(catSection);

    setDataCatalog({
      ...dataCatalog,
      Region: 0,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
      Segmento: [],
      Sexo: 0,
      Edad: [0, 101],
    });

    /* 
    setData({
      ...data,
      filtered: [],
    }); */

    setFilter(newFilter);
    onChangeFilter(newFilter);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  };

  return (
    <>
      <FilterCollapse>
        <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
          <Grid item xs={12} md={!poligono ? (!seccion ? 4 : 4) : 3}>
            {mujeres ? (
              <InputSelect
                label="Región"
                options={catRegionsFilterMujeres}
                name="region"
                value={
                  catRegionsFilterMujeres.length === 2 ? catRegionsFilterMujeres[1].value : dataCatalog.Region
                }
                onChange={handleChangeRegionMujer}
                disabled={catRegionsFilterMujeres.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            ) : (
              <InputSelect
                label="Región"
                options={catRegionsFilter}
                name="region"
                value={catRegionsFilter.length === 2 ? catRegionsFilter[1].value : dataCatalog.Region}
                onChange={handleChangeRegion}
                disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={!poligono ? (!seccion ? 4 : 4) : 3}>
            {mujeres ? (
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilterMujeres}
                name="municipio"
                value={
                  catMunicipalityFilterMujeres.length === 2
                    ? catMunicipalityFilterMujeres[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipality}
                disabled={catMunicipalityMujeres.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            ) : (
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                  catMunicipalityFilter.length === 2 ? catMunicipalityFilter[1].value : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipality}
                disabled={catMunicipality.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            )}
          </Grid>
          {poligono && (
            <Grid item xs={12} md={3}>
              <Select
                placeholder="Polígono"
                styles={selectStyles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={catPolygonFilter.length === 1 ? [catPolygonFilter[0]] : selectedPolygons}
                onChange={handleChangePolygon}
                isMulti
                options={catPolygonFilter}
                isDisabled={catPolygonFilter.length === 1 ? true : false}
              />
            </Grid>
          )}
          {seccion && (
            <Grid item xs={12} md={3}>
              <Select
                placeholder="Sección"
                styles={selectStyles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={catSectionFilter.length === 1 ? [catSectionFilter[0]] : selectedSections}
                onChange={handleChangeSection}
                isMulti
                options={catSectionFilter}
                isDisabled={catSectionFilter.length === 1 ? true : false}
              />
            </Grid>
          )}
          {structure && (
            <Grid item xs={12} md={!poligono ? (!seccion ? 4 : 4) : 12}>
              <SelectSearch
                multiple={true}
                placeholder="Segmentos"
                name="seccion"
                closeMenuOnSelect={false}
                value={selectSegmentos}
                onChange={handleChangeSegmentos}
                options={mySegmentos}
              />
            </Grid>
          )}
        </Grid>
        {filterCompromiso && (
          <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Calle"
                size="small"
                name="Calle"
                value={filter.Calle.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Colonia"
                size="small"
                name="Colonia"
                value={filter.Colonia.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/*       <Grid item xs={12} md={3}>
            <InputSelect
              label="Ranking"
              name="Ranking"
              value={filter.Ranking}
              options={[
                { value: 0, label: "Todos" },
                { value: 1, label: "1" },
                { value: 2, label: "2" },
              ]}
              sx={{ width: "100%" }}
              onChange={hadleChangeTextField}
            />
          </Grid> */}
            <Grid item xs={12} md={3}>
              <InputSelect
                label="Sexo"
                name="Sexo"
                value={filter.Sexo}
                options={catGeneros}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setDataCatalog({
                    ...dataCatalog,
                    Sexo: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Celular"
                size="small"
                name="Celular"
                value={filter.Celular}
                //onChange={hadleChangeTextField}
                onChange={(e) =>
                  hadleChangeTextField({
                    target: {
                      name: 'Celular',
                      value: isTypePhone(e.target.value) ? e.target.value : filter.Celular,
                    },
                  })
                }
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography id="track-false-slider" gutterBottom>
                Edades
              </Typography>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={dataCatalog.Edad}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Nombre"
                size="small"
                name="NombreCompleto"
                value={filter.NombreCompleto.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Clave Elector"
                size="small"
                name="ClaveElector"
                value={filter.ClaveElector.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        )}

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primaryDark" onClick={searchFilter}>
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default Filter;
