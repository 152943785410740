import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, Button, Chip } from '@mui/material';
import Swal from 'sweetalert2';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import LoadingButton from '@mui/lab/LoadingButton';

const ConfirmAssignment = (props) => {
  const { onClose, open, data, catalogo, responsable, total, ...other } = props;
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const radioGroupRef = useRef(null);
  const labels = [
    {
      value: 'cat_secciones.idMunicipio',
      label: 'Municipio',
      catalogo: 'municipios',
    },
    {
      value: 'compromisos_unicos_fa.idUsuarioCreo',
      label: 'Capturistas',
      catalogo: 'capturistas',
    },
    {
      value: 'compromisos_unicos_fa.ColoniaVive',
      label: 'Colonias',
      catalogo: 'colonias',
    },
    { value: 'cat_secciones.Seccion', label: 'Sección', catalogo: 'secciones' },
    {
      value: 'compromisos_unicos_fa.DataResult',
      label: 'Estatus',
      catalogo: 'estatus',
    },
    {
      value: " CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno) ",
      label: 'Personas',
    },
  ];

  useEffect(() => {
    let datosView = [];
    for (const filter of data.filtered) {
      let info = labels.find((item) => item.value === filter.id);
      let dataFiltro;
      let newValue;
      if (typeof filter.value === 'string') {
        dataFiltro = catalogo[info.catalogo].find((i) => i.value === filter.value);
        if (dataFiltro) {
          newValue = dataFiltro.label;
        }
      } else {
        dataFiltro = catalogo[info.catalogo].filter((i) => filter.value.includes(i.value));
        newValue = dataFiltro.map((e) => e.label);
      }
      datosView.push({ ...filter, label: info.label, value: newValue });
    }

    setValue(datosView);
    // eslint-disable-next-line
  }, [open, catalogo]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(false);
  };
  /* 
  const handleOk = () => {
    onClose(value);
  }; */

  const handleSend = () => {
    if (isLoading) {
      return false;
    }
    setIsLoading(true);

    let send = {
      ...data,
      idMovilizador: responsable.idUsuario,
      Resultados: total,
    };
    FrenteAmplioServices.setMovilizacion(send)
      .then((res) => {
        if (res.success && res.results) {
          onClose(false);
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          onClose(false);
          Swal.fire({
            title: res.message,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((e) => {
        onClose(false);
        Swal.fire({
          title: e.message,
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          minWidth: '150px',
          maxHeight: 435,
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Grid2 container sx={{ justifyContent: 'center' }} spacing={1}>
          <Grid2 item>
            <ErrorOutlineIcon fontSize="large" sx={{ color: '#FFC300' }} />
          </Grid2>
          <Grid2 item>
            <Typography variant="h6" sx={{ mt: 1, color: '#FFC300' }}>
              {' '}
              Confirmar Asignación{' '}
            </Typography>
          </Grid2>
        </Grid2>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
          Datos de Asignación
        </Typography>
        <br />
        <Grid2 container sx={{ justifyContent: 'center' }}>
          {value.map((item) => {
            return (
              <>
                <Grid2 item xs={12} sm={12} md={3} lg={3}>
                  <Typography variant="subtitle1" color="text.secondary">
                    {`${item.label}:`}
                  </Typography>
                </Grid2>
                <Grid2 item justifyContent="center" alignItems="center" xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="h6"> {`${item.value}`}</Typography>
                </Grid2>
              </>
            );
          })}
          <Grid2 item xs={12} sm={12} md={3} lg={3}>
            <Typography variant="subtitle1" color="text.secondary">
              Total a asignar
            </Typography>
          </Grid2>
          <Grid2 item xs={12} sm={12} md={8} lg={8}>
            <Typography variant="h6"> {`${total}`}</Typography>
          </Grid2>
          <Grid2 item xs={12} sm={12} md={3} lg={3}>
            <Typography variant="subtitle1" color="text.secondary">
              Asignar a:
            </Typography>
          </Grid2>
          <Grid2 item xs={12} sm={12} md={8} lg={8}>
            <Chip size="large" label={`${responsable.label}`} variant="outlined" color="primary" />
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleSend}>
          Asignar
        </LoadingButton>
        <Button autoFocus onClick={handleCancel} color="error" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmAssignment;
