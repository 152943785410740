import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  CircularProgress,
  circularProgressClasses,
  Icon,
} from '@mui/material';
import { grey } from '@mui/material/colors';

const PieProgressCard = (props) => {
  const { loading, data = '0', config = {}, styleSettings = {}, title = '' } = props;

  const [stateConfig, setStateConfig] = useState({
    title: title,
    ColorCircularProgress: '#0d47a1',
    icon: '',
  });

  const [stateStyleSettings, setStateStyleSettings] = useState({
    variant: 'elevation',
    borderRadius: '12px',
    padding: 0,
    width: '100%',
    height: 'auto',
  });

  useEffect(() => {
    Object.keys(config).forEach(function (key) {
      setStateConfig((prevState) => ({ ...prevState, [key]: config[key] }));
    });
  }, [config]);

  useEffect(() => {
    Object.keys(styleSettings).forEach(function (key) {
      setStateStyleSettings((prevState) => ({
        ...prevState,
        [key]: styleSettings[key],
      }));
    });
  }, [styleSettings]);

  return (
    <Card
      className="card-primary"
      variant={stateStyleSettings.variant}
      sx={{
        borderRadius: stateStyleSettings.borderRadius,
        width: stateStyleSettings.width,
        height: stateStyleSettings.height,
        p: stateStyleSettings.padding,
      }}
    >
      <CardContent sx={{ height: 'auto', alignContent: 'center' }}>
        <Stack direction={'row'} spacing={1} justifyContent="center" marginBottom={2}>
          <Icon sx={{ color: grey[600] }}>{stateConfig.icon}</Icon>
          <Typography marginBottom={2} fontWeight={600} variant="h6">
            {title}
          </Typography>
        </Stack>
        <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex', mt: 0.3 }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
              }}
              size={80}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={parseInt(data)}
              // disableShrink={true}
              sx={{
                color: stateConfig.ColorCircularProgress,
                animationDuration: '550ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={80}
              thickness={4}
              {...props}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: stateConfig.iconColor, m: 1 }} size={15} />
              ) : (
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontFamily: 'Helvetica Neue',
                    fontSize: 14,
                    color: stateConfig.ColorCircularProgress,
                  }}
                >{`${data}%`}</Typography>
              )}
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: 'light',
              fontFamily: 'Helvetica Neue',
            }}
          >
            {stateConfig.titlecontent}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PieProgressCard;
