import { Box, Card, CardContent, Icon, Stack, Typography, CircularProgress, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { blue } from '@mui/material/colors';

const CardTreeColumn = ({
  icon,
  title,
  total,
  subtitle,
  subIcon,
  icon2,
  title2,
  total2,
  subtitle2,
  subIcon2,
  icon3,
  title3,
  total3,
  subtitle3,
  subIcon3,
  loading,
  component,
  component2,
  component3,
}) => {
  return (
    <Card className="card-primary">
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 item xs={4} sm={4} md={4} lg={4}>
            <Stack direction="row" spacing={3} alignItems="center">
              {component ? (
                component
              ) : (
                <Box
                  width={'90px'}
                  height={'90px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  margin={'10px'}
                  sx={{ backgroundColor: blue[50], borderRadius: '50%' }}
                >
                  <Icon sx={{ fontSize: '3rem', color: blue[800] }}>{icon}</Icon>
                </Box>
              )}
              <Box margin={'10px'}>
                <Typography variant="body2" fontWeight={500}>
                  {title}
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h3" fontWeight={800}>
                    {total}
                  </Typography>
                )}

                <Typography
                  component={'subtitle2'}
                  variant="h5"
                  fontWeight={300}
                  display={'flex'}
                  alignItems={'center'}
                >
                  {subtitle}
                  <Icon sx={{ fontSize: '1rem', mr: 1, color: blue[800] }}>{subIcon}</Icon>
                </Typography>
              </Box>
            </Stack>
          </Grid2>
          <Grid2 item xs={4} sm={4} md={4} lg={4}>
            <Paper sx={{ width: '100%', height: '100%' }} elevation={4}>
              <Stack direction="row" spacing={3} alignItems="center">
                {component ? (
                  component2
                ) : (
                  <Box
                    width={'90px'}
                    height={'90px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    margin={'10px'}
                    sx={{ backgroundColor: blue[50], borderRadius: '50%' }}
                  >
                    <Icon sx={{ fontSize: '3rem', color: blue[800] }}>{icon2}</Icon>
                  </Box>
                )}
                <Box margin={'10px'}>
                  <Typography variant="body2" fontWeight={500}>
                    {title2}
                  </Typography>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h3" sx={{ color: blue[800] }} fontWeight={800}>
                      {total2}
                    </Typography>
                  )}

                  <Typography
                    component="subtitle2"
                    variant="h5"
                    fontWeight={300}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    {subtitle2}
                    <Icon sx={{ fontSize: '1rem', mr: 1, color: blue[800] }}>{subIcon2}</Icon>
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid2>
          <Grid2 item xs={4} sm={4} md={4} lg={4}>
            <Stack direction="row" spacing={3} alignItems="center">
              {component ? (
                component3
              ) : (
                <Box
                  width={'90px'}
                  height={'90px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  margin={'10px'}
                  sx={{ backgroundColor: blue[50], borderRadius: '50%' }}
                >
                  <Icon sx={{ fontSize: '3rem', color: blue[800] }}>{icon3}</Icon>
                </Box>
              )}
              <Box margin={'10px'}>
                <Typography variant="body2" fontWeight={500}>
                  {title3}
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h3" fontWeight={800}>
                    {total3}
                  </Typography>
                )}

                <Typography
                  component={'subtitle2'}
                  variant="h5"
                  fontWeight={300}
                  display={'flex'}
                  alignItems={'center'}
                >
                  {subtitle3}
                  <Icon sx={{ fontSize: '1rem', mr: 1, color: blue[800] }}>{subIcon3}</Icon>
                </Typography>
              </Box>
            </Stack>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};
export default CardTreeColumn;
