import { useState, useEffect } from 'react';

// Material UI
import { Button, TextField, Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MobilizationService from '@services/MovilizationServices';
import Swal from 'sweetalert2';

// Componentes
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Utilidades
import { isEmptyOrNullObject } from '@utils/validations';
import esLocale from 'date-fns/locale/es';

const Filter = (props) => {
  const { onSearch, onClear, isLoading, catalogs, tabValue } = props;

  const defaultOption = { value: 0, label: 'TODOS' };
  const [catalogsFiltered, setCatalogsFiltered] = useState({});
  const [filterValue, setFilterValue] = useState({
    fecha: null,
    usuario: 0,
    seccion: 0,
    casilla: 0,
  });

  const [isLoadingCasillas, setIsLoadingCasillas] = useState(false);

  const [catalogsCasillas, setCatalogsCasillas] = useState([defaultOption]); // Aquí se agrega la opción "TODOS" como primer elemento

  useEffect(() => {
    if (isEmptyOrNullObject(catalogsFiltered)) setCatalogsFiltered(catalogs);
    // eslint-disable-next-line
  }, [catalogs]);

  const isIncluded = (value) => value.includes(tabValue);

  const handleChangeFilter = ({ name, value }) => setFilterValue((prev) => ({ ...prev, [name]: value }));

  const handleCatalogFilterChange = async ({ filterKey, filterValue, filterType }) => {
    // const catalog = catalogs[filterKey].filter((item) => item[filterType] === filterValue);
    // const option = catalog.length > 1 ? [defaultOption] : [];

    // setCatalogsFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

    // if (catalog.length === 1) {
    //   const key = handleOneCatalog(filterKey);
    //   setTimeout(() => {
    //     handleChangeFilter({ name: key, value: catalog[0].value });
    //   }, 500);
    // }

    setIsLoadingCasillas(true);

    try {
      const result = await MobilizationService.validateSection(filterValue);
      const { results, response, message } = await result;

      const uniqueCasillaOptions = new Set();
      response.casillas.forEach((item) => {
        uniqueCasillaOptions.add(item.idCasilla);
      });

      const options = Array.from(uniqueCasillaOptions).map((idCasilla) => {
        const casilla = response.casillas.find((item) => item.idCasilla === idCasilla);
        return {
          value: casilla.idCasilla,
          label: casilla.NombreCasilla,
        };
      });

      // setCatalogsFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

      if (results) {
        setCatalogsCasillas([defaultOption, ...options]); // Aquí se establece la opción "TODOS" seguida de las opciones obtenidas
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingCasillas(false);
    }
  };

  // const handleOneCatalog = (key) => {
  //   switch (key) {
  //     case "secciones":
  //       return "seccion";
  //     case "casillas":
  //       return "casilla";
  //     case "usuarios":
  //       return "usuario";
  //     default:
  //       return "";
  //   }
  // };

  const handleChangeSection = (_idSection) => {
    handleCatalogFilterChange({ filterKey: 'casillas', filterType: 'Seccion', filterValue: _idSection });
  };

  const handleChangeCasilla = (_idPolygon) => {};

  const handleChangeUsuario = (_idPolygon) => {};

  const handleSearch = () => {
    const tab2 = [
      { id: 'Seccion', key: 'seccion' },
      { id: 'idCasilla', key: 'casilla' },
    ];

    let filters = [
      { id: 'Fecha', key: 'fecha' },
      { id: 'idUsuario', key: 'usuario' },
    ];

    if (tabValue === '2') filters = [...tab2, ...filters];

    /* const valid = (value, key) => {
      switch (key) {
        case "fecha":
          return value[key] !== null;
        default:
          return value[key] > 0;
      }
    }; */

    const filtered = filters
      .filter((filter) => filterValue[filter.key] !== null && filterValue[filter.key] !== 0)
      .map((filter) => {
        if (filter.key === 'fecha') {
          // Transformar la fecha al formato "YYYY-MM-DD"
          const formattedDate = filterValue[filter.key].toISOString().substring(0, 10);

          return {
            id: filter.id,
            filter: '=',
            value: formattedDate,
          };
        } else {
          return {
            id: filter.id,
            filter: '=',
            value: filterValue[filter.key],
          };
        }
      });

    // onSearch({ filtered: filtered });
    onSearch(filtered);
  };

  const handleClear = () => {
    setFilterValue({
      fecha: null,
      usuario: 0,
      seccion: 0,
      casilla: 0,
    });
    onClear();
  };

  useEffect(() => {
    setFilterValue({
      fecha: null,
      usuario: 0,
      seccion: 0,
      casilla: 0,
    });
  }, [tabValue]);

  return (
    <Box sx={{ mt: 2, paddingX: 2 }}>
      <Grid2 container marginTop={1} spacing={2}>
        {isIncluded(['2']) && (
          <Grid2 xs={12} sm={6} md={12} lg={12}>
            <AdvancedSelect
              name="seccion"
              label="Sección"
              value={filterValue.seccion}
              options={catalogsFiltered?.secciones}
              onChange={(e) => {
                handleChangeFilter({ name: e.name, value: e.value });
                handleChangeSection(e.value);
              }}
              isSearchable
              isLoading={isLoading}
              disabled={catalogsFiltered?.secciones?.length <= 1}
            />
          </Grid2>
        )}

        {isIncluded(['2']) && (
          <Grid2 xs={12} sm={6} md={12} lg={12}>
            <AdvancedSelect
              name="casilla"
              label="Casilla"
              options={catalogsCasillas}
              value={filterValue.casilla}
              onChange={(e) => {
                handleChangeFilter({ name: e.name, value: e.value });
                handleChangeCasilla(e.value);
              }}
              isSearchable
              isLoading={isLoading || isLoadingCasillas}
              disabled={catalogsFiltered?.casillas?.length <= 1 || filterValue?.seccion === 0}
            />
          </Grid2>
        )}

        {isIncluded(['1', '2']) && (
          <Grid2 xs={12} sm={6} md={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <DatePicker
                label="Ingresa un fecha"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                value={filterValue?.fecha}
                onChange={(value) => {
                  handleChangeFilter({ name: 'fecha', value: value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                    sx={{
                      width: { xs: '100%' },
                      marginY: { xs: 1, sm: 0 },
                      '& .MuiInputBase-input': {
                        fontSize: '0.875rem',
                        padding: '10px 14px',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid2>
        )}

        {isIncluded(['1', '2']) && (
          <Grid2 xs={12} sm={6} md={12} lg={12}>
            <AdvancedSelect
              name="usuario"
              label="Usuario"
              options={catalogsFiltered?.usuarios}
              value={filterValue.usuario}
              onChange={(e) => {
                handleChangeFilter({ name: e.name, value: e.value });
                handleChangeUsuario(e.value);
              }}
              isSearchable
              isLoading={isLoading}
              disabled={catalogsFiltered?.casillas?.length <= 1}
            />
          </Grid2>
        )}
      </Grid2>

      <Stack direction={'row'} spacing={1} marginTop={2} justifyContent={'flex-end'}>
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
          Limpiar
        </Button>
        <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
          Filtrar
        </Button>
      </Stack>
    </Box>
  );
};

export default Filter;
