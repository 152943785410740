import { useEffect, useState } from 'react';
import BasicTable from '@components/Tables/BasicTable';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import moment from 'moment';
import { isEmptyOrNullObject } from '@utils/validations';
import PlacementColumns from '@components/Charts/DashChart';
import middleware from '@middlewares/middleware';
import Card from '@components/Card/Card';
import Filter from '@components/Territorial/Filter';
import MessageAlert from '@components/Downloads/MessageAlert';
import Download from '@components/Downloads/Download';
import { useCatalogs } from '@hooks/useCatalogs';
import { useNavigate } from 'react-router-dom';
import CardCommitments from '@components/Card/CardStats';
import CardCommitmentsTree from '@components/Card/StatesCard';
import { numberWithCommas } from '@utils/Utilities';
import {
  Container,
  CardContent,
  Box,
  Button,
  Icon,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
  Chip,
} from '@mui/material';
import Swal from 'sweetalert2';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MovingIcon from '@mui/icons-material/Moving';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  TERRITORIAL_REGIONAL,
  TERRITORIAL_MPAL,
  TERRITORIAL_DISTRITAL,
  TERRITORIAL_POLIGONAL,
  TERRITORIAL_SECCIONAL,
  TERRITORIAL_MANZANERA,
} from '@data/constants';

//servicios
import TerritorialServices from '../../services/TerritorialServices';

const StructElectoral = (props) => {
  const navigate = useNavigate();
  let today = new Date();
  // catalogo
  const catalogsParams = [{ id: 'calidad_representante' }, { id: 'perfiles_hijos', getAll: false }];
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [total, setTotal] = useState(0);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [dataChart, setDataChart] = useState({
    title: 'Estructura',
    subtitle: today,
    dato: [],
    categories: [],
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [columns, setColumns] = useState([]);
  const [state, setState] = useState(false);
  const [responsibilities, setResponsibilities] = useState([]);
  const [loadingCharts, setLoadingChart] = useState(false);
  const [loadingStats, setLoadingStast] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [dataTotales, setDataTotales] = useState({
    MetaCompromisos: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    regional: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    municipales: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    poligonal: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    seccionales: {
      MAvanMP1: 0,
      AvanMP2: 0,
      AvanMP3: 0,
      FechaActualizo: '2024-03-11 02:00:10',
      MMP1: 0,
      MMP2: 0,
      MMP3: 0,
      Meta: 0,
      TotalAvance: 0,
    },
  });
  const [chartCompromisos, setChartCompromisos] = useState({
    type: 10,
    filtered: [],
  });

  useEffect(() => {
    if (!load) {
      let perfiles = catalogs.perfiles_hijos.filter((item) =>
        [10, 11, 12, 13, 14].includes(parseInt(item.value))
      );
      setResponsibilities(perfiles);
    }
  }, [load]);

  useEffect(() => {
    if (responsibilities.length > 0) {
      setLoadingStast(true);
      setLoading(true);
      Stast();
      handleChangeTab({}, value);
    }
  }, [responsibilities, tableData]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Poligono, Seccion } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_region.id', filter: '=', value: Region }];

      filtro = [...filtro, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: 'cat_municipios_reportes.id', filter: '=', value: Municipio }];

      filtro = [...filtro, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];
    }
    if (Poligono.length > 0) {
      filtered = [...filtered, { id: 'cat_poligonos.id', filter: 'IN', value: Poligono }];

      filtro = [...filtro, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];
    }
    if (Seccion > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];

      filtro = [...filtro, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    // setChartCompromisos({filtered: filtro})
  };

  const handleExport = () => {
    setIsDownloading(true);
    /*     SocialService.exportCompromisos({filtered:tableData.filtered})
          .then((res) => {
            if (res.success) {
              setShowMessage(true);
              setSuccessDownload(true);
            } else {
              setShowMessage(true);
              setSuccessDownload(false);
            }
          })
          .catch((error) => {
            setShowMessage(true);
            setSuccessDownload(false);
          })
          .finally(() => setIsDownloading(false)); */
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const [data, setData] = useState([
    {
      Municipio: 'Abasolo',
      MetaGeneral: 500,
      AvanceEsperadoGeneral: 100,
      AvanceGeneral: 50,
      porcentajeAvanceGeneral: 10,
      Meta: 50,
      AvanceEsperado: 20,
      Pro1: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          20
        </Button>
      ),
      Pro2: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          210
        </Button>
      ),
      Sup1: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          20
        </Button>
      ),
      Sup2: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          10
        </Button>
      ),
      'Pro1%': 20,
      'Pro2%': 20,
      'Sup1%': 20,
      'Sup2%': 20,
    },
  ]);

  const handleChangeTab = (event, newValue) => {
    setLoading(true);
    setColumns([]);
    setValue(newValue);
    let tab = responsibilities[newValue];

    switch (tab.value) {
      case TERRITORIAL_REGIONAL:
        DataRegional();
        break;
      case TERRITORIAL_MPAL:
        DataMunicipio();
        break;
      case TERRITORIAL_DISTRITAL:
        /*     DataAbogados(); */
        break;
      case TERRITORIAL_POLIGONAL:
        DataPoligono();
        break;
      case TERRITORIAL_SECCIONAL:
        DataSeccion();
        break;
      case TERRITORIAL_MANZANERA:
        DataManzana();
        break;
      default:
        break;
    }
  };

  const DataRegional = () => {
    setLoading(true);
    setData([]);
    setColumns([
      { id: 'Region', label: 'Región', width: 25, align: 'center' },
      {
        id: 'MetaEstructura',
        label: 'Meta Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'AvanceEstructura',
        label: 'Avan. Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'PorcentajeEstructura',
        width: 25,
        label: '% Avan. Estructura',
        align: 'center',
      },
    ]);

    TerritorialServices.getProgressRegion(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaCompromisos: item.MetaCompromisos ? numberWithCommas(item.MetaCompromisos) : '0',
                AvEsperado: item.AvEsperado ? item.AvEsperado : '0',
                AvanceCompromisos: numberWithCommas(item.AvanceCompromisos) ? item.AvanceCompromisos : '0',
                AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
                  ? numberWithCommas(item.AvanceCompromisosUnicos)
                  : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeEstructura: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeEstructura) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeEstructura) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeEstructura) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeEstructura > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeEstructura}
                  />
                ),
                PorcentajeCompromisos: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisos) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisos) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisos) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisos > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisos}
                  />
                ),
                PorcentajeCompromisosUnico: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisosUnico) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisosUnico > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisosUnico}
                  />
                ),
              };
            })
          );
          let datosChart = {
            series1: res.response.dataGraph.map((item) => item.MetaEstructura),
            series2: res.response.dataGraph.map((item) => item.AvanceEstructura),
            categories: res.response.dataGraph.map((item) => item.Region),
          };

          setDataChart(datosChart);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const DataMunicipio = () => {
    setLoading(true);
    setData([]);
    setColumns([
      { id: 'Region', label: 'Región', width: 25, align: 'center' },
      { id: 'Municipio', label: 'Municipio', width: 35, align: 'center' },
      {
        id: 'MetaEstructura',
        label: 'Meta Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'AvanceEstructura',
        label: 'Avan. Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'PorcentajeEstructura',
        width: 25,
        label: '% Avan. Estructura',
        align: 'center',
      },
    ]);
    TerritorialServices.getProgressDistritales(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaCompromisos: item.MetaCompromisos ? numberWithCommas(item.MetaCompromisos) : '0',
                AvEsperado: item.AvEsperado ? item.AvEsperado : '0',
                AvanceCompromisos: numberWithCommas(item.AvanceCompromisos) ? item.AvanceCompromisos : '0',
                AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
                  ? numberWithCommas(item.AvanceCompromisosUnicos)
                  : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeEstructura: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeEstructura) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeEstructura) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeEstructura) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeEstructura > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeEstructura}
                  />
                ),
                PorcentajeCompromisos: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisos) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisos) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisos) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisos > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisos}
                  />
                ),
                PorcentajeCompromisosUnico: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisosUnico) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisosUnico > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisosUnico}
                  />
                ),
              };
            })
          );

          let datosChart = {
            series1: res.response.dataGraph.map((item) => item.MetaEstructura),
            series2: res.response.dataGraph.map((item) => item.AvanceEstructura),
            categories: res.response.dataGraph.map((item) => item.Municipio),
          };

          setDataChart(datosChart);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const DataPoligono = () => {
    setData([]);
    setColumns([
      { id: 'Region', label: 'Región', width: 25, align: 'center' },
      { id: 'Municipio', label: 'Municipio', width: 35, align: 'center' },
      { id: 'Poligono', label: 'Polígono', width: 30, align: 'center' },
      {
        id: 'MetaEstructura',
        label: 'Meta Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'AvanceEstructura',
        label: 'Avan. Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'PorcentajeEstructura',
        width: 25,
        label: '% Avan. Estructura',
        align: 'center',
      },
    ]);

    TerritorialServices.getProgressPolygon(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaCompromisos: item.MetaCompromisos ? numberWithCommas(item.MetaCompromisos) : '0',
                AvEsperado: item.AvEsperado ? item.AvEsperado : '0',
                AvanceCompromisos: item.AvanceCompromisos ? numberWithCommas(item.AvanceCompromisos) : '0',
                AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
                  ? numberWithCommas(item.AvanceCompromisosUnicos)
                  : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeEstructura: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeEstructura) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeEstructura) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeEstructura) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeEstructura > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeEstructura}
                  />
                ),
                PorcentajeCompromisos: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisos) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisos) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisos) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisos > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisos}
                  />
                ),
                PorcentajeCompromisosUnico: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisosUnico) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisosUnico > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisosUnico}
                  />
                ),
              };
            })
          );
          let datosChart = {
            series1: res.response.dataGraph.map((item) => item.MetaEstructura),
            series2: res.response.dataGraph.map((item) => item.AvanceEstructura),
            categories: res.response.dataGraph.map((item) => item.Poligono),
          };

          setDataChart(datosChart);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const DataSeccion = () => {
    setData([]);
    setColumns([
      { id: 'Region', label: 'Región', width: 25, align: 'center' },
      { id: 'Municipio', label: 'Municipio', width: 35, align: 'center' },
      { id: 'Poligono', label: 'Poligono', width: 35, align: 'center' },
      { id: 'Seccion', label: 'Sección', width: 35, align: 'center' },
      {
        id: 'MetaEstructura',
        label: 'Meta Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'AvanceEstructura',
        label: 'Avan. Estructura',
        width: 25,
        align: 'center',
      },
      {
        id: 'PorcentajeEstructura',
        label: '% Avan. Estructura',
        width: 25,
        align: 'center',
      },
    ]);

    TerritorialServices.getProgressSection(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaCompromisos: item.MetaCompromisos ? numberWithCommas(item.MetaCompromisos) : '0',
                AvEsperado: item.AvEsperado ? item.AvEsperado : '0',
                AvanceCompromisos: item.AvanceCompromisos ? numberWithCommas(item.AvanceCompromisos) : '0',
                AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
                  ? numberWithCommas(item.AvanceCompromisosUnicos)
                  : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeEstructura: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeEstructura) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeEstructura) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeEstructura) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeEstructura > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeEstructura}
                  />
                ),
                PorcentajeCompromisos: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisos) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisos) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisos) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisos > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisos}
                  />
                ),
                PorcentajeCompromisosUnico: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisosUnico) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisosUnico > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisosUnico}
                  />
                ),
              };
            })
          );

          let datosChart = {
            series1: res.response.dataGraph.map((item) => item.MetaEstructura),
            series2: res.response.dataGraph.map((item) => item.AvanceEstructura),
            categories: res.response.dataGraph.map((item) => item.Seccion),
          };

          setDataChart(datosChart);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const DataManzana = () => {
    setColumns([
      { id: 'Region', label: 'Región', width: 25, align: 'center' },
      { id: 'Municipio', label: 'Municipio', width: 35, align: 'center' },
      { id: 'Poligono', label: 'Poligono', width: 35, align: 'center' },
      { id: 'Seccion', label: 'Sección', width: 35, align: 'center' },
      { id: 'MetaManzanaP1', label: 'Meta Manzana P1', width: 35, align: 'center' },
      { id: 'AvanceManzanaP1', label: 'Avance Manzana P1', width: 35, align: 'center' },
      { id: 'PorcentajeP1', label: '% Avance Manzana P1', width: 35, align: 'center' },
      { id: 'MetaManzanaP2', label: 'Meta Manzana P2', width: 35, align: 'center' },
      { id: 'AvanceManzanaP2', label: 'Avance Manzana P2', width: 35, align: 'center' },
      { id: 'PorcentajeP2', label: '% Avance Manzana P2', width: 35, align: 'center' },
      { id: 'MetaManzanaP3', label: 'Meta Manzana P3', width: 35, align: 'center' },
      { id: 'AvanceManzanaP3', label: 'Avance Manzana P3', width: 35, align: 'center' },
      { id: 'PorcentajeP3', label: '% Avance Manzana P3', width: 35, align: 'center' },
    ]);

    TerritorialServices.getProgressSection(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaManzanaP1: item.MetaManzanaP1 ? numberWithCommas(item.MetaManzanaP1) : '0',
                MetaManzanaP2: item.MetaManzanaP2 ? numberWithCommas(item.MetaManzanaP2) : '0',
                MetaManzanaP3: item.MetaManzanaP3 ? numberWithCommas(item.MetaManzanaP3) : '0',
                AvanceManzanaP1: item.AvanceManzanaP1 ? numberWithCommas(item.AvanceManzanaP1) : '0',
                AvanceManzanaP2: item.AvanceManzanaP2 ? numberWithCommas(item.AvanceManzanaP2) : '0',
                AvanceManzanaP3: item.AvanceManzanaP3 ? numberWithCommas(item.AvanceManzanaP3) : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeP1: (
                  <Chip
                    variant="outlined"
                    color={
                      parseFloat(item.PorcentajeP1) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeP1) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeP1) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeP1 > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeP1}
                  />
                ),
                PorcentajeP2: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeP2) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeP2) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeP2) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeP2 > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeP2}
                  />
                ),
                PorcentajeP3: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeP3) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeP3) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeP3) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeP3 > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeP3}
                  />
                ),
              };
            })
          );

          let datosChart = {
            series1: res.response.dataGraph.map((item) => item.MetaManzanaP1),
            series2: res.response.dataGraph.map((item) => item.AvanceManzanaP1),
            series3: res.response.dataGraph.map((item) => item.MetaManzanaP2),
            series4: res.response.dataGraph.map((item) => item.AvanceManzanaP2),
            categories: res.response.dataGraph.map((item) => item.Seccion),
          };

          setDataChart(datosChart);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const Stast = () => {
    TerritorialServices.getStatsStruct(chartCompromisos)
      .then((res) => {
        if (res.results) {
          setDataTotales(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingStast(false));
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Region: 'cat_region.Clave',
      Municipio: 'cat_municipios_reportes.Municipio',
      Poligono: 'cat_poligonos.Poligono',
      Seccion: 'cat_secciones.Seccion',
      Manzana: 'cat_secciones.Seccion',
      MetaEstructura: 'MetaEstructura',
      AvanceEstructura: 'AvanceEstructura',
      PorcentajeEstructura: 'TRUNCATE(((AvanceEstructura * 100)/MetaEstructura),2)',
      MetaManzanaP1: 'MetaManzanaP1',
      MetaManzanaP2: 'MetaManzanaP2',
      MetaManzanaP3: 'MetaManzanaP3',
      AvanceManzanaP1: 'AvanceManzanaP1',
      AvanceManzanaP2: 'AvanceManzanaP2',
      AvanceManzanaP3: 'AvanceManzanaP3',
      PorcentajeP1: 'TRUNCATE(((estad_secciones.AvanceManzanaP1 * 100)/estad_secciones.MetaManzanaP1),2)',
      PorcentajeP2: 'TRUNCATE(((estad_secciones.AvanceManzanaP2 * 100)/estad_secciones.MetaManzanaP2),2)',
      PorcentajeP3: 'TRUNCATE(((estad_secciones.AvanceManzanaP3 * 100)/estad_secciones.MetaManzanaP3),2)',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader title={`Avance Estructura`} handleclick={() => navigate(`/avance-estructura-p`)} />
          <Grid container spacing={3}>
            {responsibilities.map((item) => {
              return (
                <Grid item xs={12} md={item.value !== 14 ? 4 : 6} lg={item.value !== 14 ? 4 : 6}>
                  {item.value !== 14 ? (
                    <CardCommitments
                      icon={'groups'}
                      title={item.label}
                      total={`${numberWithCommas(
                        dataTotales[item.label]?.TotalAvance
                      )} de ${numberWithCommas(dataTotales[item.label]?.Meta)}`}
                      subIcon={'update -'}
                      subtitle={moment(dataTotales[item.label]?.FechaActualizo).format('DD/MM/YYYY')}
                      loading={loadingStats}
                    />
                  ) : (
                    <CardCommitmentsTree
                      icon={'fact_check'}
                      title={item.label}
                      total={`${numberWithCommas(parseInt(dataTotales['Seccional']?.AvanMP1) + parseInt(dataTotales['Seccional']?.AvanMP2) + parseInt(dataTotales['Seccional']?.AvanMP3)) ? numberWithCommas(parseInt(dataTotales['Seccional']?.AvanMP1) + parseInt(dataTotales['Seccional']?.AvanMP2) + parseInt(dataTotales['Seccional']?.AvanMP3)) : 0} de ${numberWithCommas(parseInt(dataTotales['Seccional']?.MMP1) + parseInt(dataTotales['Seccional']?.MMP2) + parseInt(dataTotales['Seccional']?.MMP3)) ? numberWithCommas(parseInt(dataTotales['Seccional']?.MMP1) + parseInt(dataTotales['Seccional']?.MMP2) + parseInt(dataTotales['Seccional']?.MMP3)) : 0}`}
                      subtitle1={'P1'}
                      subtotal1={`${dataTotales['Seccional']?.AvanMP1 ? dataTotales['Seccional']?.AvanMP1 : 0} ${
                        dataTotales['Seccional']?.MMP1 ? `de ${dataTotales['Seccional']?.MMP1}` : 0
                      }`}
                      subtitle2={'P2'}
                      subtotal2={`${dataTotales['Seccional']?.AvanMP2 ? dataTotales['Seccional']?.AvanMP2 : 0} ${
                        dataTotales['Seccional']?.MMP2 ? `de ${dataTotales['Seccional']?.MMP2}` : 0
                      }`}
                      subtitle3={'P3'}
                      subtotal3={`${dataTotales['Seccional']?.AvanMP3 ? dataTotales['Seccional']?.AvanMP3 : 0} ${
                        dataTotales['Seccional']?.MMP3 ? `de ${dataTotales['Seccional']?.MMP3}` : 0
                      }`}
                      subIcon={'update'}
                      subFooter={dataTotales['Seccional']?.FechaActualizo}
                      loading={loadingStats}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Card className="card-primary" sx={{ overflow: 'auto' }}>
            <CardContent>
              {loading ? (
                <CircularProgress />
              ) : (
                <PlacementColumns
                  titulo={{
                    title: `Avance ${responsibilities[value]?.label ? responsibilities[value]?.label : ''}`,
                  }}
                  subtitle={{
                    subtitle: moment(dataChart.date).format('DD/MM/YYYY H:mm'),
                  }}
                  datos={{
                    categories: dataChart.categories,
                    series:
                      responsibilities[value]?.value !== 14
                        ? [
                            {
                              name: 'Meta',
                              color: 'rgba(0,65,165,1)',
                              data: dataChart.series1 ? dataChart.series1 : 0,
                              pointPadding: 0.3,
                              pointPlacement: -0.2,
                            },
                            {
                              name: 'Avance',
                              color: 'rgba(147,220,236,.9)',
                              data: dataChart.series2 ? dataChart.series2 : 0,
                              pointPadding: 0.4,
                              pointPlacement: -0.2,
                            },
                          ]
                        : [
                            {
                              name: 'Meta Prioridad 1',
                              color: 'rgba(0,65,165,1)',
                              data: dataChart.series1 ? dataChart.series1 : 0,
                              pointPadding: 0.3,
                              pointPlacement: -0.2,
                            },
                            {
                              name: 'Avance Prioridad 1',
                              color: 'rgba(147,220,236,.9)',
                              data: dataChart.series2 ? dataChart.series2 : 0,
                              pointPadding: 0.4,
                              pointPlacement: -0.2,
                            },
                            {
                              name: 'Meta Prioridad 2',
                              color: 'rgba(235,63,157,1)',
                              data: dataChart.series3 ? dataChart.series3 : 0,
                              pointPadding: 0.3,
                              pointPlacement: 0.1,
                            },
                            {
                              name: 'Avance Prioridad 2',
                              color: 'rgba(250,182,219,.9)',
                              data: dataChart.series4 ? dataChart.series4 : 0,
                              pointPadding: 0.4,
                              pointPlacement: 0.1,
                            },
                          ],
                  }}
                  loading={loading}
                />
              )}
            </CardContent>
          </Card>
          <Card className="card-primary">
            <CardContent>
              <Filter
                onChangeFilter={(e) => handleChangeFilter(e)}
                region={true}
                municipio={false}
                poligono={responsibilities[value]?.value > 11 ? true : false}
                seccion={responsibilities[value]?.value > 12 ? true : false}
                manzana={false}
                vista={false}
                municipio_dl={responsibilities[value]?.value > 10 ? true : false}
              />
            </CardContent>
          </Card>

          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuActionId('Exportar') && (
                <>
                  {isDownloading ? (
                    <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
                      <Button
                        variant="outlined"
                        color="primaryDark"
                        startIcon={<Icon>download</Icon>}
                        size="small"
                        onClick={handleExport}
                        sx={{ borderRadius: '10px' }}
                      >
                        Exportar
                      </Button>
                    </Box>
                  )}
                </>
              )}

              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}
              <Box sx={{ overflow: 'auto' }}>
                <Tabs centered value={value} onChange={handleChangeTab} aria-label="responsabilitis">
                  {responsibilities.map((item, index) => {
                    return <Tab label={item.label} value={index} />;
                  })}
                </Tabs>
              </Box>
              <BasicTable
                rows={data}
                hcolumns={columns}
                pageProp={tableData.page}
                pageSize={tableData.pageSize}
                total={total}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};
export default StructElectoral;
