import React, { useEffect, useState } from 'react';

import { Box, Chip, Icon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SocialForm4Capture = (props) => {
  const { action, options, loadigoptions, setValues, assignedTags } = props;
  const animatedComponents = makeAnimated();
  const [selected, setSelected] = useState([]);
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  };

  useEffect(() => {
    if (options) {
      if (action === 'edit') {
        handleLabels({
          labels: [...new Set([...assignedTags, ...selected])],
          options,
        });
      } else {
        if (options.length === 1) {
          handleSelectedOptions(options);
        } else {
          handleSelectedOptions([]);
        }
      }
    }

    // eslint-disable-next-line
  }, [options]);

  const handleLabels = ({ labels, options }) => {
    if (labels.length < 1) {
      if (options.length === 1) {
        if (action !== 'edit') {
          handleSelectedOptions(options);
        } else {
          handleSelectedOptions([]);
        }
      } else {
        handleSelectedOptions([]);
      }
    } else {
      const array_ids = labels.map((item) => parseInt(item.value));
      const items = options.filter((item) => array_ids.includes(parseInt(item.value)));
      if (items.length < 1 && options.length === 1) {
        if (action !== 'edit') {
          handleSelectedOptions(options);
        } else {
          handleSelectedOptions([]);
        }
      } else {
        handleSelectedOptions(items);
      }
    }
  };

  const handleSelectedOptions = (items) => {
    setSelected(items);
    setValues(items);
  };

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Grid2 item xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>groups2</Icon>} label="Segmentos Sociales" />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 item xs={12} sm={12} md={12} lg={12}>
          <Select
            menuPlacement="top"
            styles={selectStyles}
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selected}
            onChange={handleSelectedOptions}
            isMulti
            options={options}
            isLoading={loadigoptions}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default SocialForm4Capture;
