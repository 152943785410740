import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';

import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Collapse,
  CardContent,
  Container,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

import sije from '@services/SijeService';
import CatalogServices from '@services/CatalogServices';

const FilterSije = ({ onChangeFilter, onClearFilter }) => {
  //CONSTANTS
  const defaultOption = { value: 0, label: 'TODOS' };
  const catalogsOptions = [
    { id: 'regiones', getAll: false },
    { id: 'df', getAll: false },
    { id: 'secciones', getAll: false },
    { id: 'dl', getAll: false },
    { id: 'municipios_reportes', getAll: false },
    { id: 'mpio_df', getAll: false },
    { id: 'mpio_dl', getAll: false },
    //{ id: "tipos_eleccion" },
  ];
  //STATES
  const divFilterRef = useRef(null);
  const [open, setOpen] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [loadingCatCasillas, setLoadingCatCasillas] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogCasillas, setCatalogsCasillas] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    // TipoEleccion: "",
    DttoLocal: 0,
    DttoFederal: 0,
    Region: 0,
    Municipio: 0,
    Seccion: 0,
    Casilla: '',
    AsistenciaRG: 9999,
    InstalacionCasilla: 9999,
    AperturaCasilla: 9999,
    CierreCasilla: 9999,
    CierreVotacion: 9999,
    PaseListaP1: 9999,
    PaseListaP2: 9999,
    PaseListaS1: 9999,
    PaseListaS2: 9999,
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await CatalogServices.getCatalogs(catalogsOptions);

      const { results, response, message } = result;

      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeRegion = (e) => {
    //const idRegion = e.target.value;
    const idRegion = e.value;

    if (idRegion > 0) {
      const cat_mpio_reportes = catalogs.municipios_reportes;
      const cat_secciones = catalogs.secciones;
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [
          defaultOption,
          ...cat_mpio_reportes.filter((item) => item.idRegion === idRegion),
        ],
        secciones: [defaultOption, ...cat_secciones.filter((item) => item.idRegion === idRegion)],
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
    /* setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios_reportes: catalogs.municipios_reportes.filter(
        (item) => item.idRegion === idRegion
      ),
      secciones: catalogs.secciones.filter(
        (item) => item.idRegion === idRegion
      ),
    })); */
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeDttoLocal = (e) => {
    //const idDttoLocal = e.target.value;
    const idDttoLocal = e.value;

    if (idDttoLocal > 0) {
      const cat_mpio_dl = catalogs.mpio_dl;

      if (cat_mpio_dl) {
        const municipios_dl = [
          defaultOption,
          ...cat_mpio_dl
            .filter(({ idDL }) => idDL === idDttoLocal)
            .map(({ idMunicipioReportes, Municipio }) => ({
              value: idMunicipioReportes,
              label: Municipio,
            })),
        ];

        setCatalogFiltered((prevState) => ({
          ...prevState,
          secciones: [defaultOption, ...catalogs.secciones.filter((item) => item.idDL === idDttoLocal)],
          municipios_reportes: municipios_dl,
        }));

        setFilterData((prevState) => ({
          ...prevState,
          Municipio: 0,
          Seccion: 0,
        }));
      }
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeDttoFederal = (e) => {
    //const idDttoFederal = e.target.value;
    const idDttoFederal = e.value;

    if (idDttoFederal > 0) {
      const cat_mpio_df = catalogs.mpio_df;

      if (cat_mpio_df) {
        const municipios_df = [
          defaultOption,
          ...catalogs.mpio_df
            .filter(({ idDF }) => idDF === idDttoFederal)
            .map(({ idMunicipioReportes, Municipio }) => ({
              value: idMunicipioReportes,
              label: Municipio,
            })),
        ];

        setCatalogFiltered((prevState) => ({
          ...prevState,
          secciones: [defaultOption, ...catalogs.secciones.filter((item) => item.idDF === idDttoFederal)],
          municipios_reportes: municipios_df,
        }));

        setFilterData((prevState) => ({
          ...prevState,
          Municipio: 0,
          Seccion: 0,
        }));
      }
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeMunicipality = (e) => {
    //const idMunicipality = e.target.value;
    const idMunicipality = e.value;

    if (idMunicipality > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: [
          defaultOption,
          ...catalogs.secciones.filter((item) => item.idMunicipioReportes === idMunicipality),
        ],
      }));

      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
      }));
    }
  };

  const handleChangeSection = async (e) => {
    //const idSection = e.target.value;
    const idSection = e.value;

    if (idSection) {
      try {
        setLoadingCatCasillas((prevState) => !prevState);
        const result = await sije.getCasillasBySeccion({
          Seccion: String(idSection),
        });
        const { response, results, message } = result;
        if (results) {
          setCatalogsCasillas([
            defaultOption,
            ...response.data.map((item) => {
              return {
                label: item.NombreCasilla,
                value: item.idCasilla,
              };
            }),
          ]);
          setFilterData((prevState) => ({
            ...prevState,
            Casilla: 0,
          }));
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: 'warning',
        });
      } finally {
        setLoadingCatCasillas((prevState) => !prevState);
      }
    }
  };

  const handleChangeFilter = ({ name, value }) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSearch = () => {
    let filteredChart = [];
    let filtered = [];
    let filteredMinutes = [];

    /* if (filterData.TipoEleccion > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_casilla.TipoEleccion",
          filter: "=",
          value: filterData.TipoEleccion,
        },
      ];
    } */

    if (filterData.DttoLocal > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_secciones.idDL',
          filter: '=',
          value: filterData.DttoLocal,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'idDL',
          filter: '=',
          value: filterData.DttoLocal,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: 'cat_secciones.idDL',
          filter: '=',
          value: filterData.DttoLocal,
        },
      ];
    }

    if (filterData.DttoFederal > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_secciones.idDF',
          filter: '=',
          value: filterData.DttoFederal,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'idDF',
          filter: '=',
          value: filterData.DttoFederal,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: 'cat_secciones.idDF',
          filter: '=',
          value: filterData.DttoFederal,
        },
      ];
    }

    if (filterData.Region > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_secciones.idRegion',
          filter: '=',
          value: filterData.Region,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'idRegion',
          filter: '=',
          value: filterData.Region,
        },
      ];
    }

    if (filterData.Municipio > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: filterData.Municipio,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'idMunicipioReportes',
          filter: '=',
          value: filterData.Municipio,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: filterData.Municipio,
        },
      ];
    }

    if (filterData.Seccion > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_secciones.Seccion',
          filter: '=',
          value: filterData.Seccion,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'Seccion',
          filter: '=',
          value: filterData.Seccion,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: 'cat_secciones.Seccion',
          filter: '=',
          value: filterData.Seccion,
        },
      ];
    }

    if (filterData.Casilla > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'cat_casillas.id',
          filter: '=',
          value: filterData.Casilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'idCasilla',
          filter: '=',
          value: filterData.Casilla,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: 'cat_casillas.id',
          filter: '=',
          value: filterData.Casilla,
        },
      ];
    }

    if (filterData.AsistenciaRG !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esPaseListaRG',
          filter: '=',
          value: filterData.AsistenciaRG,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esPaseListaRG',
          filter: '=',
          value: filterData.AsistenciaRG,
        },
      ];
    }

    if (filterData.InstalacionCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esInicioInstalacion',
          filter: '=',
          value: filterData.InstalacionCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esInicioInstalacion',
          filter: '=',
          value: filterData.InstalacionCasilla,
        },
      ];
    }

    if (filterData.AperturaCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esAperturaCasilla',
          filter: '=',
          value: filterData.AperturaCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esAperturaCasilla',
          filter: '=',
          value: filterData.AperturaCasilla,
        },
      ];
    }

    if (filterData.CierreCasilla !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esCierreCasilla',
          filter: '=',
          value: filterData.CierreCasilla,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esCierreCasilla',
          filter: '=',
          value: filterData.CierreCasilla,
        },
      ];
    }

    if (filterData.CierreVotacion !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esCierreVotacion',
          filter: '=',
          value: filterData.CierreVotacion,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esCierreVotacion',
          filter: '=',
          value: filterData.CierreVotacion,
        },
      ];
    }

    if (filterData.PaseListaP1 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esPaseListaP1',
          filter: '=',
          value: filterData.CierreVotacion,
        },
      ];

      filtered = [
        ...filtered,
        {
          id: 'esPaseListaP1',
          filter: '=',
          value: filterData.PaseListaP1,
        },
      ];
    }

    if (filterData.PaseListaP2 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esPaseListaP2',
          filter: '=',
          value: filterData.PaseListaP2,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esPaseListaP2',
          filter: '=',
          value: filterData.PaseListaP2,
        },
      ];
    }

    if (filterData.PaseListaS1 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esPaseListaS1',
          filter: '=',
          value: filterData.PaseListaS1,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esPaseListaS1',
          filter: '=',
          value: filterData.PaseListaS1,
        },
      ];
    }

    if (filterData.PaseListaS2 !== 9999) {
      filteredChart = [
        ...filteredChart,
        {
          id: 'sije_casillas.esPaseListaS2',
          filter: '=',
          value: filterData.PaseListaS2,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: 'esPaseListaS2',
          filter: '=',
          value: filterData.PaseListaS2,
        },
      ];
    }

    onChangeFilter({
      filtersChart: filteredChart,
      filtersData: filtered,
      filtersMinutes: filteredMinutes,
    });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      // TipoEleccion: "",
      DttoLocal: 0,
      DttoFederal: 0,
      Region: 0,
      Municipio: 0,
      Seccion: 0,
      Casilla: '',
      AsistenciaRG: 9999,
      InstalacionCasilla: 9999,
      AperturaCasilla: 9999,
      CierreCasilla: 9999,
      CierreVotacion: 9999,
      PaseListaP1: 9999,
      PaseListaP2: 9999,
      PaseListaS1: 9999,
      PaseListaS2: 9999,
    };
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    setCatalogsCasillas([]);
    setFilterData(cleanFileds);
    onClearFilter();
  };

  const handleClick = (target) => {
    setOpen({ [target]: !open[target] });
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CardContent
        ref={divFilterRef}
        sx={{
          height: '100%',
          minHeight: 'calc(100vh - 350px)',
          maxHeight: '500px',
          overflowY: 'auto',
        }}
      >
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box marginBottom={3}>
            <ListItem onClick={(e) => handleClick(0)} sx={{ cursor: 'pointer' }}>
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Distritos
                </Typography>
              />
              {open[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <AdvancedSelect
                    name="DttoLocal"
                    label="Distrito Local"
                    options={catalogsFiltered.dl}
                    value={filterData.DttoLocal}
                    isSearchable
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.name,
                        value: e.value,
                      });
                      handleChangeDttoLocal(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <AdvancedSelect
                    name="DttoFederal"
                    label="Distrito Federal"
                    options={catalogsFiltered.df}
                    value={filterData.DttoFederal}
                    isSearchable
                    onChange={(e) => {
                      handleChangeFilter({ name: e.name, value: e.value });
                      handleChangeDttoFederal(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box marginBottom={3}>
            <ListItem onClick={(e) => handleClick(1)} sx={{ cursor: 'pointer' }}>
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Ámbito
                </Typography>
              />
              {open[1] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[1]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <AdvancedSelect
                    name="Region"
                    label="Región"
                    options={catalogsFiltered.regiones}
                    value={
                      catalogsFiltered.regiones.length === 1
                        ? catalogsFiltered.regiones[0].value
                        : filterData.Region
                    }
                    onChange={(e) => {
                      handleChangeFilter({ name: e.name, value: e.value });
                      handleChangeRegion(e);
                    }}
                    isSearchable
                    isLoading={loadingCatalogs}
                    disabled={catalogsFiltered.regiones.length === 1}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <AdvancedSelect
                    name="Municipio"
                    label="Municipio"
                    options={catalogsFiltered.municipios_reportes}
                    value={
                      catalogsFiltered.municipios_reportes.length === 1
                        ? catalogsFiltered.municipios_reportes[0].value
                        : filterData.Municipio
                    }
                    onChange={(e) => {
                      handleChangeFilter({ name: e.name, value: e.value });
                      handleChangeMunicipality(e);
                    }}
                    isSearchable
                    isLoading={loadingCatalogs}
                    disabled={catalogsFiltered.municipios_reportes.length === 1}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <AdvancedSelect
                    name="Seccion"
                    label="Sección"
                    options={catalogsFiltered.secciones.map((item) => {
                      return { value: item.value, label: String(item.label) };
                    })}
                    value={
                      catalogsFiltered.secciones.length === 1
                        ? catalogsFiltered.secciones[0].value
                        : filterData.Seccion
                    }
                    onChange={(e) => {
                      handleChangeFilter({ name: e.name, value: e.value });
                      handleChangeSection(e);
                    }}
                    isSearchable
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="Casilla"
                    label="Casilla"
                    options={catalogCasillas}
                    value={filterData.Casilla}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatCasillas}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box marginBottom={3}>
            <ListItem onClick={(e) => handleClick(2)} sx={{ cursor: 'pointer' }}>
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Casilla
                </Typography>
              />
              {open[2] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[2]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="InstalacionCasilla"
                    label="Instalación Casilla"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Casillas Instaladas', value: 1 },
                      { label: 'No Instaladas', value: 0 },
                    ]}
                    value={filterData.InstalacionCasilla}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="AperturaCasilla"
                    label="Apertura Casilla"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Aperturadas', value: 1 },
                      { label: 'No Aperturadas', value: 0 },
                    ]}
                    value={filterData.AperturaCasilla}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="CierreCasilla"
                    label="Cierre Casilla"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Casillas Cerradas', value: 1 },
                      { label: 'No Cerradas', value: 0 },
                    ]}
                    value={filterData.CierreCasilla}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="CierreVotacion"
                    label="Cierre Votación"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Votación Cerradas', value: 1 },
                      { label: 'No Cerradas', value: 0 },
                    ]}
                    value={filterData.CierreVotacion}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          <Box>
            <ListItem onClick={(e) => handleClick(3)} sx={{ cursor: 'pointer' }}>
              <ListItemText
                primary=<Typography variant="body1" fontWeight={600}>
                  Pase de Lista
                </Typography>
              />
              {open[3] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[3]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="AsistenciaRG"
                    label="Asistencia RG"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Asistencia RGS', value: 1 },
                      { label: 'No Asistencia RGS', value: 0 },
                    ]}
                    value={filterData.AsistenciaRG}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
                <ListItem>
                  <BasicSelect
                    name="PaseListaP1"
                    label="Pase Lista P1"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Pase Lista RCP1', value: 1 },
                      { label: 'No Pase Lista RCP1', value: 0 },
                    ]}
                    value={filterData.PaseListaP1}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaP2"
                    label="Pase Lista P2"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Pase Lista RCP2', value: 1 },
                      { label: 'No Pase Lista RCP2', value: 0 },
                    ]}
                    value={filterData.PaseListaP2}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaS1"
                    label="Pase Lista S1"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Pase Lista RCS1', value: 1 },
                      { label: 'No Pase Lista RCS1', value: 0 },
                    ]}
                    value={filterData.PaseListaS1}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>

                <ListItem>
                  <BasicSelect
                    name="PaseListaS2"
                    label="Pase Lista S2"
                    options={[
                      { label: 'Todos', value: 9999 },
                      { label: 'Pase Lista RCS2', value: 1 },
                      { label: 'No Pase Lista RCS2', value: 0 },
                    ]}
                    value={filterData.PaseListaS2}
                    onChange={(e) => {
                      handleChangeFilter({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>
        </List>
      </CardContent>
      <Divider />
      <Container>
        <Box marginTop={2}>
          <Button
            variant="contained"
            size="small"
            color="primaryDark"
            sx={{ width: '100%', mb: 1 }}
            onClick={handleSearch}
          >
            Filtrar
          </Button>
          <Button
            size="small"
            sx={{ width: '100%' }}
            variant="outlined"
            color="primaryDark"
            onClick={handleClearFilter}
          >
            Limpiar
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default FilterSije;
