import React, { useState, useEffect } from "react";
import {
	Autocomplete,
	TextField,
	Button,
	IconButton,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Card,
	CardContent,
	Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import OficialiaServices from "@services/OficialiaServices"; // Importar el servicio

const AreasCDEAutocomplete = ({ value, setValue }) => {
	const [data, setData] = useState([]);
	const [openModal, setOpenModal] = useState(false); // Estado para controlar el modal
	const [nuevoValor, setNuevoValor] = useState(""); // Estado para el valor de la nueva empresa
	const [parentEmpresa, setParentEmpresa] = useState(null); // Estado para seleccionar empresa padre
	const [loading, setLoading] = useState(false); // Estado para manejar el loading al guardar
	const [errors, setErrors] = useState("");

	useEffect(() => {
		const fetchEmpresas = async () => {
			try {
				const results = await OficialiaServices.getOficialiaEmpresas({
					page: 0,
					pageSize: 400,
					filtered: [
						{ id: "o.parentid", value: 4, filter: "=" },
						{ id: "o.isCDM", value: 0, filter: "=" },
					],
					sorted: [{ id: "o.Empresa", value: "ASC" }],
				});

				if (results.success) {
					// Si la respuesta es exitosa, actualizar el estado con los datos
					setData(results.response.data);
				} else {
					// Manejar el caso en que no se puedan obtener los datos
					setErrors(results.message || "Error al obtener empresas");
				}
			} catch (error) {
				console.error("Error al obtener empresas:", error);
				setErrors("Error al cargar empresas");
			}
		};

		fetchEmpresas();
	}, []);

	// Función para guardar la nueva empresa
	const handleSave = async () => {
		setLoading(true);
		try {
			const results = await OficialiaServices.insertOficialiaEmpresa({
				idParent: parentEmpresa ? parentEmpresa.id : 0,
				NuevaEmpresa: nuevoValor,
			});

			if (!results.success) {
				//Swal.fire({ title: results.message, icon: "error" });
				setErrors(results.message);
				return true;
			} else {
				setErrors("");
			}

			const nuevaEmpresa = results.response.data; // Obtener el objeto retornado por la API

			// Agregar la nueva empresa a la lista de opciones y seleccionarla en el Autocomplete
			data.push(nuevaEmpresa);
			setValue({ ...value, Empresa: nuevaEmpresa });

			// Cerrar el modal
			setOpenModal(false);
			setNuevoValor("");
			setParentEmpresa(null);
		} catch (error) {
			console.error("Error al agregar empresa:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box>
			{/* Autocomplete para seleccionar empresa */}
			<Autocomplete
				value={value || null}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
				options={data}
				getOptionLabel={(option) => option.EmpresaDependencia || ""}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Selecciona empresa envía oficio"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<IconButton onClick={() => setOpenModal(true)}>
									<AddIcon />
								</IconButton>
							),
						}}
					/>
				)}
				fullWidth
			/>

			{/* Modal para agregar nueva empresa */}
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>Agregar Empresa</DialogTitle>
				<DialogContent>
					<Card>
						<CardContent>
							{/* Input para ingresar nueva empresa */}
							<TextField
								label="Nueva Empresa"
								value={nuevoValor}
								onChange={(e) => {
									setErrors("");
									setNuevoValor(e.target.value);
								}}
								fullWidth
								sx={{ mb: 2 }}
							/>
							{errors && (
								<Typography
									variant="caption"
									color="error"
									style={{ marginVertical: 10 }}
								>
									{errors ? errors : ""}
									<br />
								</Typography>
							)}
							{/* Autocomplete para seleccionar empresa padre */}
							<Autocomplete
								value={parentEmpresa}
								onChange={(event, newValue) => setParentEmpresa(newValue)}
								options={data}
								getOptionLabel={(option) => option.Empresa}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Seleccionar Empresa Padre (opcional)"
									/>
								)}
								fullWidth
							/>
						</CardContent>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)} color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
						disabled={loading || !nuevoValor}
					>
						{loading ? "Guardando..." : "Guardar"}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default AreasCDEAutocomplete;
