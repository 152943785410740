import { useState, useEffect, useCallback } from 'react';
import { Swal } from '@utils/alerts';

// Material UI
import { Card, CardContent, Container, Grid, Button, Icon } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicListDoble from '@components/Lists/BasicListDoble';
import UserMenuList from '@components/Menus/UserMenuList';
import MenusList from '@components/Menus/MenusList';
import UserInformation from '@components/Users/UserInformation';
import UserAccess from '@components/Users/UserAccess';
import UserSecurity from '@components/Users/UserSecurity';
import MenuToolbar from '@components/Menus/MenuToolbar';
import AddNewMenuList from '@components/Menus/AddNewMenuList';
import CustomTabs from '@components/Tabs/CustomTabs';
import CustomModal from '@components/Modal/CustomModal';
import DrawerRelative from '@components/Containers/DrawerRelative';

// Interfaces y Servicios
import { UserInfoInterface } from '@interfaces/UserInterfaces';
import UserService from '@services/UserServices';

// Middleware y Hooks
import middleware from '@middlewares/middleware';
import useWindowDimensions from '@hooks/useWindowDimensions';

// Utils
import { isTypePhone } from '@utils/validations';
import { limpiarTelefono } from '@utils/global';

const Users = () => {
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isAddMenusModalOpen, setIsAddMenusModalOpen] = useState(false);
  const [isLoadingAddMenu, setIsLoadingAddMenu] = useState(false);

  const [userInfo, setUserInfo] = useState(UserInfoInterface);
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);

  const [tabValue, setTabValue] = useState('1');
  const [menuType, setMenuType] = useState('web');
  const [currentMenus, setCurrentMenus] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(true);

  const { height } = useWindowDimensions();

  const [newMenus, setNewMenus] = useState([]);

  const handleClickItem = useCallback(async (info) => {
    setUserInfo(info);
    setWebMenus([]);
    setAppMenus([]);

    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const result = await UserService.getMenus({ idUsuario: info.id, Movil: 0 });
      const result2 = await UserService.getMenus({ idUsuario: info.id, Movil: 1 });
      const { results, response, message } = result;
      const { results: results2, response: response2, message: message2 } = result2;

      if (results && results2) {
        setWebMenus(response.data.map((item) => ({ ...item, id: info.id })));
        setAppMenus(response2.data.map((item) => ({ ...item, id: info.id })));
      } else throw new Error(!results ? message : message2);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  }, []);

  useEffect(() => {
    if (tabValue) {
      switch (parseInt(tabValue)) {
        case 2: //WEB
          setCurrentMenus(webMenus);
          setMenuType('web');
          break;
        case 3: //APP
          setCurrentMenus(appMenus);
          setMenuType('app');
          break;
        default:
          setCurrentMenus([]);
          setMenuType('web');
          break;
      }
    }
  }, [tabValue, webMenus, appMenus]);

  const handleAddMenu = () => {
    setNewMenus([]);
    setIsAddMenusModalOpen(true);
  };

  const submitMenus = async (menus) => {
    try {
      setIsLoadingAddMenu(true);
      const params = { menus, idUsuario: userInfo.id };

      const result = await UserService.insertUserMenus(params);
      const { success, results, message } = result;

      if (success && results) {
        setIsAddMenusModalOpen(false);
        handleClickItem(userInfo);
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingAddMenu(false);
    }
  };

  const handleSubmitMenus = () => {
    if (newMenus.length === 0) {
      Swal.fire({ title: 'Atencion', html: 'Agregue al menos un menú', icon: 'warning' });
      return;
    }

    Swal.fire({
      title: 'Atencion',
      html: `¿Esta seguro de agregar ${newMenus.length} menú(s)?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Aplicar`,
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((res) => {
      if (res.isConfirmed) {
        const formatedNewMenus = newMenus.map((menu) => ({
          idMenu: menu.idMenu,
          Ver: menu.Ver,
          Agregar: menu.Agregar,
          Editar: menu.Editar,
          Eliminar: menu.Eliminar,
          Exportar: menu.Exportar,
          Importar: menu.Importar,
          Autorizar: menu.Autorizar,
          Visible: menu.Visible,
        }));
        submitMenus(formatedNewMenus);
      }
    });
  };

  const MenuSelected = ({ menus }) =>
    middleware.checkMenuAction('Editar') ? (
      <UserMenuList
        menus={menus}
        type="list-edit"
        maxHeight={height * 0.6}
        toolbar={true}
        ComponentToolbar={MenuToolbar}
        configToolbar={{
          title: `${userInfo.Nombre} ${userInfo.Paterno} ${userInfo.Materno}`,
          icon: 'add_circle',
          btnAdd: true,
          handleAdd: handleAddMenu,
        }}
        setIsOpen={setOpenLinearProgress}
        setIsLoading={setIsLoadingForm}
        setIsSuccess={setIsSuccessForm}
        externalID={userInfo.id}
        info={userInfo}
        handleReloadList={handleClickItem}
      />
    ) : (
      <MenusList menus={menus} type="list" maxHeight={height * 0.5} />
    );

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader isCustom title="Administración de usuarios">
          <Button
            color="primaryDark"
            variant="contained"
            size="medium"
            onClick={() => setOpenDrawer((prev) => !prev)}
          >
            <Icon>{openDrawer ? 'chevron_left' : 'chevron_right'}</Icon>
          </Button>
        </ActionHeader>

        <LoadingForm
          isLoading={isloadigForm}
          success={issuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => {}}
          loadinMessage="Cargando Datos..."
        />

        <CustomModal
          open={isAddMenusModalOpen}
          onClose={() => setIsAddMenusModalOpen(false)}
          fullWidth
          disableClose
          title="Agregar menus"
          actionButtons={[{ title: 'Guardar', action: handleSubmitMenus, icon: 'save' }]}
          isLoading={isLoadingAddMenu}
        >
          <AddNewMenuList
            currentMenus={currentMenus}
            type={menuType}
            maxHeight="100%"
            disableButtons
            onMenusChange={setNewMenus}
          />
        </CustomModal>

        <Grid container spacing={1}>
          <Grid xs={12} md={4} sm={12} lg={4} item>
            <DrawerRelative
              anchor="left"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title="Listado de Usuarios"
              icon="people"
              className="card-primary"
              sxPaper={{ pb: 0, borderRadius: '12px' }}
              sx={{ mb: '0 !important', borderRadius: '12px !important' }}
            >
              <BasicListDoble
                id="id_Usuarios"
                API={UserService.getUsers}
                APIKeys={[
                  { id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'], filter: 'LIKE' },
                  { id: 'usuarios.Username', filter: '=' },
                ]}
                handleClick={handleClickItem}
                label="Encuentra y elige al usuario"
                labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
                finder
                clearData
                selectFirst
                doubleSearch
                initialSearch
                inputValidation={{ input2: isTypePhone }}
                inputFormat={{ input2: limpiarTelefono }}
                lengthValidation={{ input2: { filter: '=', value: 10 } }}
                breaks={{ xs: 12, sm: 6, md: 12, lg: 6 }}
                buttonsBreaks={{ xs: 12, sm: 6, md: 6, lg: 4 }}
                config={{ height: height * 0.55 }}
                header={false}
                setLoading={() => {
                  setIsLoadingForm(true);
                  setOpenLinearProgress(true);
                }}
              />
            </DrawerRelative>
          </Grid>
          <Grid xs={12} md={openDrawer ? 8 : 12} sm={12} lg={openDrawer ? 8 : 12} item>
            <Card sx={{ height: '100%' }} className="card-primary">
              <CardContent sx={{ px: { xs: 0, sm: 2 } }}>
                <CustomTabs
                  value={tabValue}
                  setValue={setTabValue}
                  color="secondary"
                  tabs={[
                    {
                      label: 'Información',
                      value: '1',
                      icon: 'info',
                      iconPosition: 'top',
                      component: <UserInformation data={userInfo} height={height * 0.4} />,
                    },
                    {
                      label: 'Seguridad',
                      value: '4',
                      icon: 'lock',
                      iconPosition: 'top',
                      component: <UserSecurity height={height * 0.6} data={userInfo} access="admin" />,
                      display: middleware.checkMenuAction('Editar'),
                    },
                    {
                      label: 'Menús Web',
                      value: '2',
                      icon: 'admin_panel_settings',
                      iconPosition: 'top',
                      component: <MenuSelected menus={webMenus} />,
                    },
                    {
                      label: 'Menús App',
                      value: '3',
                      icon: 'app_settings_alt',
                      iconPosition: 'top',
                      component: <MenuSelected menus={appMenus} />,
                    },
                    {
                      label: 'Accesos',
                      value: '5',
                      icon: 'exit_to_app',
                      iconPosition: 'top',
                      component: <UserAccess data={userInfo} />,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};

export default Users;
