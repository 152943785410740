import { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Clear } from '@mui/icons-material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import BasicListDoble from '@components/Lists/BasicListDoble';

// Utils
import { handleAttrs, floatWithCommas, getDate } from '@utils/Utilities';
import { isEmptyOrNullObject, isTypePhone } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';
import CatalogService from '@services/CatalogServices';

// Data
import { EditPagosInterface, PagosInterface } from '@interfaces/AportacionesInterfaces';
import { EditPagosSchema, PagosSchema } from '@schemas/AportacionesSchemas';

// Middleware
import middleware from '@middlewares/middleware';

const PagosForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
    catalogs,
    isLoadingCat,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditPagosInterface : PagosInterface,
    validationSchema: isEdit ? EditPagosSchema : PagosSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando pago...');
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Aportante;
      delete newData.Celular;
      delete newData.NombreServicio;
      delete newData.Estatus;
      delete newData.TipoPago;
      delete newData.MontoCargo;
      delete newData.FechaCargo;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoPagado: parseFloat(params.MontoPagado.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando pago...');

      const result = await services.updatePago(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoPagado: parseFloat(params.MontoPagado.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando pago...');

      const result = await services.createPago(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success' });
        formik.resetForm();
        setReload(!reload);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = isLoadingCat;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Pago creado con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
          catalogs={catalogs}
          reload={reload}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
              catalogs={catalogs}
              reload={reload}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({ formik, loadAll, isEdit, handleClose, handleDelete, data, catalogs, reload }) => {
  const [selected, setSelected] = useState({});
  const [initial, setInitial] = useState(true);

  const [isLoadingCargos, setIsLoadingCargos] = useState(false);
  const [cargos, setCargos] = useState([]);

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const loadCargos = async (value) => {
    const catParams = [
      {
        id: 'cat_aportantes_cargos',
        filtered: [{ id: 'cac.idAportante', filter: '=', value }],
        getDeleted: true,
      },
    ];

    try {
      setIsLoadingCargos(true);
      const result = await CatalogService.getCatalogs(catParams);
      const { success, results, response, message } = result;

      if (success && results) setCargos(response.catalogs.cat_aportantes_cargos);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingCargos(false);
    }
  };

  const handleUser = (e) => {
    setSelected(e);
    loadCargos(e.id);

    if (isEdit && initial) {
      setInitial(false);
      return;
    }

    formik.setValues({ ...formik.values, idAportante: e.id, idCargo: '' });
  };
  const handleClear = () => {
    setSelected({});
    setCargos([]);
    formik.setValues({ ...formik.values, idAportante: '', idCargo: '' });
  };

  useEffect(() => {
    if (!isEdit) formik.setValues({ ...PagosInterface, FechaPago: new Date() });
    setSelected({}); // eslint-disable-next-line
  }, [reload]);

  return (
    <Grid container spacing={2}>
      {/* Datos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <BasicListDoble
              id="id_Aportante"
              API={services.getAportantes}
              APIKeys={[
                { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE' },
                { id: 'ca.Celular', filter: '=' },
              ]}
              header={''}
              handleClick={handleUser}
              handleClear={handleClear}
              label={'Encuentra y elige al aportante'}
              labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
              finder
              clearData
              emptySearch
              doubleSearch
              initialSearch
              inputValidation={{ input2: isTypePhone }}
              lengthValidation={{ input2: { filter: '=', value: 10 } }}
              breaks={{ sm: 12, md: 6 }}
              buttonsBreaks={{ sm: 12, md: 4 }}
              config={{ height: 400 }}
              searchInitialValues={{ input1: data?.Aportante || '', input2: data?.Celular || '' }}
              selectFirst={isEdit && initial}
              reload={reload}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={8}>
            <Grid container spacing={2}>
              {!isEmptyOrNullObject(selected) && (
                <Grid item xs={12}>
                  {(selected?.Nombre + ' ' + selected?.Paterno + ' ' + (selected?.Materno || '')).trim()}
                </Grid>
              )}

              {verify('idAportante')?.error && (
                <Grid item xs={12}>
                  <FormHelperText error>{verify('idAportante')?.helperText}</FormHelperText>
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <AdvancedSelect
                  required
                  size="small"
                  fullWidth
                  id="idCargo"
                  name="idCargo"
                  label="Cargo *"
                  options={cargos}
                  value={formik.values.idCargo}
                  onChange={({ value, name }) => formik.setValues({ ...formik.values, [name]: value })}
                  isLoading={isLoadingCargos || loadAll}
                  error={verify('idCargo')?.error}
                  helperText={verify('idCargo')?.helperText}
                  isSearchable
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="MontoPagado"
                  label="Monto pagado"
                  placeholder="1,000"
                  value={formik.values.MontoPagado}
                  onChange={({ target: { value, name } }) =>
                    formik.setValues({ ...formik.values, [name]: floatWithCommas(value) })
                  }
                  disabled={loadAll}
                  error={verify('MontoPagado')?.error}
                  helperText={verify('MontoPagado')?.helperText}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                  <MobileDateTimePicker
                    label={`Fecha de pago *`}
                    value={formik.values.FechaPago}
                    onChange={(date) => formik.setValues({ ...formik.values, FechaPago: date ?? '' })}
                    shouldDisableDate={(date) => !isBefore(date, new Date())}
                    inputFormat="yyyy-MM-dd HH:mm:ss"
                    orientation="portrait"
                    disabled={loadAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled={loadAll}
                        sx={{ width: '100%' }}
                        error={verify('FechaPago')?.error}
                        helperText={verify('FechaPago')?.helperText}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  formik.setValues({ ...formik.values, FechaPago: new Date() });
                                }}
                                disabled={loadAll}
                              >
                                Hoy
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  formik.setValues({ ...formik.values, FechaPago: '' });
                                }}
                                disabled={loadAll}
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} lg={6}>
                <AdvancedSelect
                  required
                  size="small"
                  fullWidth
                  id="idTipoPago"
                  name="idTipoPago"
                  label="Tipo de pago *"
                  options={catalogs.cat_aportantes_tipo_pago}
                  value={formik.values.idTipoPago}
                  onChange={({ value, name }) => formik.setValues({ ...formik.values, [name]: value })}
                  isLoading={loadAll}
                  error={verify('idTipoPago')?.error}
                  helperText={verify('idTipoPago')?.helperText}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Banco"
                  label="Banco"
                  placeholder="Banco"
                  value={formik.values.Banco}
                  onChange={formik.handleChange}
                  disabled={loadAll}
                  error={verify('Banco')?.error}
                  helperText={verify('Banco')?.helperText}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent={'end'}>
                  {isEdit && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                        Cancelar
                      </Button>
                    </Grid>
                  )}

                  {isEdit && middleware.checkMenuAction('Eliminar') && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                        Eliminar
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={5} md={4} lg={3}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={formik.handleSubmit}
                      disabled={isLoadingCargos || loadAll}
                      fullWidth
                    >
                      {isEdit ? 'Editar' : 'Agregar'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PagosForm;
