import React, { useEffect, useRef, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import BasicTable from '@components/Tables/BasicTable';
import electoral from '@services/ElectoralServices';
import Swal from 'sweetalert2';
import FilterResponsable from '@components/Electoral/FilterResponsable';
import AttendanceCard from '@components/Attendance/AttendanceCard';
import { numberWithCommas } from '@utils/Utilities';
import { roundTo } from '@components/Maps/Auxiliars/MathFunctions';
import { isEmptyOrNullObject } from '@utils/validations';
import { DEBUG, encryptVars } from '@utils/global';
import { downloadFile } from '@utils/ApiExec';

import {
  CardContent,
  Container,
  Grid,
  Card,
  IconButton,
  Icon,
  LinearProgress,
  Stack,
  Tooltip,
  Box,
  Typography,
  Modal,
  Button,
  CardMedia,
  CardHeader,
  Chip,
} from '@mui/material';

const ResponsablesElectoral = () => {
  //variables
  const filePdf = useRef(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: 'idPerfil', value: 'asc' }],
  });
  const [statsData, setStatsData] = useState({
    page: 0,
    pageSize: 99999999999999,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [dataUpload, setDataUpload] = useState({});
  const [fileBase64, setFileBase64] = useState('');
  const [fileName, setFileName] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [dataCard, setDataCard] = useState({
    TotalMeta: 0,
    TotalAvance: 0,
    PorcentajeAvance: '0.22',
  });
  const [imagenView, setImagenView] = useState({
    rute: '',
    Name: '',
  });
  const [open, setOpen] = useState(false);
  const columns = [
    {
      id: 'PerfilAmbito',
      label: 'Perfil',
      columnAction: false,
      width: 150,
      orderBy: 'PerfilAmbito',
    },
    {
      id: 'NombreCompleto',
      label: 'Nombre',
      columnAction: false,
      width: 200,
      orderBy: 'NombreCompleto',
    },
    {
      id: 'Celular',
      label: 'Celular',
      columnAction: false,
      width: 200,
      orderBy: 'Celular',
    },
    /*     {
      id: "CompromisosDirectos",
      label: "Compromisos Directos",
      columnAction: false,
      width: 20,
      orderBy: "CompromisosDirectos",
    },
    {
      id: "CompromisosUnicosDirectos",
      label: "Compromisos Unicos Directos",
      columnAction: false,
      width: 20,
      orderBy: "CompromisosUnicosDirectos",
    },
    {
      id: "UsuariosDirectos",
      label: "Usuarios Directos",
      columnAction: false,
      width: 20,
      orderBy: "UsuariosDirectos",
    },
    {
      id: "CompromisosEnRed",
      label: "Compromisos En Red",
      columnAction: false,
      width: 20,
      orderBy: "CompromisosEnRed",
    },
    {
      id: "CompromisosUnicosEnRed",
      label: "Compromisos Unicos En Red",
      columnAction: false,
      width: 20,
      orderBy: "CompromisosUnicosEnRed",
    },
    {
      id: "UsuariosEnRed",
      label: "Usuarios En Red",
      columnAction: false,
      width: 20,
      orderBy: "UsuariosEnRed",
    }, */
    {
      id: 'SeccionVota',
      label: 'Sección dónde Vota',
      columnAction: false,
      width: 20,
      orderBy: 'usuarios.SeccionVota',
    },
    {
      id: 'ColoniaVive',
      label: 'Colonia dónde vive',
      columnAction: false,
      width: 20,
      orderBy: 'usuarios.ColoniaVive',
    },
    {
      id: 'DescargarFormato',
      label: 'Descargar Formato',
      columnAction: false,
      width: 200,
      orderBy: 'Celular',
    },
    { id: 'Archivo', label: 'Manifiesto', columnAction: false, width: 20 },
    {
      id: 'FormatoUni',
      label: 'Formato Uiniversal',
      columnAction: false,
      width: 20,
    },
    { id: 'INEArchivo', label: 'INE', columnAction: false, width: 20 },
    { id: 'Estatus', label: 'Estatus' },
    { id: 'Observacion', label: 'Observacion' },
  ];

  //effets
  /*   useEffect(() => {}, [loadingPdf]); */
  useEffect(() => {
    getData();
  }, [tableData]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  /*   useEffect(() => {
    if (dataUpload?.idUsuario) {
      filePdf.current.click();
    }
  }, [dataUpload]);
   */

  // funciones

  const btnDownload = async (id) => {
    await downloadFile({
      data: {},
      api: `electoral/get-acreditacion-download/${DEBUG ? id : encryptVars(id)}`,
    });
  };

  const handleClick = async (e, usuario, ine, tipo) => {
    setDataUpload({
      idUsuario: usuario,
      INE: ine,
      idTipo: tipo,
    });

    filePdf.current.click();
  };

  /*   useEffect(() => {
    if (dataUpload.idUsuario && dataUpload.INE && dataUpload.idTipo) {
      filePdf.current.click();
    }
  }, [dataUpload]);
 */

  const handleFileChange = (event) => {
    setFileName(event.target.files[0]);
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setFileBase64(base64String);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  useEffect(() => {
    if (dataUpload.idUsuario !== undefined && fileBase64 !== '') {
      setLoadingPdf(true);
      electoral
        .setPDFs({
          ...dataUpload,
          files: fileBase64,
        })
        .then((res) => {
          if (res.results) {
            getData();
            Swal.fire({ title: 'Archivo Guardado!', icon: 'success' });
          } else {
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          filePdf.current.value = '';
          setFileBase64('');
          setLoadingPdf(false);
        });
    }
  }, [fileBase64]);

  const handleDownload = (id) => {
    setLoadingDownload(true);
    electoral
      .getPDFsView({
        id: id,
      })
      .then((response) => {
        if (response.success && response.results) {
          response.response.data.forEach((item) => {
            setImagenView({
              rute: item.NombreArchivo,
              Name: `${item.INE}.${item.extension}`,
            });
            setOpen(true);
            /*    Swal.fire({
              title: "Archivo descargado",
              icon: "success",
            }); */
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Ha ocurrido un error',
          text: `No se pudo descargar el archivo, intente de nuevo, ${error.message}`,
          icon: 'warning',
        });
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const getData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getResponsables(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                CompromisosDirectos:
                  item.CompromisosDirectos !== 0 ? numberWithCommas(item.CompromisosDirectos) : '0',
                CompromisosUnicosDirectos:
                  item.CompromisosUnicosDirectos !== 0
                    ? numberWithCommas(item.CompromisosUnicosDirectos)
                    : '0',
                UsuariosDirectos: item.UsuariosDirectos !== 0 ? numberWithCommas(item.UsuariosDirectos) : '0',
                CompromisosEnRed: item.CompromisosEnRed !== 0 ? numberWithCommas(item.CompromisosEnRed) : '0',
                CompromisosUnicosEnRed:
                  item.CompromisosUnicosEnRed !== 0 ? numberWithCommas(item.CompromisosUnicosEnRed) : '0',
                UsuariosEnRed: item.UsuariosEnRed !== 0 ? numberWithCommas(item.UsuariosEnRed) : '0',
                SeccionVota: item.SeccionVota,
                ColoniaVive: item.ColoniaVive,
                DescargarFormato: <button onClick={() => btnDownload(item.id)}>Descargar</button>,
                Archivo: [2, 3].includes(item.idPerfil) ? (
                  <Stack direction="row" spacing={1}>
                    {item.TipoArchivo2 && (
                      <Tooltip title="Descargar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="secondary"
                          onClick={() => handleDownloadImg(item.TipoArchivo2, 2)}
                        >
                          <Icon>download</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.TipoArchivo2 && (
                      <Tooltip title="Visualizar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="warning"
                          onClick={() => handleDownload(item.TipoArchivo2, 2)}
                        >
                          <Icon>visibility</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Subir Archivo">
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="info"
                        onClick={(e) => handleClick(e, item.idUsuario, item.INE, 2)}
                      >
                        <Icon>upload_file</Icon>
                      </IconButton>
                      <input
                        type="file"
                        ref={filePdf}
                        accept="image/*"
                        style={{ display: 'none' }}
                        name="imgMovilURL"
                        //value={fileName}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Tooltip>
                  </Stack>
                ) : (
                  'Perfil sin archivo'
                ),
                INEArchivo: [2, 3].includes(item.idPerfil) ? (
                  <Stack direction="row" spacing={1}>
                    {item.TipoArchivo3 && (
                      <Tooltip title="Descargar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="secondary"
                          onClick={() => handleDownloadImg(item.TipoArchivo3, 3)}
                        >
                          <Icon>download</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.TipoArchivo3 && (
                      <Tooltip title="Visualizar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="warning"
                          onClick={() => handleDownload(item.TipoArchivo3, 3)}
                        >
                          <Icon>visibility</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Subir imagen INE ">
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="info"
                        onClick={(e) => handleClick(e, item.idUsuario, item.INE, 3)}
                      >
                        <Icon>badge</Icon>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  'Perfil sin archivo'
                ),
                FormatoUni: [2, 3].includes(item.idPerfil) ? (
                  <Stack direction="row" spacing={1}>
                    {item.TipoArchivo1 && (
                      <Tooltip title="Descargar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="secondary"
                          onClick={() => handleDownloadImg(item.TipoArchivo1, 1)}
                        >
                          <Icon>download</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.TipoArchivo1 && (
                      <Tooltip title="Visualizar Archivo">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="warning"
                          onClick={() => handleDownload(item.TipoArchivo1, 1)}
                        >
                          <Icon>visibility</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Subir Archivo">
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="info"
                        onClick={(e) => handleClick(e, item.idUsuario, item.INE, 1)}
                      >
                        <Icon>upload_file</Icon>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  'Perfil sin archivo'
                ),
                Estatus: item.Estatus ? (
                  <Chip label={item.Estatus} sx={{ bgcolor: item.Color }} variant="outlined" />
                ) : (
                  ''
                ),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });

    setLoadingStats(true);
    electoral
      .getStatsResponsables(statsData)
      .then((res) => {
        if (res.results) {
          setDataCard(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoadingStats(false);
      });
  };

  const handleChangeFilter = (type) => {
    const {
      Manifiesto,
      Universal,
      INE,
      estatus,
      Region,
      Responsabilidad,
      MunicipioDL,
      Celular,
      NombreCompleto,
    } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [...filtered, { id: 'idRegion', filter: '=', value: Region }];

      filtro = [
        ...filtro,
        {
          id: 'cat_municipios_reportes.idRegion',
          filter: '=',
          value: Region,
        },
      ];
    }
    if (MunicipioDL > 0) {
      filtered = [...filtered, { id: 'idMunicipio', filter: '=', value: MunicipioDL }];

      filtro = [
        ...filtro,
        {
          id: 'cat_municipios_reportes.id',
          filter: '=',
          value: MunicipioDL,
        },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'Celular',
          filter: '=',
          value: Celular,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'NombreCompleto',
          filter: 'LIKE',
          value: `%${NombreCompleto.toUpperCase().replace(/ /g, '%')}%`,
        },
      ];
    }
    if (Responsabilidad > 0) {
      filtered = [
        ...filtered,
        {
          id: 'idPerfil',
          filter: '=',
          value: Responsabilidad,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'estad_meta_electoral.idPerfil',
          filter: '=',
          value: Responsabilidad,
        },
      ];
    }

    if (estatus > 0) {
      filtered = [
        ...filtered,
        {
          id: 'idEstatus',
          filter: '=',
          value: estatus,
        },
      ];
    }

    if (Manifiesto == 1) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo2',
          filter: 'IS',
          value: 'NULL',
        },
      ];
    }

    if (Manifiesto == 2) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo2',
          filter: 'IS NOT',
          value: 'NULL',
        },
      ];
    }

    if (Universal == 1) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo1',
          filter: 'IS',
          value: 'NULL',
        },
      ];
    }

    if (Universal == 2) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo1',
          filter: 'IS NOT',
          value: 'NULL',
        },
      ];
    }

    if (INE == 1) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo3',
          filter: 'IS',
          value: 'NULL',
        },
      ];
    }

    if (INE == 2) {
      filtered = [
        ...filtered,
        {
          id: 'TipoArchivo3',
          filter: 'IS NOT',
          value: 'NULL',
        },
      ];
    }

    setTableData({
      ...tableData,
      page: 0,
      filtered: filtered,
    });

    setStatsData({
      ...statsData,
      filtered: filtro,
    });
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      NombreCompleto: 'usuarios.NombreCompleto',
      Celular: 'usuarios.Celular',
      PerfilAmbito: 'vw_listado_usuarios_electoral.ResponsabilidadEtiqueta',
      SeccionVota: 'usuarios.SeccionVota',
      ColoniaVive: 'usuarios.ColoniaVive',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleDownloadImg = (id) => {
    if (loadingDownload) {
      return true;
    }

    setLoadingDownload(true);
    electoral
      .getPDFs({
        id: id,
      })
      .then((response) => {
        if (response.success && response.results) {
          Swal.fire({
            title: 'Archivo descargado',
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Ha ocurrido un error',
          text: `No se pudo descargar el archivo, intente de nuevo, ${error.message}`,
          icon: 'warning',
        });
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setImagenView({
      rute: '',
      Name: '',
    });
  };

  const modalView = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '35%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Card sx={{ overflow: 'auto' }}>
          <CardHeader
            avatar={
              <Icon color="error" onClick={() => handleClose()}>
                close
              </Icon>
            }
          />
          <CardMedia
            component="img"
            //height="20%"
            //width={"50%"}
            image={imagenView.rute}
            alt=""
          />
        </Card>
      </Box>
    </Modal>
  );

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'Responsables'} />
        {modalView}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <FilterResponsable
                  onChangeFilter={(e) => handleChangeFilter(e)}
                  formatos={true}
                  estatus={true}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AttendanceCard
              data={{
                contentData: String(roundTo(dataCard.PorcentajeAvance ? dataCard.PorcentajeAvance : 0, 2)),
                leftFooterData: String(numberWithCommas(dataCard.TotalAvance ? dataCard.TotalAvance : 0)),
                rightFooterData: String(numberWithCommas(dataCard.TotalMeta ? dataCard.TotalMeta : 0)),
              }}
              config={{
                leftFooterTitle: 'Avance',
                rightFooterTitle: 'Meta',
              }}
              loading={loadingStats}
              titlecontent={'Responsables'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              {(loadingDownload || loadingPdf) && (
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body" color="text.secondary" align="center">
                      {loadingDownload ? 'Descargando' : loadingPdf ? 'Guardando' : ''}
                    </Typography>
                  </Box>
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      sx={{ height: 10, borderRadius: 10 }}
                      color={loadingDownload ? 'secondary' : loadingPdf ? 'info' : 'error'}
                    />
                  </Box>
                </Stack>
              )}
              <CardContent>
                <BasicTable
                  rows={data}
                  hcolumns={columns}
                  pageProp={tableData.page}
                  pageSize={tableData.pageSize}
                  total={total}
                  handleChangePagination={handleChangePagination}
                  isLoading={loading}
                  stickyHeader={true}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default ResponsablesElectoral;
