import { ListItem, ListItemText, ListItemButton } from '@mui/material';

const ItemUser = ({ item, selectedIndex, handleSelectedIndex }) => {
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  return (
    <ListItem key={item.value ? item.value : Math.random()} disablePadding>
      <ListItemButton
        sx={{ mb: 1, borderRadius: 2 }}
        selected={selectedIndex === item.value}
        onClick={(event) => handleListItemClick(event, item.value)}
      >
        <ListItemText
          primary={`${item.label}(${item.Edad} años) - ${item.Municipio}`}
          secondary={`Loc: ${item.Localidad} - Col. ${item.ColoniaVive}`}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ItemUser;
