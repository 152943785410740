import React, { useEffect } from 'react';
import { Box, Typography, Divider, Modal, IconButton, TextField, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { red } from '@mui/material/colors';

import Swal from 'sweetalert2';
import { useFormik } from 'formik';

//COMPONENTS
import { updateInfoCompromisos } from '@data/schemas/CompromisoSchemas';
import { updateInfo } from '@data/interfaces/CompromisoInterfaces';
import { handleKeyPress, handleKeyPress2 } from '@data/schemas/MovilizationSchemas';

import CompromisosSevices from '@services/CompromisoServices';

//UTILS
import { validaINE } from '@utils/Utilities';

const EditModal = (props) => {
  const { setModalOpen, modalOpen, params, setLoading, hasResponsable } = props;

  const formik = useFormik({
    initialValues: updateInfo,
    validationSchema: updateInfoCompromisos,
    enableReinitialize: false,
    onSubmit: (values, actions) => {
      Valid(values, actions);
    },
  });

  const handleEditModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (fieldName) => (e) => {
    const { value } = e.target;
    formik.setValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleINE = handleInputChange('INE');
  const handleSeccionVota = handleInputChange('SeccionVota');
  const handleCelular = handleInputChange('Celular');
  const handleTelefono = handleInputChange('Telefono');
  const handleMensajes = handleInputChange('TelMensajes');
  const handleNombre = handleInputChange('Nombre');
  const handlePaterno = handleInputChange('Paterno');
  const handleMaterno = handleInputChange('Materno');

  const excludeFields = (obj, excludedFields) => {
    const newObj = { ...obj };
    excludedFields.forEach((field) => delete newObj[field]);
    Object.keys(newObj).forEach((key) => {
      if (newObj[key] === null || newObj[key] === '') {
        delete newObj[key];
      }
    });
    return newObj;
  };

  const updateParams = (usuario, nuevosValores) => {
    Object.keys(nuevosValores).forEach((clave) => {
      if (usuario.hasOwnProperty(clave) && nuevosValores[clave] !== null) {
        usuario[clave] = String(nuevosValores[clave]).toUpperCase();
      }
    });
  };

  const Valid = async (values) => {
    const { INE, Nombre, Paterno, Materno, TieneUsuario } = values;
    if (values) {
      let lenINE = INE.length;
      let genderId = '';
      if (lenINE >= 15) {
        const genderKey = INE.substring(14, 15);
        genderId = genderKey === 'M' ? 3 : genderKey === 'H' ? 2 : 1;
      }

      const res = validaINE(INE, Nombre, Paterno, Materno, genderId);

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            container: 'modal-alert',
          },
        });
      } else {
        if (TieneUsuario || hasResponsable > 0) {
          Swal.fire({
            title: 'Advertencia',
            html: `Se ha detectado que el compromiso tiene una cuenta de usuario. <br/><br/>Por favor, seleccione una de las siguientes opciones.`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#1976d2',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: { container: 'modal-alert' },
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };

  const handleSave = async (values) => {
    try {
      setLoading(true);
      const excludedFields = ['UserName', 'TieneUsuario'];
      const updatedValues = excludeFields(values, excludedFields);
      const result = await CompromisosSevices.updateUnique(updatedValues);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          customClass: {
            container: 'modal-alert',
          },
        });
        setModalOpen(false);
        updateParams(params, values);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params) {
      formik.setValues((prev) => ({
        ...prev,
        id: params.id,
        INE: params.INE ? params.INE.trim() : '',
        SeccionVota: params.SeccionVota,
        Nombre: params.Nombre ? params.Nombre.trim() : '',
        Paterno: params.Paterno ? params.Paterno.trim() : '',
        Materno: params.Materno ? params.Materno.trim() : '',
        Celular: params.Celular ? params.Celular.trim() : null,
        Telefono: params.Telefono ? params.Telefono.trim() : null,
        TelMensajes: params.TelMensajes ? params.TelMensajes.trim() : null,
        UserName: params.Username ? params.Username.trim() : null,
        TieneUsuario: params.TieneUsuario,
      }));
    }
    //eslint-disable-next-line
  }, [params]);

  return (
    <Modal
      open={modalOpen}
      disableEscapeKeyDown
      disableBackdropClick
      aria-labelledby="modal-edit-title"
      aria-describedby="modal-edit-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 900,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-edit-title" variant="h6" component="h2">
            Corrección de informació
          </Typography>
          <IconButton onClick={handleEditModalClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Grid2 container xs={12} spacing={2} mt={2}>
          <Grid2 item xs={12} md={4}>
            <TextField
              name="INE"
              label="Clave de Elector"
              variant="outlined"
              fullWidth
              value={formik.values.INE.toUpperCase()}
              onChange={handleINE}
              size="small"
              pattern="/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g"
              type="text"
              error={Boolean(formik.errors.INE)}
              helperText={formik.errors.INE}
            />
          </Grid2>
          <Grid2 item xs={12} md={4}>
            <TextField
              name="SeccionVota"
              label="Sección Vota"
              variant="outlined"
              fullWidth
              value={formik.values.SeccionVota}
              onChange={handleSeccionVota}
              size="small"
              onKeyPress={handleKeyPress}
              error={Boolean(formik.errors.SeccionVota)}
              helperText={formik.errors.SeccionVota}
            />
          </Grid2>
        </Grid2>
        <Grid2 container xs={12} spacing={2} mt={2}>
          <Grid2 item xs={12} md={4}>
            <TextField
              name="Nombre"
              label="Nombre"
              variant="outlined"
              fullWidth
              value={formik.values.Nombre.toUpperCase()}
              onChange={handleNombre}
              size="small"
              onKeyPress={handleKeyPress2}
              error={Boolean(formik.errors.Nombre)}
              helperText={formik.errors.Nombre}
            />
          </Grid2>
          <Grid2 item xs={12} md={4}>
            <TextField
              name="Paterno"
              label="Paterno"
              variant="outlined"
              fullWidth
              value={formik.values.Paterno.toUpperCase()}
              onChange={handlePaterno}
              size="small"
              onKeyPress={handleKeyPress2}
              error={Boolean(formik.errors.Paterno)}
              helperText={formik.errors.Paterno}
            />
          </Grid2>
          <Grid2 item xs={12} md={4}>
            <TextField
              name="Materno"
              label="Materno"
              variant="outlined"
              fullWidth
              value={formik.values.Materno.toUpperCase()}
              onChange={handleMaterno}
              size="small"
              onKeyPress={handleKeyPress2}
              error={Boolean(formik.errors.Materno)}
              helperText={formik.errors.Materno}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={1} justifyContent="center" mt={3}>
          <Grid2 item xs={6} sm={2} md={2}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={handleEditModalClose}
              style={{ backgroundColor: red[800] }}
            >
              Cancelar
            </Button>
          </Grid2>
          <Grid2 item xs={6} sm={2} md={2}>
            <Button
              fullWidth
              variant="contained"
              color="primaryDark"
              size="small"
              onClick={formik.handleSubmit}
            >
              Guardar
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>
  );
};

export default EditModal;
