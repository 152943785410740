import { useState, useEffect } from 'react';

// Material UI
import { Box } from '@mui/material';
import BasicTable from '@components/Tables/BasicTable';
import Swal from 'sweetalert2';

// Utilidades
import { numberWithCommas } from '@utils/Utilities/';

// Servicios
import MetasServices from '@services/MetasServices';

const InfoCard = ({ filter, filtered }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([1]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await MetasServices.getGoalsTotals(filtered);
      const { results, message, response } = result;

      if (results) {
        const dataAPI = response.data[0];
        const dataTextCard = Object.keys(dataAPI).map((key) => ({
          Resumen: key.slice(5),
          Total: dataAPI[key] === null ? '-' : numberWithCommas(dataAPI[key]),
        }));

        setData(dataTextCard);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: 'warning',
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [filter]);

  // Titulos de la tabla
  const columns = [
    { id: 'Resumen', label: 'Resumen' },
    { id: 'Total', label: '', align: 'right' },
  ];

  return (
    <Box>
      <BasicTable
        rows={data}
        hcolumns={columns}
        isLoading={loading}
        cardType={false}
        stickyHeader={true}
        showPagination={false}
      />
    </Box>
  );
};

export default InfoCard;
