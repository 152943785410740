import { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import { useSelection } from '../../hooks/use-funcion';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { Box, Container, Card, CardContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ListMove from '../../components/FrenteAmplio/ListMove';
import TimelinePath from '../../components/FrenteAmplio/TimelinePath';

const RutasMovilizacion = () => {
  const [data, setData] = useState([]);
  const dataSelection = useSelection({
    items: data,
    Api: FrenteAmplioServices.getMyListMovilizacion,
    infinite: true,
  });

  useEffect(() => {
    const list = dataSelection.data.map((item) => {
      return {
        ...item,
        subtitle: item.DomicilioCompleto,
        secundaryTitle: 'Hola',
        label: 'Domicilio',
        subLabel: item.DomicilioCompleto,
        colorCircle: 'transparent',
        icon: 'person_pin_circle',
        colorIcon: '#E69138',
      };
    });
    setData(list);
  }, [dataSelection.data]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'Rutas Movilización'} />
        <Box>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <ListMove
                data={data}
                Header={true}
                search={false}
                count={dataSelection.total}
                fetchHasMore={dataSelection.fetchHas}
                hasMore={dataSelection.hasMore}
                configParams={{
                  title: 'Domicilios',
                  icon: 'map',
                  height: 579,
                  endMessage: 'No hay registros para mostrar',
                }}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardContent>
                  <TimelinePath
                    data={data}
                    count={dataSelection.total}
                    isLoading={dataSelection.isLoading}
                    fetchHasMore={dataSelection.fetchHas}
                    hasMore={dataSelection.hasMore}
                    configParams={{
                      title: '',
                      icon: 'location_on',
                      height: 550,
                      endMessage: 'No hay registros para mostrar',
                    }}
                  />
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </AdminLayout>
  );
};
export default RutasMovilizacion;
