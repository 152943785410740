import React, { useEffect, useState } from 'react';

import {
  Card,
  CardContent,
  Container,
  Grid,
  Box,
  Icon,
  Typography,
  Stack,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tab,
} from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Tree from '@components/TreeView/TreeView';
import FilterData from '@components/Movilizacion/FilterData';
import UserServices from '@services/UserServices';
import Swal from 'sweetalert2';
import { numberWithCommas, searchTree } from '@utils/Utilities';
import Table from '@components/Movilizacion/Table';
import TableColmena from '@components/Movilizacion/TableColmena';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Colmena = () => {
  const [loadingTree, setLoadingTree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [nameUser, setNameUser] = useState('');
  const [phone, setPhone] = useState('');
  const [treeUsersFound, setTreeUsersFound] = useState({});
  const [treeUsers, setTreeUsers] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const [isExpanded, setIsExpanded] = useState(true);

  const [filter, setFilter] = useState({
    filtered: [],
  });
  const [data, setData] = useState({
    idUsuario: 0,
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [totalUser, setTotalUser] = useState({
    totalUsuarios: 0,
    totalCompromisos: 0,
    totalCompromisosUnicos: 0,
  });

  //API's
  const getMyUsersTree = () => {
    setLoadingTree(true);
    UserServices.getMyUsers({})
      .then((res) => {
        if (res.results) {
          setTreeUsers([res.response]);
          setTreeUsersFound(res.response);
          handleItemTree(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingTree(false));
  };

  const ApiResponse = async (values) => {
    setLoading(true);
    try {
      const result = await rows;
      const { results, message, response } = result;
      if (results) {
        let filteredData = response.data;
        if (tabValue === 1) {
          filteredData = response.data.filter(
            (item) =>
              item.ContactoEstatus === 1 ||
              (item.ContactoEstatus === 2 && item.EstatusMedio === 3) ||
              item.ContactoEstatus === 3
          );
        } else if (tabValue === 2) {
          filteredData = response.data.filter(
            (item) => item.ContactoEstatus === 2 && item.EstatusMedio !== 3
          );
        }
        setDataTable(filteredData);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleItemTree = (e) => {
    if (e !== null) {
      const { id, Nombre, Paterno, Materno, TotalUsuarios, TotalCompromisos, CompromisosUnicos } = e;
      const NameUser = `${Nombre} ${Paterno} ${Materno}`;
      setNameUser(NameUser);
      setTotalUser({
        ...totalUser,
        totalUsuarios: numberWithCommas(TotalUsuarios),
        totalCompromisos: numberWithCommas(TotalCompromisos),
        totalCompromisosUnicos: numberWithCommas(CompromisosUnicos),
      });
      setData({ ...data, idUsuario: id });
    } else {
      setNameUser('');
      setTotalUser({
        ...totalUser,
        totalUsuarios: 0,
        totalCompromisos: 0,
        totalCompromisosUnicos: 0,
      });
    }
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSearch = () => {
    if (phone === '') {
      return;
    }
    const founds = searchTree(treeUsersFound, 'Username', phone);
    setTreeUsers(founds !== null ? [founds] : []);
    setShowButtonCancel(true);
    handleItemTree(founds);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClearSearch = () => {
    setPhone('');
    setShowButtonCancel(false);
    getMyUsersTree();
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleChangeFilter = (option) => {
    setFilter({
      ...filter,
      filtered: [option],
    });
  };

  useEffect(() => {
    getMyUsersTree();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    ApiResponse(filter);
    //eslint-disable-next-line
  }, [filter, tabValue]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Mis Movilizados'} />
        <Grid container spacing={2}>
          {!isExpanded && (
            <IconButton
              onClick={() => setIsExpanded(!isExpanded)}
              aria-label="open Drawer"
              color={isExpanded ? 'default' : 'primary'}
            >
              {isExpanded ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          )}
        </Grid>
        <Grid container spacing={2}>
          {isExpanded && (
            <Grid item xs={12} md={4}>
              <Card className="card-primary" sx={{ height: '1200px', overflow: 'auto' }}>
                <CardContent>
                  <Box marginBottom={'1rem'}>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent="space-between"
                      spacing={1}
                      marginBottom={'0.5rem'}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Icon>person_search</Icon>
                        <Typography fontWeight={600} variant={'body2'} marginBottom={'0.5rem'}>
                          Buscar por número de celular
                        </Typography>
                      </Stack>
                      <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <ChevronLeft /> : <ChevronRight />}
                      </IconButton>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        label="Celular"
                        size="small"
                        name="phone"
                        value={phone}
                        onChange={handleChangePhone}
                        onKeyUp={handleOnKeyPress}
                        InputProps={{
                          endAdornment: showButtonCancel && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearSearch}>
                                <Icon>clear</Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: '100%' }}
                      />
                      <Button
                        variant="contained"
                        color="primaryDark"
                        sx={{ width: '25%' }}
                        onClick={handleSearch}
                      >
                        Buscar
                      </Button>
                    </Stack>
                  </Box>
                  <Divider sx={{ marginBottom: '1rem' }} />
                  <Tree
                    data={treeUsers}
                    typeData={{
                      value: 'id',
                      label: 'Nombre',
                      S_label: 'Paterno',
                      T_label: 'Username',
                    }}
                    handleClick={handleItemTree}
                    loading={loadingTree}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={isExpanded ? 8 : null}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Card style={{ padding: 16, borderRadius: 8 }}>
                  <Box>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Icon style={{ fontSize: 30, color: '#0293fa', marginLeft: 4 }}>groups</Icon>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                          Estadística de promoción
                        </Typography>
                      </Grid>
                    </Grid>
                    <Table data={sampleData} />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card style={{ padding: 16, borderRadius: 8 }}>
                  <Box>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Icon style={{ fontSize: 30, color: '#0293fa', marginLeft: 4 }}>groups</Icon>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                          Estatus de movilización
                        </Typography>
                      </Grid>
                    </Grid>
                    <Table data={sampleData2} />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card style={{ padding: 16, borderRadius: 8 }}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Icon style={{ fontSize: 30, color: '#0293fa', marginLeft: 4 }}>update</Icon>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                          Último corte
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                          20 Octubre
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                          10:10
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          color="primaryDark"
                          /* onClick={handleUpdate} */ fullWidth
                          style={{ marginTop: 16 }}
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Card className="card-primary" sx={{ mt: 2 }}>
              <CardContent>
                {nameUser !== '' && (
                  <>
                    <Stack direction={'row'} spacing={1} alignItems="center">
                      <Icon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>account_circle</Icon>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {nameUser}
                      </Typography>
                    </Stack>
                    <Divider />
                  </>
                )}
                <Box marginTop={'1rem'} sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tabValue}>
                    <Box>
                      <TabList onChange={handleTabChange} aria-label="Tabs example" centered>
                        <Tab label="Pendientes" value={1} />
                        <Tab label="Contactados" value={2} />
                      </TabList>
                      <FilterData onChangeFilter={(e) => handleChangeFilter(e)} TabValue={tabValue} />
                    </Box>
                    <TabPanel value={1}>
                      <TableColmena
                        columns={columns}
                        rows={dataTable}
                        pageSize={data.pageSize}
                        page={data.page}
                        loading={loading}
                      />
                    </TabPanel>
                    <TabPanel value={2}>
                      <TableColmena
                        columns={columns}
                        rows={dataTable}
                        pageSize={data.pageSize}
                        page={data.page}
                        loading={loading}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};

export default Colmena;
const sampleData = [
  { label: 'Compromisos', value: '123,456' },
  { label: 'Votantes', value: '234,568' },
  { label: 'Pendientes', value: '12,354' },
  // Agrega más objetos si es necesario
];
const sampleData2 = [
  { label: 'Pendientes', value: '123,456' },
  { label: 'Contactados', value: '234,568' },
  { label: 'No Localizados', value: '12,354' },
  // Agrega más objetos si es necesario
];
const rows = {
  results: true,
  message: 'Consultado con exitó',
  response: {
    data: [
      {
        id: 1,
        NombreCompleto: 'Juan Pérez',
        Celular: '555-123-4567',
        EstatusContacto: 'Pendiente',
        ContactoEstatus: 1,
        MedioContacto: '',
        EstatusMedio: 0,
        Domicilio: 'Calle Principal 123',
        Seccion: 'A',
      },
      {
        id: 2,
        NombreCompleto: 'María López',
        Celular: '555-987-6543',
        EstatusContacto: 'Pendiente',
        ContactoEstatus: 1,
        MedioContacto: '',
        EstatusMedio: 0,
        Domicilio: 'Avenida Secundaria 456',
        Seccion: 'B',
      },
      {
        id: 3,
        NombreCompleto: 'María López',
        Celular: '555-987-6543',
        EstatusContacto: 'Contactado',
        ContactoEstatus: 2,
        MedioContacto: 'Pendiente',
        EstatusMedio: 3,
        Domicilio: 'Avenida Secundaria 456',
        Seccion: 'B',
      },
      {
        id: 4,
        NombreCompleto: 'María López',
        Celular: '555-987-6543',
        EstatusContacto: 'Contactado',
        ContactoEstatus: 2,
        MedioContacto: 'No va a votar',
        EstatusMedio: 2,
        Domicilio: 'Avenida Secundaria 456',
        Seccion: 'B',
      },
      {
        id: 5,
        NombreCompleto: 'María López',
        Celular: '555-987-6543',
        EstatusContacto: 'No Localizado',
        ContactoEstatus: 3,
        MedioContacto: '',
        EstatusMedio: 0,
        Domicilio: 'Avenida Secundaria 456',
        Seccion: 'B',
      },
      {
        id: 6,
        NombreCompleto: 'María López',
        Celular: '555-987-6543',
        EstatusContacto: 'No Localizado',
        ContactoEstatus: 3,
        MedioContacto: '',
        EstatusMedio: 0,
        Domicilio: 'Avenida Secundaria 456',
        Seccion: 'c',
      },
    ],
  },
};
const columns = [
  { id: 'NombreCompleto', label: 'Nombre', align: 'center' },
  { id: 'Celular', label: 'Celular', align: 'center' },
  {
    id: 'EstatusContacto',
    label: 'Estatus Contacto',
    align: 'center',
    select: true,
    options: [
      {
        label: 'Pendiente',
        value: 1,
      },
      {
        label: 'Contactado',
        value: 2,
      },
      {
        label: 'No Localizado',
        value: 3,
      },
    ],
    width: '200px',
  },
  {
    id: 'MedioContacto',
    label: 'Medio de Contacto',
    align: 'center',
    select: true,
    options: [
      {
        label: 'Ya votó',
        value: 1,
      },
      {
        label: 'No va a votar',
        value: 2,
      },
      {
        label: 'Pendiente',
        value: 3,
      },
    ],
    width: '200px',
  },
  { id: 'Domicilio', label: 'Domicilio', align: 'center' },
  { id: 'Seccion', label: 'Sección', align: 'center', width: '100px' },
];
