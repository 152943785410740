import { useState } from 'react';

// Material UI
import { Grid, Typography, Button } from '@mui/material';

// Componentes
import BasicListDoble from '@components/Lists/BasicListDoble';

// Servicios
import UserService from '@services/UserServices';

// Utilidades
import { isTypePhone } from '@utils/validations';
import useWindowDimensions from '@hooks/useWindowDimensions';

const CustomTabsExamples = () => {
  const [header, setHeader] = useState(true);
  const [label, setLabel] = useState(true);
  const [labelNote, setLabelNote] = useState(true);
  const [finder, setFinder] = useState(true);
  const [doubleSearch, setDoubleSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState(false);
  const [initialSearch, setInitialSearch] = useState(false);
  const [disableCardType, setDisableCardType] = useState(false);
  const [breaks, setBreaks] = useState(true);
  const [buttonsBreaks, setButtonsBreaks] = useState(true);

  const { height } = useWindowDimensions();

  const handleHeader = () => setHeader(!header);
  const handleLabel = () => setLabel(!label);
  const handleLabelNote = () => setLabelNote(!labelNote);
  const handleFinder = () => setFinder(!finder);
  const handleDoubleSearch = () => setDoubleSearch(!doubleSearch);
  const handleEmptySearch = () => setEmptySearch(!emptySearch);
  const handleInitialSearch = () => setInitialSearch(!initialSearch);
  const handleDisableCardType = () => setDisableCardType(!disableCardType);
  const handleBreaks = () => setBreaks(!breaks);
  const handleButtonsBreaks = () => setButtonsBreaks(!buttonsBreaks);

  const handleClick = (e) => console.log(e);

  return (
    <Grid container spacing={2} rowSpacing={5} sx={{ p: 1 }}>
      {/* Opciones */}
      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800 }}>
          Opciones
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={header ? 'outlined' : 'contained'} onClick={handleHeader}>
          {header ? 'Quitar header' : 'Agregar header'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={label ? 'outlined' : 'contained'} onClick={handleLabel}>
          {label ? 'Quitar title' : 'Agregar title'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={labelNote ? 'outlined' : 'contained'} onClick={handleLabelNote}>
          {labelNote ? 'Quitar titleNote' : 'Agregar titleNote'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={finder ? 'outlined' : 'contained'} onClick={handleFinder}>
          {finder ? 'Quitar finder' : 'Agregar finder'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={doubleSearch ? 'outlined' : 'contained'} onClick={handleDoubleSearch}>
          {doubleSearch ? 'Quitar doubleSearch' : 'Agregar doubleSearch'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={emptySearch ? 'outlined' : 'contained'} onClick={handleEmptySearch}>
          {emptySearch ? 'Deshabilitar emptySearch' : 'Habilitar emptySearch'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={initialSearch ? 'outlined' : 'contained'} onClick={handleInitialSearch}>
          {initialSearch ? 'Deshabilitar initialSearch' : 'Habilitar initialSearch'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth
          variant={disableCardType ? 'outlined' : 'contained'}
          onClick={handleDisableCardType}
        >
          {disableCardType ? 'Habilitar CardType ' : 'Deshabilitar CardType'}
        </Button>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={breaks ? 'outlined' : 'contained'} onClick={handleBreaks}>
          {breaks ? 'Cambiar breaks 1' : 'Cambiar breaks 2'}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth variant={buttonsBreaks ? 'outlined' : 'contained'} onClick={handleButtonsBreaks}>
          {buttonsBreaks ? 'Cambiar buttonsBreaks 1' : 'Cambiar buttonsBreaks 2'}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <BasicListDoble
          id="id_nuevo"
          API={UserService.getUsers}
          APIKeys={[
            { id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)", filter: 'LIKE' },
            {
              id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
              filter: 'LIKE',
            },
          ]}
          // itemComponent={DefaultListItem} // Se puede omitir
          header={header}
          label={label ? 'Encuentra al usuario' : ''}
          labelNote={labelNote ? 'Nota: Debe ingresar un nombre' : ''}
          finder={finder}
          handleClick={handleClick}
          inputValidation={{ input2: isTypePhone }}
          initialSearch={initialSearch}
          emptySearch={emptySearch}
          doubleSearch={doubleSearch}
          breaks={breaks ? { sm: 12, md: 6 } : { sm: 12, md: 12 }}
          buttonsBreaks={buttonsBreaks ? { sm: 12, md: 4 } : { sm: 12, md: 6 }}
          disableCardType={disableCardType}
          config={{ height: height * 0.5 }}
          clearData
        />
      </Grid>
    </Grid>
  );
};

export default CustomTabsExamples;
