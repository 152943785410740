import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import { Card, CardContent, Box, Button } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomTabs from '@components/Tabs/CustomTabs';

import Config from '@components/Activation/Asign/Config';
import SectionDetails from '@components/Activation/Asign/SectionDetails';
import AccordionMobilizers from '@components/Activation/Asign/AccordionMobilizers';
import AsignCompromisos from '@components/Activation/Asign/AsignCompromisos';

// Utilidades
import { getVars, setVars } from '@utils/global';
import { isEmptyOrNullObject, isEmptyOrInvalidArray } from '@utils/validations';

// Servicios
import MovilizationServices from '@services/MovilizationServices';

const MobilizationAssign = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState({ isLoading: false, isSuccess: false, open: false });

  const [valueTab, setValueTab] = useState('0');

  // Paso 1
  const [section, setSection] = useState(0);
  const [distributionType, setDistributionType] = useState('');
  const [catView, setCatView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Paso 2
  const [ids, setIds] = useState([]);
  const [mobilizers, setMobilizers] = useState({});

  // Paso 3
  const [assignment, setAssignment] = useState([]);
  const [limitVariable, setLimitVariable] = useState(0);
  const [mobilizationLimit, setMobilizationLimit] = useState(0);

  // Obtener Movilizadores de la sección
  const getMobilizers = async (section) => {
    setIsLoading(true);
    try {
      const res = await MovilizationServices.getListMobilizers(section);
      const { results, response, message } = res;

      if (results) {
        const totalsR = response.TotalsResponsables.filter((item) => item.value > 0).map((item) => ({
          ...item,
          value: item.id,
          total: item.value,
        }));

        const totalsC = response.totalsCompromisos
          .filter((item) => item.value > 0)
          .map((item) => ({
            ...item,
            value: item.id,
            total: item.value,
          }));

        const data = response.data.map((item) => ({
          ...item,
          value: item.id,
        }));

        const params = {
          totalsResponsables: totalsR,
          data: data,
          totalsCompromisos: totalsC,
        };

        setMobilizers(params);
        setVars('MobAsignMobilizers', params);
      } else throw new Error(message);
    } catch (error) {
      setMobilizers({});
      setVars('MobAsignMobilizers', {});
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  // Catalogos dinamicos
  const getCatView = async () => {
    try {
      const res = await MovilizationServices.getAmbitoAgrupadorMovilizacion();
      const { results, response, message } = res;

      if (results) {
        const data = response.data.data.sort((a, b) => a.value - b.value);
        setCatView(data);
        setVars('MobAsignCatView', data);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  // Limite de movilización
  const getMobilizationLimit = async () => {
    try {
      const res = await MovilizationServices.getMobilizationLimit();
      const { results, response, message } = res;

      if (results) {
        setLimitVariable(response.ValorVariable);
        setVars('MobAsignMobilizationLimitVariable', response.ValorVariable);

        setMobilizationLimit(response.ValorVariable);
        setVars('MobAsignMobilizationLimit', response.ValorVariable);
      } else throw new Error(message);
    } catch (error) {
      setLimitVariable(0);
      setMobilizationLimit(0);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    const catView = getVars('MobAsignCatView', '');
    if (!catView?.length) {
      getCatView();
      getMobilizationLimit();
      setVars('MobAsignCatView', '');
      setVars('MobAsignFilter', '');
      setVars('MobAsignCatalog', '');
      setVars('MobAsignCatalogFiltered', '');
      setVars('MobAsignSection', '');
      setVars('MobAsignIds', '');
      setVars('MobAsignMobilizers', '');
      setVars('MobAsignAssignment', '');
      setVars('MobAsignDistributionType', '');
      setVars('MobAsignMobilizationLimit', '');
      setVars('MobAsignMobilizationLimitVariable', '');
      handleClear();
    } else {
      const section = getVars('MobAsignSection', 0);
      const ids = getVars('MobAsignIds', []);
      const mobs = getVars('MobAsignMobilizers', {});
      const assign = getVars('MobAsignAssignment', []);
      const distribution = getVars('MobAsignDistributionType', '');
      const limit = getVars('MobAsignMobilizationLimit', 0);
      const limitVar = getVars('MobAsignMobilizationLimitVariable', 0);

      setCatView(catView !== '' ? catView : []);
      setIds(ids !== '' ? ids : []);
      setSection(section !== '' ? section : 0);
      setMobilizers(mobs !== '' ? mobs : {});
      setAssignment(assign !== '' ? assign : []);
      setDistributionType(distribution);
      setMobilizationLimit(limit !== '' ? limit : 0);
      setLimitVariable(limitVar !== '' ? limitVar : 0);
    }
    // eslint-disable-next-line
  }, []);

  const handleFilter = (e) => {
    setIds([]);
    setAssignment([]);
    setSection(e.value);
    setDistributionType('');
    getMobilizers({ Seccion: e.value });
    setLimitVariable(mobilizationLimit);

    setVars('MobAsignSection', e.value);
    setVars('MobAsignIds', []);
    setVars('MobAsignAssignment', []);
    setVars('MobAsignDistributionType', '');
    setVars('MobAsignMobilizationLimitVariable', mobilizationLimit);
  };

  const handleClear = () => {
    setIds([]);
    setSection(0);
    setDistributionType('');
    setMobilizers({});
    setAssignment([]);

    setVars('MobAsignSection', 0);
    setVars('MobAsignIds', []);
    setVars('MobAsignMobilizers', {});
    setVars('MobAsignAssignment', []);
    setVars('MobAsignDistributionType', '');
  };

  const disabledButton = (value) => {
    switch (value ?? valueTab) {
      case '0':
      case '1':
        return (
          isEmptyOrNullObject(mobilizers) ||
          isEmptyOrInvalidArray(mobilizers?.totalsResponsables) ||
          isEmptyOrInvalidArray(mobilizers?.totalsCompromisos) ||
          distributionType === '' ||
          limitVariable === 0 ||
          limitVariable === ''
        );
      case '2':
        return ids.length === 0 || distributionType === 'auto';
      case '3':
        if (distributionType === 'manual') return assignment.length === 0;
        else
          return (
            isEmptyOrNullObject(mobilizers) ||
            isEmptyOrInvalidArray(mobilizers?.totalsResponsables) ||
            isEmptyOrInvalidArray(mobilizers?.totalsCompromisos) ||
            distributionType === '' ||
            limitVariable === 0 ||
            limitVariable === ''
          );
      default:
        return true;
    }
  };

  const actionButton = (action) => {
    const tab = parseInt(valueTab);
    if (action === 'siguiente') setValueTab(`${distributionType === 'auto' ? '4' : tab + 1}`);
    else setValueTab(`${distributionType === 'auto' ? '0' : tab - 1}`);
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={'Cargando datos...'}
        isLoading={loading.isLoading}
        success={loading.isSuccess}
        isOpen={loading.open}
        setIsOpen={() => setLoading({ ...loading, open: false })}
      />

      <ActionHeader
        title={`Asignar Movilizadores ${section ? `(sección ${section})` : ''}`}
        handleclick={() => navigate('/mis-usuarios')}
      />

      <Card className="card-primary" sx={{ marginBottom: { xs: '0px!important' } }}>
        <CardContent sx={{ padding: { xs: 0, sm: 2 } }}>
          <Box sx={{ typography: 'body1' }}>
            <CustomTabs
              value={valueTab}
              setValue={setValueTab}
              tabs={[
                {
                  value: '0',
                  icon: 'settings',
                  label: 'Configuración',
                  component: (
                    <Config
                      section={section}
                      handleFilter={handleFilter}
                      handleClear={handleClear}
                      mobilizers={mobilizers}
                      catView={catView}
                      isLoading={isLoading}
                      distributionType={distributionType}
                      setDistributionType={setDistributionType}
                      limit={mobilizationLimit}
                      limitVariable={limitVariable}
                      setLimit={setLimitVariable}
                    />
                  ),
                },
                {
                  value: '1',
                  icon: 'pin',
                  label: 'Detalle Sección',
                  display: distributionType === 'manual',
                  disabled: disabledButton('0') || distributionType === 'auto',
                  component: <SectionDetails section={section} mobilizers={mobilizers} />,
                },
                {
                  value: '2',
                  icon: 'how_to_reg',
                  label: 'Movilizadores',
                  display: distributionType === 'manual',
                  disabled: disabledButton('1') || distributionType === 'auto',
                  component: <AccordionMobilizers ids={ids} setIds={setIds} mobilizers={mobilizers} />,
                },
                {
                  value: '3',
                  icon: 'checklist_rtl',
                  label: 'Asignación',
                  display: distributionType === 'manual',
                  disabled: disabledButton('2') || distributionType === 'auto',
                  component: (
                    <AsignCompromisos
                      ids={ids}
                      limit={limitVariable}
                      mobilizers={mobilizers}
                      assignment={assignment}
                      setAssignment={setAssignment}
                    />
                  ),
                },
                {
                  value: '4',
                  icon: 'swap_horiz',
                  label: 'Resumen',
                  disabled: disabledButton('3'),
                  component: <>RESUMEN</>,
                },
              ]}
              color="secondary"
            />

            <Box sx={{ px: { xs: 2, sm: 0 }, mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                sx={{ px: 3 }}
                disabled={valueTab === '0'}
                onClick={() => actionButton('atras')}
              >
                Atras
              </Button>

              <Button
                variant="outlined"
                sx={{ px: 2 }}
                disabled={disabledButton()}
                onClick={() => actionButton('siguiente')}
              >
                Siguiente
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default MobilizationAssign;
