import { useState } from 'react';
import { Card, CardContent, CardHeader, Container, Button } from '@mui/material';
import { Add, ChevronLeft } from '@mui/icons-material';
import Swal from 'sweetalert2';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Prep/PartiesFilter';
import PartiesTable from '@components/Prep/PartiesTable';
import PartyForm from '@components/Prep/PartyForm';

import middleware from '@middlewares/middleware';

import prep from '@services/PrepServices';

const Partidos = () => {
  const [flagForm, setFlagForm] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [data4Edit, setData4Edit] = useState(null);

  const handleEdit = (data) => {
    prep
      .findElectoralParties(data.id)
      .then((res) => {
        if (res.results) {
          setFlagForm(true);
          setData4Edit(res.response);
        } else {
          setData4Edit(null);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
        setData4Edit(null);
      });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Administración de Partidos'} />

        <Card
          sx={{
            variant: 'elevation',
            borderRadius: '12px',
            padding: 0,
            width: '100%',
          }}
          className="card-primary"
        >
          <CardHeader
            title={!flagForm ? 'Consulta' : 'Registro / Edición'}
            action={
              flagForm ? (
                <Button
                  color="primaryDark"
                  size="medium"
                  variant="contained"
                  onClick={(e) => {
                    setFlagForm(false);
                    setData4Edit(null);
                  }}
                  startIcon={<ChevronLeft />}
                  sx={{ m: 2 }}
                >
                  Regresar
                </Button>
              ) : (
                middleware.checkMenuAction('Agregar') && (
                  <Button
                    color="primaryDark"
                    size="medium"
                    variant="contained"
                    onClick={(e) => {
                      setFlagForm(true);
                    }}
                    startIcon={<Add />}
                    sx={{ m: 2 }}
                  >
                    Agregar
                  </Button>
                )
              )
            }
          />
          <CardContent className="p-0">
            {!flagForm ? (
              <>
                <Filter onChangeFilter={setFiltered} />

                <PartiesTable filtered={filtered} handleEdit={handleEdit} />
              </>
            ) : (
              <PartyForm data4Edit={data4Edit} />
            )}
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Partidos;
