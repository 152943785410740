import React, { useState, useEffect } from 'react';

//MUI
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import SelectSearch from '../Selects/SelectSearch';

// Token
import { getVars } from '@utils/global';

//Components
import InputSelect from '@components/Selects/BasicSelect';
import CatalogServices from '@services/CatalogServices';
import Swal from 'sweetalert2';
import FilterCollapse from '@components/Filters/FilterCollapse';
import { TIPO_CANDIDATO, TIPO_GENERAL, TIPO_TERRITORIAL } from '../../data/constants';

const Filter = ({
  onChangeFilter,
  municipio = true,
  municipio_dl = false,
  poligono = true,
  seccion = true,
  manzana = true,
  checkRespon = false,
  checkResponsabilidades = false,
  responsable = false,
  vista = true,
  setActualVista = () => {},
}) => {
  const {
    user: { ambito_perfil },
  } = getVars('Token');
  const { user } = getVars('Token');
  //variables
  const defaultOption = { value: 0, label: 'TODOS' };
  //States
  const [, setCatRegions] = useState([]);
  const [catVista, setCatVista] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
  const [catPolygon, setCatPolygon] = useState([]);
  const [catPolygonFilter, setCatPolygonFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [catDL, setCatDL] = useState([]);
  const [catDF, setCatDF] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Vista: 0,
    DL: 0,
    DF: 0,
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
    Manzana: [],
    responsable: 0,
    Celular: '',
    NombreCompleto: '',
  });
  const [catResponsabilidades, setCatResponsabilidades] = useState([]);
  const [selectedManzana, setSelectedManzana] = useState([]);
  const [catManzanaFilter, setCatManzanaFilter] = useState([]);
  const [loadingBlocks, setLoadingBlocks] = useState(false);
  const [flagPoligono, setFlagPoligono] = useState(true);

  //Functions Api's

  useEffect(() => {
    if (ambito_perfil.find((item) => item.idTipo === TIPO_GENERAL || item.idTipo === 8)) {
      setCatVista(
        flagPoligono
          ? [
              { value: 1, label: 'Región' },
              { value: 5, label: 'Distrito Federal' },
              { value: 6, label: 'Distrito Local' },
              { value: 2, label: 'Municipio' },
              { value: 3, label: 'Polígono' },
              { value: 4, label: 'Sección' },
            ]
          : [
              { value: 5, label: 'Distrito Federal' },
              { value: 6, label: 'Distrito Local' },
              { value: 2, label: 'Municipio' },
              { value: 4, label: 'Sección' },
            ]
      );
    } else if (ambito_perfil.find((item) => [TIPO_TERRITORIAL, TIPO_CANDIDATO, 8, 9].includes(item.idTipo))) {
      let perfil = ambito_perfil.find((item) =>
        [TIPO_TERRITORIAL, TIPO_CANDIDATO, 8, 9].includes(item.idTipo)
      );

      switch (perfil.idAmbito) {
        case 2:
          setCatVista(
            flagPoligono
              ? [
                  { value: 1, label: 'Región' },
                  { value: 2, label: 'Municipio' },
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [
                  { value: 1, label: 'Región' },
                  { value: 2, label: 'Municipio' },
                  { value: 4, label: 'Sección' },
                ]
          );
          break;
        case 3:
          setCatVista(
            flagPoligono
              ? [
                  { value: 5, label: 'Distrito Federal' },
                  { value: 2, label: 'Municipio' },
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [
                  { value: 5, label: 'Distrito Federal' },
                  { value: 2, label: 'Municipio' },
                  { value: 4, label: 'Sección' },
                ]
          );
          break;
        case 4:
          setCatVista(
            flagPoligono
              ? [
                  { value: 6, label: 'Distrito Local' },
                  { value: 2, label: 'Municipio' },
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [
                  { value: 6, label: 'Distrito Local' },
                  { value: 2, label: 'Municipio' },
                  { value: 4, label: 'Sección' },
                ]
          );
          break;
        case 5:
          setCatVista(
            flagPoligono
              ? [
                  { value: 2, label: 'Municipio' },
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [
                  { value: 2, label: 'Municipio' },
                  { value: 4, label: 'Sección' },
                ]
          );
          break;
        case 6:
          setCatVista(
            flagPoligono
              ? [
                  { value: 2, label: 'Municipio' },
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [
                  { value: 2, label: 'Municipio' },
                  { value: 4, label: 'Sección' },
                ]
          );
          break;
        case 7:
          setCatVista(
            flagPoligono
              ? [
                  { value: 3, label: 'Polígono' },
                  { value: 4, label: 'Sección' },
                ]
              : [{ value: 4, label: 'Sección' }]
          );
          break;
        case 8:
          setCatVista(flagPoligono ? [{ value: 4, label: 'Sección' }] : [{ value: 4, label: 'Sección' }]);
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    const catalogsParams = [
      { id: 'dl', getAll: false },
      { id: 'df', getAll: false },
      { id: 'regiones', getAll: false },
      { id: 'municipios', getAll: false },
      { id: 'municipios_dl', getAll: false },
      { id: 'poligonos', getAll: false },
      { id: 'secciones', getAll: false },
      {
        id: 'perfiles_hijos',
        filtered: [{ id: 'perfiles.idTipo', filter: '=', value: 3 }],
        getAll: false,
      },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case 'dl':
                setCatDL([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'df':
                setCatDF([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'regiones':
                setCatRegions([defaultOption].concat(res.response.catalogs[item.id]));
                setCatRegionsFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;

              case 'municipios_dl':
                setCatMunicipality([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipalityFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'municipios':
                setCatMunicipio([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipioFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              case 'poligonos':
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;

              case 'secciones':
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);

              case 'perfiles_hijos':
                setCatResponsabilidades([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (catVista.length > 0) {
      setDataCatalog({ ...dataCatalog, Vista: catVista[0].value });
      setActualVista(catVista);
      searchFilter();
    }
  }, [catVista]);

  useEffect(() => {
    //console.log("dataCatalog",dataCatalog);
    let newFilter = dataCatalog;

    if (!municipio_dl || !municipio) {
      newFilter = { ...newFilter, Municipio: 0 };
      setCatMunicipioFilter(catMunicipio);
      setCatMunicipalityFilter(catMunicipality);
    }
    if (!poligono) {
      setSelectedPolygons([]);
      newFilter = { ...newFilter, Poligono: [] };
      setCatPolygonFilter(catPolygon);
    }
    if (!seccion) {
      setSelectedSections([]);
      newFilter = { ...newFilter, Seccion: [] };
      setCatSectionFilter(catSection);
    }
    if (!manzana) {
      setSelectedManzana([]);
      newFilter = { ...newFilter, Manzana: [] };
    }

    setDataCatalog(newFilter);

    onChangeFilter(newFilter);
  }, [municipio, municipio_dl, poligono, seccion, manzana]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(catMunicipality.filter((item) => item.idRegion === idRegion))
      );
      setCatMunicipioFilter(
        [defaultOption].concat(catMunicipality.filter((item) => item.idRegion === idRegion))
      );
      setCatPolygonFilter(catPolygon.filter((item) => item.idRegion === idRegion));
      setCatSectionFilter(catSection.filter((item) => item.idRegion === idRegion));
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeDF = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      DF: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    /*     if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatMunicipioFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idRegion)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idRegion)
      );
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    } */
  };

  const handleChangeDL = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      DL: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    /*     if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatMunicipioFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idRegion)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idRegion)
      );
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    } */
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(catPolygon.filter((item) => item.idMunicipio === idMunicipality));
      setCatSectionFilter(catSection.filter((item) => item.idMunicipio === idMunicipality));
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangeMunicipio = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(catPolygon.filter((item) => item.idMunicipioReportes === idMunicipality));
      setCatSectionFilter(catSection.filter((item) => item.idMunicipioReportes === idMunicipality));
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangePolygon = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Poligono: array_poligon,
    });

    if (array_poligon.length > 0) {
      setCatSectionFilter(catSection.filter((item) => array_poligon.some((id) => id === item.idPoligono)));
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    setDataCatalog({
      ...dataCatalog,
      Seccion: array_section,
    });
    loadUrbanBlocks(array_section);
  };

  const handleChangeManzana = (newValue) => {
    setSelectedManzana(newValue);
    const array_manzanas = newValue.map((option) => option.value || option);
    setDataCatalog({
      ...dataCatalog,
      Manzana: array_manzanas,
    });
  };

  const handleChangeResponsable = (newValue) => {
    const res = newValue.target.value;

    setDataCatalog({
      ...dataCatalog,
      responsable: res,
    });
  };

  const hadleChangeTextField = (e) => {
    setDataCatalog({
      ...dataCatalog,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      responsable: dataCatalog.responsable,
      Responsabilidad: dataCatalog.responsable,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Manzana: dataCatalog.Manzana,
      Celular: dataCatalog.Celular,
      NombreCompleto: dataCatalog.NombreCompleto,
      Vista: dataCatalog.Vista !== 0 ? dataCatalog.Vista : catVista[0].value,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      Municipio: 0,
      responsable: 0,
      Poligono: [],
      Seccion: [],
      Manzana: [],
      Celular: '',
      NombreCompleto: '',
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatPolygonFilter(catPolygon);
    setCatSectionFilter(catSection);

    setDataCatalog(newFilter);

    /* 
    setData({
      ...data,
      filtered: [],
    }); */
    onChangeFilter(newFilter);
  };

  const selectStyles = {
    select: (provided, state) => ({
      ...provided,
      height: '150px',
    }),
    menu: (provided, state) => ({
      ...provided,
      height: '150px',
    }),
  };

  const loadUrbanBlocks = (section) => {
    setLoadingBlocks(true);
    const catalogsParams = [
      {
        id: 'manzanas',
        filtered: [
          {
            id: 'cat_manzanas.Seccion',
            filter: 'IN',
            value: section,
          },
        ],
      },
    ];
    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatManzanaFilter(res.response.catalogs.manzanas ? res.response.catalogs.manzanas : []);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: 'warning',
        });
      })
      .finally(() => setLoadingBlocks(false));
  };

  return (
    <>
      <FilterCollapse>
        <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'} sx={{ position: 'relative' }}>
          {vista && (
            <Grid item xs={12}>
              <InputSelect
                label="Vista por: "
                options={catVista}
                name="vista"
                value={dataCatalog.Vista}
                onChange={(e) => {
                  setDataCatalog({
                    ...dataCatalog,
                    Vista: e.target.value,
                  });
                }}
                //disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {catVista.find((item) => item.value === 1) && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <InputSelect
                label="Región"
                options={catRegionsFilter}
                name="region"
                value={catRegionsFilter.length === 2 ? catRegionsFilter[1].value : dataCatalog.Region}
                onChange={handleChangeRegion}
                disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {catVista.find((item) => item.value === 5) && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <InputSelect
                label="Distrito Federal"
                options={catDF}
                name="DF"
                value={catDF.length === 2 ? catDF[1].value : dataCatalog.DF}
                onChange={handleChangeDF}
                disabled={catDF.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {catVista.find((item) => item.value === 6) && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <InputSelect
                label="Distrito Local"
                options={catDL}
                name="DL"
                value={catDL.length === 2 ? catDL[1].value : dataCatalog.DL}
                onChange={handleChangeDL}
                disabled={catDL.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {municipio && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <InputSelect
                label="Municipio"
                options={catMunicipioFilter}
                name="municipio"
                value={catMunicipioFilter.length === 2 ? catMunicipioFilter[1].value : dataCatalog.Municipio}
                onChange={handleChangeMunicipality}
                disabled={catMunicipio.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {municipio_dl && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                  catMunicipalityFilter.length === 2 ? catMunicipalityFilter[1].value : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipio}
                disabled={catMunicipality.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {catVista.find((item) => item.value === 3) && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : seccion ? 4 : 6}>
              <SelectSearch
                multiple={true}
                placeholder="Polígono"
                closeMenuOnSelect={false}
                value={selectedPolygons}
                onChange={handleChangePolygon}
                options={catPolygonFilter}
                isDisabled={catPolygonFilter.length === 1 ? true : false}
              />
            </Grid>
          )}
          {seccion && (
            <Grid item xs={12} md={3}>
              <SelectSearch
                multiple={true}
                placeholder="Sección"
                closeMenuOnSelect={false}
                value={catSectionFilter.length === 1 ? [catSectionFilter[0]] : selectedSections}
                onChange={handleChangeSection}
                options={catSectionFilter}
                isDisabled={catSectionFilter.length === 1 ? true : false}
              />
            </Grid>
          )}
          {manzana && (
            <Grid item xs={12} md={4}>
              <SelectSearch
                placeholder="Manzana"
                styles={selectStyles}
                closeMenuOnSelect={false}
                value={catManzanaFilter.length === 1 ? [catManzanaFilter[0]] : selectedManzana}
                onChange={handleChangeManzana}
                multiple={true}
                isLoading={loadingBlocks}
                options={catManzanaFilter}
                isDisabled={catManzanaFilter.length === 1 ? true : false}
              />
              {dataCatalog.Seccion.length < 1 && (
                <Typography color={'blue'} variant="caption">
                  Seleccione una sección
                </Typography>
              )}
            </Grid>
          )}
          {
            /* manzana */ false && (
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Prioridad"
                  options={[]}
                  name="Prioridad"
                  value={
                    catMunicipalityFilter.length === 2
                      ? catMunicipalityFilter[1].value
                      : dataCatalog.Prioridad
                  }
                  onChange={handleChangeMunicipality}
                  disabled={catMunicipality.length === 2 ? true : false}
                  sx={{ width: '100%' }}
                />
              </Grid>
            )
          }
          {
            /* manzana */ false && (
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Rentabilidad"
                  options={[]}
                  name="Rentabilidad"
                  value={
                    catMunicipalityFilter.length === 2
                      ? catMunicipalityFilter[1].value
                      : dataCatalog.Rentabilidad
                  }
                  onChange={handleChangeMunicipality}
                  disabled={catMunicipality.length === 2 ? true : false}
                  sx={{ width: '100%' }}
                />
              </Grid>
            )
          }
        </Grid>
        <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
          {checkResponsabilidades && (
            <Grid item xs={4} md={4}>
              <InputSelect
                label="Responsable"
                options={catResponsabilidades}
                name="Responsable"
                value={dataCatalog.responsable}
                onChange={handleChangeResponsable}
                disabled={false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {checkRespon && (
            <Grid item xs={4} md={4}>
              <InputSelect
                label="Responsable"
                options={[
                  { label: 'Todos', value: 0 },
                  { label: 'Sin Responsabilidad', value: 1 },
                  { label: 'Con Responsabilidad', value: 2 },
                ]}
                name="Responsable"
                value={dataCatalog.responsable}
                onChange={handleChangeResponsable}
                disabled={false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {responsable && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Nombre"
                  size="small"
                  name="NombreCompleto"
                  value={dataCatalog.NombreCompleto.toUpperCase()}
                  onChange={hadleChangeTextField}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Celular"
                  size="small"
                  name="Celular"
                  value={dataCatalog.Celular.toUpperCase()}
                  onChange={hadleChangeTextField}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primaryDark" onClick={searchFilter}>
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default Filter;
