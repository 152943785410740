import React, { useState, useEffect } from 'react';

//Mui
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Tooltip,
  Divider,
  Modal,
  Card,
} from '@mui/material';

//Icons
import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import { WhatsApp as WhatsAppIcon, Message as MessageIcon, Phone as PhoneIcon } from '@mui/icons-material';

import Swal from 'sweetalert2';

// Services
import movilizacion from '@services/MovilizationServices';

function UserSearchHistoryModal({ openModalS, handleCloseModalS, dataFieldValue }) {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getListResponsibles = async (params) => {
    if (!dataFieldValue) {
      return;
    }

    setIsLoading(true);
    try {
      const res = await movilizacion.getListResponsibles(params);
      const { results, message, response } = res;
      if (results) {
        setData(response.data);
        setFilteredData(response.data);
      } else {
        setFilteredData([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListResponsibles(dataFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFieldValue]);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredResults = data.filter((item) => item.NombreCompleto.toLowerCase().includes(keyword));
    setFilteredData(filteredResults);
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Modal
      open={openModalS}
      onClose={handleCloseModalS}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          width: 650,
          height: 650,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 3,
          overflowY: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={handleCloseModalS}
            size="small"
            sx={{
              '&:hover': {
                color: '#ff0000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <TextField
            label="Buscar por nombre"
            variant="outlined"
            onChange={handleSearch}
            fullWidth
            style={{ marginBottom: 30, marginTop: 4 }}
          />

          {isLoading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {filteredData.length === 0 && (
                <Box style={{ textAlign: 'center', marginTop: 20 }}>
                  <Typography variant="body1">¡No se encontraron resultados!</Typography>
                  <Icon style={{ fontSize: 40, color: 'black' }}>error</Icon>
                </Box>
              )}

              {filteredData.map((item, index) => (
                <>
                  <ListItem
                    sx={{ maxWidth: 400 }}
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Icon sx={{ fontSize: 40 }}>account_circle</Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      style={{ marginLeft: 10, minWidth: '70%' }}
                      primary={item.NombreCompleto}
                      secondary={item.ResponsabilidadEtiqueta}
                    />
                    <ListItemText
                      style={{
                        minWidth: '30%',
                        backgroundColor: item.PaseLista === 'No reportado' ? '#D64526' : '#4C9920',
                        borderRadius: '5px',
                        padding: '4px',
                        textAlign: 'center',
                        marginLeft: 15,
                      }}
                      secondary={
                        <Typography
                          style={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                        >
                          {item.PaseLista}
                        </Typography>
                      }
                    />
                    {item.Telefono && (
                      <Tooltip title={`Teléfono: ${item.Telefono}`} arrow>
                        <IconButton
                          edge="end"
                          aria-label="teléfono"
                          sx={{
                            '&:hover': {
                              color: 'rgb(0, 65, 160, .9)',
                            },
                            marginLeft: 2,
                          }}
                        >
                          <PhoneIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.Celular && (
                      <Tooltip title={`WhatsApp: ${item.Celular}`} arrow>
                        <IconButton
                          edge="end"
                          aria-label="whatsapp"
                          onClick={() => redirectToWhatsApp(item.Celular)}
                          sx={{
                            '&:hover': {
                              color: 'rgb(0, 65, 160, .9)',
                            },
                            marginLeft: 2,
                          }}
                        >
                          <WhatsAppIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.TelMensajes && (
                      <Tooltip title={`Mensajes: ${item.TelMensajes}`} arrow>
                        <IconButton
                          edge="end"
                          aria-label="mensajes"
                          onClick={() => redirectToWhatsApp(item.TelMensajes)}
                          sx={{
                            '&:hover': {
                              color: 'rgb(0, 65, 160, .9)',
                            },
                            marginLeft: 2,
                          }}
                        >
                          <MessageIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListItem>
                  <Divider />
                </>
              ))}
            </>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export default UserSearchHistoryModal;
