import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const MyDatePicker = ({
	label,
	requerido,
	helperText,
	value,
	handleChange,
}) => {
	// Función para validar si un valor es null o undefined
	const isNullOrUndefined = (value) => value === null || value === undefined;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
			<DatePicker
				label={label}
				onChange={(e) => {
					handleChange(isNullOrUndefined(e) ? "" : e);
				}}
				error={requerido && isNullOrUndefined(value)}
				value={value}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						variant="outlined"
						sx={{ width: "100%" }}
						error={requerido && isNullOrUndefined(value)}
						helperText={requerido && isNullOrUndefined(value) ? helperText : ""}
						InputProps={{
							...params.InputProps,
							// Agregamos el botón de borrar
							endAdornment: (
								<>
									{params.InputProps.endAdornment}
									{value && (
										<IconButton
											onClick={() => {
												handleChange(null);
											}}
										>
											<ClearIcon />
										</IconButton>
									)}
								</>
							),
						}}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default MyDatePicker;
