import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Collapse,
  Icon,
  Container,
} from '@mui/material';
import UserUpdate from './UsersUpdate';
import UserStatus from './UserStatus';
import { UserInfoInterface } from '@data/interfaces/UserInterfaces';

const UserSecurity = (props) => {
  const { data, access } = props;
  const [openPass, setOpenPass] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [userInfo, setUserInfo] = useState(UserInfoInterface);
  const [perfil, setPerfil] = useState(false);

  useEffect(() => {
    setUserInfo(data);
    setPerfil(true);
  }, [data]);

  return (
    <Box>
      <Container>
        <List>
          {access !== 'profile' && (
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Typography variant="h6">
                    {userInfo.Nombre} {userInfo.Paterno} {userInfo.Materno}
                  </Typography>
                }
              />
            </ListItem>
          )}
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start" onClick={() => setOpenPass(!openPass)}></ListItem>

          <UserUpdate idUsuario={userInfo.id} perfil={perfil} />

          <Divider variant="inset" component="li" />
          {access === 'admin' && (
            <>
              <ListItem alignItems="flex-start" onClick={() => setOpenStatus(!openStatus)}>
                <ListItemAvatar>
                  <ListItemAvatar>
                    <Icon>block</Icon>
                  </ListItemAvatar>
                </ListItemAvatar>
                <ListItemText primary="Modificar Estatus" secondary={''} />
              </ListItem>
              <Collapse in={openStatus} timeout="auto" unmountOnExit>
                <UserStatus idUsuario={userInfo.id} />
              </Collapse>
              <Divider variant="inset" component="li" />
            </>
          )}
        </List>
      </Container>
    </Box>
  );
};

export default UserSecurity;

/* */
