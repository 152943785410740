// Material UI
import { Box, Typography, Button, Link } from '@mui/material';

// Utilidades
import { setVars } from '@utils/global';

// Assets
import Back from '@assets/img/Back.jpg';

const NotAuthorized = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        padding: { xs: '24px 20px' },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${Back})`,
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="h2"
          fontSize={{ xs: 140, lg: 200 }}
          fontWeight={400}
          lineHeight={0.6}
          style={{ color: '#576169' }}
        >
          401
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: { xs: 'center', lg: 'start' },
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          variant="h4"
          align={undefined}
          fontSize={{ xs: 28, sm: 28, lg: 36 }}
          lineHeight={1.2}
          sx={{
            color: '#576169',
            maxWidth: { xs: '380px', lg: '490px' },
            textAlign: { xs: 'center', lg: 'left' },
          }}
        >
          No tiene permisos de acceso o su sesión caducó
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          href="/"
          onClick={() => setVars('sideBar', '')}
          sx={{ textDecoration: 'none' }}
        >
          IR AL INICIO
        </Button>
      </Box>
    </Box>
  );
};

export default NotAuthorized;
