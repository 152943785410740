import { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { Container, CardContent, Chip, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BasicTable from '@components/Tables/BasicTable';
import Card from '@components/Card/Card';
import Filter from '@components/Territorial/Filter';
import CardCommitments from '@components/Card/CardStats';
import PlacementColumns from '@components/Charts/DashChart';
import Grid from '@mui/material/Unstable_Grid2';

import { getVars } from '@utils/global';

import Swal from 'sweetalert2';

import moment from 'moment';

// icons
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MovingIcon from '@mui/icons-material/Moving';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

//validationes y utilities
import { EJERCICIO } from '@data/constants';
import { isEmptyOrNullObject } from '@utils/validations';
import { numberWithCommas } from '@utils/Utilities';

//servicios
import TerritorialServices from '../../services/TerritorialServices';
import { isNull } from 'lodash';

const ProgressRegion = (props) => {
  const navigate = useNavigate();
  const { user } = getVars('Token');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCharts, setLoadingChart] = useState(false);
  let filterFijo = { id: 'Ejercicio', value: EJERCICIO, filter: '=' };
  const [chartCompromisos, setChartCompromisos] = useState({
    type: 10,
    filtered: [],
  });
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [filterFijo],
  });
  /*   const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false); */
  const [sortedData, setSortedData] = useState([]);
  const [dataTotales, setDataTotales] = useState({
    MetaCompromisos: {
      FechaActualizo: '2023-01-11 16:00:19',
      Meta: 0,
      TotalAvance: 0,
    },
    regional: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    municipales: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    distritales: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    poligonal: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    seccionales: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
  });
  const [dataChart, setDataChart] = useState({
    title: '',
    date: '',
    categories: [],
    series1: [],
    series2: [],
  });
  const [flagCards, setFlagCard] = useState([]);
  const [columns, setColumns] = useState([
    { id: 'Region', label: 'Región' },
    { id: 'Lnom', label: 'LNOM', align: 'center' },
    { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
    { id: 'AvanceCompromisosUnicos', label: 'Av.Compromisos', align: 'right' },
    { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
  ]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const getList = () => {
    TerritorialServices.getProgressCompromisos(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                Lnom: item.Lnom ? numberWithCommas(item.Lnom) : '0',
                MetaCompromisos: item.MetaCompromisos ? numberWithCommas(item.MetaCompromisos) : '0',
                AvEsperado: item.AvEsperado ? item.AvEsperado : '0',
                AvanceCompromisos: item.AvanceCompromisos ? numberWithCommas(item.AvanceCompromisos) : '0',
                AvanceCompromisosUnicos: item.AvanceCompromisosUnicos
                  ? numberWithCommas(item.AvanceCompromisosUnicos)
                  : '0',
                MetaEstructura: item.MetaEstructura ? numberWithCommas(item.MetaEstructura) : '0',
                AvanceEstructura: item.AvanceEstructura ? numberWithCommas(item.AvanceEstructura) : '0',
                PorcentajeEstructura: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeEstructura) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeEstructura) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeEstructura) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeEstructura > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeEstructura}
                  />
                ),
                PorcentajeCompromisos: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisos) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisos) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisos) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisos > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisos}
                  />
                ),
                PorcentajeCompromisosUnico: (
                  <Chip
                    variant="outlined"
                    color={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100
                        ? 'success'
                        : parseInt(item.PorcentajeCompromisosUnico) > 50
                          ? 'warning'
                          : 'error'
                    }
                    icon={
                      parseInt(item.PorcentajeCompromisosUnico) >= 100 ? (
                        <TaskAltIcon />
                      ) : item.PorcentajeCompromisosUnico > 50 ? (
                        <MovingIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={item.PorcentajeCompromisosUnico}
                  />
                ),
              };
            })
          );
          let series2 = [];
          let series = [];
          let categories = [];
          let fecha = '';
          switch (tableData.GroupBy) {
            case 1:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.Region);
                fecha = element.FechaActualizo;
              });
              break;
            case 2:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.Municipio);
                fecha = element.FechaActualizo;
              });
              break;
            case 3:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.Poligono);
                fecha = element.FechaActualizo;
              });
              break;
            case 4:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.Seccion);
                fecha = element.FechaActualizo;
              });
              break;
            case 5:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.DF);
                fecha = element.FechaActualizo;
              });
              break;
            case 6:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.DL);
                fecha = element.FechaActualizo;
              });
              break;
            case 7:
              res.response.dataStas.forEach((element) => {
                series.push(parseInt(element.MetaCompromisos));
                series2.push(parseInt(element.AvanceCompromisosUnicos));
                categories.push(element.MunicipioDL);
                fecha = element.FechaActualizo;
              });
              break;
            default:
              break;
          }
          setDataChart({
            ...dataChart,
            date: fecha,
            series1: series,
            series2: series2,
            categories: categories,
          });
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const getTotalCompromiso = () => {
    TerritorialServices.getStatsCompromisos(chartCompromisos)
      .then((res) => {
        if (res.results) {
          setDataTotales(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    let column = [];
    switch (tableData.GroupBy) {
      case 1:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 2:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'Municipio', label: 'Municipio' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 3:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'Municipio', label: 'Municipio' },
          { id: 'Poligono', label: 'Polígono' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 4:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'Municipio', label: 'Municipio' },
          { id: 'Poligono', label: 'Polígono' },
          { id: 'Seccion', label: 'Sección' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 5:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 6:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      case 7:
        column = [
          flagCards.includes(1) && { id: 'Region', label: 'Región' },
          flagCards.includes(5) && { id: 'DF', label: 'Distrito Federal' },
          flagCards.includes(6) && { id: 'DL', label: 'Distrito Local' },
          { id: 'MunicipioDL', label: 'Municipio' },
          { id: 'Lnom', label: 'LNOM', width: 35, align: 'center' },
          { id: 'MetaCompromisos', label: 'Meta Compromisos', align: 'right' },
          {
            id: 'AvanceCompromisosUnicos',
            label: 'Av.Compromisos',
            align: 'right',
          },
          { id: 'PorcentajeCompromisosUnico', label: '(%) Av.Compromisos' },
        ];
        setColumns(column.filter((item) => item));
        break;
      default:
        break;
    }
    setLoading(true);
    setLoadingChart(true);
    getList();
    getTotalCompromiso();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Region: 'cat_region.Clave',
      Municipio: 'cat_municipios.Municipio',
      Poligono: 'cat_poligonos.Poligono',
      Seccion: 'cat_secciones.Seccion',
      DF: 'cat_df.DistritoFederal',
      DL: 'cat_dl.DistritoLocal',
      Lnom: 'cat_secciones.Lnom',
      MetaCompromisos: 'estad_secciones.MetaCompromisos',
      AvanceCompromisos: 'estad_secciones.AvanceCompromisos',
      PorcentajeCompromisos:
        'IF(TRUNCATE(((estad_secciones.AvanceCompromisos * 100)/estad_secciones.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_secciones.AvanceCompromisos * 100)/estad_secciones.MetaCompromisos),2))',
      AvanceCompromisosUnicos: 'estad_secciones.AvanceCompromisosUnicos',
      PorcentajeCompromisosUnico:
        'IF(TRUNCATE(((estad_secciones.AvanceCompromisosUnicos * 100)/estad_secciones.MetaCompromisos),2)>=100,100,TRUNCATE(((estad_secciones.AvanceCompromisosUnicos * 100)/estad_secciones.MetaCompromisos),2))',
      MetaEstructura: 'estad_secciones.MetaEstructura',
      AvanceEstructura: 'estad_secciones.AvanceEstructura',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleChangeFilter = (type) => {
    const { DF, DL, Region, Municipio, Vista, responsable, Poligono, Seccion, Celular, NombreCompleto } =
      type;

    let filtered = [filterFijo];
    let filtro = [];

    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filtro = [...filtro, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (DL > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDL', filter: '=', value: DL }];

      filtro = [...filtro, { id: 'cat_secciones.idDL', filter: '=', value: DL }];
    }
    if (DF > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDL', filter: '=', value: DF }];

      filtro = [...filtro, { id: 'cat_secciones.idDL', filter: '=', value: DF }];
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: Municipio,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: Municipio,
        },
      ];
    }
    if (Poligono.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];

      filtro = [...filtro, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];
    }
    if (Seccion.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: 'IN', value: Seccion }];

      filtro = [...filtro, { id: 'cat_secciones.Seccion', filter: 'IN', value: Seccion }];
    }
    if (responsable > 0) {
      filtered = [
        ...filtered,
        responsable === 1
          ? { id: 'estad_secciones.AvanceEstructura', filter: '=', value: '0' }
          : { id: 'estad_secciones.AvanceEstructura', filter: '>', value: '0' },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios.Celular',
          filter: 'LIKE',
          value: Celular,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'],
          filter: 'LIKE',
          value: NombreCompleto,
        },
      ];
    }

    if (Vista > 0 || !isNull(Vista)) {
      setTableData({
        ...tableData,
        page: 0,
        filtered: filtered,
        GroupBy: Vista,
      });
    } else {
      setTableData({
        ...tableData,
        page: 0,
        filtered: filtered,
      });
    }

    setChartCompromisos({ type: 13, filtered: filtro });
  };

  const handleValidation = (e) => {
    setFlagCard(e.map((item) => item.value));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Avance Compromisos" handleclick={() => navigate('/compromisos_region')} />
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <CardCommitments
              icon={'groups'}
              title={'Compromisos'}
              total={`${numberWithCommas(
                dataTotales.MetaCompromisos.TotalAvance
              )} de ${numberWithCommas(dataTotales.MetaCompromisos.Meta)}`}
              subIcon={'update'}
              subtitle={moment(dataTotales.MetaCompromisos.FechaActualizo).format('DD/MM/YYYY')}
              loading={loadingCharts}
            />
          </Grid>
          {flagCards.includes(1) && (
            <Grid xs={12} md={6} lg={2}>
              <CardCommitments
                icon={'groups'}
                title={'Región'}
                total={`${numberWithCommas(dataTotales.Totales?.Region ? dataTotales.Totales?.Region : 0)}`}
                loading={loadingCharts}
              />
            </Grid>
          )}
          {flagCards.includes(2) && (
            <Grid xs={12} md={6} lg={2}>
              <CardCommitments
                icon={'groups'}
                title={'Municipio'}
                total={`${numberWithCommas(
                  dataTotales.Totales?.Municipio ? dataTotales.Totales?.Municipio : 0
                )}`}
                loading={loadingCharts}
              />
            </Grid>
          )}
          {flagCards.includes(3) && [3].includes(user.idEstado) && (
            <Grid xs={12} md={6} lg={2}>
              <CardCommitments
                icon={'groups'}
                title={'Polígono'}
                total={`${numberWithCommas(
                  dataTotales.Totales?.Poligono ? dataTotales.Totales?.Poligono : 0
                )}`}
                loading={loadingCharts}
              />
            </Grid>
          )}
          <Grid xs={12} md={6} lg={2}>
            <CardCommitments
              icon={'groups'}
              title={'Sección'}
              total={`${numberWithCommas(dataTotales.Totales?.Seccion ? dataTotales.Totales?.Seccion : 0)}`}
              loading={loadingCharts}
            />
          </Grid>
        </Grid>
        <Card className="card-primary">
          <CardContent>
            {loading ? (
              <CircularProgress />
            ) : (
              <PlacementColumns
                titulo={{ title: 'Avance Compromisos' }}
                subtitle={{
                  subtitle: moment(dataChart.date).format('DD/MM/YYYY H:mm'),
                }}
                datos={{
                  categories: dataChart.categories,
                  series: [
                    {
                      name: 'Meta',
                      color: 'rgba(0,65,165,1)',
                      data: dataChart.series1,
                      pointPadding: 0.3,
                      pointPlacement: -0.2,
                    },
                    {
                      name: 'Avance',
                      color: 'rgba(147,220,236,.9)',
                      data: dataChart.series2,
                      pointPadding: 0.4,
                      pointPlacement: -0.2,
                    },
                  ],
                }}
                loading={loading}
              />
            )}
          </CardContent>
        </Card>
        <Card className="card-primary">
          <CardContent>
            <Filter
              onChangeFilter={handleChangeFilter}
              manzana={false}
              responsable={false}
              checkRespon={false}
              municipio={false}
              municipio_dl={true}
              setActualVista={(e) => handleValidation(e)}
            />
          </CardContent>
        </Card>
        <Card className="card-primary">
          <CardContent>
            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tableData.page}
              pageSize={tableData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default ProgressRegion;
