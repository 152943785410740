import { useState, useEffect } from 'react';
//MUI
import { Container, Card, Box, Button, Typography, Grid, Icon } from '@mui/material';
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import Swal from 'sweetalert2';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import ProgressIndicator from '@components/Activation/EstadisticasFolio/ProgressIndicator';
import ProgressChart from '@components/Activation/EstadisticasFolio/ProgressChart';
import ChartDetailTable from '@components/Activation/EstadisticasFolio/ChartDetailTable';
import CurrentProgressChart from '@components/Activation/EstadisticasFolio/CurrentProgressChart';
import ComulativeProgressChart from '@components/Activation/EstadisticasFolio/CumulativeProgressChart';
import Filter from '@components/Activation/EstadisticasFolio/Filter';
import DrawerRelative from '@components/Containers/DrawerRelative';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';

import middleware from '@middlewares/middleware';

import BasicSelect from '@components/Selects/BasicSelect';

//Service
import MovilizationServices from '@services/MovilizationServices';

const EstadisticasBingo = () => {
  const [isLoadigForm, setIsLoadingForm] = useState(false);

  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [catView, setCatView] = useState([]);
  const [agrupador, setAgrupador] = useState(4);
  const [agrupadorTable, setAgrupadorTable] = useState(4);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [idFilter, setIdFilter] = useState(agrupador);
  const [isDownloading, setIsDownloading] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [filtered, setFiltered] = useState({
    filtered: [],
    filtered2: [],
    ambitoAgrupador: '',
  });

  const handleClearFilter = () => {
    setAgrupador(4);
    setAgrupadorTable(4);
    setFiltered({
      filtered: [],
      filtered2: [],
      ambitoAgrupador: 4,
    });
  };

  const changeTypeViewFilter = (view) => {
    setAgrupador(view);
    setAgrupadorTable(view);
  };

  const handleSearchFilter = ({ filtereds, filtered2, vista }) => {
    setAgrupador(vista);
    setAgrupadorTable(vista);
    setFiltered({ filtered: filtereds, filtered2: filtered2, ambitoAgrupador: agrupador });
  };

  const handleExport = () => {
    setIsDownloading(true);
    MovilizationServices.exportProgressListDetails({
      filtered: filtered.filtered,
      idAmbitoAgrupador: agrupadorTable,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const getCatView = async () => {
    try {
      const res = await MovilizationServices.getAmbitoAgrupadorMovilizacion();
      const { results, response, message } = res;
      if (results) {
        const data = response.data.data
          .filter((item) => item.value !== 7 && item.value !== 8)
          .sort((a, b) => a.value - b.value);
        setCatView(data);
        setFiltered({ ...filtered, ambitoAgrupador: data[2]?.value });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    getCatView();
    //eslint-disable-next-line
  }, []);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'Estadísticas de Asignación Folios Bingos'} isCustom={true}>
          <Button
            onClick={() => setOpenDrawer(!openDrawer)}
            aria-label="open Drawer"
            endIcon={openDrawer ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
          >
            <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
          </Button>
        </ActionHeader>
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />
        <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid item xs={12} md={openDrawer ? 8 : 12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <ProgressIndicator
                  openDrawer={openDrawer}
                  filtered={filtered}
                  setLoading={(e) => {
                    setIsLoadingForm(e);
                    setOpenLinearProgress(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid
                  component={Card}
                  variant="outlined"
                  borderRadius={2}
                  padding={2}
                  minHeight={300}
                  elevation={0}
                  sx={{ height: '100%' }}
                >
                  <Box mb={'2vh'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <BasicSelect
                      name="agrupador"
                      label="Agrupar por"
                      options={catView}
                      value={agrupador}
                      onChange={(e) => {
                        setAgrupador(e.target.value);
                      }}
                      sx={{ width: '300px' }}
                    />
                  </Box>
                  <ProgressChart
                    openDrawer={openDrawer}
                    agrupador={agrupador}
                    filtered={filtered}
                    setLoading={(e) => {
                      setIsLoadingForm(e);
                      setOpenLinearProgress(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={'2vh'}>
              <Box component={Card} variant="outlined" borderRadius={2} padding={2} elevation={0}>
                <Box mb={'2vh'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <BasicSelect
                    name="agrupador"
                    label="Agrupar por"
                    options={catView}
                    value={agrupadorTable}
                    onChange={(e) => {
                      setAgrupadorTable(e.target.value);
                    }}
                    sx={{ width: '300px' }}
                  />
                  {true /* middleware.checkMenuActionId("Exportar") */ && (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <Button
                          variant="outlined"
                          color="primaryDark"
                          startIcon={<Icon>download</Icon>}
                          size="small"
                          onClick={handleExport}
                          sx={{ borderRadius: '10px' }}
                        >
                          Exportar
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
                {isDownloading && <Download isDownload={isDownloading} format="xlsx" />}
                {showMessage && (
                  <Box marginTop={2}>
                    <MessageAlert
                      showMessage={showMessage}
                      successDownload={successDownload}
                      setShowMessage={setShowMessage}
                    />
                  </Box>
                )}
                <ChartDetailTable
                  agrupador={agrupadorTable}
                  filtered={filtered}
                  setLoading={(e) => {
                    setIsLoadingForm(e);
                    setOpenLinearProgress(e);
                  }}
                />
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} mt={'2vh'}>
                <Box
                  component={Card}
                  variant="outlined"
                  borderRadius={2}
                  padding={2}
                  minHeight={480}
                  elevation={0}
                >
                  <CurrentProgressChart
                    openDrawer={openDrawer}
                    filtered={filtered}
                    setLoading={(e) => {
                      setIsLoadingForm(e);
                      setOpenLinearProgress(e);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mt={'2vh'}>
                <Box
                  component={Card}
                  variant="outlined"
                  borderRadius={2}
                  padding={2}
                  minHeight={480}
                  elevation={0}
                >
                  <ComulativeProgressChart
                    openDrawer={openDrawer}
                    filtered={filtered}
                    setLoading={(e) => {
                      setIsLoadingForm(e);
                      setOpenLinearProgress(e);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} spacing={2}>
            <DrawerRelative
              anchor="right"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={'Filtrar'}
              icon="person_search"
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <Filter
                onSearch={handleSearchFilter}
                onClear={handleClearFilter}
                changeTypeView={changeTypeViewFilter}
                //refresh={refresh}
                setLoading={() => {}}
                catView={catView}
                setIdFilter={setIdFilter}
                ambitoUser={agrupador}
              />
            </DrawerRelative>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};

export default EstadisticasBingo;
