import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Box, CircularProgress } from "@mui/material";

import OficialiaServices from "@services/OficialiaServices"; // Importar el servicio

const DirectorioEmpresasFiltroAutocomplete = ({ value, setValue }) => {
	const [data, setData] = useState([]); // Estado para seleccionar empresa padre
	const [loading, setLoading] = useState(false); // Estado para manejar el loading al guardar
	const [errors, setErrors] = useState("");

	useEffect(() => {
		const fetchEmpresas = async () => {
			setLoading(true);
			try {
				const results = await OficialiaServices.getDirectorioRecursivo({
					page: 0,
					pageSize: 400,
					filtered: [],
					sorted: [{ id: "tb.persona", value: "ASC" }],
				});

				console.log("=>results: ", results);

				if (results.success) {
					// Si la respuesta es exitosa, actualizar el estado con los datos
					setData(results.response.data);
				} else {
					// Manejar el caso en que no se puedan obtener los datos
					setErrors(results.message || "Error al obtener directorio");
				}
				setLoading(false);
			} catch (error) {
				console.error("Error al obtener directorio:", error);
				setErrors("Error al cargar directorio");
			}
		};

		fetchEmpresas();
	}, []);

	return (
		<Box>
			{loading ? (
				<CircularProgress
					variant={loading ? "indeterminate" : "determinate"}
					sx={{
						color: (theme) =>
							theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
					}}
					size={40}
					thickness={4}
					value={100}
				/>
			) : (
				<Autocomplete
					value={value.DeQuien || null}
					onChange={(event, newValue) => {
						setValue({ ...value, DeQuien: newValue });
					}}
					onInputChange={(event, newInputValue) => {
						// Si el valor del input es vacío, eliminamos la prioridad del estado
						if (newInputValue === "") {
							setValue({ ...value, DeQuien: null });
						}
					}}
					options={data}
					getOptionLabel={(option) =>
						`${option.persona} ${option.Cargo} ${option.jerarquia_empresa}` ||
						""
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label="De quien"
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
					fullWidth
				/>
			)}
		</Box>
	);
};

export default DirectorioEmpresasFiltroAutocomplete;
