import { useState, useEffect } from 'react';

// Material UI
import {
  Box,
  Typography,
  LinearProgress,
  Chip,
  // Tooltip,
  CircularProgress,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Flag } from '@mui/icons-material';
import Swal from 'sweetalert2';

// Utilidades
import { numberWithCommas } from '@utils/Utilities/';

// Servicios
import MetasServices from '@services/MetasServices';

const BarraCarga = ({ filter, filtered }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await MetasServices.getGoalsPercentageProgress(filtered);
      const { results, message, response } = result;

      if (results) {
        setData(response.data[0]);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: 'warning',
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [filter]);

  return (
    <Box>
      <Chip icon={<Flag />} label="Avance de Meta" sx={{ backgroundColor: 'transparent', fontSize: 16 }} />
      {loading ? (
        <Grid2 container xs={12} height={100} alignItems="center" justifyContent="center">
          <CircularProgress size={50} />
        </Grid2>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: { xs: 1, sm: 2 },
              mt: 1,
            }}
          >
            <Box>
              <Typography variant="body1" align="right">
                Inicio
              </Typography>
              <Typography variant="body2" mb={1}>
                0
              </Typography>
            </Box>
            <Box>
              {/* <Tooltip title={`Avance: ${data.PorcentajeAvance}%`} arrow placement="top"> */}
              <Typography variant="body1" align="center">
                Avance
              </Typography>
              <Typography variant="body2" mb={1} align="center">
                {`${numberWithCommas(data.Avance)} (${data.PorcentajeAvance}%)`}
              </Typography>{' '}
              {/* </Tooltip> */}
            </Box>
            <Box>
              <Typography variant="body1" align="right">
                Meta
              </Typography>
              <Typography variant="body2" mb={1} align="right">
                {numberWithCommas(data.Total)}
              </Typography>
            </Box>
          </Box>
          {/* <Tooltip title={`Avance: ${data.PorcentajeAvance}%`} arrow> */}
          <LinearProgress
            variant="determinate"
            value={data.PorcentajeAvance}
            sx={{
              height: '10px',
              borderRadius: '5px',
              backgroundImage: `linear-gradient(45deg, transparent 25%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1))`,
              backgroundSize: '20px 20px',
              py: 1,
            }}
          />
          {/* </Tooltip> */}
        </>
      )}
    </Box>
  );
};

export default BarraCarga;
