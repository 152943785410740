import { useEffect, useState } from 'react';
import { ACTIONS_SPEED, COLUMNS_OPERATION } from '@data/constants/Sije';
import { useSorted } from '@hooks/useSorted';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '@hooks/useWindowDimensions';
import moment from 'moment';
import Swal from 'sweetalert2';
import sije from '@services/SijeService';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Typography,
} from '@mui/material';
import { blue, green, indigo, pink } from '@mui/material/colors';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import MinutesCard from '@components/Sije/OperationCasilla/MinutesCard';
import IncidentsCard from '@components/Sije/OperationCasilla/IncidentsCard';
import CasillaCard from '@components/Sije/OperationCasilla/CasillaCard';
import PlacementColumns from '@components/Charts/DashChart';
import BasicLine from '@components/Charts/BasicLine';
import FilterSije from '@components/Sije/OperationCasilla/Filter';
import CardLayout from '@components/Sije/OperationCasilla/CardLayout';
import Download from '@components/Downloads/Download';
import ModalPreviewImages from '@components/Sije/OperationCasilla/ModalPreviewImages';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import LastUpdatedCard from '@components/Activation/DashboardElectoral/LastUpdatedCard';
import CustomTable from '@components/Tables/CustomTable';
import DrawerRelative from '@components/Containers/DrawerRelative';
import FloatingButton from '@components/Button/FloatingButton';

import middleware from '@middlewares/middleware';

const OperationCasilla = () => {
  const { height } = useWindowDimensions();

  //STATES
  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [total, setTotal] = useState(10);
  const [filteredChart, setFilteredChart] = useState({ filtered: [] });
  const [filteredMinutes, setFilteredMinutes] = useState({ filtered: [] });
  const [totalIncidencias, setTotalIncidencias] = useState([]);
  const [totalCasillas, setTotalCasillas] = useState([]);
  const [dataOperation, setDataOperation] = useState([]);
  const [isDownload, setIsDownloading] = useState(false);
  const [infoGraficasRCs, setInfoGraficasRCs] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasRGs, setInfoGraficasRGs] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficas, setInfoGraficas] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasCierre, setInfoGraficasCierre] = useState({
    series: [],
    categories: [],
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [preview, setOpenPreview] = useState(false);
  const [dataImage, setDataImage] = useState([]);
  const [loadingDataImage, setLoadingDataImage] = useState(false);
  const [loadingForm, setLoadingForm] = useState({
    isLoading: false,
    isOpen: false,
    isSuccess: false,
    loadingMessage: 'Cargando...',
    successMessage: '¡Consultado con éxito!',
  });
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });
  const navigate = useNavigate();

  const getListSije = async (data) => {
    try {
      setLoadingData((prevState) => !prevState);
      const result = await sije.getListSije(data);
      const { response, results, message } = await result;
      if (results) {
        const data = response.data.map((item) => {
          return {
            ...item,
            tieneFoto: item.tieneFoto ? (
              <IconButton color="primaryDark" onClick={() => getListImage({ idCasilla: item.idCasilla })}>
                <Icon>photo_camera</Icon>
              </IconButton>
            ) : null,
            Conectividad: (
              <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                <Icon fontSize="small" color={item.ConectividadInternet ? 'primary' : 'disabled'}>
                  wifi
                </Icon>
                <Icon fontSize="small" color={item.ConectividadTelefono ? 'primary' : 'disabled'}>
                  phone_iphone
                </Icon>
              </Stack>
            ),
            TipoTransmision: (
              <Box>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={item.TipoTransmision.total}
                  direction="right"
                  sx={{
                    '& .MuiFab-primary': { width: 32, height: 32 },
                  }}
                >
                  {ACTIONS_SPEED.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      sx={{
                        width: 32,
                        height: 32,
                        color: item.TipoTransmision[action.type] && blue[700],
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            ),
            TotalIncidencias: (
              <Box
                component={'a'}
                onClick={() => {
                  navigate('/incidencias', { state: item.idCasilla });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Badge badgeContent={item.TotalIncidencias} color={'warning'} />
              </Box>
            ),
          };
        });

        setDataOperation(data);
        setTotal(response.total);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingData((prevState) => !prevState);
    }
  };

  const getTotalesCards = async () => {
    try {
      const result = await sije.getTotalesCards({
        catalogs: [{ id: 'casillas' }, { id: 'incidencias' }],
        filtered: filteredChart.filtered,
      });
      const { response, message, results } = await result;
      if (results) {
        setTotalIncidencias(() => {
          return {
            Favorables: response.data.incidencias[0].value,
            Competidas: response.data.incidencias[1].value,
            Adversas: response.data.incidencias[2].value,
          };
        });

        setTotalCasillas(() => {
          return {
            CasillasAprobadas: parseInt(
              response.data.casillas[0].CasillasAprobadas !== null
                ? response.data.casillas[0].CasillasAprobadas
                : 0
            ),
            CasillasInstaladas: parseInt(
              response.data.casillas[0].CasillasInstaladas !== null
                ? response.data.casillas[0].CasillasInstaladas
                : 0
            ),
            CasillasAperturadas: parseInt(
              response.data.casillas[0].CasillasAperturadas !== null
                ? response.data.casillas[0].CasillasAperturadas
                : 0
            ),
            CasillasCerradas: parseInt(
              response.data.casillas[0].CasillasCerradas !== null
                ? response.data.casillas[0].CasillasCerradas
                : 0
            ),
            VotacionCerrada: parseInt(
              response.data.casillas[0].VotacionCerrada !== null
                ? response.data.casillas[0].VotacionCerrada
                : 0
            ),
          };
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  };

  const getChartPaseListaRcs = async () => {
    try {
      const result = await sije.getChartPaseListaRcs(filteredChart);
      const { response, message, results } = await result;
      if (results) {
        setInfoGraficasRCs({
          categories: response.categories,
          series: response.series2.map((item) => parseInt(item)),
          Meta: response.series1.map((item) => parseInt(item)),
          Actualizado: response.date,
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  };

  const getChartPaseListaRG = async () => {
    try {
      const result = await sije.getChartPaseListaRG(filteredChart);
      const { response, results, message } = result;
      if (results) {
        setInfoGraficasRGs({
          categories: response.categories,
          Actualizado: response.date,
          series: response.Avance,
          Meta: response.Meta,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    }
  };

  const getChartAperturaCierreSije = async () => {
    try {
      const result = await sije.getChartAperturaCierreSije(filteredChart);
      const { response, message, results } = result;
      if (results) {
        setInfoGraficas({
          categories: response.Apertura.categories,
          series: [
            {
              name: 'Instalación',
              data: response.Apertura.Instalacion.map((item) => parseInt(item)),
              color: green[100],
            },
            {
              name: 'Apertura',
              data: response.Apertura.Apertura.map((item) => parseInt(item)),
              color: green[800],
            },
          ],
          Actualizado: response.date,
        });

        setInfoGraficasCierre({
          categories: response.Cierre.categories,
          series: [
            {
              name: 'Cierre Casilla',
              data: response.Cierre.Casilla.map((item) => parseInt(item)),
              color: pink[100],
            },
            {
              name: 'Cierre Votación',
              data: response.Cierre.Votacion.map((item) => parseInt(item)),
              color: pink[800],
            },
          ],
          Actualizado: response.date,
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  };

  const getListImage = ({ idCasilla }) => {
    setOpenPreview(true);
    getPicturesListSije({ idCasilla });
  };

  const getPicturesListSije = async ({ idCasilla }) => {
    try {
      setLoadingDataImage(true);
      const result = await sije.getPicturesListSije({
        filtered: [
          {
            id: 'sije_casillas_fotos.idCasilla',
            filter: '=',
            value: idCasilla,
          },
        ],
      });
      const { results, response, message } = result;
      if (results) {
        setDataImage(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoadingDataImage(false);
    }
  };

  const handleExport = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: { filtered: data.filtered },
        api: 'sije/export-list-sije',
      });
      const { success } = await result;

      if (success) {
        Swal.fire({
          title: 'Descargado con éxito.',
          icon: 'success',
        });
      } else {
        throw new Error('Ocurrió un error, contacte al administrador.');
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const onChangeFilter = ({ filtersChart, filtersData, filtersMinutes }) => {
    setFilteredChart((prevState) => ({
      ...prevState,
      filtered: filtersChart,
    }));
    setData((prevState) => ({
      ...prevState,
      page: 0,
      filtered: filtersData,
    }));
    setFilteredMinutes((prevState) => ({
      ...prevState,
      filtered: filtersMinutes,
    }));
  };

  const onClearFilter = () => {
    setFilteredChart((prevState) => ({
      ...prevState,
      filtered: [],
    }));
    setData((prevState) => ({ ...prevState, filtered: [] }));
    setFilteredMinutes((prevState) => ({ ...prevState, filtered: [] }));
  };

  const fetchData = async () => {
    try {
      setLoadingForm((prevState) => ({
        ...prevState,
        isLoading: true,
        isOpen: true,
        isSuccess: false,
      }));
      const apiPromises = [
        getTotalesCards(),
        getChartPaseListaRcs(),
        getChartAperturaCierreSije(),
        getChartPaseListaRG(),
      ];

      await Promise.all(apiPromises);
      setLoadingForm({
        ...loadingForm,
        isLoading: false,
        isOpen: false,
        isSuccess: true,
      });
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleRefresh = () => {
    setData((prev) => ({ ...prev, page: 0, pageSize: 10, sorted: [] }));
    setFilteredChart((prev) => ({ ...prev }));
    setFilteredMinutes((prev) => ({ ...prev }));
  };

  useEffect(() => {
    getListSije(data);
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    /* getTotalesCards();
    getChartPaseListaRcs();
    getChartAperturaCierreSije();
    getChartPaseListaRG(); */
    fetchData();
    //eslint-disable-next-line
  }, [filteredChart]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={loadingForm.loadingMessage}
        successMessage={loadingForm.successMessage}
        isLoading={loadingForm.isLoading}
        success={loadingForm.isSuccess}
        isOpen={loadingForm.isOpen}
        setIsOpen={() => {}}
      />

      <Container maxWidth="false">
        <ActionHeader title="Operación de Casillas" isCustom>
          <Button
            variant="text"
            color="primary"
            startIcon={<Icon>{openDrawer ? 'filter_list_off' : 'filter_list'}</Icon>}
            onClick={() => setOpenDrawer((prevState) => !prevState)}
          >
            Filtros
          </Button>
        </ActionHeader>
        <Grid2 container spacing={openDrawer ? 2 : 0} direction={{ md: 'row-reverse' }}>
          <Grid2 xs={12} md={3}>
            <DrawerRelative
              anchor="right"
              open={openDrawer}
              setOpen={setOpenDrawer}
              title={'Filtros'}
              titleComponentPlace="under"
              className="card-primary"
              sxPaper={{ borderRadius: 3 }}
              isSticky
            >
              <FilterSije onChangeFilter={onChangeFilter} onClearFilter={onClearFilter} />
            </DrawerRelative>
          </Grid2>
          <Grid2 xs={12} md={openDrawer ? 9 : 12}>
            <Box component="div" marginBottom={1}>
              <Grid2 container spacing={2}>
                {/* <Grid2 xs={12} md={4}>
                  <LastUpdatedCard
                    dateHour={
                      moment(infoGraficasRCs.Actualizado).format(
                        "DD/MM/YYYY HH:mm:ss"
                      ) || ""
                    }
                    handleClick={handleRefresh}
                    //columnIn={openDrawer ? "lg" : "md"}
                  />
                </Grid2> */}
                <Grid2 xs={12} md={12}>
                  <MinutesCard filtered={filteredMinutes.filtered} />
                </Grid2>
              </Grid2>
            </Box>

            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  <CasillaCard data={totalCasillas} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <IncidentsCard data={totalIncidencias} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardLayout
                    title={'PASE DE LISTA RCS'}
                    bgColor={indigo[50]}
                    color={indigo[800]}
                    icon={'checklist'}
                  >
                    <PlacementColumns
                      titulo={{ title: '', right: 'Asistencia' }}
                      subtitle={{
                        subtitle: moment(infoGraficasRCs.Actualizado).format('DD/MM/YYYY HH:mm:ss'),
                      }}
                      datos={{
                        categories: infoGraficasRCs.categories,
                        series: [
                          {
                            name: 'Meta',
                            color: indigo[300],
                            data: infoGraficasRCs.Meta,
                            pointPadding: 0.3,
                            pointPlacement: -0.1,
                          },
                          {
                            name: 'Avance',
                            color: indigo[700],
                            data: infoGraficasRCs.series,
                            pointPadding: 0.3,
                            pointPlacement: -0.1,
                          },
                        ],
                      }}
                      height={height * 0.25}
                    />
                  </CardLayout>
                </Grid2>
              </Grid2>
            </Box>

            <Box marginBottom={2}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  <CardLayout
                    title={'PASE DE LISTA RGS'}
                    bgColor={indigo[50]}
                    color={indigo[800]}
                    icon={'checklist'}
                  >
                    <PlacementColumns
                      titulo={{ title: '', right: 'Asistencia' }}
                      subtitle={{
                        subtitle: moment(infoGraficasRGs.Actualizado).format('DD/MM/YYYY HH:mm:ss'),
                      }}
                      datos={{
                        categories: infoGraficasRGs.categories,
                        series: [
                          {
                            name: 'Meta',
                            color: indigo[300],
                            data: infoGraficasRGs.Meta,
                            pointPadding: 0.3,
                            // pointPlacement: -0.1,
                          },
                          {
                            name: 'Avance',
                            color: indigo[700],
                            data: infoGraficasRGs.series,
                            pointPadding: 0.3,
                            //pointPlacement: -0.1,
                          },
                        ],
                      }}
                      height={height * 0.25}
                    />
                  </CardLayout>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardLayout title={'INICIO'} bgColor={green[100]} color={green[800]} icon={'lock_open'}>
                    <BasicLine
                      title={{
                        primary: '',
                        sub: moment(infoGraficas.Actualizado).format('DD/MM/YYYY HH:mm:ss'),
                      }}
                      series={infoGraficas.series}
                      categories={infoGraficas.categories}
                      height={height * 0.25}
                    />
                  </CardLayout>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardLayout title={'CIERRE'} bgColor={pink[100]} color={pink[800]} icon={'lock'}>
                    <BasicLine
                      title={{
                        primary: '',
                        sub: moment(infoGraficasCierre.Actualizado).format('DD/MM/YYYY HH:mm:ss'),
                      }}
                      series={infoGraficasCierre.series}
                      categories={infoGraficasCierre.categories}
                      height={height * 0.25}
                    />
                  </CardLayout>
                </Grid2>
              </Grid2>
            </Box>

            <Card className="card-primary">
              <CardContent>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginBottom={2}
                >
                  <Typography variant="body2" fontWeight={700}>
                    OPERACIÓN CASILLA
                  </Typography>
                  {middleware.checkMenuAction('Exportar') && (
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      startIcon={<Icon>download</Icon>}
                      size="small"
                      disabled={isDownload}
                      onClick={handleExport}
                    >
                      Exportar
                    </Button>
                  )}
                </Stack>
                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={'xlsx'} isDownload={isDownload} />
                  </Box>
                )}
                {/*  <BasicTable
                  rows={dataOperation}
                  hcolumns={COLUMNS_OPERATION}
                  total={total}
                  pageProp={data.page}
                  pageSize={data.pageSize}
                  minHeight="0px"
                  stickyHeader={true}
                  isLoading={loadingData}
                  handleChangePagination={handleChangePagination}
                  handleManualSort={handleRequestSort}
                /> */}

                <CustomTable
                  rows={dataOperation}
                  columns={COLUMNS_OPERATION}
                  //orderBy={orderBy}
                  total={total}
                  page={data.page}
                  pageSize={data.pageSize}
                  isLoading={loadingData}
                  handlePagination={handleChangePagination}
                  handleSort={handleRequestSort}
                  stickyHeader
                  maxHeight={'100%'}
                  //summary={summary}
                  //summarySettings={{ start: 0 }}
                  //disableCardType
                />
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>

        {!openDrawer && (
          <FloatingButton
            icon="filter_list"
            iconSize="30px"
            label="Mostrar Filtros"
            onClick={() => setOpenDrawer(true)}
            sx={{ display: { xs: 'none', md: 'block' } }}
          />
        )}

        <ModalPreviewImages
          images={dataImage}
          openModal={preview}
          loading={loadingDataImage}
          setOpenModal={setOpenPreview}
        />
      </Container>
    </AdminLayout>
  );
};

export default OperationCasilla;
