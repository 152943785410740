import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Card, CardContent, Grid, Tooltip } from '@mui/material';
import { Pending, AccessTimeFilled, CheckCircle } from '@mui/icons-material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';

import BasicSelect from '@components/Selects/BasicSelect';
import FilterCollapse from '@components/Filters/FilterCollapse';

// Servicios, Utils
import DiaDServices from '@services/DiaDServices';
import { setDiynamicColumns } from '@utils/Utilities';

const Table = (props) => {
  const { params, catalogs, isLoadingCatalogs, isOpen } = props;

  const clearFilter = {
    tipo: 0,
    estatus: 0,
    pregunta1: 0,
    pregunta2: 0,
    pregunta3: 0,
    pregunta4: 0,
    pregunta5: 0,
    pregunta6: 0,
    pregunta7: 0,
  };

  const [localParams, setLocalParams] = useState({ page: 0, pageSize: 10, sorted: [] });
  const [filter, setFilter] = useState(clearFilter);

  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(10);
  const [columnsDinamics, setColumnsDinamics] = useState([]);

  useEffect(() => {
    if (localParams.filtered) getData(localParams);
    // eslint-disable-next-line
  }, [localParams]);

  useEffect(() => {
    setLocalParams({ ...localParams, filtered: params.filtered, page: 0 });
    setFilter(clearFilter);
    // eslint-disable-next-line
  }, [params]);

  const getData = async (data) => {
    try {
      setLoading(true);
      const result = await DiaDServices.getTableMRT(data);
      const { results, response, message } = result;

      if (results) {
        setTotal(response.total);

        const newData = response.data.map((item) => ({
          ...item,
          estatus: (
            <Tooltip title={item.estatus} placement="right-start" disableInteractive arrow>
              {item.idEstatus === 1 ? (
                <Pending color="error" />
              ) : item.idEstatus === 2 ? (
                <AccessTimeFilled color="warning" />
              ) : (
                <CheckCircle color="success" />
              )}
            </Tooltip>
          ),
        }));

        handleData({ data: newData });
      } else {
        setRows([]);
        setTotal(0);
        setColumnsDinamics([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleData = ({ data }) => {
    const localColumns = [
      { id: 'tipo', label: 'Tipo' },
      { id: 'Ruta', label: 'Ruta' },
      { id: 'Municipio', label: 'Municipio' },
      { id: 'DFED', label: 'Distrito Federal' },
      { id: 'Seccion', label: 'Seccion' },
      { id: 'NombreCasilla', label: 'Casilla' },
      { id: 'estatus', label: 'Estatus', align: 'center', disablePadding: true },
      { id: 'respuesta1', label: 'Funcionario que entregó el paquete:' },
      { id: 'respuesta2', label: '¿El responsable del INE es el autorizado?' },
      { id: 'respuesta3', label: '¿Muestra alteración?' },
      { id: 'respuesta4', label: '¿Está firmado?' },
      { id: 'respuesta5', label: '¿Tiene cinta y etiqueta de seguridad?' },
      { id: 'respuesta6', label: '¿Hubo custodia?' },
      { id: 'respuesta7', label: '¿Hubo incidentes?' },
    ];

    const columns = setDiynamicColumns({
      data: data[0],
      columns: localColumns,
    });
    setColumnsDinamics(columns);
    setRows(data);
  };

  const handleChangeFilter = ({ name, value }) => {
    let filters = localParams.filtered;

    switch (name) {
      case 'tipo':
        filters = filters.filter((item) => item.id !== 'rutas_mrt.idTipo');
        if (value > 0) filters.push({ id: 'rutas_mrt.idTipo', filter: '=', value: value });
        break;
      case 'estatus':
        filters = filters.filter((item) => item.id !== 'rutas_casillas_mrt.idEstatus');
        if (value > 0)
          filters.push({
            id: 'rutas_casillas_mrt.idEstatus',
            filter: value === 1 ? '=' : 'IN',
            value: value === 1 ? 3 : [1, 2],
          });
        break;
      case 'pregunta1':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta1');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta1', filter: value === 1 ? '>' : '=', value: 0 });
        break;
      case 'pregunta2':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta2');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta2', filter: '=', value: value === 1 ? 1 : 0 });
        break;
      case 'pregunta3':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta3');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta3', filter: value === 1 ? '>' : '=', value: 0 });
        break;
      case 'pregunta4':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta4');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta4', filter: value === 1 ? '>' : '=', value: 0 });
        break;
      case 'pregunta5':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta5');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta5', filter: value === 1 ? '>' : '=', value: 0 });
        break;
      case 'pregunta6':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta6');
        if (value > 0) filters.push({ id: 'respuestas.idRespuesta6', filter: '=', value: value });
        break;
      case 'pregunta7':
        filters = filters.filter((item) => item.id !== 'respuestas.idRespuesta7');
        if (value > 0)
          filters.push({ id: 'respuestas.idRespuesta7', filter: value === 1 ? '>' : '=', value: 0 });
        break;
      default:
        break;
    }

    setLocalParams({ ...localParams, filtered: filters, page: 0 });
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handlePagination = ({ page, pageSize }) => setLocalParams({ ...localParams, page, pageSize });

  const handleSorted = ({ orderBy, order }) => {
    let sorted = [];

    switch (orderBy) {
      case 'Seccion':
        sorted = [{ id: 'rutas_casillas_mrt.Seccion', value: order }];
        break;
      case 'Casilla':
        sorted = [{ id: 'rutas_casillas_mrt.idCasilla', value: order }];
        break;
      case 'estatus':
        sorted = [{ id: 'rutas_casillas_mrt.idEstatus', value: order }];
        break;
      case 'tipo':
        sorted = [{ id: 'rutas_mrt.idTipo', value: order }];
        break;
      default:
        sorted = [{ id: orderBy, value: order }];
        break;
    }

    setLocalParams({ ...localParams, sorted });
  };

  return (
    <>
      <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
        <CardContent>
          <FilterCollapse expand={false}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="tipo"
                  label="Tipos"
                  options={catalogs?.tipos}
                  value={filter.tipo}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="estatus"
                  label="Estatus"
                  options={catalogs?.estatus}
                  value={filter.estatus}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta1"
                  label="Funcionario que entregó el paquete:"
                  options={catalogs?.pregunta1}
                  value={filter.pregunta1}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta2"
                  label="¿El responsable del INE es el autorizado?"
                  options={catalogs?.pregunta2}
                  value={filter.pregunta2}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta3"
                  label="¿Muestra alteración?"
                  options={catalogs?.pregunta3}
                  value={filter.pregunta3}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta4"
                  label="¿Está firmado?"
                  options={catalogs?.pregunta4}
                  value={filter.pregunta4}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta5"
                  label="¿Tiene cinta y etiqueta de seguridad?"
                  options={catalogs?.pregunta5}
                  value={filter.pregunta5}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta6"
                  label="¿Hubo custodia?"
                  options={catalogs?.pregunta6}
                  value={filter.pregunta6}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={4}>
                <BasicSelect
                  name="pregunta7"
                  label="¿Hubo incidentes?"
                  options={catalogs?.pregunta7}
                  value={filter.pregunta7}
                  onChange={(e) => handleChangeFilter({ name: e.target.name, value: e.target.value })}
                  isLoading={isLoadingCatalogs}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </FilterCollapse>

          <br />

          <CustomTable
            rows={rows}
            columns={columnsDinamics}
            total={total}
            page={localParams.page}
            pageSize={localParams.pageSize}
            isLoading={loading}
            handlePagination={handlePagination}
            handleSort={handleSorted}
            disableCardType
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Table;
