import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { saveAs } from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as ExcelJS from 'exceljs';

const DownloadButton = ({
  text,
  textColor = 'white',
  pY = 0.5,
  pX = 2,
  fileData,
  errorData,
  rowStart,
  headers,
}) => {
  const theme = useTheme();

  const generateErrorExcelFile = async () => {
    const workbook = new ExcelJS.Workbook();

    // Crear hoja de Excel
    const worksheet = workbook.addWorksheet('Errores');

    // Agregar encabezados
    const headerRowNumber = 1;
    const headerRow = worksheet.getRow(headerRowNumber);
    headers.forEach((header, index) => {
      const cell = headerRow.getCell(index + 1);
      cell.value = header.name;
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: '000000' }, // Black text color
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    headerRow.commit();

    const dataStartRowNumber = rowStart;
    fileData.forEach((row, rowIndex) => {
      const dataRow = worksheet.getRow(dataStartRowNumber + rowIndex);
      headers.forEach((header, colIndex) => {
        const cell = dataRow.getCell(colIndex + 1);
        const data = row[header.name] || '';
        cell.value = data.text || data;
        cell.alignment = data.alignment || { horizontal: 'left' };
      });
      dataRow.commit();
    });

    // Ajustar tamaño de celdas
    worksheet.columns.forEach((column) => {
      let maxColumnWidth = 15;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        const cellWidth = cellValue.length + 2;
        if (cellWidth > maxColumnWidth) {
          maxColumnWidth = cellWidth;
        }
      });
      column.width = maxColumnWidth;
    });

    // Agregar notas y celdas en rojo
    errorData.forEach((error) => {
      const row = worksheet.getRow(error.row); // Ajuste para la fila de inicio correcta
      headers.forEach((header, colIndex) => {
        if (
          header.name === error.column ||
          (header.name === 'Paterno' && error.column === 'Materno') ||
          (header.name === 'Materno' && error.column === 'Paterno')
        ) {
          const cell = row.getCell(colIndex + 1);
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD6D6' },
          };
          cell.note = {
            texts: [{ text: error.message }],
          };
        }
      });
    });

    // Crear buffer y guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'Errores_Encontrados.xlsx');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingX: pX,
        paddingY: pY,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: '5rem',
        cursor: 'pointer',
      }}
      onClick={generateErrorExcelFile}
    >
      <Typography
        variant="h6"
        color={textColor}
        align="center"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {text}
      </Typography>
      <IconButton size="small" sx={{ color: textColor, ml: 1 }}>
        <GetAppIcon />
      </IconButton>
    </Box>
  );
};

export default DownloadButton;
