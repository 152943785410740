const MapAddPolygonInterface = {
  idUsuarioRG: 0,
  idUsuarioAbogado: 0,
  Description: '',
  Casillas: [],
};

const MapEditPolygonInterface = {
  id: '',
  idUsuarioRG: 0,
  idUsuarioAbogado: 0,
  Description: '',
  Casillas: [],
};

export { MapAddPolygonInterface, MapEditPolygonInterface };
