import { useEffect, useState, useRef } from 'react';

// Charts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Material UI
import { CircularProgress, Card, CardContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

// Utilidades
import { numberWithCommas } from '@utils/Utilities/';

// Hooks
import useWindowDimensions from '@hooks/useWindowDimensions';

const BarChartTop = (props) => {
  const { dataAPI, filtered, title = 'titulo', idCategories, idSeries, change } = props;

  const [actualDate, setActualDate] = useState(new Date().toLocaleString());
  const [localTitle, setLocalTitle] = useState(title);
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);

  const { height, width } = useWindowDimensions();

  // Llamada a la API
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await dataAPI(filtered);
      const { results, message, response } = result;

      if (results) {
        const tempCategories = response.data.map((item) => item[idCategories]);
        const tempSeriesData = response.data.map((item) => parseInt(item[idSeries]));
        setCategories(tempCategories);
        setSeriesData(tempSeriesData);

        /* if (chartRef.current) {
          const chart = chartRef.current.chart;

          chart.update({
            subtitle: {
              text: `${new Date().toLocaleString()}`,
            },
          });
        } */
      } else {
        setCategories([]);
        setSeriesData([]);
        Swal.fire({
          title: title,
          text: message,
          icon: 'warning',
        });
      }
    } catch (errors) {
      Swal.fire({
        title: '',
        text: errors.message,
        icon: 'warning',
      });
    } finally {
      setLocalTitle(title);
      setActualDate(new Date().toLocaleString());
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [change]);

  // Evita que se quite la linea del chart
  const handleLegendItemClick = (e) => {
    if (e.target.name === 'data') {
      e.preventDefault();
    }
  };

  // Opciones del chart
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: width > 1440 ? height * 0.4 : 'auto',
    },
    title: {
      text: `${localTitle.toUpperCase()}`,
      align: 'right',
      style: {
        color: '#999',
        fontSize: width > 1440 ? width * 0.01 : '18px',
      },
    },
    subtitle: {
      text: `${actualDate}`,
      align: 'right',
      style: {
        fontSize: width > 1440 ? width * 0.008 : '12px',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : '14px',
        },
      },
    },
    yAxis: {
      title: '',
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : '14px',
        },
      },
    },
    series: [
      {
        name: 'data',
        showInLegend: false,
        data: seriesData,
        // Etiquetas de datos en las columnas
        dataLabels: {
          enabled: true,
          formatter: function () {
            return numberWithCommas(this.y); // Valor de la columna
          },
          style: {
            color: '#333',
            fontSize: width > 1440 ? width * 0.0085 : '12px',
          },
        },
        events: {
          legendItemClick: handleLegendItemClick, // Maneja el evento de clic en la leyenda
        },
      },
    ],
    tooltip: {
      formatter: function () {
        // Formato personalizado del tooltip
        return `<span style="font-size: ${width > 1440 ? `${width * 0.0085}px` : '12px'}">
        <b>${this.point.category}</b><br/>Total: ${numberWithCommas(this.point.y)}
        </span>`;
      },
    },
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        )}
      </CardContent>
    </Card>
  );
};

export default BarChartTop;
