import { useState } from 'react';

// Material UI
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { roundTo } from '@components/Maps/Auxiliars/MathFunctions';
import { convertToNumber } from '@utils/Utilities';

/* Ejemplo de elements
    const elements = [
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "Avance:",
      name: "avance",
      type: "int",
    },
    {
      title: "Porcentaje de Avance:",
      name: "avance_porcentual",
      type: "percent",
    },
  ]; */

/* Ejemplos de title
    const title = [ "titulo 1" , "titulo 2" ];
    const title ="titulo general"; 
*/

const TableInfo = (props) => {
  const { properties, elements, width = '100%' } = props;

  const minRowsPerPage = 5;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(minRowsPerPage);

  // Estructura de la tabla
  const titles = elements.map((item) => ({
    type: item.type,
    title: item.title.replace(/:\s*$/, ''),
  }));

  const tableItems = properties?.map((row) =>
    elements.map((item) => ({
      type: item.type,
      value: row
        ? item.type === 'text'
          ? row[item.name] || row[item.name] === 0
            ? row[item.name]
            : '--'
          : item.type === 'int'
            ? new Intl.NumberFormat('en-EN').format(row[item.name])
            : item.type === 'percent'
              ? row[item.name] === null
                ? '--'
                : roundTo(row[item.name], 2) + '%'
              : row[item.name]
        : null,
    }))
  );

  // Paginación
  const handleChangePage = (e, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // Ordenamiento
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    if (orderBy !== property) setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    if (array) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }
  };

  const getComparator = (order, orderBy) => {
    const value = 'value';

    return order === 'desc'
      ? (a, b) => {
          const numA = convertToNumber(a[orderBy][value]);
          const numB = convertToNumber(b[orderBy][value]);
          return numB < numA ? -1 : 1;
        }
      : (a, b) => {
          const numA = convertToNumber(a[orderBy][value]);
          const numB = convertToNumber(b[orderBy][value]);
          return numA < numB ? -1 : 1;
        };
  };

  // Datos ordenados y paginados
  const visibleRows = tableItems
    ? stableSort(tableItems, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : null;

  // Estilos
  const style = { paddingY: '1px', paddingX: '4px', fontSize: '12px' };
  const hoverStyle = { '&:hover': { backgroundColor: '#C7D8EA' } };
  return (
    <>
      {properties && (
        <Box sx={{ paddingY: 1, width: width }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="información del feature">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#EBEBEB' }}>
                  {titles.map((row, index) => (
                    <TableCell
                      key={index}
                      align={row.type !== 'text' ? 'right' : 'left'}
                      sx={{
                        ...style,
                        paddingY: tableItems.length <= 1 ? '4px' : '1px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {/* Ordenamiento */}
                      {tableItems.length <= 1 ? (
                        <Typography variant="h5" fontSize={12} fontWeight={400} noWrap>
                          {row.title}
                        </Typography>
                      ) : (
                        <TableSortLabel
                          active={orderBy === index}
                          direction={orderBy === index ? order : 'asc'}
                          onClick={() => handleRequestSort(index)}
                        >
                          <Typography variant="h5" fontSize={12} fontWeight={400} noWrap>
                            {row.title}
                          </Typography>
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: index % 2 ? '#E4ECFA' : 'white', ...hoverStyle }}
                  >
                    {row.map((item, rowIndex) => (
                      <TableCell
                        key={rowIndex}
                        align={item.type !== 'text' ? 'right' : 'left'}
                        sx={{ ...style, maxWidth: '150px' }}
                      >
                        <Typography variant="h5" fontSize={12} fontWeight={400} noWrap>
                          {item.value}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Paginación */}
          {tableItems.length >= minRowsPerPage && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default TableInfo;
