import { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

import { Box, Button, CardContent, Icon } from '@mui/material';

import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

//import MovilizationServices from "@services/MovilizationServices";
//import Swal from "sweetalert2";

const Filter = ({ tab, setParamsFilter, onChange, onClear }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: 'municipios_reportes', getAll: false },
    { id: 'poligonos', getAll: false },
    { id: 'secciones', getAll: false },
  ];
  /* const options_Yes_No = [
    { value: 0, label: "TODOS" },
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
  ]; */

  // const showFilterHours = [0, 1];
  //const tabsRequired = [0];
  //const MunRequired = [1];
  //const showLista_MovReportada = 1;

  const showFilterHours = 0;

  //States
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  //const [catSchedules, setCatSchedules] = useState([]);
  const [filterData, setFilterData] = useState({
    municipio: '',
    poligono: '',
    // hora: "",
    seccion: '',
  });
  const [errors, setErrors] = useState({
    //municipio: false,
    //hora: false,
    seccion: false,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);

  /*  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await MovilizationServices.getSchedules({});
      const { results, response, message } = result;

      if (results) setCatSchedules(response.data);
      else throw new Error(message);
    } catch (error) {
      setCatSchedules([]);
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setLoadingCatalogs(false);
    }
  }; */

  const handleChangeFilter = (e) => {
    const key = (e.target && e.target.name) || e.name;
    setFilterData({
      ...filterData,
      [key]: (e.target && e.target.value) || e.value,
    });
  };

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      poligonos: catalogs.poligonos.filter(
        ({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality
      ),
      secciones: catalogs.secciones.filter(({ idMunicipio }) => idMunicipio === _idMunicipality),
    }));
    setFilterData((prevState) => ({ ...prevState, poligono: '', seccion: '' }));
  };

  const handleChangePolygon = (e) => {
    const _idPolygon = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      secciones: catalogs.secciones.filter(({ idPoligono }) => idPoligono === _idPolygon),
    }));

    setFilterData((prevState) => ({ ...prevState, seccion: '' }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitFilter();
      e.preventDefault();
    }
  };

  const handleSubmitFilter = () => {
    setParamsFilter((prevState) => ({
      ...prevState,
      seccion: filterData.seccion,
      hora: filterData.hora,
    }));
    onChange(filterData);
  };

  const handleClear = () => {
    setCatalogFiltered(catalogs);
    setFilterData((prevState) => ({
      ...prevState,
      municipio: '',
      poligono: '',
      seccion: '',
    }));
    setParamsFilter((prev) => ({ ...prev, seccion: '' }));
    setErrors({ seccion: false });
    onClear();
  };

  useEffect(() => {
    if (tab === showFilterHours) {
      if (filterData.seccion === '') {
        setErrors((prevState) => ({ ...prevState, seccion: true }));
      } else {
        setErrors((prevState) => ({ ...prevState, seccion: false }));
      }
    }
  }, [tab, filterData.seccion]);

  /*   useEffect(() => {
    if (showFilterHours.includes(tab)) {
      // hora y sección requerida en tab "CAPTURA DE MOV"
      if (tabsRequired.includes(tab)) {
        if (filterData.hora === "") {
          setErrors((prev) => ({ ...prev, hora: true }));
        } else setErrors((prev) => ({ ...prev, hora: false }));

        if (filterData.seccion === "")
          setErrors((prev) => ({ ...prev, seccion: true }));
        else setErrors((prev) => ({ ...prev, seccion: false }));
      } else setErrors((prev) => ({ ...prev, hora: false, seccion: false }));

      // Municipio requerido en tab "REPORTE"
      if (MunRequired.includes(tab)) {
        if (filterData.municipio === "") {
          setErrors((prev) => ({ ...prev, municipio: true }));
        } else setErrors((prev) => ({ ...prev, municipio: false }));
      } else setErrors((prev) => ({ ...prev, municipio: false }));
    } else setErrors({ hora: false, seccion: false, municipio: false });
    // eslint-disable-next-line
  }, [tab, filterData.hora, filterData.seccion, filterData.municipio]); */

  useEffect(() => {
    if (tab !== showFilterHours) {
      setErrors({
        ...errors,
        seccion: false,
      });
    }
  }, [tab]);

  /*  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []); */

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
      setFilterData((prev) => ({
        ...prev,
        municipio: catalogs.municipios_reportes?.length === 1 ? catalogs.municipios_reportes[0].value : '',
        poligono: catalogs.poligonos?.length === 1 ? catalogs.poligonos[0].value : '',
      }));
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <CardContent>
      <Box component={'div'} marginBottom={2}>
        <BasicSelect
          name="municipio"
          label="Municipio"
          options={catalogsFiltered.municipios_reportes}
          value={
            catalogsFiltered.municipios_reportes?.length === 1
              ? catalogsFiltered.municipios_reportes[0].value
              : filterData.municipio
          }
          disabled={catalogsFiltered.municipios_reportes?.length <= 1}
          //error={errors.municipio}
          //errorMessage={errors.municipio ? "Municipio es requerido" : ""}
          onChange={(e) => {
            handleChangeFilter(e);
            handleChangeMunicipality(e);
          }}
          isLoading={load}
          sx={{ width: '100%', mb: 3 }}
        />

        <BasicSelect
          name="poligono"
          label="Polígono"
          options={catalogsFiltered.poligonos}
          value={
            catalogsFiltered.poligonos?.length === 1
              ? catalogsFiltered.poligonos[0].value
              : filterData.poligono
          }
          disabled={catalogsFiltered.poligonos?.length <= 1}
          onChange={(e) => {
            handleChangeFilter(e);
            handleChangePolygon(e);
          }}
          isLoading={load}
          sx={{ width: '100%', mb: 3 }}
        />

        <AdvancedSelect
          name="seccion"
          label="Sección"
          options={catalogsFiltered.secciones?.map((item) => ({
            value: item.value,
            label: String(item.label),
          }))}
          value={filterData.seccion}
          onChange={(e) => handleChangeFilter({ name: 'seccion', value: e.value })}
          onKeyDown={handleKeyPress}
          error={errors.seccion}
          helperText={errors.seccion && 'El campo sección es requerido'}
          isSearchable
          sx={{ width: '100%', mb: 3 }}
        />
      </Box>
      <Box marginBottom={2}>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search</Icon>}
          onClick={handleSubmitFilter}
          disabled={Object.values(errors).includes(true)}
          sx={{ width: '100%', mb: 1 }}
        >
          buscar
        </Button>
        <Button
          variant="outlined"
          color="primaryDark"
          size="small"
          startIcon={<Icon>search_off</Icon>}
          disabled={Object.values(errors).includes(true)}
          onClick={handleClear}
          sx={{ width: '100%' }}
        >
          Limpiar
        </Button>
      </Box>
    </CardContent>
  );
};

export default Filter;
