import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Yup from '@utils/Yupi18n';
import Swal from 'sweetalert2';

// Material UI
import {
  Box,
  Container,
  Typography,
  TextField,
  Icon,
  Card,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Link,
} from '@mui/material';
import { SystemSecurityUpdateGood } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@src/theme/index';

import { setVars, RECAPTCHA_SITE_KEY, Version, URL_PUBLICA_FRONT } from '@utils/global';
import { isEmptyOrInvalidString } from '@utils/validations';

import avatar from '@assets/img/SIAN.png';
// import gorro from "@assets/img/GorroNavidad.png";
// import Snowfall from "react-snowfall";
import '@pages/auth/LoginStyle.css';

// import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Servicios
import AuthServices from '@services/AuthServices';

// Hooks
import useWindowDimensions from '@hooks/useWindowDimensions';

const CardHeader = () => {
  return (
    <>
      <div className="card-header-multimedia">
        <img alt="Logo" src={avatar} width={120} height={120} />
        <Typography sx={{ textAlign: 'center', mt: 2 }} variant="h5" fontWeight={400}>
          Iniciar Sesión
        </Typography>
      </div>
    </>
  );
};

const CardBody = ({
  showPassword,
  setShowPassword,
  formik,
  handleOnKeyPress,
  // captchaRef,
  // onChangeCaptcha,
}) => {
  return (
    <>
      <div className="card-content">
        <TextField
          //label="Usuario"
          placeholder="Usuario"
          className="input"
          size="small"
          sx={{ mb: 1 }}
          error={formik.touched.username && !isEmptyOrInvalidString(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username && formik.errors.username}
          type="text"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          autoFocus
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ cursor: 'inherit' }}>
                  <Icon>account_circle</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          //label="Contraseña"
          placeholder="Contraseña"
          className="input"
          size="small"
          error={formik.touched.password && !isEmptyOrInvalidString(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password && formik.errors.password}
          type={showPassword ? 'text' : 'password'}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <Box
          marginTop={3}
          marginBottom={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            onChange={onChangeCaptcha}
          />
        </Box> */}
        <Typography variant="body2" sx={{ textAlign: 'center', color: '#9a9a9a' }}>
          Acceso a personal autorizado
        </Typography>
      </div>
    </>
  );
};

const CardAction = ({ signIn, loading, formik }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="card-action">
        {loading ? (
          <CircularProgress
            sx={{
              display: 'flex',
              margin: '0 auto',
              width: '30px !important',
              height: '30px !important',
            }}
          />
        ) : (
          <Button className="button-login" onClick={signIn}>
            INICIAR SESIÓN
          </Button>
        )}
        <div style={{ textAlign: 'end', mt: 1 }}>
          <Typography variant="body1" sx={{ textDecoration: 'none', p: 0 }}>
            ¿Olvidaste tu contraseña?{' '}
            {
              <Button
                sx={{ p: 0 }}
                onClick={() =>
                  navigate(
                    `/cambio-validation/${btoa(
                      JSON.stringify({
                        dato: formik.values.username,
                        value: formik.values.idEstado,
                      })
                    )}`
                  )
                }
              >
                Cámbiala aquí
              </Button>
            }
          </Typography>
        </div>

        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            color: '#0041a0',
          }}
        >
          <Link underline="hover" target="_blank" rel="noreferrer" href={`${URL_PUBLICA_FRONT}descarga-app`}>
            Actualiza la versión de tu aplicación
          </Link>
          <SystemSecurityUpdateGood sx={{ color: '#0041a0' }} />
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: '#9a9a9a',
            marginBottom: '5px',
          }}
        >
          {Version ? Version : 'Ver. 3.0.0'}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            underline="hover"
            style={{ color: '#0041a0' }}
            target="_blank"
            rel="noreferrer"
            href={`${URL_PUBLICA_FRONT}privacidad`}
          >
            Ver aviso de privacidad
          </Link>
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: '#9a9a9a',
            marginTop: '2px',
            marginBottom: '5px',
          }}
        >
          <Link
            underline="hover"
            style={{ color: '#9a9a9a', fontWeight: 500 }}
            target="_blank"
            rel="noreferrer"
            href={`${URL_PUBLICA_FRONT}soporte`}
          >
            ¿Necesitas Ayuda?
          </Link>
        </Typography>
      </div>
    </>
  );
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const captchaRef = useRef(null);
  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const size = { xxs: width < 400 };

  const initialValues = {
    username: '',
    password: '',
    type_login: 'web',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required().label('Usuario'),
    password: Yup.string().required().min(6).label('Contraseña'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //signIn(values);
      onChangeCaptcha(values);
    },
  });

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('login')
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          signIn(data);
        })
        .catch((error) => Swal.fire({ title: error, icon: 'warning' }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const signIn = async (values) => {
    // const token = captchaRef.current.getValue()

    const forcePasswordChange = () => {
      Swal.fire({
        icon: 'info',
        title: 'Actualizar Contraseña',
        text: 'Antes de iniciar sesión, por favor, actualiza tu contraseña.',
        showCancelButton: false,
        confirmButtonText: 'ACTUALIZAR',
        confirmButtonColor: '#0041a0',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate(
            `/cambio-validation/${btoa(JSON.stringify({ dato: values.username, value: values.idEstado }))}`
          );
        }
      });
    };

    try {
      setLoading(true);

      console.log('=>login: ', values);
      const result = await AuthServices.login(values);
      const { results, response, message } = await result;

      if (results) {
        if (response.forcePasswordChange) return forcePasswordChange();

        setVars('Token', response);
        const perfil = response.user.ambito_perfil[0].Perfil;

        if (perfil === 'RCs' || perfil === 'RGs') navigate('/welcome-dia-d');
        else navigate(response.user.DefaultPage);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoading(false);
      // captchaRef.current.reset();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="section-image"
        sx={{
          backgroundImage: `url(${require('@assets/img/back02.png')})`,
          py: { xs: 0 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/*    <Snowfall snowflakeCount={100} fallSpeed={0.75} snowflakeSize={3} /> */}
        <Container component="main" maxWidth="sm">
          <Box className="login-container" sx={{ position: 'relative' }}>
            <Card className="card-login" sx={{ width: { xs: size.xxs ? '90%' : '80%', sm: '70%' } }}>
              {/*     <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  transform: "translate(30%, -50%) rotate(12deg)",
                }}
              >
                <img
                  alt="Logo"
                  src={gorro}
                  width={"200"}
                  height={"200"}
                  style={{
                    position: "relative",
                    right: "25%",
                  }}
                />
              </Box> */}
              <CardHeader />
              <CardBody
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                formik={formik}
                handleOnKeyPress={handleOnKeyPress}
              />
              <CardAction signIn={formik.submitForm} loading={loading} formik={formik} />
            </Card>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const LoginSian = () => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default LoginSian;
