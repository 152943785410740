import { useState } from 'react';
import { useSorted } from '@hooks/useSorted';

import { Box, Button, Card, CardContent, Icon, Stack, Typography } from '@mui/material';

import BasicTable from '@components/Tables/BasicTable';
import Download from '@components/Downloads/Download';
import BasicSelect from '@components/Selects/BasicSelect';

const TableMobilization = (props) => {
  const {
    catGroupTableFilter,
    idAmbitoAgrupadorTable,
    dataMovilization,
    columnsDinamics,
    total,
    data,
    loading,
    setData,
    handleChangeAmbitoTable,
  } = props;

  const [isDownload] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const handleChangePagination = (pagination) => setData({ ...data, ...pagination });

  return (
    <Card className="card-primary">
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={2}>
          <Typography variant="body2" fontWeight={700}>
            MOVILIZACIÓN
          </Typography>
          <Button
            variant="outlined"
            color="primaryDark"
            startIcon={<Icon>download</Icon>}
            size="small"
            disabled={isDownload}
          >
            Exportar
          </Button>
        </Stack>
        {isDownload && (
          <Box marginBottom={2}>
            <Download format={'xlsx'} isDownload={isDownload} />
          </Box>
        )}
        <Stack direction={'row'} spacing={2}>
          <BasicSelect
            name="agrupador"
            label="Agrupar por"
            options={catGroupTableFilter}
            value={idAmbitoAgrupadorTable}
            onChange={handleChangeAmbitoTable}
            sx={{ width: { xs: '100%', md: '30%' }, mb: 2 }}
          />
        </Stack>
        <BasicTable
          rows={dataMovilization}
          hcolumns={columnsDinamics}
          total={total}
          pageProp={data.page}
          pageSize={data.pageSize}
          minHeight="0px"
          stickyHeader={true}
          isLoading={loading.isLoadigForm}
          handleChangePagination={handleChangePagination}
          handleManualSort={handleRequestSort}
        />
      </CardContent>
    </Card>
  );
};

export default TableMobilization;
