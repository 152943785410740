import { Checkbox, Icon, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { isNullOrUndefined } from '@utils/validations';
import { MENUS_PRIVILEGES } from '@data/constants';

const MenuRow4Add = ({ menu, index, disabled, currentMenu, handleReturnMenu }) => {
  const columns = MENUS_PRIVILEGES;
  const [isChecked, setIsCheked] = useState(!isNullOrUndefined(disabled));
  const [isDisabled, setIsDisabled] = useState(!isNullOrUndefined(disabled));
  const [open, setOpen] = useState(false);
  const [submenus, setSubmenus] = useState([]);

  useEffect(() => {
    setSubmenus(
      menu.submenus.map((submenu) => {
        return {
          ...submenu,
          checked:
            !isNullOrUndefined(currentMenu) &&
            !isNullOrUndefined(
              currentMenu.submenus && currentMenu.submenus.find((x) => x.idMenu === submenu.idMenu)
            ),
          disabled:
            !isNullOrUndefined(currentMenu) &&
            !isNullOrUndefined(
              currentMenu.submenus && currentMenu.submenus.find((x) => x.idMenu === submenu.idMenu)
            ),
        };
      })
    );
  }, [menu, currentMenu]);

  const handleCheckMenu = () => {
    setIsCheked(!isChecked);
    const formatedMenu = {
      ...menu,
      checked: !isChecked,
    };
    handleReturnMenu([formatedMenu]);
  };

  const handleCheckSumenu = (index) => {
    let auxSubmenus = [...submenus];
    auxSubmenus[index].checked = !auxSubmenus[index].checked;
    const checkedSubmenus = auxSubmenus.filter((x) => x.checked).length;
    if (checkedSubmenus === 0 && isDisabled && !disabled) {
      setIsDisabled(false);
    } else {
      setIsCheked(true);
      setIsDisabled(true);
    }
    setSubmenus(auxSubmenus);
    handleFormatMenu(auxSubmenus[index]);
  };

  const handleFormatMenu = (submenu) => {
    const formatedMenu = {
      ...menu,
      checked: !disabled,
    };
    handleReturnMenu([formatedMenu, submenu]);
  };

  const getMenuContent = (menu) => {
    return (
      <>
        <TableCell align="right" width="5%">
          {menu.Icono ? <Icon>{menu.Icono}</Icon> : menu.Siglas ? menu.Siglas : ''}
        </TableCell>
        <TableCell width="25%" sx={{ pr: 0 }}>
          {menu.Menu} <br />
          <small>{menu.Ruta}</small>
        </TableCell>
        {columns.map((col) => (
          <TableCell align="center" key={Math.random()} width="7.5%">
            {menu[`${col}`] === 1 ? <Icon color="primary">checked</Icon> : '-'}
          </TableCell>
        ))}
      </>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell width="5%">
          {menu.submenus.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          <Checkbox checked={isChecked} disabled={isDisabled} onClick={() => handleCheckMenu()} />
        </TableCell>
        {getMenuContent(menu)}
      </TableRow>
      {submenus &&
        menu.submenus.length > 0 &&
        submenus.map((submenu, submenuIndex) => (
          <TableRow key={`${index}-${submenu.idMenu}`} sx={{ display: `${open ? '' : 'none'}` }}>
            <TableCell align="right" width="10%" colSpan={2}>
              <Checkbox
                checked={submenu.checked}
                disabled={submenu.disabled}
                onClick={() => handleCheckSumenu(submenuIndex)}
              />
            </TableCell>
            {getMenuContent(submenu)}
          </TableRow>
        ))}
    </>
  );
};

export default MenuRow4Add;
