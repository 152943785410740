import { useState } from 'react';
import { Swal } from '@utils/alerts';

// Material UI y estilos
import { Card, CardContent, Grid, Button, Icon, Box, Typography } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicListDoble from '@components/Lists/BasicListDoble';
import EventListItem from '@components/Events/EventListItem';

// Servicios
import UserService from '@services/UserServices';
import EventsServices from '@services/EventsServices';

// Utils
import { isTypePhone, isEmptyOrNullObject } from '@utils/validations';
import { getFechaActual } from '@utils/Utilities';

const Staff = ({ idTipoEvento = 0 }) => {
  const filter = idTipoEvento ? [{ id: 'cat_actividades.eventType', filter: '=', value: idTipoEvento }] : [];

  const [event, setEvent] = useState({});
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async () => {
    try {
      setIsLoading(true);

      const params = {
        idEvento: event.id,
        idUsuario: user.id,
      };

      const result = await EventsServices.setOrganizador(params);
      const { results, message } = result;

      if (results) Swal.fire({ title: message, icon: 'success' });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEvent = (e) => setEvent(e);

  const handleUser = (e) => setUser(e);
  const handleClear = () => setUser({});

  return (
    <AdminLayout key={idTipoEvento}>
      <ActionHeader title="Staff" />

      <LoadingForm
        loadinMessage="Añadiendo Staff..."
        successMessage="Staff añadido con exito!"
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <Card className="card-primary">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <BasicListDoble
                id="id_Eventos"
                API={EventsServices.getMyRecords}
                APIKeys={[{ id: ['eventos.Actividad'], filter: 'LIKE' }]}
                APIFilter={{
                  start: filter.concat([
                    { id: 'DATE(eventos.FechaInicio)', filter: '>=', value: getFechaActual() },
                  ]),
                }}
                header=""
                handleClick={handleEvent}
                label={'Encuentra y elige el evento'}
                labelNote={'Nota: Para realizar una búsqueda debe ingresar el nombre del evento.'}
                finder={{ placeholder: 'Buscar evento' }}
                initialSearch
                emptySearch
                config={{ height: 439.2 }}
                itemComponent={EventListItem}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <BasicListDoble
                id="id_Usuarios"
                API={UserService.getUsers}
                APIKeys={[
                  { id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'], filter: 'LIKE' },
                  { id: 'usuarios.Username', filter: '=' },
                ]}
                header={''}
                handleClick={handleUser}
                handleClear={handleClear}
                label={'Encuentra y elige al usuario'}
                labelNote={
                  'Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular.'
                }
                finder
                clearData
                doubleSearch
                inputValidation={{ input2: isTypePhone }}
                lengthValidation={{ input2: { filter: '=', value: 10 } }}
                breaks={{ sm: 12, md: 6 }}
                buttonsBreaks={{ sm: 12, md: 4 }}
                config={{ height: 400 }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  mt: 4,
                  px: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography>
                  <strong>Evento:</strong> {event?.Actividad ?? 'No encontrado, debes ser staff.'}
                </Typography>
                <Typography>
                  <strong>Usuario:</strong> {user?.NombreCompleto}
                </Typography>

                <Box>
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    startIcon={<Icon>add</Icon>}
                    onClick={handleAdd}
                    disabled={isEmptyOrNullObject(event) || isEmptyOrNullObject(user)}
                  >
                    Añadir a Staff
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default Staff;
