import { Card, CardContent, CardMedia, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Form from '@components/HelpCenter/Form';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '@utils/global';
import image from '@assets/img/back_sian.png';

const HelpCenter = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Typography fontWeight={800} variant="h4" textAlign={'center'} marginTop={4} marginBottom={4}>
        ¿Necesitas ayuda?
      </Typography>
      <Typography variant="subtitle1" textAlign={'center'} color={'text.secondary'} marginBottom={4}>
        Completa el formulario con los detalles y nos pondremos en contacto contigo pronto.
      </Typography>

      <Container
        sx={{
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card className="card-primary" sx={{ display: 'flex', maxHeight: '100%' }}>
          {!mobile && (
            <CardMedia
              component="img"
              sx={{
                width: '40%',
                boxShadow:
                  'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
              }}
              image={image}
              alt="sian"
            />
          )}

          <CardContent sx={{ width: '100%' }}>
            <Typography variant="subtitle1" fontWeight={600} color={'text.secondary'} textAlign={'center'}>
              Favor de completar el siguiente formulario para solicitar la asistencia técnica
            </Typography>
            <Form />
          </CardContent>
        </Card>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default HelpCenter;
