import React from 'react';

import { Card, CardContent, Typography, Box, Avatar, Icon, LinearProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import InfiniteScroll from 'react-infinite-scroll-component';

const UserTable = (props) => {
  const {
    data,
    setIdUser,
    setName,
    setClearForm = () => {},
    total,
    upPageSize,
    hasMore,
    complement,
    clickCard = () => {},
    setSelectedCard = () => {},
    selectedCard,
  } = props;

  const handleCardClick = (user) => {
    setSelectedCard(user.id);
    setIdUser(user.id);
    setName(user.NombreCompleto);
    setClearForm(true);
    clickCard(user);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      upPageSize();
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon sx={{ fontSize: 20, marginRight: 1 }}>people</Icon>
          <Typography variant="body2" fontWeight={600}>
            Usuarios
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: 'auto',
          }}
          onScroll={handleScroll}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={upPageSize}
            hasMore={hasMore}
            loader={<LinearProgress color="secondary" />}
            style={{ overflow: 'visible' }}
          >
            <Box>
              {data.map((user) => (
                <Card
                  key={user.id}
                  onClick={() => handleCardClick(user)}
                  sx={{
                    p: 0,
                    backgroundColor: user.id === selectedCard ? '#e3e3e3' : 'white',
                    boxShadow: user.id === selectedCard ? 'none' : '0px 0px 0px rgba(0, 0, 0, 0)',
                    cursor: 'pointer',
                    minHeight: 45,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ width: 28, height: 28, marginRight: 2 }}>
                        <PersonIcon />
                      </Avatar>
                      <Grid2>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                          {user.NombreCompleto}
                        </Typography>
                        {complement && (
                          <Grid2>
                            <Typography variant="body2" color="text.secondary">
                              Edad: {user.Edad}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Municipio: {user.Municipio}
                            </Typography>
                          </Grid2>
                        )}
                      </Grid2>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </InfiniteScroll>
        </Box>
        <Typography variant="body2" color="textSecondary" mt={2} ml={2}>
          Mostrando {data.length} de {total}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserTable;
