export const PublicacionesInterface = {
  idTipo: 1,
  Nombre: '',
  Descripcion: '',
  Sinopsis: '',
  files: [],
  fileName: '',
  fileNameImg: '',
  FechaPublicacion: '',
};

export const EditPublicacionesInterface = {
  id: 0,
  idTipo: 1,
  Nombre: '',
  Descripcion: '',
  Sinopsis: '',
  files: [],
  fileName: '',
  fileNameImg: '',
  fileURL: '',
  fileURLImg: '',
  FechaPublicacion: '',
};
