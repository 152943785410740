import { Public, ProtectedRoute, PrivilegedRoute } from '@middlewares';

import Eventos from '@pages/Events/Eventos';
import PreguntasPost from '@pages/Events/PreguntasPost';
import Invitados from '@pages/Events/Invitados';
import InvitadosEvent from '@pages/Events/InvitadosEvent';
import Staff from '@pages/Events/Staff';
import StaffEvent from '@pages/Events/StaffEvent';
import EventosPaseLista from '@pages/Events/EventosPaseLista';
import EventosPaseListaInfo from '@pages/Events/EventosPaseListaInfo';
import QRAsistencia from '@pages/Events/QRAsistencia';
import PreRegistro from '@pages/Events/PreRegistro';
import Publicos from '@pages/Events/Publicos';
import Dashboard from '@pages/Events/Dashboard';

import { ID_EVENTOS } from '@data/constants/Eventos';

const EventsRoutes = [
  {
    path: '/eventos',
    element: (
      <PrivilegedRoute>
        <Eventos idTipoEvento={ID_EVENTOS} id="eventos" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/eventos/preguntas',
    element: (
      <ProtectedRoute>
        <PreguntasPost idTipoEvento={ID_EVENTOS} id="eventos" />
      </ProtectedRoute>
    ),
  },
  {
    path: '/eventos/invitados',
    element: (
      <PrivilegedRoute>
        <Invitados idTipoEvento={ID_EVENTOS} id="eventos" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/eventos/invitados/:id',
    element: (
      <ProtectedRoute>
        <InvitadosEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: '/eventos/staff',
    element: (
      <PrivilegedRoute>
        <Staff idTipoEvento={ID_EVENTOS} id="eventos" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/eventos/staff/:id',
    element: (
      <ProtectedRoute>
        <StaffEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: '/eventos/pase-lista',
    element: (
      <PrivilegedRoute>
        <EventosPaseLista idTipoEvento={ID_EVENTOS} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/eventos/pase-lista/:id',
    element: (
      <ProtectedRoute>
        <EventosPaseListaInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: '/eventos/asistenciaQR/:id',
    element: (
      <Public>
        <QRAsistencia />
      </Public>
    ),
  },
  {
    path: '/eventos/preregistro/:id',
    element: (
      <Public>
        <PreRegistro />
      </Public>
    ),
  },
  {
    path: '/eventos-publicos',
    element: (
      <Public>
        <Publicos />
      </Public>
    ),
  },
  {
    path: '/eventos/dashboard/:id',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
];

export default EventsRoutes;
