import * as React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';
import { Icon, CircularProgress, Box, Typography, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Utils
import { isEmptyOrInvalidArray, isNullOrUndefined } from '@utils/validations';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    '&.Mui-expanded': { fontWeight: theme.typography.fontWeightMedium },
    [`& .${treeItemClasses.label}`]: { fontWeight: 'inherit', color: 'inherit' },
  },
  /* [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: { paddingLeft: theme.spacing(2) },
  }, */
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const { statusColor, statusIcon, label, labelInfo, icon, iconLabel, ...other } = props;

  const position = icon?.position === 'right' ? 'right' : 'left';

  const click = (e, number) => {
    e?.stopPropagation();
    icon.click(number);
  };

  const iconType = icon ? typeof icon.icon === 'string' ? <Icon>{icon.icon}</Icon> : icon.icon : null;

  const iconComponent = icon ? (
    <Tooltip
      title={iconLabel}
      disableInteractive
      arrow
      sx={{ color: statusColor ?? icon.color ?? 'inherit' }}
    >
      {icon.click ? (
        <IconButton size="small" onClick={(e) => click(e, iconLabel)}>
          {iconType}
        </IconButton>
      ) : (
        iconType
      )}
    </Tooltip>
  ) : null;

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            p: 0.5,
            pr: 0,
            color: statusColor ?? 'inherit',
          }}
        >
          {statusIcon && <Box component={statusIcon} color="inherit" sx={{ mr: 1 }} />}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {icon && position === 'left' && iconComponent}

            <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
              {label}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          {icon && position === 'right' && iconComponent}

          {labelInfo && (
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          )}
        </Box>
      }
      {...other}
      ref={ref}
    />
  );
});

const Tree = (props) => {
  const {
    data = [],
    loading = false,
    handleClick = () => {},
    typeData = { value: 'id', label: 'label', S_label: '', T_label: '', customItem: null },
    isSameIds = false,
    sameId = '',
    itemComponent = null, // Manda esto a la funcion: { nodes, handleClick, typeData, isSameIds, sameId, renderTree, getStatusInfo }
  } = props;

  const getStatusInfo = (nodes, type) => {
    if (type.customItem) {
      const matchingItems = type.customItem.filter((item) => {
        const filter = item.filter ? item.filter.toUpperCase() : '=';
        const value = nodes[item.id];

        if (value) {
          switch (filter) {
            case '=':
              return value === item.value;
            case '<>':
              return value !== item.value;
            case '>':
              return value > item.value;
            case '<':
              return value < item.value;
            case '>=':
              return value >= item.value;
            case '<=':
              return value <= item.value;
            case 'IN':
              if (Array.isArray(item.value)) return item.value.includes(value);
              return null;
            default:
              return value === item.value;
          }
        } else return null;
      });

      switch (matchingItems.length) {
        case 0:
          return null;
        // El elemento coincide con un criterio
        case 1:
          return {
            icon: matchingItems[0]?.icon || null,
            color: matchingItems[0]?.color || null,
          };
        // El elemento coincide con varios criterios
        default:
          // Busca si un elemento tiene ambos (le da prioridad)
          const two = matchingItems.find((item) => item.icon && item.color);
          if (two)
            return {
              icon: two.icon !== 'none' ? two.icon : null,
              color: two.color !== 'none' ? two.color : null,
            };

          // En caso de no tener ambos, entonces cada uno tiene uno
          const icon = matchingItems.find((item) => item.icon);
          const color = matchingItems.find((item) => item.color);
          return { icon: icon?.icon || null, color: color?.color || null };
      }
    }
    return null;
  };

  const renderTree = (nodes) => {
    if (itemComponent)
      return itemComponent({ nodes, handleClick, typeData, isSameIds, sameId, renderTree, getStatusInfo });

    const statusInfo = getStatusInfo(nodes, typeData);

    const { value, label, T_label, S_label, icon } = typeData;

    const text1 = nodes[T_label] ? `${nodes[T_label]}` : '';
    const divider = text1 !== '' ? ' - ' : '';
    const text2 = nodes[label];
    const text3 = nodes[S_label] ?? '';

    return (
      <StyledTreeItem
        key={nodes.id}
        itemId={`${nodes[value]} ${
          isSameIds ? (!isNullOrUndefined(nodes[sameId]) ? '-' + nodes[sameId] : '') : ''
        } `}
        label={`${text1} ${divider} ${text2} ${text3}`}
        statusIcon={statusInfo ? statusInfo.icon : null}
        statusColor={statusInfo ? statusInfo.color : null}
        icon={icon?.icon ? icon : null}
        iconLabel={icon?.label ? nodes[icon.label] : ''}
        onClick={() => handleClick(nodes)}
      >
        {!isEmptyOrInvalidArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </StyledTreeItem>
    );
  };

  const TreeComponent = <SimpleTreeView>{data.map((item) => renderTree(item))}</SimpleTreeView>;

  const loadingComponent = (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 5 }}>
      <CircularProgress />
    </Box>
  );

  const noDataComponent = (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 5 }}>Sin datos</Box>
  );

  return loading ? loadingComponent : data.length !== 0 ? TreeComponent : noDataComponent;
};

Tree.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
  typeData: PropTypes.shape({
    value: PropTypes.string,
    icon: PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      color: PropTypes.string,
      position: PropTypes.oneOf(['left', 'right']),
      click: PropTypes.func,
    }),
    label: PropTypes.string,
    S_label: PropTypes.string,
    T_label: PropTypes.string,
    customItem: PropTypes.array, // Al mandar un componente, se deshabilita el por defecto
  }),
  isSameIds: PropTypes.bool,
  sameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemComponent: PropTypes.func, // Manda esto a la funcion: { nodes, handleClick, typeData, isSameIds, sameId, renderTree, getStatusInfo }
};

export default Tree;

/* Ejemplos de customItem - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

/* 
customItem: [
              { id: "idEstatus", value: 4, icon: Block, color: "#F67B7B" },           // icono y color
              { id: "idEstatus", value: 1, icon: Block, },                            // Solo icono
              { id: "idEstatus", value: 2, color: "#4BB543" },                        // Solo color
              { id: "idEstatus", filter: "IN", value: [1, 2], color: "#4BB543" },     // Con un operador distinto

                  Acepta estos operadores: "=", "<>", ">", "<", ">=", "<=", "IN" (este ultimo solo acepta arrays)
                  por defecto se usa "="
            ],

Se le da prioridad a los que tengan ambos elementos. por lo que
en caso de que otra condicion de un elemento se cumpla, no se va a sobreescribir.

// Evitar Sobreescritura
En caso de que quieras solo cambiar un elemento, pero no quieres que se sobreescriba, puedes usar "none" en el otro.
{ id: "idEstatus", value: 4, icon: "none", color: "#F67B7B" },

Es importante el orden que se le de (en caso de que dos elementos coincidan y cambien la misma cosa)
*/

/* Ejemplo de itemComponent - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

/*
  import { TreeItem } from "@mui/x-tree-view";
  import { isEmptyOrInvalidArray, isNullOrUndefined } from "@utils/validations";

  const component = (props) => {
    const { nodes, handleClick, typeData, isSameIds, sameId, renderTree } = props;
    return (
      <TreeItem
        key={nodes.id}
        nodeId={`${nodes[typeData.value]} ${isSameIds ? (!isNullOrUndefined(nodes[sameId]) ? "-" + nodes[sameId] : "") : ""} `}
        label={`${nodes[typeData.T_label] ? `${nodes[typeData.T_label]} -` : ""} ${nodes[typeData.label]} ${
          nodes[typeData.S_label] ? nodes[typeData.S_label] : ""
        }`}
        onClick={() => handleClick(nodes)}
      >
        {!isEmptyOrInvalidArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };

*/
