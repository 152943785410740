import React, { useState } from 'react';
import { Button, Container, Icon, IconButton, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Navbar from '../Navbar';
import WebCam from '../WebCam';
import Toast from '../Toast';

import moment from 'moment/moment';
import 'moment/locale/es';
import esLocale from 'date-fns/locale/es';
import { getVars } from '@utils/global';
import { useMobileServices } from '@hooks/MobileDiaD/MobileServices';

const ScreenAperturaCasilla = ({ setTypeScreen }) => {
  const {
    casilla_rc: {
      Casillas: { NombreCasilla, Seccion, idCasilla },
    },
    user: { ambito_perfil },
  } = getVars('Token');

  const { loading, snackbar, setSnackbar, setAperturaCasillas } = useMobileServices();

  const [image, setImage] = useState(null);
  const [formValue, setFormValue] = useState({
    hour: moment(),
    officials: '',
    takenRow: '',
  });

  const handleChangeFormValue = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleAperturaCasilla = async () => {
    const params = {
      idUser: ambito_perfil[0].idUsuario,
      casillas: [
        {
          idCasilla: idCasilla,
          FechaHora: moment(formValue.hour).format('HH:mm'),
          NumFuncionarios: formValue.officials,
          TomadosFila: formValue.takenRow,
        },
      ],
    };

    if (image) params.casillas[0].files = [{ file: image }];

    const results = await setAperturaCasillas(params);

    if (results) {
      setImage(null);
      setFormValue({
        hour: moment(),
        officials: '',
        takenRow: '',
      });
    }
  };

  return (
    <>
      <Toast open={snackbar.show} message={snackbar.message} setOpen={setSnackbar} />
      <Navbar
        isScreen
        screenBack={'rcs'}
        title={'Apertura de Casilla'}
        setTypeScreen={setTypeScreen}
        color="black"
      />
      <Container sx={{ mt: 4 }}>
        <Typography textAlign={'center'} fontWeight={600} marginBottom={2}>
          ¿Ya se aperturó la casilla?
        </Typography>
        <Stack direction={'column'} spacing={1}>
          <Typography variant="body2">Sección</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {Seccion}
          </Typography>
          <Typography variant="body2">Casilla</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {NombreCasilla}
          </Typography>

          <Typography variant="body2">Hora de la apertura formato 24hrs (Ejemplo: 07:30)</Typography>
          <Stack direction={'row'} spacing={1}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
              localeText={{
                timePicker24Hour: '24 horas',
                okButtonLabel: 'Aceptar',
                cancelButtonLabel: 'Cancelar',
              }}
            >
              <TimePicker
                label={'Hora apertura'}
                value={formValue.hour}
                onChange={(newValue) => setFormValue({ ...formValue, hour: newValue })}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            <IconButton onClick={() => setFormValue({ ...formValue, hour: '' })}>
              <Icon>delete</Icon>
            </IconButton>
          </Stack>
          <TextField
            label="*Número de funcionarios presentes"
            name="officials"
            value={formValue.officials}
            error={!formValue.officials}
            helperText={!formValue.officials && 'Campo requerido'}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={handleChangeFormValue}
            fullWidth
          />
          <TextField
            label="*Tomados de la fila"
            name="takenRow"
            value={formValue.takenRow}
            error={!formValue.takenRow}
            helperText={!formValue.takenRow && 'Campo requerido'}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={handleChangeFormValue}
            fullWidth
          />
          <WebCam image={image} setImage={setImage} />
          <Button
            variant="contained"
            onClick={handleAperturaCasilla}
            disabled={loading || !formValue.officials || !formValue.takenRow}
          >
            {loading ? 'Guardando...' : 'Aperturó la casilla'}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default ScreenAperturaCasilla;
