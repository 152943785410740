import { PrivilegedRoute, ProtectedRoute } from '@middlewares';

import Eventos from '@pages/Events/Eventos';
import PreguntasPost from '@pages/Events/PreguntasPost';
import Invitados from '@pages/Events/Invitados';
import InvitadosEvent from '@pages/Events/InvitadosEvent';
import Staff from '@pages/Events/Staff';
import StaffEvent from '@pages/Events/StaffEvent';
import EventosPaseLista from '@pages/Events/EventosPaseLista';
import EventosPaseListaInfo from '@pages/Events/EventosPaseListaInfo';
import Noticias from '@pages/Noticias/Noticias';
import Registro from '@pages/Ppm/Registro';
import UploadInformation from '@pages/Certificados_ppm_foca/UploadInformation';
import Dashboard from '@pages/Events/Dashboard';

import { PAGINA_PPM } from '@data/constants/Noticias';
import { ID_PPM } from '@data/constants/Eventos';

const PpmRoutes = [
  {
    path: '/ppm/eventos',
    element: (
      <PrivilegedRoute>
        <Eventos idTipoEvento={ID_PPM} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/ppm/eventos/preguntas',
    element: (
      <ProtectedRoute>
        <PreguntasPost idTipoEvento={ID_PPM} id="ppm" />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ppm/eventos/invitados',
    element: (
      <PrivilegedRoute>
        <Invitados idTipoEvento={ID_PPM} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/ppm/eventos/invitados/:id',
    element: (
      <ProtectedRoute>
        <InvitadosEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ppm/eventos/staff',
    element: (
      <PrivilegedRoute>
        <Staff idTipoEvento={ID_PPM} id="ppm" />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/ppm/eventos/staff/:id',
    element: (
      <ProtectedRoute>
        <StaffEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ppm/eventos/pase-lista',
    element: (
      <PrivilegedRoute>
        <EventosPaseLista idTipoEvento={ID_PPM} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/ppm/eventos/pase-lista/:id',
    element: (
      <ProtectedRoute>
        <EventosPaseListaInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ppm/noticias',
    element: (
      <PrivilegedRoute>
        <Noticias page={PAGINA_PPM} />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/ppm/registro',
    element: (
      <PrivilegedRoute>
        <Registro />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/reconocimientos/upload',
    element: (
      <ProtectedRoute>
        <UploadInformation />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ppm/eventos/dashboard/:id',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
];

export default PpmRoutes;
