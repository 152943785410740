import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import { Container, CardContent, Chip, Button, Icon, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/MapsModules/Routes/FilterList';
import AssignRG from '@components/Electoral/AssignRGs';
import BasicTable from '@components/Tables/BasicTable';
import CardStats from '@components/Card/CardStats';
import Card from '@components/Card/Card';
// import AssignRepresentative from "@components/Electoral/AssignRepresentative";

// Middleware y Utilidades
import middleware from '@middlewares/middleware';
import { isNullOrUndefined, isEmptyOrNullObject } from '@utils/validations';

// Servicios
import ElectoralServices from '@services/ElectoralServices';
import RouteServices from '@services/MapServices/RouteServices';

const ListProgress = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCharts, setLoadingChart] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [chartData, setChartData] = useState({
    Total: 0,
    TotalSinRuta: 0,
    TotalConRuta: 0,
    TotalRuta: 0,
  });
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [paramsChart, setParamsChart] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [openAssing, setOpenAssing] = useState(false);
  // const [openAssing2, setOpenAssing2] = useState(false); // hasta que quiera editar
  const [dataRuta, setDataRuta] = useState({});
  const [filtrosModal, setFiltrosModal] = useState({
    idRegion: 0,
    idMunicipio: 0,
    Seccion: 0,
  });

  var today = new Date();

  const [columns, setColumns] = useState([
    { id: 'Region', label: 'Región' },
    { id: 'MunicipioReportes', label: 'Municipio' },
    { id: 'Ruta', label: 'Ruta' },
    { id: 'Seccion', label: 'Sección' },
    { id: 'NombreCasilla', label: 'Casilla' },
  ]);

  /* 
  .map((item) => {
              return {
                ...item,
                Segmento: (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                    sx={{display:"contents"}}
                  >
                    {item.Segmento.split(",").map((i) => (
                      <Chip label={i} color="primary" variant="outlined" size="small" />
                    ))}
                  </Stack>
                ),
                Ranking: (
                  <Rating
                    name="text-feedback"
                    value={item.Ranking/10}
                    readOnly
                    precision={0.5}       
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                ),
              };
            })
  */

  const handleDelete = async (data) => {
    let nombre = '';
    let idUser = '';

    switch (data.idTipo) {
      case 0: // RG
        nombre = data.NombreRG;
        break;
      case 1: // Abogado
        nombre = data.NombreAbogado;
        break;
      case 2: // Propietario 1
      case 3: // Propietario 2
      case 4: // Suplente 1
      case 5: // Suplente 2
        idUser = data?.label?.split('-')[0].trim();
        nombre = data?.label?.split('-')[1].trim();
        break;
      default:
        break;
    }

    Swal.fire({
      title: 'Eliminar',
      html: `<p>Esta Seguro de Eliminar la Ruta ${data.ruta} al ${data.tipo} ${nombre}</p>`,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: `Continuar`,
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      preConfirm: async () => {
        try {
          const newData = { idRuta: data.idRuta };
          let response = {};

          switch (data.idTipo) {
            case 0: // RG
              response = await ElectoralServices.deleteRGRuta(newData);
              break;
            case 1: // Abogado
              response = await ElectoralServices.deleteAbogadoRuta(newData);
              break;
            case 2: // Propietario 1
            case 3: // Propietario 2
            case 4: // Suplente 1
            case 5: // Suplente 2
              response = { results: true, message: `Propietario 1 TEST ${idUser}` };
              break;
            default:
              break;
          }

          const { results } = response;
          if (!results) {
            return Swal.showValidationMessage(`${JSON.stringify(response)}`);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.results) {
          Swal.fire({ title: result.value.message, icon: 'success', timer: 1500, showConfirmButton: false });
          getList();
        }
      }
    });

    /*     const res = await Swal.fire({
      title: "¡Advertencia!",
      html: `<p>Esta Seguro de Eliminar la Ruta ${data.ruta} al ${data.tipo} ${data.idTipo === 0 ? data.NombreRG : data.NombreAbogado}</p>`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Continuar`,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      customClass: {
        container: "modal-alert",
      },
    });

    if (res.isConfirmed) {
    //  setIsLoadingForm(true);
      let newData = {
        idRuta:data.idRuta
      };

      try {
        const result = await ElectoralServices.deleteRGRuta(newData);
        const { results, message } = result;
        if (results) {
          Swal.fire({ title: message, icon: "warning" });
        } else {
          Swal.fire({ title: message, icon: "warning" });
        }
      } catch (error) {
        Swal.fire({ title: error.message, icon: "warning" });
      }finally{
        getList();
      }
    } */
  };

  const getRC = (props) => {
    const { item, label, number, array = [], tipo, idPropietario, idTipo } = props;

    const Lnumber = item[number] ?? 0;
    const Llabel = item[label] ?? '';

    const send = { ...item, tipo, idPropietario, idTipo, ruta: item.Ruta };

    const onDelete = (label) => {
      return {}; // { onDelete: () => handleDelete({ ...send, label: label }) }
    };

    return middleware.checkMenuAction('Autorizar') ? (
      Lnumber !== 0 && !isNullOrUndefined(Lnumber) ? (
        array.length > 1 ? (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <Chip color="primary" size="small" label={Lnumber} />
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {array.map((subItem, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  {...onDelete(subItem)}
                  size="small"
                  label={`${subItem}`}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <Chip variant="outlined" {...onDelete(Llabel)} size="small" label={`${Llabel}`} />
        )
      ) : false /* middleware.checkMenuAction("Editar") */ ? (
        // No se utiliza (hasta que quieran agregar el editar)
        <Button
          variant="contained"
          size="small"
          sx={{ bgcolor: '#f73378' }}
          onClick={() => OnClickHandle(send)}
          endIcon={<Icon>group_add</Icon>}
        >
          Asignar
        </Button>
      ) : (
        ''
      )
    ) : Lnumber !== 0 ? (
      Llabel
    ) : (
      ''
    );
  };

  const getRG_AB = (props) => {
    const { item, tipo, idTipo } = props;

    const usuario = item[`idUsuario${tipo}`] ?? null;
    const nombre = item[`Nombre${tipo}`] ?? '';
    const celular = item[`Celular${tipo}`] ?? '';

    const send = { ...item, tipo, idTipo, ruta: item.Ruta };

    return middleware.checkMenuAction('Autorizar') ? (
      !isNullOrUndefined(item.idRuta) ? (
        !isNullOrUndefined(usuario) ? (
          <Chip
            variant="outlined"
            onDelete={() => handleDelete(send)}
            size="small"
            label={`${celular} - ${nombre}`}
          />
        ) : (
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: '#f73378' }}
            onClick={() => OnClickHandle(send)}
            endIcon={<Icon>group_add</Icon>}
          >
            Asignar
          </Button>
        )
      ) : (
        'SIN RUTA'
      )
    ) : !isNullOrUndefined(item.idRuta) ? (
      !isNullOrUndefined(usuario) ? (
        `${celular} - ${nombre}`
      ) : (
        ''
      )
    ) : (
      ''
    );
  };

  const getList = () => {
    RouteServices.ListProgress(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              const RCP1 = item?.RCP1 ? item.RCP1?.split(',').map((i) => i.trim()) : [];
              const RCS1 = item?.RCS1 ? item.RCS1?.split(',').map((i) => i.trim()) : [];
              const RCP2 = item?.RCP2 ? item.RCP2?.split(',').map((i) => i.trim()) : [];
              const RCS2 = item?.RCS2 ? item.RCS2?.split(',').map((i) => i.trim()) : [];

              return {
                ...item,
                Ruta: (
                  <Chip
                    label={item.Ruta ? item.Ruta : 'Sin Ruta'}
                    variant="outlined"
                    color={item.Ruta ? 'success' : 'error'}
                    size="small"
                    sx={{
                      color: item.Ruta ? 'green' : 'red',
                      fontWeight: item.Ruta ? '700' : '0',
                      width: '100px',
                    }}
                  />
                ),

                RG: getRG_AB({ item, tipo: 'RG', idTipo: 0 }),

                Abogado: getRG_AB({ item, tipo: 'Abogado', idTipo: 1 }),

                RCP1: getRC({
                  item,
                  label: 'RCP1',
                  number: 'RCSP1',
                  array: RCP1,
                  tipo: 'Propietario 1',
                  idPropietario: 1,
                  idTipo: 2,
                }),

                RCS1: getRC({
                  item,
                  label: 'RCS1',
                  number: 'RCSS1',
                  array: RCS1,
                  tipo: 'Suplente 1',
                  idPropietario: 2,
                  idTipo: 3,
                }),

                RCP2: getRC({
                  item,
                  label: 'RCP2',
                  number: 'RCSP2',
                  array: RCP2,
                  tipo: 'Propietario 2',
                  idPropietario: 3,
                  idTipo: 4,
                }),

                RCS2: getRC({
                  item,
                  label: 'RCS2',
                  number: 'RCSS2',
                  array: RCS2,
                  tipo: 'Suplente 2',
                  idPropietario: 4,
                  idTipo: 5,
                }),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const getTotalCompromiso = () => {
    RouteServices.TotalsProgress(paramsChart)
      .then((res) => {
        if (res.results) {
          setChartData(res.response.data[0]);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      let data = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(data);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    setLoading(true);
    setLoadingChart(true);
    /*   if (middleware.checkMenuAction("Autorizar")) { */
    setColumns([
      { id: 'Region', label: 'Región' },
      { id: 'MunicipioReportes', label: 'Municipio' },
      { id: 'Ruta', label: 'Ruta' },
      { id: 'Seccion', label: 'Sección' },
      { id: 'NombreCasilla', label: 'Casilla' },
      { id: 'RG', label: 'RG' },
      { id: 'Abogado', label: 'Abogado' },
      { id: 'RCP1', label: 'Propietario 1' },
      { id: 'RCS1', label: 'Suplente 1' },
      { id: 'RCP2', label: 'Propietario 2' },
      { id: 'RCS2', label: 'Suplente 2' },
    ]);
    /*     } */
    getList();
    getTotalCompromiso();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Seccion, Ruta, RutaId, RG, Abogado, P1, P2, S1, S2 } = type;

    let filtered = [];
    let filteredChart = [];

    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filteredChart = [...filteredChart, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idMunicipioReportes', filter: '=', value: Municipio }];

      filteredChart = [
        ...filteredChart,
        { id: 'cat_secciones.idMunicipioReportes', filter: '=', value: Municipio },
      ];
    }
    if (RutaId > 0) {
      filtered = [...filtered, { id: 'rutas.id', filter: '=', value: RutaId }];
    }
    if (Seccion.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: 'IN', value: Seccion }];
    }
    if (Ruta > 0) {
      filtered = [
        ...filtered,
        { id: 'rutas_casillas.idRuta', filter: Ruta === 1 ? 'IS' : 'IS NOT', value: 'NULL' },
      ];
    }
    if (RG > 0) {
      filtered = [
        ...filtered,
        { id: 'rutas.idUsuarioRG', filter: RG === 1 ? 'IS' : 'IS NOT', value: 'NULL' },
      ];
    }
    if (Abogado > 0) {
      filtered = [
        ...filtered,
        { id: 'rutas.idUsuarioAbogado', filter: Abogado === 1 ? 'IS' : 'IS NOT', value: 'NULL' },
      ];
    }

    if (P1 > 0) {
      if (P1 === 2) {
        filtered = [...filtered, { id: 'p1.RCSP1', filter: 'IS NOT', value: 'NULL' }];
      } else if (P1 === 1) {
        filtered = [...filtered, { id: 'p1.RCSP1', filter: 'IS', value: 'NULL' }];
      } else {
        filtered = [...filtered, { id: 'p1.RCSP1', filter: '>', value: '1' }];
      }
    }
    if (P2 > 0) {
      if (P2 === 2) {
        filtered = [...filtered, { id: 'p2.RCSP2', filter: 'IS NOT', value: 'NULL' }];
      } else if (P2 === 1) {
        filtered = [...filtered, { id: 'p2.RCSP2', filter: 'IS', value: 'NULL' }];
      } else {
        filtered = [...filtered, { id: 'p2.RCSP2', filter: '>', value: '1' }];
      }
    }
    if (S1 > 0) {
      if (S1 === 2) {
        filtered = [...filtered, { id: 's1.RCSS1', filter: 'IS NOT', value: 'NULL' }];
      } else if (S1 === 1) {
        filtered = [...filtered, { id: 's1.RCSS1', filter: 'IS', value: 'NULL' }];
      } else {
        filtered = [...filtered, { id: 's1.RCSS1', filter: '>', value: '1' }];
      }
    }
    if (S2 > 0) {
      if (S2 === 2) {
        filtered = [...filtered, { id: 's2.RCSS2', filter: 'IS NOT', value: 'NULL' }];
      } else if (S2 === 1) {
        filtered = [...filtered, { id: 's2.RCSS2', filter: 'IS', value: 'NULL' }];
      } else {
        filtered = [...filtered, { id: 's2.RCSS2', filter: '>', value: '1' }];
      }
    }

    setFiltrosModal({
      idRegion: Region,
      idMunicipio: Municipio,
      Seccion: Seccion,
    });

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    setParamsChart({
      ...paramsChart,
      filtered: filteredChart,
    });
  };

  const OnClickHandle = (info) => {
    /* if (info.idPropietario) {
      // No se utiliza (hasta que quieran agregar el editar)
      setOpenAssing2(true);
      setDataRuta({ ...info, tipo: info.tipo, casilla: info.NombreCasilla, seccion: info.Seccion });
    } else {
      // lo que esta afuera
    } */

    setOpenAssing(true);
    setDataRuta({ ...info });
  };

  const setOpenModal = (e) => {
    getList();
    setOpenAssing(e);
    // setOpenAssing2(e); hasta editar
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Region: 'cat_municipios_reportes.idRegion',

      MunicipioReportes: 'cat_municipios_reportes.Municipio',
      Ruta: 'rutas.Rutas',
      Seccion: 'cat_secciones.Seccion',
      NombreCasilla: 'cat_social.NombreSocial',
      RG: 'rg.Nombre',
      Abogado: 'abogado.Nombre',
      RCP1: 'p1.RCP1',
      RCS1: 's1.RCS1',
      RCP2: 'p2.RCP2',
      RCS2: 's2.RCS2',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Listado Rutas" handleclick={() => navigate('/lista-rutas')} />
        {openAssing && (
          <AssignRG open={openAssing} title={dataRuta} filtro={filtrosModal} setOpen={setOpenModal} />
        )}
        {/* No se utiliza (hasta que quieran agregar el editar) 
        {openAssing2 && <AssignRepresentative open={openAssing2} title={dataRuta} setOpen={setOpenModal} />}*/}
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={'list_alt'}
              title={'Casillas'}
              total={chartData.Total}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={'playlist_remove'}
              title={'Casillas sin ruta'}
              total={chartData.TotalSinRuta}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={'playlist_add_check'}
              title={'Casillas con rutas'}
              total={chartData.TotalConRuta}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <CardStats
              icon={'roundabout_right'}
              title={'Rutas'}
              total={chartData.TotalRuta}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            <Filter onChangeFilter={handleChangeFilter} />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            {/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )} */}

            {/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
            */}
            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tableData.page}
              pageSize={tableData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default ListProgress;
