import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { useNavigate } from 'react-router-dom';
import BasicTable from '@components/Tables/BasicTable';
import Tree from '@components/TreeView/TreeView';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  IconButton,
  Typography,
  Stack,
  Icon,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import SocialService from '@services/SocialServices';
import { Search, WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import { isTypePhone } from '@utils/validations';

const MovilizacionSocial = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [loadingCard, setLoadingCard] = useState(false);
  const [tree, setTree] = useState([]);
  const [loadingTree, setLoadingTree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({
    filtered: [],
  });
  const [id, setId] = useState(0);
  const [dataCard, setDataCard] = useState({
    Total: 0,
    Avance: 0,
    PorcentajeAvance: 0,
    Pendiente: 0,
    PorcentajePendiente: 0,
    Contactado: 0,
    PorcentajeContactado: 0,
    NoLocalizado: 0,
    PorcentajeNoLocalizado: 0,
  });
  const [type, setType] = useState('1');
  const columns = [
    { id: 'EstatusMovilizacion', label: '' },
    { id: 'Celular', label: 'Celular' },
    { id: 'Nombre', label: 'Nombre' },
    { id: 'Seccion', label: 'Sección' },
    { id: 'Colonia', label: 'Colonia' },
  ];

  useEffect(() => {
    getStructs();
  }, [params]);

  const getStructs = () => {
    setLoadingTree(true);
    SocialService.getMiSocial(params)
      .then((res) => {
        if (res.results) {
          setTree(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoadingTree(false));
  };

  const handleItemTree = (e) => {
    setId(e);
    getCards(e.idUsuario, type);
  };

  const getCards = async (id, type = 1) => {
    try {
      setLoadingCard(true);

      const result = await SocialService.getCardsContactEstatusMiResponsable({
        idUsuario: id,
        type: type,
      });
      const { results, response, message } = result;

      if (results) setDataCard(response);
      else throw new Error(message);
    } catch (e) {
      setDataCard({});
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingCard(false);
    }
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/52${phoneNumber}`;
    if (isTypePhone(phoneNumber)) window.open(whatsappUrl, '_blank');
    else
      Swal.fire({
        title: 'Numero invalido',
        text: `"${phoneNumber}" no es un número válido`,
        icon: 'warning',
      });
  };

  const handleSearch = () => {
    setTree([]);
    if (search && search !== '') {
      setParams({
        ...params,
        filtered: [
          {
            id: "CONCAT_WS('%',NombreCompleto,Celular)",
            value: `%${search.toUpperCase().replace(/ /g, '%')}%`,
            filter: 'LIKE',
          },
        ],
      });
    } else {
      setParams({ ...params, filtered: [] });
    }
  };

  const handleClearSearch = () => {
    setParams({ ...params, filtered: [] });
  };

  const handleChange = (e) => {
    setType(e.target.value);
    getCards(id.idUsuario, e.target.value);
  };

  return (
    <AdminLayout>
      <ActionHeader title="Responsables Movilización" handleclick={() => navigate('/movilizacion-social')} />
      <Grid2 container spacing={2}>
        <Grid2 item xs={4}>
          <Card sx={{ height: '800px', overflow: 'auto' }}>
            <CardContent>
              <Box marginBottom={'1rem'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1} marginBottom={'0.5rem'}>
                  <Icon>person_search</Icon>
                  <Typography fontWeight={600} variant={'body2'} marginBottom={'0.5rem'}>
                    Buscar Responsable
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Nombre/Celular"
                    size="small"
                    name="phone"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    // onKeyUp={handleOnKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <Icon color="error">clear</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '100%' }}
                  />

                  <Button
                    variant="contained"
                    color="primaryDark"
                    sx={{ width: '25%' }}
                    onClick={handleSearch}
                  >
                    Buscar
                  </Button>
                </Stack>
              </Box>
              <Tree
                data={tree}
                defaultExpanded={tree[0]}
                typeData={{
                  value: 'idUsuario',
                  label: 'NombreCompleto',
                  S_label: null,
                  T_label: 'Celular',
                }}
                handleClick={handleItemTree}
                loading={loadingTree}
              />
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 item xs={8}>
          <Card>
            <CardContent>
              <Grid2 container spacing={2}>
                {/*            <Grid2 item xs={12} sm={6} md={12}>
              <AdaptiveCard
                title="Participación"
                icon="people"
                config={{ percent: dataCard?.PorcentajeAvance || 0 }}
                leftConfig={{ title: "Avance", data: dataCard?.Avance || 0 }}
                rightConfig={{ title: "Total", data: dataCard?.Total || 0 }}
                fixed
                adjust
                loading={loadingCard}
              />
            </Grid2> */}
                {id ? (
                  <Grid2 item xs={12} sm={12} md={12}>
                    <Typography>{id.NombreCompleto}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pl: 1,
                          pb: 1,
                        }}
                      >
                        <IconButton aria-label="play/pause" onClick={() => redirectToWhatsApp(id.Celular)}>
                          <WhatsAppIcon
                            sx={{
                              height: 38,
                              width: 38,
                              color: '#89D580',
                              mr: 1,
                            }}
                          />
                          <Typography variant="subtitle1">{id.Celular}</Typography>
                        </IconButton>
                      </Box>
                    </Box>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Movilizados</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={type}
                        onChange={handleChange}
                      >
                        <FormControlLabel value={1} control={<Radio />} label="Directos" />
                        <FormControlLabel value={2} control={<Radio />} label="En Red" />
                      </RadioGroup>
                    </FormControl>
                  </Grid2>
                ) : (
                  <></>
                )}
                {id ? (
                  <Grid2 item xs={12} sm={12} md={12}>
                    <AdaptiveCard
                      config={{
                        title: 'Contactados',
                        data: dataCard?.Contactado || 0,
                        percent: dataCard?.PorcentajeContactado || 0,
                      }}
                      leftConfig={{
                        title: 'Pendientes',
                        data: dataCard?.Pendiente || 0,
                        percent: dataCard?.PorcentajePendiente || 0,
                      }}
                      rightConfig={{
                        title: 'No localizados',
                        data: dataCard?.NoLocalizado || 0,
                        percent: dataCard?.PorcentajeNoLocalizado || 0,
                      }}
                      fixed
                      adjust
                      loading={loadingCard}
                    />
                  </Grid2>
                ) : (
                  <></>
                )}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </AdminLayout>
  );
};
export default MovilizacionSocial;
