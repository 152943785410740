import { useState, useEffect, useRef } from 'react';
import esLocale from 'date-fns/locale/es';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import {
  Chip,
  Icon,
  TextField,
  IconButton,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  FormHelperText,
} from '@mui/material';
import { purple, red } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import PreguntasEventos from '@components/Events/PreguntasEventos';
import AutoComplete from '@components/Selects/Autocomplete';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomModal from '@components/Modal/CustomModal';
import DragMap from '@components/Maps/Components/DragMap';

// Utils
import { isEmptyOrNullObject, isNullOrUndefined } from '@utils/validations';
import { handleAttrs, formatDateAPI } from '@utils/Utilities';

// Servicios
import CatalogService from '@services/CatalogServices';
import EventsServices from '@services/EventsServices';

// Data
import { EventoEditInterface, EventoInterface } from '@interfaces/EventosInterfaces';
import { EventosEditSchema, EventosSchema } from '@schemas/EventosSchemas';

const EventForm = (props) => {
  const {
    fechaCalendario = '',
    handleAction = () => {},
    type = 'create',
    setType = () => {},
    data,
    getEvents,
    handleDelete = () => {},
    catalogsOut,
    isLoadingCat,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EventoEditInterface : EventoInterface,
    validationSchema: isEdit ? EventosEditSchema : EventosSchema,
    onSubmit: (values) => {
      if (isEdit) handleEditEvento(handleAttrs(values));
      else handleCreateEvento(handleAttrs(values));
    },
  });

  const fileInputRef = useRef(null);
  const fileInputMovilRef = useRef(null);
  const [catColonias, setCatColonias] = useState([]);
  const [isLoadingColonias, setIsLoadingColonias] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando evento...');
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const [catalogs, setCatalogs] = useState({});

  const [open, setOpen] = useState(false);
  const [coordsMap, setCoordsMap] = useState(null);

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const params = {
        idEvento: data.id,
        idEstatus: data.idEstatus,

        imgURL: data.imagen ? 'Imagen.jpg' : '',
        imgMovilURL: data.imagenMovil ? 'Imagen_movil.jpg' : '',
        files: [],

        idPartido: data.idPartido, // No se usa en front
        idAmbito: data.idAmbito,
        AmbitoValor: data.AmbitoValor,

        idTipoActividad: data.idTipoActividad,
        Actividad: data.Actividad,
        Descripcion: data.Descripcion ?? '',
        FechaInicio: data.FechaInicio,
        FechaFin: data.FechaFin,

        idModalidad: data.idModalidad,
        NombreLugar: data.NombreLugar ?? '',
        Calle: data.Calle ?? '',
        NumExt: data.NumExt ?? '',
        NumInt: data.NumInt ?? '',
        Colonia: data.Colonia ?? '',
        idMunicipio: data.idMunicipio ?? 0,
        LigaVirtual: data.LigaVirtual ?? '',

        TienePreregistro: data.TienePreregistro,
        FechaFinPreregistro: data.FechaFinPreregistro,

        tienePreguntas: false,
        preguntas: [],

        coordenadas: { latitud: data.latitud ?? null, longitud: data.longitud ?? null },
      };

      getQuestions(data.id, params);
    } // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!isLoadingCat && !isEmptyOrNullObject(catalogsOut) && isEmptyOrNullObject(catalogs)) {
      const municipios = catalogsOut.municipios.filter((item) => item.value !== 0);
      const tipo_actividad_evento = catalogsOut.tipo_actividad_evento.filter((item) => item.value !== 0);
      const regiones = catalogsOut.regiones.filter((item) => item.value !== 0);

      const cat = { ...catalogsOut, municipios, tipo_actividad_evento, regiones };
      setCatalogs(cat);
      loadCatalogsByMunicipio({ target: { value: EventoInterface.idMunicipio } });
      if (!isEdit)
        handleChangeTipoActividad({
          target: { value: tipo_actividad_evento[0]?.value ?? EventoInterface.idTipoActividad },
          cat,
        });
    } // eslint-disable-next-line
  }, [isLoadingCat, catalogs]);

  const getQuestions = async (idEvento, params) => {
    try {
      setIsLoadingQuestions(true);

      const localParams = {
        page: 0,
        pageSize: 10,
        filtered: [
          { id: 'idEvento', filter: '=', value: idEvento },
          { id: 'idTipoPregunta', filter: '=', value: 1 },
        ],
      };

      const result = await EventsServices.getQuestions(localParams);
      const { results, response, message } = result;

      if (results) {
        const tienePreguntas = response.total > 0;
        formik.setValues({
          ...params,
          tienePreguntas,
          preguntas: response.data,
          canEditPreguntas: response.canEditPR,
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    const uploadedFile = e.target.files[0];
    const dataNew = name === 'imgURL' ? 'web' : 'app';

    e.target.value = null;

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        const filesNew = formik.values.files.filter((item) => item.classificationId !== dataNew);
        filesNew.push({
          classificationId: dataNew,
          file: base64String,
          type: uploadedFile.type,
          size: uploadedFile.size,
        });

        formik.setValues({ ...formik.values, files: filesNew, [name]: uploadedFile?.name });
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleClick = () => fileInputRef.current.click();

  const handleClickMovil = () => fileInputMovilRef.current.click();

  const handleEditEvento = async (val) => {
    const {
      imgURL,
      imgMovilURL,
      NumInt,
      FechaInicio,
      FechaFin,
      FechaFinPreregistro,
      tienePreguntas,
      preguntas,
      canEditPreguntas,
      ...otros
    } = val;

    const params = {
      ...otros,
      tienePreguntas: val.TienePreregistro ? tienePreguntas : false,
      preguntas: val.TienePreregistro ? preguntas : [],
      NumInt: NumInt ?? '',
      FechaInicio: formatDateAPI(FechaInicio),
      FechaFin: formatDateAPI(FechaFin),
      FechaFinPreregistro: val.TienePreregistro
        ? formatDateAPI(FechaFinPreregistro)
        : formatDateAPI(FechaFin),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando evento...');

      const result = await EventsServices.updateEvent(params);
      const { success, results, message } = result;

      if (success && results) {
        getEvents();
        handleAction(false);
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateEvento = async (val) => {
    const {
      imgURL,
      imgMovilURL,
      NumInt,
      FechaInicio,
      FechaFin,
      FechaFinPreregistro,
      tienePreguntas,
      preguntas,
      canEditPreguntas,
      ...otros
    } = val;

    const params = {
      ...otros,
      tienePreguntas: val.TienePreregistro ? tienePreguntas : false,
      preguntas: val.TienePreregistro ? preguntas : [],
      NumInt: NumInt ?? '',
      FechaInicio: formatDateAPI(FechaInicio),
      FechaFin: formatDateAPI(FechaFin),
      FechaFinPreregistro: val.TienePreregistro
        ? formatDateAPI(FechaFinPreregistro)
        : formatDateAPI(FechaFin),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando evento...');

      const result = await EventsServices.setEvent(params);
      const { success, results, message } = result;

      if (success && results) {
        getEvents();
        handleAction(false);
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTipoActividad = (e) => {
    const value = e.target.value;
    const cat = e?.cat ?? catalogs;
    const valueAct = cat.tipo_actividad_evento.find((item) => item.value === value)?.label ?? '';

    if (formik.values.FechaInicio === '' && fechaCalendario?.start) {
      const date = fechaCalendario.start;

      formik.setValues({
        ...formik.values,
        idTipoActividad: value,
        Actividad: valueAct,
        FechaInicio: isNullOrUndefined(date) ? '' : date,
        FechaFin: isNullOrUndefined(date) ? '' : date,
      });
    } else formik.setValues({ ...formik.values, idTipoActividad: value, Actividad: valueAct });
  };

  const handleChangeAmbito = (e) => {
    const value = e.target.value;

    //  Actividad: `${formik.values.Actividad} - ${item.label}`,
    switch (value) {
      case 2:
        const itemM = catalogs.municipios[0];
        formik.setValues({ ...formik.values, idAmbito: value, AmbitoValor: itemM?.value });
        break;
      case 5:
        const itemR = catalogs.regiones[0];
        formik.setValues({ ...formik.values, idAmbito: value, AmbitoValor: itemR?.value });
        break;
      default:
        formik.setValues({ ...formik.values, idAmbito: value, AmbitoValor: 1 });
        break;
    }
  };

  const handleChangeAmbitoValor = (e, type) => {
    const value = e.target.value;

    if (type === 2) {
      const item = catalogs.municipios.find((item) => item.value === value).label;
      formik.setValues({
        ...formik.values,
        Actividad: `${formik.values.Actividad} - ${item}`,
        AmbitoValor: value,
      });
    } else {
      const item = catalogs.regiones.find((item) => item.value === value).label;
      formik.setValues({
        ...formik.values,
        Actividad: `${formik.values.Actividad} - ${item}`,
        AmbitoValor: value,
      });
    }
  };

  const handleCheck = (value) => {
    if (value) {
      formik.setValues({ ...formik.values, esConcurrente: value });
    } else {
      formik.setValues({
        ...formik.values,
        esConcurrente: value,
        FechaFin: formik.values.FechaInicio,
        diasConcurrentes: [],
      });
    }
  };

  const handleCheckDias = (value, dia) => {
    if (dia === 8) {
      if (value) {
        formik.setValues({ ...formik.values, diasConcurrentes: [] });
      } else {
        formik.setValues({ ...formik.values, diasConcurrentes: [1, 2, 3, 4, 5, 6, 7] });
      }
    } else {
      if (value) {
        formik.setValues({
          ...formik.values,
          diasConcurrentes: formik.values.diasConcurrentes.filter((item) => item !== dia),
        });
      } else {
        formik.setValues({ ...formik.values, diasConcurrentes: [...formik.values.diasConcurrentes, dia] });
      }
    }
  };

  const handleChangeDia = (e) => {
    if (!formik.values.esConcurrente) {
      formik.setValues({
        ...formik.values,
        FechaInicio: isNullOrUndefined(e) ? '' : e,
        FechaFin: isNullOrUndefined(e) ? '' : e,
      });
    } else {
      formik.setValues({
        ...formik.values,
        FechaInicio: isNullOrUndefined(e) ? '' : e,
      });
    }
  };

  const loadCatalogsByMunicipio = async (e) => {
    const value = e.target.value;

    formik.setValues({
      ...formik.values,
      idMunicipio: value,
      Colonia: '',
    });

    const catParams = [{ id: 'colonias', filtered: [{ id: 'idMunicipio', filter: '=', value: value }] }];

    try {
      setIsLoadingColonias(true);
      const result = await CatalogService.getCatalogs(catParams);
      const { success, results, response, message } = result;

      if (success && results) setCatColonias(response.catalogs.colonias);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoadingColonias(false);
    }
  };

  const handleChangeColonia = (e) => formik.setValues({ ...formik.values, Colonia: e.label });

  const handleChangeCoords = () => {
    formik.setValues({ ...formik.values, coordenadas: coordsMap });
    setOpen(false);
  };

  const isError = (value) => formik.touched[value] && Boolean(formik.errors[[value]]);

  const getModalidad = (value) => {
    if (Array.isArray(value)) return value.includes(formik.values.idModalidad);
    return formik.values.idModalidad === value;
  };

  const loadAll = isLoadingCat || isLoadingQuestions;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage={'Evento creado con exito!'}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Chip icon={<Icon>today</Icon>} label="Registro de Evento" />
        </Grid2>

        <Grid2
          xs={12}
          sm={12}
          md={[2, 5].includes(formik.values.idAmbito) ? 4 : 6}
          lg={[2, 5].includes(formik.values.idAmbito) ? 4 : 6}
        >
          <BasicSelect
            name="idTipoActividad"
            label="Tipo Actividad"
            options={catalogs.tipo_actividad_evento}
            value={formik.values.idTipoActividad}
            onChange={handleChangeTipoActividad}
            size="small"
            sx={{ width: '100%' }}
            disabled={loadAll}
            isLoading={loadAll}
            error={isError('idTipoActividad')}
            errorMessage={formik.errors.idTipoActividad}
          />
        </Grid2>

        <Grid2
          xs={12}
          sm={[2, 5].includes(formik.values.idAmbito) ? 6 : 12}
          md={[2, 5].includes(formik.values.idAmbito) ? 4 : 6}
          lg={[2, 5].includes(formik.values.idAmbito) ? 4 : 6}
        >
          <BasicSelect
            name="idAmbito"
            label="Ambito"
            options={catalogs.ambito_evento}
            value={formik.values.idAmbito}
            onChange={handleChangeAmbito}
            size="small"
            sx={{ width: '100%' }}
            disabled={loadAll}
            isLoading={loadAll}
            error={isError('idAmbito')}
            errorMessage={formik.errors.idAmbito}
          />
        </Grid2>

        {formik.values.idAmbito === 2 && (
          <Grid2 xs={12} sm={6} md={4} lg={4}>
            <BasicSelect
              name="AmbitoValor"
              label="Región"
              options={catalogs.regiones}
              value={formik.values.AmbitoValor}
              onChange={(e) => handleChangeAmbitoValor(e, 1)}
              size="small"
              sx={{ width: '100%' }}
              disabled={loadAll}
              isLoading={loadAll}
              error={isError('AmbitoValor')}
              errorMessage={formik.errors.AmbitoValor}
            />
          </Grid2>
        )}

        {formik.values.idAmbito === 5 && (
          <Grid2 xs={12} sm={6} md={4} lg={4}>
            <BasicSelect
              name="AmbitoValor"
              label="Municipio"
              options={catalogs.municipios}
              value={formik.values.AmbitoValor}
              onChange={(e) => handleChangeAmbitoValor(e, 2)}
              size="small"
              sx={{ width: '100%' }}
              disabled={loadAll}
              isLoading={loadAll}
              error={isError('AmbitoValor')}
              errorMessage={formik.errors.AmbitoValor}
            />
          </Grid2>
        )}

        <Grid2 xs={12} sm={12} md={6} lg={6}>
          <TextField
            required
            label="Nombre Evento"
            size="small"
            name="Actividad"
            fullWidth
            value={formik.values.Actividad}
            onChange={formik.handleChange}
            disabled={loadAll}
            error={isError('Actividad')}
            helperText={formik.errors.Actividad}
          />
        </Grid2>

        <Grid2 xs={12} sm={12} md={6} lg={6}>
          <TextField
            required
            label="Descripción Evento"
            size="small"
            name="Descripcion"
            fullWidth
            multiline
            value={formik.values.Descripcion}
            onChange={formik.handleChange}
            disabled={loadAll}
            error={isError('Descripcion')}
            helperText={formik.errors.Descripcion}
          />
        </Grid2>

        <Grid2 xs={12} sm={12} md={12} lg={12}>
          <>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} sm={6} md={4.5} lg={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                  <MobileDateTimePicker
                    label="Fecha Evento *"
                    value={formik.values.FechaInicio}
                    onChange={handleChangeDia}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled={loadAll}
                        sx={{ width: '100%' }}
                        error={isError('FechaInicio')}
                        helperText={formik.errors.FechaInicio}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid2>
              {formik.values.esConcurrente && (
                <Grid2 xs={12} sm={6} md={4.5} lg={5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <MobileDateTimePicker
                      label="Fecha Evento Fin *"
                      value={formik.values.FechaFin}
                      onChange={(e) => {
                        formik.setValues({ ...formik.values, FechaFin: isNullOrUndefined(e) ? '' : e });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          disabled={loadAll}
                          sx={{ width: '100%' }}
                          error={isError('FechaFin')}
                          helperText={formik.errors.FechaFin}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid2>
              )}
              {!formik.values.esConcurrente && <Grid2 xs={12} sm={6} md={4.5} lg={5} />}
              {!isEdit && (
                <Grid2 xs={12} sm={6} md={3} lg={2}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ color: purple[400] }}
                      control={
                        <Checkbox
                          checked={formik.values.esConcurrente}
                          onChange={() => handleCheck(!formik.values.esConcurrente)}
                          sx={{ color: purple[400], '&.Mui-checked': { color: purple[300] } }}
                          disabled={loadAll}
                        />
                      }
                      label="Rango Días"
                    />
                  </FormGroup>
                </Grid2>
              )}
            </Grid2>
          </>
        </Grid2>

        {formik.values.esConcurrente && (
          <>
            <Grid2 xs={12}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6} md={6} lg={6}>
                  <FormGroup sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.length > 6}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.length > 6, 8)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="TODOS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(1)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(1), 1)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="LUNES"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(2)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(2), 2)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="MARTES"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(3)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(3), 3)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="MIERCOLES"
                    />
                  </FormGroup>
                </Grid2>

                <Grid2 xs={12} sm={6} md={6} lg={6}>
                  <FormGroup sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(4)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(4), 4)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="JUEVES"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(5)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(5), 5)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="VIERNES"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(6)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(6), 6)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="SABADO"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.diasConcurrentes.includes(7)}
                          onChange={(e) => handleCheckDias(formik.values.diasConcurrentes.includes(7), 7)}
                          sx={{ color: isError('diasConcurrentes') ? red[400] : '' }}
                        />
                      }
                      label="DOMINGO"
                    />
                  </FormGroup>
                </Grid2>

                {Boolean(formik.errors.diasConcurrentes) && (
                  <Grid2 xs={12}>
                    <Typography variant="caption" color={isError('diasConcurrentes') ? 'error' : ''}>
                      {formik.errors.diasConcurrentes}
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
            </Grid2>
          </>
        )}

        {/* <Grid2 xs={12} sm={12} md={4} lg={3}>
                <BasicSelect
                  name="idModoEvento"
                  label="Modalidad"
                  options={catalogs.modo_evento}
                  value={formik.values.idModoEvento}
                  onChange={formik.handleChange}
                  size="small"
                  sx={{ width: "100%" }}
                  disabled={loadAll}
                  isLoading={loadAll}
                  error={isError("idModoEvento")}
                  errorMessage={formik.errors.idModoEvento}
                />
              </Grid2> */}

        <Grid2 xs={12} sm={6} md={4} lg={getModalidad(1) ? 3 : 4}>
          <TextField
            required={Boolean(!formik.values.imgMovilURL)}
            label="Banner Imagen"
            size="small"
            name="webImg"
            fullWidth
            onClick={handleClick}
            onChange={handleClick}
            value={formik.values.imgURL}
            disabled={loadAll}
            error={isError('files')}
            helperText={formik.errors.files}
            InputProps={{
              endAdornment: (
                <IconButton component="span" aria-label="upload file" color="primary">
                  <Icon>upload_file</Icon>
                </IconButton>
              ),
            }}
          />
          <input
            type="file"
            name="imgURL"
            ref={fileInputRef}
            disabled={loadAll}
            accept=".png,.jpg,.jpeg"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Grid2>

        <Grid2 xs={12} sm={6} md={4} lg={getModalidad(1) ? 3 : 4}>
          <TextField
            required={Boolean(!formik.values.imgURL)}
            label="Imagen Movil"
            size="small"
            name="ImgMovil"
            fullWidth
            onClick={handleClickMovil}
            onChange={handleClickMovil}
            value={formik.values.imgMovilURL}
            disabled={loadAll}
            error={isError('files')}
            InputProps={{
              endAdornment: (
                <IconButton component="span" aria-label="upload file" color="primary">
                  <Icon>upload_file</Icon>
                </IconButton>
              ),
            }}
          />
          <input
            type="file"
            ref={fileInputMovilRef}
            disabled={loadAll}
            accept=".png,.jpg,.jpeg"
            style={{ display: 'none' }}
            name="imgMovilURL"
            onChange={handleFileChange}
          />
        </Grid2>

        <Grid2 xs={12} sm={getModalidad([2, 3]) ? 4 : 12} md={4} lg={getModalidad(1) ? 3 : 4}>
          <BasicSelect
            name="idModalidad"
            label="Modalidad"
            options={catalogs.modalidad}
            value={formik.values.idModalidad}
            onChange={formik.handleChange}
            size="small"
            sx={{ width: '100%' }}
            disabled={loadAll}
            isLoading={loadAll}
            error={isError('idModalidad')}
            errorMessage={formik.errors.idModalidad}
          />
        </Grid2>

        {getModalidad([2, 3]) && (
          <Grid2 xs={12} sm={8} md={6} lg={6}>
            <TextField
              required
              label="Liga del Evento"
              size="small"
              name="LigaVirtual"
              fullWidth
              value={formik.values.LigaVirtual}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={isError('LigaVirtual')}
              helperText={formik.errors.LigaVirtual}
            />
          </Grid2>
        )}

        {getModalidad([1, 3]) && (
          <>
            <Grid2 xs={12} sm={6} md={getModalidad([1]) ? 4 : 6} lg={getModalidad([1]) ? 3 : 6}>
              <BasicSelect
                name="idMunicipio"
                label="Municipio"
                options={catalogs.municipios}
                value={formik.values.idMunicipio}
                onChange={loadCatalogsByMunicipio}
                size="small"
                sx={{ width: '100%' }}
                disabled={loadAll}
                isLoading={loadAll}
                error={isError('idMunicipio')}
                errorMessage={formik.errors.idMunicipio}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={4} lg={6}>
              <TextField
                required
                label="Nombre del Lugar"
                size="small"
                name="NombreLugar"
                fullWidth
                value={formik.values.NombreLugar}
                onChange={formik.handleChange}
                disabled={loadAll}
                error={isError('NombreLugar')}
                helperText={formik.errors.NombreLugar}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                label="Calle"
                size="small"
                name="Calle"
                fullWidth
                value={formik.values.Calle}
                onChange={formik.handleChange}
                disabled={loadAll}
                error={isError('Calle')}
                helperText={formik.errors.Calle}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={4} lg={3}>
              <AutoComplete
                required
                label="Colonia"
                name="Colonia"
                autoComplete="Colonia"
                value={
                  formik.values.Colonia &&
                  formik.values.Colonia.length > 0 &&
                  formik.values.Colonia.toUpperCase()
                }
                id="Colonia"
                size="small"
                loading={isLoadingColonias || loadAll}
                disabled={isLoadingColonias || loadAll}
                setSelect={handleChangeColonia}
                data={catColonias}
                onChange={formik.handleChange}
                error={isError('Colonia')}
                helperText={formik.errors.Colonia}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={2} lg={2}>
              <TextField
                required
                label="Num Ext"
                size="small"
                name="NumExt"
                fullWidth
                value={formik.values.NumExt}
                onChange={formik.handleChange}
                disabled={loadAll}
                error={isError('NumExt')}
                helperText={formik.errors.NumExt}
              />
            </Grid2>

            <Grid2 xs={12} sm={6} md={2} lg={2}>
              <TextField
                label="Num Int"
                size="small"
                name="NumInt"
                fullWidth
                value={formik.values.NumInt}
                onChange={formik.handleChange}
                disabled={loadAll}
                error={isError('NumInt')}
                helperText={formik.errors.NumInt}
              />
            </Grid2>
          </>
        )}

        <Grid2
          xs={12}
          sm={formik.values.TienePreregistro === 1 ? 6 : 12}
          md={getModalidad(2) ? (formik.values.TienePreregistro === 1 ? 2 : 6) : 4}
          lg={getModalidad(2) ? (formik.values.TienePreregistro === 1 ? 2 : 6) : 4}
        >
          <BasicSelect
            name="TienePreregistro"
            label="Tiene PreRegistro"
            options={[
              { value: 0, label: 'No' },
              { value: 1, label: 'Si' },
            ]}
            value={formik.values.TienePreregistro}
            onChange={formik.handleChange}
            size="small"
            sx={{ width: '100%' }}
            disabled={loadAll}
            isLoading={loadAll}
            error={isError('TienePreregistro')}
            errorMessage={formik.errors.TienePreregistro}
          />
        </Grid2>

        {formik.values.TienePreregistro === 1 &&
          (formik.values.esConcurrente ? (
            <Grid2 xs={12} sm={6} md={4} lg={4}>
              <TextField
                size="small"
                variant="outlined"
                disabled
                label="Fecha Fin PreRegistro"
                helperText="Se agregaran las fechas automaticamente"
                sx={{ width: '100%' }}
              />
            </Grid2>
          ) : (
            <Grid2 xs={12} sm={6} md={4} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                <MobileDateTimePicker
                  label="Fecha Fin PreRegistro *"
                  value={formik.values.FechaFinPreregistro}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      FechaFinPreregistro: isNullOrUndefined(e) ? '' : e,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      disabled={loadAll}
                      error={isError('FechaFinPreregistro')}
                      helperText={formik.errors.FechaFinPreregistro}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid2>
          ))}

        {formik.values.TienePreregistro === 1 && (
          <Grid2 xs={12}>
            <PreguntasEventos
              isLoading={loadAll}
              formik={formik}
              type={type}
              canEdit={formik.values.canEditPreguntas}
            />
          </Grid2>
        )}

        <Grid2 xs={12}>
          <Grid2 container spacing={2} justifyContent="end">
            {getModalidad([1, 3]) && (
              <Grid2 xs={12} sm={4} md={2} lg={2}>
                <Tooltip
                  title={
                    parseFloat(formik.values?.coordenadas?.latitud ?? 0)?.toFixed(4) +
                    ' , ' +
                    parseFloat(formik.values?.coordenadas?.longitud ?? 0)?.toFixed(4)
                  }
                >
                  <Button
                    variant={isError('coordenadas') ? 'outlined' : 'contained'}
                    color={isError('coordenadas') ? 'error' : 'info'}
                    onClick={() => setOpen(true)}
                    fullWidth
                    disabled={loadAll}
                    startIcon={<Icon>{isError('coordenadas') ? 'error' : 'map'}</Icon>}
                  >
                    Ubicación
                  </Button>
                </Tooltip>
                {isError('coordenadas') && <FormHelperText error>Agrega las coordenadas</FormHelperText>}
              </Grid2>
            )}

            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button
                variant="contained"
                color={isEdit ? 'warning' : 'error'}
                onClick={() => (isEdit ? setType(2) : handleAction(false))}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid2>

            {isEdit && (
              <Grid2 xs={12} sm={4} md={2} lg={2}>
                <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                  Eliminar
                </Button>
              </Grid2>
            )}

            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button
                variant="contained"
                color="success"
                onClick={formik.handleSubmit}
                disabled={loadAll}
                fullWidth
              >
                {isEdit ? 'Editar' : 'Crear'}
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        disableClose
        sxCard={{ overflowY: 'none' }}
        sxContent={{ p: 1 }}
        actionButtons={[{ title: 'Guardar', action: handleChangeCoords, icon: 'save' }]}
      >
        <DragMap
          handleCoords={setCoordsMap}
          ubicacion={[coordsMap?.latitud, coordsMap?.longitud]}
          tooltip="Ubicación del evento"
        />
      </CustomModal>
    </>
  );
};

export default EventForm;
