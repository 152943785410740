import { useState, useContext, useEffect, Fragment } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  FormHelperText,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';
import moment from 'moment';

import BasicSelect from '@components/Selects/BasicSelect';
import FormContext from './FormContext';
import { AddResponsibilityInterface } from '@interfaces/VolunteerInterfaces';
import { AddResponsibilitySchema } from '@schemas/VolunteerSchemas';
import {
  AMBITO_ESTATAL,
  AMBITO_DF,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
  AMBITO_CASILLA,
  PERFIL_RC,
  PERFIL_FiL,
  PERFIL_RG,
  PERFIL_OBSERVER,
  PERFIL_ABOGADO,
  PERFIL_CASA,
  GUARDIAN_SECCION,
  LOGISTICA_ALIMENTOS,
  idPropietario,
} from '@data/constants';

import CatalogService from '@services/CatalogServices';
import { ValidateSection, SelectFunction, FormButtons } from './FormComponents';
import { changeResponsibility, changeScope } from './GenericFunctions';
import { removeDefaultOption } from '@utils/Utilities';
import { isEmptyOrInvalidString } from '@utils/validations';

const ANIOS_VIGENCIA = 10;
const VIGENCIA_INE = parseInt(moment().format('YYYY')) + ANIOS_VIGENCIA;

const validity = (data) => {
  const regex = /^[0-9]*$/;
  return data.match(regex) && data.length <= 4;
};

const ElectoralForm = (props) => {
  const { handleSubmit, assigned } = props;
  const { functionLabel, catalogs, volunteer, catResponsibilities, userOwner } = useContext(FormContext);

  const formik = useFormik({
    initialValues: {
      ...AddResponsibilityInterface,
      idUsurioAutorizo: userOwner.value,
      idCompromisoUnico: volunteer.id,
    },
    validationSchema: AddResponsibilitySchema,
    onSubmit: (values) => {
      const params = {
        ...values,
        idUsurioAutorizo: userOwner.value,
        idCompromisoUnico: volunteer.id,
      };
      handleSubmit(params);
    },
  });

  const [scopeLabel, setScopeLabel] = useState('');
  const [catScope, setCatScope] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loadingCatScope, setLoadingCatScope] = useState(false);
  const [sectionValue, setSectionValue] = useState('');
  const [sectionValid, setSectionValid] = useState(false);
  const [propietario] = useState(
    catalogs.calidad_representante ? catalogs.calidad_representante.filter((item) => item.value !== 0) : []
  );

  useEffect(() => {
    if (assigned.length > 0) {
      handleClearForm();
    }
    //eslint-disable-next-line
  }, [assigned]);

  useEffect(() => {
    if (formik.values.idAmbitoCargo && formik.values.idAmbitoCargo === AMBITO_CASILLA) {
      formik.setValues({
        ...formik.values,
        AmbitoValorCargo: '',
        ResponsabilidadEtiqueta: '',
        Prioridad: '',
      });
    }
    //eslint-disable-next-line
  }, [formik.values.idPerfil]);

  useEffect(() => {
    if (sectionValid && formik.values.idAmbitoCargo === AMBITO_CASILLA) {
      loadCasillas(sectionValue);
    } else {
      if (formik.values.idAmbitoCargo === AMBITO_CASILLA) {
        setCatScope([]);
      }
    }
    //eslint-disable-next-line
  }, [sectionValid]);

  const handleChangeResponsibility = async (value) => {
    const profileInfo = catResponsibilities.find((item) => item.value === value);
    setSectionValue('');

    if (profileInfo.idAmbitoCargo === AMBITO_CASILLA) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }

    try {
      const { success, data } = await changeResponsibility({
        value,
        catalogs,
        profileInfo,
        values: formik.values,
      });

      if (success) {
        formik.setValues(data.newValues);
        setCatScope(data.catScope);
        setScopeLabel(data.scopeLabel);
      } else {
        setCatScope([]);
      }
    } catch (error) {
      setCatScope([]);
    }
  };

  const handleChangeScope = async (value, idPropietario) => {
    const profileInfo = catResponsibilities.find((item) => item.value === formik.values.idPerfil);

    try {
      const { success, data } = await changeScope({
        value,
        catalogs,
        profileInfo,
        values: formik.values,
        catScope,
        idPropietario,
      });

      if (success) {
        let mun_filtered = catalogs.municipios_dl.filter((item) => item.value !== 0);
        if (mun_filtered.length === 1) {
          formik.setValues({
            ...data,
            idMunicipio: mun_filtered[0].value,
          });
        } else {
          //Verificar CASOS
          formik.setValues(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeMpio = (value) => {
    setCatScope(removeDefaultOption(catalogs.poligonos.filter((item) => item.idMunicipio === value)));
  };

  const loadCasillas = (section) => {
    setLoadingCatScope(true);
    const catalogsParams = [
      {
        id: 'casillas',
        filtered: [
          {
            id: 'cat_casillas.Seccion',
            filter: '=',
            value: section,
          },
        ],
      },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatScope(res.response.catalogs.casillas ? res.response.catalogs.casillas : []);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: 'warning',
        });
      })
      .finally(() => setLoadingCatScope(false));
  };

  const loadCatalogsByMunicipio = (e) => {
    let idMunicipioReportes = e.target.value;
    formik.setFieldValue(e.target.name, idMunicipioReportes);

    let catalogsParams = [
      {
        id: 'df',
        filtered: [
          {
            id: 'idMunicipioReportes',
            filter: '=',
            value: idMunicipioReportes,
          },
        ],
      },
    ];

    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.success && res.results) {
          formik.setFieldValue('AmbitoValorCargo', '');

          setCatScope(removeDefaultOption(res.response.catalogs.df));
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });
  };

  const handleChangeRadio = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    formik.handleChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  const handleClearForm = () => {
    formik.setValues({
      idUsurioAutorizo: userOwner.value,
      idCompromisoUnico: volunteer.id,
      idPerfil: '',
      idAmbitoCargo: '',
      AmbitoValorCargo: '',
      ResponsabilidadEtiqueta: '',
      Prioridad: '',
      idPropietario: '',
    });
  };

  const RenderScope = () => {
    return (
      <Fragment>
        <BasicSelect
          label={scopeLabel}
          options={catScope}
          name="AmbitoValorCargo"
          value={formik.values.AmbitoValorCargo}
          onChange={(e) => handleChangeScope(e.target.value)}
          onBlur={formik.handleBlur}
          size="small"
          sx={{ width: '100%' }}
          isLoading={loadingCatScope}
        />
        <FormHelperText error>
          {formik.errors.AmbitoValorCargo &&
            formik.errors.AmbitoValorCargo.replace('AmbitoValorCargo', scopeLabel)}
        </FormHelperText>
      </Fragment>
    );
  };

  return (
    <Box>
      <SelectFunction
        label={functionLabel}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        options={catResponsibilities}
        handleChange={handleChangeResponsibility}
      />

      {formik.values.idAmbitoCargo && formik.values.idAmbitoCargo !== AMBITO_ESTATAL && (
        <Box>
          <Grid2 container spacing={2}>
            {formik.values.idAmbitoCargo && formik.values.idAmbitoCargo === AMBITO_POLIGONAL && (
              <Grid2 item xs={12} sm={12} md={6} lg={6}>
                <BasicSelect
                  label={'Municipio'}
                  options={removeDefaultOption(catalogs.municipios)}
                  onChange={(e) => handleChangeMpio(e.target.value)}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Grid2>
            )}

            {formik.values.idPerfil &&
              [PERFIL_RG, PERFIL_OBSERVER, PERFIL_CASA].includes(formik.values.idPerfil) &&
              [AMBITO_DF].includes(formik.values.idAmbitoCargo) &&
              catalogs.municipios_dl.length !== 2 && (
                <Grid2 xs={12} sm={12} md={6} lg={6}>
                  <BasicSelect
                    label={'Municipio'}
                    name="idMunicipio"
                    options={
                      catalogs.municipios_dl.length === 2
                        ? removeDefaultOption(catalogs.municipios_dl)
                        : removeDefaultOption(catalogs.municipios_dl)
                    }
                    onChange={loadCatalogsByMunicipio}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </Grid2>
              )}
            {formik.values.idAmbitoCargo &&
              ![AMBITO_SECCIONAL, AMBITO_CASILLA].includes(formik.values.idAmbitoCargo) && (
                <Grid2 item xs={12} sm={12} md={6} lg={6}>
                  <RenderScope />
                </Grid2>
              )}
          </Grid2>

          {formik.values.idAmbitoCargo &&
            [AMBITO_SECCIONAL, AMBITO_CASILLA].includes(formik.values.idAmbitoCargo) && (
              <Grid2 container spacing={2}>
                {formik.values.idAmbitoCargo && formik.values.idAmbitoCargo === AMBITO_CASILLA ? (
                  <ValidateSection
                    catalogs={catalogs}
                    handleChange={formik.handleChange}
                    setBtnDisabled={setBtnDisabled}
                    sectionValid={sectionValid}
                    setSectionValid={setSectionValid}
                    sectionValue={sectionValue}
                    setSectionValue={setSectionValue}
                  >
                    <RenderScope />
                  </ValidateSection>
                ) : (
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <RenderScope />
                  </Grid2>
                )}
              </Grid2>
            )}

          <Grid2 container spacing={2}>
            {formik.values.idPerfil &&
              [PERFIL_RC, PERFIL_FiL, PERFIL_RG, PERFIL_OBSERVER].includes(formik.values.idPerfil) && (
                <Grid2 xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={formik.touched.VigenciaINE && !isEmptyOrInvalidString(formik.errors.VigenciaINE)}
                    helperText={
                      formik.touched.VigenciaINE && formik.errors.VigenciaINE && formik.errors.VigenciaINE
                    }
                    sx={{ width: '100%' }}
                    label="Vigencia de INE"
                    type="number"
                    name="VigenciaINE"
                    inputProps={{
                      maxLength: 4,
                    }}
                    onChange={(e) =>
                      formik.handleChange({
                        target: {
                          name: 'VigenciaINE',
                          value: validity(e.target.value)
                            ? parseInt(e.target.value)
                            : formik.values.VigenciaINE,
                        },
                      })
                    }
                    onBlur={(e) =>
                      formik.handleChange({
                        target: {
                          name: 'VigenciaINE',
                          value: validity(e.target.value)
                            ? parseInt(e.target.value)
                            : formik.values.VigenciaINE,
                        },
                      })
                    }
                    value={formik.values.VigenciaINE}
                    variant="outlined"
                    size="small"
                    className="fixed-input"
                  />
                </Grid2>
              )}

            {formik.values.idPerfil && idPropietario.includes(formik.values.idPerfil) && (
              <Grid2 item xs={12} sm={12} md={6} lg={6}>
                <BasicSelect
                  label={'Propietario'}
                  options={propietario}
                  name="idPropietario"
                  value={formik.values.idPropietario}
                  onChange={(e) => {
                    if (formik.values.idPerfil === PERFIL_RC && formik.values.AmbitoValorCargo) {
                      handleChangeScope(formik.values.AmbitoValorCargo, e.target.value);
                    } else formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  size="small"
                  sx={{ width: '100%' }}
                  isLoading={loadingCatScope}
                />
                <FormHelperText error>
                  {formik.errors.idPropietario && formik.errors.idPropietario}
                </FormHelperText>
              </Grid2>
            )}
            {formik.values.idPerfil &&
              [
                PERFIL_RC,
                PERFIL_RG,
                PERFIL_OBSERVER,
                PERFIL_FiL,
                PERFIL_ABOGADO,
                GUARDIAN_SECCION,
                LOGISTICA_ALIMENTOS,
              ].includes(formik.values.idPerfil) && (
                <>
                  <Grid2 item xs={12} sm={12} md={3} lg={3}>
                    <FormControl>
                      <FormLabel id="TieneAuto">¿Tiene Auto?</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="TieneAuto"
                        name="TieneAuto"
                        value={formik.values.TieneAuto}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel value={1} control={<Radio />} label="SI" />
                        <FormControlLabel value={0} control={<Radio />} label="NO" />
                      </RadioGroup>
                      {formik.errors.TieneAuto && (
                        <FormHelperText error>{formik.errors.TieneAuto}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid2>
                  <Grid2 item xs={12} sm={12} md={3} lg={3}>
                    <FormControl>
                      <FormLabel id="TieneExperiencia">¿Tiene Experiencia?</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="TieneExperiencia"
                        name="TieneExperiencia"
                        value={formik.values.TieneExperiencia}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel value={1} control={<Radio />} label="SI" />
                        <FormControlLabel value={0} control={<Radio />} label="NO" />
                      </RadioGroup>
                      {formik.errors.TieneExperiencia && (
                        <FormHelperText error>{formik.errors.TieneExperiencia}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid2>
                  {formik.values.idPerfil && [PERFIL_RC, PERFIL_FiL].includes(formik.values.idPerfil) && (
                    <Grid2 item xs={12} sm={12} md={3} lg={3}>
                      <FormControl>
                        <FormLabel id="AceptaCuidarOtra">¿Aceptaría cuidar otra casilla?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="AceptaCuidarOtra"
                          name="AceptaCuidarOtra"
                          value={formik.values.AceptaCuidarOtra}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel value={1} control={<Radio />} label="SI" />
                          <FormControlLabel value={0} control={<Radio />} label="NO" />
                        </RadioGroup>
                        {formik.errors.AceptaCuidarOtra && (
                          <FormHelperText error>{formik.errors.AceptaCuidarOtra}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid2>
                  )}
                </>
              )}
          </Grid2>
        </Box>
      )}

      <FormButtons
        submitForm={formik.submitForm}
        handleClearForm={handleClearForm}
        btnDisabled={btnDisabled}
      />
    </Box>
  );
};

export default ElectoralForm;
