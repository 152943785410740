import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Card, CardContent, Typography, Stack, Box, Button, Icon, ButtonGroup } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import ResponsablesList from '@components/DiaD/AssignMRT/ResponsableList';

// Utils, hooks y servicios
import Yup from '@utils/Yupi18n';
import { useCatalogs } from '@hooks/useCatalogs';
import DiaDServices from '@services/DiaDServices';

const validationSchema = Yup.object().shape({
  idRuta: Yup.array().min(1).required().label('Rutas'),
  Municipio: Yup.number().positive('Elige un municipio').required().label('Municipio'),
  idResponsable: Yup.number().integer().required().label('idResponsable'),
  idPropietario: Yup.number().integer().required().label('idPropietario'),
  Responsable: Yup.string().required().label('Responsable'),
});

const AssignUser = (props) => {
  const { params, setParams, userData, setLoading, setLoadingMessage } = props;

  const [catalogsInitial, setCatalogsInitial] = useState({});
  const [catalogsFiltered, setCatalogsFiltered] = useState({});
  const [catResponsibilitiesType, setCatResponsibilitiesType] = useState([]);
  const [formType, setFormType] = useState(0);

  const formik = useFormik({
    initialValues: {
      idRuta: [],
      idResponsable: '',
      Responsable: '',
      Municipio: 0,
      idPropietario: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSave(values);
    },
  });

  const paramsEmpty = Object.keys(params).length === 0;

  const catalogsOptions = [
    { id: 'municipios_reportes', getAll: false },
    { id: 'rutas_mrt', getAll: false },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: true,
  });

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;

    if (_idMunicipality === 0) setCatalogsFiltered(catalogsInitial);
    else {
      setCatalogsFiltered((prev) => ({
        ...prev,
        rutas_mrt: catalogsInitial.rutas_mrt.filter(
          ({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality
        ),
      }));
    }
    formik.setValues((prev) => ({ ...prev, Municipio: _idMunicipality, idRuta: [] }));
  };

  const handleChangeidRuta = (newValue) => {
    formik.setValues((prev) => ({ ...prev, idRuta: newValue }));
  };

  const handleChangeidPropietario = ({ target }) => {
    formik.setValues((prev) => ({ ...prev, idPropietario: target.value }));
  };

  const clean = () => {
    setCatalogsFiltered(catalogsInitial);
    formik.setValues((prev) => ({ ...prev, Municipio: 0, idRuta: [] }));
  };

  const handleSave = async () => {
    const sendParams = {
      idUsurioAutorizo: formik.values.idResponsable,
      idCompromisoUnico: params.idCompromisoUnico,
      idPerfil: 160, // fijo
      idAmbitoCargo: 13, // fijo
      AmbitoValorCargo: formik.values.idRuta.map((item) => item.value),
      ResponsabilidadEtiqueta: `Ruta MRT - Propietario ${formik.values.idPropietario === 1 ? 1 : 2}`, // fijo
      Prioridad: null, // fijo
      idPropietario: formik.values.idPropietario,
      idMunicipio: formik.values.Municipio,
    };

    try {
      setLoading(true);
      setLoadingMessage('Guardado datos...');

      const result = await DiaDServices.createUsuarioDiad(sendParams);
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });

        setParams({
          ...params,
          idRuta: params?.idRuta ? [...params?.idRuta, ...formik.values.idRuta] : formik.values.idRuta,
          idResponsable: formik.values.idResponsable || '',
        });

        clean();
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCatResponsibilitiesType([{ idTipo: 0, Tipo: 'Asignación por ruta' }]);
  }, []);

  useEffect(() => {
    if (!load) {
      const rutas_mrt = catalogs.rutas_mrt.map((item) => ({
        ...item,
        label: item.RutaLabel ?? item.label,
        RutaLabel: item.label,
      }));
      const propietarios = [
        { label: 'Propietario 1', value: 1 },
        { label: 'Propietario 2', value: 3 },
      ];

      setCatalogsInitial({ ...catalogs, rutas_mrt, propietarios });
      setCatalogsFiltered({ ...catalogs, rutas_mrt, propietarios });
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <Card className="card-primary" sx={{ height: '100%' }}>
      <CardContent>
        <Box textAlign="center" mt={2}>
          <Typography component={'h6'} variant="h6" fontWeight={800} fontSize={18}>
            AGREGAR PROPUESTAS DE RESPONSABILIDAD
          </Typography>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="div">Tipo de responsabilidad</Typography>
            <ButtonGroup size="medium" variant="outlined">
              {catResponsibilitiesType.map((item) => (
                <Button
                  color="info"
                  key={item.idTipo}
                  onClick={() => setFormType(item.idTipo)}
                  variant={item.idTipo === formType ? 'contained' : 'outlined'}
                  sx={{
                    borderWidth: '1px',
                    borderRadius: '30px',
                    pr: '23 px',
                    pl: '23 px',
                  }}
                >
                  {item.Tipo}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
        </Box>

        <ResponsablesList
          setResponsable={(e) => {
            formik.setValues((prev) => ({ ...prev, idResponsable: e.value, Responsable: e.label }));
          }}
        />

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={12} lg={6}>
            <BasicSelect
              name="Municipio"
              label="Municipio"
              options={catalogsFiltered.municipios_reportes}
              value={formik.values.Municipio}
              onChange={handleChangeMunicipality}
              sx={{ width: '100%' }}
              isLoading={load}
              error={Boolean(formik.errors.Municipio)}
              errorMessage={formik.errors.Municipio}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={6}>
            <AdvancedSelect
              name={'idRuta'}
              label={'Rutas'}
              options={catalogsFiltered?.rutas_mrt?.filter((item) => {
                const data = userData ?? [];
                return !data
                  .concat([{ value: 0 }])
                  .map((it) => it.AmbitoValorCargo)
                  .includes(item.value);
              })}
              value={formik.values.idRuta}
              onChange={handleChangeidRuta}
              isLoading={load}
              disabled={formik.values.Municipio === 0}
              error={Boolean(formik.errors.idRuta)}
              helperText={formik.errors.idRuta}
              disableCloseOnSelect
              multiple
              isSearchable
              useObjects
              tooltipOptions
            />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={6}>
            <BasicSelect
              name="idPropietario"
              label="Propietario"
              options={catalogsFiltered.propietarios}
              value={formik.values.idPropietario}
              onChange={handleChangeidPropietario}
              sx={{ width: '100%' }}
              isLoading={load}
              error={Boolean(formik.errors.idPropietario)}
              errorMessage={formik.errors.idPropietario}
            />
          </Grid>

          <Grid item xs={12} container spacing={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={4} md={5}>
              <Button variant="outlined" color="primaryDark" size="small" onClick={clean} fullWidth>
                Limpiar
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={5}>
              <Button
                fullWidth
                variant="contained"
                color="primaryDark"
                size="small"
                startIcon={<Icon>save</Icon>}
                onClick={formik.handleSubmit}
                disabled={paramsEmpty}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssignUser;
