import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Avatar, Icon, LinearProgress, Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import InfiniteScroll from 'react-infinite-scroll-component';

const UserTable = (props) => {
  const {
    data,
    setId,
    setNombreCompleto,
    total,
    upPageSize,
    hasMore,
    complement,
    clickCard = () => {},
    setUserData = () => {},
  } = props;
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (id, nombreCompleto) => {
    setSelectedCard(id);
    setId(id);
    setNombreCompleto(nombreCompleto);
    clickCard();
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      upPageSize();
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon sx={{ fontSize: 20, marginRight: 1 }}>people</Icon>
          <Typography variant="h7">Usuarios</Typography>
        </Box>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: 'auto',
          }}
          onScroll={handleScroll}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={upPageSize}
            hasMore={hasMore}
            loader={<LinearProgress color="secondary" />}
            style={{ overflow: 'visible' }}
          >
            <Box>
              {data.map((user) => (
                <Card
                  key={user.id}
                  onClick={() => {
                    handleCardClick(user.id, user.NombreCompleto);
                    setUserData(user);
                  }}
                  sx={{
                    p: 0,
                    backgroundColor: user.id === selectedCard ? '#e3e3e3' : 'white',
                    boxShadow: user.id === selectedCard ? 'none' : '0px 0px 0px rgba(0, 0, 0, 0)',
                    cursor: 'pointer',
                    minHeight: 45,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ width: 28, height: 28, marginRight: 2 }}>
                        <PersonIcon />
                      </Avatar>
                      <Grid>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                          {user.NombreCompleto}
                        </Typography>
                        {complement && (
                          <Grid>
                            <Typography variant="body2" color="text.secondary">
                              Edad: {user.Edad}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Municipio: {user.Municipio}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </InfiniteScroll>
        </Box>
        <Typography variant="body2" color="textSecondary" mt={2} ml={2}>
          Mostrando {data.length} de {total}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserTable;
