import { useState, useEffect } from 'react';

// Material UI
import { Card, Grid, Button, IconButton, Icon, Box, CardContent, Typography } from '@mui/material';

// Componentes
import PDFViewer from '@components/Containers/PDFViewer';
import CustomTable from '@components/Tables/CustomTable';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Form from '@components/Foca/PublicacionesForm';
import Filter from '@components/Foca/PublicacionesFilter';
import CustomModal from '@components/Modal/CustomModal';

// Servicios
import services from '@services/Foca/PublicacionesServices';
import { CATALOGS_PUBLICACIONES, COLUMNS_PUBLICACIONES } from '@data/constants/Foca';

// Middleware
import middleware from '@middlewares/middleware';
import { Swal, SwalComponent, SwalDelete, SwalImage } from '@utils/alerts';

const PublicacionesView = (props) => {
  const canEdit = middleware.checkMenuAction('Editar');
  const canDelete = middleware.checkMenuAction('Eliminar');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getData(params);
    // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    try {
      setIsLoading(true);

      const result = await services.getPublicaciones(params);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

        const format = response.data.map((item) => ({
          ...item,
          Tipo: CATALOGS_PUBLICACIONES.find((i) => i.value === item.idTipo)?.label ?? 'indefinido',
          Nombre: <Typography sx={{ maxWidth: 200, ...style }}>{item.Nombre}</Typography>,
          Descripcion: <Typography sx={{ maxWidth: 200, ...style }}>{item.Descripcion}</Typography>,
          Sinopsis: <Typography sx={{ maxWidth: 300, ...style }}>{item.Sinopsis}</Typography>,
          FechaPublicacion: ('' + (item.FechaPublicacion ?? '')).slice(0, 10),
          fileURLImg: item.fileURLImg ? (
            <Button
              size="small"
              fullWidth
              onClick={() => SwalImage({ image: item.fileURLImg, title: item.Nombre })}
              sx={{ justifyContent: 'flex-start', textAlign: 'left', textTransform: 'none' }}
            >
              Ver portada
            </Button>
          ) : (
            'Sin portada'
          ),
          fileName: (
            <Button
              size="small"
              fullWidth
              onClick={() =>
                SwalComponent({
                  component: (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <PDFViewer path={'foca/publicaciones/' + item.fileName} />
                    </Box>
                  ),
                })
              }
              sx={{
                justifyContent: 'flex-start',
                textAlign: 'left',
                textTransform: 'none',
              }}
            >
              {item.fileName}
            </Button>
          ),
          actions: (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {canEdit && (
                <IconButton aria-label="edit" color="success" onClick={() => handleEdit(item)}>
                  <Icon>edit</Icon>
                </IconButton>
              )}
              {canDelete && (
                <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </Box>
          ),
        }));

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  const handleEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };

  const handleIsEdited = () => setParams((prev) => ({ ...prev, page: 0 }));

  const deleteConfirmation = async ({ Nombre, id, fileName }) => {
    SwalDelete({
      title: 'Eliminar Publicación',
      text: `¿Estas seguro de querer eliminar la publicación: ${Nombre}?`,
      buttonText: 'Eliminar publicación',
      onDelete: () => handleDelete({ id, fileName }),
    });
  };

  const handleDelete = async ({ id, fileName }) => {
    try {
      setIsDeleting(true);

      const result = await services.deletePublicacion({ id, fileName });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
        handleClose();
        getData(params);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      Nombre: 'Nombre',
      Descripcion: 'Descripcion',
      Sinopsis: 'Sinopsis',
      fileName: 'FileName',
      Tipo: 'idTipo',
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      <LoadingForm
        loadinMessage={'Elimindo Publicación'}
        successMessage="Publicación eliminada con exito!"
        success={false}
        isLoading={isDeleting}
        isOpen={isDeleting}
        setIsOpen={() => {}}
      />

      <CustomModal open={open} onClose={handleClose} title="Editar Publicación" fullWidth disableClose>
        <Form
          type="edit"
          data={currentData}
          handleDelete={deleteConfirmation}
          handleClose={handleClose}
          handleIsEdited={handleIsEdited}
        />
      </CustomModal>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter handleFilter={handleFilter} />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_PUBLICACIONES(canEdit || canDelete)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PublicacionesView;
