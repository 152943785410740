import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { useNavigate } from 'react-router-dom';
import Tree from '@components/TreeView/TreeView';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  LinearProgress,
  CardHeader,
  Box,
  Stack,
  Icon,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import social from '@services/SocialServices';
import Swal from 'sweetalert2';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import ColumnStacked from '@components/Charts/BasiBarColumnPorcent';
import CustomTable from '@components/Tables/CustomTable';
import AutoComplete from '@components/Selects/Autocomplete';

const MovilizacionSegmento = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const [tree, setTree] = useState([]);
  const [loadingTree, setLoadingTree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCards, setLoadingCards] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [params, setParams] = useState({
    filtered: [],
  });
  const [paramsInfo, setParamsInfo] = useState({
    view: 'Social-SubSegmentos',
    page: 0,
    pageSize: 99999999999999,
    filtered: [],
    sorted: [],
  });
  const [search, setSearch] = useState('');
  const [segmentos, setSegmentos] = useState([]);
  const [rows, setRows] = useState([]);
  const [databar, setDataBar] = useState([
    {
      name: '',
      data: {
        categories: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7'],
        series: [
          {
            name: 'Pendientes',
            data: [50, 50, 50, 50, 50, 50, 50],
          },
          {
            name: 'Contactado',
            data: [5, 0, 18, 3, 7, 2, 6],
          },
          {
            name: 'No Localizado',
            data: [5, 0, 18, 3, 7, 2, 6],
          },
        ],
        title: 'AVANCE MOVILIZACIÓN POR ',
      },
    },
  ]);
  const [dataCard, setDataCard] = useState([
    {
      name: '',
      data: [
        {
          Meta: 0,
          Contactado: 0,
          PorcentajeContactado: 0,
        },
      ],
    },
  ]);
  const [columnsPercent, setColumnsPercent] = useState([
    { id: 'SubNombreSocial', label: 'Social' },
    { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
    { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
    {
      id: 'PorcentajePendientes',
      label: '% Pendientes',
      type: 'linear',
      fixed: 3,
    },
    { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
    {
      id: 'PorcentajeContactado',
      label: '% Contactados',
      type: 'linear',
      fixed: 3,
    },
    { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
    {
      id: 'PorcentajeNoLocalizado',
      label: '% No Localizado',
      type: 'linear',
      fixed: 3,
    },
  ]);

  useEffect(() => {
    social
      .catalogoStruct({})
      .then((res) => {
        if (res.results) {
          setSegmentos(
            res.response.map((item) => {
              return {
                value: item.id,
                label: item.NombreSocial,
                idPadre: item.idPadre,
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });
  }, []);

  useEffect(() => {
    getStructs();
  }, [params]);

  /*   useEffect(()=>{
    if(data.id){
      getAdvance(data);
    }
  },[paramsInfo]); */

  const getStructs = () => {
    setLoadingTree(true);
    social
      .getStruct(params)
      .then((res) => {
        if (res.results) {
          const struct = res.response.map((social) => {
            return {
              ...social,
              level: 1,
              /* NombreCompleto: (
                <Button
                  variant="text"
                  color="primaryDark"
                  size="small"
                  //onClick={() => handleEdit(commitment)}
                >
                  {commitment.Nombre} {commitment.Paterno}
                  <br />
                  {commitment.Edad} Años
                </Button>
              ),
              isVoluntario: commitment.isVoluntario === 1 ? "Si" : "No",
              Responsabilidad:
                commitment.Responsabilidad !== null
                  ? commitment.Responsabilidad
                  : "Sin Responsabiliad", */
            };
          });
          setTree(struct);
          setData(struct[0]);
          getAdvance(struct[0], paramsInfo);
          //  setStructs(struct);
          //  setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoadingTree(false));
  };

  const transformData = (datos, variant, name) => {
    // Objeto donde almacenaremos los datos transformados
    const datosTransformados = {};
    if (datos.length > 0) {
      // Iteramos sobre las claves de uno de los objetos para inicializar el objeto datosTransformados
      Object.keys(datos[0]).forEach((key) => {
        datosTransformados[key] = [];
      });

      // Iteramos sobre cada objeto en el array datos
      datos.forEach((objeto) => {
        // Iteramos sobre cada clave del objeto
        Object.keys(objeto).forEach((key) => {
          // Añadimos el valor correspondiente al array en datosTransformados
          let valor;
          if (key === variant) {
            valor = objeto[key];
          } else {
            valor = isNaN(parseFloat(objeto[key])) ? objeto[key] : parseFloat(objeto[key]);
          }

          // Añadimos el valor correspondiente al array en datosTransformados
          datosTransformados[key].push(valor);
        });
      });

      // Imprimimos los datos transformados
      return {
        categories: datosTransformados[variant] ? datosTransformados[variant] : [variant],
        series: [
          {
            name: 'Pendientes',
            data: datosTransformados['Pendientes'],
            Meta: datosTransformados['Meta'],
            color: '#BBEDF8',
          },
          {
            name: 'Contactado',
            data: datosTransformados['Contactado'],
            Meta: datosTransformados['Meta'],
            color: '#CBF8BB',
          },
          {
            name: 'No Localizado',
            data: datosTransformados['NoLocalizado'],
            Meta: datosTransformados['Meta'],
            color: '#F8C6BB',
          },
        ],
        title: name,
      };
    } else {
      return {
        categories: [''],
        series: [
          {
            name: 'Pendientes',
            data: [-1],
            Meta: [0],
            color: '#BBEDF8',
          },
          {
            name: 'Contactado',
            data: [-1],
            Meta: [0],
            color: '#CBF8BB',
          },
          {
            name: 'No Localizado',
            data: [-1],
            Meta: [0],
            color: '#F8C6BB',
          },
        ],
        title: name,
      };
    }
  };

  const getAdvance = (id, parametros) => {
    let paramsChnage = {
      ...parametros,
      filtered: [
        {
          id: `AND cat_seccion.idEstructuraSocial IN (WITH RECURSIVE Jerarquia AS (SELECT id, NombreSocial, Alias, idPadre, id as idPrincipal, Nivel FROM cat_social WHERE id IN  (${id.id}) AND FechaElimino IS NULL UNION ALL SELECT e.id, e.NombreSocial, e.Alias, e.idPadre,  CASE WHEN j.nivel = 0 THEN e.id ELSE j.idPrincipal END as idPrincipal, j.nivel + 1 FROM cat_social e JOIN Jerarquia j ON e.idPadre = j.id  WHERE FechaElimino IS NULL  ) SELECT id FROM Jerarquia ORDER BY id)`,
          filterQuery: 'true',
        },
      ],
    };
    setParamsInfo(paramsChnage);
    setLoadingData(true);
    social
      .getAdvence(paramsChnage)
      .then((res) => {
        if (res.results) {
          let result = res.response.data;
          setRows(result);
          setTotal(res.response.total);

          let type = 'SubNombreSocial';

          //   (idAmbitoAgrupadorTable.valor === 16 ? "Municipio" : (idAmbitoAgrupadorTable.valor === 17 ? "Region" : idAmbitoAgrupadorTable.valor === 15 ? "NombreSocial" :""));

          setDataBar(
            result.map((item) => {
              return {
                name: item.name,
                data: transformData(item.data, type, item.name),
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingData(false));

    setLoadingCards(true);
    social
      .getAdvence({ ...paramsChnage, view: 'Estado' })
      .then((res) => {
        if (res.results) {
          let result = res.response.data;
          setDataCard(result);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingCards(false));
  };

  const handleItemTree = (e) => {
    setData(e);
    getAdvance(e, { ...paramsInfo, page: 0, pageSize: 10 });
  };

  const handleSearch = () => {
    if (search && search !== '' && search.value !== 'string') {
      setParams({ ...params, idEstructura: search.value });
    } else {
      setParams({ ...params, idEstructura: null });
    }
  };

  const handleClearSearch = () => {
    setSearch('');
    setParams({ ...params, filtered: [] });
    setTree([]);
  };

  return (
    <AdminLayout>
      <ActionHeader title="Movilización Social" handleclick={() => navigate('/movilizacion-social')} />
      <Grid2 container spacing={2}>
        <Grid2 item xs={4}>
          <Card sx={{ height: '800px', overflow: 'auto' }}>
            <CardContent>
              <Box marginBottom={'1rem'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1} marginBottom={'0.5rem'}>
                  <Icon>person_search</Icon>
                  <Typography fontWeight={600} variant={'body2'} marginBottom={'0.5rem'}>
                    Buscar
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <AutoComplete
                    required
                    label="Estructuras"
                    name="Estructuras"
                    autoComplete="Estructuras"
                    value={search}
                    id="Estructuras"
                    size="small"
                    disableUnderline={false}
                    setSelect={(e) => setSearch(e)}
                    data={segmentos}
                    fullWidth={true}
                    //onChange={handleChange}
                  />

                  <Button
                    variant="contained"
                    color="primaryDark"
                    sx={{ width: '25%' }}
                    onClick={handleSearch}
                  >
                    Buscar
                  </Button>
                </Stack>
              </Box>
              <Divider sx={{ marginBottom: '1rem' }} />
              {loading && <LinearProgress />}
              <Tree
                data={tree}
                defaultExpanded={tree[0]}
                typeData={{
                  value: 'id',
                  label: 'NombreSocial',
                  S_label: null,
                  T_label: 'Alias',
                }}
                handleClick={handleItemTree}
                loading={loadingTree}
              />
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 item xs={8}>
          <Card>
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 item xs={12} md={12} lg={12}>
                  {dataCard.map((item) => {
                    return (
                      <AdaptiveCard
                        title="Avance de Contacto"
                        icon="description"
                        config={{
                          percent: parseFloat(item.data[0]?.PorcentajeContactado) || 0,
                        }}
                        leftConfig={{
                          title: 'Meta',
                          data: parseFloat(item.data[0]?.Meta) || 0,
                        }}
                        rightConfig={{
                          title: 'Avance',
                          data: parseFloat(item.data[0]?.Contactado) || 0,
                        }}
                        fixed
                        loading={loadingCards}
                      />
                    );
                  })}
                </Grid2>
                {loadingData ? (
                  <LinearProgress color="secondary" />
                ) : (
                  <Grid2 container spacing={2}>
                    {databar.map((item, index) => (
                      <Grid2 item xs={12} sm={6} md={6} lg={databar.length > 1 ? 6 : 12} key={index}>
                        <Card className="card-primary">
                          <CardContent>
                            <ColumnStacked
                              series={item.data.series}
                              categories={item.data.categories}
                              title={item.data.title}
                              loading={false}
                            />
                          </CardContent>
                        </Card>
                      </Grid2>
                    ))}
                    {rows.map((item, index) => (
                      <Grid2 item xs={12} sm={6} md={6} lg={rows.length > 1 ? 6 : 12} key={index}>
                        <Card>
                          <CardHeader title={item.name} />
                          <CardContent>
                            <CustomTable
                              rows={item.data}
                              columns={columnsPercent}
                              orderBy={orderBy}
                              loading={loading}
                              // total={total}
                              // page={paramsInfo.page}
                              // pageSize={paramsInfo.pageSize}
                              // handlePagination={(e)=>{getAdvance(data,{...paramsInfo,...e})}}
                            />
                          </CardContent>
                        </Card>
                      </Grid2>
                    ))}
                  </Grid2>
                )}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </AdminLayout>
  );
};
export default MovilizacionSegmento;
