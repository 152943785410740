import { useState } from 'react';
import { numberWithCommas } from '@utils/Utilities';
import { Container } from '@mui/material';
import AdminLayout from '@components/MainPage/AdminLayout';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CardsPorcent from '../../components/Card/CardsPorcent';

const EstadisticasBingo = () => {
  const [dataTotales, setDataTotales] = useState({
    TotalBingo: 0,
    MetaBingo: 100,
    PorcentajeBingo: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Grid2 item xs={12} sm={12} md={6} lg={6}>
          <CardsPorcent
            icon={'flag'}
            title={'Avance'}
            total={`${numberWithCommas(
              dataTotales.TotalBingo
            )} de ${numberWithCommas(dataTotales.MetaBingo)}`}
            subIcon={'percent'}
            subtitle={dataTotales.PorcentajeBingo}
            loading={isLoading}
          />
        </Grid2>
      </Container>
    </AdminLayout>
  );
};
export default EstadisticasBingo;
