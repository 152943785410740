//MUI
import {
  Box,
  Card,
  CircularProgress,
  Icon,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';

//Components
import Modal from '@components/Modal/Dataview';
import ListRepresentatives from './ListRepresentatives';

import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';

const ModalDetailsCasilla = ({ loading, openModal, setOpenModal, data }) => {
  const hasLocation =
    data?.Latitud && data?.Longitud && data?.Latitud.length > 0 && data?.Longitud.length > 0;

  return (
    <Modal open={openModal} setOpen={setOpenModal} hasButtonClose={false} maxWidth={'sm'} borderRadius={12}>
      <Box>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h6">Información de la sección</Typography>
          <IconButton
            size="small"
            sx={{
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            }}
            onClick={() => setOpenModal(false)}
          >
            <Icon>close</Icon>
          </IconButton>
        </Stack>

        {loading ? (
          <Box display={'flex'} justifyContent={'center'} marginTop={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Box marginTop={2}>
            <Stack flexDirection={'column'}>
              <Stack flexDirection={'column'}>
                <List sx={{ mb: 2 }}>
                  <ListItem divider sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">RG</Typography>
                    <Typography variant="body2" sx={{ width: '50%' }}>
                      {data?.NombreRG} <br />
                      {data?.CelularRG}
                    </Typography>
                  </ListItem>

                  <ListItem divider sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Ruta</Typography>
                    <Typography variant="body2" sx={{ width: '50%' }}>
                      {data?.NombreRuta}
                    </Typography>
                  </ListItem>

                  <ListItem divider sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Sección</Typography>
                    <Typography variant="body2" sx={{ width: '50%' }}>
                      {data?.Seccion}
                    </Typography>
                  </ListItem>

                  {hasLocation && (
                    <ListItem divider sx={{ justifyContent: 'space-between' }}>
                      <Typography variant="subtitle2">Ubicación</Typography>
                      <Typography
                        variant="subtitle2"
                        component={'a'}
                        href={`https://www.google.com/maps?q=${data?.Latitud},${data?.Longitud}&z=17&hl=es`}
                        target="_blank"
                        sx={{ width: '50%' }}
                      >
                        Ver ubicación en google maps
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </Stack>

              <Stack flexDirection={'column'}>
                <Typography variant="h6">Casilla</Typography>
                <Box
                  marginTop={1}
                  sx={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    display: 'block',
                  }}
                >
                  <Typography variant="subtitle2">{data?.NombreCasilla}</Typography>
                  <ListRepresentatives representative={data} />
                </Box>
              </Stack>

              <Stack flexDirection={'column'}>
                <Typography variant="h6">Dirección</Typography>
                <Box marginTop={1}>
                  <Typography variant="subtitle2" marginBottom={1}>
                    {data?.Domicilio && data?.Domicilio.length > 0
                      ? data.Domicilio
                      : 'No cuenta con dirección.'}
                  </Typography>

                  {hasLocation && (
                    <Box component={Card} elevation={0} borderRadius={4}>
                      <MapContainer
                        center={[data?.Latitud, data?.Longitud]}
                        zoom={16}
                        scrollWheelZoom={false}
                        style={{ width: '100%', height: '30vh' }}
                        fullscreenControl={true}
                        fullscreenControlOptions={{
                          position: 'topright',
                          title: 'Mirar en pantalla completa',
                          titleCancel: 'Salir de pantalla completa',
                          content: null,
                          forceSeparateButton: true,
                          forcePseudoFullscreen: false,
                          fullscreenElement: false,
                        }}
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[data?.Latitud, data?.Longitud]}>
                          <Popup>{data?.Domicilio}</Popup>
                        </Marker>
                      </MapContainer>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalDetailsCasilla;
