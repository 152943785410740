import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

//MUI
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Stack,
  Divider,
  Icon,
  Typography,
  Tab,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { Block, ChevronLeft, ChevronRight, WhatsApp } from '@mui/icons-material';

//Components
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import TreeViewIcon from '@components/TreeView/TreeViewIcon';
import CardStats from '@components/Card/CardStats';
import BasicTable from '@components/Tables/BasicTable';
import MyUsersFilter from '@components/Users/MyUsersFilter';
import DrawerRelative from '@components/Containers/DrawerRelative';
import UserInfo from '@components/Users/UserInfo';

//Services
import UserServices from '@services/UserServices';
import CompromisoServices from '@services/CompromisoServices';

//Utils
import { isTypePhone } from '@utils/validations';
import { numberWithCommas, searchTree, removeMinus, removeSpaces } from '@utils/Utilities';
import { limpiarTelefono } from '@utils/global';

//Middleware
import middleware from '@middlewares/middleware';

const MyUsers = () => {
  //variables
  const columns = [
    { id: 'Region', label: 'Región', align: 'center' },
    { id: 'Municipio', label: 'Municipio', align: 'center' },
    { id: 'NombreCompleto', label: 'Nombre', align: 'center' },
    { id: 'SeccionVota', label: 'Sección Vota', align: 'center' },
    { id: 'EsVoluntario', label: 'Voluntario', align: 'center' },
    { id: 'CapturadoPor', label: 'Capturado Por', align: 'center' },
  ];
  const navigate = useNavigate();
  const [shortUrl, setShortUrl] = useState('');
  //States
  // const [total] = useState(0);
  const [currentUserID, setCurrentUserID] = useState(true);
  const [openGraph, setOpenGraph] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingTree, setLoadingTree] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [valueTab, setValueTab] = useState('2');
  const [nameUser, setNameUser] = useState('');
  const [phone, setPhone] = useState('');
  const [commitments, setCommitments] = useState([]);
  const [treeUsersFound, setTreeUsersFound] = useState({});
  const [treeUsers, setTreeUsers] = useState([]);
  const [graphUsuarios, setGraphUsuarios] = useState({ nodes: [], links: [] });
  const [loadingCommitments, setLoadingCommitments] = useState(false);
  const [totalCommitments, setTotalCommitments] = useState(0);
  const [data, setData] = useState({
    idUsuario: 0,
    page: 0,
    pageSize: 10,
    unique: false,
    nested: false,
    filtered: [],
    //sorted: [{ id: "compromisos_unicos.FechaCreo", value: "DESC" }],
  });
  const [totalUser, setTotalUser] = useState({
    totalUsuarios: 0,
    totalCompromisos: 0,
    totalCompromisosUnicos: 0,
  });
  const [dataUser, setUser] = useState({});
  //API's
  /*   function flattenJsonNode(json, parentId = 0, flatData = []) {
     let  flatNode = {
        id: `${json.id}`,
        group: `${parentId}`,
        name: `${json.Nombre} ${json.Paterno}`,
        children: json.children.length
      };
      flatData.push(flatNode);
      if (json.children && json.children.length > 0 ) {
        json.children.forEach(child => {
          flattenJsonNode(child, json.id, flatData);
        });
      }
  
    return flatData;
  }
  function flattenJsonLinks(json, parentId = 0, flatData = []) {
    let  flatNode = {
       target: `${json.id}`,
       value: `${parentId}`,
       source: `${parentId}`, //`${json.Nombre} ${json.Paterno}`,
       children: json.children.length
     };

    flatData.push(flatNode);
      json.children.forEach(child => {
        flattenJsonLinks(child, json.id, flatData);
      });
    
 
   return flatData;
 } */

  const getMyUsersTree = () => {
    setLoadingTree(true);
    UserServices.getMyUsers({})
      .then((res) => {
        if (res.results) {
          setTreeUsers([res.response]);
          /*   setGraphUsuarios({
            nodes:flattenJsonNode(res.response).sort((a, b) => b.children - a.children),
            links:flattenJsonLinks(res.response).sort((a, b) => b.children - a.children)
          }); */
          setTreeUsersFound(res.response);
          handleItemTree(res.response);
        } else Swal.fire({ title: res.message, icon: 'warning' });
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingTree(false));
  };

  const getUserCommitments = (params) => {
    setLoadingCommitments(true);
    CompromisoServices.getUserCommitments(params)
      .then((res) => {
        if (res.results) {
          setCommitments(
            res.response.data.map((item) => {
              return {
                ...item,
                EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO',
              };
            })
          );
          setTotalCommitments(res.response.total);
        } else Swal.fire({ title: res.message, icon: 'warning' });
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setLoadingCommitments(false));
  };

  useEffect(() => {
    const id = data?.idUsuario;
    // if (id && id !== 0 && id !== currentUserID) {
    setCurrentUserID(id);
    getUserCommitments(data);
    //  }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getMyUsersTree();
    //eslint-disable-next-line
  }, []);

  //Functions and Handlers
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleItemTree = (e) => {
    if (e?.id !== currentUserID) {
      if (e !== null) {
        const { id, Nombre, Paterno, Materno, UUID, TotalUsuarios, TotalCompromisos, CompromisosUnicos } = e;
        setUser(e);
        const NameUser = `${Nombre} ${Paterno}`;
        setNameUser(NameUser);
        setTotalUser({
          ...totalUser,
          totalUsuarios: numberWithCommas(TotalUsuarios),
          totalCompromisos: numberWithCommas(TotalCompromisos),
          totalCompromisosUnicos: numberWithCommas(CompromisosUnicos),
        });
        setData({ ...data, idUsuario: id });
      } else {
        setCurrentUserID(0);
        setNameUser('');
        setTotalUser({
          ...totalUser,
          totalUsuarios: 0,
          totalCompromisos: 0,
          totalCompromisosUnicos: 0,
        });
      }
    }
  };

  const handleChangeTab = (event, newValue) => setValueTab(newValue);

  const handleChangePhone = (e) => {
    const formatted = limpiarTelefono(e.target.value);
    const value = isTypePhone(formatted) ? formatted : phone;

    setPhone(value);
    if (!value && treeUsers.length === 0) handleClearSearch();
  };

  const handleSearch = () => {
    if (phone === '') return;

    const founds = searchTree(treeUsersFound, 'Username', phone);
    setTreeUsers(founds !== null ? [founds] : []);
    setShowButtonCancel(true);
    handleItemTree(founds);
  };

  const handleClearSearch = () => {
    setPhone('');
    setShowButtonCancel(false);
    getMyUsersTree();
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (phone.length === 10) handleSearch();
    }
  };

  const handleChangeFilter = (options) => {
    setData({
      ...data,
      page: 0,
      unique: options.unique,
      nested: options.nested,
    });
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/52${phoneNumber}`;
    if (isTypePhone(phoneNumber)) window.open(whatsappUrl, '_blank');
    else
      Swal.fire({
        title: 'Numero invalido',
        text: `"${phoneNumber}" no es un número válido`,
        icon: 'warning',
      });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Mis Usuarios'} isCustom={true}>
          {middleware.checkMenuAction('Editar') && (
            <Button
              variant="contained"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              size="small"
              color="primaryDark"
              startIcon={<Icon>edit</Icon>}
              onClick={() => navigate('/mis-usuarios-editar')}
            >
              Editar
            </Button>
          )}

          <IconButton
            onClick={() => setOpenDrawer(!openDrawer)}
            aria-label="open Drawer"
            color={openDrawer ? 'default' : 'primary'}
          >
            {openDrawer ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </ActionHeader>
        {!openGraph ? (
          <Grid container spacing={openDrawer ? 2 : 0}>
            <Grid item xs={12} md={4}>
              <DrawerRelative
                open={openDrawer}
                setOpen={setOpenDrawer}
                title={'Buscar por número de celular'}
                icon="person_search"
                titleComponentPlace="under"
                titleComponent={
                  <Stack direction="row" spacing={2} sx={{ paddingX: 2, paddingBottom: 2 }}>
                    <TextField
                      label="Celular"
                      size="small"
                      name="phone"
                      value={phone}
                      onChange={handleChangePhone}
                      onKeyUp={handleOnKeyPress}
                      error={phone.length > 0 && phone.length < 10}
                      helperText={phone.length > 0 && phone.length < 10 && 'Ingresa un numero valido'}
                      InputProps={{
                        endAdornment: showButtonCancel && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch}>
                              <Icon>clear</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />

                    <Button
                      variant="contained"
                      color="primaryDark"
                      sx={{ width: '25%' }}
                      onClick={handleSearch}
                      disabled={phone.length < 10}
                    >
                      Buscar
                    </Button>
                  </Stack>
                }
                className="card-primary"
                sxPaper={{ borderRadius: 3 }}
              >
                <Card
                  sx={{
                    height: '100%',
                    overflow: 'auto',
                    marginBottom: { xs: '0px!important' },
                  }}
                >
                  {/*     <CardHeader
                  sx={{maxHeight:"10px"}}
                  action={
                    <IconButton>
                      <Icon>account_tree</Icon>
                    </IconButton>
                  }
                /> */}
                  <CardContent>
                    <TreeViewIcon
                      data={treeUsers}
                      typeData={{
                        value: 'id',
                        label: 'Nombre',
                        S_label: 'Paterno',
                        icon: {
                          label: 'Username',
                          icon: <WhatsApp />,
                          color: '#25D366',
                          position: 'right',
                          click: (e) => redirectToWhatsApp(e),
                        },
                        customItem: [
                          {
                            id: 'idEstatus',
                            value: 4,
                            icon: Block,
                            color: '#F67B7B',
                          },
                        ],
                      }}
                      handleClick={handleItemTree}
                      loading={loadingTree}
                    />
                    {showButtonCancel && treeUsers.length === 0 && (
                      <>
                        <br />
                        <Stack justifyContent="center" alignItems="center">
                          <Button
                            variant="contained"
                            color="primaryDark"
                            onClick={handleClearSearch}
                            sx={{ width: 'fit-content' }}
                          >
                            Reiniciar
                          </Button>
                        </Stack>
                      </>
                    )}
                  </CardContent>
                </Card>
              </DrawerRelative>
            </Grid>

            <Grid item xs={12} md={openDrawer ? 8 : 12}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <CardStats icon={'people'} title={'Total de Usuarios'} total={totalUser.totalUsuarios} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CardStats icon={'feed'} title={'Compromisos (Gral)'} total={totalUser.totalCompromisos} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardStats
                    icon={'fact_check'}
                    title={'Compromisos Únicos'}
                    total={totalUser.totalCompromisosUnicos}
                  />
                </Grid>
              </Grid>

              <Card className="card-primary" sx={{ marginBottom: { xs: '0px!important' } }}>
                <CardContent>
                  {nameUser !== '' && (
                    <>
                      <Stack direction={'row'} spacing={1} alignItems="center">
                        <Icon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>account_circle</Icon>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {nameUser}
                        </Typography>
                      </Stack>
                      <Divider />
                    </>
                  )}
                  <Box marginTop={'1rem'} sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={valueTab}>
                      <Box>
                        <TabList onChange={handleChangeTab} aria-label="tabs para graficas y tablas">
                          <Tab
                            icon={<Icon>show_chart</Icon>}
                            iconPosition="start"
                            label="Información"
                            value="1"
                          />
                          <Tab icon={<Icon>feed</Icon>} iconPosition="start" label="Compromisos" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">{nameUser !== '' && <UserInfo data={dataUser} />}</TabPanel>
                      <TabPanel value="2">
                        <MyUsersFilter onChangeFilter={(e) => handleChangeFilter(e)} />
                        <BasicTable
                          rows={commitments}
                          hcolumns={columns}
                          pageProp={data.page}
                          pageSize={data.pageSize}
                          total={totalCommitments}
                          handleChangePagination={handleChangePagination}
                          isLoading={loadingCommitments}
                          stickyHeader={true}
                        />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          {
            /* <NetworkGraph  data={graphUsuarios} /> */
          }
        )}
      </Container>
    </AdminLayout>
  );
};

export default MyUsers;
