import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Button, Stack, Tabs, Tab } from '@mui/material';

// Componentes
import { TabPanel, a11yProps } from '@components/Maps/Varius/TabComponent';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import BasicSelect from '@components/Selects/BasicSelect';

// Servicios y Utils
import CatalogService from '@services/CatalogServices';
import { setVars, getVars } from '@utils/global';
import '@components/Maps/Styles/TabPadding.css';

const Filter = (props) => {
  const {
    catalogs,
    loadingCatalogs,
    handleFilter,
    type = false,
    responsability = false,
    section = false,
    disableTabMunicipio = false,
    disableTabDFED = false,
    disableTabDLOC = false,
  } = props;

  const tipoOptions = [
    { value: 10, label: 'TODOS' },
    { value: 1, label: 'Se acreditan INE' },
    { value: 0, label: 'No se acreditan INE' },
  ];

  const AMBITO_MPAL = 0;
  const AMBITO_DF = 1;
  const AMBITO_DL = 2;

  const [tabValue, setTabValue] = useState(getVars('valueTab', AMBITO_MPAL));

  const [catResponsabilidad, setCatResponsabilidad] = useState([]);
  const [catMunicipios, setCatMunicipios] = useState([{ value: 0, label: 'TODOS' }]);
  const [catSecciones, setCatSecciones] = useState([
    { value: 0, label: `Selecciona un ${tabValue === AMBITO_MPAL ? 'municipio' : 'distrito'}` },
  ]);
  const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);

  const [filter, setFilter] = useState({
    idTipo: 10,
    idResponsabilidad: 0,
    idRegion: [],
    idMunicipio: [],
    idDF: [],
    idDL: [],
    Seccion: 0,
    porAvance: 0,
  });

  const handleChange = (event, newValue) => {
    if (section) {
      setCatSecciones([
        { value: 0, label: `Selecciona un ${newValue === AMBITO_MPAL ? 'municipio' : 'distrito'}` },
      ]);
    }

    const shp = getVars('filteredShapeMap', { tab: null });

    if (shp.tab !== null && shp.tab === newValue) {
      const filterMap = getVars('filterSavedMap', {});
      setFilter(filterMap);
    } else {
      setFilter({
        idTipo: 10,
        idResponsabilidad: 0,
        idRegion: [],
        idMunicipio: [],
        idDF: [],
        idDL: [],
        Seccion: 0,
        porAvance: 0,
      });
    }

    if (newValue === AMBITO_MPAL) setCatResponsabilidad(catalogs?.estructura_electoral);
    else setCatResponsabilidad(catalogs?.estructura_electoral_edit);

    setVars('valueTab', newValue);
    setTabValue(newValue);
  };

  const getCatalogsWithStored = () => {
    const mapCatalogMunicipio = getVars('mapCatalogMunicipio', []);
    const mapCatalogSeccion = getVars('mapCatalogSeccion', []);
    const mapCatalogResponsabilidad = getVars('mapCatalogResponsabilidad', []);
    setCatMunicipios(mapCatalogMunicipio);
    if (section) setCatSecciones(mapCatalogSeccion);
    if (responsability) setCatResponsabilidad(mapCatalogResponsabilidad);
  };

  useEffect(() => {
    const filter_saved = getVars('filterSavedMap');
    if (filter_saved.idMunicipio !== '') {
      setFilter(filter_saved);
      getCatalogsWithStored();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const map = getVars('mapCatalogMunicipio', '');
    if (map === '' && catalogs?.municipios_reportes) {
      setCatMunicipios(catalogs.municipios_reportes);
      if (responsability) setCatResponsabilidad(catalogs?.estructura_electoral);
    }
    // eslint-disable-next-line
  }, [catalogs]);

  const emptyOptions = () => {
    if (section)
      setCatSecciones([
        { value: 0, label: `Selecciona un ${tabValue === AMBITO_MPAL ? 'municipio' : 'distrito'}` },
      ]);
  };

  const loadCatalogsSections = async (value = 0, name) => {
    setFilter({ ...filter, [name]: value, Seccion: '' });

    if (section) {
      const catalogsParams = [];

      if (value > 0) catalogsParams.push({ id: name, value: value, filter: '=' });
      else {
        setCatSecciones([
          { value: 0, label: `Selecciona un ${name === 'idMunicipio' ? 'municipio' : 'distrito'}` },
        ]);
        return true;
      }

      try {
        setIsLoadingCatSecciones(true);

        const result = await CatalogService.getCatalogsNationalSections(catalogsParams);
        const { success, results, response, message } = result;

        if (success && results) {
          setCatSecciones([{ value: 0, label: 'TODAS' }].concat(response.data));
        } else {
          Swal.fire({ title: message, icon: 'warning' });
          setCatSecciones([]);
        }
      } catch (e) {
        Swal.fire({ title: e.message, icon: 'warning' });
      } finally {
        setIsLoadingCatSecciones(false);
        setFilter({ ...filter, [name]: value, Seccion: '' });
      }
    }
  };

  const handleChangeGlobal = (localValue, name) => {
    const clear = { idMunicipio: [], idDF: [], idDL: [], Seccion: 0, porAvance: 0 };

    switch (name) {
      case 'idTipo':
        emptyOptions();
        if (localValue !== 10) {
          // diferente a TODOS
          const filterData = catalogs?.estructura_electoral.filter((item) => item.Acreditar === localValue);
          if (filterData.length === 0) setCatResponsabilidad([]);
          else setCatResponsabilidad([catalogs?.estructura_electoral[0], ...filterData]);
        } else setCatResponsabilidad(catalogs?.estructura_electoral);
        setFilter({ [name]: localValue, idResponsabilidad: 0, ...clear });
        break;
      case 'idResponsabilidad':
        emptyOptions();
        setFilter({ ...filter, [name]: localValue, ...clear });
        break;
      case 'idRegion':
        if (localValue.includes(0) && localValue.length > 1) {
          if (localValue.indexOf(0) > 0) localValue = [0];
          else localValue = localValue.filter((value) => value !== 0);
        }
        emptyOptions();
        const newCat = catalogs.municipios_reportes.filter(
          (item) => localValue.includes(item.idRegion) || item.value === 0
        );
        setCatMunicipios(newCat.length === 1 ? catalogs.municipios_reportes : newCat);
        setFilter({ ...filter, [name]: localValue, ...clear });
        break;
      case 'Seccion':
      case 'porAvance':
        setFilter({ ...filter, [name]: localValue });
        break;
      default: // idMunicipio, idDF y idDL
        if (localValue.includes(0) && localValue.length > 1) {
          if (localValue.indexOf(0) > 0) localValue = [0];
          else localValue = localValue.filter((value) => value !== 0);
        }
        loadCatalogsSections(localValue, name);
        break;
    }
  };

  const valid = (value, noValid) => {
    if (noValid !== undefined && Array.isArray(value))
      return value !== '' && value !== undefined && value.length !== noValid;
    if (noValid !== undefined) return value !== '' && value !== undefined && value !== noValid;
    return value !== '' && value !== undefined;
  };

  const isIncluded = (values) => values.includes(tabValue);

  const handleClick = (filter_saved) => {
    let filter_data = [];
    let shp = 'entidad';

    const same = { id: 'tb.id', filter: 'IN' };

    if (valid(filter.idTipo, 10) && type) {
      filter_data.push({ id: 'perfiles.Acreditar', filter: '=', value: filter.idTipo });
    }
    if (valid(filter.idResponsabilidad, 0) && responsability) {
      filter_data.push({ id: 'perfiles.id', filter: '=', value: filter.idResponsabilidad });
    }

    filter_data.push({ id: 'tb.idEntidad', filter: '=', value: 11 });

    if (valid(filter.idRegion, 0) && !valid(filter.idMunicipio, 0)) {
      if (!filter.idRegion.includes(0)) filter_data.push({ ...same, value: filter.idRegion });
      shp = 'region';
    }
    if (valid(filter.idMunicipio, 0)) {
      if (!filter.idMunicipio.includes(0)) filter_data.push({ ...same, value: filter.idMunicipio });

      // Trae todos los municipios de esa región
      if (valid(filter.idRegion, 0) && filter.idMunicipio.includes(0)) {
        const ids = catMunicipios.map((item) => item.value).filter((value) => value !== 0);
        filter_data.push({ ...same, value: ids });
      }

      shp = 'municipio';
    }
    if (valid(filter.idDF, 0)) {
      if (!filter.idDF.includes(0)) filter_data.push({ ...same, value: filter.idDF });
      shp = 'dfed';
    }
    if (valid(filter.idDL, 0)) {
      if (!filter.idDL.includes(0)) filter_data.push({ ...same, value: filter.idDL });
      shp = 'dloc';
    }
    if (valid(filter.Seccion, 0) && section) {
      filter_data.push({ id: 'tb.Seccion', filter: '=', value: filter.Seccion });
      shp = 'seccion';
    }
    if (valid(filter.porAvance, 0)) {
      filter_data.push({ id: 'avance', filter: '=', value: catalogs?.avance[filter.porAvance]?.label });
    }

    handleFilter(filter_data, tabValue, shp);
    setVars('mapCatalogMunicipio', catMunicipios);
    if (section) setVars('mapCatalogSeccion', catSecciones);
    if (responsability) setVars('mapCatalogResponsabilidad', catResponsabilidad);

    const tipo = type ? { idTipo: filter.idTipo } : {};
    const responsabilidad = responsability ? { idResponsabilidad: filter.idResponsabilidad } : {};

    setVars('filterSavedMap', {
      ...tipo,
      ...responsabilidad,
      idRegion: filter.idRegion,
      idMunicipio: filter.idMunicipio,
      idDF: filter.idDF,
      idDL: filter.idDL,
      Seccion: filter.Seccion,
      porAvance: filter.porAvance,
    });
    setVars('filteredShapeMap', {
      filtered: filter_data,
      tab: tabValue,
      shp: shp,
    });
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="Tabs"
            variant="scrollable"
            scrollButtons={false}
          >
            {!disableTabMunicipio && <Tab label="Municipio" {...a11yProps(AMBITO_MPAL)} sx={{ flex: 1 }} />}
            {!disableTabDFED && <Tab label="DFED" {...a11yProps(AMBITO_DF)} sx={{ flex: 1 }} />}
            {!disableTabDLOC && <Tab label="DLOC" {...a11yProps(AMBITO_DL)} sx={{ flex: 1 }} />}
          </Tabs>
        </Grid>

        {type && isIncluded([AMBITO_MPAL]) && (
          <Grid item xs={12}>
            <BasicSelect
              label="Tipo"
              name="idTipo"
              id="idTipo"
              options={tipoOptions}
              value={filter.idTipo}
              onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}

        {responsability && (
          <Grid item xs={12}>
            <AdvancedSelect
              label="Responsabilidad"
              name="idResponsabilidad"
              id="idResponsabilidad"
              options={catResponsabilidad}
              value={filter.idResponsabilidad}
              onChange={(e) => handleChangeGlobal(e.value, e.name)}
              isLoading={loadingCatalogs}
              isSearchable
            />
          </Grid>
        )}

        {isIncluded([AMBITO_MPAL]) && (
          <Grid item xs={12}>
            <AdvancedSelect
              label="Región"
              name="idRegion"
              id="idRegion"
              disabled={catalogs?.regiones?.length <= 1}
              options={catalogs?.regiones}
              value={filter.idRegion}
              onChange={(e) => handleChangeGlobal(e, 'idRegion')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TabPanel value={tabValue} index={AMBITO_MPAL}>
            <AdvancedSelect
              label="Municipio"
              name="idMunicipio"
              id="idMunicipio"
              disabled={catMunicipios.length <= 1}
              options={catMunicipios}
              value={filter.idMunicipio}
              onChange={(e) => handleChangeGlobal(e, 'idMunicipio')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
          <TabPanel value={tabValue} index={AMBITO_DF}>
            <AdvancedSelect
              label="DFED"
              name="idDF"
              id="idDF"
              disabled={catalogs?.municipios_df?.length <= 1}
              options={catalogs?.municipios_df}
              value={filter.idDF}
              onChange={(e) => handleChangeGlobal(e, 'idDF')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
          <TabPanel value={tabValue} index={AMBITO_DL}>
            <AdvancedSelect
              label="DLOC"
              name="idDL"
              id="idDL"
              disabled={catalogs?.municipios_dl?.length <= 1}
              options={catalogs?.municipios_dl}
              value={filter.idDL}
              onChange={(e) => handleChangeGlobal(e, 'idDL')}
              isLoading={loadingCatalogs}
              multiple
              disableCloseOnSelect
              isSearchable
            />
          </TabPanel>
        </Grid>

        {section && (
          <Grid item xs={12}>
            <AdvancedSelect
              label="Sección"
              name="Seccion"
              id="Seccion"
              disabled={catSecciones.length <= 1}
              options={catSecciones}
              value={filter.Seccion}
              onChange={(e) => handleChangeGlobal(e.value, e.name)}
              isLoading={loadingCatalogs || isLoadingCatSecciones}
              defaultOption={filter.Seccion}
              isSearchable
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <AdvancedSelect
            label="Porcentaje de Avance"
            name="porAvance"
            id="porAvance"
            options={catalogs?.avance}
            value={filter.porAvance}
            onChange={(e) => handleChangeGlobal(e.value, e.name)}
            isLoading={loadingCatalogs}
          />
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={loadingCatalogs || isLoadingCatSecciones}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;
