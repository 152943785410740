import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import TipoDocumentoAutocomplete from './TipoDocumentoAutocomplete';
import DirectorioCDEFiltroAutocomplete from './DirectorioCDEFiltroAutocomplete';
import DirectorioEmpresasFiltroAutocomplete from './DirectorioEmpresasFiltroAutocomplete';
import FilterCollapse from '@components/Filters/FilterCollapse';
import PickerFecha from './PickerFecha';
import dayjs from 'dayjs';

const FiltrosOficialia = ({ setFiltros, showMore }) => {
  const [state, setState] = useState({
    ParaQuien: null,
    DeQuien: null,
    QuienRecibe: null,
    FechaOficio: null,
    FechaTermino: null,
    FechaRecepcion: null,
    FolioOficio: '',
    FolioInterno: '',
    TipoDocumento: null,
    Asunto: '',
    Prioridad: null,
    SolicitaRespuesta: 0,
  });

  useEffect(() => {
    handleFilter();
  }, [showMore]);

  const catPrioridad = [
    { id: 1, Prioridad: 'Normal' },
    { id: 2, Prioridad: 'Medio' },
    { id: 3, Prioridad: 'Alto' },
  ];

  const handleFilter = () => {
    let filtros = [];

    if (state.TipoDocumento) {
      if (state.TipoDocumento.id) {
        filtros.push({
          id: 'o.idTipoDocumento',
          value: `${state.TipoDocumento.id}`,
          filter: '=',
        });
      }
    }

    if (state.Prioridad) {
      if (state.Prioridad.id) {
        filtros.push({
          id: 'o.idPrioridad',
          value: `${state.Prioridad.id}`,
          filter: '=',
        });
      }
    }

    if (state.FolioOficio.length > 0) {
      filtros.push({
        id: 'o.NumOficio',
        value: `%${state.FolioOficio}%`,
        filter: 'LIKE',
      });
    }

    if (state.FolioInterno.length > 0) {
      filtros.push({
        id: 'o.FolioInterno',
        value: `%${state.FolioInterno}%`,
        filter: 'LIKE',
      });
    }

    if (state.Asunto.length > 0) {
      filtros.push({
        id: 'o.Asunto',
        value: `%${state.Asunto}%`,
        filter: 'LIKE',
      });
    }

    if (state.FechaOficio) {
      filtros.push({
        id: 'to_days(o.FechaOficio)',
        value: `to_days(${dayjs(state.FechaOficio).format('YYYY-MM-DD')})`,
        filter: '=',
      });
    }

    if (state.FechaRecepcion) {
      filtros.push({
        id: 'to_days(o.FechaRecibido)',
        value: `to_days(${dayjs(state.FechaRecepcion).format('YYYY-MM-DD')})`,
        filter: '=',
      });
    }

    if (state.FechaTermino) {
      filtros.push({
        id: 'to_days(o.FechaTermino)',
        value: `to_days(${dayjs(state.FechaTermino).format('YYYY-MM-DD')})`,
        filter: '=',
      });
    }

    if (state.DeQuien) {
      if (state.DeQuien.id) {
        filtros.push({
          id: 'o.idDeQuien',
          value: `${state.DeQuien.id}`,
          filter: '=',
        });
      }
    }

    if (state.ParaQuien) {
      if (state.ParaQuien.id) {
        filtros.push({
          id: 'o.idParaQuien',
          value: `${state.ParaQuien.idUsuarioSIAN}`,
          filter: '=',
        });
      }
    }

    if (state.QuienRecibe) {
      if (state.QuienRecibe.id) {
        filtros.push({
          id: 'o.idUsuarioRecibio',
          value: `${state.QuienRecibe.idUsuarioSIAN}`,
          filter: '=',
        });
      }
    }

    if (filtros.length) {
      if (showMore) {
        const newFilter = {
          id: ' and o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
          filterQuery: true,
        };

        const filterExists = filtros.some((filter) => filter.id === newFilter.id && filter.filterQuery);

        if (!filterExists) {
          filtros.push(newFilter); // Agrega el nuevo filtro si no existe
        }
      }
    } else {
      if (showMore) {
        filtros.push({
          id: ' where o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
          filterQuery: true,
        });
      }
    }

    setFiltros(filtros);
  };

  const clearFilter = () => {
    setState({
      ParaQuien: null,
      DeQuien: null,
      QuienRecibe: null,
      FechaOficio: null,
      FechaTermino: null,
      FolioOficio: '',
      FolioInterno: '',
      TipoDocumento: null,
      Asunto: '',
      Prioridad: null,
      SolicitaRespuesta: 0,
    });

    setFiltros([]);
  };
  const handleFechaOficio = (fecha) => {
    setState({ ...state, FechaOficio: fecha });
  };

  const handleFechaTermino = (fecha) => {
    setState({ ...state, FechaTermino: fecha });
  };

  const handleFechaRecepcion = (fecha) => {
    setState({ ...state, FechaRecepcion: fecha });
  };

  return (
    <>
      <FilterCollapse>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Grid2 container spacing={3} sx={{ mt: 2 }}>
            <Grid2 xs={12} sm={12} md={2} lg={2}>
              <br />
              <TipoDocumentoAutocomplete value={state} setValue={setState} handleChange={null} />
            </Grid2>
            <Grid2 xs={12} sm={12} md={2} lg={2}>
              <br />
              <Autocomplete
                value={state.Prioridad}
                onChange={(event, newValue) => {
                  console.log('=>newValue Prioridad: ', newValue);
                  setState({ ...state, Prioridad: newValue });
                }}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'clear') {
                    setState({ ...state, Prioridad: null });
                  }
                }}
                options={catPrioridad}
                getOptionLabel={(option) => option.Prioridad || ''}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prioridad"
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: 40, // Ajusta la altura para que sea más delgado
                        borderRadius: 2, // Redondeo similar a Select
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: 14, // Ajusta el tamaño de fuente
                      },
                    }}
                  />
                )}
                fullWidth
              />
            </Grid2>

            <Grid2 xs={12} sm={12} md={2} lg={2}>
              <br />
              <TextField
                label={'FolioOficio'}
                value={state.FolioOficio}
                onChange={(e) => setState({ ...state, FolioOficio: e.target.value })}
                name="FolioOficio"
                sx={{ width: '100%' }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={2} lg={2}>
              <br />
              <TextField
                label={'Folio Interno'}
                value={state.FolioInterno}
                onChange={(e) => setState({ ...state, FolioInterno: e.target.value })}
                name="FolioInterno"
                sx={{ width: '100%' }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={3} sx={{ mt: 2 }}>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <TextField
                label={'Asunto'}
                value={state.Asunto}
                onChange={(e) => setState({ ...state, Asunto: e.target.value })}
                name="Asunto"
                sx={{ width: '100%' }}
                type="text"
                variant="outlined"
                size="small"
                className="fixed-input"
              />
            </Grid2>
            <Grid2 xs={4} sm={12} md={2} lg={2}>
              <PickerFecha
                label={'Fecha oficio'}
                requerido={false}
                helperText="Este campo es obligatorio"
                value={state.FechaOficio}
                handleChange={handleFechaOficio}
              />
            </Grid2>
            <Grid2 xs={4} sm={12} md={2} lg={2}>
              <PickerFecha
                label={'Fecha término'}
                requerido={false}
                helperText="Este campo es obligatorio"
                value={state.FechaTermino}
                handleChange={handleFechaTermino}
              />
            </Grid2>
            <Grid2 xs={4} sm={12} md={2} lg={2}>
              <PickerFecha
                label={'Fecha recepción'}
                requerido={false}
                helperText="Este campo es obligatorio"
                value={state.FechaRecepcion}
                handleChange={handleFechaRecepcion}
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={3} sx={{ mt: 2 }}>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <DirectorioCDEFiltroAutocomplete
                value={state}
                setValue={setState}
                label="Para Quien"
                name="ParaQuien"
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <DirectorioEmpresasFiltroAutocomplete value={state} setValue={setState} />
            </Grid2>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <DirectorioCDEFiltroAutocomplete
                value={state}
                setValue={setState}
                label="Recibe"
                name="QuienRecibe"
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <br />
            </Grid2>
          </Grid2>

          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button variant="contained" color="primaryDark" onClick={handleFilter}>
              Filtrar
            </Button>
            <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
              Limpiar
            </Button>
          </Box>
        </Box>
      </FilterCollapse>
    </>
  );
};
export default FiltrosOficialia;
