import { useState } from 'react';

// Material UI
import { Grid, Button, Container } from '@mui/material';
import { Add } from '@mui/icons-material';

import Swal from 'sweetalert2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import TableRegistro from '@components/ppm/table_registros';
import RegisterForm from '@components/ppm/Register_form';

//SERVICES
import ppmServices from '@services/PpmServices';

//UTILS
import { convertBase64 } from '@utils/Utilities';

//MIDDLEWARE
import middleware from '@middlewares/middleware';

const Registro = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const hasAddPermission = middleware.checkMenuAction('Agregar');
  const titleModal = 'Registrar nuevo PPM';

  const handleInsertData = async (values) => {
    try {
      setLoading(true);

      const file = values.files[0].file;

      if (file) {
        const base64File = await convertBase64(file);
        values.files[0].file = base64File;
      }

      ['idUnico', 'comparar'].forEach((prop) => {
        if (values.hasOwnProperty(prop)) {
          delete values[prop];
        }
      });

      const result = await ppmServices.setPersonInfo(values);
      const { results, message } = result;
      if (results) {
        setRefreshApi((prev) => !prev);
        Swal.fire({
          title: message,
          icon: 'success',
          customClass: {
            container: 'modal-alert',
          },
        });
        setOpenModal(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Gestión de Registros PPM'} isCustom={true}>
          {hasAddPermission ? (
            <Button
              variant="contained"
              color="primaryDark"
              size="small"
              endIcon={<Add />}
              onClick={() => setOpenModal(true)}
            >
              Agregar Nuevo
            </Button>
          ) : null}
        </ActionHeader>
        <LoadingForm
          isLoading={loading}
          isOpen={loading}
          setIsOpen={() => setLoading(!loading)}
          loadinMessage={'Cargando...'}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableRegistro setIsLoading={(e) => setLoading(e)} refresh={refreshApi} />
          </Grid>
        </Grid>
        {openModal && (
          <RegisterForm
            setModalOpen={setOpenModal}
            modalOpen={openModal}
            title={titleModal}
            setData={handleInsertData}
          />
        )}
      </Container>
    </AdminLayout>
  );
};

export default Registro;
