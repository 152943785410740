import React, { useEffect, useState } from 'react';
import { Card, Container, CardContent, Stack, Typography, Icon, Grid, Box, Button } from '@mui/material';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import InputSelect from '@components/Selects/BasicSelect';
import AttendanceCard from '@components/Attendance/AttendanceCard';
import BasicTable from '@components/Tables/BasicTable';
import CompromisoServices from '@services/CompromisoServices';
import CatalogServices from '@services/CatalogServices';
import Swal from 'sweetalert2';
import { getVars } from '@utils/global';
import MessageAlert from '@components/Downloads/MessageAlert';
import Download from '@components/Downloads/Download';

const Filters = ({ values, catMunicipios, catRegiones, loading, handleChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <InputSelect
          label="Región"
          //name="idRegion"
          value={values.idRegion}
          onChange={(e) => handleChange(e, 'region')}
          options={catRegiones}
          sx={{ width: '100%' }}
          isLoading={loading}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputSelect
          label="Municipio"
          //name="idMunicipio"
          value={values.idMunicipio}
          onChange={(e) => handleChange(e, 'municipio')}
          options={catMunicipios}
          sx={{ width: '100%' }}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
};

const AttendanceReport = () => {
  const [access_token] = useState(() => {
    let t = getVars('Token');
    if (t !== {}) return t.access_token;
    return '';
  });
  const columns = [
    { id: 'Region', label: 'Región' },
    { id: 'Municipio', label: 'Municipio' },
    { id: 'Compromisos', label: 'Compromisos' },
    { id: 'Militantes', label: 'Militantes' },
    { id: 'Asistio', label: 'Asistentes Compromisos' },
    { id: 'AsistioMilitante', label: 'Asistentes Militantes' },
  ];
  const defaultOption = { value: 0, label: 'TODOS' };

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [catRegiones, setCatRegiones] = useState([defaultOption]);
  const [dataReport, setDataReport] = useState([]);
  const [catMunicipios, setCatMunicipios] = useState([defaultOption]);
  const [catMunicipiosFiltered, setCatMunicipiosFiltered] = useState([]);
  const [dataReportFiltered, setDataReportFiltered] = useState([]);

  const [filter, setFiltered] = useState({ filtered: [] });
  const [values, setValues] = useState({
    idRegion: 0,
    idMunicipio: 0,
  });
  const [compromisos, setCompromisos] = useState({
    meta: 0,
    avance: 0,
    porcentaje: 0,
  });
  const [militantes, setMilitantes] = useState({
    meta: 0,
    avance: 0,
    porcentaje: 0,
  });

  const getAttendanceReport = () => {
    setLoading(true);
    CompromisoServices.getAttendanceReport(filter)
      .then((res) => {
        if (res.results) {
          const attendanceReport = res.response.data;
          setDataReport(attendanceReport);
          const dataFiltered = res.response.data.slice(0, pageSize);
          setDataReportFiltered(dataFiltered);
          setTotal(res.response.total);

          //funciones para los resumen de las cards
          dataSummaryCardCompromiso(attendanceReport);
          dataSummaryCardMilitantes(attendanceReport);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err.message, icon: 'warning' }))
      .finally(() => {
        setPage(0);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    setIsDownloading(true);
    CompromisoServices.exportAttendanceReport(filter, access_token)
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const handleChange = (e, type) => {
    let filterAux = [];

    if (type === 'region') {
      const idRegion = e.target.value;
      setValues({
        ...values,
        idRegion: idRegion,
      });

      if (idRegion > 0) {
        setCatMunicipiosFiltered(
          [defaultOption].concat(catMunicipios.filter((item) => item.idRegion === idRegion))
        );

        setFiltered({
          filtered: [{ id: 'M.idRegion', filter: '=', value: e.target.value }],
        });
      } else {
        setCatMunicipiosFiltered(catMunicipios);
        setFiltered({ filtered: filterAux });
      }
    } else {
      const idMunicipio = e.target.value;
      const municipios = catMunicipios.filter((item) => item.value === idMunicipio);
      setValues({
        ...values,
        idRegion: municipios[0].idRegion,
        idMunicipio: idMunicipio,
      });

      if (idMunicipio > 0) {
        setValues({
          ...values,
          idRegion: municipios[0].idRegion,
          idMunicipio: idMunicipio,
        });
        setFiltered({
          filtered: [
            { id: 'M.idRegion', filter: '=', value: municipios[0].idRegion },
            { id: 'M.id', filter: '=', value: idMunicipio },
          ],
        });
      } else {
        setFiltered({
          filtered: [{ id: 'M.idRegion', filter: '=', value: values.idRegion }],
        });
        setValues({
          idRegion: values.idRegion,
          idMunicipio: idMunicipio,
        });
      }
    }
  };

  const dataSummaryCardCompromiso = (data) => {
    const metaCompromiso = data.reduce((sum, value) => sum + value.Compromisos, 0);
    const avanceCompromiso = data.reduce((sum, value) => sum + value.Asistio, 0);
    const porcentajeCompromiso = ((avanceCompromiso / metaCompromiso) * 100).toFixed(2);

    setCompromisos({
      ...compromisos,
      meta: metaCompromiso,
      avance: avanceCompromiso,
      porcentaje: porcentajeCompromiso,
    });
  };

  const dataSummaryCardMilitantes = (data) => {
    const metaMilitantes = data.reduce((sum, value) => sum + value.Militantes, 0);

    const avanceMilitantes = data.reduce((sum, value) => sum + value.AsistioMilitante, 0);

    const porcentajeMilitantes = ((avanceMilitantes / metaMilitantes) * 100).toFixed(2);

    setMilitantes({
      ...militantes,
      meta: metaMilitantes,
      avance: avanceMilitantes,
      porcentaje: porcentajeMilitantes,
    });
  };

  useEffect(() => {
    const catalogsParams = [
      {
        id: 'regiones',
      },
      { id: 'municipios' },
    ];
    setLoadingCatalogs(true);
    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case 'regiones':
                setCatRegiones(
                  [defaultOption].concat(res.response.catalogs[item.id].filter((item) => item.value !== 9999))
                );
                break;
              case 'municipios':
                setCatMunicipios([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipiosFiltered([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              default:
                break;
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }))
      .finally(() => setLoadingCatalogs(false));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAttendanceReport();
    //eslint-disable-next-line
  }, [filter]);

  const handleChangePage = ({ page, pageSize }) => {
    const start = page * pageSize;
    const end = start + pageSize;

    const dataFiltered = dataReport.slice(start, end);
    setDataReportFiltered(dataFiltered);
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reporte de asistencias" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <AttendanceCard
              titlecontent={'Compromisos'}
              data={{
                contentData: compromisos.porcentaje,
                leftFooterData: compromisos.meta,
                rightFooterData: compromisos.avance,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AttendanceCard
              titlecontent={'Militantes'}
              data={{
                contentData: militantes.porcentaje,
                leftFooterData: militantes.meta,
                rightFooterData: militantes.avance,
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false}>
        <Card className="card-primary">
          <CardContent>
            <Stack direction={'row'} spacing={1} marginBottom={2} alignItems="center">
              <Icon>tune</Icon>
              <Typography variant="subtitle2" fontWeight={600}>
                Filtro
              </Typography>
            </Stack>

            <Filters
              values={values}
              catMunicipios={catMunicipiosFiltered}
              catRegiones={catRegiones}
              loading={loadingCatalogs}
              handleChange={handleChange}
            />
          </CardContent>
        </Card>
      </Container>

      <Container maxWidth={false} sx={{ mt: 2 }}>
        <Card className="card-primary">
          <CardContent>
            {isDownloading ? (
              <Download isDownload={isDownloading} format="xlsx" />
            ) : (
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  size="small"
                  startIcon={<Icon>download</Icon>}
                  //disabled={values.idRegion === "" && values.idMunicipio === ""}
                  onClick={handleDownload}
                  sx={{ borderRadius: '10px' }}
                >
                  Exportar
                </Button>
              </Box>
            )}

            {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}

            <BasicTable
              rows={dataReportFiltered}
              hcolumns={columns}
              total={total}
              pageProp={page}
              pageSize={pageSize}
              stickyHeader={true}
              isLoading={loading}
              handleChangePagination={handleChangePage}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default AttendanceReport;
