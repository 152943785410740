import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { Swal } from '@utils/alerts';

// Material UI
import { Box, Button, Card, CardContent, Container, Icon, ListItemText, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Events/PaseListaFilter';
import CustomTable from '@components/Tables/CustomTable';

// Servicios
import EventsServices from '@services/EventsServices';

// Utils
import { isEmptyOrNullObject } from '@utils/validations';

const EventosPaseLista = ({ idTipoEvento = 0 }) => {
  const navigate = useNavigate();

  const filter = idTipoEvento ? [{ id: 'cat_actividades.eventType', filter: '=', value: idTipoEvento }] : [];

  const colums = [
    { label: 'Fecha', id: 'FechaInicio' },
    { label: 'Evento', id: 'Actividad' },
    { label: 'Hora Inicio', id: 'HoraInicio', align: 'center' },
  ];

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: filter,
    sorted: [{ id: 'eventos.FechaInicio', value: 'desc' }],
  });
  const [dataListEvents, setDataListEvents] = useState([]);
  const [total, setTotal] = useState(0);

  const getEventosData = useCallback(async (data) => {
    try {
      setLoading(true);

      const result = await EventsServices.getMyRecords(data);
      const { results, response, message } = result;

      if (results) {
        const Eventos = response.data.map((item) => ({
          ...item,
          Actividad: (
            <Button
              disableElevation
              variant="text"
              color="primaryDark"
              size="small"
              sx={{ '&:hover': { bgcolor: blue[900], color: 'white' }, p: '0 auto', textAlign: 'left' }}
              startIcon={<Icon>edit</Icon>}
              onClick={() => handlePaseLista(item)}
              disabled={!isSameDay(new Date(item.FechaFin + ' 00:00:00'), new Date())}
            >
              <ListItemText
                primary={
                  <Typography variant="div" fontWeight={700} fontSize={15}>
                    {item.Actividad}
                  </Typography>
                }
              />
            </Button>
          ),
        }));
        setDataListEvents(Eventos);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoading(false);
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEventosData(data); // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (idTipoEvento)
      setData({
        page: 0,
        pageSize: 25,
        filtered: filter,
        sorted: [{ id: 'eventos.FechaInicio', value: 'desc' }],
      }); // eslint-disable-next-line
  }, [idTipoEvento]);

  const handlePaseLista = ({ id }) => navigate(`${id}`);

  const handleFilter = (filtered) => setData((prev) => ({ ...prev, filtered: filter.concat(filtered) }));

  const handlePagination = (pagination) => setData({ ...data, ...pagination });

  const handleSort = (sort) => {
    const fields = {
      Actividad: 'eventos.Actividad',
      FechaInicio: 'eventos.FechaInicio',
      HoraInicio: "DATE_FORMAT(eventos.FechaInicio,'%H:%i:%s')",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(sort)) {
      sorted.push({ id: fields[sort.orderBy], value: sort.order });
    }

    if (total > 0) setData({ ...data, sorted });
  };

  return (
    <AdminLayout key={idTipoEvento}>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <ActionHeader title="Pase de Lista en eventos" />

        <Box>
          <Filter onChangeFilter={handleFilter} />
          <Card className="card-primary">
            <CardContent>
              <CustomTable
                rows={dataListEvents}
                columns={colums}
                handlePagination={handlePagination}
                isLoading={loading}
                total={total}
                page={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleSort={handleSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default EventosPaseLista;
