import { useState, useEffect } from 'react';

// Material UI
import { Box, Button, Grid } from '@mui/material';

// Componentes
import InputSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

const Filter = ({ onChangeFilter, catalogs, load }) => {
  //variables
  const defaultOption = { value: 0, label: 'TODOS' };
  const catRutas = [defaultOption, { value: 1, label: 'Sin Ruta' }, { value: 2, label: 'Con Ruta' }];

  //States
  const [catRegions, setCatRegions] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);

  const [catRutasList, setRutasList] = useState([]);
  const [catRutasFilter, setRutasFilter] = useState([]);
  const [clean, setClean] = useState(false);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Seccion: [],
    Ruta: 0,
    RutaId: 0,
  });

  useEffect(() => {
    if (!load) {
      setCatRegions([defaultOption].concat(catalogs?.regiones));
      setCatMunicipality([defaultOption].concat(catalogs?.municipios_dl));
      setCatMunicipalityFilter([defaultOption].concat(catalogs?.municipios_dl));
      setCatSection(catalogs?.secciones);
      setCatSectionFilter(catalogs?.secciones);
      setRutasList([defaultOption].concat(catalogs?.rutas_mrt));
      setRutasFilter([defaultOption].concat(catalogs?.rutas_mrt));
    }
    // eslint-disable-next-line
  }, [catalogs]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({ ...dataCatalog, Region: idRegion, Municipio: 0, Seccion: [], RutaId: 0 });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(catMunicipality.filter((item) => item.idRegion === idRegion))
      );
      setCatSectionFilter(catSection.filter((item) => item.idRegion === idRegion));
      setRutasFilter(catRutasList.filter((item) => item.idRegion === idRegion));
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatSectionFilter(catSection);
      setRutasFilter(catRutasList);
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMun = e.target.value;
    setDataCatalog({ ...dataCatalog, Municipio: idMun, RutaId: 0, Seccion: [] });

    if (idMun > 0) {
      setCatSectionFilter(catSection.filter((item) => item.idMunicipioReportes === idMun));
      setRutasFilter(catRutasList.filter((item) => item.idMunicipioReportes === idMun));
    } else {
      setRutasFilter(catRutasList);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeRutasList = (e) => {
    const statusRuta = e.value;
    setDataCatalog({ ...dataCatalog, RutaId: statusRuta });
  };

  const handleChangeRutas = (e) => {
    const statusRuta = e.target.value;
    setDataCatalog({ ...dataCatalog, Ruta: statusRuta });
  };

  const handleChangeSection = (newValue) => setDataCatalog({ ...dataCatalog, Seccion: newValue });

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      RutaId: dataCatalog.RutaId,
      Ruta: dataCatalog.Ruta,
      Seccion: dataCatalog.Seccion,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);

    const newFilter = {
      Region: 0,
      Municipio: 0,
      Seccion: [],
      Ruta: 0,
      RutaId: 0,
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatSectionFilter(catSection);
    setRutasFilter(catRutasList);
    setDataCatalog({ ...dataCatalog, ...newFilter });
    onChangeFilter(newFilter);
  };

  return (
    <>
      <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Región"
            options={catRegions}
            name="region"
            value={catRegions.length === 2 ? catRegions[1].value : dataCatalog.Region}
            onChange={handleChangeRegion}
            isLoading={load}
            disabled={catRegions.length === 2 || load}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Municipio"
            options={catMunicipalityFilter}
            name="municipio"
            value={
              catMunicipalityFilter.length === 2 ? catMunicipalityFilter[1].value : dataCatalog.Municipio
            }
            onChange={handleChangeMunicipality}
            isLoading={load}
            disabled={catMunicipality.length === 2 || load}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AdvancedSelect
            name="Ruta"
            label="Ruta"
            options={catRutasFilter}
            value={catRutasFilter.length === 2 ? catRutasFilter[1].value : dataCatalog.RutaId}
            onChange={handleChangeRutasList}
            isSearchable
            isLoading={load}
            disabled={catRutasList.length === 2 || load}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AdvancedSelect
            name="seccion"
            label="Sección"
            options={catSectionFilter}
            value={catSectionFilter === 1 ? [catSectionFilter[0]] : dataCatalog.Seccion}
            onChange={handleChangeSection}
            multiple
            isSearchable
            disableCloseOnSelect
            isLoading={load}
            disabled={catSectionFilter.length === 1 || load}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Estatus Sección"
            options={catRutas}
            name="Rutas"
            value={dataCatalog.Ruta}
            onChange={handleChangeRutas}
            isLoading={load}
            disabled={load}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant="contained" color="primaryDark" onClick={searchFilter}>
          Filtrar
        </Button>
        <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
          Limpiar
        </Button>
      </Box>
    </>
  );
};

export default Filter;
