import { Badge, Box, Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';

const SummaryCasillas = ({ casillas = [], selected, handleClick }) => {
  return (
    <Card className="card-primary">
      <CardContent>
        <Typography textAlign={'center'} marginBottom={1} variant="body2" fontWeight={600}>
          RESUMEN - CASILLAS
        </Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'center'}>
          <Badge variant="dot" badgeContent=" " color="success" />
          <Typography variant="subtitle2">Capturadas</Typography>
          <Badge
            variant="dot"
            badgeContent=" "
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#0000004d',
              },
            }}
          />
          <Typography variant="subtitle2">No Capturadas</Typography>
        </Stack>

        {casillas.length > 0 ? (
          <>
            <Typography variant="body2" marginTop={1} fontWeight={600}>
              Para continuar con el formulario de captura que se encuentra a continuación, por favor marca la
              casilla de opción 'No capturadas'.
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ height: '130px', overflowY: 'scroll' }}>
              {casillas.map(({ id, Seccion, NombreCasilla, esAperturaCasilla }) => (
                <Chip
                  key={id}
                  disabled={esAperturaCasilla ? true : false}
                  label={`SECCIÓN ${Seccion} - ${NombreCasilla}`}
                  variant={selected === id ? 'outlined' : 'filled'}
                  color={selected === id ? 'primary' : 'default'}
                  onClick={() => handleClick({ itemChip: { id, Seccion } })}
                  sx={{
                    mr: 1,
                    mb: 1,
                    backgroundColor: esAperturaCasilla && green[50],
                    color: esAperturaCasilla && green[900],
                    '&.Mui-disabled': {
                      opacity: 1,
                    },
                  }}
                />
              ))}
            </Box>
          </>
        ) : (
          <Typography marginTop={4} textAlign={'center'}>
            No tiene casillas para capturar.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default SummaryCasillas;
