import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: null,
  },
});

const BasicBarColumnPorcent = (props) => {
  const { categories, series, title, subtitle, textX, loading = false, colors, initialPosition } = props;
  const [data, setData] = useState(null);

  const mapData = (serie, Meta, name, index) => {
    return serie.map((item, i) => {
      let percent = 100;
      const meta = Meta && Meta[i] !== undefined ? Meta[i] : 0;

      if (item === 0) {
        percent = 0;
      }
      if (item >= meta) {
        percent = 100;
      }
      if (item < meta) {
        percent = parseFloat(((item / meta) * 100).toFixed(2));
      }

      return {
        y: percent,
        value: item,
        name: name,
      };
    });
  };

  useEffect(() => {
    const updatedSeries = series.map((item, index) => {
      return {
        name: item.name,
        color: item.color,
        dataLabels: [
          {
            enabled: true,
            inside: false,
            format: `{point.y}%`,
            style: { fontSize: '0.85em' },
          },
        ],
        data: mapData(item.data, item.Meta, item.name, index),
      };
    });

    const chartOptions = {
      chart: {
        type: 'column',
        scrollablePlotArea: {
          minWidth: categories.length * 100,
          scrollPositionX: initialPosition ? 0 : 1,
        },
      },
      title: {
        text: title,
      },
      subtitle: {
        text: subtitle,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: [
        { title: { text: '' }, min: 0 },
        { title: { text: '' }, opposite: true },
      ],
      legend: { shadow: false },
      tooltip: {
        shared: true,
        headerFormat: `<span>{point.x}</span><br/>`,
        pointFormat: `<span style="color:{point.color}">\u25CF</span> <b>{point.name}: {point.value} ({point.y}%)</b><br/>`,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            format: '{point.y}%',
            style: {
              fontSize: '0.85em',
            },
          },
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      series: updatedSeries, // Aquí pasamos el array actualizado correctamente
    };

    setData(chartOptions);
    // eslint-disable-next-line
  }, [series, title, categories, initialPosition, textX]);

  return loading ? <CircularProgress /> : data && <HighchartsReact highcharts={Highcharts} options={data} />;
};

export default BasicBarColumnPorcent;
