import { Button, Icon, Stack, Typography, Link, Box } from '@mui/material';

const ActionHeader = (props) => {
  const {
    title,
    titleLink,
    link,
    titleButton = 'Agregar',
    iconButton = 'add',
    isAction = false,
    isLink = false,
    isCustom = false,
    isComponent = false,
    component,
    handleclick,
  } = props;

  return (
    <Stack direction="row" marginBottom={4} justifyContent="end" alignItems="center" flexWrap="wrap" gap={1}>
      <Typography variant="h5" fontWeight={700}>
        {title}
      </Typography>

      {/* Separación */}
      <Box sx={{ flexGrow: 1 }} />

      {isCustom && <>{props.children}</>}

      {isAction && (
        <Button
          variant="contained"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          size="small"
          color="primaryDark"
          startIcon={<Icon>{iconButton}</Icon>}
          onClick={(e) => handleclick()}
        >
          {titleButton}
        </Button>
      )}

      {isLink && (
        <Button
          component={Link}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          href={link}
          size="small"
          target="_blank"
          fontWeight={700}
          variant="outlined"
          /* endIcon={<OpenInNewIcon />} */
        >
          {titleLink}
        </Button>
      )}

      {isComponent && component}
    </Stack>
  );
};

export default ActionHeader;
