import { useEffect, useState } from 'react';

import { Card, CardContent, Typography, Box } from '@mui/material';
import Swal from 'sweetalert2';
import ErrorIcon from '@mui/icons-material/Error';

import movilizacion from '@services/MovilizationServices';
import BarColumnTriplePercent from '@components/Charts/BarColumnTriplePercent';

const ChartMobilizationProgress = ({ body, setLoading, openDrawer, ambitoUser }) => {
  const [dataChart, setDataChart] = useState({
    categories: [],
    series: [],
    title: '',
  });

  // controlar si no se encontraron resultados
  const [noResults, setNoResults] = useState(false);

  const mobilizedChartTerritorial = async (body) => {
    const filteredBody = {
      ...body,
      filtered: body.filtered.filter((item) => item.id !== 'PorcentajeAvance'),
    };

    setLoading((prevState) => ({
      ...prevState,
      isLoadigForm: true,
      openLinearProgress: true,
    }));

    try {
      const res = await movilizacion.getMobilizedChartTerritorial(filteredBody);

      const { results, message, response } = res;
      if (results && response && response.series && response.categories) {
        setDataChart({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
        });

        setNoResults(false);
      } else {
        setDataChart({
          categories: [],
          series: [],
          title: '',
        });
        setNoResults(true);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        isLoadigForm: false,
        openLinearProgress: false,
      }));
    }
  };

  useEffect(() => {
    if (ambitoUser) mobilizedChartTerritorial(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary">
      <CardContent key={`Bingo_${openDrawer}`}>
        {noResults ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
            }}
          >
            <ErrorIcon sx={{ fontSize: 40, mt: 1 }} />
            <Typography fontWeight={600} variant="subtitle2" marginTop={2}>
              No se encontraron resultados
            </Typography>
          </Box>
        ) : (
          <>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              {dataChart.title}
            </Typography>
            <BarColumnTriplePercent series={dataChart.series} categories={dataChart.categories} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ChartMobilizationProgress;
