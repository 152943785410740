import { useState, useEffect } from 'react';
import { Swal } from '@utils/alerts';

// Material UI y estilos
import { Container, Button, ButtonGroup, Card, CardContent, Grid } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import Calendario from '@components/Events/Calendario';
import ListEventos from '@components/Events/ListEventos';

// Servicios y utilidades
import CatalogServices from '@services/CatalogServices';

const Eventos = (props) => {
  const { idTipoEvento = 0, URL = '', id = 'EventosCapa' } = props;

  const [tabButton, setTabButton] = useState(1);

  const handleCalendarioClick = (e) => setTabButton(e);

  const [catalogs, setCatalogs] = useState({});
  const [isLoadingCat, setIsLoadingCat] = useState({});

  useEffect(() => {
    getCatalogs(); // eslint-disable-next-line
  }, [id]);

  const getCatalogs = async () => {
    try {
      const catalogsParams = [
        { id: 'regiones', getAll: false },
        { id: 'municipios', getAll: false },
        { id: 'municipios_dl', getAll: false },
        { id: 'modo_evento', getAll: false },
        { id: 'modalidad', getAll: false },
        {
          id: 'tipo_actividad_evento',
          getAll: false,
          filtered: idTipoEvento ? [{ id: 'eventType', filter: '=', value: idTipoEvento }] : [],
        },
        {
          id: 'ambito_evento',
          getAll: false,
          filtered: [{ id: 'cat_ambito.id', filter: 'IN', value: [1, 2, 5] }],
        },
      ];

      const defaultOption = { value: 0, label: 'TODOS' };

      setIsLoadingCat(true);
      const result = await CatalogServices.getCatalogs(catalogsParams);
      const { results, response, message } = result;

      if (results)
        setCatalogs({
          regiones: [defaultOption].concat(response.catalogs.regiones ?? []),
          municipios: [defaultOption].concat(response.catalogs.municipios ?? []),
          municipios_dl: [defaultOption].concat(response.catalogs.municipios_dl ?? []),
          tipo_actividad_evento: [defaultOption].concat(response.catalogs.tipo_actividad_evento ?? []),
          modo_evento: response.catalogs.modo_evento,
          modalidad: response.catalogs.modalidad,
          ambito_evento: response.catalogs.ambito_evento,
        });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoadingCat(false);
    }
  };

  const buttons = [
    {
      id: 1,
      button: (
        <Button key="Calendario" onClick={() => handleCalendarioClick(1)}>
          Calendario
        </Button>
      ),
      container: (
        <Calendario catalogs={catalogs} isLoadingCat={isLoadingCat} idTipoEvento={idTipoEvento} URL={URL} />
      ),
    },
    {
      id: 2,
      button: (
        <Button key="ListaEventos" onClick={() => handleCalendarioClick(2)}>
          Lista Eventos
        </Button>
      ),
      container: (
        <ListEventos catalogs={catalogs} isLoadingCat={isLoadingCat} idTipoEvento={idTipoEvento} URL={URL} />
      ),
    },
  ];

  return (
    <AdminLayout key={id}>
      <Container maxWidth="false">
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={'center'}>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <ButtonGroup color="secondary" aria-label="Medium-sized button group">
                    {buttons.map((item) => item.button)}
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                {buttons.map((item, index) => {
                  return (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={12}>
                        {tabButton === item.id && item.container}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Eventos;
