import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';

const ColumnStacked = (props) => {
  const { categories, series, title, loading = false } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: 'column',
      },
      title: {
        text: title.primary,
        align: 'left',
      },

      subtitle: {
        text: title.sub,
        align: 'left',
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: title.y,
        },
      },

      xAxis: {
        categories: categories,
      },

      legend: {
        reversed: true,
      },

      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
          },
        },
      },
      credits: false,
      series: series,

      /*  responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      }, */
    });
    // eslint-disable-next-line
  }, [series, title]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default ColumnStacked;
