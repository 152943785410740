import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const headerCellStyle = {
  backgroundColor: grey[100],
  fontWeight: 'bold',
};

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="right" style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            SECCIÓN
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            CASILLA
          </Typography>
        </TableCell>
        <TableCell style={{ ...headerCellStyle }}>
          <Typography variant="subtitle2" fontWeight={600}>
            NOMBRE
          </Typography>
        </TableCell>
        <TableCell align="center" style={{ ...headerCellStyle }}>
          FOLIO BINGO
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
