import React, { useEffect, useState } from 'react';

import { Card, CardContent, Icon, InputAdornment, Stack, TextField } from '@mui/material';
import Modal from '@components/Modal/Dataview';
import sije from '@services/SijeService';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

const UpdateIncidentStatus = ({ open, idIncident, setOpen, setRefresh }) => {
  const { id: id_incidencia, HoraIncidencia } = idIncident;

  const [formValue, setFormValue] = useState({
    ComoSeResolvio: '',
    HoraSolucion: '',
  });
  const [loading, setLoading] = useState(false);
  const [errorHour, setErrorHour] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleUpdatedStatus = async () => {
    setLoading(true);
    const params = {
      id: id_incidencia,
      ...formValue,
    };

    try {
      const { results, message, success } = await sije.updatedSijeIncidencias(params);

      if (!results) {
        throw new Error(message);
      }

      Swal.fire({
        title: message,
        icon: 'success',
        customClass: {
          container: 'modal-alert',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setOpen(false);
          setRefresh(true);
          setFormValue((prevState) => ({
            ...prevState,
            ComoSeResolvio: '',
            HoraSolucion: '',
          }));
        }
      });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formValue.HoraSolucion.length > 0) {
      const hourFormatRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/;

      if (hourFormatRegex.test(formValue.HoraSolucion)) {
        if (formValue.HoraSolucion <= HoraIncidencia) {
          setErrorHour('No es posible resolver antes o al mismo tiempo que la hora de la incidencia.');
        } else {
          setErrorHour('');
        }
      } else {
        setErrorHour('El formato de la hora debe ser HH:MM');
      }
    }
  }, [formValue.HoraSolucion]);

  useEffect(() => {
    if (!open) {
      setFormValue({ ComoSeResolvio: '', HoraSolucion: '' });
      setErrorHour('');
    }
  }, [open]);

  return (
    <Modal open={open} setOpen={setOpen} headerTitle="Actualizar estatus" maxWidth={'sm'}>
      <Card elevation={0}>
        <CardContent>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Descripción de la solución de la incidencia"
              name="ComoSeResolvio"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={formValue.ComoSeResolvio.toUpperCase()}
              onChange={handleChange}
            />
            <TextField
              label={'Hora Solución (Formato 24hrs)'}
              name="HoraSolucion"
              value={formValue.HoraSolucion}
              onChange={handleChange}
              size="small"
              fullWidth
              InputProps={{
                size: 'small',
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>schedule</Icon>
                  </InputAdornment>
                ),
              }}
              error={errorHour !== '' ? errorHour : ''}
              helperText={errorHour !== '' ? errorHour : ''}
            />
            <LoadingButton
              variant="contained"
              size="small"
              color="primaryDark"
              loading={loading}
              disabled={formValue.ComoSeResolvio === '' || formValue.HoraSolucion === '' || errorHour !== ''}
              onClick={handleUpdatedStatus}
            >
              Actualizar
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default UpdateIncidentStatus;
