import { useState, useEffect } from 'react';
import BasicTableCustomizing from '@components/Tables/BasicTableCustomizing';

import {
  Box,
  Icon,
  Tooltip,
  Typography,
  Button,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import { blue, red } from '@mui/material/colors';
import Swal from 'sweetalert2';

import prep from '@services/PrepServices';
import middleware from '@middlewares/middleware';
import { isEmptyOrNullObject } from '@utils/validations';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CoalitionPartiesList from '@components/Prep/CoalitionPartiesList';

import { URL_IMAGES } from '@utils/global';

const PartiesTable = (props) => {
  const { filtered, handleEdit } = props;
  const columns = [
    { id: 'Actions', label: '', align: 'left' },
    { id: 'Partido', label: 'Partido', align: 'center' },
    { id: 'Siglas', label: 'Siglas', align: 'center' },
    { id: 'EsIndependiente', label: '¿Es independiente?', align: 'center' },
    { id: 'EsCoalicion', label: '¿Es coalición?', align: 'center' },
    { id: 'TotalPartidos', label: 'Partidos y combinaciones de la coalición', align: 'center' },
  ];
  const [loadingTable, setLoadingTable] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const [dataTable, setDataTable] = useState({
    data: [],
    total: 0,
  });
  const [paramsTable, setParamsTable] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [partyDetail, setPartyDetail] = useState([]);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  useEffect(() => {
    if (filtered) {
      setParamsTable((prevState) => ({ ...prevState, filtered: filtered, page: 0 }));
    }
    //eslint-disable-next-line
  }, [filtered]);

  useEffect(() => {
    if (sortedData.length > 0) {
      setParamsTable((prevState) => ({ ...prevState, sorted: sortedData }));
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    getTable(paramsTable);
    //eslint-disable-next-line
  }, [paramsTable]);

  const handleGetCoalitionDetail = (party) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    prep
      .getDetailParties(party.id)
      .then((res) => {
        if (res.results) {
          setOpenModal(true);
          setPartyDetail(res.response.data);
        } else {
          setPartyDetail([]);
        }
      })
      .catch((error) => {
        setPartyDetail([]);
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleDelete = async (data) => {
    Swal.fire({
      title: 'Advertencia',
      html: `Esta acción eliminará el registro seleccionado. ¿Desea continuar?`,
      icon: 'warning',
      cancelButtonText: 'No, Cancelar',
      confirmButtonText: 'Sí, Eliminar',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async (res) => {
      if (res.isConfirmed) {
        await deleteFromServer(data.id);
      }
    });
  };

  const deleteFromServer = async (id) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await prep.deleteParty(id);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        });

        getTable(paramsTable);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const mapDataTable = (data) => {
    return data.map((item) => {
      item.Actions = middleware.checkMenuAction('Eliminar') ? (
        <ButtonGroup>
          <Tooltip title="Eliminar">
            <IconButton size="small" onClick={(e) => handleDelete(item)}>
              <Icon sx={{ color: red[800] }}>delete</Icon>
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ) : (
        ''
      );
      item.Partido = middleware.checkMenuAction('Editar') ? (
        <ListItem>
          <ListItemAvatar>
            <Avatar src={`${URL_IMAGES}/${item.RutaLogo}`} />
          </ListItemAvatar>
          <Tooltip title="Click para editar">
            <Button
              disableElevation
              variant="text"
              color="primaryDark"
              size="small"
              sx={{
                ':hover': {
                  bgcolor: blue[900],
                  color: 'white',
                },
                p: '0 auto',
                textAlign: 'left',
                ml: 2,
              }}
              endIcon={<Icon>edit</Icon>}
              onClick={(e) => handleEdit(item)}
            >
              <ListItemText
                primary={
                  <Typography variant="div" fontWeight={700} fontSize={15}>
                    {item.Partido}
                  </Typography>
                }
              />
            </Button>
          </Tooltip>
        </ListItem>
      ) : (
        <ListItem>
          <ListItemAvatar>
            <Avatar src={`${URL_IMAGES}/${item.RutaLogo}`} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="div" fontWeight={700} fontSize={15}>
                {item.Partido}
              </Typography>
            }
          />
        </ListItem>
      );
      item.EsCoalicion = item.EsCoalicion === 1 ? 'SI' : 'NO';
      item.EsIndependiente = item.EsIndependiente === 1 ? 'SI' : 'NO';
      item.TotalPartidos = middleware.checkMenuAction('Editar') ? (
        item.EsCoalicion === 1 || item.EsCoalicion === 'SI' ? (
          <Chip
            label={item.TotalPartidos}
            color="primary"
            variant="outlined"
            size="large"
            onClick={(e) => handleGetCoalitionDetail(item)}
            sx={{
              fontWeight: '700',
            }}
          />
        ) : (
          ''
        )
      ) : item.EsCoalicion === 1 || item.EsCoalicion === 'SI' ? (
        <Chip
          label={item.TotalPartidos}
          color="primary"
          variant="outlined"
          size="large"
          sx={{
            fontWeight: '700',
          }}
        />
      ) : (
        ''
      );

      return item;
    });
  };

  const getTable = (params) => {
    setLoadingTable(true);
    prep
      .getElectoralParties(params)
      .then((res) => {
        if (res.results) {
          setDataTable({
            data: mapDataTable(res.response.data),
            total: res.response.total,
          });
        } else {
          setDataTable({
            data: [],
            total: 0,
          });
        }
      })
      .catch((error) => {
        setDataTable({
          data: [],
          total: 0,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const handleChangePagination = (pagination) => {
    setParamsTable({ ...paramsTable, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      switch (dataSorted.orderBy) {
        default:
          sorted.push({
            id: dataSorted.orderBy,
            value: dataSorted.order,
          });
          break;
      }
    }
    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (dataTable.total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <Box component={'div'}>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={'Cargando...'}
      />

      <BasicTableCustomizing
        rows={dataTable.data}
        hcolumns={columns}
        pageProp={paramsTable.page}
        pageSize={paramsTable.pageSize}
        total={dataTable.total}
        handleChangePagination={handleChangePagination}
        isLoading={loadingTable}
        stickyHeader={true}
        handleManualSort={handleRequestSort}
      />

      <CoalitionPartiesList openModal={openModal} setOpenModal={setOpenModal} data={partyDetail} />
    </Box>
  );
};

export default PartiesTable;
