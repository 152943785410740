import React, { useState, useEffect, useCallback } from 'react';

import { Box, Icon, Chip, TextField, InputAdornment } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isEmptyOrInvalidString, isTypePhone } from '@utils/validations';

import CatalogService from '@services/CatalogServices';

import BasicSelect from '@components/Selects/BasicSelect';

import Swal from 'sweetalert2';

const AddressFormExtranjero = (props) => {
  const {
    catalogs,
    loadigCatalogs,
    errors,
    touched,
    values,
    handleChange,
    setValues,
    movile,
    domiciliotext = 'Domicilio en el extranjero',
  } = props;

  //const [catSuburbLives, setCatSuburbLives] = useState([]);
  const [catEstadoLives, setCatEstadoLives] = useState([]);
  //const [catLocalitiesLives, setCatLocalitiesLives] = useState([]);
  //const [isLoadingCatLocLives, setIsLoadingCatLocLives] = useState(false);

  useEffect(() => {}, [props.movil]);

  useEffect(() => {
    if (values.idEstadoExtranjero && parseInt(values.idEstadoExtranjero) > 0) {
      loadCatalogsByMunicipio(values.idPais);
    }
    // eslint-disable-next-line
  }, [values.idEstadoExtranjero]);

  const loadCatalogsByMunicipio = (idPais) => {
    let catalogsParams = [
      {
        id: 'estados_paises',
        filtered: [{ id: 'idPais', filter: '=', value: idPais }],
      },
      /*   {
        id: "colonias",
        filtered: [{ id: "idMunicipio", filter: "=", value: municipalityId }],
      }, */
    ];

    //setIsLoadingCatLocLives(true);

    if (movile) {
      CatalogService.getCatalogsPublicEvents({
        catalogs: catalogsParams,
      })
        .then((res) => {
          if (res.success && res.results) {
            //  setCatLocalitiesLives(res.response.catalogs.localidades);

            handleChange({
              target: {
                name: 'idLocalidadVive',
                value: res.response.catalogs.localidades.find(
                  (item) => `${item.value}`.slice(-4, item.value.length) === '0001'
                ).value,
              },
            });
            // setCatSuburbLives(res.response.catalogs.colonias);
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        });
      // .finally(() => setIsLoadingCatLocLives(false));
    } else {
      CatalogService.getCatalogs(catalogsParams)
        .then((res) => {
          if (res.success && res.results) {
            // setCatLocalitiesLives(res.response.catalogs.localidades);
            // setCatSuburbLives(res.response.catalogs.colonias);
            setCatEstadoLives(res.response.catalogs.estados_paises);
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        });
      // .finally(() => setIsLoadingCatLocLives(false));
    }
  };

  // const handleChangeMunicipalityLives = (e) => {
  //   const value = e.target.value;

  //   setValues({
  //     ...values,
  //     idMunicipioVive: value,
  //     idLocalidadVive: "",
  //     ColoniaVive: "",
  //   });
  // };

  const handleChangePaisLives = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      idPais: value,
      idEstadoExtranjero: '',
      State: '',
      ZipCode: '',
    });

    const catEstadosPais = catalogs.estados_paises.filter((p) => p.idPais === value);
    setCatEstadoLives(catEstadosPais);
  };

  // const handleChangeSuburbLives = (e) => {
  //   if (e.CP) {
  //     setValues({
  //       ...values,
  //       ColoniaVive: e.label,
  //       CPVive: e.CP,
  //     });
  //   } else {
  //     setValues({
  //       ...values,
  //       ColoniaVive: e.label,
  //       CPVive: "",
  //     });
  //   }
  // };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>home</Icon>} label={domiciliotext} />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <BasicSelect
            error={touched.Pais && !isEmptyOrInvalidString(errors.Pais)}
            errorMessage={errors.Pais}
            name="Pais"
            label="País"
            options={catalogs.paises}
            value={values.idPais}
            onChange={handleChangePaisLives}
            sx={{ width: '100%' }}
            isLoading={loadigCatalogs}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <BasicSelect
            error={touched.idEstadoExtranjero && !isEmptyOrInvalidString(errors.idEstadoExtranjero)}
            errorMessage={errors.idEstadoExtranjero}
            name="idEstadoExtranjero"
            label={
              values.idPais === 2 ? 'Estado *' : values.idPais === 3 ? 'Provincia/Territorio *' : 'Estado *'
            }
            options={catEstadoLives}
            value={values.idEstadoExtranjero}
            onChange={handleChange}
            isLoading={loadigCatalogs}
            sx={{ width: '100%' }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <TextField
            error={touched.State && !isEmptyOrInvalidString(errors.State)}
            label="Ciudad"
            helperText={touched.State && errors.State && errors.State}
            type="text"
            name="State"
            onChange={handleChange}
            value={values.State.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={touched.Line1 && !isEmptyOrInvalidString(errors.Line1)}
            label="Calle o apartado postal"
            helperText={touched.Line1 && errors.Line1 && errors.Line1}
            type="text"
            name="Line1"
            onChange={handleChange}
            value={values.Line1.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={touched.Line2 && !isEmptyOrInvalidString(errors.Line2)}
            label="Departamento, oficina, unidad, edificio"
            helperText={touched.Line2 && errors.Line2 && errors.Line2}
            type="text"
            name="Line2"
            onChange={handleChange}
            value={values.Line2?.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={2} lg={2}>
          <TextField
            error={touched.ZipCode && !isEmptyOrInvalidString(errors.ZipCode)}
            helperText={touched.ZipCode && errors.ZipCode && errors.ZipCode}
            sx={{ width: '100%' }}
            required
            label="Código Postal"
            type="text"
            name="ZipCode"
            disabled={values.idPais.length === 0}
            onChange={(e) =>
              handleChange({
                target: {
                  name: 'ZipCode',
                  // value: isTypePhone(e.target.value)
                  //   ? e.target.value
                  //   : values.ZipCode,
                  value:
                    values.idPais === 2
                      ? isTypePhone(e.target.value)
                        ? e.target.value
                        : values.ZipCode
                      : e.target.value.toUpperCase(),
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: 'ZipCode',
                  // value: isTypePhone(e.target.value)
                  //   ? e.target.value
                  //   : values.ZipCode,
                  value:
                    values.idPais === 2
                      ? isTypePhone(e.target.value)
                        ? e.target.value
                        : values.ZipCode
                      : e.target.value,
                },
              })
            }
            value={values.ZipCode}
            variant="outlined"
            size="small"
            className="fixed-input"
            inputProps={{
              maxLength: values.idPais === 2 && 5,
            }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default AddressFormExtranjero;
