import { useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Typography,
  CardContent,
  CardActions,
  TextField,
  CircularProgress,
  styled,
  Stack,
  Icon,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { styleTextFieldNumber } from '../../../theme';

import mobilization from '@services/MovilizationServices';
import Swal from 'sweetalert2';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  backgroundColor: grey[100],
}));

const EditingSchedules = ({
  /* open = false,
  data,
  setOpen,
  handleChangeSchedules,
  handleRefresh, */
  dataMovilization,
  loadingList,
  loading,
  handleSaveData,
  handleEditMovilizados,
}) => {
  //const [loading, setLoading] = useState(false);
  /* const [loadingList, setLoadingList] = useState(false);
  const [dataMovilization, setDataMovilization] = useState([]);

  const resetMobilizedBySchedule = async ({ body }) => {
    setLoadingList(true);
    try {
      const res = await mobilization.resetMobilizedBySchedule(body);
      const { results, message } = res;
      if (results) {
        getMobilizedBySchedule(data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    }
  };

  const getMobilizedBySchedule = async (data) => {
    try {
      const { results, response, message, success } =
        await mobilization.getDetailMobilizedByHour(data);

      if (!results && !success) throw new Error(message);

      if (results && success) {
        setDataMovilization(response.data);
      } else {
        setDataMovilization([]);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoadingList(false);
    }
  };

  const handleEditMovilizados = (idUsuario, newValue) => {
    const updatedData = dataMovilization.map((row) =>
      row.idUsuario === idUsuario ? { ...row, movilizados: newValue } : row
    );

    setDataMovilization(updatedData);
  };

  const handleSaveData = () => {
    const combinedData = dataMovilization.map((row) => {
      const editedRow = dataMovilization.find(
        (editedRow) => editedRow.idUsuario === row.idUsuario
      );
      return editedRow || row;
    });

    const users = combinedData.map(({ idUsuario, movilizados }) => ({
      idUsuario,
      movilizados: parseInt(movilizados),
    }));

    setMovilization({ users });
  };

  const setMovilization = async ({ users }) => {
    setLoading(true);
    try {
      const res = await mobilization.saveMobilizedBySchedule({
        ...data,
        Usuarios: users,
      });
      const { results, message } = res;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
          customClass: {
            container: "modal-alert",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            setOpen(false);
            handleChangeSchedules();
            handleRefresh();
          }
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
        customClass: {
          container: "modal-alert",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      //getMobilizedBySchedule(data);
      resetMobilizedBySchedule({ body: data });
    }
  }, [data, open]); */

  return (
    <>
      <Alert
        severity="error"
        color="warning"
        sx={{
          mt: 1,
          mb: 1,
          display:
            dataMovilization.length > 0 && dataMovilization.some((item) => item.esPaseLista !== 1)
              ? 'flex'
              : 'none',
          alignItems: 'center',
        }}
      >
        Advertencia. Si el responsable tiene el símbolo{' '}
        {
          <Icon fontSize="small" color="error">
            error
          </Icon>
        }{' '}
        la edición no estará habilitada, ya que aún no ha realizado el pase de lista.
      </Alert>
      <Card elevation={0} sx={{ border: '1px solid #E0E0E0', borderRadius: 3 }}>
        <CardContent>
          <TableContainer sx={{ marginTop: 2, borderRadius: 3, maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCellStyled align="right">
                    <Typography variant="subtitle2" fontWeight={600}>
                      SECCIÓN
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled>
                    <Typography variant="subtitle2" fontWeight={600}>
                      RESPONSABLE
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled>
                    <Typography variant="subtitle2" fontWeight={600}>
                      RESPONSABILIDAD
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled align="center">COMPROMISOS MOVILIZADOS</TableCellStyled>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingList ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {dataMovilization.length > 0 ? (
                      dataMovilization.map((row) => (
                        <TableRow key={row.idUsuario}>
                          <TableCell align="right">{row.Seccion}</TableCell>
                          <TableCell>{row.NombreCompleto}</TableCell>
                          <TableCell>{row.Responsabilidad}</TableCell>
                          <TableCell align="center">
                            <TextField
                              size="small"
                              disabled={row.idEstatus === 1 ? false : true}
                              value={row.movilizados !== null ? row.movilizados : ''}
                              onChange={(e) => handleEditMovilizados(row.idUsuario, e.target.value)}
                              //sx={{ width: "50%" }}
                              type="number"
                              sx={{
                                ...styleTextFieldNumber,
                                width: '50%',
                                display: row.esPaseLista !== 1 ? 'none' : 'block',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Stack direction={'column'} spacing={1} alignItems={'center'}>
                            <Icon sx={{ color: grey[500] }} fontSize="large">
                              search_off
                            </Icon>
                            <Typography>Sin resultados</Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        {dataMovilization.length > 0 && (
          <CardActions sx={{ justifyContent: 'center' }}>
            <LoadingButton
              variant="contained"
              color="primaryDark"
              loading={loading}
              onClick={() => handleSaveData()}
            >
              Editar
            </LoadingButton>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default EditingSchedules;
