import Yup from '@utils/Yupi18n';

export const AddGoalSchema = Yup.object({
  idPerfil: Yup.number().required().label('Seleccionar un perfil'),
  idMunicipioReportes: Yup.number().required().label('Seleccionar un municipio  '),
  idDF: Yup.number()
    .when('RequiereDF', {
      // Cuando RequiereDF es 1
      is: 1,
      // Hacer DF requerido
      then: Yup.number()
        .integer('Debe ser un número entero')
        .required('Este campo es requerido')
        .min(1, 'Debe ser al menos 1 número'),
      // Si no, hacerlo opcional
      otherwise: Yup.number().optional().nullable(),
    })
    .label('Descripción del incidente'),
  Meta: Yup.number()
    .integer('Debe ser un número entero')
    .min(1, 'Ingresa un número mayor a 0')
    .required('Este campo es requerido')
    .label('Descripción de la meta'),
});
