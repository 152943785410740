import { Card, CardContent, Stack, Typography } from '@mui/material';
import LinearWithValueLabel from '@components/Sije/OperationCasilla/ProgressLabel';
import { numberWithCommas } from '@utils/Utilities';

const CardRepresentative = ({ dataRC, loading }) => {
  return (
    <Card className="card-secondary">
      <CardContent>
        <Typography fontWeight={300}>Representates de Casillas</Typography>
        <Typography variant="body2" fontWeight={700}>
          Total de Casillas
        </Typography>
        <Typography variant="h4" fontWeight={700}>
          {numberWithCommas(dataRC.total[0])}
        </Typography>

        <div>
          <Stack direction={'row'} spacing={2}>
            <LinearWithValueLabel
              progress={(dataRC.avance[0] * 100) / dataRC.total[0]}
              avance={numberWithCommas(dataRC.avance[0])}
              total={numberWithCommas(dataRC.total[0])}
              label={'Propietario 1'}
              loading={loading}
            />
            <LinearWithValueLabel
              progress={(dataRC.avance[1] * 100) / dataRC.total[1]}
              avance={numberWithCommas(dataRC.avance[1])}
              total={numberWithCommas(dataRC.total[1])}
              label={'Propietario 2'}
              color={'success'}
              loading={loading}
            />
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <LinearWithValueLabel
              progress={(dataRC.avance[2] * 100) / dataRC.total[2]}
              avance={numberWithCommas(dataRC.avance[2])}
              total={numberWithCommas(dataRC.total[2])}
              label={'Suplente 1'}
              color={'error'}
              loading={loading}
            />
            <LinearWithValueLabel
              progress={(dataRC.avance[3] * 100) / dataRC.total[3]}
              avance={numberWithCommas(dataRC.avance[3])}
              total={numberWithCommas(dataRC.total[3])}
              label={'Suplente 2'}
              color={'warning'}
              loading={loading}
            />
          </Stack>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardRepresentative;
