import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Button, Typography } from '@mui/material';
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import FloatingButton from '@components/Button/FloatingButton';
import DrawerRelative from '@components/Containers/DrawerRelative';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import Filter from '@components/DiaD/DashboardMRT/Filter';
import Table from '@components/DiaD/DashboardMRT/Table';

// Servicios
import CatalogServices from '@services/CatalogServices';
import DiaDServices from '@services/DiaDServices';

const DashboardMRT = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);
  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [dataCards, setDataCards] = useState({ federales: {}, locales: {}, casillas: {} });

  const [catalogs, setCatalogs] = useState({});

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCardsMRT(params);
    // eslint-disable-next-line
  }, [params]);

  const getCatalogs = async () => {
    try {
      setIsLoadingCatalogs(true);

      const result = await CatalogServices.getCatalogs([
        { id: 'regiones', getAll: false },
        { id: 'municipios_reportes', getAll: false },
        { id: 'df', getAll: false },
        { id: 'dl', getAll: false },
        { id: 'secciones', getAll: false },
        { id: 'casillas', getAll: false },
      ]);
      const { results, response, message } = await result;

      if (results) {
        const { regiones, municipios_reportes, df, dl, secciones, casillas } = response.catalogs;
        const all = (O = 'O') => ({ value: 0, label: `TOD${O}S` });

        const Si_No = [
          { value: 1, label: `SI` },
          { value: 2, label: `NO` },
        ];

        const Registro = [
          { value: 1, label: `REGISTRADO` },
          { value: 2, label: `OTRO` },
        ];

        const Custodia = [
          { value: 1, label: `NINGUNA` },
          { value: 2, label: `SEDENA/MARINA` },
          { value: 3, label: `GUARDIA NACIONAL` },
          { value: 4, label: `ESTATAL` },
          { value: 5, label: `MUNICIPAL` },
          { value: 6, label: `OTRO` },
        ];

        const localCat = {
          tipos: [all('A'), { value: 1, label: 'Federales' }, { value: 2, label: 'Locales' }],
          estatus: [all(), { value: 1, label: 'Completadas' }, { value: 2, label: 'No completadas' }],
          regiones: [all('A'), ...regiones],
          municipios_reportes: [all('O'), ...municipios_reportes],
          df: [all('O'), ...df],
          dl: [all('O'), ...dl],
          secciones: [all('A'), ...secciones],
          casillas: [all('A'), ...casillas],
          pregunta1: [all('O'), ...Registro],
          pregunta2: [all('O'), ...Si_No],
          pregunta3: [all('O'), ...Si_No],
          pregunta4: [all('O'), ...Si_No],
          pregunta5: [all('O'), ...Si_No],
          pregunta6: [all('O'), ...Custodia],
          pregunta7: [all('O'), ...Si_No],
        };
        setCatalogs(localCat);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingCatalogs(false);
    }
  };

  const getCardsMRT = async (params) => {
    try {
      setIsLoading(true);

      const result = await DiaDServices.getCardsMRT({ filtered: params.filtered });
      const { results, response, message } = await result;

      if (results) setDataCards(response);
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (filter) => {
    setParams({ ...params, filtered: filter });
  };

  return (
    <AdminLayout>
      <ActionHeader title="Dashboard MRT" isCustom={true}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          aria-label="open Drawer"
          endIcon={isOpen ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
        >
          <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
        </Button>
      </ActionHeader>

      <Grid container spacing={isOpen ? 2 : 0} sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
        <Grid item xs={12} md={isOpen ? 4 : 0}>
          <DrawerRelative
            anchor="right"
            open={isOpen}
            setOpen={setIsOpen}
            title="Filtros"
            icon="filter_list"
            sxPaper={{ borderRadius: 3 }}
            className="card-primary"
            screenHeight="md"
            isSticky
          >
            <Filter onSearch={onSearch} catalogs={catalogs} isLoadingCatalogs={isLoadingCatalogs} />
          </DrawerRelative>
        </Grid>

        <Grid item xs={12} md={isOpen ? 8 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={isOpen ? 6 : 3}>
              <AdaptiveCard
                title="Rutas Federales"
                icon="map"
                config={{ percent: dataCards?.federales?.porcentaje ?? 0, type: 'linear' }}
                leftConfig={{ title: 'Total', data: dataCards?.federales?.total ?? 0 }}
                rightConfig={{ title: 'Avance', data: dataCards?.federales?.avance ?? 0 }}
                loading={isLoading}
                fixed
                adjust
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={isOpen ? 6 : 3}>
              <AdaptiveCard
                title="Rutas Locales"
                icon="home"
                config={{ percent: dataCards?.locales?.porcentaje ?? 0, type: 'linear' }}
                leftConfig={{ title: 'Total', data: dataCards?.locales?.total ?? 0 }}
                rightConfig={{ title: 'Avance', data: dataCards?.locales?.avance ?? 0 }}
                loading={isLoading}
                fixed
                adjust
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isOpen ? 6 : 4} lg={isOpen ? 6 : 3}>
              <AdaptiveCard
                title="Casillas Federales"
                icon="pin"
                // config={{ percent: 70, type: "linear" }}
                leftConfig={{ title: 'Total', data: dataCards?.casillas?.federales ?? 0 }}
                rightConfig={{ title: 'Recuperadas', data: dataCards?.casillas?.avanceFederales ?? 0 }}
                loading={isLoading}
                fixed
                adjust
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isOpen ? 6 : 12} lg={isOpen ? 6 : 3}>
              <AdaptiveCard
                title="Casillas Locales"
                icon="pin"
                // config={{ percent: 70, type: "linear" }}
                leftConfig={{ title: 'Total', data: dataCards?.casillas?.locales ?? 0 }}
                rightConfig={{ title: 'Recuperadas', data: dataCards?.casillas?.avanceLocales ?? 0 }}
                loading={isLoading}
                fixed
                adjust
              />
            </Grid>

            <Grid item xs={12}>
              <Table
                params={params}
                catalogs={catalogs}
                isLoadingCatalogs={isLoadingCatalogs}
                isOpen={isOpen}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!isOpen && (
        <FloatingButton
          onClick={() => setIsOpen(true)}
          label="Mostrar Filtros"
          sx={{ display: { xs: 'none', md: 'block' } }}
        />
      )}
    </AdminLayout>
  );
};

export default DashboardMRT;
