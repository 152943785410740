import React, { useState, useEffect } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { useNavigate } from 'react-router-dom';
import BasicTable from '@components/Tables/BasicTable';
import Swal from 'sweetalert2';
import { Card, Container, CardContent, TextField, Button, Icon, IconButton } from '@mui/material';
import CardStats from '@components/Card/CardStats';
import { isEmptyOrNullObject } from '@utils/validations';
import SocialService from '@services/SocialServices';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { numberWithCommas } from '@utils/Utilities';

const AvanceCompromisos = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataFiltro, setDataFiltro] = useState([]);
  const [totales, setTotales] = useState({});
  const [total, setTotal] = useState([]);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 50,
  });
  const [sortedData, setSortedData] = useState([]);
  const [segmentoFiltro, setSegmentoFiltro] = useState('');
  const columns = [
    { id: 'NombreSocial', label: 'Segmento' },
    { id: 'AvanceCompromisos', label: 'Avance Compromisos' },
    { id: 'AvanceCompromisosUnicos', label: 'Avance Compromisos Únicos' },
    { id: 'AvanceCoordinadores', label: 'Coordinadores' },
    { id: 'AvanceEnlaces', label: 'Enlaces' },
  ];

  const getList = () => {
    SocialService.getAvanceCompromisos(params)
      .then((res) => {
        if (res.results) {
          let result = res.response.data.map((item) => {
            return {
              ...item,
              AvanceCompromisosUnicos:
                item.AvanceCompromisosUnicos !== 0 ? numberWithCommas(item.AvanceCompromisosUnicos) : '0',
              AvanceCompromisos:
                item.AvanceCompromisos !== 0 ? numberWithCommas(item.AvanceCompromisos) : '0',
              AvanceCoordinadores:
                item.AvanceCoordinadores !== 0 ? numberWithCommas(item.AvanceCoordinadores) : '0',
              AvanceEnlaces: item.AvanceEnlaces !== 0 ? numberWithCommas(item.AvanceEnlaces) : '0',
            };
          });
          setData(result);
          setDataFiltro(result);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const getTotales = () => {
    SocialService.getAvanceCompromisosTotales(params)
      .then((res) => {
        if (res.results) {
          setTotales(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getList();
    getTotales();
  }, [params]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let paramsTemp = {
        ...params,
        sorted: sortedData,
      };
      setParams(paramsTemp);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangePagination = (pagination) => {
    setParams({ ...params, ...pagination });
  };

  const handleRequestSort = (sortData) => {
    setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      NombreSocial: 'cat_social.NombreSocial',
      AvanceCompromisos: 'compromisos.Avance',
      AvanceCompromisosUnicos: 'compromisos_unicos.Avance',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const filtro = () => {
    if (segmentoFiltro.length > 2) {
      setDataFiltro([]);
      setParams({
        ...params,
        filtered: [
          {
            id: 'cat_social.NombreSocial',
            value: `%${segmentoFiltro.toUpperCase().replace(/ /g, '%')}%`,
            filter: 'LIKE',
          },
        ],
      });
    }
    if (segmentoFiltro.length === 0) {
      setParams({ ...params, filtered: [] });
    }
  };

  const filtroClean = () => {
    setParams({ ...params, filtered: [] });
    setSegmentoFiltro('');
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Compromisos" handleclick={() => navigate('/avance-compromisos')} />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={6} lg={4}>
            <CardStats
              icon={'group'}
              title={'Compromisos Etiquetados Únicos'}
              total={totales.unicos}
              subIcon={'calendar_today'}
              //  subtitle={today.toLocaleDateString()}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} sm={6} lg={4}>
            <CardStats
              icon={'groups'}
              title={'Compromisos Etiquetados'}
              total={totales.compromiso}
              subIcon={'calendar_today'}
              // subtitle={today.toLocaleDateString()}
              loading={loading}
            />
          </Grid2>
        </Grid2>
        <Card className="card-primary">
          <CardContent>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} md={6} lg={4}>
                <TextField
                  id="outlined-required"
                  label="Segmento"
                  fullWidth
                  value={segmentoFiltro}
                  onChange={(e) => setSegmentoFiltro(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => filtroClean()}>
                        <Icon>close</Icon>
                      </IconButton>
                    ),
                  }}
                />
              </Grid2>
              <Grid2 item xs={12} md={3} lg={2}>
                <Button onClick={() => filtro()} variant="contained" sx={{ mt: 2 }}>
                  Buscar
                </Button>
              </Grid2>
              <Grid2 item xs={12} md={12} lg={12}>
                <BasicTable
                  rows={dataFiltro}
                  hcolumns={columns}
                  pageProp={params.page}
                  pageSize={params.pageSize}
                  total={total}
                  handleChangePagination={handleChangePagination}
                  isLoading={loading}
                  stickyHeader={true}
                  handleManualSort={handleRequestSort}
                />
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default AvanceCompromisos;
