import { WhatsApp } from '@mui/icons-material';
import { Icon } from '@mui/material';

export const COLUMNS_DASH_MOBILIZATION = [
  //{ id: "DistritoFederal", label: "DISTRITOFEDERAL" },
  //{ id: "DistritoLocal", label: "DISTRITOLOCAL" },
  { id: 'Region', label: 'REGIÓN' },
  { id: 'idDF', label: 'DF' },
  { id: 'idDL', label: 'DL' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'Poligono', label: 'POLÍGONO' },
  { id: 'Seccion', label: 'SECCIÓN' },
  { id: 'LNOM', label: 'LNOM' },
  { id: 'Meta', label: 'META' },
  { id: 'SIAN', label: 'SIAN' },
  //{ id: "Tipo", label: "TIPO" },
  { id: 'AvanceBingo', label: 'AVANCE BINGO' },
  { id: 'AvanceCompromiso', label: 'AVANCE COMPROMISO' },
  { id: 'AvanceCiudadano', label: 'AVANCE CIUDADANO' },
  { id: 'AvanceTotal', label: 'AVANCE TOTAL' },
  { id: 'AvancePorcentaje', label: 'AVANCE PORCENTAJE' },
  { id: 'Efectividad', label: 'EFECTIVIDAD' },
];

export const COLUMNS_DASH_MOBILIZATION_ELECTORAL = [
  { id: 'Seccion', label: 'SECCIÓN', align: 'center' },
  { id: 'Region', label: 'REGIÓN', align: 'center' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'DistritoFederal', label: 'DISTRITO FEDERAL', align: 'center' },
  { id: 'DistritoLocal', label: 'DISTRITO LOCAL', align: 'center' },
  { id: 'Poligono', label: 'POLÍGONO', align: 'center' },
  { id: 'idTipoRentabilidad', label: 'TIPO RENTABILIDAD', align: 'center' },
  {
    id: 'MetaCasillas',
    label: 'CASILLAS APROBADAS',
    type: 'number',
    align: 'center',
  },
  {
    id: 'AvanceCasillas',
    label: 'CASILLAS APERTURADAS',
    type: 'number',
    align: 'center',
  },
  {
    id: 'PorcentajeAvanceCasillas',
    label: '% APERTURADAS',
    type: 'circular',
    fixed: 2,
    align: 'center',
  },
  {
    id: 'PendienteMovilizar',
    label: 'PENDIENTE MOVILIZAR',
    //type: "circular",
    //fixed: 2,
    align: 'center',
  },
  {
    id: 'MetaMovilizacion',
    label: 'META BINGOS',
    type: 'number',
    align: 'center',
  },
  {
    id: 'VotacionCompromisos',
    label: 'BINGO COMPROMISOS',
    type: 'number',
    align: 'center',
  },
  {
    id: 'VotacionCiudadana',
    label: 'BINGO OTROS',
    type: 'number',
    align: 'center',
  },
  { id: 'VotacionTotal', label: 'AFLUENCIA', type: 'number', align: 'center' },
  {
    id: 'PorcentajeAvance',
    label: '% AVANCE BINGO',
    type: 'circular',
    fixed: 2,
    align: 'center',
  },
];

export const COLUMNS_DASH_MOBILIZATION_TERRITORIAL = [
  { id: 'Meta', label: 'META', type: 'number', align: 'right' },
  {
    id: 'MetaCasillas',
    label: 'META CASILLAS',
    align: 'center',
    type: 'number',
  },
  { id: 'AvanceTotal', label: 'AVANCE TOTAL', align: 'center', type: 'number' },
  {
    id: 'AvanceCasillas',
    label: 'AVANCE CASILLAS',
    align: 'center',
    type: 'number',
  },
  {
    id: 'PorcentajeAvance',
    label: 'PORCENTAJE AVANCE',
    type: 'circular',
    fixed: 2,
    align: 'center',
  },
  {
    id: 'PorcentajeAvanceCasillas',
    label: 'PORCENTAJE AVANCE CASILLAS',
    type: 'circular',
    fixed: 2,
    align: 'center',
  },
];

export const HOURS = [
  { id: '9am', label: '9am' },
  { id: '10am', label: '10am' },
  { id: '11am', label: '11am' },
  { id: '12pm', label: '12pm' },
  { id: '1pm', label: '1pm' },
  { id: '2pm', label: '2pm' },
  { id: '3pm', label: '3pm' },
  { id: '4pm', label: '4pm' },
  { id: '5pm', label: '5pm' },
  { id: '6pm', label: '6pm' },
];

export const COLUMNS_LIST_NOT_PENDING_REPORT = [
  { id: 'Region', label: 'REGIÓN' },
  { id: 'idDF', label: 'DF' },
  { id: 'idDL', label: 'DL' },
  { id: 'Municipio', label: 'MUNICIPIO' },
  { id: 'Poligono', label: 'POLÍGONO' },
  { id: 'Seccion', label: 'SECCIÓN' },
  { id: 'Movilizadores', label: 'MOVILIZADORES' },
  { id: '9am', label: 'FALTANTES 9AM' },
  { id: '10am', label: 'FALTANTES 10AM' },
  { id: '11am', label: 'FALTANTES 11AM' },
  { id: '12pm', label: 'FALTANTES 12PM' },
  { id: '1pm', label: 'FALTANTES 1PM' },
  { id: '2pm', label: 'FALTANTES 2PM' },
  { id: '3pm', label: 'FALTANTES 3PM' },
  { id: '4pm', label: 'FALTANTES 4PM' },
  { id: '5pm', label: 'FALTANTES 5PM' },
  { id: '6pm', label: 'FALTANTES 6PM' },
];

export const CATALOG_OPTIONS = [
  { id: 'regiones', getAll: false },
  { id: 'df', getAll: false },
  { id: 'secciones', getAll: false },
  { id: 'poligonos', getAll: false },
  { id: 'dl', getAll: false },
  { id: 'municipios_reportes', getAll: false },
  { id: 'municipios_df', getAll: false },
  { id: 'municipios_dl', getAll: false },
  { id: 'mpio_df', getAll: false },
  { id: 'mpio_dl', getAll: false },
];

export const CATALOG_GROUP = [
  { value: 2, label: 'Región', key: 'idRegion' },
  { value: 5, label: 'Municipio', key: 'idMunicipioReportes' },
  { value: 7, label: 'Polígono', key: 'idPoligono' },
  { value: 8, label: 'Sección', key: 'Seccion' },
];

export const COLUMNS_ADVANCE_MOBILIZATION_TERRITORIAL = [
  { id: 'Responsabilidad', label: 'RESPONSABILIDAD', align: 'center' },
  { id: 'Meta', label: 'META', align: 'center', type: 'number' },
  { id: 'Avance', label: 'AVANCE', align: 'center', type: 'number' },
  {
    id: 'PorcentajeAvance',
    label: 'PORCENTAJE AVANCE',
    align: 'center',
    type: 'linear',
    fixed: 1,
  },
];

export const PROGRESS_KEYS = ['PorcentajeAvance'];

export const ACTIONS_SPEED = [
  {
    icon: <WhatsApp />,
    name: 'Celular',
    key: 'Celular',
    //link: "whatsapp://send?phone=52",
    link: 'https://api.whatsapp.com/send?phone=52',
  },
  { icon: <Icon>call</Icon>, name: 'Teléfono', key: 'Telefono' },
  { icon: <Icon>sms</Icon>, name: 'TelMensajes', key: 'TelMensajes' },
];

export const handleGroupToTable = ({ group, catalog }) => {
  const matchingIndices = group.map((filterObj) => {
    const idToMatch = filterObj.id;
    const matchingIndex = catalog.findIndex((item) => idToMatch.includes(item.key));
    return matchingIndex;
  });

  const resultArray = matchingIndices.map((index) => catalog.slice(index));
  const catGroup = resultArray[resultArray.length - 1];

  return catGroup;
};

export const COLUMNS_CAPTURE_MOBILIZATION_REPORTED = [
  { id: 'Region', label: 'Región' },
  { id: 'Municipio', label: 'Municipio' },
  { id: 'Seccion', label: 'Sección' },
  { id: 'Nombre', label: 'Nombre' },
  { id: 'Contacto', label: 'Contacto' },
  { id: 'ResponsabilidadEtiqueta', label: 'Responsabilidad Etiqueta' },
  {
    id: 'MovilizacionReportada',
    label: 'Movilización Reportada',
    align: 'right',
    type: 'number',
  },
  { id: '9am', label: '9am', align: 'center', type: 'number' },
  { id: '10am', label: '10am', align: 'center', type: 'number' },
  { id: '11am', label: '11am', align: 'center', type: 'number' },
  { id: '12pm', label: '12pm', align: 'center', type: 'number' },
  { id: '1pm', label: '1pm', align: 'center', type: 'number' },
  { id: '2pm', label: '2pm', align: 'center', type: 'number' },
  { id: '3pm', label: '3pm', align: 'center', type: 'number' },
  { id: '4pm', label: '4pm', align: 'center', type: 'number' },
  { id: '5pm', label: '5pm', align: 'center', type: 'number' },
  { id: '6pm', label: '6pm', align: 'center', type: 'number' },
];

export const COLUMS_SUMMARY_MOBILIZATION_SIMULATED = [
  { id: 'Seccion', label: 'Sección' },
  { id: 'NombreCompleto', label: 'Nombre Completo' },
  { id: 'Responsabilidad', label: 'Responsabilidad' },
  { id: 'TotalCompromisos', label: 'Total Compromisos' },
  { id: 'PuertasTocadas', label: 'Puertas Tocadas' },
  { id: 'VoluntariosGenerados', label: 'Voluntarios Generados' },
];
