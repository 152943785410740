import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Box, Icon, Tooltip, Chip, Alert, Typography, Card, CardContent } from '@mui/material';
import { orange } from '@mui/material/colors';

// Componentes
import CustomTable from '@components/Tables/CustomTable';

// Servicios, Data y Middleware
import MovilizationServices from '@services/MovilizationServices';
import middleware from '@middlewares/middleware';
import { COLUMS_SUMMARY_MOBILIZATION_SIMULATED } from '@data/constants/Mobilization';

const ListReported = ({ dataParams }) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const getMovSummary = async (value) => {
    setLoading(true);

    const params = { filtered: value.filtered };

    try {
      const res = await MovilizationServices.getSummaryMobilizers(params);
      const { results, message, response, success } = res;

      if (results && success) {
        setRows(
          response.data.map((item) => {
            return {
              ...item,
              NombreCompleto:
                item.PaseLista === 'NO' ? (
                  item.NombreCompleto?.toUpperCase()
                ) : (
                  <Tooltip title="NO TIENE PASE DE LISTA">
                    <Chip
                      size="small"
                      icon={
                        <Icon fontSize="small" sx={{ color: '#e65100 !important' }}>
                          error
                        </Icon>
                      }
                      label={item.NombreCompleto?.toUpperCase()}
                      variant="contained"
                      sx={{ color: orange[900], backgroundColor: orange[100] }}
                    />
                  </Tooltip>
                ),
            };
          })
        );
      } else throw new Error(message);
    } catch (error) {
      setRows([]);
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMovSummary(dataParams);
  }, [dataParams]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 0.4,
            mb: 1,
          }}
        >
          <Typography fontWeight={600} variant="subtitle2">
            REPORTADOS
          </Typography>

          <Chip
            label={rows.length}
            color="primary"
            size="small"
            icon={<Icon>check_circle</Icon>}
            sx={{ width: '10%' }}
          />
        </Box>

        <Alert
          severity="error"
          color="warning"
          sx={{
            display: rows.length > 0 && rows.some((item) => item.PaseLista === 'SI') ? 'flex' : 'none',
            alignItems: 'center',
            mb: 1,
          }}
        >
          Advertencia. Si el responsable tiene el símbolo{' '}
          {
            <Icon fontSize="small" color="error">
              error
            </Icon>
          }{' '}
          aún no ha realizado el pase de lista.
        </Alert>

        <CustomTable
          rows={rows}
          columns={COLUMS_SUMMARY_MOBILIZATION_SIMULATED}
          total={rows.length}
          pageSize={25}
          isLoading={loading}
          stickyHeader
          maxHeight={'calc(100vh - 180px)'}
        />
      </CardContent>
    </Card>
  );
};

export default ListReported;
