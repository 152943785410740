import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

// Material UI
import { Box, Typography, styled } from '@mui/material';

// Assets
import UploadFile from '@assets/img/upload_files.svg';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme, isValid, error, inicialState }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: 6,
  transition: theme.transitions.create('padding'),
  backgroundColor: inicialState
    ? theme.palette.grey[50]
    : isValid
      ? error
        ? '#FFC3C3'
        : '#E2FFE5'
      : '#FFC3C3',
  border: `1px dashed ${theme.palette.grey[400]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
  [theme.breakpoints.down('md')]: { padding: theme.spacing(1, 1) },
}));

// ----------------------------------------------------------------------

const CustomUploadFile = (props) => {
  const {
    acceptFiles = '', // ejemplo ".pdf, .xlsx" (con punto y separado por comas)
    fileName = 'Archivo',
    setReset = () => {},
    getFile = () => {},
    handleError = () => {},
    handleFileUpload = null,
    API = () => ({ results: false, message: 'Ingresa una API' }),
    params = {},
    setResponse = () => {},
    setLoading = () => {},
    sx = {},
  } = props;

  const [isFileTypeError, setIsFileTypeError] = useState(false);
  const [inicialState, setInicialState] = useState(true);
  const [error, setError] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    setInicialState(false);
    setReset();

    const acceptedExt = acceptFiles ? acceptFiles.split(',').map((ext) => ext.trim()) : [];
    const fileExt = '.' + file.name.split('.').pop().toLowerCase();

    if ((file && acceptedExt.length === 0) || acceptedExt.includes(fileExt)) {
      setIsFileTypeError(false); // Reiniciar el estado de error de tipo de archivo
      getFile(file);

      if (handleFileUpload) handleFileUpload(file);
      else {
        setLoading(true);
        setError(false);
        try {
          const result = await API(params);
          const { results, response, message } = result;

          if (results) {
            setResponse(response);
            Swal.fire({ title: message, icon: 'success' });
          } else throw new Error(message);
        } catch (e) {
          setError(true);
          handleError();
          Swal.fire({ title: e.message, icon: 'warning' });
        } finally {
          setLoading(false);
        }
      }
    } else {
      handleError();
      setIsFileTypeError(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    onDrop,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...(isDragReject && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
        isValid={!isFileTypeError}
        error={error}
        inicialState={inicialState}
      >
        <input {...getInputProps()} accept={acceptFiles} />

        <Box component="img" alt="avatar upload" src={UploadFile} width={140} />

        <Box
          sx={{
            px: { xs: 1, md: 2, lg: 3 },
            pt: 3,
            pb: { xs: 1, md: 3 },
            ml: { md: 2 },
          }}
        >
          <Typography gutterBottom variant="h5">
            Arrastre o seleccione el {fileName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Coloque el {fileName.toLowerCase()} aquí o haga click en&nbsp;
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              navegar
            </Typography>
            &nbsp;a través de su máquina
          </Typography>

          {!inicialState && (
            <Typography
              gutterBottom
              variant="h6"
              margin={0}
              marginTop={2}
              color={isFileTypeError ? '#9A3939' : error ? '#9A3939' : '#5BA681'}
            >
              {isFileTypeError
                ? 'Solo se permiten archivos ' + acceptFiles
                : 'Archivo permitido' + (error ? ' (con errores)' : '')}
            </Typography>
          )}
        </Box>
      </DropZoneStyle>
    </Box>
  );
};

CustomUploadFile.propTypes = {
  acceptFiles: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  setReset: PropTypes.func,
  getFile: PropTypes.func,
  handleError: PropTypes.func,
  handleFileUpload: PropTypes.func,
  API: PropTypes.func,
  params: PropTypes.object,
  setResponse: PropTypes.func,
  setLoading: PropTypes.func,
  sx: PropTypes.object,
};

export default CustomUploadFile;
