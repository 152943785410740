import { useState } from 'react';

// Material UI
import { Card, CardContent } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import CustomTabs from '@components/Tabs/CustomTabs';
import Form from '@components/Aportaciones/AportantesForm';
import View from '@components/Aportaciones/AportantesView';

// Middleware
import middleware from '@middlewares/middleware';

// Hooks
import { useCatalogs } from '@hooks/useCatalogs';

const Aportantes = () => {
  const add = middleware.checkMenuAction('Agregar');
  const editable = middleware.checkMenuAction('Editar') || middleware.checkMenuAction('Eliminar');

  const [tab, setTab] = useState(add ? 0 : 1);

  const catalogsOptions = [{ id: 'municipios', getAll: false }];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  return (
    <AdminLayout>
      <ActionHeader title="Aportantes" />

      <Card className="card-primary">
        <CardContent>
          <CustomTabs
            value={tab}
            setValue={setTab}
            tabs={[
              {
                value: 0,
                label: 'Agregar',
                icon: 'add',
                sxContent: { pt: 0 },
                component: <Form catalogs={catalogs} isLoadingCat={load} />,
                display: add,
              },
              {
                value: 1,
                label: editable ? 'Editar' : 'Ver',
                icon: editable ? 'edit' : 'visibility',
                sxContent: { pt: 0 },
                component: <View catalogs={catalogs} isLoadingCat={load} />,
              },
            ]}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default Aportantes;
