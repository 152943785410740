import { PrivilegedRoute } from '@middlewares';
import EstructuraElectoral from '@pages/Metas/EstructuraElectoral';
import MetasCompromisos from '@pages/Metas';

const AuditoriaRoutes = [
  // {
  //   path: "/metas",
  //   element: <ProtectedRoute></ProtectedRoute>,
  // },
  {
    path: '/metas/estructura-electoral',
    element: (
      <PrivilegedRoute>
        <EstructuraElectoral />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/metas/compromisos',
    element: (
      <PrivilegedRoute>
        <MetasCompromisos />
      </PrivilegedRoute>
    ),
  },
];

export default AuditoriaRoutes;
