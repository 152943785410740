import { useState } from 'react';

// Material UI
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// Utilidades
import { setVars } from '@utils/global';

const AccordionMobilizers = (props) => {
  const { ids, setIds, mobilizers } = props;

  const [expanded, setExpanded] = useState(mobilizers.totalsResponsables[0]?.value);

  const handleChange = (panel) => (e, isExp) => setExpanded(isExp ? panel : false);

  const handleSetID = (id) => {
    const filter = [...ids, id];
    setIds(filter);
    setVars('MobAsignIds', filter);
  };
  const handleDelID = (id) => {
    const filter = ids.filter((item) => item !== id);
    setIds(filter);
    setVars('MobAsignIds', filter);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        {mobilizers.totalsResponsables.map((item, index) => (
          <Accordion key={index} expanded={expanded === item.value} onChange={handleChange(item.value)}>
            <AccordionSummary expandIcon={<ExpandMore />}>{item.label}</AccordionSummary>
            <AccordionDetails sx={{ overflowY: 'auto', maxHeight: 250, p: 0 }}>
              <List dense component="div" role="list" sx={{ py: 0 }}>
                {mobilizers.data
                  .filter((subItem) => item.value === subItem.idResponsable && !ids.includes(subItem.value))
                  .map((subItem, index) => {
                    const labelId = `transfer-list-item-${subItem.value}-label`;
                    const label = subItem.label;
                    const id = subItem.value;

                    return (
                      <ListItemButton key={label} role="listitem" onClick={() => handleSetID(id)}>
                        <ListItemIcon>
                          <Checkbox
                            checked={false}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={label} />
                      </ListItemButton>
                    );
                  })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" color="initial">
          Seleccionados:
        </Typography>
        <List dense component="div" role="list">
          {mobilizers.data
            .filter((subItem) => ids.includes(subItem.value))
            .map((subItem, index) => {
              const labelId = `transfer-list-item-${subItem.value}-label`;
              const label = subItem.label;
              const id = subItem.value;

              return (
                <ListItemButton key={label} role="listitem" onClick={() => handleDelID(id)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={true}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={label} />
                </ListItemButton>
              );
            })}
        </List>
      </Grid>
    </Grid>
  );
};

export default AccordionMobilizers;
