// Material UI
import { IconButton } from '@mui/material';
import { MapOutlined } from '@mui/icons-material';

const ControlCenterView = ({ handleClickEvent, width = 0 }) => {
  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  };
  const positionClass = POSITION_CLASSES['bottomleft'];

  return (
    <div className="leaflet-control-container">
      <div className={positionClass}>
        <div className="leaflet-bar leaflet-control" style={{ marginLeft: width + 10 }}>
          <IconButton
            aria-label="Cambiar tipo de vista"
            onClick={handleClickEvent}
            style={{ backgroundColor: 'white' }}
            sx={{
              height: '30px',
              width: '30px',
              borderRadius: '3px',
              '&:hover': { backgroundColor: '#eeeeee!important' },
            }}
          >
            <MapOutlined style={{ fontSize: 25, color: '#000' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ControlCenterView;
