import { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

import { Box, Card, CardContent, Stack, Typography, Button } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Swal from 'sweetalert2';

import BasicSelect from '@components/Selects/BasicSelect';

import prep from '@services/PrepServices';

const ReportFilter = (props) => {
  const { onChangeFilter } = props;
  const catalogsOptions = [{ id: 'tipo_eleccion' }];
  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [catGroupType, setCatGroupType] = useState([]);
  const [groupType, setGroupType] = useState('');
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [electionType, setElectionType] = useState([]);

  useEffect(() => {
    const init = async () => {
      if (!load) {
        setElectionType(catalogs.tipo_eleccion[0].value);
        await initLoad(catalogs.tipo_eleccion[0].value);
      }
    };

    init();
    //eslint-disable-next-line
  }, [load]);

  const initLoad = async (idTipoEleccion) => {
    await loadGrouper(idTipoEleccion).then((res) => {
      if (res.success) {
        onChangeFilter({
          idTipoEleccion: idTipoEleccion,
          idAmbitoAgrupador: res.data[0].value,
        });
      }
    });
  };

  const handleChangeGroupType = (e) => setGroupType(e.target.value);
  const handleChangeReportType = async (e) => {
    const value = e.target.value;
    setElectionType(value);
    await loadGrouper(value);
  };

  const loadGrouper = async (electionType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCatalogs(true);
        const filtered = [{ id: 'idTipoEleccion', filter: '=', value: electionType }];
        const result = await prep.getElectionScopeReport({ filtered });
        const { results, response, message } = await result;

        if (results) {
          setCatGroupType(response.data);
          setGroupType(response.data[0].value);
          resolve({ success: true, data: response.data });
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
          reject({ success: false });
        }
      } catch (error) {
        Swal.fire({
          title: error,
          icon: 'warning',
        });
        reject({ success: false });
      } finally {
        setLoadingCatalogs(false);
      }
    });
  };

  const handleSearchFilter = () => {
    onChangeFilter({
      idTipoEleccion: electionType,
      idAmbitoAgrupador: groupType,
    });
  };

  const handleClearFilter = async () => {
    setElectionType(catalogs.tipo_eleccion[0].value);
    await initLoad(catalogs.tipo_eleccion[0].value);
  };

  return (
    <Box component={Card} className="card-primary">
      <Box component={CardContent}>
        <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
          <FilterList />
          <Typography variant="body2" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>

        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={5} lg={5}>
            <BasicSelect
              name="electionType"
              label="Tipo de elección"
              options={catalogs.tipo_eleccion}
              value={electionType}
              onChange={handleChangeReportType}
              isLoading={load}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} md={5} lg={5}>
            <BasicSelect
              name="groupType"
              label="Tipo de agrupación"
              options={catGroupType}
              value={groupType}
              onChange={handleChangeGroupType}
              isLoading={loadingCatalogs}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} md={1} lg={1}>
            <Button
              sx={{ width: '100%' }}
              color="primaryDark"
              variant="contained"
              onClick={handleSearchFilter}
            >
              Filtrar
            </Button>
          </Grid2>
          <Grid2 xs={12} md={1} lg={1}>
            <Button sx={{ width: '100%' }} color="primaryDark" onClick={handleClearFilter}>
              Limpiar
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default ReportFilter;
