import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import UserUpdateContext from './UserUpdateContext';

const UserResponsable = (props) => {
  const { idUsuario, API, APIDELETE } = props;
  const { setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm } = useContext(UserUpdateContext);

  const fontHeader = { fontWeight: 'bold', mb: 2, fontSize: 18, textAlign: 'center' };
  const [dispositivos, setDispositivos] = useState([]);

  const loadDispositivos = async () => {
    if (!idUsuario) {
      return;
    }

    await API(idUsuario)
      .then((response) => {
        if (response.status === 200) {
          setDispositivos(response.response.data);
        } else {
          Swal.fire('Error', 'Hubo un error al cargar los dispositivos.', 'error');
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Hubo un error al cargar los dispositivos.', 'error');
      });
  };

  useEffect(() => {
    loadDispositivos();
    if (!idUsuario) {
      setDispositivos([]);
    }
    // eslint-disable-next-line
  }, [idUsuario]);

  const handleDesvincularClick = async (dispositivoId, dispositivoNombre) => {
    const desvincularData = {
      Device: dispositivoId,
      idUsuario: idUsuario,
    };

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres desvincular el dispositivo "${dispositivoNombre}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, desvincular',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff0000',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (result.isConfirmed) {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      try {
        const res = await APIDELETE(desvincularData);
        const { results, message } = res;

        if (results) {
          Swal.fire({
            title: message,
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
          }).then((res) => {
            if (res) {
              loadDispositivos();
            }
          });
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
          setIsSuccessForm(false);
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
        setIsSuccessForm(false);
      } finally {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      }
    }
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" sx={fontHeader}>
            Dispositivos Registrados
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserResponsable;
