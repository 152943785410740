import { PrivilegedRoute } from '@middlewares';
import MainPage from '@pages/Numeralia';

const NumeraliaRoutes = [
  {
    path: '/numeralia',
    element: (
      <PrivilegedRoute>
        <MainPage />
      </PrivilegedRoute>
    ),
  },
];

export default NumeraliaRoutes;
