export const ESTATUS_NO_ENCONTRADO = 3;
export const ESTATUS_CORREGIDO = 4;

export const TOTALES_POR_USUARIO_FECHA = [
  { id: 'Fecha', label: 'Fecha Captura', type: 'text', align: 'center' },
  { id: 'Username', label: 'Celular', type: 'text', align: 'center' },
  { id: 'NombreCompleto', label: 'Nombre Completo', type: 'text', align: 'center' },
  { id: 'CuadernillosEnRevision', label: 'Cuadernillos En Revisión', type: 'number', align: 'center' },
  { id: 'CuadernillosFinalizados', label: 'Cuadernillos Finalizados', type: 'number', align: 'center' },
  { id: 'FoliosAsignados', label: 'Folios Asignados', type: 'text', align: 'center' },
];

export const CONSULTA_POR_CASILLA_O_USUARIO = [
  { id: 'Fecha', label: 'Fecha', type: 'text', align: 'center' },
  { id: 'Username', label: 'Celular', type: 'text', align: 'center' },
  { id: 'NombreCompleto', label: 'Nombre Completo', type: 'text', align: 'center' },
  { id: 'DistritoFederal', label: 'Distrito Federal', type: 'text', align: 'center' },
  { id: 'DistritoLocal', label: 'Distrito Local', type: 'text', align: 'center' },
  { id: 'Region', label: 'Región', type: 'text', align: 'center' },
  // { id: "Municipio", label: "Municipio", type: "text", align: "center"  },
  { id: 'MunicipioReportes', label: 'Municipio', type: 'text', align: 'center' },
  { id: 'Seccion', label: 'Seccion', type: 'number', align: 'center' },
  { id: 'NombreCasilla', label: 'Nombre Casilla', type: 'text', align: 'center' },
  { id: 'EstatusCuadernillo', label: 'Estatus Cuadernillo', type: 'text', align: 'center' },
  { id: 'FoliosAsignados', label: 'Folios Asignados', type: 'text', align: 'center' },
];

export const TOTALES_POR_CUADERNILLO = [
  { id: 'idRegion', label: 'Región', type: 'text', align: 'center' },
  { id: 'idDF', label: 'DF', type: 'text', align: 'center' },
  { id: 'idDL', label: 'DL', type: 'text', align: 'center' },
  { id: 'Municipio', label: 'Municipio', type: 'text', align: 'left' },
  { id: 'Seccion', label: 'Sección', type: 'number', align: 'right' },
  { id: 'NombreCasilla', label: 'Casilla', type: 'text', align: 'left' },
  { id: 'TotalCiudadanos', label: 'Total de Ciudadanos', type: 'number', align: 'right' },
  { id: 'CorrelativosAsignados', label: 'Correlativos Asignados', type: 'number', align: 'right' },
  { id: 'BingosReportados', label: 'Bingos Reportados', type: 'number', align: 'right' },
  { id: 'BingosCompromisos', label: 'Bingos Compromisos', type: 'number', align: 'right' },
];

export const DETALLE_USUARIO_FECHA = [
  { id: 'Fecha', label: 'Fecha Captura', type: 'text', align: 'center' },
  { id: 'NombreUsuario', label: 'Nombre Completo', type: 'text', align: 'center' },
  { id: 'idRegion', label: 'Región', type: 'text', align: 'center' },
  { id: 'idDF', label: 'DF', type: 'text', align: 'center' },
  { id: 'idDL', label: 'DL', type: 'text', align: 'center' },
  { id: 'Municipio', label: 'Municipio', type: 'text', align: 'left' },
  { id: 'Seccion', label: 'Sección', type: 'number', align: 'right' },
  { id: 'NombreCasilla', label: 'Casilla', type: 'text', align: 'left' },
  { id: 'CorrelativosAsignados', label: 'Correlativos Asignados', type: 'number', align: 'right' },
  { id: 'BingosReportados', label: 'Bingos Reportados', type: 'number', align: 'right' },
  { id: 'BingosCompromisos', label: 'Bingos Compromisos', type: 'number', align: 'right' },
];
