import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Yup from '@utils/Yupi18n';
import Swal from 'sweetalert2';

// Material UI
import {
  Modal,
  Box,
  IconButton,
  Button,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close } from '@mui/icons-material';

// Componentes
import InputSelect from '@components/Selects/BasicSelect';
import BasicList from '@components/Lists/BasicList';
import RouteListItem from '@components/DiaD/RoutesMRT/RouteListItem';

// Servicios, assets, etc
import DiaDServices from '@services/DiaDServices';
import NotPermission from '@assets/img/NotPermission.jpg';
import middleware from '@middlewares/middleware';

const AssignMecanismo = ({
  open,
  setOpen,
  filtro,
  title = { tipo: 'Mecanismo', ruta: '' },
  catalogs,
  load,
}) => {
  const filtroTodos = [{ label: 'TODOS', value: 0 }];
  const [volunteer, setVolunteer] = useState({ NombreCompleto: '' });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [municipio, setMunicipio] = useState([]);

  const formik = useFormik({
    initialValues: {
      idPropietario: null,
      idUsuario: null,
      idRuta: null,
    },
    validationSchema: Yup.object().shape({
      idPropietario: Yup.number().required(),
      idUsuario: Yup.number().required(),
      idRuta: Yup.number().required(),
    }),
    onSubmit: (values) => {
      // Aquí manejas la lógica de envío del formulario
      onSubmit(values);
    },
  });
  const [dataFilter, setDataFilter] = useState({
    idRegion: filtro.idRegion,
    idMunicipio: filtro.idMunicipio,
  });

  useEffect(() => {
    if (!load) setMunicipio(filtroTodos.concat(catalogs.municipios_dl));
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    setDataFilter({
      idRegion: filtro.idRegion,
      idMunicipio: filtro.idMunicipio,
    });
  }, [filtro]);

  useEffect(() => {
    if (open)
      formik.setValues({
        ...formik.values,
        idRuta: title?.idRuta ?? null,
        idPropietario: title?.idPropietario ?? null,
      });
    // eslint-disable-next-line
  }, [title]);

  useEffect(() => {
    if (open) handleSearch('');
    // eslint-disable-next-line
  }, [dataFilter]);

  const getList = (data) => {
    if (loading) {
      return true;
    }
    setLoading(true);

    DiaDServices.getMecanismosRoutes({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            const volunteers = res.response.data.map((volunteer) => {
              return {
                ...volunteer,
                NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
              };
            });

            setData(volunteers);
            setTotal(res.response.total);
          }

          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else throw new Error(res.message);
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  };

  const fetchHasMore = () => {
    DiaDServices.getMecanismosRoutes({
      page: page + 1,
      pageSize: pageSize,
      filtered: filtered,
    })
      .then((res) => {
        if (res.results) {
          const volunteers = res.response.data.map((volunteer) => {
            return {
              ...volunteer,
              NombreCompleto: `${volunteer.Nombre} ${volunteer.Paterno}`,
            };
          });
          setData(data.concat(volunteers));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
        } else throw new Error(res.message);
      })
      .catch((e) => {
        setHasMore(false);
        Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
      });

    setPage(page + 1);
  };

  const handleClickData = (data) => {
    formik.setValues({ ...formik.values, idUsuario: data.id });
    setVolunteer(data);
  };

  const onSubmit = async (values) => {
    const res = await Swal.fire({
      title: 'Asignar!',
      html: `<p>Esta seguro de asignar la Ruta ${title.ruta} al ${title.tipo} ${volunteer.NombreCompleto}</p>`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Continuar`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: { container: 'modal-alert' },
    });

    if (res.isConfirmed) {
      setIsLoadingForm(true);
      try {
        const result = await DiaDServices.assignMecanismoToRoute(values);
        const { results, message } = result;
        if (results) setOpen(false);
        else throw new Error(message);
      } catch (e) {
        Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
      } finally {
        setIsLoadingForm(false);
      }
    }
  };

  const evalInputSearch = (text) => {
    let out_text = text.trim();
    let type = 'text';
    // const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const phoneRegex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

    if (phoneRegex.test(out_text)) {
      out_text = out_text.replace(/\D/g, '').slice(-10);
      type = 'phone';
    } else {
      out_text = text.replace(/\s+/g, '%');
    }

    return {
      type,
      text: out_text,
    };
  };

  const handleSearch = (searchInput) => {
    let filtro = [];
    if (searchInput === '') {
      filtro = [];
    } else {
      const { type, text } = evalInputSearch(searchInput);
      if (type === 'phone') {
        filtro = [{ id: `usuarios.Celular`, filter: '=', value: `${text}`, inheritFilterType: null }];
      } else {
        filtro = [
          {
            id: `CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)`,
            filter: 'LIKE',
            value: `%${text}%`,
            inheritFilterType: null,
          },
        ];
      }
    }

    if (dataFilter.idRegion > 0) {
      filtro.push({ id: 'cat_municipios_reportes.idRegion', value: dataFilter.idRegion, filter: '=' });
    }

    if (dataFilter.idMunicipio > 0) {
      filtro.push({ id: 'cat_municipios_reportes.id', value: dataFilter.idMunicipio, filter: '=' });
    }

    setData([]);
    setFiltered(filtro);
    setPage(0);
    getList({ page: 0, pageSize: pageSize, filtered: filtro });
  };

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataFilter({ ...dataFilter, idRegion: idRegion, idMunicipio: 0 });

    if (idRegion > 0) {
      setMunicipio(filtroTodos.concat(catalogs.municipios_dl.filter((item) => item.idRegion === idRegion)));
    } else setMunicipio(filtroTodos.concat(catalogs.municipios_dl));
  };

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    setDataFilter({ ...dataFilter, idMunicipio: idMunicipio });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            p: 2,
            width: { xs: '100%', sm: '90%', md: '80%', lg: '50%' },
            overflowY: 'auto',
            maxHeight: '90%',
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: 'fixed', left: '3%', top: '2%', color: '#FFFFFF' }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          {
            /* middleware.checkMenuAction("Autorizar") */ true ? (
              <Card elevation={0} sx={{ overflow: 'auto' }}>
                <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
                  <Grid item xs={12} md={6}>
                    <InputSelect
                      label="Región"
                      options={!load ? filtroTodos.concat(catalogs.regiones) : []}
                      name="region"
                      value={dataFilter.idRegion}
                      onChange={handleChangeRegion}
                      disabled={!load && catalogs.regiones.length === 1 ? true : false}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSelect
                      label="Municipio"
                      options={municipio}
                      name="municipio"
                      value={dataFilter.idMunicipio}
                      onChange={handleChangeMunicipio}
                      disabled={!load && catalogs.municipios_dl.length === 1 ? true : false}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <CardHeader
                  title={`Asignar ${title.tipo}`}
                  subheader={`Ruta ${title.ruta} - Propietario ${title.idPropietario === 1 ? '1' : 2}`}
                  titleTypographyProps={{
                    align: 'center',
                    variant: 'h5',
                    color: 'white',
                  }}
                  subheaderTypographyProps={{ align: 'center', color: 'white' }}
                  sx={{ backgroundColor: '#152A9E', pt: '25px' }}
                />
                <CardContent>
                  <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid2 xs={12} sm={12} md={12} lg={12}>
                      {loading && <LinearProgress color="secondary" />}
                      <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
                        <BasicList
                          Header={false}
                          data={data}
                          fetchHasMore={fetchHasMore}
                          totalItems={total}
                          itemComponent={RouteListItem}
                          hasMore={hasMore}
                          finder={true}
                          search={handleSearch}
                          handleClickItem={handleClickData}
                        />
                      </Stack>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            ) : (
              <Card elevation={0}>
                <CardHeader
                  title={`Asignar ${title.tipo}`}
                  subheader={`Ruta ${title.ruta}`}
                  titleTypographyProps={{
                    align: 'center',
                    variant: 'h5',
                    color: 'white',
                  }}
                  subheaderTypographyProps={{ align: 'center', color: 'white' }}
                  sx={{ backgroundColor: '#152A9E', pt: '25px' }}
                />
                <CardContent>
                  <Grid2 container justifyContent="center" rowSpacing={1}>
                    <Grid2 item xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        style={{
                          fontFamily: 'Open Sans, sans-serif',
                          fontSize: '25px',
                          color: 'red',
                          textAlign: 'center', // Centra el texto
                        }}
                      >
                        No tiene los permisos necesarios. Contacte al administrador
                      </Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={12} md={12} lg={12}>
                      <img src={NotPermission} alt="permission" style={{ width: '100%', height: '100%' }} />
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            )
          }
          <div>
            <Grid2 container spacing={1} justifyContent={'flex-end'}>
              <Grid2 item>
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={isLoadigForm}
                  endIcon={<Icon>save</Icon>}
                  loadingPosition="end"
                  onClick={formik.submitForm}
                >
                  Asignar
                </LoadingButton>
              </Grid2>
              <Grid2 item>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => setOpen(false)}
                  sx={{ borderRadius: '10px' }}
                  endIcon={<Close />}
                >
                  Cerrar
                </Button>
              </Grid2>
            </Grid2>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AssignMecanismo;
