import ApiExec from '@utils/ApiExec';
import { encryptVars, DEBUG } from '@utils/global';
/**
 * Clase para invocar las apis del módulo menus
 */

class UserRoleService {
  getRoles = (params) => {
    return ApiExec(params, 'roles/get', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getMenus = (params) => {
    return ApiExec(params, 'roles/get-menus', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getParentRoles = (params) => {
    return ApiExec(params, 'roles/get-parent-roles', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getChildrenRoles = (params) => {
    return ApiExec(params, 'roles/get-children-roles', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getRole = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `roles/find/${_id}`, 'GET')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setRole = (params) => {
    return ApiExec(params, `roles/create`, 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updRole = (params) => {
    return ApiExec(params, `roles/update`, 'PUT')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getImpact4Change = (params) => {
    return ApiExec(params, 'roles/get-impact-change-menu', 'GET')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  changeAccess = (params) => {
    return ApiExec(params, 'roles/update-menu-privilege', 'PUT')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getImpact4MenuDelete = (params) => {
    return ApiExec(params, `roles/get-impact-delete-menu`, 'GET')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  deleteMenu = (params) => {
    return ApiExec(params, 'roles/delete-menu', 'DELETE')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getUsers = (params) => {
    return ApiExec(params, `roles/get-users/`, 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getImpact4Delete = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `roles/get-impact-delete/${_id}`, 'GET')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  delete = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `roles/delete/${_id}`, 'DELETE')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  insertMenus = (params) => {
    return ApiExec(params, `roles/insert-menus`, 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  changeOrder = (params) => {
    return ApiExec({ params: params }, 'roles/change-order', 'PUT')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const role = new UserRoleService();
export default role;
