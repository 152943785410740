import { useState, useRef, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import { Icon, TextField, IconButton, Button, Card, CardContent, FormHelperText } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import RichTextEditor from '@components/Containers/RichTextEditor';

// Utils
import { handleAttrs, generarURL, formatDateAPI } from '@utils/Utilities';

// Servicios
import services from '@services/NoticiasServices';

// Data
import { EditNoticiasInterface, NoticiasInterface } from '@interfaces/NoticiasInterfaces';
import { EditNoticiasSchema, NoticiasSchema } from '@schemas/NoticiasSchemas';

// Middleware
import middleware from '@middlewares/middleware';

const NoticiasForm = (props) => {
  const {
    type = 'create',
    catalogs = {},
    load = false,
    idPagina,
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditNoticiasInterface : NoticiasInterface,
    validationSchema: isEdit ? EditNoticiasSchema : NoticiasSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando noticia...');

  useEffect(() => {
    if (data) formik.setValues({ ...data, files: [] });
    else formik.setValues({ ...formik.values, FechaPublicacion: new Date() });
    // eslint-disable-next-line
  }, [data]);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];

    e.target.value = null;

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        let FileName = uploadedFile.name;

        FileName = FileName.replace(/\s+/g, '_');
        FileName = FileName.replace(/[^a-zA-Z0-9-_.]/g, '');

        formik.setValues({
          ...formik.values,
          FileName: FileName,
          files: [{ file: base64String }],
        });
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleClick = () => fileInputRef.current.click();

  const handleEdit = async (params) => {
    const { Tipo, ...all } = params;

    const localParams = {
      ...all,
      URL: generarURL(params.Titulo),
      FechaPublicacion: formatDateAPI(params.FechaPublicacion),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando noticia...');

      const result = await services.updateNoticia(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        formik.setValues({ ...params, files: [] });
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      idPagina,
      URL: generarURL(params.Titulo),
      FechaPublicacion: formatDateAPI(params.FechaPublicacion),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando noticia...');

      const result = await services.setNoticia(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
        formik.resetForm();
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const isError = (value) => formik.touched[value] && Boolean(formik.errors[[value]]);

  const loadAll = load;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage={'Noticia creada con exito!'}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          catalogs={catalogs}
          formik={formik}
          loadAll={loadAll}
          isError={isError}
          isEdit={isEdit}
          handleClick={handleClick}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
        />
      ) : (
        <Card sx={{ overflow: 'visible' }}>
          <CardContent>
            <Form
              catalogs={catalogs}
              formik={formik}
              loadAll={loadAll}
              isError={isError}
              isEdit={isEdit}
              handleClick={handleClick}
              fileInputRef={fileInputRef}
              handleFileChange={handleFileChange}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({
  catalogs,
  formik,
  loadAll,
  isError,
  isEdit,
  handleClick,
  fileInputRef,
  handleFileChange,
  handleClose,
  handleDelete,
  data,
}) => (
  <Grid2 container spacing={2}>
    <Grid2 xs={12} sm={6} lg={4}>
      <BasicSelect
        name="idTipo"
        label="Tipo *"
        options={catalogs?.cat_tipo_noticia}
        value={formik.values.idTipo}
        onChange={formik.handleChange}
        size="small"
        sx={{ width: '100%' }}
        disabled={loadAll}
        isLoading={loadAll}
        error={isError('idTipo')}
        errorMessage={formik.errors.idTipo}
      />
    </Grid2>

    <Grid2 xs={12} sm={6} lg={4}>
      <TextField
        required
        label="Titulo"
        size="small"
        name="Titulo"
        fullWidth
        value={formik.values.Titulo}
        onChange={formik.handleChange}
        disabled={loadAll}
        error={isError('Titulo')}
        helperText={formik.errors.Titulo}
      />
    </Grid2>

    <Grid2 xs={12} sm={12} lg={4}>
      <TextField
        required
        label="Descripción"
        size="small"
        name="Descripcion"
        fullWidth
        multiline
        minRows={isEdit ? 3 : 1}
        value={formik.values.Descripcion}
        onChange={formik.handleChange}
        disabled={loadAll}
        error={isError('Descripcion')}
        helperText={formik.errors.Descripcion}
      />
    </Grid2>

    <Grid2 xs={12} sm={6} lg={6}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
        <MobileDateTimePicker
          label="Fecha de Publicación *"
          value={formik.values.FechaPublicacion}
          onChange={(e) => formik.setValues({ ...formik.values, FechaPublicacion: e ?? '' })}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              disabled={loadAll}
              sx={{ width: '100%' }}
              error={isError('FechaPublicacion')}
              helperText={formik.errors.FechaPublicacion}
            />
          )}
        />
      </LocalizationProvider>
    </Grid2>

    {/* Archivo */}
    <Grid2 xs={12} sm={6}>
      <TextField
        required={Boolean(!formik.values.FileName)}
        label="Imagen"
        size="small"
        name="imagen"
        fullWidth
        onClick={handleClick}
        onChange={handleClick}
        value={formik.values.FileName}
        disabled={loadAll}
        error={isError('FileName')}
        helperText={formik.errors.FileName}
        InputProps={{
          endAdornment: (
            <IconButton component="span" aria-label="upload file" color="primary">
              <Icon>upload_file</Icon>
            </IconButton>
          ),
        }}
      />
      <input
        name="imagen"
        type="file"
        ref={fileInputRef}
        disabled={loadAll}
        accept=".png,.jpg,.jpeg"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </Grid2>

    <Grid2 xs={12} lg={12}>
      <RichTextEditor
        value={formik.values.Contenido}
        onChange={(value) => formik.setFieldValue('Contenido', value)}
        placeholder="Escribe aquí..."
        disabled={loadAll}
      />

      <FormHelperText id="RichTextEditor" error={isError('Contenido')}>
        {formik.errors.Contenido}
      </FormHelperText>
    </Grid2>

    <Grid2 xs={12}>
      <>
        <Grid2 container spacing={2} justifyContent={'end'}>
          {isEdit && (
            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                Cancelar
              </Button>
            </Grid2>
          )}

          {isEdit && middleware.checkMenuAction('Eliminar') && (
            <Grid2 xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                Eliminar
              </Button>
            </Grid2>
          )}

          <Grid2 xs={12} sm={4} md={2} lg={2}>
            <Button
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
              disabled={loadAll}
              fullWidth
            >
              {isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </Grid2>
        </Grid2>
      </>
    </Grid2>
  </Grid2>
);

export default NoticiasForm;
