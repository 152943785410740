import Yup from "@utils/Yupi18n";

const OficialiaAddSchema = Yup.object({
	idUsuarioRecibe: Yup.number().required().label("Persona que recibe"),
	idTipoDocumento: Yup.number().required().label("Tipo de documento"),
	FolioOficio: Yup.string()
		.optional()
		.nullable()
		.min(3)
		.label("Número de Oficio"),
	FechaOficio: Yup.date().optional().nullable(true).label("Fecha Oficio"),
	FechaRecibido: Yup.date().optional().label("Fecha Recibido"),
	FechaTermino: Yup.date().optional().nullable(true).label("Fecha Termino"),
	Asunto: Yup.string().required().min(5).label("Asunto"),
	SolicitaRespuesta: Yup.number().required().label("Solicita una respuesta"),
	idPrioiridad: Yup.number().required().label("Prioridad"),
	idPersonaEnvia: Yup.number().required().label("Quien firma el documento"),

	idParaQuien: Yup.number().required().label("A quien va dirigido"),

	TieneAdjuntos: Yup.number().required(),
	ComentarioAdjuntos: Yup.string().optional().label("Comentario de adjuntos"),
	FotosAdjuntos: Yup.array().optional().label("Foto de adjuntos"),
	OficioAdjuntos: Yup.array().optional().label("Oficio adjunto"),
});

export { OficialiaAddSchema };
