import { useState, useEffect } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
  Checkbox,
  Collapse,
  Switch,
} from '@mui/material';
import Swal from 'sweetalert2';

import RoleServices from '@services/RoleServices';
import { MENUS_PRIVILEGES } from '@data/constants';

const mapItem = (item) => {
  return {
    idMenu: item.idMenu,
    Ver: item.Ver,
    Agregar: item.Agregar,
    Editar: item.Editar,
    Eliminar: item.Eliminar,
    Exportar: item.Exportar,
    Importar: item.Importar,
    Autorizar: item.Autorizar,
    Visible: item.Visible,
  };
};

const MenuRow4SelectEdit = (props) => {
  const { menu, select, selectedMenu, reset, setIsOpen, setIsLoading, handleReloadList, externalID } = props;
  const [open, setOpen] = useState(false);
  const [disabledSubmenus, setDisabledSubMenus] = useState(true);
  const [submenus, setSubmenus] = useState(() => {
    if (menu.submenus.length > 0) {
      return menu.submenus.map((item) => {
        let row = mapItem(item);
        row.checked = false;
        return row;
      });
    }
    return [];
  });

  const [menu4Select, setMenu4Select] = useState(() => {
    let row = mapItem(menu);
    row.checked = false;
    return row;
  });
  const columns = MENUS_PRIVILEGES;

  useEffect(() => {
    if (reset) {
      setMenu4Select(() => {
        let row = mapItem(menu);
        row.checked = false;
        return row;
      });
      setSubmenus(() => {
        if (menu.submenus.length > 0) {
          return menu.submenus.map((item) => {
            let row = mapItem(item);
            row.checked = false;
            return row;
          });
        }
        return [];
      });
    }
  }, [reset, menu]);

  const handleChecked = (e, index, item, type, checked) => {
    e.preventDefault();

    if (type === 'menu') {
      setMenu4Select({ ...menu4Select, checked: checked });
    } else if (type === 'submenu') {
      let items = [...submenus];
      let item = { ...items[index] };
      item.checked = checked;
      items[index] = item;
      setSubmenus(items);
    }

    if (checked) {
      if (type === 'menu') {
        setDisabledSubMenus(false);
        if (submenus.length > 0) {
          setOpen(true);
          setSubmenus(
            submenus.map((item) => {
              selectedMenu({
                action: 'add',
                item: item,
              });
              item.checked = true;
              return item;
            })
          );
        }
      }
      selectedMenu({
        action: 'add',
        item: item,
      });
    } else {
      if (type === 'menu') {
        setDisabledSubMenus(true);
        if (submenus.length > 0) {
          setSubmenus(
            submenus.map((item) => {
              selectedMenu({
                action: 'delete',
                item: item,
              });
              item.checked = false;
              return item;
            })
          );
        }
      }
      selectedMenu({
        action: 'delete',
        item: item,
      });
    }
  };

  const handleSwitch = (e, type, index, col) => {
    e.preventDefault();
    e.preventDefault();
    let checkedValue = e.target.checked ? 1 : 0;
    let idMenu = type === 'menu' ? menu4Select.idMenu : submenus[index].idMenu;
    showImpact4Change(idMenu, col, checkedValue, type, index);

    // if (type === "submenu") {
    //   let items = [...submenus];
    //   let item = { ...items[index] };
    //   item[col] = value;
    //   items[index] = item;
    //   setSubmenus(items);
    //   if (item.checked) {
    //     selectedMenu({
    //       action: "modify",
    //       item: item,
    //     });
    //   }
    // } else if (type === "menu") {
    //   setMenu4Select({ ...menu4Select, [col]: value });
    //   if (menu4Select.checked) {
    //     selectedMenu({
    //       action: "modify",
    //       item: menu4Select,
    //     });
    //   }
    // }
  };

  const showImpact4Change = (id, column, value, type, index) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.getImpact4Change({ menuId: id, roleId: externalID, column, value })
      .then((res) => {
        if (res.success) {
          let itAffects = res.response.AffectedUsers > 0 ? true : false;
          selectApplyChange(itAffects, res.response, id, column, value, type, index);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  const selectApplyChange = (itAffects, response, id, col, value, type, index) => {
    if (itAffects) {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            0: 'Aplicar a partir de ahora',
            1: 'Aplicar a todos',
          });
        }, 1000);
      });

      Swal.fire({
        title: 'Advertencia',
        html: `Esta acción puede modificar el privilegio a ${response.AffectedUsers} usuario(s) que tienen asignado este menú. ¿Qué acción desea realizar?`,
        icon: 'warning',
        input: 'radio',
        inputOptions: inputOptions,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aplicar',
        confirmButtonColor: '#1976d2',
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: (action) => {
          if (!action) {
            return 'Debe seleccionar una opción!';
          } else {
            if (action) {
              updatePrivillege(id, col, value, parseInt(action));
            }
          }
        },
      });
    } else {
      updatePrivillege(id, col, value, 0);
    }
  };

  const updatePrivillege = (id, column, value, action) => {
    setIsLoading(true);
    setIsOpen(true);
    RoleServices.changeAccess({ menuId: id, roleId: externalID, column, value, action })
      .then((res) => {
        if (res.success && res.results) {
          handleReloadList();
          Swal.fire({ title: res.message, icon: 'success' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell width="5%">
          {menu.submenus.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {select && (
            <Checkbox
              color="primary"
              onClick={(e) => handleChecked(e, 0, menu4Select, 'menu', !menu4Select.checked)}
              checked={menu4Select.checked}
            />
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {menu.Icono ? <Icon>{menu.Icono}</Icon> : menu.Siglas ? menu.Siglas : ''}
        </TableCell>
        <TableCell width="25%">
          {menu.Menu} <br />
          <small>{menu.Ruta}</small>
        </TableCell>
        {columns.map((col) => {
          return (
            <TableCell align="center" key={Math.random()} width="10%">
              {menu[`_${col}`] === 1 ? (
                <Switch
                  aria-label={`Switch ${col}_${menu.id}`}
                  onChange={(e) => handleSwitch(e, 'menu', 0, col)}
                  checked={menu4Select[col] === 1 ? true : false}
                />
              ) : (
                '-'
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {submenus.length > 0 && (
        <TableRow sx={{ padding: 0 }}>
          <TableCell sx={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {menu.submenus.map((submenu, i) => {
                    return (
                      <TableRow key={submenu.id}>
                        <TableCell align="right" colSpan={2} width="20%">
                          {select && (
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                handleChecked(e, i, submenus[i], 'submenu', !submenus[i].checked)
                              }
                              disabled={disabledSubmenus}
                              checked={submenus[i].checked}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right" width="8%">
                          {submenu.Icono ? (
                            <Icon>{submenu.Icono}</Icon>
                          ) : submenu.Siglas ? (
                            submenu.Siglas
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell width="18%" sx={{ pr: 0 }}>
                          {submenu.Menu} <br />
                          <small>{submenu.Ruta}</small>
                        </TableCell>
                        {columns.map((col) => {
                          return (
                            <TableCell align="center" key={Math.random()} width="10%">
                              {submenu[`_${col}`] === 1 ? (
                                <Switch
                                  aria-label={`Switch ${col}_${submenu.id}`}
                                  onChange={(e) => handleSwitch(e, 'submenu', i, col)}
                                  checked={submenus[i][col] === 1 ? true : false}
                                />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MenuRow4SelectEdit;
