import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

import OficialiaServices from '@services/OficialiaServices'; // Importar el servicio

const EstatusSeguimientoAutocomplete = ({ value, setValue }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const results = await OficialiaServices.getStatusOficio({
          page: 0,
          pageSize: 10,
          sorted: [],
        });

        if (results.success) {
          setData(results.response.data);
        } else {
          // Manejar el caso en que no se puedan obtener los datos
        }
      } catch (error) {
        console.error('Error al obtener tipo docto:', error);
      }
    };

    fetchEmpresas();
    console.log('=>combo Estatus: ', value);
  }, []);

  return (
    <Box>
      {/* Autocomplete para seleccionar empresa */}
      <Autocomplete
        value={value.Estatus || null}
        onChange={(event, newValue) => {
          setValue({ ...value, Estatus: newValue });
        }}
        onInputChange={(event, newInputValue) => {
          // Si el valor del input es vacío, eliminamos la prioridad del estado
          if (newInputValue === '') {
            setValue({ ...value, Estatus: null });
          }
        }}
        options={data}
        getOptionLabel={(option) => option.Estatus || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Estatus Seguimiento"
            variant="outlined"
            sx={{
              '& .MuiInputBase-root': {
                height: 40, // Ajusta la altura para que sea más delgado
                borderRadius: 2, // Redondeo similar a Select
              },
              '& .MuiInputLabel-root': {
                fontSize: 14, // Ajusta el tamaño de fuente
              },
            }}
          />
        )}
        fullWidth
      />
    </Box>
  );
};

export default EstatusSeguimientoAutocomplete;
