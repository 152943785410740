import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import BasicTable from '@components/Tables/BasicTable';
import Swal from 'sweetalert2';
import Filter from '../../components/Territorial/Filter';
import TerritorialService from '../../services/TerritorialServices';
import { numberWithCommas } from '@utils/Utilities';

import { CardContent, Container, Grid, Card, Typography, Stack } from '@mui/material';
import { isNull } from 'lodash';

const AvanceResponsabilidades = () => {
  //variables
  const [data, setData] = useState([]);
  const [dataResumen, setDataResumen] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalResumen, setTotalResumen] = useState(0);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [paramsResumen, setParamsResumen] = useState({
    page: 0,
    pageSize: 5,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingStads, setLoadingStads] = useState(false);
  /*   const [dataCard,setDataCard] = useState({
    "TotalMeta": 0,
    "TotalAvance": 0,
    "PorcentajeAvance": "0.22"
  }) */
  const columns = [
    { id: 'PerfilAmbito', label: 'Perfil', columnAction: false, width: 150 },
    { id: 'NombreCompleto', label: 'Nombre', columnAction: false, width: 200 },
    {
      id: 'Celular',
      label: 'Celular',
      columnAction: false,
      width: 200,
      orderBy: 'Celular',
    },
    { id: 'NombreResponsable', label: 'Responsable', columnAction: false, width: 200 },
    {
      id: 'CompromisosDirectos',
      label: 'Compromisos Directos',
      columnAction: false,
      width: 20,
    },
    {
      id: 'CompromisosUnicosDirectos',
      label: 'Compromisos Unicos Directos',
      columnAction: false,
      width: 20,
    },
    {
      id: 'UsuariosDirectos',
      label: 'Usuarios Directos',
      columnAction: false,
      width: 20,
    },
    {
      id: 'CompromisosEnRed',
      label: 'Compromisos En Red',
      columnAction: false,
      width: 20,
    },
    {
      id: 'CompromisosUnicosEnRed',
      label: 'Compromisos Unicos En Red',
      columnAction: false,
      width: 20,
    },
    {
      id: 'UsuariosEnRed',
      label: 'Usuarios En Red',
      columnAction: false,
      width: 20,
    },
  ];

  const columnsResumen = [
    { id: 'Data', label: '', columnAction: false, width: 200 },
    { id: 'Total', label: 'No', columnAction: false, width: 100 },
    { id: 'MetaEstructura', label: 'Meta', columnAction: false, width: 200 },
    {
      id: 'AvanceEstructura',
      label: 'Avance',
      columnAction: false,
      width: 200,
    },
    { id: 'PAvance', label: '% Avance', columnAction: false, width: 150 },
  ];

  //effets

  useEffect(() => {
    getData();
  }, [tableData]);

  useEffect(() => {
    getResumen();
  }, [paramsResumen]);

  // funciones

  const getData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    TerritorialService.getListResponsableDia(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                PerfilAmbito: (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: '550' }} variant="body1">
                        {item.PerfilAmbito}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="#133DCE" variant="caption">
                        {item.NombreAsigno}
                      </Typography>
                    </Grid>
                  </Grid>
                ),
                CompromisosDirectos:
                  item.CompromisosDirectos !== 0 ? numberWithCommas(item.CompromisosDirectos) : '0',
                CompromisosUnicosDirectos:
                  item.CompromisosUnicosDirectos !== 0
                    ? numberWithCommas(item.CompromisosUnicosDirectos)
                    : '0',
                UsuariosDirectos: item.UsuariosDirectos !== 0 ? numberWithCommas(item.UsuariosDirectos) : '0',
                CompromisosEnRed: item.CompromisosEnRed !== 0 ? numberWithCommas(item.CompromisosEnRed) : '0',
                CompromisosUnicosEnRed:
                  item.CompromisosUnicosEnRed !== 0 ? numberWithCommas(item.CompromisosUnicosEnRed) : '0',
                UsuariosEnRed: item.UsuariosEnRed !== 0 ? numberWithCommas(item.UsuariosEnRed) : '0',
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getResumen = () => {
    if (loadingStads) {
      return true;
    }

    setLoadingStads(true);

    TerritorialService.getResumenMetas(paramsResumen)
      .then((res) => {
        if (res.results) {
          setDataResumen(
            res.response.data.map((item) => {
              return {
                ...item,
                MetaEstructura: !isNull(item.AvanceEstructura)
                  ? parseInt(item.MetaEstructura) !== 0
                    ? numberWithCommas(parseInt(item.MetaEstructura))
                    : '0'
                  : '0',
                AvanceEstructura: !isNull(item.AvanceEstructura)
                  ? parseInt(item.AvanceEstructura) !== 0
                    ? numberWithCommas(parseInt(item.AvanceEstructura))
                    : '0'
                  : '0',
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoadingStads(false);
      });
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Responsabilidad, Municipio, Poligono, Seccion, Manzana, Celular, NombreCompleto } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filtro = [
        ...filtro,
        {
          id: 'cat_secciones.idRegion',
          filter: '=',
          value: Region,
        },
      ];
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: Municipio,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'cat_secciones.idMunicipioReportes',
          filter: '=',
          value: Municipio,
        },
      ];
    }
    if (Poligono.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];

      filtro = [
        ...filtro,
        {
          id: 'cat_secciones.idPoligono',
          filter: 'IN',
          value: Poligono,
        },
      ];
    }
    if (Seccion.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: 'IN', value: Seccion }];

      filtro = [
        ...filtro,
        {
          id: 'cat_secciones.Seccion',
          filter: 'IN',
          value: Seccion,
        },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'cat_secciones.Celular',
          filter: '=',
          value: Celular,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'cat_secciones.NombreCompleto',
          filter: 'LIKE',
          value: `%${NombreCompleto.toUpperCase().replace(/ /g, '%')}%`,
        },
      ];
    }
    if (Responsabilidad > 0) {
      filtered = [
        ...filtered,
        {
          id: 'cat_secciones.idPerfil',
          filter: '=',
          value: Responsabilidad,
        },
      ];

      /*    filtro = [
        ...filtro,
        {
          id: "cat_secciones.idPerfil",
          filter: "=",
          value: Responsabilidad,
        },
      ]; */
    }

    setTableData({
      ...tableData,
      page: 0,
      filtered: filtered,
    });

    setParamsResumen({
      ...tableData,
      page: 0,
      filtered: filtro,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'Avance'} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardContent>
                <BasicTable
                  rows={dataResumen}
                  hcolumns={columnsResumen}
                  pageProp={paramsResumen.page}
                  pageSize={paramsResumen.pageSize}
                  total={totalResumen}
                  handleChangePagination={handleChangePagination}
                  isLoading={loadingStads}
                  stickyHeader={true}
                  colorPar={'#cfe2f3'}
                  colorImpar={'#ffffff'}
                  colorHead={'rgba(61, 133, 198, 0.3)'}
                  showPagination={false}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <Filter
                  onChangeFilter={(e) => handleChangeFilter(e)}
                  typeResponsables={3}
                  vista={false}
                  responsable={true}
                  checkResponsabilidades={true}
                  manzana={false}
                  municipio_dl={true}
                  municipio={false}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <BasicTable
                  rows={data}
                  hcolumns={columns}
                  pageProp={tableData.page}
                  pageSize={tableData.pageSize}
                  total={total}
                  handleChangePagination={handleChangePagination}
                  isLoading={loading}
                  stickyHeader={true}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default AvanceResponsabilidades;
