import { WhatsApp } from '@mui/icons-material';
import { Box, Icon, Stack, Tooltip } from '@mui/material';

const ContactsStack = ({ whatsapp, nombre, telefono, telefono_mensaje }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      {whatsapp && (
        <Box
          component={'a'}
          href={`https://api.whatsapp.com/send?phone=52${whatsapp} &text=Hola ${nombre} `}
          target={'_blank'}
          color={'inherit'}
        >
          <Tooltip title={whatsapp}>
            <WhatsApp fontSize="small" />
          </Tooltip>
        </Box>
      )}
      {telefono && (
        <Tooltip title={telefono}>
          <Icon fontSize="small">smartphone</Icon>
        </Tooltip>
      )}
      {telefono_mensaje && (
        <Tooltip title={telefono_mensaje}>
          <Icon fontSize="small">message</Icon>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ContactsStack;
