import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Icon,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import Navbar from '../Navbar';
import Toast from '../Toast';

import moment from 'moment/moment';
import 'moment/locale/es';
import esLocale from 'date-fns/locale/es';
import { getVars } from '@utils/global';
import { useMobileServices } from '@hooks/MobileDiaD/MobileServices';

const ScreenInstalacionCasilla = ({ setTypeScreen }) => {
  const { loading, snackbar, setSnackbar, setInstalacionCasillas } = useMobileServices();

  const {
    ruta_rg: { Ruta, Descripcion, Casillas },
    user: { ambito_perfil },
  } = getVars('Token');
  const initialHours = {};
  Casillas.forEach((casilla) => {
    initialHours[casilla.idCasilla] = casilla.HoraInicioInstalacion;
  });

  const [hour, setHour] = useState(initialHours);
  const [openSchedule, setOpenSchedule] = useState(null);

  const handleHourChange = (idCasilla, newValue) => {
    setHour((prevValues) => ({
      ...prevValues,
      [idCasilla]: newValue,
    }));
  };

  const handleOpenSchedule = (idCasilla) => {
    setOpenSchedule(idCasilla);
  };

  const handleCloseSchedule = () => {
    setOpenSchedule(null);
  };

  const handleOkButtonClick = (idCasilla) => {
    setInstalacionCasillas({
      casillas: [
        {
          idCasilla,
          FechaHora: moment(hour[idCasilla]).format('HH:mm'),
        },
      ],
      idUser: ambito_perfil[0].idUsuario,
    });
  };

  return (
    <>
      <Toast open={snackbar.show} message={snackbar.message} setOpen={setSnackbar} />
      <Navbar
        isScreen
        screenBack={'rgs'}
        title={'Instalación de Casillas RG'}
        setTypeScreen={setTypeScreen}
        color="black"
      />

      <Container sx={{ mt: 4 }}>
        <Typography textAlign={'center'} fontWeight={600} marginBottom={2}>
          Inicio de instalación de casilla
        </Typography>

        <Stack direction={'column'}>
          <Box textAlign={'center'}>
            <Typography variant="subtitle1" fontWeight={600}>
              {Ruta}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Descripcion}
            </Typography>

            {loading && <LinearProgress sx={{ width: '100%' }} />}
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Box height={'80vh'} overflow={'auto'}>
            {Casillas &&
              Casillas.map((casilla) => (
                <Card key={casilla.idCasilla} className="card-secondary" sx={{ mb: 4 }}>
                  <CardContent>
                    <Stack
                      direction={'row'}
                      marginBottom={2}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box>
                        <Typography variant="subtitle1" fontWeight={600} sx={{ color: 'primary.main' }}>
                          {casilla.Seccion}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight={600} sx={{ color: 'primary.main' }}>
                          {casilla.NombreCasilla}
                        </Typography>
                      </Box>
                      {casilla.HoraInicioInstalacion && (
                        <Typography variant="caption">
                          Ult. Actualización: {moment(casilla.HoraInicioInstalacion).format('YYYY-MM-DD')}
                        </Typography>
                      )}
                    </Stack>

                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Button
                        fullWidth
                        size="small"
                        startIcon={<Icon>schedule</Icon>}
                        variant="contained"
                        onClick={() => handleOpenSchedule(casilla.idCasilla)}
                      >
                        Instalación
                      </Button>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={esLocale}
                        localeText={{
                          timePicker24Hour: '24 horas',
                          okButtonLabel: 'Aceptar',
                          cancelButtonLabel: 'Cancelar',
                        }}
                      >
                        <MobileTimePicker
                          label={'Pase de lista'}
                          value={hour[casilla.idCasilla]}
                          open={openSchedule === casilla.idCasilla}
                          onChange={(newValue) => handleHourChange(casilla.idCasilla, newValue)}
                          onAccept={() => handleOkButtonClick(casilla.idCasilla)}
                          onClose={() => handleCloseSchedule(casilla.idCasilla)}
                          renderInput={(params) => <> </>}
                          DialogProps={{
                            BackdropProps: {
                              sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                              },
                            },
                            PaperProps: {
                              sx: {
                                backgroundColor: 'white',
                                boxShadow: 'none',
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <TextField
                        fullWidth
                        variant="standard"
                        value={
                          hour[casilla.idCasilla]
                            ? moment(hour[casilla.idCasilla]).format('HH:mm')
                            : casilla.HoraInicioInstalacion
                              ? moment(casilla.HoraInicioInstalacion).format('YYYY-MM-DD HH:mm')
                              : ''
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              ))}
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default ScreenInstalacionCasilla;
