//Librerías
import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Box, Grid, Typography, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import Swal from 'sweetalert2';

//Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import ActionHeader from '@components/Containers/ActionHeader';

import UserState from '@components/Users/UserStatus';
import UsersActivationSearchh from '@components/Users/UsersActivationSearch';
import UsersActivationTablee from '@components/Users/UsersActivationTable';
import BasicListDoble from '@components/Lists/BasicListDoble';
import ListItemCustom from '@components/Users/ListItemCustom';

//Validaciones
import { isTypePhone } from '@utils/validations';

//Servicios
import SupportService from '@services/SupportServices';

const UserStatus = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [loadingForm, setLoadingForm] = useState({
    isLoading: false,
    isOpen: false,
    isSuccess: false,
    loadingMessage: 'Validando...',
    successMessage: '¡Consultado con éxito!',
  });

  //Sirve para ver en cual pestaña del tab empezar
  const [tabValue, setTabValue] = useState('1');

  const [id, setId] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState('');

  //Datos del filtro
  const [filtered, setFiltered] = useState([]);

  //Datos de tabla
  const [dataTable, setDataTable] = useState([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');

  useEffect(() => {
    if (tabValue === '2') {
      const params = {
        page: page,
        pageSize: pageSize,
        filtered: [],
        sorted: [],
      };
      fetchData(params);
    }
    // eslint-disable-next-line
  }, [tabValue]); // Solo ejecuta el useEffect cuando tabValue cambia

  const handleFilter = (action, params) => {
    const filter = [];
    if (action !== 'clear') {
      if (params.Nombre) {
        filter.push({
          id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'],
          filter: 'LIKE',
          value: params.Nombre,
        });
      }

      if (params.Celular) {
        filter.push({
          id: 'usuarios.Username',
          filter: '=',
          value: params.Celular,
        });
      }

      setFiltered(filter);

      const sendParams = {
        page: 0,
        pageSize: pageSize,
        filtered: filter,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };

      fetchData(sendParams);
    } else {
      setFiltered([]);

      const clearFiltered = {
        page: 0,
        pageSize: pageSize,
        filtered: [],
        sorted: [],
      };

      fetchData(clearFiltered);
    }
  };

  // Paginación remota de la tabla
  const handlePagination = ({ page, pageSize }) => {
    setPage(page);
    setPageSize(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: orderBy ? [{ id: orderBy, value: order }] : [],
    };

    fetchData(params);
  };

  // Ordenamiento remoto de la tabla
  const handleSort = (e) => {
    setOrderBy(e.orderBy);
    setOrder(e.order);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: [{ id: e.orderBy, value: e.order }],
    };

    fetchData(params);
  };

  const fetchData = async (params) => {
    setIsLoading(true);
    setLoadingForm({
      isLoading: true,
      isOpen: true,
      isSuccess: false,
      loadingMessage: 'Datos...',
      successMessage: 'Datos obtenidos con éxito!',
    });

    try {
      const result = await SupportService.getInactiveUser(params);
      const { results, message, response } = result;

      if (results) {
        setDataTable(response.data);
        setTotal(response.total);
      } else {
        Swal.fire({ title: message, icon: 'warning' });
        setDataTable([]);
        setTotal(0);
      }
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
      setLoadingForm({ ...loadingForm, isLoading: false, isOpen: false });
    }
  };

  //Cuando hay modificaciones en el tabvalue 1 y cambias de tab que se recargue el listado en el tabvalue 2
  useEffect(() => {
    if (tabValue === '1') {
      setId(0);
      setNombreCompleto('');
    }

    if (tabValue === '2') {
      const params = {
        page: page,
        pageSize: pageSize,
        filtered: filtered,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };
      fetchData(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  const handleClick = (e) => {
    setId(e.id);
    setNombreCompleto(e.NombreCompleto);
  };

  const handleClear = () => {
    setId(0);
    setNombreCompleto('');
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={loadingForm.loadingMessage}
        successMessage={loadingForm.successMessage}
        isLoading={loadingForm.isLoading}
        success={loadingForm.isSuccess}
        isOpen={loadingForm.isOpen}
        setIsOpen={() => {}}
      />
      <Container maxWidth="false">
        <ActionHeader title="Bloqueos y Activaciones" />
        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <TabContext value={tabValue}>
                <TabList onChange={(e, newVal) => setTabValue(newVal)} aria-label="user-status-tabs" centered>
                  <Tab label="Bloqueos y Activaciones" value="1" />
                  <Tab label="Pendientes por activar" value="2" />
                </TabList>
                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <BasicListDoble
                        id="id_nuevo"
                        API={SupportService.getUser}
                        APIKeys={[
                          {
                            id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
                            filter: 'LIKE',
                          },
                          {
                            id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                            filter: 'LIKE',
                          },
                        ]}
                        itemComponent={ListItemCustom}
                        header={''}
                        handleClick={handleClick}
                        handleClear={handleClear}
                        label={'Encuentra y elige al usuario para la acción a realizar'}
                        labelNote={
                          'Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular.'
                        }
                        finder={true}
                        inputValidation={{ input2: isTypePhone }}
                        initialSearch={false}
                        emptySearch={false}
                        doubleSearch={true}
                        breaks={{ sm: 12, md: 6 }}
                        buttonsBreaks={{ sm: 12, md: 4 }}
                        disableCardType={true}
                        config={{ height: 400 }}
                        clearData={true}
                      />
                    </Grid>

                    <Grid item xs={12} lg={5.5}>
                      <Grid
                        style={{
                          height: '50px',
                          textAlign: 'center',
                          marginBottom: '10px',
                          fontStyle: 'italic',
                          borderBottom: '2px solid #000',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            visibility: nombreCompleto ? 'visible' : 'hidden',
                          }}
                        >
                          {nombreCompleto}
                        </Typography>
                      </Grid>
                      <Card variant="outlined">
                        <CardContent>
                          <UserState idUsuario={id} API={SupportService.updateStatus} />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <UsersActivationSearchh handleFilter={handleFilter} />
                      <UsersActivationTablee
                        handleFilter={handleFilter}
                        dataTable={dataTable}
                        total={total}
                        page={page}
                        pageSize={pageSize}
                        handleSort={handleSort}
                        handlePagination={handlePagination}
                        isLoading={isLoading}
                        setLoadingForm={setLoadingForm} // Pasa la función setLoadingForm como prop
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserStatus;
