import React from 'react';
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Icon,
  Chip,
  Stack,
} from '@mui/material';
import { blue, yellow } from '@mui/material/colors';

const RoleListItem = ({ item, selectedIndex, handleSelectedIndex, handleDeleteItem }) => {
  const handleListItemClick = (event, index) => {
    let data = item;
    handleSelectedIndex(index, data);
  };

  return (
    <>
      <ListItem>
        <ListItemButton
          selected={selectedIndex === item.id}
          onClick={(event) => handleListItemClick(item, item.id)}
          style={{
            backgroundColor: selectedIndex === item.id ? blue[100] : 'transparent',
            '&:hover': {
              backgroundColor: blue[50],
            },
          }}
          disabled={item?.Responsabilidad ? true : false}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: yellow[900] }}>
              <Icon>assignment_ind</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary">
                  {item.NombreCompleto}
                  {'  ('}
                  {item.Edad}
                  {')'}
                </Typography>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.secundary"
                >
                  {`Sección: ${item.SeccionVota}`}
                </Typography>
                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.info">
                  {`Voluntario: ${item.actividad}`}
                </Typography>
              </Stack>
            }
            secondary={
              item.Responsabilidad ? (
                <Chip label={item.Responsabilidad} color="primary" variant="outlined" />
              ) : (
                ''
              )
            }
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default RoleListItem;
