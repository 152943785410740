import { useState } from 'react';

// Material UI
import { Grid, Stack, TextField, Button, InputAdornment } from '@mui/material';

// Componentes
import FilterCollapse from '@components/Filters/FilterCollapse';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Utils
import { isTypePhone } from '@utils/validations';

const PagosFilter = (props) => {
  const { handleFilter = () => {}, catalogs = {}, isLoadingCat = false, flags = {} } = props;

  const defaultData = {
    nombre: '',
    celular: '',
    perfil: '',
    servicio: '',
    descripcion: '',
    porcentaje: '',
    cat_municipio: 0,
    cat_servicio: 0,
    cat_estatus: 0,
    cat_tipo_pago: 0,
    cat_perfil: 0,
  };

  const clearData = Object.keys(defaultData).reduce((acc, key) => {
    if (flags[key]) acc[key] = defaultData[key];
    return acc;
  }, {});

  const [filter, setFilter] = useState(clearData);

  const newCatalogs = (catalog) => [
    { value: 0, label: 'TODOS' },
    ...(catalog && Array.isArray(catalog) && catalog.length ? catalog : []),
  ];

  const handleLocalFilter = (data = filter) => {
    const conditions = [
      { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE', value: data.nombre },
      { id: 'ca.Celular', filter: 'LIKE', value: data.celular },
      { id: 'cap.PerfilAportador', filter: 'LIKE', value: data.perfil },
      { id: 'cacs.NombreServicio', filter: 'LIKE', value: data.servicio },
      { id: 'cacs.Descripcion', filter: 'LIKE', value: data.descripcion },
      { id: 'cacs.PorcentajeCargo', filter: 'LIKE', value: data.porcentaje },
      { id: 'ca.idMunicipio', value: data.cat_municipio },
      { id: 'cacs.idServicio', value: data.cat_servicio },
      { id: 'caep.id', value: data.cat_estatus },
      { id: 'capa.idTipoPago', value: data.cat_tipo_pago },
      { id: 'cap.idPerfilAportante', value: data.cat_perfil },
    ];

    const filtered = conditions
      .filter(({ value }) => value || value === false)
      .map(({ id, filter = '=', value }) => ({ id, filter, value }));

    handleFilter(filtered);
  };

  const handleClear = () => {
    setFilter(clearData);
    handleLocalFilter(clearData);
  };

  const handleChange = (e) => {
    const value = e?.target?.value || e?.value;
    const name = e?.target?.name || e?.name;

    if (value && ['celular'].includes(name) && !isTypePhone(value)) return;

    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter);

    if (name.startsWith('cat')) handleLocalFilter(newFilter);
    else if (!value) handleLocalFilter(newFilter);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLocalFilter(filter);
    }
  };

  const isView = (value) => flags[value] || false;

  return (
    <FilterCollapse expand={false}>
      <Grid container spacing={2}>
        {isView('cat_municipio') && (
          <Grid item xs={12} sm={4} md={4}>
            <AdvancedSelect
              name="cat_municipio"
              label="Municipio"
              options={newCatalogs(catalogs.municipios)}
              isLoading={isLoadingCat}
              value={filter.cat_municipio}
              onChange={handleChange}
              fullWidth
              isSearchable
            />
          </Grid>
        )}

        {isView('nombre') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Nombre"
              name="nombre"
              size="small"
              placeholder="Escribe el nombre"
              value={filter.nombre || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        )}

        {isView('celular') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Celular"
              name="celular"
              size="small"
              placeholder="Escribe el celular"
              value={filter.celular || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        )}

        {isView('perfil') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Perfil"
              name="perfil"
              size="small"
              placeholder="Escribe el perfil"
              value={filter.perfil || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        )}

        {isView('servicio') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Servicio"
              name="servicio"
              size="small"
              placeholder="Escribe el servicio"
              value={filter.servicio || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        )}

        {isView('descripcion') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Descripción"
              name="descripcion"
              size="small"
              placeholder="Escribe la descripción"
              value={filter.descripcion || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        )}

        {isView('porcentaje') && (
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Porcentaje"
              name="porcentaje"
              size="small"
              placeholder="10.5"
              value={filter.porcentaje || ''}
              onChange={(e) => {
                const value = e.target.value;
                const name = e.target.name;

                let newValue = value.replace(/[^0-9.]/g, '');
                if (/^\d{3}$/.test(newValue)) newValue = newValue.slice(0, 2) + '.' + newValue.slice(2);

                if (newValue.endsWith('.')) newValue = newValue.slice(0, -1);

                const regex = /^(?:\d{0,2}(?:\.\d{0,2})?)?$/;
                if (regex.test(newValue)) handleChange({ name, value: newValue });
              }}
              fullWidth
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        )}

        {isView('cat_perfil') && (
          <Grid item xs={12} sm={4} md={4}>
            <AdvancedSelect
              id="cat_perfil"
              name="cat_perfil"
              label="Perfil"
              options={newCatalogs(catalogs.cat_aportantes_perfil)}
              isLoading={isLoadingCat}
              value={filter.cat_perfil}
              onChange={handleChange}
              fullWidth
              isSearchable
            />
          </Grid>
        )}

        {isView('cat_servicio') && (
          <Grid item xs={12} sm={4} md={4}>
            <AdvancedSelect
              id="cat_servicio"
              name="cat_servicio"
              label="Servicio"
              options={newCatalogs(catalogs.cat_aportantes_servicios)}
              isLoading={isLoadingCat}
              value={filter.cat_servicio}
              onChange={handleChange}
              fullWidth
              isSearchable
            />
          </Grid>
        )}

        {isView('cat_estatus') && (
          <Grid item xs={12} sm={4} md={4}>
            <AdvancedSelect
              id="cat_estatus"
              name="cat_estatus"
              label="Estatus"
              options={newCatalogs(catalogs.cat_aportantes_estatus_pago)}
              isLoading={isLoadingCat}
              value={filter.cat_estatus}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        )}

        {isView('cat_tipo_pago') && (
          <Grid item xs={12} sm={4} md={4}>
            <AdvancedSelect
              id="cat_tipo_pago"
              name="cat_tipo_pago"
              label="Tipo de pago"
              options={newCatalogs(catalogs.cat_aportantes_tipo_pago)}
              isLoading={isLoadingCat}
              value={filter.cat_tipo_pago}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
          Limpiar
        </Button>

        <Button variant="contained" color="primaryDark" size="small" onClick={() => handleLocalFilter()}>
          Filtrar
        </Button>
      </Stack>
    </FilterCollapse>
  );
};

export default PagosFilter;
