import { useEffect, useState } from 'react';

import { Card, CardContent, Typography } from '@mui/material';

import BasicLine from '@components/Charts/BasicLine';

import movilizacion from '@services/MovilizationServices';
import Swal from 'sweetalert2';

const ChartMobilization = ({ body, setLoading }) => {
  const [dataChart, setDataChart] = useState({
    categories: [],
    series: [],
    title: '',
  });

  const getMobilizedByScheduleChart = async () => {
    try {
      const res = await movilizacion.getMobilizedByScheduleChart(body);
      const { results, message, response } = res;
      if (results) {
        setDataChart({
          ...dataChart,
          categories: response.categories,
          series: response.series,
          title: response.title.toUpperCase(),
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        isLoadigForm: false,
        openLinearProgress: false,
      }));
    }
  };

  useEffect(() => {
    getMobilizedByScheduleChart(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
          {dataChart.title}
        </Typography>
        <BasicLine
          title={{
            primary: '',
          }}
          legend={{
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          }}
          series={dataChart.series}
          categories={dataChart.categories}
        />
      </CardContent>
    </Card>
  );
};

export default ChartMobilization;
