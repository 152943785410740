import { useEffect, useState } from 'react';

//MUI
import { Container, Box, Button, Icon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import Swal from 'sweetalert2';
import moment from 'moment';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomTabs from '@components/Tabs/CustomTabs';
import Download from '@components/Downloads/Download';
import CustomTable from '@components/Tables/CustomTable';

//Middlewares
import middleware from '@middlewares/middleware';

//Constantes
import { TOTALES_POR_CUADERNILLO, DETALLE_USUARIO_FECHA } from '@data/constants/Bingo';

//Servicios
import MobilizationService from '@services/MovilizationServices';

const TotalCuadernillos = (props) => {
  const { setIsLoadingForm, setOpenLinearProgress } = props;
  const [isDownload, setIsDownloading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [summary, setSummary] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filtered] = useState([]);

  useEffect(() => {
    if (filtered) {
      const params = {
        page,
        pageSize,
        filtered,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };
      fetchData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered]);

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const params = {
        filtered,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };

      const res = await MobilizationService.exportGralStat(params);
      const { results, message } = res;
      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => {
    setPage(page);
    setPageSize(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: orderBy ? [{ id: orderBy, value: order }] : [],
    };
    fetchData(params);
  };

  const handleSort = (e) => {
    setOrderBy(e.orderBy);
    setOrder(e.order);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: [{ id: e.orderBy, value: e.order }],
    };
    fetchData(params);
  };

  const fetchData = async (params) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsLoading(true);
    try {
      const res = await MobilizationService.getGralStat(params);
      const { results, message, response } = res;

      if (!results) {
        setDataTable([]);
        setTotal(0);
        setSummary([]);
        throw new Error(message);
      } else {
        let summaryValues = {};
        TOTALES_POR_CUADERNILLO.forEach((col) => {
          summaryValues[col.id] = response.totales[col.id];
        });

        const array_values = Object.values(summaryValues);
        setSummary(array_values);
        setDataTable(response.data);
        setTotal(response.total);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  return (
    <Box>
      {middleware.checkMenuAction('Exportar') && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primaryDark"
            startIcon={<Icon>download</Icon>}
            size="small"
            disabled={isDownload}
            onClick={handleExport}
          >
            Exportar
          </Button>
        </Box>
      )}

      {isDownload && (
        <Box marginBottom={2}>
          <Download format={'xlsx'} isDownload={isDownload} />
        </Box>
      )}

      <Grid2 container spacing={2} sx={{ mt: 0.5 }}>
        <Grid2 item xs={12} lg={12}>
          <CustomTable
            rows={dataTable}
            columns={TOTALES_POR_CUADERNILLO}
            total={total}
            page={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handlePagination={handlePagination}
            handleSort={handleSort}
            orderBy={orderBy}
            summary={summary}
            summarySettings={{ start: 0, title: 'Totales: ', align: 'center' }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

const DetalleUsuario = (props) => {
  const { setIsLoadingForm, setOpenLinearProgress } = props;
  const [isDownload, setIsDownloading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [summary, setSummary] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filtered] = useState([]);

  useEffect(() => {
    if (filtered) {
      const params = {
        page,
        pageSize,
        filtered,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };
      fetchData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered]);

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const params = {
        filtered,
        sorted: orderBy ? [{ id: orderBy, value: order }] : [],
      };

      const res = await MobilizationService.exportUserStat(params);
      const { results, message } = res;
      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => {
    setPage(page);
    setPageSize(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: orderBy ? [{ id: orderBy, value: order }] : [],
    };
    fetchData(params);
  };

  const handleSort = (e) => {
    setOrderBy(e.orderBy);
    setOrder(e.order);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered,
      sorted: [{ id: e.orderBy, value: e.order }],
    };
    fetchData(params);
  };

  const fetchData = async (params) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsLoading(true);
    try {
      const res = await MobilizationService.getUserStat(params);
      const { results, message, response } = res;

      if (!results) {
        setDataTable([]);
        setTotal(0);
        setSummary([]);
        throw new Error(message);
      } else {
        let summaryValues = {};
        DETALLE_USUARIO_FECHA.forEach((col) => {
          summaryValues[col.id] = response.totales[col.id];
        });

        const array_values = Object.values(summaryValues);
        setSummary(array_values);
        setDataTable(
          response.data.map((item) => ({
            ...item,
            Fecha: moment(item.Fecha, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          }))
        );
        setTotal(response.total);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  return (
    <Box>
      {middleware.checkMenuAction('Exportar') && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primaryDark"
            startIcon={<Icon>download</Icon>}
            size="small"
            disabled={isDownload}
            onClick={handleExport}
          >
            Exportar
          </Button>
        </Box>
      )}

      {isDownload && (
        <Box marginBottom={2}>
          <Download format={'xlsx'} isDownload={isDownload} />
        </Box>
      )}

      <Grid2 container spacing={2} sx={{ mt: 0.5 }}>
        <Grid2 item xs={12} lg={12}>
          <CustomTable
            rows={dataTable}
            columns={DETALLE_USUARIO_FECHA}
            total={total}
            page={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handlePagination={handlePagination}
            handleSort={handleSort}
            orderBy={orderBy}
            summary={summary}
            summarySettings={{ start: 0, title: 'Totales: ', align: 'center' }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

const BingosPostEleccion = () => {
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [tabValue, setTabValue] = useState('1');

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Bingos: Reporte Post Elección" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />

        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={12}>
            <CustomTabs
              value={tabValue}
              setValue={setTabValue}
              color={'primary'}
              tabs={[
                {
                  label: 'Totales por cuadernillo',
                  value: '1',
                  component: (
                    <TotalCuadernillos
                      setIsLoadingForm={setIsLoadingForm}
                      setOpenLinearProgress={setOpenLinearProgress}
                    />
                  ),
                },
                {
                  label: 'Detalle por usuario y fecha',
                  value: '2',
                  component: (
                    <DetalleUsuario
                      setIsLoadingForm={setIsLoadingForm}
                      setOpenLinearProgress={setOpenLinearProgress}
                    />
                  ),
                },
                // {
                //     label: "Totales por usuario",
                //     value: "3",
                // },
              ]}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default BingosPostEleccion;
