import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Banner from '@components/RecoveryPasswd/Banner';
import Form from '@components/RecoveryPasswd/Form';
import { RECAPTCHA_SITE_KEY } from '@utils/global';

const RecoveryPasswd = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Box>
        <Grid2
          container
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Grid2 xs={12} md={8} lg={8} sx={{ position: 'relative' }}>
            <Banner />
          </Grid2>
          <Grid2 xs={12} md={4} lg={4} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Form />
          </Grid2>
        </Grid2>
      </Box>
    </GoogleReCaptchaProvider>
  );
};

export default RecoveryPasswd;
