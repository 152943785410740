import React, { useState /*, useEffect*/ } from 'react';
import { QRCode } from 'react-qrcode-logo';
// import Swal from "sweetalert2";

// Material UI
import { Grid, Card, CardHeader, CardMedia, CardContent, Container, Icon } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import UserSecurity from '@components/Users/UserSecurity';
import UserFormBack from '@components/Users/UserFormBack';
import UserAccess from '@components/Users/UserAccess';
import EventsAttendance from '@components/Users/EventsAttendance';
import MenusListAll from '@components/Menus/MenusListAll';
import CustomTabs from '@components/Tabs/CustomTabs';

// Interfaces, Utilidades y Assets
// import UserServices from "@services/UserServices";
import { UserProfileInterface } from '@data/interfaces/UserInterfaces';
import { getVars, setVars } from '@utils/global';
import Logo from '@assets/img/SIAN.png';

// Hooks
import useWindowDimensions from '@hooks/useWindowDimensions';

const UserProfile = (props) => {
  const { height } = useWindowDimensions();
  const [refresh, setRefresh] = useState(true);
  const [tabValue, setTabValue] = useState('1');
  const [webMenus /*setWebMenus*/] = useState([]);
  const [appMenus /*setAppMenus*/] = useState([]);
  const [userInfo, setUserInfo] = useState(() => {
    let token = getVars('Token').user;
    let user = UserProfileInterface;
    Object.keys(token).forEach((key) => {
      user[key] = token[key] !== undefined ? token[key] : UserProfileInterface[key];
    });
    return user;
  });

  /*   useEffect(() => {
    let t = getVars("Token");
    UserServices.getMenus({ idUsuario: t.id }, t.access_token)
      .then((res) => {
        if (res.results) {
          setWebMenus(res.response.web);
          setAppMenus(res.response.app);
        } else throw new Error(res.message);
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  }, []); */

  const handleChangeUserInfo = (newValue) => {
    setRefresh(false);

    const token = getVars('Token');
    setVars('Token', {
      ...token,
      user: {
        ...token.user,
        Nombre: newValue.Nombre,
        Paterno: newValue.Paterno,
        Materno: newValue.Materno,
        Celular: newValue.Celular,
        Correo: newValue.Correo,
        idFotoPerfil: newValue.idFotoPerfil,
      },
    });

    setTimeout(() => {
      setRefresh(true);
    }, 500);

    setUserInfo(() => {
      let user = userInfo;
      Object.keys(newValue).forEach((key) => {
        if (user[key] !== undefined) user[key] = newValue[key];
      });
      return user;
    });
  };

  return (
    <AdminLayout refresh={refresh}>
      <Container id="container" maxWidth={false}>
        <Card className="card-primary">
          <CardHeader title="Mi perfil" subheader="Datos de usuario" avatar={<Icon>person</Icon>} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={5}>
                <Card className="card-secondary" sx={{ textAlign: 'center' }}>
                  <CardMedia sx={{ overflow: 'auto' }}>
                    <QRCode
                      value={userInfo.UUID}
                      logoImage={Logo}
                      size={200}
                      ecLevelc="L"
                      fgColor="#01579b"
                    />
                  </CardMedia>
                  <CardContent sx={{ px: { xs: 0, sm: 2 } }}>
                    <UserSecurity height={height * 0.6} data={userInfo} access="profile" />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={12} lg={7}>
                <CustomTabs
                  value={tabValue}
                  setValue={setTabValue}
                  tabs={[
                    {
                      value: '1',
                      icon: 'info',
                      label: 'Información',
                      component: (
                        <Card className="card-secondary">
                          <UserFormBack
                            action="edit"
                            data={userInfo}
                            handleChangeUserInfo={handleChangeUserInfo}
                            isAdmin={false}
                          />
                        </Card>
                      ),
                    },
                    {
                      value: '2',
                      icon: 'admin_panel_settings',
                      label: 'Menús',
                      display: false,
                      component: (
                        <Card className="card-secondary">
                          <MenusListAll webMenus={webMenus} appMenus={appMenus} type="list" />
                        </Card>
                      ),
                    },
                    {
                      value: '3',
                      icon: 'exit_to_app',
                      label: 'Accesos',
                      display: false,
                      component: <UserAccess data={userInfo} />,
                    },
                    {
                      value: '4',
                      icon: 'photo_camera',
                      label: 'Eventos',
                      component: <EventsAttendance />,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserProfile;
