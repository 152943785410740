import { useState, useEffect } from 'react';

// Material UI
import { Grid, Button, Stack } from '@mui/material';

// Componentes
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Services y Utils
import { getVars, setVars } from '@utils/global';

const Filter = ({ catalogs, loadingCatalogs, handleFilter }) => {
  const clearFilter = { idRegion: [], idMunicipio: [], idPoligono: [], idSeccion: [] };

  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catPoligonos, setCatPoligonos] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);
  const [filter, setFilter] = useState(clearFilter);

  useEffect(() => {
    const Re = catalogs?.regiones;
    const Mu = catalogs?.municipios;
    const Po = catalogs?.poligonos;
    const Se = catalogs?.secciones;

    const filter_saved = getVars('filterSavedMap', {
      idRegion: '',
      idMunicipio: '',
      idPoligono: '',
      idSeccion: '',
    });
    if (
      filter_saved.idRegion !== '' ||
      filter_saved.idMunicipio !== '' ||
      filter_saved.idPoligono !== '' ||
      filter_saved.idSeccion !== ''
    ) {
      setFilter(filter_saved);
      const catSavedMap = getVars('catSavedMap', {});
      setCatMunicipios(catSavedMap.municipios ?? []);
      setCatPoligonos(catSavedMap.poligonos ?? []);
      setCatSecciones(catSavedMap.secciones ?? []);
    } else if (Re?.length) {
      setCatMunicipios(Mu);
      setCatPoligonos(Po);
      setCatSecciones(Se);

      let filtered = clearFilter;

      if (Re?.length === 1) filtered.idRegion = [Re[0].value];
      if (Mu?.length === 1) filtered.idMunicipio = [Mu[0].value];
      if (Po?.length === 1) filtered.idPoligono = [Po[0].value];
      if (Se?.length === 1) filtered.idSeccion = [Se[0].value];

      setFilter(filtered);
      setVars('filterSavedMap', filtered);
      setVars('catSavedMap', { municipios: Mu, poligonos: Po, secciones: Se });
    }
    // eslint-disable-next-line
  }, [catalogs]);

  const handleChangeGlobal = (localValue, name) => {
    const clear = { idMunicipio: [], idPoligono: [], idSeccion: [] };

    if (localValue.includes(0) && localValue.length > 1) {
      if (localValue.indexOf(0) > 0) localValue = [0];
      else localValue = localValue.filter((value) => value !== 0);
    }

    const validCat = (ids, zero, local = localValue) => local.includes(ids) || zero === 0;

    const invalid = (array) => array.includes(0) || array.length === 0;

    switch (name) {
      case 'idRegion':
        const M_R = catalogs.municipios.filter((i) => validCat(i.idRegion, i.value));
        const P_R = catalogs.poligonos.filter((i) => validCat(i.idRegion, i.value));
        const S_R = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value));
        setCatMunicipios(M_R.length === 1 ? catalogs.municipios : M_R);
        setCatPoligonos(P_R.length === 1 ? catalogs.poligonos : P_R);
        setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        setFilter({ ...clear, [name]: localValue });
        break;

      case 'idMunicipio':
        if (invalid(localValue) && invalid(filter.idRegion)) {
          setCatPoligonos(catalogs.poligonos);
          setCatSecciones(catalogs.secciones);
        } else if (!invalid(localValue)) {
          const P_M = catalogs.poligonos.filter((i) => validCat(i.idMunicipioReportes, i.value));
          const S_M = catalogs.secciones.filter((i) => validCat(i.idMunicipioReportes, i.value));
          setCatPoligonos(P_M.length === 1 ? catalogs.poligonos : P_M);
          setCatSecciones(S_M.length === 1 ? catalogs.secciones : S_M);
        } else {
          const P_R = catalogs.poligonos.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          const S_R = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          setCatPoligonos(P_R.length === 1 ? catalogs.poligonos : P_R);
          setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        }

        setFilter({ ...filter, ...clear, [name]: localValue });
        break;

      case 'idPoligono':
        if (invalid(localValue) && invalid(filter.idRegion) && invalid(filter.idMunicipio)) {
          setCatSecciones(catalogs.secciones);
        } else if (!invalid(localValue)) {
          const S_P = catalogs.secciones.filter((i) => validCat(i.idPoligono, i.value));
          setCatSecciones(S_P.length === 1 ? catalogs.secciones : S_P);
        } else if (!invalid(filter.idMunicipio)) {
          const S_R = catalogs.secciones.filter((i) =>
            validCat(i.idMunicipioReportes, i.value, filter.idMunicipio)
          );
          setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        } else {
          const S_M = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          setCatSecciones(S_M.length === 1 ? catalogs.secciones : S_M);
        }

        setFilter({ ...filter, [name]: localValue, idSeccion: [] });
        break;

      default: // idSeccion
        setFilter({ ...filter, [name]: localValue });
        break;
    }
  };

  const valid = (value, noValid, noValidArray) => {
    if (noValid !== undefined && noValidArray !== undefined && Array.isArray(value))
      return value !== '' && value !== undefined && value.length !== noValid && !value.includes(noValidArray);
    if (noValid !== undefined && Array.isArray(value))
      return value !== '' && value !== undefined && value.length !== noValid;
    if (noValid !== undefined) return value !== '' && value !== undefined && value !== noValid;
    return value !== '' && value !== undefined;
  };

  const handleClick = (params, cMun, cPo, cSe) => {
    let filter_data = [];
    let shp = 'entidad';

    const municipios = cMun ?? catMunicipios;
    const poligonos = cPo ?? catPoligonos;
    const secciones = cSe ?? catSecciones;

    const { idSeccion, idPoligono, idMunicipio, idRegion } = params;

    const same = { id: 'tb.id', filter: 'IN' };

    if (valid(idSeccion, 0)) {
      if (!idSeccion.includes(0)) filter_data.push({ ...same, id: 'tb.Seccion', value: idSeccion });

      // Trae todos las secciones de esa región, municipio o poligono
      if (
        idSeccion.includes(0) &&
        (valid(idPoligono, 0, 0) || valid(idMunicipio, 0, 0) || valid(idRegion, 0, 0))
      ) {
        const ids = secciones.map((item) => item.value).filter((value) => value !== 0);
        filter_data.push({ ...same, id: 'tb.Seccion', value: ids });
      }

      shp = 'seccion';
    } else if (valid(idPoligono, 0)) {
      if (!idPoligono.includes(0)) filter_data.push({ ...same, value: idPoligono });

      // Trae todos los poligonos de esa región o municipio
      if (idPoligono.includes(0) && (valid(idMunicipio, 0, 0) || valid(idRegion, 0, 0))) {
        const ids = poligonos.map((item) => item.value).filter((value) => value !== 0);
        filter_data.push({ ...same, value: ids });
      }

      shp = 'poligono';
    } else if (valid(idMunicipio, 0)) {
      if (!idMunicipio.includes(0)) filter_data.push({ ...same, value: idMunicipio });

      // Trae todos los municipios de esa región
      if (idMunicipio.includes(0) && valid(idRegion, 0, 0)) {
        const ids = municipios.map((item) => item.value).filter((value) => value !== 0);
        filter_data.push({ ...same, value: ids });
      }

      shp = 'municipio';
    } else if (valid(idRegion, 0)) {
      if (!idRegion.includes(0)) filter_data.push({ ...same, value: idRegion });
      shp = 'region';
    }

    setVars('catSavedMap', { municipios, poligonos, secciones });
    setVars('filterSavedMap', { idRegion, idMunicipio, idPoligono, idSeccion });
    handleFilter(filter_data, shp);
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <AdvancedSelect
            label="Región"
            name="idRegion"
            id="idRegion"
            options={catalogs?.regiones}
            value={filter.idRegion}
            onChange={(e) => handleChangeGlobal(e, 'idRegion')}
            disabled={catalogs?.regiones?.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            disableCloseOnSelect
            isSearchable
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <AdvancedSelect
            label="Muncipio"
            name="idMunicipio"
            id="idMunicipio"
            options={catMunicipios}
            value={filter.idMunicipio}
            onChange={(e) => handleChangeGlobal(e, 'idMunicipio')}
            disabled={catMunicipios.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            disableCloseOnSelect
            isSearchable
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <AdvancedSelect
            label="Poligono"
            name="idPoligono"
            id="idPoligono"
            options={catPoligonos}
            value={filter.idPoligono}
            onChange={(e) => handleChangeGlobal(e, 'idPoligono')}
            disabled={catPoligonos.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            disableCloseOnSelect
            isSearchable
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <AdvancedSelect
            label="Sección"
            name="idSeccion"
            id="idSeccion"
            options={catSecciones}
            value={filter.idSeccion}
            onChange={(e) => handleChangeGlobal(e, 'idSeccion')}
            disabled={catSecciones.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            disableCloseOnSelect
            isSearchable
            multiple
          />
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: '20px', alignSelf: 'center' }}
          variant="outlined"
          disabled={
            filter.idRegion === '' &&
            filter.idMunicipio === '' &&
            filter.idPoligono === '' &&
            filter.idSeccion === ''
          }
          onClick={() => handleClick(filter)}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default Filter;
