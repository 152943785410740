import { useEffect, useState } from 'react';

//MUI
import { Container, Box } from '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BingoFolioFilter from '@components/Activation/Bingo/BingoFolioFilter';
import BingoFolioTable from '@components/Activation/Bingo/BingoFolioTable';

//SERVICES

const BingoFolio = () => {
  const [filtered, setFiltered] = useState(null);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [casillasOptions, setCasillasOptions] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClear = () => {
    setCasillasOptions([]);
    setClean((prevState) => !prevState);
    sessionStorage.removeItem('filter');
  };

  useEffect(() => {
    if (filtered) {
      if (filtered.idCasilla) {
        setSelectedOption(casillasOptions.find((item) => item.value === filtered.idCasilla));
      }
    }
    //eslint-disable-next-line
  }, [filtered]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Bingo: Asignación de folio bingo" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />
        <Box sx={{ mt: 1, mb: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12} md={3} lg={3}>
              <BingoFolioFilter
                onChange={setFiltered}
                setPassCasilla={setCasillasOptions}
                onClear={handleClear}
              />
            </Grid2>
            <Grid2 xs={12} sm={12} md={9} lg={9}>
              <BingoFolioTable
                selected={selectedOption}
                filtered={filtered}
                onClean={clean}
                setLoading={(e) => {
                  setIsLoadingForm(e);
                  setOpenLinearProgress(e);
                }}
              />
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default BingoFolio;
