import { useState, useEffect } from 'react';

//MUI
import { Card, CircularProgress, CardContent, Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import Swal from 'sweetalert2';
import moment from 'moment/moment';

//COMPONENTS
import PlacementColumns from '@components/Charts/DashChart';

import MovilizacionService from '@services/MovilizationServices';

const ProgressChart = (props) => {
  const { openDrawer, setLoading, filtered, agrupador } = props;
  const [loadingChart, setLoadingChart] = useState(false);
  const [data, setData] = useState({
    title: '',
    date: '',
    data: [],
    categories: [],
  });

  const ApiResponse = async (value) => {
    try {
      setLoading(true);
      setLoadingChart(true);
      let values = {
        idAmbitoAgrupador: agrupador,
        filtered: value.filtered,
      };
      const result = await MovilizacionService.getProgressGraph(values);
      const { results, message, response } = result;
      if (results) {
        setData({
          title: response.title,
          date: response.date,
          categories: response.categories,
          data: response.series,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (filtered.ambitoAgrupador !== '' && filtered.ambitoAgrupador !== 0) {
      ApiResponse(filtered);
    }
  }, [filtered, agrupador]);

  return (
    <Grid2 sx={{ width: '100%' }} key={openDrawer}>
      {loadingChart ? (
        <Grid2
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}
        >
          <CircularProgress />
        </Grid2>
      ) : (
        <Box key={openDrawer}>
          <PlacementColumns
            titulo={{ title: data.title }}
            subtitle={{
              subtitle: moment(data.date).format('DD/MM/YYYY H:mm'),
            }}
            datos={{
              categories: data.categories || [],
              series: data.data || [],
            }}
            loading={loadingChart}
          />
        </Box>
      )}
    </Grid2>
  );
};
export default ProgressChart;
