import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Material UI
import {
  Box,
  Grid,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Stack,
  Chip,
  Icon,
  TableHead,
  TableSortLabel,
  LinearProgress,
  Divider,
  Button,
  ListItemText,
  useMediaQuery,
  useTheme,
  TableFooter,
} from '@mui/material';

// Componentes
import FilterCollapse from '@components/Filters/FilterCollapse';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Servicios Middlewares y Utils
import electoral from '@services/ElectoralServices';
import CatalogServices from '@services/CatalogServices';
import middleware from '@middlewares/middleware';
import { isTypePhone } from '@utils/validations';
import { isNil, orderBy, set } from 'lodash';
import { isNull } from 'lodash';
import { numberFormat } from 'highcharts';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const TableRowComponent = ({ data, index, selected, handleUpdate, handleConfirm, handleEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <LoadingForm
        loadinMessage={'Editando estatus...'}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell>{data.Celular}</TableCell>
        <TableCell>{data.Telefono}</TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data.Nombre}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{data.TieneUsuario}</TableCell>
        {middleware.checkMenuAction('Agregar') && (
          <TableCell sx={{ width: '30%', textAlign: 'center' }}>
            {data.idEstatus !== 2 ? (
              <Button
                variant="outlined"
                startIcon={<Icon>task_alt</Icon>}
                onClick={() => handleConfirm(data, index, 2)}
                color="success"
              >
                Asistencia
              </Button>
            ) : (
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                {middleware.checkMenuAction('Eliminar') ? (
                  <Chip
                    sx={{ bgcolor: '#D9F8BB' }}
                    label={data.Estatus}
                    onDelete={() => handleConfirm(data, index, 1)}
                  />
                ) : (
                  <Chip sx={{ bgcolor: '#D9F8BB' }} label={data.Estatus} />
                )}
                {middleware.checkMenuAction('Editar') && (
                  <Button
                    variant="outlined"
                    startIcon={<Icon>edit</Icon>}
                    size="small"
                    onClick={() => handleEdit(data)}
                  >
                    Editar Celular
                  </Button>
                )}
              </Stack>
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

const TableHeadComponent = ({
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) => {
  const onSort = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ color: 'aqua' }}>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={headCell.orderBy ? headCell.orderBy : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              hideSortIcon
              active={headCell.orderBy}
              direction={
                orderBy.find((item) => item.id === headCell.id)
                  ? orderBy.find((item) => item.id === headCell.id).value
                  : 'asc'
              }
              onClick={onSort(headCell.orderBy)}
            >
              {headCell.label}
              {orderBy === headCell.orderBy ? (
                <Box sx={{ ...visuallyHidden }}>
                  {orderBy.find((item) => item.id === headCell.id).value === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableScroll = (props) => {
  const { config = { height: 588 }, onFilter, refresh = () => {} } = props;

  const colums = [
    { id: 'Celular', label: 'Celular', orderBy: 'compromisos_unicos.Celular' },
    {
      id: 'Telefono',
      label: 'Teléfono',
      orderBy: 'compromisos_unicos.Telefono',
    },
    { id: 'Nombre', label: 'Nombre', orderBy: 'compromisos_unicos.Nombre' },
    { id: 'TieneUsuario', label: 'Tiene Cuenta', orderBy: 'usuarios.id' },
    { id: '', label: 'Acción' },
  ];

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [pageAnt, setPageAnt] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [orderBy, setOrderBy] = useState([]);

  const [selected, setSelected] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (params.filtered.length > 0) {
      getList();
    } else {
      setData([]);
    }
    // setHasMore(true);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    setParams({
      ...params,
      sorted: orderBy,
    });
    // setHasMore(true);
    // eslint-disable-next-line
  }, [orderBy]);

  const getList = async () => {
    if (loading) return true;
    setLoading(true);

    let filtros = { ...params, pageSize: params.pageSize * pageAnt };

    try {
      const result = await electoral.getListCompromisos(filtros);
      const { results, response, message } = result;

      if (results) {
        const formattedData = response.data.map((item) => ({
          ...item,
          TieneUsuario: isNull(item.idUsuario) ? 'NO' : 'SI',
          NombreT: item.Nombre,
          CelularAnterior: item.Celular,
          Celular: item.Celular ? (
            <a
              href={`https://api.whatsapp.com/send?phone=52${item.Celular}&text=Hola ${item.Nombre} te invito a que juntos vayamos a ganar con fuerza y corazón por gto`}
              target="_blank"
            >
              {<Chip color="success" icon={<WhatsAppIcon />} label={item.Celular} variant="outlined" />}
            </a>
          ) : (
            'SIN CELULAR'
          ),
          Telefono: item.Telefono,
          Nombre: <ListItemText primary={item.Nombre} /* secondary={item.Domicilio}  */ />,
        }));
        setData(filtros.page === 0 ? formattedData : data.concat(formattedData));
        setTotal(response.total);
        const end = (params.page + 1) * params.pageSize;
        if (end >= response.total) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }

        setPageAnt(1);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = (item, index, idEstatus) => {
    const action = idEstatus === 2 ? 'CAPACITADO' : idEstatus === 1 ? 'SIN CAPACITAR' : 'NO LOCALIZADO';
    Swal.fire({
      title: `¿ESTÁS SEGUR@ DE INDICAR COMO "${action}" A ${item.NombreT}?`,
      text: 'Esta acción no se podrá revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Sí, ${action} `,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) handleEdit(item, index, idEstatus);
    });
  };

  const handleEdit = async (item, index, idEstatus) => {
    const sendParams = {
      idEstatus,
      idCompromisoUnico: item.idCompromisoUnico,
      idUsuario: item.idUsuario,
      idPerfil: item.idPerfil,
    };
    try {
      setLoading(true);
      const result = await electoral.updatePaseEstatus(sendParams);
      const { results, message } = result;
      if (results) {
        setPageAnt(params.page > 1 ? params.page : 1);
        setData([]);
        setParams({
          ...params,
          page: 0,
        });
        refresh(params.page);
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    let find = orderBy.find((item) => item.id === property);
    if (find) {
      let filter = orderBy.filter((item) => item.id !== property);

      setOrderBy([
        ...filter,
        {
          id: property,
          value: find.value === 'asc' ? 'desc' : 'asc',
        },
      ]);
    } else {
      setOrderBy([
        ...orderBy,
        {
          id: property,
          value: 'asc',
        },
      ]);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleFilter = (filtros) => {
    setData([]);
    setParams({ ...params, page: 0, filtered: filtros });
    onFilter(filtros);
  };

  const hadleEditCelular = async (item) => {
    Swal.fire({
      title: 'Cambio de celular',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Editar',
      showLoaderOnConfirm: true,
      preConfirm: async (celular) => {
        try {
          let sendParams = {
            idCompromisoUnico: item.idCompromisoUnico,
            CelularAnterior: item.CelularAnterior,
            CelularNuevo: celular,
          };

          const response = await electoral.updatePaseCelular({ ...sendParams });
          const { results, message } = response;

          if (!results) {
            return Swal.showValidationMessage(`
              ${message}
            `);
          }
          return response;
        } catch (error) {
          Swal.showValidationMessage(`
             ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: `${result.value.message}`,
        });
      }
    });
  };

  return (
    <>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Filter handleFilter={handleFilter} />
        <Divider />
        <br />
        <InfiniteScroll
          scrollableTarget={'scrollableDiv'}
          next={() => setParams({ ...params, page: params.page + 1 })}
          hasMore={hasMore}
          scrollThreshold={0.7}
          dataLength={data.length}
          loader={loading ? <LinearProgress /> : <></>}
        >
          {isMobile ? (
            <TableContainer sx={{ maxHeight: config.height }} id="scrollableDiv">
              <Grid container spacing={2}>
                {data.length > 0 ? (
                  data.map((row, index) => (
                    <Grid item xs={12} key={index}>
                      <Box p={2} border={1} borderRadius={2}>
                        <Typography variant="h6">{row.Nombre}</Typography>
                        <Typography variant="body2">Celular: {row.Celular}</Typography>
                        <Typography variant="body2">Teléfono: {row.Telefono}</Typography>
                        <Typography variant="body2">Tiene cuenta: {row.TieneUsuario}</Typography>
                        {middleware.checkMenuAction('Agregar') && (
                          <Box mt={2}>
                            {row.idEstatus !== 2 ? (
                              <Button
                                variant="outlined"
                                startIcon={<Icon>task_alt</Icon>}
                                onClick={() => handleConfirm(row, index, 2)}
                                color="success"
                                fullWidth
                              >
                                Asistencia
                              </Button>
                            ) : (
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                {middleware.checkMenuAction('Eliminar') ? (
                                  <Chip
                                    sx={{ bgcolor: '#D9F8BB' }}
                                    label={row.Estatus}
                                    onDelete={() => handleConfirm(row, index, 1)}
                                  />
                                ) : (
                                  <Chip sx={{ bgcolor: '#D9F8BB' }} label={row.Estatus} />
                                )}
                                {middleware.checkMenuAction('Editar') && (
                                  <Button
                                    variant="outlined"
                                    startIcon={<Icon>edit</Icon>}
                                    size="small"
                                    onClick={() => hadleEditCelular(row)}
                                  >
                                    Editar Celular
                                  </Button>
                                )}
                              </Stack>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} key={'error'}>
                    <Box p={2} border={1} borderRadius={2} textAlign="center" boxShadow={3}>
                      <Icon color="error" style={{ fontSize: 48 }}>
                        search_off
                      </Icon>
                      <Typography variant="h6" color="textSecondary">
                        Coloque un filtro para encontrar a la persona. {/*  No hay registros disponibles */}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </TableContainer>
          ) : (
            <TableContainer sx={{ maxHeight: config.height }} id="scrollableDiv">
              <Table stickyHeader>
                <TableHeadComponent
                  orderBy={orderBy}
                  rowCount={data.length}
                  headLabel={colums}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.length > 0 ? (
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.NombreT);
                      return (
                        <TableRowComponent
                          key={index}
                          data={row}
                          index={index}
                          selected={isItemSelected}
                          handleUpdate={(index, idEstatus) => {
                            const newData = [...data];
                            newData[index].idEstatusContactoMovilizacion = idEstatus;
                            setData(newData);
                          }}
                          handleConfirm={handleConfirm}
                          handleEdit={hadleEditCelular}
                        />
                      );
                    })
                  ) : (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell colSpan={colums.length} style={{ padding: 0 }}>
                        {
                          /*   params.filtered === 0 ? */
                          <Box
                            p={2}
                            border={1}
                            borderRadius={2}
                            textAlign="center"
                            justifyContent={'center'}
                            boxShadow={3}
                            width="100%"
                          >
                            <Icon color="error" style={{ fontSize: 48 }}>
                              search_off
                            </Icon>
                            <Typography variant="h6" color="textSecondary">
                              Coloque un filtro para encontrar a la persona.
                            </Typography>
                          </Box> /* :
                        <Box
                        p={2}
                        border={1}
                        borderRadius={2}
                        textAlign="center"
                        justifyContent={"center"}
                        boxShadow={3}
                        width="100%"
                      >
                        <Icon color="error" style={{ fontSize: 48 }}>
                          person_off
                        </Icon>
                        <Typography variant="h6" color="textSecondary">
                         No se encontro registros.
                        </Typography>
                      </Box> */
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </InfiniteScroll>
      </Box>
      {/*    <Box sx={{ textAlign:"end" }}>
          <Divider/>
        <Typography variant="body1">Mostrando</Typography><Typography>{numberFormat(data.length)} de {numberFormat(total)}</Typography>
        </Box> */}
    </>
  );
};

const Filter = ({ handleFilter }) => {
  const [filter, setFilter] = useState({
    nombre: '',
    celular: '',
    paterno: '',
    materno: '',
    INE: '',
    idMunicipio: 0,
  });

  const [catalogs, setCatalogs] = useState({});
  const [catSeccion, setCatSeccion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCatalogs();
  }, []);

  const getCatalogs = async () => {
    try {
      setIsLoading(true);

      const params = [{ id: 'municipios_reportes', getAll: true }];

      const result = await CatalogServices.getCatalogs(params);
      const { results, response, message } = result;

      if (results) {
        const { municipios_reportes } = response.catalogs;
        const todos = { value: 0, label: 'TODOS' };
        const todas = { value: 0, label: 'TODAS' };

        const newCat = {
          municipios_reportes: [todos, ...municipios_reportes],
        };

        setCatalogs(newCat);
        setCatSeccion(newCat.secciones);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeGlobal = (localValue, name) => {
    let clear = {};

    switch (name) {
      case 'idMunicipio':
        break;

      default:
        break;
    }

    setFilter({ ...filter, ...clear, [name]: localValue });
  };

  const onFilter = () => {
    const { idMunicipio, INE, celular, nombre, materno, paterno } = filter;

    let filtro = [];
    console.log(idMunicipio);
    if (idMunicipio > 0) {
      filtro.push({
        id: 'cat_municipios_reportes.id',
        filter: '=',
        value: idMunicipio,
      });
    }

    if (INE.length > 0) {
      filtro.push({
        id: 'usuarios.INE',
        filter: '=',
        value: `${INE}`,
      });
    }

    if (celular.length > 0) {
      filtro.push({
        id: 'usuarios.Celular',
        filter: '=',
        value: `${celular}`,
      });
    }
    if (nombre.length > 0) {
      filtro.push({
        id: 'usuarios.Nombre',
        filter: 'LIKE',
        value: `%${nombre.toUpperCase().replace(/ /g, '%')}%`,
      });
    }
    if (paterno.length > 0) {
      filtro.push({
        id: 'usuarios.Paterno',
        filter: '=',
        value: `${paterno.toUpperCase()}`,
      });
    }
    if (materno.length > 0) {
      filtro.push({
        id: 'usuarios.Materno',
        filter: '=',
        value: `${materno.toUpperCase()}`,
      });
    }

    handleFilter(filtro);
  };

  const onClear = () => {
    setFilter({
      nombre: '',
      celular: '',
      idMunicipio: 0,
      idSeccion: 0,
      idEstatus: 0,
    });

    handleFilter([]);
  };

  return (
    <FilterCollapse expand={true}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <AdvancedSelect
            label="Municipio"
            name="idMunicipio"
            id="idMunicipio"
            options={catalogs.municipios_reportes}
            value={filter.idMunicipio}
            onChange={(e) => handleChangeGlobal(e.value, e.name)}
            isLoading={isLoading}
            isSearchable
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Clave Elector"
            name="INE"
            value={filter.INE}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Paterno"
            name="paterno"
            value={filter.paterno}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Materno"
            name="materno"
            value={filter.materno}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Nombre"
            name="nombre"
            value={filter.nombre}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Celular"
            name="celular"
            value={filter.celular}
            onChange={(e) => {
              if (isTypePhone(e.target.value)) handleChangeGlobal(e.target.value, e.target.name);
            }}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            sx={{ paddingX: { xs: 5, sm: 'auto' } }}
            onClick={onClear}
            disabled={isLoading}
            size="small"
            color="primaryDark"
          >
            Limpiar
          </Button>
          <Button
            onClick={onFilter}
            variant="contained"
            sx={{ paddingX: { xs: 5, sm: 'auto' } }}
            disabled={isLoading}
            size="small"
            color="primaryDark"
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>
    </FilterCollapse>
  );
};

export default TableScroll;
