import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
//Mui
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import BasicTableCustomizing from '@components/Tables/BasicTableCustomizing';
/* import MessageAlert from "@components/Downloads/MessageAlert"; */
/* import Download from "@components/Downloads/Download"; */

import result from '@services/ResultServices';
import { isEmptyOrNullObject, isNullOrUndefined } from '@utils/validations';
import { numberWithCommas } from '@utils/Utilities';
/* import middleware from "@middlewares/middleware"; */
import { AMBITO_CASILLA } from '@data/constants';
import {
  ELECCION_DIPUTADO_FEDERAL,
  ELECCION_DIPUTADO_LOCAL,
  ELECCION_PRESIDENTE_MUNICIPAL,
} from '@data/constants/Prep';

const TableResultados = (props) => {
  const { title, params, setLoadingFilter, setData } = props;
  const [loadingTable, setLoadingTable] = useState(true);
  const [paramsTable, setParamsTable] = useState({
    page: 0,
    pageSize: 999999999,
    filtered: [],
  });
  const [dataTable, setDataTable] = useState({
    columns: [],
    data: [],
    total: 0,
  });
  const [sortedData, setSortedData] = useState([]);
  /*  const [isDownloading, setIsDownloading] = useState(false); */
  /*    const [showMessage, setShowMessage] = useState(false); */
  /*    const [successDownload, setSuccessDownload] = useState(false); */
  /*    const [showAlert, setShowAlert] = useState(false); */

  useEffect(() => {
    if (params) {
      setParamsTable((prevState) => ({
        ...prevState,
        sorted: [],
        page: 0,
        filtered: params?.filtered || [],
      }));
      // setDataTable((prevState) => ({ ...prevState, total: 0 }))
    }
    //eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (params && paramsTable.sorted) {
      const bodyParams = {
        ...paramsTable,
        ...params,
      };

      getTable(bodyParams);

      if (
        [ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL, ELECCION_PRESIDENTE_MUNICIPAL].includes(
          bodyParams.idTipoEleccion
        ) &&
        bodyParams.idAmbitoAgrupador === AMBITO_CASILLA &&
        bodyParams.ValorAmbito === 0 &&
        bodyParams.idTipoVista === 1
      ) {
        /*    setShowAlert(true); */
      } else {
        /* setShowAlert(false); */
      }
    }
    //eslint-disable-next-line
  }, [paramsTable]);

  useEffect(() => {
    if (sortedData.length > 0) {
      setParamsTable((prevState) => ({ ...prevState, sorted: sortedData }));
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangePagination = (pagination) => {
    setParamsTable({ ...paramsTable, ...pagination });
  };

  const handleRequestSort = (sortData) => {
    if (dataTable.total > 0) setSortedData(handleSorted(sortData));
  };

  const getTable = (params) => {
    setLoadingFilter(true);
    setLoadingTable(true);
    result
      .getTable({
        ...params,
        ...paramsTable,
      })
      .then((res) => {
        const { results, response } = res;
        if (results) {
          setDataTable({
            columns: response.columns,
            data: dataFormatted(response.data),
            total: response.total,
          });
          setData(response.ranking);
        } else {
          setDataTable({
            columns: [],
            data: [],
            total: 0,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
        setDataTable({
          columns: [],
          data: [],
          total: 0,
        });
      })
      .finally(() => {
        setLoadingTable(false);
        setLoadingFilter(false);
      });
  };

  const handleShowActa = (item) => {
    const url = item.RutaFotoActa;
    window.open(url, '_blank');
  };

  const dataFormatted = (data) => {
    return data.map((item) => {
      if (!isNullOrUndefined(item.RutaFotoActa)) {
        item.Foto = (
          <Button
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            color="primaryDark"
            onClick={() => handleShowActa(item)}
          >
            <CameraAlt />
          </Button>
        );
      } else {
        item.Foto = '';
      }

      let newItem = {};
      Object.keys(item).forEach((k) => {
        if (typeof item[k] === 'number') {
          newItem[k] = numberWithCommas(item[k]);
        } else {
          newItem[k] = item[k];
        }
      });

      return newItem;
    });
  };

  const handleSorted = (dataSorted) => {
    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      switch (dataSorted.orderBy) {
        default:
          sorted.push({
            id: dataSorted.orderBy,
            value: dataSorted.order,
          });
          break;
      }
    }
    return sorted;
  };

  /*  const handleDownload = () => {
     let bodyParams = {
         ...params,
         filtered: paramsTable.filtered ? paramsTable.filtered : [],
         sorted: paramsTable.sorted ? paramsTable.sorted : []
      }

      setIsDownloading(true);
      prep.exportTable(bodyParams).then(res => {
         if (res.success) {
            setShowMessage(true);
            setSuccessDownload(true);
         } else {
            setShowMessage(true);
            setSuccessDownload(false);
         }
      }).catch(e => {
         setShowMessage(true);
         setSuccessDownload(false);
      }).finally(() => setIsDownloading(false)) 
   }*/

  return (
    <Box component={Card} className="card-primary">
      <Box component={CardContent}>
        <Grid2 container spacing={2} m={1}>
          <Grid2 xs={12} sm={12} md={4} lg={4} justifyContent={'start'}>
            <Typography variant="body2" fontWeight={700} fontSize={16} marginBottom={2}>
              {title}
            </Typography>
          </Grid2>
          {/*      <Grid2 xs={12} sm={12} md={4} lg={4} display={"flex"} justifyContent={"flex-end"}>
                  {!isDownloading && middleware.checkMenuAction("Exportar") && !showAlert && (
                     <Tooltip title='Click para exportar'>
                        <Button
                           variant="outlined"
                           color="primaryDark"
                           size="small"
                           startIcon={<Icon>download</Icon>}
                           onClick={handleDownload}
                           sx={{ borderRadius: "10px" }}
                        >
                           Exportar
                        </Button>
                     </Tooltip>
                  )}
               </Grid2> */}
        </Grid2>
        {/*     {isDownloading && (
               <Grid2 container spacing={2} m={1}>
                  <Grid2 xs={12}>
                     <Download isDownload={isDownloading} format="xlsx" />
                  </Grid2>
               </Grid2>
            )} */}

        {/*      {showMessage && (
               <Box marginTop={2}>
                  <MessageAlert
                     showMessage={showMessage}
                     successDownload={successDownload}
                     setShowMessage={setShowMessage}
                  />
               </Box>
            )} */}

        <BasicTableCustomizing
          rows={dataTable.data}
          hcolumns={dataTable.columns}
          pageProp={paramsTable.page}
          pageSize={paramsTable.pageSize}
          total={dataTable.total}
          handleChangePagination={handleChangePagination}
          isLoading={loadingTable}
          stickyHeader={true}
          handleManualSort={handleRequestSort}
        />
      </Box>
    </Box>
  );
};

export default TableResultados;
