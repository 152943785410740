import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import MovilizacionService from '@services/MovilizationServices';
import CustomTable from '@components/Tables/CustomTable';
import { numberWithCommas } from '@utils/Utilities';
import { setDiynamicColumns } from '@utils/Utilities';

const ChartDetailTable = (props) => {
  const { setLoading, filtered, agrupador } = props;
  const [loadingTable, setLoadingTable] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [summary, setSummary] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [order, setOrder] = useState('');
  const [params, setParams] = useState({
    data: [],
    totales: [],
  });

  const baseColumns = [
    {
      id: 'PorcentajeAvance',
      label: '% de Avance de Cuadernillos',
      align: 'center',
      type: 'circular',
      fixed: 1,
    },
    { id: 'TotalCuadernillos', label: 'Total de Cuadernillos', align: 'right' },
    { id: 'CuadernillosFinalizados', label: 'Cuadernillos Finalizados', align: 'right' },
    { id: 'CuadernillosPendientes', label: 'Cuadernillos Pendientes', align: 'right' },
    { id: 'CompromisosParaAsignar', label: 'Compromisos en SIAN', align: 'right' },
    { id: 'FoliosAsignados', label: 'Folios Asignados', align: 'right' },
    { id: 'FoliosUnicosAsignados', label: 'Folios Unicos Asignados', align: 'right' },
    { id: 'PendientesDeAsignar', label: 'Pendientes de Asignar', align: 'right' },
  ];

  const columnas = setDiynamicColumns({
    data: params.data[0],
    columns: [
      ...baseColumns,
      {
        id: 'Region',
        label: 'Región',
        type: 'text',
        align: 'right',
      },
      {
        id: 'Municipio',
        label: 'Municipio',
        type: 'text',
        align: 'left',
      },
      {
        id: 'Poligono',
        label: 'Polígono',
        type: 'text',
        align: 'right',
      },
      {
        id: 'DistritoFederal',
        label: 'Distrito Federal',
        type: 'text',
        align: 'right',
      },
      {
        id: 'DistritoLocal',
        label: 'Distrito Local',
        type: 'text',
        align: 'right',
      },
      {
        id: 'Seccion',
        label: 'Sección',
        type: 'text',
        align: 'right',
      },
    ],
  });

  const handleChangePagination = (pagination) => {
    setPage(pagination.page);
    setPageSize(pagination.pageSize);
  };

  const handleRequestSort = (sortData) => {
    setOrderBy([{ id: sortData.orderBy, value: sortData.order }]);
    setOrder(sortData.orderBy);
  };

  const ApiResponse = async (value) => {
    try {
      setLoading(true);
      setLoadingTable(true);
      let values = {
        idAmbitoAgrupador: agrupador,
        filtered: value.filtered,
        page: page,
        pageSize: pageSize,
        sorted: orderBy,
      };

      const result = await MovilizacionService.getProgressList(values);
      const { results, message, response } = result;
      if (results) {
        const processedData = response.data.map((item) => {
          const processedItem = {};
          Object.keys(item).forEach((key) => {
            processedItem[key] = numberWithCommas(item[key]);
          });
          return processedItem;
        });
        const summaryValues = Object.values(response.totales);
        setTotal(response.total);
        setSummary([...summaryValues]);
        setParams({
          data: processedData,
          totales: response.totales,
        });
      } else {
        setOrder('');
        setOrderBy([]);
        setTotal(0);
        setPage(0);
        setParams({
          data: [],
          totales: [],
        });
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    if (agrupador !== '' && agrupador !== 0) {
      if (page === 0 && orderBy.lenght > 0) {
        ApiResponse(filtered);
      } else {
        setOrder('');
        setOrderBy([]);
        setTotal(0);
        setPage(0);
        setParams({
          data: [],
          totales: [],
        });
        setPage(0);
      }
    }
    //eslint-disable-next-line
  }, [filtered, agrupador]);

  useEffect(() => {
    ApiResponse(filtered);
    //eslint-disable-next-line
  }, [page, pageSize, orderBy]);

  return (
    <CustomTable
      rows={params.data}
      columns={columnas}
      total={total}
      page={page}
      pageSize={pageSize}
      orderBy={order}
      isLoading={loadingTable}
      stickyHeader={true}
      handlePagination={handleChangePagination}
      handleSort={handleRequestSort}
      summary={summary}
      summarySettings={{ start: 90, align: 'right' }}
      maxHeight={600}
      disableCardType
    />
  );
};

export default ChartDetailTable;
