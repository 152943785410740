import { Container } from '@mui/material';

import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Drive from '@components/Movilizacion/Drive';

const DownloadFileStructure = () => {
  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Descarga de archivos'} />
        <Drive />
      </Container>
    </AdminLayout>
  );
};

export default DownloadFileStructure;
