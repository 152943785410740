import React from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  Icon,
} from '@mui/material';
import PropTypes from 'prop-types';

const LinearWithValueLabel = (props) => {
  const {
    isOpen = false,
    setIsOpen = () => {},
    success = false,
    isLoading = false,
    loadinMessage = 'Guardando...',
    successMessage = '¡Almacenado con exito!',
    IconOption,
  } = props;
  return (
    <Dialog onClose={setIsOpen} open={isOpen}>
      {success && (
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={setIsOpen}
            sx={{
              position: 'absolute',
              right: 8,
              top: 0.2,
            }}
          >
            <Icon sx={{ fontSize: 18 }}>close</Icon>
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent align="center" dividers>
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="success" />
            <Typography
              variant="subtitle1"
              component="header"
              sx={{
                flex: '1 1 100%',
                fontWeight: 'medium',
                fontSize: 20,
              }}
            >
              {loadinMessage}
            </Typography>
          </Box>
        )}
        {success ? (
          <>
            <Icon
              color="success"
              sx={{
                fontSize: 30,
              }}
            >
              {IconOption ? IconOption : 'done_all'}
            </Icon>
            <Typography
              variant="subtitle1"
              component="header"
              sx={{
                flex: '1 1 100%',
                fontWeight: 'medium',
                fontSize: 20,
              }}
            >
              {successMessage}
            </Typography>
          </>
        ) : (
          <Icon
            color="success"
            sx={{
              fontSize: 30,
            }}
          >
            loop
          </Icon>
        )}
      </DialogContent>
    </Dialog>
  );
};

LinearWithValueLabel.propTypes = {
  loadinMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default LinearWithValueLabel;
