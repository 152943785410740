import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//MaterialUI
import { Container, Card, CardContent, Box, Grid } from '@mui/material';

//Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import UserUpdate from '@components/Users/UsersUpdate';
import SupportService from '@services/SupportServices';
import BasicListDoble from '@components/Lists/BasicListDoble';
import ListItemCustom from '@components/Users/ListItemCustom';

//Validaciones
import { isTypePhone } from '@utils/validations';

const UserPassword = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState('');
  const [username, setUsername] = useState('');

  const handleClick = (e) => {
    setId(e.id);
    setNombreCompleto(e.NombreCompleto);
    setUsername(e.Username);
  };

  const handleClear = () => {
    setId(0);
    setNombreCompleto('');
    setUsername('');
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Contraseñas y Dispositivos"
          isAction={false}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item sx={12} lg={6}>
                  <BasicListDoble
                    id="id_nuevo"
                    API={SupportService.getUser}
                    APIKeys={[
                      {
                        id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
                        filter: 'LIKE',
                      },
                      {
                        id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                        filter: 'LIKE',
                      },
                    ]}
                    itemComponent={ListItemCustom}
                    header={''}
                    handleClick={handleClick}
                    handleClear={handleClear}
                    label={'Encuentra y elige al usuario para la acción a realizar'}
                    labelNote={
                      'Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular.'
                    }
                    finder={true}
                    inputValidation={{ input2: isTypePhone }}
                    initialSearch={false}
                    emptySearch={false}
                    doubleSearch={true}
                    breaks={{ sm: 12, md: 6 }}
                    buttonsBreaks={{ sm: 12, md: 4 }}
                    disableCardType={true}
                    config={{ height: 400 }}
                    clearData={true}
                  />
                </Grid>
                <Grid item sx={{ width: '100%' }} lg={5.5}>
                  <UserUpdate
                    idUsuario={id}
                    nombreCompleto={nombreCompleto}
                    username={username}
                    perfil={false}
                    defaultOpen={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserPassword;
