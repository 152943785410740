import { Box } from '@mui/material';
import { styledGridx3Mobile } from '../../theme';

import CardOption from './CardOption';

import { RCS } from '@data/constants/MobileDiaD';
import Navbar from './Navbar';
import ShowInfo from './ShowInfo';

const GridOptionsRcs = ({ setTypeScreen }) => {
  const handleClick = (typeScreen) => {
    setTypeScreen(typeScreen);
  };

  return (
    <>
      <Navbar title={"Día D RC's"} isScreen screenBack={'welcome'} setTypeScreen={setTypeScreen} />
      <ShowInfo />
      <Box sx={{ ...styledGridx3Mobile, p: 2, height: '100vh' }}>
        {RCS.map((item) => (
          <CardOption
            key={item.id}
            number={item.id}
            label={item.label}
            icon={item.icon}
            handleClick={() => handleClick(item.screen, item.title)}
          />
        ))}
      </Box>
    </>
  );
};

export default GridOptionsRcs;
