//Librerías
import React, { useState } from 'react';
import { Container, Card, CardContent, Box } from '@mui/material';

//Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';

import CustomTabs from '@components/Tabs/CustomTabs';
import ActaEscrutinio from '@pages/Prep/ActaEscrutinio/ActaEscrutinio';
import Reporte from '@pages/Prep/ActaEscrutinio/Reporte';

const Index = () => {
  const [tabValue, setTabValue] = useState('1');

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Acta de escrutinio y cómputo de casilla" />
        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <CustomTabs
                value={tabValue}
                setValue={setTabValue}
                color={'primary'}
                tabs={[
                  {
                    label: 'Captura de Acta',
                    value: '1',
                    component: <ActaEscrutinio />,
                  },
                  {
                    label: 'Reportes',
                    value: '2',
                    component: <Reporte />,
                  },
                ]}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Index;
