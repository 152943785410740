import { Box, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import logo_sian from '@assets/img/SIAN.png';

const PrivacyPolicy = () => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={4}>
      <Card
        className="card-primary"
        sx={{
          maxWidth: 550,
        }}
      >
        <Box bgcolor={'#0041a0'} width={'100%'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ padding: '40px 20px' }}>
            <Box component={'img'} src={logo_sian} width={80} height={80} />
            <Box>
              <Typography color={'white'} fontWeight={600} variant="h5">
                Aviso de privacidad
              </Typography>
              <Typography variant="body2" color={'white'}>
                Sistema de Información de Acción Nacional
              </Typography>
            </Box>
          </Stack>
        </Box>
        <CardContent sx={{ padding: '40px 20px' }}>
          <Typography variant="subtitle2" textAlign={'center'} marginBottom={3}>
            AVISO DE PRIVACIDAD SIMPLIFICADO.
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            Confundamento en lo dispuesto en los artículos 3fracción ,I 34, 35, 36, 37, 39,40, 42 asi como lo
            dispuesto en el Título Tercero, Capítulo Primero de la Ley de Protección de Datos Personales en
            Posesión de Sujetos Obligados para el Estado de Guanajuato, publicada en el Periodo Oficial del
            Gobierno del Estado de Guanajuato el 14 de julio de 2017, se hace de su conocimiento lo siguiente:
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            <strong>Denominación del responsable</strong>: Partido Acción Nacional, es una asociación de
            ciudadanos mexicanos en pleno ejercicio de sus derechos cívicos, constituida en partido político
            nacional, con el fin de intervenir orgánicamente en todos los aspectos de la vida pública de
            México y tener acceso al ejercicio democrático del poder.
          </Typography>
          <Typography variant="body2" marginBottom={4}>
            <strong>Información que recopilamos</strong>: Cuando te registras en la app, recopilamos
            automáticamente cierta información sobre tu dispositivo, incluida información sobre tu dirección
            IP, Nombre del dispositivo, modelo del dispositivo, token para recibir notificaciones y se asigna
            un identificar único al dispositivo. Nos referimos a esta información recopilada automáticamente
            como "Información del dispositivo". Además, podemos recopilar los datos personales que nos
            proporcionas (incluidos, entre otros, nombre, apellidos, dirección, telefóno, clave de elector,
            seccion electoral, etc.) durante el registro de usuario.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            <strong>La finalidad del tratamiento es:</strong>
          </Typography>
          <Typography variant="body2" marginBottom={4}>
            <li>
              Mantener un registro de los ciudadanos que desean participar como representantes del Partido
              Acción Nacional ante las Autoridades Electorales, así como Representantes Generales y de
              Casilla.
            </li>
            <li>Mantener un registro de los militantes y simpatizantes del Partido Acción Nacional.</li>
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            <strong>Seguridad de la información</strong>: Aseguramos la información que proporcionas en
            servidores informáticos en un entorno controlado y seguro, protegido del acceso, uso o divulgación
            no autorizados. Mantenemos medidas de seguridad administrativas, técnicas y físicas razonables
            para proteger contra el acceso no autorizado, el uso, la modificación y la divulgación de datos
            personales bajo su control y custodia. Sin embargo, no se puede garantizar la transmisión de datos
            a través de Internet o redes inalámbricas.
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            <strong>De las transferencias</strong>: Se hace de su conocimiento que sus datos personales podrán
            ser transmitidos a otros sujetos obligados siempre y cuando los datos se utilicen para el
            ejercicio de facultades propias de los mismos, además de otras transmisiones previstas en la Ley.
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            <strong>
              Mecanismos y medios disponibles para q u eel titular de los datos personales pueda manifestar su
              negativa para el tratamiento de sus datos personales
            </strong>
            : El partido pondrá a consideración del ciudadano, a través de un manifiesto expreso, la
            autorización o no de la transferencia de sus datos personales a otros sujetos obligados, cuyo
            tratamiento sea susceptible de transferencia.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            <strong>El sitio donde se podrá consultar el aviso de privacidad integral</strong>: El aviso de
            privacidad podrá ser consultado en la página web: http://siangto.com/.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PrivacyPolicy;
