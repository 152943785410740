import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Box, Button, Card, CardContent, Container, Icon, Stack, Typography } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Download from '@components/Downloads/Download';
import FilterEstatal from '@components/Rcos/FilterEstatal';
import CustomTable from '@components/Tables/CustomTable';

// Data, Servicios, Utilidades y Middleware
import { COLUMNS_RCO_ESTATAL } from '@data/constants/Rco';
import RcosServices from '@services/RcosServices';
import { setDiynamicColumns } from '@utils/Utilities';
import middleware from '@middlewares/middleware';

// Hooks
import { useSorted } from '@hooks/useSorted';

const Estatal = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [summary, setSummary] = useState([]);
  const [total, setTotal] = useState(10);

  const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] = useState(null);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const { handleRequestSort } = useSorted({ total: total, setData });

  const getInfoRcosGeneral = async (body) => {
    try {
      setIsLoading(true);

      const result = await RcosServices.getInfoRcosGeneral(body);
      const { response, message, results } = result;

      if (!results) throw new Error(message);

      const columns = setDiynamicColumns({ columns: COLUMNS_RCO_ESTATAL, data: response.data[0] });
      setColumns(columns);
      setRows(response.data);
      setSummary(response.totales ? Object.values(response.totales) : []);
      setTotal(response.total);
    } catch (e) {
      Swal.fire({ title: e, icon: 'warning' });
      setRows([]);
      setColumns([]);
      setSummary([]);
      setTotal(0);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToExcel = async () => {
    setIsDownload(true);
    try {
      const result = await RcosServices.export({
        api: 'rcos/export-info-rcos-general-by-scope',
        params: {
          idAmbitoAgrupador: idAmbitoAgrupadorTable,
          filtered: data.filtered,
          sorted: data.sorted,
        },
      });
      const { success, error } = result;

      if (!success) throw new Error(error.message);
      Swal.fire({ title: 'Descargado con éxito', icon: 'success' });
    } catch (e) {
      Swal.fire({ title: e, icon: 'warning' });
    } finally {
      setIsDownload(false);
    }
  };

  const handlePagination = (pagination) => setData({ ...data, ...pagination });

  const handleSearchFilter = ({ filtereds }) => setData((prev) => ({ ...prev, filtered: filtereds }));

  const handleClearFilter = () => {
    if (data.filtered.length > 0 || data.idAmbitoAgrupador !== idAmbitoAgrupadorTable) {
      setData((prevState) => ({
        ...prevState,
        filtered: [],
        idAmbitoAgrupador: idAmbitoAgrupadorTable,
      }));
      //setIdAmbitoAgrupadorTable();
    }
  };

  useEffect(() => {
    if (data.idAmbitoAgrupador !== null) getInfoRcosGeneral(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="RCO Avance Estructura" />
        <FilterEstatal
          //idAmbitoAgrupador={idAmbitoAgrupadorTable}
          setIdAmbitoAgrupador={setIdAmbitoAgrupadorTable}
          setData={setData}
          onChangeFilter={handleSearchFilter}
          onClearFilter={handleClearFilter}
        />

        <Card className="card-primary">
          <CardContent>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={2}>
              <Typography variant="body2" fontWeight={700}>
                Avance Estructura
              </Typography>
              {middleware.checkMenuAction('Exportar') && (
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  disabled={isDownload || isLoading}
                  onClick={exportToExcel}
                >
                  Exportar
                </Button>
              )}
            </Stack>

            {isDownload && (
              <Box marginBottom={2}>
                <Download format={'xlsx'} isDownload={isDownload} />
              </Box>
            )}

            <CustomTable
              rows={rows}
              columns={columns}
              summary={summary}
              total={total}
              page={data.page}
              pageSize={data.pageSize}
              maxHeight={500}
              stickyHeader
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Estatal;
