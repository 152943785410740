import { useEffect, useState } from 'react';

import AdminLayout from '@components/MainPage/AdminLayout';
import Card from '@components/Card/Card';
import TreeViewSpaces from '@components/Structure/TreePermisos';
import Grid from '@mui/material/Unstable_Grid2';

import PieChart from '@components/Charts/PieChart';
import BasicLine from '@components/Charts/BasicLine';
import StackedBar from '@components/Charts/StackedBar';
import BarClick from '@components/Charts/BarClick';
import ActionHeader from '@components/Containers/ActionHeader';
import { Container, Breadcrumbs, Icon, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SocialService from '@services/SocialServices';
import Swal from 'sweetalert2';
import AutoComplete from '@components/Selects/Autocomplete';
import CardCommitments from '@components/Card/StatesCard';
import { numberWithCommas } from '@utils/Utilities';

const SocialNumerals = () => {
  const navigate = useNavigate();
  var today = new Date();
  const [loading, setLoading] = useState(false);
  const [paramStruct] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [structs, setStructs] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [searchEstructura /* setSearchEstructura */] = useState('');
  const [segmentos, setSegmentos] = useState([]);

  /* Graficas */
  const [loadingChart, setLoadingChart] = useState(false);
  const [paramsChart, setParamsChart] = useState({});
  const [paramsTotal, setParamsTotal] = useState({});
  const [dataChart, setDataChart] = useState({
    TotalEstructuras: 0,
    TotalCompromisos: 0,
    compromisos: {
      TotalCompromisos: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    responsables: {
      TotalResponsables: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    enlaces: {
      TotalEnlaces: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
  });
  const [dataSex, setDataSex] = useState({
    TotalCompromisos: 0,
    TotalMasculino: 0,
    TotalFemenino: 0,
    TotalBinario: 0,
  });
  const [dataAgeSex, setDataAgeSex] = useState({
    categories: ['Uno'],
    series: [1],
    title: {
      primary: 'Edades',
      sub: 'sexo',
    },
  });
  const [dataDay, setDataDay] = useState({
    categories: [],
    series: [],
    drilldown: {},
    title: {
      primary: 'Compromisos',
    },
  });
  const [dataRegion, setDataRegion] = useState({
    drilldown: { series: [] },
    series: [],
    title: {
      primary: 'Compromisos por Región',
      sub: 'Región -> Municipio',
    },
    ejes: {
      x: 'Compromisos',
    },
  });

  const getStructs = () => {
    setLoading(true);
    SocialService.getStruct(paramStruct)
      .then((res) => {
        if (res.results) {
          const struct = res.response.map((social) => {
            return {
              ...social,
              level: 1,
            };
          });
          setSelectedNode(struct[0]);
          setStructs(struct);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };

  /* */
  const getLineStruct = () => {
    SocialService.catalogoStruct({})
      .then((res) => {
        if (res.results) {
          setSegmentos(
            res.response.map((item) => {
              return {
                value: item.id,
                label: item.NombreSocial,
                idPadre: item.idPadre,
              };
            })
          );
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });
  };

  const getCompromisoSexo = () => {
    setLoadingChart(true);
    SocialService.getTotalesSocial(paramsTotal)
      .then((res) => {
        if (res.results) {
          setDataSex(res.response.compromisos);
          setDataChart(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  const getAgeSex = () => {
    setLoadingChart(true);
    SocialService.getAgeSex(paramsChart)
      .then((res) => {
        if (res.results) {
          setDataAgeSex(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  const getDay = () => {
    setLoadingChart(true);
    SocialService.getDiaCompromisos(paramsChart)
      .then((res) => {
        if (res.results) {
          setDataDay(res.response);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  const getRegion = () => {
    setLoadingChart(true);
    SocialService.getRegionCompromisos(paramsChart)
      .then((res) => {
        if (res.results) {
          setDataRegion({
            ...dataRegion,
            series: res.response.series,
            drilldown: res.response.drilldown,
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    getStructs();
    getLineStruct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCompromisoSexo();
    getAgeSex();
    getDay();
    getRegion();
    // eslint-disable-next-line
  }, [paramsChart]);

  const SelecFilter = (e) => {
    setParamsTotal({
      idEstructura: [e.id],
    });
    setParamsChart({
      idEstructura: e.id,
    });
    setSelected(e.id);
    let exp = [e.id];
    let stringExp = [`${e.id}`];
    for (let count = 0; count < e.Nivel; count++) {
      let search = segmentos.filter((item) => item.value === exp[count]);
      exp.push(search[0].idPadre);
      stringExp.push(`${search[0].idPadre}`);
    }
    setExpanded(stringExp);
    setSelectedNode(e);
  };

  const searchTree = (node, value) => {
    if (node['NombreSocial'].toUpperCase().match(value)) {
      return node;
    } else if (node.children.length > 0) {
      var i;
      var result = null;
      for (i = 0; result == null && i < node.children.length; i++) {
        result = searchTree(node.children[i], value.trim());
      }
      return result;
    }
    return null;
  };

  const filterSegmento = (e) => {
    let search = searchTree(structs[0], e.label.toUpperCase());
    if (search) {
      SelecFilter(search);
    }
  };

  const formatDataChartGender = () => {
    let data = {
      data: [
        {
          name: 'femenino',
          color: '#FF6384',
          y: dataSex.TotalFemenino,
          total: dataSex.TotalCompromisos,
          sliced: true,
          selected: true,
        },
        {
          name: 'Masculino',
          color: '#36A2EB',
          y: dataSex.TotalMasculino,
          total: dataSex.TotalCompromisos,
        },
        {
          name: 'Binario',
          color: '#CA86F7',
          y: dataSex.TotalBinario,
          total: dataSex.TotalCompromisos,
        },
      ],
    };

    return {
      series: data,
      options: {
        title: 'Sexo',
        subtitle: '',
      },
      loading: false,
      pieColors: ['#36A2EB', '#FF6384', '#CA86F7'],
    };
  };

  const Selec = (e) => {
    setParamsChart({ idEstructura: e.id });
    setParamsTotal({
      idEstructura: [e.id],
    });
    setSelectedNode(e);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title={
            selectedNode.id ? (
              <>
                <Breadcrumbs
                  separator={<Icon fontSize="small">arrow_forward_ios</Icon>}
                  aria-label="breadcrumb"
                >
                  <Typography variant="body1">Numeralia</Typography>
                  <Typography variant="h6" color={'InfoText'}>
                    {selectedNode.NombreSocial}
                  </Typography>
                </Breadcrumbs>
              </>
            ) : (
              'Numeralia'
            )
          }
          handleclick={() => navigate('/social-numeralia')}
        />
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <CardCommitments
              icon={'reduce_capacity'}
              title={'Total Compromisos'}
              total={dataChart.compromisos.TotalCompromisos}
              subtitle1={'Femenino'}
              subtotal1={dataChart.compromisos.TotalFemenino}
              subtitle2={'Maculino'}
              subtotal2={dataChart.compromisos.TotalMasculino}
              subtitle3={'Binario'}
              subtotal3={dataChart.compromisos.TotalBinario}
              subIcon={'update'}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <CardCommitments
              icon={'manage_accounts'}
              title={'Total Responsables'}
              total={dataChart.responsables.TotalResponsables}
              subtitle1={'Femenino'}
              subtotal1={dataChart.responsables.TotalFemenino}
              subtitle2={'Maculino'}
              subtotal2={dataChart.responsables.TotalMasculino}
              subtitle3={'Binario'}
              subtotal3={dataChart.responsables.TotalBinario}
              subIcon={'update'}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <CardCommitments
              icon={'record_voice_over'}
              title={'Total Enlaces'}
              total={dataChart.enlaces.TotalEnlaces}
              subtitle1={'Femenino'}
              subtotal1={dataChart.enlaces.TotalFemenino}
              subtitle2={'Maculino'}
              subtotal2={dataChart.enlaces.TotalMasculino}
              subtitle3={'Binario'}
              subtotal3={dataChart.enlaces.TotalBinario}
              subIcon={'update'}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <Card
              sx={{ height: '1400px', overflow: 'auto' }}
              hasCardHeader={true}
              colorbgtittle="#E6F2FC"
              colorTittle="#3063BA"
              Tittle={
                <Grid container alignItems="center">
                  <Grid item xs={0.8}>
                    <Icon color="primary" sx={{ mt: 0.5 }}>
                      fact_check
                    </Icon>
                  </Grid>
                  <Grid item xs>
                    <Typography gutterBottom variant="h6" component="Box">
                      {selectedNode.NombreSocial}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{dataChart.TotalEstructuras}</Typography>
                  </Grid>
                </Grid>
              }
              children={
                <>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      {/*    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="stretch"
                        sx={{bgcolor:"#C2D9F2"}}
                        spacing={2}
                      >
                        <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}>
                        <Icon color="primary" sx={{mr:0}}>fact_check</Icon>
                        <Typography variant="h6" sx={{ml:0, color:"#3053B0"}}>{selectedNode.NombreSocial}</Typography>
                        </Stack>
                        
                      </Stack> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <AutoComplete
                        required
                        label="Estructuras"
                        name="Estructuras"
                        autoComplete="Estructuras"
                        value={searchEstructura}
                        id="Estructuras"
                        size="small"
                        disableUnderline={false}
                        setSelect={(e) => filterSegmento(e)}
                        data={segmentos}
                        //onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <TreeViewSpaces
                    loading={loading}
                    total={total}
                    data={structs}
                    setSelected={(e) => setSelected(e)}
                    setExpanded={(e) => setExpanded(e)}
                    onExpanded={expanded}
                    onSelected={selected}
                    typeData={{ value: 'id', label: 'NombreSocial' }}
                    onSelect={(e) => Selec(e)}
                  />
                </>
              }
            />
          </Grid>
          <Grid xs={12} sm={12} md={8} lg={8}>
            <Grid sm={12} xs={12} md={12} lg={12}>
              <Card
                sx={{ overflow: 'auto' }}
                children={<BasicLine {...{ ...dataDay, loading: loadingChart }} />}
              />
            </Grid>
            <Grid sm={12} xs={12} md={12} lg={12}>
              <Card sx={{ overflow: 'auto' }} children={<PieChart {...formatDataChartGender()} />} />
            </Grid>
            <Grid sm={12} xs={12} md={12} lg={12}>
              <Card
                sx={{ overflow: 'auto' }}
                children={<StackedBar {...{ ...dataAgeSex, loading: loadingChart }} />}
              />
            </Grid>

            {/*    <Grid sm={12} xs={12} md={12} lg={12}>
              <Card
                sx={{ overflow: "auto" }}
                children={
                  <BarClick {...{ ...dataRegion, loading: loadingChart }} />
                }
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default SocialNumerals;
