import { useEffect, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

import PropTypes from 'prop-types';
//Components
import InputSelect from '@components/Selects/BasicSelect';

//Mui
import { Box, Button, Icon, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { ELECCIONES_GLOBALES, ELECCION_GOBERNADOR } from '@data/constants/Prep';

const Filter = (props) => {
  const { onChangeFilter, disabledButtons } = props;
  const catalogsOptions = [
    {
      id: 'tipo_eleccion',
      filtered: [
        {
          id: 'id',
          filter: 'IN',
          value: [ELECCION_GOBERNADOR],
        },
      ],
    },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  const [filterData, setFilterData] = useState({
    idTipoEleccion: '',
  });
  const [initFilter, setInitFilter] = useState({});
  useEffect(() => {
    if (!load) {
      if (catalogs.tipo_eleccion && catalogs.tipo_eleccion.length > 0) {
        handleSetInitFilter(catalogs.tipo_eleccion[0].value);
      }
    }
    //eslint-disable-next-line
  }, [load]);

  const handleSetInitFilter = (idTipoEleccion) => {
    setFilterData({
      idTipoEleccion: idTipoEleccion,
    });

    onChangeFilter({
      idTipoEleccion: idTipoEleccion,
    });

    setInitFilter({
      idTipoEleccion: idTipoEleccion,
    });
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    onChangeFilter(filterData);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilterData(initFilter);
    onChangeFilter(initFilter);
  };

  const handleChangeElectionType = (value) => {
    setFilterData({
      idTipoEleccion: value,
    });
  };

  return (
    <Box sx={{ mt: 2, paddingX: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <Icon>filter_list</Icon>
        <Typography variant="body2" fontWeight={600}>
          Filtros
        </Typography>
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <InputSelect
            label="Tipo de elección"
            options={catalogs.tipo_eleccion}
            name="idTipoEleccion"
            value={!load ? filterData.idTipoEleccion : ''}
            onChange={(e) => handleChangeElectionType(e.target.value)}
            disabled={!load && catalogs.tipo_eleccion.length < 2}
            sx={{ width: '100%' }}
            isLoading={load}
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={1} sx={{ mt: 2 }} justifyContent={'end'} alignItems={'end'}>
        <Grid2 xs={6} sm={3} md={6} lg={4}>
          <Button
            variant="outlined"
            color="primaryDark"
            onClick={handleClearFilter}
            fullWidth
            disabled={disabledButtons}
          >
            Limpiar
          </Button>
        </Grid2>
        <Grid2 xs={6} sm={3} md={6} lg={4}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={handleApplyFilter}
            fullWidth
            disabled={disabledButtons}
          >
            Filtrar
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

Filter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default Filter;
