import { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { Container, CardContent, Chip } from '@mui/material';
import Filter from '@components/MapsModules/CreationOfPolygons/FilterList';
import { useNavigate } from 'react-router-dom';
import BasicTable from '@components/Tables/BasicTable';
import Card from '@components/Card/Card';
import CardStats from '@components/Card/CardStats';
import Grid from '@mui/material/Unstable_Grid2';
import PolygonService from '@services/MapServices/PolygonServices';
import Swal from 'sweetalert2';

const ListProgress = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCharts, setLoadingChart] = useState(false);
  const [chartData, setChartData] = useState({
    Total: 0,
    TotalSinPoligono: 0,
    TotalConPoligono: 0,
  });
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [paramsChart, setParamsChart] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });

  var today = new Date();

  const columns = [
    { id: 'Region', label: 'Región' },
    { id: 'Municipio', label: 'Municipio' },
    { id: 'Poligono', label: 'Polígono' },
    { id: 'Seccion', label: 'Sección' },
  ];

  /* 
  .map((item) => {
              return {
                ...item,
                Segmento: (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                    sx={{display:"contents"}}
                  >
                    {item.Segmento.split(",").map((i) => (
                      <Chip label={i} color="primary" variant="outlined" size="small" />
                    ))}
                  </Stack>
                ),
                Ranking: (
                  <Rating
                    name="text-feedback"
                    value={item.Ranking/10}
                    readOnly
                    precision={0.5}       
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                ),
              };
            })
  */
  const getList = () => {
    PolygonService.ListProgress(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                Poligono: (
                  <Chip
                    label={item.Poligono ? item.Poligono : 'Sin Polígono'}
                    variant="outlined"
                    color={item.Poligono ? 'success' : 'error'}
                    size="small"
                    sx={{
                      color: item.Poligono ? 'green' : 'red',
                      fontWeight: item.Poligono ? '700' : '0',
                      width: '100px',
                    }}
                  />
                ),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const getTotalCompromiso = () => {
    PolygonService.TotalsProgress(paramsChart)
      .then((res) => {
        if (res.results) {
          setChartData(res.response.data[0]);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    setLoading(true);
    setLoadingChart(true);
    getList();
    getTotalCompromiso();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Poligono } = type;

    let filtered = [];
    let filteredChart = [];
    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filteredChart = [...filteredChart, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];

      filteredChart = [...filteredChart, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];
    }
    if (Poligono > 0) {
      filtered = [
        ...filtered,
        {
          id: 'creacion_poligonos_secciones.Seccion',
          filter: Poligono === 1 ? 'IS' : 'IS NOT',
          value: 'NULL',
        },
      ];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    setParamsChart({
      ...paramsChart,
      filtered: filteredChart,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Listado Polígonos" handleclick={() => navigate('/list-polygons')} />
        <Grid container spacing={2}>
          <Grid xs={4}>
            <CardStats
              icon={'list_alt'}
              title={'Secciones'}
              total={chartData.Total}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={4}>
            <CardStats
              icon={'playlist_add_check'}
              title={'Secciones sin polígono'}
              total={chartData.TotalSinPoligono}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={4}>
            <CardStats
              icon={'playlist_remove'}
              title={'Secciones con polígono'}
              total={chartData.TotalConPoligono}
              subIcon={'calendar_today'}
              subtitle={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            <Filter onChangeFilter={(e) => handleChangeFilter(e)} />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            {/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )} */}

            {/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
            */}
            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tableData.page}
              pageSize={tableData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default ListProgress;
