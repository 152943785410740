import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Card, CardContent, Container, Tab, Box, Icon } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import Swal from 'sweetalert2';

import { getVars } from '@utils/global';
import middleware from '@middlewares/middleware';
import useWindowDimensions from '@hooks/useWindowDimensions';
import AdminLayout from '@components/MainPage/AdminLayout';
import MenusList from '@components/Menus/MenusList';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import MenuForm from '@components/Menus/MenuForm';
import MenuService from '@services/MenuServices';
import ActionHeader from '@components/Containers/ActionHeader';

const Menus = () => {
  const { height } = useWindowDimensions();
  const [webMenus, setWebMenus] = useState([]);
  const [appMenus, setAppMenus] = useState([]);
  const [tabValue, setTabValue] = useState('1');
  const [flagForm, setFlagForm] = useState(false);
  const [title, setTitle] = useState('Administración de menús');
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [issuccessForm, setIsSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [access_token] = useState(() => {
    let t = getVars('Token');
    if (t !== {}) return t.access_token;
    return '';
  });

  const handleLoadList = useCallback(() => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    MenuService.getAllMenus({}, access_token)
      .then((res) => {
        if (res.results) {
          setAppMenus(res.response.appMenus);
          setWebMenus(res.response.webMenus);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, [access_token]);

  useEffect(() => {
    handleLoadList();
  }, [handleLoadList]);

  const changeWebOrder = (newOrder) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    let Change = [];
    newOrder.forEach((row, index) => {
      if (row.idMenu !== webMenus[index].idMenu) {
        Change.push({
          id: row.idMenu,
          Orden: index * 100,
        });
      }
    });

    MenuService.changeOrder(Change, access_token)
      .then((res) => {
        if (res.results) {
          setWebMenus(newOrder);
          Swal.fire({ title: res.message, icon: 'success' });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  };

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleNewRecord = (webMenus, appMenus) => {
    setWebMenus(webMenus);
  };

  const handleClickAdd = () => {
    if (flagForm || edit) {
      setFlagForm(false);
      setTitle('Administración de Menús');
      setEdit(false);
    } else {
      setTitle('Agregar Nuevo Menú');
      setFlagForm(true);
    }
  };

  return (
    <>
      <AdminLayout>
        <Container id="container" maxWidth={false}>
          <ActionHeader
            title={title}
            titleButton={flagForm || edit ? 'Regresar' : 'Agregar Nuevo Menú'}
            iconButton={flagForm || edit ? 'chevron_left' : 'add'}
            isAction={true}
            handleclick={() => handleClickAdd()}
          />
          <Card className="card-primary">
            <CardContent className="p-0">
              <LoadingForm
                isLoading={isloadigForm}
                success={issuccessForm}
                isOpen={openLinearProgress}
                setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                loadinMessage={'Cargando...'}
              />
              {flagForm || edit ? (
                <MenuForm
                  action={flagForm ? 'add' : 'edit'}
                  data={editData}
                  handleNewRecord={handleNewRecord}
                  setIsOpen={setOpenLinearProgress}
                  setIsLoading={setIsLoadingForm}
                  setIsSuccess={setIsSuccessForm}
                />
              ) : (
                <Grid xs={12} item>
                  <TabContext value={tabValue}>
                    <Box>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="fullWidth"
                        scrollButtons="auto"
                      >
                        <Tab icon={<Icon>computer</Icon>} label="web" value="1" />
                        <Tab icon={<Icon>phone_iphone</Icon>} label="app" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      {middleware.checkMenuAction('Editar') ? (
                        <MenusList
                          menus={webMenus}
                          setMenus={changeWebOrder}
                          type={'draggable'}
                          maxHeight={height * 0.5}
                          setIsOpen={setOpenLinearProgress}
                          setIsLoading={setIsLoadingForm}
                          setIsSuccess={setIsSuccessForm}
                          handleReloadList={handleLoadList}
                          setEdit={(e) => setEdit(e)}
                          edit={edit}
                          setEditData={(e) => setEditData(e)}
                        />
                      ) : (
                        <MenusList menus={webMenus} type={'list'} maxHeight={height * 0.5} />
                      )}
                    </TabPanel>

                    <TabPanel value="2">
                      {middleware.checkMenuAction('Editar') ? (
                        <MenusList
                          menus={appMenus}
                          setMenus={changeWebOrder}
                          type={'draggable'}
                          maxHeight={height * 0.5}
                          setIsOpen={setOpenLinearProgress}
                          setIsLoading={setIsLoadingForm}
                          setIsSuccess={setIsSuccessForm}
                          handleReloadList={handleLoadList}
                          setEdit={(e) => setEdit(e)}
                          edit={edit}
                          setEditData={(e) => setEditData(e)}
                        />
                      ) : (
                        <MenusList menus={appMenus} type={'list'} maxHeight={height * 0.5} />
                      )}
                    </TabPanel>
                  </TabContext>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};

export default Menus;
