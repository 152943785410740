import { useState, useEffect } from 'react';
//MUI
import {
  Container,
  Card,
  CardContent,
  Box,
  Tab,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Send } from '@mui/icons-material';
import { Check } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//COMPONENTS
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CompromisosTable from '@components/Movilizacion/CompromisosTable';
import MovilizacionFilter from '@components/Movilizacion/CompromisosFilter';
import BasicList from '@components/Lists/BasicList';
import MovilizacionService from '@services/MovilizationServices';
import middleware from '@middlewares/middleware';

const Item = ({ item, selectedIndex, handleSelectedIndex }) => {
  const handleListItemClick = (event, index) => {
    handleSelectedIndex(index, item);
  };

  return (
    <ListItem key={item.idCompromisoUnico} disablePadding>
      <ListItemButton
        selected={selectedIndex === item.idCompromisoUnico}
        onClick={(event) => handleListItemClick(event, item.idCompromisoUnico)}
        disabled={item.idEstatusMovilizacion === 2}
      >
        <Grid2 container xs={12}>
          <Grid2 item xs={12} md={5}>
            <ListItemText primary={`${item.Nombre?.toUpperCase()}`} />
          </Grid2>
          <Grid2 item xs={12} md={3} container alignItems="center">
            <Typography variant="body2" style={{ margin: 'auto', color: `${grey[500]}` }}>
              {item.idEstatusMovilizacion === 2
                ? item.EstatusMovilizacion && item.EstatusMovilizacion?.toUpperCase()
                : 'Presiona para registrar el voto'}
            </Typography>
          </Grid2>
          <Grid2 container xs={12} md={3} justifyContent="flex-end" alignItems="center">
            <ListItemIcon>
              {item.idEstatusMovilizacion === 2 ? <Check color="info" /> : <Send color="primary" />}
            </ListItemIcon>
          </Grid2>
        </Grid2>
      </ListItemButton>
    </ListItem>
  );
};

const CompromisosMovilizacion = () => {
  const config = {
    title: 'Votantes',
    icon: 'people',
    height: 400,
    endMessage: 'No hay más registros para mostrar',
    alertMessage:
      'Si no encontró a la persona que buscaba. Presiona el siguiente botón para registrar el voto:',
    buttonName: 'Registrar Voto',
  };
  const [usersList, setUsersList] = useState([]);
  const [total, setTotal] = useState(0);
  const [box, setBox] = useState(null);
  const [isLoadigForm, setIsLoadingForm] = useState(false);

  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [casillaValue, setCasillaValue] = useState();
  const [open, setOpen] = useState({
    open: false,
    message: '',
  });

  const middleWares = {
    edit: middleware.checkMenuAction('Editar'),
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setBox(null);
  };

  const handleClickReport = () => {
    let filter = {
      idTipoBingo: 2,
      Seccion: box.Seccion,
      Nombre: box.Nombre?.toUpperCase(),
      OrigenCaptura: 'web',
    };
    if (casillaValue) {
      filter = {
        ...filter,
        idCasilla: casillaValue,
      };
    }
    saveMovilizacionElectoral(filter);
  };

  const handleClickItem = (userInfo) => {
    let filter = {
      idTipoBingo: 2,
      Seccion: userInfo.Seccion,
      idCompromisoUnico: userInfo.idCompromisoUnico,
      OrigenCaptura: 'web',
    };

    if (casillaValue) {
      filter = {
        ...filter,
        idCasilla: casillaValue,
      };
    }

    saveMovilizacionElectoral(filter);
  };

  const resetParams = () => {
    setUsersList([]);
    setTotal(0);
    setShowAlert(false);
  };

  const handleClose = (event) => {
    setOpen({
      open: false,
      message: '',
    });
  };

  const compromisosMovElectoral = async (value) => {
    let filter = {
      NombreCompleto: value.Nombre?.toUpperCase(),
      Seccion: value.Seccion,
      RangoInicial: value.RangoInicial,
      RangoFinal: value.RangoFinal,
      OrigenCaptura: 'web',
    };
    if (value.idCasilla) {
      filter = {
        ...filter,
        idCasilla: value.idCasilla,
      };
    }
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await MovilizacionService.getCompromisosMovElectoral(filter);
      const { results, message, response } = result;
      if (results) {
        setShowAlert(true);
        const filteredData = response.data;
        setUsersList(filteredData);
        setTotal(filteredData.length);
      } else {
        setShowAlert(true);
        setUsersList([]);
        setTotal(0);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const saveMovilizacionElectoral = async (values) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await MovilizacionService.setParamsBingo(values);
      const { results, message } = result;
      if (results) {
        const updatedData = usersList.map((row) =>
          row.idCompromisoUnico === values.idCompromisoUnico ? { ...row, idEstatusMovilizacion: 2 } : row
        );
        setUsersList(updatedData);
        setOpen({
          open: true,
          message: message,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  useEffect(() => {
    if (box) {
      compromisosMovElectoral(box);
    } else {
      resetParams();
    }
    //eslint-disable-next-line
  }, [box]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Bingos Movilización" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={'Cargando...'}
        />
        <Card className="card-primary">
          <CardContent>
            <Snackbar
              open={open.open}
              autoHideDuration={1500}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                {open.message}
              </Alert>
            </Snackbar>
            <TabContext value={tabValue}>
              {/* <Box>
                <TabList onChange={handleTabChange} aria-label="Tabs" centered>
                  <Tab label={"CAPTURA BINGOS"} value={1} />
                  <Tab label={"BINGOS REPORTADOS"} value={2} />
                  <Tab label={"PENDIENTES DE REPORTAR"} value={3} />
                </TabList>
              </Box> */}
              <TabPanel value={1}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={3}>
                      <MovilizacionFilter
                        handleSetBox={setBox}
                        TabValue={tabValue}
                        casillaValue={setCasillaValue}
                      />
                    </Grid2>
                    <Grid2 xs={12} md={9}>
                      <Card className="card-primary">
                        <CardContent>
                          <Grid2 container spacing={3}>
                            <BasicList
                              configParams={config}
                              data={usersList}
                              totalItems={total}
                              itemComponent={Item}
                              hasMore={false}
                              handleClickItem={handleClickItem}
                              finder={false}
                              alertUser={showAlert}
                              onClick={handleClickReport}
                            />
                          </Grid2>
                        </CardContent>
                      </Card>
                    </Grid2>
                  </Grid2>
                </Box>
              </TabPanel>
              {middleWares.edit && (
                <TabPanel value={2}>
                  <Box>
                    <Grid2 container spacing={2}>
                      <Grid2 xs={12}>
                        <MovilizacionFilter
                          handleSetBox={setBox}
                          TabValue={tabValue}
                          casillaValue={setCasillaValue}
                        />
                      </Grid2>
                      <Grid2 xs={12}>
                        <CompromisosTable
                          box={box}
                          setIsLoadingForm={setIsLoadingForm}
                          setOpenLinearProgress={setOpenLinearProgress}
                          loading={isLoadigForm}
                        />
                      </Grid2>
                    </Grid2>
                  </Box>
                </TabPanel>
              )}
              <TabPanel value={3}>
                <Box>
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12}>
                      <MovilizacionFilter
                        handleSetBox={setBox}
                        TabValue={tabValue}
                        casillaValue={setCasillaValue}
                      />
                    </Grid2>
                    <Grid2 xs={12}>
                      <CompromisosTable
                        box={box}
                        setIsLoadingForm={setIsLoadingForm}
                        setOpenLinearProgress={setOpenLinearProgress}
                        loading={isLoadigForm}
                      />
                    </Grid2>
                  </Grid2>
                </Box>
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default CompromisosMovilizacion;
