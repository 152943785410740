import ApiExec from '@utils/ApiExec';
import { encryptVars, DEBUG } from '@utils/global';

class DiaDServices {
  getCatalogs = (params) => {
    return ApiExec(params, 'diad/get-catalogs', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getPeopleToAssign = (params) => {
    return ApiExec(params, 'diad/get-people-for-assign', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setResponsability = (params) => {
    return ApiExec(params, 'diad/set-responsibility', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getResponsabilities = (value) => {
    const values = DEBUG ? value : encryptVars(value);
    return ApiExec({}, `diad/get-responsibilities/${values}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setResponsability = (params) => {
    return ApiExec(params, 'diad/set-responsibility/', 'POST')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  deleteResponsability = (params) => {
    return ApiExec(params, 'diad/delete-responsibility', 'DELETE')
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  createUsuarioDiad = (params) => {
    return ApiExec(params, 'volunteers/create-usuario-diad', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsabilityRutaDiad = (params) => {
    return ApiExec(params, 'volunteers/get-responsability-ruta-diad', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteResponsibility = (params) => {
    return ApiExec(params, 'volunteers/delete-responsibility', 'DELETE')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsabilityRutaMRT = (params) => {
    return ApiExec(params, 'volunteers/get-responsability-ruta-mrt', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsabilitySocial = (params) => {
    return ApiExec(params, 'volunteers/get-responsability-social', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardsMRT = (params) => {
    return ApiExec(params, 'mrt/get-cards-mrt', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTableMRT = (params) => {
    return ApiExec(params, 'mrt/get-table-mrt', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCasillasInformationMRT = (params) => {
    return ApiExec(params, 'mrt/get-casillas-information-mrt', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCasillasInformationTotalsMRT = (params) => {
    return ApiExec(params, 'mrt/get-casillas-information-totals-mrt', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMecanismosRoutes = (params) => {
    return ApiExec(params, 'mrt/get-mecanismos-routes', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  assignMecanismoToRoute = (params) => {
    return ApiExec(params, 'mrt/assign-mecanismo-to-route', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  removeMecanismoRoute = (params) => {
    return ApiExec(params, 'mrt/remove-mecanismo-route', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const services = new DiaDServices();
export default services;
