import { useState } from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';

/* Ejemplo de  markers
{
    data: [{ title: "", latitud: "", longitud: "" }], // Los Markers que se veran
    icon: { }, // Icono apropiado para Marker
    onClick: () => {},
    disableTooltip: false,
}*/

const Markers = (props) => {
  const { markers = {}, setLeftOpen } = props;
  const { data = [], onClick = () => {}, disableTooltip = false, icon = null } = markers;

  const [current, setCurrent] = useState({});

  return data?.map((item, index) => {
    return (
      <Marker
        key={'Marker_' + index}
        {...(icon && { icon })}
        position={[item.latitud, item.longitud]}
        eventHandlers={{
          click: () => {
            onClick(item);
            setCurrent(item);
            setLeftOpen((prev) => (item.title === current.title ? !prev : true));
          },
        }}
      >
        {!disableTooltip && (
          <Tooltip direction="bottom" opacity={1} offset={[-15, 30]} className="custom-tooltip">
            {item.title}
          </Tooltip>
        )}

        <Popup minWidth={90}>
          <span>{item.title}</span>
        </Popup>
      </Marker>
    );
  });
};

export default Markers;
