import { useState, useEffect } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';

//Mui
import { Button, Card, CardContent, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

//Components
import FilterCollapse from '@components/Filters/FilterCollapse';
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Utils
import { isTypePhone } from '@utils/validations';

const Filter = ({ onChangeFilter, onClearFilter }) => {
  //Variables
  const catalogsOptions = [
    { id: 'df', getAll: false },
    { id: 'secciones', getAll: false },
    { id: 'dl', getAll: false },
    { id: 'municipios_reportes', getAll: false },
    { id: 'municipios_dl', getAll: false },
    { id: 'municipios_df', getAll: false },
    { id: 'rutas', getAll: false },
  ];
  const defaultOption = { value: 0, label: 'TODOS' };

  //States
  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [filterData, setFilterData] = useState({
    DistritoLocal: 0,
    DistritoFederal: 0,
    Municipio: 0,
    Ruta: 0,
    Seccion: 0,
    NombreRG: '',
    NombreRC: '',
    Celular: '',
    PaseListaRC: 9999,
    PaseListaRG: 9999,
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: true,
  });

  const handleChangeFilter = ({ name, value }) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChangeDttoFederal = (e) => {
    const idDttoFederal = e.value;

    if (idDttoFederal > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [defaultOption].concat(
          catalogs.municipios_df.filter((item) => item.idDF === idDttoFederal)
        ),
        secciones: [defaultOption].concat(catalogs.secciones.filter((item) => item.idDF === idDttoFederal)),
      }));

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: catalogs.municipios_reportes,
        secciones: catalogs.secciones,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeDttoLocal = (e) => {
    const idDttoLocal = e.value;

    if (idDttoLocal > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [defaultOption].concat(
          catalogs.municipios_dl.filter((item) => item.idMunicipio === idDttoLocal)
        ),
        secciones: [defaultOption].concat(catalogs.secciones.filter((item) => item.idDL === idDttoLocal)),
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: catalogs.municipios_reportes,
        secciones: catalogs.secciones,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.value;

    if (idMunicipality > 0) {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: [defaultOption].concat(
          catalogs.secciones.filter((item) => item.idMunicipioReportes === idMunicipality)
        ),
        rutas: [defaultOption].concat(
          catalogs.rutas.filter((item) => item.idMunicipioReportes === idMunicipality)
        ),
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
        Ruta: 0,
      }));
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: catalogs.secciones,
        rutas: catalogs.rutas,
      }));
      setFilterData((prevState) => ({
        ...prevState,
        Seccion: 0,
        Ruta: 0,
      }));
    }
  };

  const handleSearch = () => {
    let filtered = [];

    if (filterData.DistritoFederal > 0) {
      filtered = [
        ...filtered,
        {
          id: 'DFED',
          filter: '=',
          value: filterData.DistritoFederal,
        },
      ];
    }

    if (filterData.DistritoLocal > 0) {
      filtered = [
        ...filtered,
        {
          id: 'DLOC',
          filter: '=',
          value: filterData.DistritoLocal,
        },
      ];
    }

    if (filterData.Municipio > 0) {
      filtered = [
        ...filtered,
        {
          id: 'idMunicipioReportes',
          filter: '=',
          value: filterData.Municipio,
        },
      ];
    }

    if (filterData.Ruta > 0) {
      filtered = [
        ...filtered,
        {
          id: 'idRuta',
          filter: '=',
          value: filterData.Ruta,
        },
      ];
    }

    if (filterData.Seccion > 0) {
      filtered = [
        ...filtered,
        {
          id: 'Seccion',
          filter: '=',
          value: filterData.Seccion,
        },
      ];
    }

    if (filterData.NombreRG.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'NombreRG',
          filter: 'like',
          value: filterData.NombreRG,
        },
      ];
    }

    if (filterData.NombreRC.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'NombreRC',
          filter: 'like',
          value: filterData.NombreRC,
        },
      ];
    }

    if (filterData.Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: ['CelularRCP1', 'CelularRCP2', 'CelularRCS1', 'CelularRCS2', 'CelularRG'],
          filter: 'LIKE',
          value: filterData.Celular,
        },
      ];
    }

    if (filterData.PaseListaRC !== 9999) {
      filtered = [
        ...filtered,
        {
          id: 'PaseListaRC',
          filter: '=',
          value: filterData.PaseListaRC,
        },
      ];
    }

    if (filterData.PaseListaRG !== 9999) {
      filtered = [
        ...filtered,
        {
          id: 'PaseListaRG',
          filter: '=',
          value: filterData.PaseListaRG,
        },
      ];
    }

    onChangeFilter({
      filtersData: filtered,
    });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      DistritoLocal: 0,
      DistritoFederal: 0,
      Municipio: 0,
      Seccion: 0,
      Ruta: 0,
      NombreRG: '',
      NombreRC: '',
      Celular: '',
      PaseListaRC: 9999,
      PaseListaRG: 9999,
    };
    setCatalogFiltered(catalogs);
    setFilterData(cleanFileds);
    onClearFilter();
  };

  useEffect(() => {
    if (!load) setCatalogFiltered(catalogs);
  }, [load]);

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={4} lg={4}>
              <AdvancedSelect
                label="Distrito Federal"
                name="DistritoFederal"
                value={filterData.DistritoFederal}
                options={catalogsFiltered.df}
                onChange={(e) => {
                  handleChangeFilter({ name: e.name, value: e.value });
                  handleChangeDttoFederal(e);
                }}
                isSearchable
                isLoading={load}
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <AdvancedSelect
                label="Distrito Local"
                name="DistritoLocal"
                value={filterData.DistritoLocal}
                options={catalogsFiltered.dl}
                onChange={(e) => {
                  handleChangeFilter({ name: e.name, value: e.value });
                  handleChangeDttoLocal(e);
                }}
                isSearchable
                isLoading={load}
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <AdvancedSelect
                label="Municipio"
                name="Municipio"
                value={filterData.Municipio}
                options={catalogsFiltered.municipios_reportes}
                onChange={(e) => {
                  handleChangeFilter({ name: e.name, value: e.value });
                  handleChangeMunicipality(e);
                }}
                isSearchable
                isLoading={load}
                sx={{ width: '100%' }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={4} lg={4}>
              <AdvancedSelect
                label="Sección"
                name="Seccion"
                value={filterData.Seccion}
                options={catalogsFiltered.secciones?.map((item) => {
                  return { value: item.value, label: String(item.label) };
                })}
                onChange={(e) => handleChangeFilter({ name: e.name, value: e.value })}
                isLoading={load}
                isSearchable
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={8} lg={8}>
              <AdvancedSelect
                label="Ruta"
                name="Ruta"
                value={filterData.Ruta}
                options={catalogsFiltered.rutas}
                onChange={(e) => handleChangeFilter({ name: e.name, value: e.value })}
                isLoading={load}
                isSearchable
                sx={{ width: '100%' }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={4} lg={4}>
              <TextField
                label="Nombre RG"
                name="NombreRG"
                value={filterData.NombreRG.toUpperCase()}
                size="small"
                onChange={(e) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
              />
            </Grid2>

            <Grid2 xs={12} md={4} lg={4}>
              <TextField
                label="Nombre RC"
                name="NombreRC"
                value={filterData.NombreRC.toUpperCase()}
                size="small"
                onChange={(e) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <TextField
                label="Celular"
                name="Celular"
                value={filterData.Celular}
                size="small"
                onChange={(e) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: isTypePhone(e.target.value) ? e.target.value : filterData.Celular,
                  })
                }
                sx={{ width: '100%' }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} marginTop={1}>
            <Grid2 xs={12} md={5} lg={5}>
              <BasicSelect
                label="Pase Lista RC"
                name="PaseListaRC"
                value={filterData.PaseListaRC}
                options={[
                  { label: 'Todos', value: 9999 },
                  { value: 0, label: 'NO TIENE' },
                  { value: 1, label: 'TIENE' },
                ]}
                onChange={(e) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                isLoading={load}
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={5} lg={5}>
              <BasicSelect
                label="Pase Lista RG"
                name="PaseListaRG"
                value={filterData.PaseListaRG}
                options={[
                  { label: 'Todos', value: 9999 },
                  { value: 0, label: 'NO TIENE' },
                  { value: 1, label: 'TIENE' },
                ]}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                isLoading={load}
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 xs={12} md={1} lg={1}>
              <Button variant="contained" color="primaryDark" sx={{ width: '100%' }} onClick={handleSearch}>
                Filtrar
              </Button>
            </Grid2>
            <Grid2 xs={12} md={1} lg={1}>
              <Button color="primaryDark" sx={{ width: '100%' }} onClick={handleClearFilter}>
                Limpiar
              </Button>
            </Grid2>
          </Grid2>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default Filter;
