import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Stack,
  Divider,
  Card,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  LinearProgress,
  Button,
  Icon,
} from '@mui/material';
import middleware from '@middlewares/middleware';
import ResponsibleList from './ResponsableList';
import social from '../../services/SocialServices';
import Swal from 'sweetalert2';
import { useCatalogs } from '@hooks/useCatalogs';
import Select from 'react-select';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const CardInfoSocial = (props) => {
  const { idUsuario } = props;
  const catalogsParams = [{ id: 'tipos_usuario' }];
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([
    {
      Enlace: 'Segmento',
      Asignado: 'Lupita',
      idAsignado: 451,
      FechaAsigno: '2024-01-29 17:02:30',
    },
  ]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  };
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  useEffect(() => {
    if (isLoading) {
      return true;
    }

    setIsLoading(true);
    social
      .getDataResponsble({
        idUsuario: idUsuario,
      })
      .then((res) => {
        if (res.results) {
          setData(res.response);
          setRows(res.response.Enlaces);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: 'warning' }))
      .finally(() => setIsLoading(false));
  }, [idUsuario]);

  return (
    <Container variant="outlined" sx={{ maxHeight: '845px', overflow: 'auto' }}>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : (
        <>
          <Box sx={{ p: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="h5" component="div">
                {data.Nombre}
              </Typography>
              {data.idPerfil !== 0 && (
                <Typography gutterBottom variant="h6" component="div">
                  {data.Asignacion} - {data.Segmentos}
                </Typography>
              )}
            </Stack>
            {data.idPerfil !== 0 && (
              <>
                <Typography color="text.secondary" variant="body2">
                  Asignado Por:
                </Typography>
                {middleware.checkMenuAction('Editar') ? (
                  <ResponsibleList
                    // errors={formik.errors}
                    // touched={formik.touched}
                    ticket={false}
                    user={{
                      value: data.idAsignado,
                      label: data.Asignado,
                      TieneUsuario: 1,
                      idUsuario: data.id,
                      idEstructura: data.idEstructuraSocial,
                      idPerfil: data.idPerfil,
                    }}
                    values={parseInt(data.idAsignado)}
                    // setValues={(e) => setData(...data,...e)}
                    // handleChange={formik.handleChange}
                    // finished={finished}
                  />
                ) : (
                  <Typography color="text.secondary" variant="h5">
                    {data.Asignado}
                  </Typography>
                )}
              </>
            )}
            {/*           {  middleware.checkMenuAction("Editar") ?  
            (<Grid2 container spacing={1}>
              <Grid2 item xs={1}>
              <Typography color="text.secondary" variant="body2">
                  Tipo:
              </Typography>
              </Grid2>
              <Grid2 item xs={7}>
              <Select
                placeholder="Tipo"
               // closeMenuOnSelect={false}
                styles={selectStyles}
                menuPortalTarget={document.body}
                value={data.idTipo}
              //  onChange={catalogs.tipos_usuario}
                options={catalogs.tipos_usuario}
              //  isDisabled={catalogsFiltered.poligonos.length === 1 ? true : false}
              />
              </Grid2>
              <Grid2  item xs={3}>
              <Button variant="contained" size="small" endIcon={<Icon>save</Icon>} >
              Guardar
              </Button>
              </Grid2>
              </Grid2>): 
               <Typography color="text.secondary" variant="body2">
               {`Tipo: ${data.Tipo}`}
              </Typography>
            } */}
          </Box>
          <Divider light />
          <Box sx={{ p: 1 }}>
            {rows.length > 0 && (
              <Typography gutterBottom variant="body2">
                Enlace en:
              </Typography>
            )}
            <TableContainer component={Card}>
              <Table>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                  ).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell style={{ minWidth: '50px', textAlign: 'center' }}>
                        {row.NombreSocial}
                      </TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        {middleware.checkMenuAction('Editar') ? (
                          <ResponsibleList
                            // errors={formik.errors}
                            // touched={formik.touched}
                            ticket={false}
                            user={{
                              value: row.idAsignado,
                              label: row.Asignado,
                              TieneUsuario: 1,
                              idUsuario: row.idUsuario,
                              idEstructura: row.idEstructuraSocial,
                              idPerfil: 0,
                            }}
                            values={parseInt(row.idAsignado)}
                            //setValues={(e) => setData({...data,idAsignado:e.value,Asignado:e.label})}
                            //   handleChange={(e) =>handleChange(e)}
                            // finished={finished}
                          />
                        ) : (
                          row.Asignado
                        )}
                      </TableCell>
                      <TableCell /* style={{ minWidth: "50px",textAlign:"center" }} */>
                        {row.FechaAsigno}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Container>
  );
};
export default CardInfoSocial;
