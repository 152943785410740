// material-ui
import { useParams } from 'react-router-dom';
import { Grid, Stack, Typography, FormControlLabel, Button, Box, Container, Checkbox } from '@mui/material';
import { VIGENCIA_INE, VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL } from '@data/constants';
import Swal from 'sweetalert2';
import moment from 'moment';
import avatar from '@assets/img/SIAN.png';
import { useState, useEffect, useCallback } from 'react';
import { validaINE, handleAttrs } from '@utils/Utilities';
import PersonalInformationForm from '@components/Compromisos/PersonalInformationForm';
import ContactInformationForm from '@components/Compromisos/ContactInformationForm';
import AddressForm from '@components/Compromisos/AddressForm';
import VolunteerForm from '@components/Compromisos/VolunteerForm';
import SocialForm from '@components/Compromisos/SocialForm';
import UserForm from '../../components/Compromisos/UserForm';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import { useNavigate } from 'react-router-dom';
import {
  CompromisoAddInterfacePublicRegister,
  CompromisoAddInterfaceRegister,
} from '@data/interfaces/CompromisoInterfaces';
import { CompromisoAddPublicSchema } from '@data/schemas/CompromisoSchemas';
import { isEmptyOrNullObject } from '@utils/validations';
import { useFormik } from 'formik';
import CompromisoServices from '@services/CompromisoServices';
import UserServices from '@services/UserServices';
import CatalogServices from '@services/CatalogServices';
import SendIcon from '@mui/icons-material/Send';
import { RECAPTCHA_SITE_KEY } from '@utils/global';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { URL_PUBLICA_FRONT } from '@utils/global';

// project imports
//import Logo from 'ui-component/Logo';

// assets

const Register = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const catalogsOptions = [
    { id: 'entidades' },
    { id: 'municipios' },
    { id: 'voluntarios' },
    { id: 'tipo_vialidad' },
    { id: 'mis_segmentos' },
    { id: 'partidos' },
    { id: 'medios_contacto' },
    { id: 'generos' },
  ];
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const formik = useFormik({
    initialValues: CompromisoAddInterfacePublicRegister,
    validationSchema: CompromisoAddPublicSchema,
    onSubmit: (values) => {
      onChangeCaptcha(handleAttrs(values));
    },
  });
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [load, setLoad] = useState(true);
  const [dataUser, setDataUser] = useState({});
  const [idEstructuraPadre, setIdEstructuraPadre] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    UserServices.getDataUserOwner({ Username: id })
      .then((res) => {
        if (res.results) {
          formik.handleChange({
            target: {
              name: 'idUsuarioPropietario',
              value: res.response.id,
            },
          });
          formik.handleChange({
            target: {
              name: 'idCompromisoPropietario',
              value: res.response.id,
            },
          });
          formik.handleChange({
            target: {
              name: 'idUsuarioResponsable',
              value: res.response.id,
            },
          });
          formik.handleChange({
            target: {
              name: 'idPartido',
              value: res.response.idPartido,
            },
          });

          formik.setFieldValue('idUsuario', res.response.id, false);
          formik.setFieldValue('DeviceID', res.response.DeviceID, false);

          setDataUser({
            Nombre: res.response.Nombre,
            idUsuario: res.response.id,
            DeviceID: res.response.DeviceID,
            idPartido: res.response.idPartido,
          });
          let found = res.response.ambito_perfil.find((element) => element.idTipo === 4);
          formik.setFieldValue('idEstructuraSocial', found ? found.AmbitoValor : 1, false);
          setIdEstructuraPadre(found ? found.AmbitoValor : 1);
          getCatalogs(res.response.id, res.response.DeviceID);
        } else {
          Swal.fire({
            title: 'No se encuentra el URL',
            icon: 'warning',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }))
      .finally(() => setLoad(false));

    // eslint-disable-next-line
  }, [formik.values.idUsuarioPropietario, formik.values.idCompromisoPropietario]);

  const getCatalogs = (id, celularDevice) => {
    CatalogServices.getCatalogsPublicEvents({
      catalogs: catalogsOptions,
    })
      .then((res) => {
        if (res.results) {
          setCatalogFiltered(() => {
            let newObject = {};
            for (const item of catalogsOptions) {
              newObject[item.id] = res.response.catalogs[item.id];
            }
            return newObject;
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }))
      .finally(() => setLoad(false));
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('invito')
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          handleVerification(handleAttrs(data));
        })
        .catch((error) => Swal.fire({ title: error, icon: 'warning' }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(values.idVoluntario) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: 'Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso',
            icon: 'info',
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };

  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
    if (values.isUsuario === 0) {
      Object.keys(CompromisoAddInterfaceRegister).forEach((key) => {
        data[key] = values[key] !== null ? values[key] : CompromisoAddInterfaceRegister[key];
      });
    } else {
      Object.keys(CompromisoAddInterfacePublicRegister).forEach((key) => {
        data[key] = values[key] !== null ? values[key] : CompromisoAddInterfacePublicRegister[key];
      });
    }

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format('YYYY-MM-DD'),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
      idPartido: values.idPartido ? values.idPartido : dataUser.idPartido,
    };
    delete data.TipoVialidad;
    if (values.isUsuario === 0) {
      data = {
        ...data,
        EsNuevo: 0,
        idVoluntario: data.EsVoluntario === 1 ? data.idVoluntario : null,
      };
      delete data.idUsuarioPropietario;
      delete data.idUsuarioResponsable;
      if (![VOL_RC, VOL_RG, VOL_OBSERVER].includes(data.idVoluntario)) {
        delete data.TieneAuto;
        delete data.TieneExperiencia;
        delete data.AceptaCuidarOtra;
      }
      delete data.DeviceID;

      CompromisoServices.createCompromisoPublic({
        ...data,
      })
        .then((res) => {
          if (res.success && res.results) {
            Swal.fire({
              title: res.message,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((isResponse) => {
              if (isResponse.isConfirmed) {
                clear();
                /*   if (!isNullOrUndefined(getFindCommitment))
                    navigate("/pase-lista"); */
              }
            });
          } else {
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    } else {
      delete data.isUsuario;
      data = {
        ...data,
        Username: values.Celular,
        medioConfirmacion: 'sms',
        idVoluntario: data.EsVoluntario === 1 ? data.idVoluntario : null,
      };
      delete data.idMedioContacto;
      delete data.Segmentos;
      if (![VOL_RC, VOL_RG, VOL_OBSERVER].includes(data.idVoluntario)) {
        delete data.TieneAuto;
        delete data.TieneExperiencia;
        delete data.AceptaCuidarOtra;
      }

      delete data.idEvento;
      // delete data.token;
      delete data.DeviceID;
      delete data.idUsuario;

      UserServices.createUser({
        ...data,
      })
        .then((res) => {
          if (res.success && res.results) {
            if (res.response?.codigo) {
              Swal.fire({
                backdrop: false,
                title: res.message,
                allowOutsideClick: false,
                icon: 'success',
                input: 'text',
                inputAttributes: {
                  autocapitalize: 'off',
                },
                showCancelButton: false,
                confirmButtonText: 'Confirmar',
                showLoaderOnConfirm: true,
                preConfirm: (code) => {
                  if (code === res.response.codigo) {
                    UserServices.codeValidate({
                      code: code,
                      c: res.response.UUID,
                    })
                      .then((respuesta) => {
                        if (respuesta.success) {
                          Swal.fire({
                            backdrop: false,
                            title: `Usuario Validado`,
                            icon: 'info',
                            html: '<b>Puede Ingrese en </b>, ' + `<a href="{${URL_PUBLICA_FRONT}">Sian</a> `,
                            preConfirm: (code) => {
                              navigate('/login');
                            },
                          });
                        } else {
                          Swal.showValidationMessage(`Error en la validación. contacte al administrador`);
                        }
                      })
                      .catch((e) => {
                        Swal.showValidationMessage(`Error en la validación. contacte al administrador.`);
                      });
                  } else {
                    Swal.showValidationMessage(`El codígo no coincide`);
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              });
            } else {
              Swal.fire({
                backdrop: false,
                title: res.message,
                icon: 'success',
                icon: 'info',
                html: '<b>Puede Ingrese en </b>, ' + `<a href="{${URL_PUBLICA_FRONT}">Sian</a> `,
                preConfirm: (code) => {
                  navigate('/login');
                },
              });
            }

            clear();
          } else {
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
          setIsSuccessFormSave(true);
        });
    }
  };

  const clear = () => {
    const idPropietario =
      formik.values.idUsuarioPropietario ||
      formik.values.idCompromisoPropietario ||
      formik.values.idUsuarioResponsable;

    formik.setValues({
      ...formik.values,
      Password: '',
      Confirm: '',
      PIN: '',
      idVoluntario: 1,
      INE: '',
      VigenciaINE: '',
      Nombre: '',
      Paterno: '',
      Materno: '',
      FechaNacimiento: '',
      idSexo: '',
      idEstadoNacimiento: '',
      CalleVive: '',
      TipoVialidad: 'C',
      NumExtVive: '',
      NumIntVive: '',
      idMunicipioVive: '',
      idLocalidadVive: '',
      ColoniaVive: '',
      CPVive: '',
      SeccionVota: '',
      Celular: '',
      Telefono: '',
      TelMensajes: '',
      Correo: '',
      Facebook: '',
      Instagram: '',
      Twitter: '',
      TikTok: '',
      EsVoluntario: 1,
      Segmentos: [],
      TieneAuto: '',
      TieneExperiencia: '',
      AceptaCuidarOtra: '',
    });

    formik.handleChange({
      target: {
        name: 'idUsuarioPropietario',
        value: idPropietario,
      },
    });
    formik.handleChange({
      target: {
        name: 'idCompromisoPropietario',
        value: idPropietario,
      },
    });
    formik.handleChange({
      target: {
        name: 'idUsuarioResponsable',
        value: idPropietario,
      },
    });
  };

  const handleCheck = (event) => {
    formik.handleChange({
      target: {
        name: 'isUsuario',
        value: event,
      },
    });
  };

  const handleSummit = (e) => {
    if (Object.values(formik.errors).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Revisar',
        html: `<ul>${Object.values(formik.errors)
          .map((item) => `<li>${item}</li>`)
          .join('')}</ul>`,
      });
      return true;
    }

    formik.submitForm(formik.values);
  };

  return (
    <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
      <LoadingForm
        loadinMessage={'Cargando datos...'}
        successMessage="¡Cargado con exito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            {dataUser.Nombre && (
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <Box
                    sx={{
                      bgcolor: 'background.paper',
                      pt: 8,
                      pb: 6,
                    }}
                  >
                    <Container maxWidth="lg">
                      <img
                        alt="Logo"
                        style={{ position: 'absolute', top: 10, left: 10 }}
                        src={avatar}
                        width={80}
                        height={80}
                      />
                      <Typography variant="h5" align="justify" color="#16537E" paragraph sx={{ mt: 3 }}>
                        GRACIAS POR FORMAR PARTE DE LA RED DE AMIGOS DE <strong>{dataUser.Nombre}</strong>{' '}
                        PARA DEFENDER A GUANAJUATO
                      </Typography>
                      {formik.values.isUsuario === 1 ? (
                        <Typography variant="h6" align="center" color="#16537E" gutterBottom>
                          Si no deseas crear tu cuenta de usuario para formar tu propia red de amigos que
                          defenderá a Guanajuato da clic en:{' '}
                          <strong style={{ fontFamily: 'inherit' }}>No crear cuenta de usuario</strong>.
                        </Typography>
                      ) : (
                        <Typography variant="h6" align="center" color="#16537E" gutterBottom>
                          Si deseas crear tu cuenta de usuario para formar tu propia red de amigos que
                          defenderá a Guanajuato da clic en:{' '}
                          <strong style={{ fontFamily: 'inherit' }}> Crear cuenta de usuario</strong>.
                        </Typography>
                      )}
                      <Stack sx={{ pt: 1 }} direction="row" spacing={2} justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={() => handleCheck(formik.values.isUsuario === 1 ? 0 : 1)}
                          sx={{
                            bgcolor: formik.values.isUsuario === 1 ? '#5B5B5B' : '#C90076',
                          }}
                        >
                          {formik.values.isUsuario === 1
                            ? 'NO CREAR CUENTA DE USUARIO'
                            : 'CREAR CUENTA DE USUARIO'}
                        </Button>
                        {/*        <FormControlLabel
                          color="primary"
                          value={formik.values.isUsuario === 1 ? true : false}
                          componentsProps={{
                            typography: {
                              color:
                                formik.values.isUsuario === 1
                                  ? "#C90076"
                                  : "#5B5B5B",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={
                                formik.values.isUsuario === 1 ? true : false
                              }
                              onChange={handleCheck}
                              sx={{
                                color: "#5B5B5B",
                                "&.Mui-checked": {
                                  color: pink[600],
                                },
                              }}
                            />
                          }
                          label={
                            formik.values.isUsuario === 1
                              ? "NO CREAR CUENTA DE USUARIO"
                              : "CREAR CUENTA DE USUARIO"
                          }
                        /> */}
                      </Stack>
                      {formik.values.isUsuario === 1 && (
                        <UserForm
                          catalogs={catalogsFiltered}
                          loadigCatalogs={load}
                          errors={formik.errors}
                          touched={formik.touched}
                          values={formik.values}
                          setValues={formik.setValues}
                          handleChange={formik.handleChange}
                        />
                      )}
                    </Container>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" align="left" color="#16537E" gutterBottom>
                    A continuación llena los siguientes datos:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PersonalInformationForm
                    catalogs={catalogsFiltered}
                    loadigCatalogs={load}
                    errors={formik.errors}
                    touched={formik.touched}
                    values={formik.values}
                    setValues={formik.setValues}
                    handleChange={formik.handleChange}
                    NotSimple={true}
                    validar={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContactInformationForm
                    errors={formik.errors}
                    touched={formik.touched}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressForm
                    catalogs={catalogsFiltered}
                    loadigCatalogs={load}
                    errors={formik.errors}
                    touched={formik.touched}
                    values={formik.values}
                    setValues={formik.setValues}
                    handleChange={formik.handleChange}
                    movile={true}
                    movil={dataUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VolunteerForm
                    medioContacto={false}
                    catalogs={catalogsFiltered}
                    loadigCatalogs={load}
                    errors={formik.errors}
                    touched={formik.touched}
                    values={formik.values}
                    setValues={formik.setValues}
                    handleChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {catalogsFiltered.mis_segmentos.length > 0 && (
                    <SocialForm
                      catalogs={catalogsFiltered}
                      loadigCatalogs={load}
                      values={formik.values}
                      setValues={formik.setValues}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Grid item container direction="column" alignItems="center" xs={12}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSummit}>
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        {/* <AuthFooter /> */}
      </Grid>
    </Grid>
  );
};

const recaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Register />
    </GoogleReCaptchaProvider>
  );
};
export default recaptcha;
