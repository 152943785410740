import { PrivilegedRoute, ProtectedRoute } from '@middlewares';

/* import Estructura from "@pages/Social/Structure"; */
import StructureTabs from '@pages/Social/StructureTabs';
import SocialNumerals from '@pages/Social/SocialNumerals';
import SocialCommitments from '@pages/Social/SocialCommitments';
import SocialStructure from '@pages/Social/SocialStructure';
import SocialResponsible from '../../pages/Social/SocialResponsible';
import AvanceCompromisos from '../../pages/Social/AvanceCompromisos';
import MovilizacionSocial from '../../pages/Social/Movilizacion';
import DashbordMovilizacion from '../../pages/Social/DashbordMovilizacion';
import DashbordContacto from '../../pages/Social/DashbordContacto';
import SeguimientoCompromisos from '../../pages/Social/SeguimientoCompromisos';
import MovilizacionSegmento from '../../pages/Social/MovilizacionSegmentos';

const SocialRoutes = [
  {
    path: '/estructura-social',
    element: (
      <PrivilegedRoute>
        <StructureTabs />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/social-compromisos',
    element: (
      <PrivilegedRoute>
        <SocialCommitments />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/social-numeralia',
    element: (
      <PrivilegedRoute>
        <SocialNumerals />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/social',
    element: (
      <PrivilegedRoute>
        <SocialStructure />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/social-responsible',
    element: (
      <PrivilegedRoute>
        <SocialResponsible />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/avance-compromisos',
    element: (
      <PrivilegedRoute>
        <AvanceCompromisos />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/movilizacion-social',
    element: (
      <ProtectedRoute>
        <MovilizacionSocial />
      </ProtectedRoute>
    ),
  },
  {
    path: '/movilizacion-social-dashbord',
    element: (
      <PrivilegedRoute>
        <DashbordMovilizacion />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/contacto-social-dashbord',
    element: (
      <PrivilegedRoute>
        <DashbordContacto />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/seguimiento-mis-compromisos',
    element: (
      <PrivilegedRoute>
        <SeguimientoCompromisos />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/movilizacion-segmento',
    element: (
      <PrivilegedRoute>
        <MovilizacionSegmento />
      </PrivilegedRoute>
    ),
  },
];

export default SocialRoutes;
