export const MAX_VOTO_CASILLA = 750;

//Tipos de elecciones
export const ELECCION_PRESIDENTE_REPUBLICA = 1;
export const ELECCION_SENADOR = 2;
export const ELECCION_DIPUTADO_FEDERAL = 3;
export const ELECCION_GOBERNADOR = 4;
export const ELECCION_DIPUTADO_LOCAL = 5;
export const ELECCION_PRESIDENTE_MUNICIPAL = 6;

export const ELECCIONES_GLOBALES = [ELECCION_PRESIDENTE_REPUBLICA, ELECCION_SENADOR, ELECCION_GOBERNADOR];
