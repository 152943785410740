import { useEffect, useState } from 'react';
import BasicTable from '@components/Tables/BasicTable';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import BarClick from '@components/Charts/BarClick';
import middleware from '@middlewares/middleware';
import Card from '@components/Card/Card';
import Filter from '../../components/Electoral/Filter';
import MessageAlert from '@components/Downloads/MessageAlert';
import Download from '@components/Downloads/Download';
import { useCatalogs } from '@hooks/useCatalogs';
import { useNavigate } from 'react-router-dom';
import { Container, CardContent, Box, Button, Icon, Tabs, Tab, CircularProgress } from '@mui/material';
import ListCalidadRepresentante from '../../components/Electoral/ListCalidadRepresentante';
import {
  PERFIL_RC,
  PERFIL_FiL,
  PERFIL_RG,
  PERFIL_CASA,
  PERFIL_OBSERVER,
  PERFIL_ABOGADO,
  idPropietario,
} from '@data/constants';

const StructElectoral = (props) => {
  const navigate = useNavigate();
  // catalogo
  const catalogsParams = [{ id: 'calidad_representante' }];
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [total, setTotal] = useState(0);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [dataChart, setDataChart] = useState({
    title: 'RCS',
    series: [
      {
        name: 'Municipios',
        colorByPoint: true,
        data: [
          {
            name: 'ATARJEA',
            y: 5,
            drilldown: 1,
            color: '#034498',
          },
          {
            name: 'DOCTOR MORA',
            y: 5,
            drilldown: 2,
            color: '#034498',
          },
          {
            name: 'SAN JOSÉ ITURBIDE',
            y: 9,
            drilldown: 3,
            color: '#034498',
          },
          {
            name: 'SAN LUIS DE LA PAZ',
            y: 9,
            drilldown: 4,
            color: '#034498',
          },
          {
            name: 'SANTA CATARINA',
            y: 9,
            drilldown: 5,
            color: '#034498',
          },
          {
            name: 'TIERRA BLANCA',
            y: 10,
            drilldown: 6,
            color: '#034498',
          },
          {
            name: 'VICTORIA',
            y: 33,
            drilldown: 7,
            color: '#034498',
          },
          {
            name: 'XICHÚ',
            y: 55,
            drilldown: 7,
            color: '#034498',
          },
          {
            name: 'SAN MIGUEL DE ALLENDE',
            y: 45,
            drilldown: 8,
            color: '#034498',
          },
          {
            name: 'DOLORES HIDALGO C.I.N.',
            y: 923,
            drilldown: 9,
            color: '#034498',
          },
        ],
      },
    ],
    drilldown: {
      series: [
        {
          id: 1,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 2,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 3,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 4,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 5,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 6,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 7,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 8,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
        {
          id: 9,
          data: [
            ['PROPIETARIO 1', 5],
            ['PROPIETARIO 2', 5],
            ['SUPLENTE 1', 5],
            ['SUPLENTE 2', 22],
          ],
        },
      ],
    },
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [columns, setColumns] = useState([]);
  const [state, setState] = useState(false);
  const [responsibilities, setResponsibilities] = useState([
    { label: 'RCS', value: 2 },
    { label: 'RGS', value: 3 },
    { label: 'ABOGADO', value: 8 },
    { label: 'OBSERVADORES', value: 9 },
    { label: 'PRIMERO EN FILA', value: 40 },
    { label: 'CASA AZUL', value: 15 },
  ]);

  useEffect(() => {
    if (!load) {
      handleChangeTab({}, value);
    }
  }, [load]);

  const handleChangeFilter = (type) => {
    const {
      DF,
      DL,
      Region,
      Municipio,
      Poligono,
      Seccion,
      Casillas,
      Propietario,
      Celular,
      ClaveElector,
      NombreCompleto,
      TieneAuto,
      TieneExperiencia,
      AceptaOtra,
    } = type;

    let filtered = [];
    let filtro = [];

    if (DF > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDF', filter: '=', value: DF }];

      filtro = [...filtro, { id: 'cat_secciones.idDF', filter: '=', value: DF }];
    }
    if (DL > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDL', filter: '=', value: DL }];

      filtro = [...filtro, { id: 'cat_secciones.idDL', filter: '=', value: DL }];
    }
    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filtro = [...filtro, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];

      filtro = [...filtro, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];
    }
    if (Poligono.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];

      filtro = [...filtro, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];
    }
    if (Seccion > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];

      filtro = [...filtro, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];
    }
    if (Casillas > 0) {
      filtered = [...filtered, { id: 'cat_casillas.id', filter: '=', value: Casillas }];

      filtro = [...filtro, { id: 'cat_casillas.id', filter: '=', value: Casillas }];
    }
    if (Propietario > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios_ambito_perfil.idPropietario',
          filter: '=',
          value: Propietario,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'usuarios_ambito_perfil.idPropietario',
          filter: '=',
          value: Propietario,
        },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios.Celular',
          filter: 'LIKE',
          value: Celular,
        },
      ];
    }
    if (ClaveElector.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios.INE',
          filter: '=',
          value: ClaveElector,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'],
          filter: 'LIKE',
          value: NombreCompleto,
        },
      ];
    }
    if (TieneAuto) {
      filtered = [...filtered, { id: 'usuarios_ambito_perfil.TieneAuto', filter: '=', value: 1 }];

      filtro = [...filtro, { id: 'usuarios_ambito_perfil.TieneAuto', filter: '=', value: 1 }];
    }
    if (TieneExperiencia) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios_ambito_perfil.TieneExperiencia',
          filter: '=',
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'usuarios_ambito_perfil.TieneExperiencia',
          filter: '=',
          value: 1,
        },
      ];
    }
    if (AceptaOtra) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios_ambito_perfil.AceptaCuidarOtra',
          filter: '=',
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'usuarios_ambito_perfil.AceptaCuidarOtra',
          filter: '=',
          value: 1,
        },
      ];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    // setChartCompromisos({filtered: filtro})
  };

  const handleExport = () => {
    setIsDownloading(true);
    /*     SocialService.exportCompromisos({filtered:tableData.filtered})
          .then((res) => {
            if (res.success) {
              setShowMessage(true);
              setSuccessDownload(true);
            } else {
              setShowMessage(true);
              setSuccessDownload(false);
            }
          })
          .catch((error) => {
            setShowMessage(true);
            setSuccessDownload(false);
          })
          .finally(() => setIsDownloading(false)); */
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const [data, setData] = useState([
    {
      Municipio: 'Abasolo',
      MetaGeneral: 500,
      AvanceEsperadoGeneral: 100,
      AvanceGeneral: 50,
      porcentajeAvanceGeneral: 10,
      Meta: 50,
      AvanceEsperado: 20,
      Pro1: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          20
        </Button>
      ),
      Pro2: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          210
        </Button>
      ),
      Sup1: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          20
        </Button>
      ),
      Sup2: (
        <Button variant="outlined" onClick={toggleDrawer('right', true)}>
          10
        </Button>
      ),
      'Pro1%': 20,
      'Pro2%': 20,
      'Sup1%': 20,
      'Sup2%': 20,
    },
  ]);

  const handleChangeTab = (event, newValue) => {
    setLoading(true);
    setColumns([]);
    setValue(newValue);
    let tab = responsibilities[newValue];

    switch (tab.value) {
      case PERFIL_RC:
        DataRcs();
        break;
      case PERFIL_RG:
        DataRGs();
        break;
      case PERFIL_ABOGADO:
        DataAbogados();
        break;
      case PERFIL_OBSERVER:
        DataObservadores();
        break;
      case PERFIL_CASA:
        DataCasa();
        break;
      case PERFIL_FiL:
        DataFil();
        break;
      default:
        break;
    }
  };

  const DataRcs = () => {
    let dataColums = catalogs.calidad_representante.map((item) => {
      let labelData = `${item.label.substr(0, 3)}${item.label.substr(
        item.label.length - 1,
        item.label.length - 1
      )}`;
      return { id: labelData, label: labelData };
    });
    let dataColumsPor = catalogs.calidad_representante.map((item) => {
      let labelData = `${item.label.substr(0, 3)}${item.label.substr(
        item.label.length - 1,
        item.label.length - 1
      )}`;
      return { id: `${labelData}%`, label: `${labelData}%` };
    });
    let table = [
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      {
        id: 'MetaGeneral',
        label: 'Meta General',
        width: 10,
        align: 'center',
      },
      {
        id: 'AvanceEsperadoGeneral',
        label: 'Avan. Esperado General',
        align: 'center',
      },
      { id: 'AvanceGeneral', label: 'Avan. General', align: 'center' },
      {
        id: 'porcentajeAvanceGeneral',
        label: '% Avan. General',
        align: 'center',
      },
      { id: 'Meta', label: 'Meta', align: 'center' },
      { id: 'AvanceEsperado', label: 'Avan. Esperado', align: 'center' },
    ].concat(dataColums);
    let newTable = table.concat(dataColumsPor);
    setColumns(newTable);

    let categories = catalogs.calidad_representante.map((item) => {
      return item.label;
    });

    let datosChart = [
      {
        name: 'Meta',
        color: 'rgba(98, 168, 239,0.4)',
        data: [150, 100, 200, 80],
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: 'Representantes',
        color: 'rgba(3, 15, 152,.9)',
        data: [140, 50, 200, 60],
        pointPadding: 0.4,
        pointPlacement: -0.2,
      },
    ];
    setDataChart({ ...dataChart, dato: datosChart, categories: categories });
    setLoading(false);
  };

  const DataRGs = () => {
    setColumns([
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      { id: 'Meta', label: 'Meta', width: 20, align: 'center' },
      { id: 'AvanceEsperado', label: 'Avance Esperado', width: 20, align: 'center' },
      { id: 'AvanceRG', label: 'AvanceRG', width: 20, align: 'center' },
      { id: 'PorAvanceRG', label: '%AvanceRG', width: 20, align: 'center' },
    ]);
    setLoading(false);
  };

  const DataAbogados = () => {
    setColumns([
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      { id: 'Meta', label: 'Meta', width: 20, align: 'center' },
      { id: 'AvanceEsperado', label: 'Avance Esperado', width: 20, align: 'center' },
      { id: 'Avance', label: 'Avance Abogados', width: 20, align: 'center' },
      { id: 'PorAvance', label: '%Avance Abogados', width: 20, align: 'center' },
    ]);
    setLoading(false);
  };

  const DataObservadores = () => {
    setColumns([
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      { id: 'Meta', label: 'Meta', width: 20, align: 'center' },
      { id: 'AvanceEsperado', label: 'Avance Esperado', width: 20, align: 'center' },
      { id: 'Avance', label: 'Avance Observadores', width: 20, align: 'center' },
      { id: 'PorAvance', label: '%Avance Observadores', width: 20, align: 'center' },
    ]);
    setLoading(false);
  };

  const DataFil = () => {
    setColumns([
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      { id: 'Meta', label: 'Meta', width: 20, align: 'center' },
      { id: 'AvanceEsperado', label: 'Avance Esperado', width: 20, align: 'center' },
      { id: 'Avance', label: 'Avance P.FILA', width: 20, align: 'center' },
      { id: 'PorAvance', label: '%Avance P.FILA', width: 20, align: 'center' },
    ]);
    setLoading(false);
  };

  const DataCasa = () => {
    setColumns([
      { id: 'Municipio', label: 'Municipio', width: 20, align: 'center' },
      { id: 'Meta', label: 'Meta', width: 20, align: 'center' },
      { id: 'AvanceEsperado', label: 'Avance Esperado', width: 20, align: 'center' },
      { id: 'Avance', label: 'Avance C.AZUL', width: 20, align: 'center' },
      { id: 'PorAvance', label: '%Avance C.AZUL', width: 20, align: 'center' },
    ]);
    setLoading(false);
  };

  return (
    <>
      <ListCalidadRepresentante state={state} toggleDrawer={(e) => toggleDrawer(e)} />
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader title={`Estructura`} handleclick={() => navigate(`/estructura-electoral`)} />
          {isLoadingChart ? <CircularProgress /> : <BarClick {...{ ...dataChart, loading: loading }} />}
          <Card className="card-primary">
            <CardContent>
              <Filter
                onChangeFilter={(e) => handleChangeFilter(e)}
                filterData={false}
                dl={false}
                df={false}
                region={true}
                municipio={false}
                municipioDL={true}
                poligono={false}
                seccion={false}
                tieneExperiencia={false}
                TieneAuto={false}
                AceptaOtra={false}
                filtroRepresentante={false}
              />
            </CardContent>
          </Card>

          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuActionId('Exportar') && (
                <>
                  {isDownloading ? (
                    <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
                      <Button
                        variant="outlined"
                        color="primaryDark"
                        startIcon={<Icon>download</Icon>}
                        size="small"
                        onClick={handleExport}
                        sx={{ borderRadius: '10px' }}
                      >
                        Exportar
                      </Button>
                    </Box>
                  )}
                </>
              )}

              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}
              <Box sx={{ overflow: 'auto' }}>
                <Tabs centered value={value} onChange={handleChangeTab} aria-label="responsabilitis">
                  {responsibilities.map((item) => {
                    return <Tab label={item.label} />;
                  })}
                </Tabs>
              </Box>
              <BasicTable
                rows={data}
                hcolumns={columns}
                pageProp={tableData.page}
                pageSize={tableData.pageSize}
                total={total}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                stickyHeader={true}
              />
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};
export default StructElectoral;
