import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel, a11yProps } from '@components/Maps/Varius/TabComponent';
import Create from './Create';
import Edit from './Edit';
import Search from './Search';
import { useFormik } from 'formik';
import { MapAddPolygonInterface } from '@data/interfaces/MapInterfaces';
import { MapAddPolygonSchema } from '@data/schemas/MapSchemas';
import { handleAttrs } from '@utils/Utilities';
import { isEmptyOrNullObject } from '@utils/validations';
import PolygonService from '@services/MapServices/PolygonServices';
import Swal from 'sweetalert2';
import { setVars, getVars } from '@utils/global';
import LoadingForm from '@components/LinearProgress/LoadingForm';
const PolygonsCard = ({
  selectedSections,
  selectedSectionsProperties,
  polygons,
  handleRefreshShape,
  handleGetShapePolygonEvent,
  handleSetSelectedSections,
  handleEmptyProperties,
}) => {
  const [value, setValue] = useState(getVars('valueTab', 0));
  const handleChange = (event, newValue) => {
    setVars('valueTab', newValue);
    setValue(newValue);
    if (newValue !== 2) {
      const item = getVars('itemEdit', null);
      if (item) handleGetShapePolygonEvent(item, false);
      setVars('itemEdit', null);
      const sections = [];
      handleSetSelectedSections(sections);
      setVars('selectedSections', sections);
      //setVars("flagCreatePolygon",true); //agregue comentada
    }
  };
  const formik = useFormik({
    initialValues: MapAddPolygonInterface,
    validationSchema: MapAddPolygonSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });

  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  const clear = () => {
    formik.setValues({
      Description: '',
      Sections: [],
    });
  };

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      handleSave(values);
    }
  };
  const handleRefreshShapeExect = () => {
    handleRefreshShape();
  };
  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {
      Sections: selectedSections,
      Description: values.Description ? values.Description.toUpperCase() : '',
    };
    PolygonService.setPolygon(data)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({ title: res.message, icon: 'success' }).then((isResponse) => {
            if (isResponse.isConfirmed) {
              clear();
              setVars('selectedSections', []);
              handleEmptyProperties();
            }
          });
          handleRefreshShapeExect();
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setIsSuccessFormSave(true);
      });
  };
  const handleSaveEditExect = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {
      id: values.id,
      Sections: selectedSections,
      Description: values.Description ? values.Description.toUpperCase() : '',
    };
    PolygonService.updatePolygon(data)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({ title: res.message, icon: 'success' }).then((isResponse) => {
            if (isResponse.isConfirmed) {
              clear();
              setVars('selectedSections', []);
              setVars('valueTab', 1);
              setValue(1);
              //handleGetShapePolygonEvent(item,true);
              setVars('itemEdit', null);
              const sections = []; //item.Secciones.split(",").map(item => parseInt(item));
              handleSetSelectedSections(sections);
              setVars('selectedSections', sections);
              //setVars("flagCreatePolygon",true);
            }
          });
          handleRefreshShapeExect();
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setIsSuccessFormSave(true);
      });
  };
  const handleGetShapePolygon = (item) => {
    setVars('valueTab', 2);
    setValue(2);
    handleGetShapePolygonEvent(item, true);
    setVars('itemEdit', item);
    const sections = item.Secciones.split(',').map((item) => parseInt(item));
    handleSetSelectedSections(sections);
    setVars('selectedSections', sections);
    setVars('flagCreatePolygon', true);
  };
  const handleDeleteExect = (_itemEdit) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el polígono ${_itemEdit.Poligono}?`,
      text: 'Se eliminará por completo, esta acción no se puede revertir.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, Eliminar!',
      confirmButtonColor: '#14A52E',
    }).then((result) => {
      if (result.isConfirmed) {
        PolygonService.deletePolygon({ id: _itemEdit.id })
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire('Eliminado!', '', 'success');
              handleChange({}, 1);
            } else {
              Swal.fire({ title: res.message, icon: 'warning' });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: 'warning' });
          })
          .finally(() => {
            // setIsLoading(false);
          });
      }
    });
  };
  return (
    <>
      <LoadingForm
        loadinMessage={'Cargando datos...'}
        successMessage="¡Cargado con exito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs">
          <Tab label="Crear" {...a11yProps(0)} />
          <Tab label="Buscar" {...a11yProps(1)} />
          <Tab disabled={value === 2 ? false : true} label="Editar" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <>
          <Create
            formik={formik}
            selectedSections={selectedSections}
            selectedSectionsProperties={selectedSectionsProperties}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
          />
        </>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Search handleGetShapePolygon={handleGetShapePolygon} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Edit
          selectedSections={selectedSections}
          selectedSectionsProperties={selectedSectionsProperties}
          polygons={polygons}
          handleSaveEditExect={handleSaveEditExect}
          handleDeleteExect={handleDeleteExect}
        ></Edit>
      </TabPanel>
    </>
  );
};
export default PolygonsCard;
