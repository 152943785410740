import ApiExec from '@utils/ApiExec';
import { encryptVars, DEBUG } from '@utils/global';
import { handleAttrs } from '@utils/Utilities';

class VolunteerServices {
  getList = (params) => {
    return ApiExec(params, 'volunteers/get', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  findById = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `volunteers/find/${_id}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsibilities = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `volunteers/get-responsibilities/${_id}`, 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createResponsibility = (params) => {
    return ApiExec(handleAttrs(params), 'volunteers/create-responsibility', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteResponsibility = (params) => {
    return ApiExec(params, 'volunteers/delete-responsibility', 'DELETE')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const volunteer = new VolunteerServices();
export default volunteer;
