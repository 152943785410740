import React from 'react';

import { Avatar, Box, LinearProgress, Stack, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';

import icon_pdf from '@assets/img/_pdf.svg';
import icon_excel from '@assets/img/excel.svg';

const Download = ({ format, isDownload }) => {
  return (
    <Box
      sx={{
        bgcolor: format === 'pdf' ? red[50] : green[50],
        borderRadius: '6px',
      }}
    >
      <Stack direction="row" spacing marginTop={2} padding={1}>
        <Avatar
          src={format === 'pdf' ? icon_pdf : icon_excel}
          variant="rounded"
          sx={{ width: 60, height: 60 }}
        />
        {isDownload && (
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{
                color: format === 'pdf' ? red[900] : green[900],
                marginTop: '24px ',
              }}
            >
              Descargando el archivo...
            </Typography>
            <LinearProgress color={format === 'pdf' ? 'error' : 'success'} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Download;
