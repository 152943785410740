import { useEffect, useState } from 'react';
import BasicTable from '@components/Tables/BasicTable';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { isNullOrUndefined } from '../../utils/validations';
import electoral from '@services/ElectoralServices';
import moment from 'moment';
import PlacementColumns from '@components/Charts/DashChart';
import middleware from '@middlewares/middleware';
import Card from '@components/Card/Card';
import Filter from '../../components/Electoral/Filter';
import MessageAlert from '@components/Downloads/MessageAlert';
import Download from '@components/Downloads/Download';
import { useCatalogs } from '@hooks/useCatalogs';
import { useNavigate } from 'react-router-dom';
import { Container, CardContent, Box, Button, Icon, CircularProgress, Badge } from '@mui/material';
import ProfileRepresentante from '../../components/Electoral/ProfileRepresentante';
import AssignRepresentative from '../../components/Electoral/AssignRepresentative';
import { isEmptyOrNullObject } from '@utils/validations';
import Swal from 'sweetalert2';

const AvanceCasillas = (props) => {
  const navigate = useNavigate();
  let today = new Date();
  // catalogo
  const catalogsParams = [{ id: 'calidad_representante' }];
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [statsData, setStatsData] = useState({
    page: 0,
    pageSize: 99999999999999,
    filtered: [],
  });
  const [loadingStats, setLoadingStats] = useState(false);
  const [dataTotales, setDataTotales] = useState({
    responsable: {
      Meta: 0,
      TotalAvance: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
    voluntarios: {
      asignados: 0,
      voluntarios: 0,
      FechaActualizo: '2023-01-11 16:00:19',
    },
  });
  const [total, setTotal] = useState(0);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [dataChart, setDataChart] = useState({
    title: 'Estructura de Casilla',
    subtitle: today,
    dato: [],
    categories: [],
  });
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [open, setOpen] = useState(false);
  const [openAssing, setOpenAssing] = useState(false);
  const [dataCasilla, setDataCasilla] = useState({});

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData,
      };

      setTableData(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    getData();
  }, [tableData]);

  useEffect(() => {
    if (!load) {
      let dataColums = catalogs.calidad_representante.map((item) => {
        return { id: item.label, label: item.label };
      });
      let table = [
        { id: 'Municipio', label: 'Municipio' },
        { id: 'Seccion', label: 'Sección' },
        { id: 'NombreCasilla', label: 'Casilla' },
      ].concat(dataColums);
      setColumns(table);

      const dataOrdenada = catalogs.calidad_representante.sort((a, b) => a.value - b.value);
      let categories = dataOrdenada.map((item) => {
        return item.label;
      });

      let meta = [];
      let avance = [];

      setLoadingStats(true);

      electoral
        .getStatsRs(statsData)
        .then((res) => {
          if (res.results) {
            dataOrdenada.forEach((element) => {
              let datoRepresentante = res.response.data.representantes.find(
                (item) => item.Tipo === element.value
              )?.TotalGeneral;
              meta.push(
                res.response.data.rc.TotalMeta
                  ? res.response.data.rc.TotalMeta / catalogs.calidad_representante.length
                  : 0
              );
              avance.push(datoRepresentante ? datoRepresentante : 0);
            });
          } else {
            Swal.fire({ title: res.message, icon: 'warning' });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => {
          setLoadingStats(false);
        });

      let datosChart = [
        {
          name: 'Meta',
          color: 'rgba(98, 168, 239,0.4)',
          data: meta,
          pointPadding: 0.3,
          pointPlacement: -0.2,
        },
        {
          name: 'Representantes',
          color: 'rgba(3, 15, 152,.9)',
          data: avance,
          pointPadding: 0.4,
          pointPlacement: -0.2,
        },
      ];
      setDataChart({ ...dataChart, dato: datosChart, categories: categories });
    }
  }, [load]);

  const getData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getAvanceCasillla(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                'Propietario 1': middleware.checkMenuAction('Agregar') ? (
                  item.Tipo1 !== 0 && !isNullOrUndefined(item.Tipo1) ? (
                    <Badge badgeContent={item.Tipo1} color="primary">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          onCLickResponsables({ ...item, Tipo: 'Propietario 1', idPropietario: 1 })
                        }
                      >
                        Ver Responsables
                      </Button>
                    </Badge>
                  ) : middleware.checkMenuAction('Editar') ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ bgcolor: '#f73378' }}
                      onClick={() => OnClickHandle({ ...item, Tipo: 'Propietario 1', idPropietario: 1 })}
                      endIcon={<Icon>group_add</Icon>}
                    >
                      Asignar
                    </Button>
                  ) : item.Tipo1 !== 0 ? (
                    item.Tipo1
                  ) : (
                    ''
                  )
                ) : item.Tipo1 !== 0 ? (
                  item.Tipo1
                ) : (
                  ''
                ),
                'Suplente 1': middleware.checkMenuAction('Agregar') ? (
                  item.Tipo2 !== 0 && !isNullOrUndefined(item.Tipo2) ? (
                    <Badge badgeContent={item.Tipo2} color="primary">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onCLickResponsables({ ...item, Tipo: 'Suplente 1', idPropietario: 2 })}
                      >
                        Ver Responsables
                      </Button>
                    </Badge>
                  ) : middleware.checkMenuAction('Editar') ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ bgcolor: '#f73378' }}
                      onClick={() => OnClickHandle({ ...item, Tipo: 'Suplente 1', idPropietario: 2 })}
                      endIcon={<Icon>group_add</Icon>}
                    >
                      Asignar
                    </Button>
                  ) : item.Tipo2 !== 0 ? (
                    item.Tipo2
                  ) : (
                    ''
                  )
                ) : item.Tipo2 !== 0 ? (
                  item.Tipo2
                ) : (
                  ''
                ),
                'Propietario 2': middleware.checkMenuAction('Agregar') ? (
                  item.Tipo3 !== 0 && !isNullOrUndefined(item.Tipo3) ? (
                    <Badge badgeContent={item.Tipo3} color="primary">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          onCLickResponsables({ ...item, Tipo: 'Propietario 2', idPropietario: 3 })
                        }
                      >
                        Ver Responsables
                      </Button>
                    </Badge>
                  ) : middleware.checkMenuAction('Editar') ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ bgcolor: '#f73378' }}
                      onClick={() => OnClickHandle({ ...item, Tipo: 'Propietario 2', idPropietario: 3 })}
                      endIcon={<Icon>group_add</Icon>}
                    >
                      Asignar
                    </Button>
                  ) : item.Tipo3 !== 0 ? (
                    item.Tipo3
                  ) : (
                    ''
                  )
                ) : item.Tipo3 !== 0 ? (
                  item.Tipo3
                ) : (
                  ''
                ),
                'Suplente 2': middleware.checkMenuAction('Agregar') ? (
                  item.Tipo4 !== 0 && !isNullOrUndefined(item.Tipo4) ? (
                    <Badge badgeContent={item.Tipo4} color="primary">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onCLickResponsables({ ...item, Tipo: 'Suplente 2', idPropietario: 4 })}
                      >
                        Ver Responsables
                      </Button>
                    </Badge>
                  ) : middleware.checkMenuAction('Editar') ? (
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#f73378' }}
                      onClick={() => OnClickHandle({ ...item, Tipo: 'Suplente 2', idPropietario: 4 })}
                      size="small"
                      endIcon={<Icon>group_add</Icon>}
                    >
                      Asignar
                    </Button>
                  ) : item.Tipo4 !== 0 ? (
                    item.Tipo4
                  ) : (
                    ''
                  )
                ) : item.Tipo4 !== 0 ? (
                  item.Tipo4
                ) : (
                  ''
                ),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const OnClickHandle = (info) => {
    setOpenAssing(true);
    setDataCasilla({ ...info, tipo: info.Tipo, casilla: info.NombreCasilla, seccion: info.Seccion });
  };

  const onCLickResponsables = (info) => {
    setOpen(true);
    setDataCasilla({ ...info, tipo: info.Tipo, casilla: info.NombreCasilla, seccion: info.Seccion });
  };

  const handleChangeFilter = (type) => {
    const {
      DF,
      DL,
      Region,
      Municipio,
      MunicipioDL,
      Poligono,
      Seccion,
      Casillas,
      Propietario,
      Celular,
      ClaveElector,
      NombreCompleto,
      TieneAuto,
      TieneExperiencia,
      AceptaOtra,
      P1,
      P2,
      S1,
      S2,
    } = type;

    let filtered = [];
    let filtro = [];

    if (DF > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDF', filter: '=', value: DF }];

      filtro = [...filtro, { id: 'cat_secciones.idDF', filter: '=', value: DF }];
    }
    if (DL > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idDL', filter: '=', value: DL }];

      filtro = [...filtro, { id: 'cat_secciones.idDL', filter: '=', value: DL }];
    }
    if (Region > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];

      filtro = [...filtro, { id: 'cat_secciones.idRegion', filter: '=', value: Region }];
    }
    if (Municipio > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];

      filtro = [...filtro, { id: 'cat_secciones.idMunicipio', filter: '=', value: Municipio }];
    }
    if (MunicipioDL > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idMunicipioReportes', filter: '=', value: MunicipioDL }];

      filtro = [...filtro, { id: 'cat_secciones.idMunicipioReportes', filter: '=', value: MunicipioDL }];
    }
    if (Poligono.length > 0) {
      filtered = [...filtered, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];

      filtro = [...filtro, { id: 'cat_secciones.idPoligono', filter: 'IN', value: Poligono }];
    }
    if (Seccion > 0) {
      filtered = [...filtered, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];

      filtro = [...filtro, { id: 'cat_secciones.Seccion', filter: '=', value: Seccion }];
    }
    if (Casillas > 0) {
      filtered = [...filtered, { id: 'cat_casillas.id', filter: '=', value: Casillas }];

      filtro = [...filtro, { id: 'cat_casillas.id', filter: '=', value: Casillas }];
    }
    if (P1 > 0) {
      if (P1 === 2) {
        filtered = [
          ...filtered,
          {
            id: 'prop1.Total',
            filter: 'IS NOT',
            value: 'NULL',
          },
        ];
      } else if (P1 === 1) {
        filtered = [
          ...filtered,
          {
            id: 'prop1.Total',
            filter: 'IS',
            value: 'NULL',
          },
        ];
      } else {
        {
          filtered = [
            ...filtered,
            {
              id: 'prop1.Total',
              filter: '>',
              value: '1',
            },
          ];
        }
      }
    }
    if (P2 > 0) {
      if (P2 === 2) {
        filtered = [
          ...filtered,
          {
            id: 'prop3.Total',
            filter: 'IS NOT',
            value: 'NULL',
          },
        ];
      } else if (P2 === 1) {
        filtered = [
          ...filtered,
          {
            id: 'prop3.Total',
            filter: 'IS',
            value: 'NULL',
          },
        ];
      } else {
        {
          filtered = [
            ...filtered,
            {
              id: 'prop3.Total',
              filter: '>',
              value: '1',
            },
          ];
        }
      }
    }
    if (S1 > 0) {
      if (S1 === 2) {
        filtered = [
          ...filtered,
          {
            id: 'prop2.Total',
            filter: 'IS NOT',
            value: 'NULL',
          },
        ];
      } else if (S1 === 1) {
        filtered = [
          ...filtered,
          {
            id: 'prop2.Total',
            filter: 'IS',
            value: 'NULL',
          },
        ];
      } else {
        filtered = [
          ...filtered,
          {
            id: 'prop2.Total',
            filter: '>',
            value: '1',
          },
        ];
      }
    }
    if (S2 > 0) {
      if (S2 === 2) {
        filtered = [
          ...filtered,
          {
            id: 'prop4.Total',
            filter: 'IS NOT',
            value: 'NULL',
          },
        ];
      } else if (S2 === 1) {
        filtered = [
          ...filtered,
          {
            id: 'prop4.Total',
            filter: 'IS',
            value: 'NULL',
          },
        ];
      } else {
        filtered = [
          ...filtered,
          {
            id: 'prop4.Total',
            filter: '>',
            value: '1',
          },
        ];
      }
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios.Celular',
          filter: 'LIKE',
          value: Celular,
        },
      ];
    }
    if (ClaveElector.length > 0) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios.INE',
          filter: '=',
          value: ClaveElector,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: ['usuarios.Nombre', 'usuarios.Paterno', 'usuarios.Materno'],
          filter: 'LIKE',
          value: NombreCompleto,
        },
      ];
    }
    if (TieneAuto) {
      filtered = [...filtered, { id: 'usuarios_ambito_perfil.TieneAuto', filter: '=', value: 1 }];

      filtro = [...filtro, { id: 'usuarios_ambito_perfil.TieneAuto', filter: '=', value: 1 }];
    }
    if (TieneExperiencia) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios_ambito_perfil.TieneExperiencia',
          filter: '=',
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'usuarios_ambito_perfil.TieneExperiencia',
          filter: '=',
          value: 1,
        },
      ];
    }
    if (AceptaOtra) {
      filtered = [
        ...filtered,
        {
          id: 'usuarios_ambito_perfil.AceptaCuidarOtra',
          filter: '=',
          value: 1,
        },
      ];

      filtro = [
        ...filtro,
        {
          id: 'usuarios_ambito_perfil.AceptaCuidarOtra',
          filter: '=',
          value: 1,
        },
      ];
    }

    setTableData({
      ...tableData,
      page: 0,
      filtered: filtered,
    });

    // setChartCompromisos({filtered: filtro})
  };

  const handleExport = () => {
    if (isDownloading) {
      return true;
    }
    setIsDownloading(true);
    electoral
      .getAvanceCasillla({ ...tableData, export: true, page: 0, pageSize: 99999999999999 })
      .then((res) => {
        if (res.results) {
          Swal.fire({ title: res.message, icon: 'success' });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const setOpenModal = (e) => {
    getData();
    setOpenAssing(e);
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: 'cat_municipios.Municipio',
      Seccion: 'cat_casillas.Seccion',
      NombreCasilla: 'cat_casillas.NombreCasilla',
      'Propietario 1': 'prop1.idPropietario = 1',
      'Propietario 2': 'prop1.idPropietario = 3',
      'Suplente 1': 'prop1.idPropietario = 2',
      'Suplente 2': 'prop1.idPropietario = 4',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader title={`Avance Casillas`} handleclick={() => navigate(`/AvanceCasillas`)} />
          {openAssing && (
            <AssignRepresentative open={openAssing} title={dataCasilla} setOpen={(e) => setOpenModal(e)} />
          )}
          {open && <ProfileRepresentante open={open} setOpen={(e) => setOpen(e)} title={dataCasilla} />}
          {isLoadingChart ? (
            <CircularProgress />
          ) : (
            <PlacementColumns
              titulo={{ title: dataChart.title }}
              subtitle={{
                subtitle: moment(dataChart.date).format('DD/MM/YYYY H:mm'),
              }}
              datos={{
                categories: dataChart.categories,
                series: dataChart.dato,
              }}
              loading={isLoadingChart}
            />
          )}
          <Card className="card-primary">
            <CardContent>
              <Filter
                onChangeFilter={(e) => handleChangeFilter(e)}
                filterData={false}
                dl={false}
                df={false}
                region={true}
                municipio={false}
                municipioDL={true}
                poligono={false}
                seccion={true}
                tieneExperiencia={false}
                TieneAuto={false}
                AceptaOtra={false}
                filtroRepresentante={true}
              />
            </CardContent>
          </Card>

          <Card className="card-primary">
            <CardContent>
              {middleware.checkMenuActionId('Exportar') && (
                <>
                  {isDownloading ? (
                    <Download isDownload={isDownloading} format="xlsx" />
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
                      <Button
                        variant="outlined"
                        color="success"
                        startIcon={<Icon>download</Icon>}
                        size="small"
                        onClick={handleExport}
                        sx={{ borderRadius: '10px' }}
                      >
                        Exportar
                      </Button>
                    </Box>
                  )}
                </>
              )}

              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}

              <BasicTable
                rows={data}
                hcolumns={columns}
                pageProp={tableData.page}
                pageSize={tableData.pageSize}
                total={total}
                handleChangePagination={handleChangePagination}
                isLoading={loading}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Container>
      </AdminLayout>
    </>
  );
};
export default AvanceCasillas;
