import { useState } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Grid, Button, TextField } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomTable from '@components/Tables/CustomTable';

// Servicios
import ExamplesServices from '@services/_ExamplesServices';
import { convertBase64, setDiynamicColumns } from '@utils/Utilities';
import { isTypePhone } from '@utils/validations';

const Pruebas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Subiendo datos...');

  const [idFile, setIdFile] = useState(35);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const columns_base = [
    { id: 'registro', label: 'Registro', type: 'number', align: 'right' },
    { id: 'dato', label: 'Dato' },
    { id: 'observaciones', label: 'Observaciones' },
  ];

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const type = e.target.name;

    const allowedTypes = [
      'text/plain',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    try {
      if (!allowedTypes.includes(file.type)) throw new Error('Formato no es compatible.');

      setIsLoading(true);
      setLoadingMessage(`Subiendo ${type}...`);
      const base64 = await convertBase64(file);

      const result = await ExamplesServices.uploadFile({ type: type, idFile: idFile, file: base64 });
      const { results, response, message } = result;

      if (results) {
        setRows(response.data);
        setColumns(setDiynamicColumns({ data: response.data[0], columns: columns_base }));

        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (error) {
      e.target.value = null;
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
      e.target.value = null;
    }
  };

  const handleText = (e) => {
    const value = e.target.value;
    if (isTypePhone(value)) setIdFile(value);
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={loadingMessage}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <ActionHeader title="Botones" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField variant="outlined" label="id TXT" value={idFile} onChange={handleText} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <label htmlFor="upload-excel">
            <input
              style={{ display: 'none' }}
              id="upload-excel"
              name="excel"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              disabled={!idFile || idFile === '0'}
            />
            <Button variant="outlined" fullWidth component="span" disabled={!idFile || idFile === '0'}>
              Subir Excel
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="upload-txt">
            <input
              style={{ display: 'none' }}
              id="upload-txt"
              name="txt"
              type="file"
              accept=".txt"
              onChange={handleFileUpload}
              disabled={!idFile || idFile === '0'}
            />
            <Button variant="outlined" fullWidth component="span" disabled={!idFile || idFile === '0'}>
              Subir TXT
            </Button>
          </label>
        </Grid>

        {Boolean(columns.length) && (
          <Grid item xs={12}>
            <CustomTable
              rows={rows ?? []}
              columns={columns}
              stickyHeader
              maxHeight={500}
              disableCardType
              isLoading={isLoading}
            />
          </Grid>
        )}
      </Grid>
    </AdminLayout>
  );
};

export default Pruebas;
