import ApiExec from '@utils/ApiExec';
import { handleAttrs } from '@utils/Utilities';

class ResultServices {
  getTable = (params) => {
    return ApiExec(handleAttrs(params), 'result/get-table', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResult = (params) => {
    return ApiExec(handleAttrs(params), 'result/get-result', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsable = (params) => {
    return ApiExec(handleAttrs(params), 'result/get-responsables', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const result = new ResultServices();
export default result;
