import React, { useEffect } from 'react';
import {
  Container,
  Button,
  Icon,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import Yup from '@utils/Yupi18n';

//SERVICES
import SupportService from '@services/SupportServices';
//ASSETS
import PAN from '@assets/img/PAN_logo.png';
import PRI from '@assets/img/PRI_logo.png';
import PRD from '@assets/img/PRD_logo.png';
import { useState } from 'react';

const validationSchema = Yup.object().shape({
  idPartido: Yup.number().oneOf([0, 1, 2, 3]).required('Seleccione una opción.'),
});

const UserStatus = (props) => {
  const { idUsuario, setLoading } = props;
  const [partidoID, setIdPartdio] = useState('');

  const formik = useFormik({
    initialValues: {
      idUsuario: '',
      idPartido: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      validParams(values);
    },
  });

  const validParams = (values) => {
    if (values.idPartido === partidoID) {
      Swal.fire({
        title: 'Registro previo',
        text: 'Usted ya se encuentra registrado en este partido. No es posible efectuar un registro duplicado en el mismo evento.',
        icon: 'info',
      });
    } else {
      handleSave(values);
    }
  };

  const handleSave = async (data) => {
    setLoading(true);

    try {
      const result = await SupportService.saveParty(data);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCleanForm = (e) => {
    formik.resetForm();
  };

  useEffect(() => {
    if (idUsuario.id) {
      formik.setValues((prev) => ({
        ...prev,
        idUsuario: idUsuario.id,
        idPartido: idUsuario.idPartido,
      }));
      setIdPartdio(idUsuario.idPartido);
    }
  }, [idUsuario]);

  const partyAvatars = {
    0: <Avatar>S</Avatar>,
    1: <Avatar src={PAN} alt="PAN" />,
    2: <Avatar src={PRI} alt="PRI" />,
    3: <Avatar src={PRD} alt="PRD" />,
  };

  return (
    <Container sx={{ ml: 4, mb: 4, mt: 4 }}>
      <Grid item xs={12} sm={12} sx={{ mr: 4 }}>
        <Typography variant="h6" mb={3} gutterBottom>
          <strong>Seleccione una de las siguientes opciones:</strong>
        </Typography>

        <RadioGroup
          aria-label="Partido"
          name="Partido"
          value={formik.values.idPartido}
          onChange={(e) => {
            formik.setValues((prev) => ({ ...prev, idPartido: parseInt(e.target.value) }));
          }}
          sx={{ flexDirection: 'row' }}
        >
          <FormControlLabel value={0} control={<Radio />} label="Sin partido" />
          <FormControlLabel value={1} control={<Radio />} label={partyAvatars[1]} />
          <FormControlLabel value={2} control={<Radio />} label={partyAvatars[2]} />
          <FormControlLabel value={3} control={<Radio />} label={partyAvatars[3]} />
        </RadioGroup>
        {formik.errors.idPartido ? (
          <Typography variant="body2" color="error">
            {formik.errors.idPartido}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={12} sx={{ mt: '5vh', mr: 4, textAlign: 'end' }}>
        <Button variant="outlined" size="small" onClick={handleCleanForm} startIcon={<Icon>cancel</Icon>}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          onClick={formik.submitForm}
          disabled={!idUsuario}
          startIcon={<Icon>save</Icon>}
          sx={{ ml: 1 }}
        >
          Guardar
        </Button>
      </Grid>
    </Container>
  );
};

export default UserStatus;
