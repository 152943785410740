import ApiExec, { downloadFile } from '@utils/ApiExec';

class CDEService {
  getTotales = (params) => {
    return ApiExec(params, 'cde/total-mecanismos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMecanismos = (params) => {
    return ApiExec(params, 'cde/get-mecanismos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportMecanismos = (params) => {
    return downloadFile({
      api: 'cde/export-mecanismos',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };
}

const cde = new CDEService();
export default cde;
