import { useEffect } from 'react';

import { useMap } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import '@components/Maps/Styles/GeoSearch.css';

const SearchField = ({ handleCoordsEvent }) => {
  const handleCoords = (e) => handleCoordsEvent(e);

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    style: 'bar',
    showMarker: false,
    showPopup: false,
    searchLabel: '- Busca tu direccion aquí!',
    notFoundMessage: 'No se encontró esa dirección',
    popupFormat: ({ query, result }) => {
      if (result.x !== undefined && result.y !== undefined) {
        handleCoords({
          latitud: parseFloat(result.y),
          longitud: parseFloat(result.x),
        });
      }
      return result.label;
    },
    resultFormat: ({ result }) => result.label,
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl); // eslint-disable-next-line
  }, []);

  return null;
};

export default SearchField;
