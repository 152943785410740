import { useState, useEffect } from 'react';
// import PropTypes from "prop-types";
import Swal from 'sweetalert2';

import { Box, Card, CardContent, Icon, Stack, Typography, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import AutoComplete from '@components/Selects/Autocomplete';
import InputSelect from '@components/Selects/BasicSelect';

import CatalogServices from '@services/CatalogServices';

const PartiesFilter = (props) => {
  const { onChangeFilter } = props;

  const catPartyTypes = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: 'ÚNICOS' },
    { value: 2, label: 'COALICIÓN' },
    { value: 3, label: 'INDEPENDIENTE' },
  ];

  const [loading, setLoading] = useState(true);
  const [catParties, setCatParties] = useState([]);
  const [filterData, setFilterData] = useState({
    idTipoPartido: 0,
    idPartido: '',
    Partido: '',
  });

  useEffect(() => {
    getParties();
    //eslint-disable-next-line
  }, []);

  const mapParties = (parties) => {
    return parties.map((item) => {
      if (item.label.trim().toUpperCase() === item.Partido.trim().toUpperCase()) {
        return item;
      }

      return { ...item, label: `${item.Partido} (${item.label})` };
    });
  };

  const getParties = () => {
    setLoading(true);
    const params = [{ id: 'partidos' }];
    CatalogServices.getCatalogs(params)
      .then((res) => {
        if (res.results) {
          if (res.response.catalogs && res.response.catalogs.partidos) {
            setCatParties(mapParties(res.response.catalogs.partidos));
          }
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => {
        Swal.fire({ title: error, icon: 'warining' });
      })
      .finally(() => setLoading(false));
  };

  const handleSelected = (e) => {
    if (e.value === 'string') {
      if (e.label === '') {
        handleApplyFilter({
          ...filterData,
          idPartido: '',
          Partido: '',
        });
      }
      setFilterData({
        ...filterData,
        idPartido: '',
        Partido: '',
      });
    } else {
      setFilterData({
        ...filterData,
        idPartido: e.value,
        Partido: e.label,
      });
    }
  };

  const handleChangePartyType = (value) => {
    setFilterData({
      idTipoPartido: value,
      idPartido: '',
      Partido: '',
    });
  };

  const handleClearFilter = () => {
    setFilterData({
      idTipoPartido: 0,
      idPartido: '',
      Partido: '',
    });

    onChangeFilter([]);
  };

  const handleApplyFilter = (filterData) => {
    let filtered = [];

    switch (filterData.idTipoPartido) {
      case 1:
        filtered.push({
          id: 'cat_partido_electoral.EsCoalicion',
          filter: '=',
          value: 0,
        });
        break;
      case 2:
        filtered.push({
          id: 'cat_partido_electoral.EsCoalicion',
          filter: '=',
          value: 1,
        });
        break;
      case 3:
        filtered.push({
          id: 'cat_partido_electoral.EsIndependiente',
          filter: '=',
          value: 1,
        });
        break;
      default:
        break;
    }

    if (filterData.idPartido !== '') {
      filtered.push({
        id: 'cat_partido_electoral.id',
        filter: '=',
        value: filterData.idPartido,
      });
    }

    onChangeFilter(filtered);
  };

  return (
    <Box component={Card} className="card-primary">
      <Box component={CardContent}>
        <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
          <Icon>filter_list</Icon>
          <Typography variant="body2" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>

        <Grid2 container spacing={2} m={1}>
          <Grid2 xs={12} md={3}>
            <InputSelect
              label="Tipo de partido"
              options={catPartyTypes}
              name="idTipoPartido"
              value={filterData.idTipoPartido}
              onChange={(e) => handleChangePartyType(e.target.value)}
              sx={{ width: '100%' }}
            />
          </Grid2>
          <Grid2 xs={12} md={5}>
            <AutoComplete
              label="Partido"
              name="Partido"
              autoComplete="Partido"
              value={filterData.Partido}
              id="Partido"
              size="small"
              disableUnderline={false}
              setSelect={(e) => handleSelected(e)}
              data={catParties}
              // onChange={(e) => handleSelected(e)}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} md={2}>
            <Button
              variant="outlined"
              color="primaryDark"
              onClick={handleClearFilter}
              sx={{ ml: 1 }}
              fullWidth
            >
              Limpiar
            </Button>
          </Grid2>
          <Grid2 xs={12} md={2}>
            <Button
              variant="contained"
              color="primaryDark"
              onClick={(e) => handleApplyFilter(filterData)}
              fullWidth
            >
              Filtrar
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default PartiesFilter;
