import { useState } from 'react';

// Material UI
import { Card, CardHeader, IconButton } from '@mui/material';
import { RemoveCircleOutline, DashboardCustomizeOutlined } from '@mui/icons-material';

const style_square = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
};

const CardInfo = ({ component, title }) => {
  const [showCard, setShowCard] = useState(true);

  return showCard ? (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control" style={style_square}>
          <Card className="card-secondary">
            <CardHeader
              title={title}
              style={{ textAlign: 'center', padding: '2px' }}
              subheader={''}
              titleTypographyProps={{ /* variant: "h6" */ fontSize: '16px' }}
              action={
                <IconButton
                  onClick={() => {
                    setShowCard(false);
                  }}
                  aria-label="Minimizar"
                >
                  <RemoveCircleOutline />
                </IconButton>
              }
            />
            {component}
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-bar leaflet-control">
          <IconButton
            aria-label="Abrir componente de opciones"
            onClick={() => setShowCard(true)}
            style={{ backgroundColor: 'white' }}
            sx={{
              height: '30px',
              width: '30px',
              borderRadius: '3px',
              '&:hover': { backgroundColor: '#eeeeee!important' },
            }}
          >
            <DashboardCustomizeOutlined sx={{ fontSize: 25, fontWeight: 200, color: '#000' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default CardInfo;
