import { Box, Card } from '@mui/material';

const CardOption = ({ icon, handleClick }) => {
  return (
    <Box
      component={Card}
      className="card-primary"
      sx={{
        height: '100%',
        maxHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#eee',
      }}
      onClick={handleClick}
    >
      <Box
        component={'img'}
        src={icon}
        width={150}
        height={'80%'}
        alt="ico_dia_d"
        sx={{ objectFit: 'cover' }}
      />
    </Box>
  );
};

export default CardOption;
