//Librerías
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Icon, Card, CardContent, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

//Servicios y Data
import * as Validate from '@data/schemas/UserSchemas';

//Componentes
import UserTable from '@components/Users/UsersTable';
import { limpiarTelefono } from '@utils/global';

const UsersSearch = React.forwardRef((props, ref) => {
  const { idUsuario, nombreCompleto, infoAsignacion, API, clickCard, setUserData = () => {} } = props;
  const [searchData, setSearchData] = useState({
    nombre: '',
    numeroCelular: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setdata] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [allowPageSizeUpdate, setAllowPageSizeUpdate] = useState(true);
  const phoneRegex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

  React.useImperativeHandle(ref, () => ({
    handleLimpiarClick,
  }));
  const handleSearch = async () => {
    setHasMore(true);
    const filtered = [];

    if (searchData.nombre) {
      filtered.push({
        id: ['Nombre', 'Paterno', 'Materno'],
        filter: 'LIKE',
        value: searchData.nombre,
      });
    }
    if (searchData.numeroCelular) {
      filtered.push({
        id: 'Username',
        filter: '=',
        value: searchData.numeroCelular,
      });
    }

    try {
      const result = await API({ page, pageSize: 10, filtered });
      const { results, message, response } = result;
      if (results) {
        setdata((prevData) => [...prevData, ...response.data]);
        if (response.total !== 0) {
          setTotal(response.total);
        }
      } else {
        idUsuario('');
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      idUsuario('');
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: error.message,
        icon: 'warning',
      });
    } finally {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (page !== 0 && data.length !== total) {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [page]);

  const handleValidat = () => {
    if (!searchData.nombre && !phoneRegex.test(searchData.numeroCelular)) {
      setErrorMessage('Debe ingresar al menos un criterio de búsqueda.');
    } else {
      setPage(0);
      setErrorMessage('');
      setdata([]);
      setTotal(0);
      setHasMore(false);
      handleSearch();
    }
  };
  const handleLimpiarClick = () => {
    setPage(0);
    setSearchData({
      nombre: '',
      numeroCelular: '',
    });
    setErrorMessage('');
    setdata([]);
    idUsuario('');
    nombreCompleto('');
    setTotal(0);
    setHasMore(false);
    setAllowPageSizeUpdate(false);
    setTimeout(() => {
      setAllowPageSizeUpdate(true);
    }, 500);
  };
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleValidat();
    }
  };
  const debounceSearch = debounce(handleValidat, 1000);
  return (
    <Box display="flex">
      <Card variant="outlined" sx={{ borderRadius: 2, width: '100%' }}>
        <CardContent>
          <Typography variant="h6">Encuentra y elige al usuario para la acción a realizar</Typography>

          {infoAsignacion ? (
            <Alert variant="outlined" severity="info" sx={{ mt: 2, borderColor: 'info.main' }}>
              Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de
              celular.
            </Alert>
          ) : (
            <Alert variant="outlined" severity="info" sx={{ mt: 2, borderColor: 'info.main' }}>
              Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de
              celular.
            </Alert>
          )}

          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1} mt={2}>
            <TextField
              label="Nombre"
              value={searchData.nombre}
              onChange={(e) => setSearchData({ ...searchData, nombre: e.target.value })}
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              onKeyPress={(e) => {
                Validate.handleKeyPress2(e);
                handleEnterPress(e);
              }}
              sx={{
                marginBottom: { xs: 1, sm: 0 },
                marginRight: { xs: 0, sm: 1 },
              }}
            />
            <TextField
              label="Número de Celular"
              value={searchData.numeroCelular}
              onChange={(e) =>
                setSearchData({
                  ...searchData,
                  numeroCelular: limpiarTelefono(e.target.value),
                })
              }
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              onKeyPress={(e) => {
                Validate.handleKeyPress(e);
                handleEnterPress(e);
              }}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primaryDark"
              onClick={debounceSearch}
              size="small"
              startIcon={<Icon>search</Icon>}
              sx={{ ml: 1 }}
            >
              BUSCAR
            </Button>
            <Button variant="outlined" onClick={handleLimpiarClick} size="small" sx={{ ml: 2 }}>
              LIMPIAR
            </Button>
          </Box>
          {errorMessage && (
            <Box mt={2}>
              <Alert variant="outlined" severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <UserTable
              data={data}
              total={total}
              setId={idUsuario}
              setNombreCompleto={nombreCompleto}
              upPageSize={() => {
                if (allowPageSizeUpdate) {
                  setPage(page + 1);
                }
              }}
              hasMore={hasMore}
              complement={true}
              clickCard={clickCard}
              setUserData={setUserData}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default UsersSearch;
