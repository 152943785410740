import { useState } from 'react';

// Material UI
import { Typography, Divider } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import Map from '@components/Maps/CustomComponents/GenericMap';

import { isEmptyOrNullObject } from '@utils/validations';

import polygons from '@data/polygons/geogto.json';

// Estilos de mapas
import { style_disabled } from '@components/Maps/Auxiliars/ColorFeature';

const elements = {
  comite: [
    { name: 'title', type: 'text' },
    { title: 'Latitud:', name: 'latitud', type: 'text' },
    { title: 'Longitud:', name: 'longitud', type: 'text' },
  ],
};

const Maps = () => {
  const [selected, setSelected] = useState({});

  const FilterComponent = (
    <>
      <Typography variant="h6" fontSize={16} align="center">
        {selected.title}
      </Typography>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="h6" fontSize={16} align="center">
        {selected.latitud} / {selected.longitud}
      </Typography>
    </>
  );

  const markers = [
    { title: 'Comite Directivo Estatal del Pan', latitud: '21.163759', longitud: '-101.648330' },
  ];

  const getMarker = (values) => setSelected(values);

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        drawerLeft={{
          title: 'Cómite',
          open: !isEmptyOrNullObject(selected),
          component: FilterComponent,
          width: { sm: 300, md: 350, lg: 500, xl: 600 },
        }}
        cardInfo={{ initialValues: { title: 'INFORMACIÓN', elements: elements['comite'] } }}
        centerByFeatures
        markers={{ data: markers, onClick: getMarker }}
        highlightFlag={false}
        styleProp={style_disabled}
      />
    </AdminLayout>
  );
};

export default Maps;
