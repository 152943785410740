import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: null,
  },
});

const BasicBarChart = (props) => {
  const {
    categories,
    series,
    title,
    subtitle,
    xName,
    textX,
    loading = false,
    height,
    lineColor = '#3063BA',
  } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: title,
        align: 'left',
      },
      subtitle: {
        text: subtitle,
        align: 'left',
      },
      xAxis: {
        categories: categories,
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: textX,
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      tooltip: {
        valueSuffix: ' millions',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '0%',
            borderRadiusBottomRight: '20% !important',
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: series,
    };

    setData(chartOptions);
    // eslint-disable-next-line
  }, [series, title, categories]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default BasicBarChart;
