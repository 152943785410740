//Librerías
import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Box, Grid, Typography, Icon, Stack } from '@mui/material';

//Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import ActionHeader from '@components/Containers/ActionHeader';

import BasicListDoble from '@components/Lists/BasicListDoble';
import ListItemCustom from '@components/Users/ListItemCustom';
import UserPartidos from '@components/Users/UsersPartidos';

//Validaciones
import { isTypePhone } from '@utils/validations';

//Servicios
import SupportService from '@services/SupportServices';

const UserStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);

  const [id, setId] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState('');

  const handleClick = (e) => {
    setId({ id: e.id, idPartido: e.idPartido });
    setNombreCompleto(e.NombreCompleto);
  };

  const handleClear = () => {
    setId(0);
    setNombreCompleto('');
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={'Cargando ...'}
        isLoading={isLoading}
        isOpen={openProgress}
        setIsOpen={() => {}}
      />
      <Container maxWidth="false">
        <ActionHeader title="Cambio de Partido Electoral" />
        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <BasicListDoble
                    id="id_nuevo"
                    API={SupportService.getUser}
                    APIKeys={[
                      {
                        id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
                        filter: 'LIKE',
                      },
                      {
                        id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                        filter: 'LIKE',
                      },
                    ]}
                    itemComponent={ListItemCustom}
                    header={''}
                    handleClick={handleClick}
                    handleClear={handleClear}
                    label={'Encuentra y elige al usuario para la acción a realizar'}
                    labelNote={
                      'Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular.'
                    }
                    finder={true}
                    inputValidation={{ input2: isTypePhone }}
                    initialSearch={false}
                    emptySearch={false}
                    doubleSearch={true}
                    breaks={{ sm: 12, md: 6 }}
                    buttonsBreaks={{ sm: 12, md: 4 }}
                    disableCardType={true}
                    config={{ height: 400 }}
                    clearData={true}
                  />
                </Grid>

                <Grid item xs={12} lg={5.5}>
                  <Grid
                    style={{
                      height: '50px',
                      textAlign: 'center',
                      marginBottom: '10px',
                      fontStyle: 'italic',
                      borderBottom: '2px solid #000',
                    }}
                  >
                    {nombreCompleto ? (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Icon fontSize="large" sx={{ color: '#a5a5a5' }}>
                          person
                        </Icon>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            visibility: nombreCompleto ? 'visible' : 'hidden',
                          }}
                        >
                          {nombreCompleto}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Icon fontSize="large">info</Icon>
                        <Typography>Seleccione un usuario</Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      <UserPartidos
                        idUsuario={id}
                        resetParams={handleClear}
                        setLoading={(loading) => {
                          setOpenProgress(loading);
                          setIsLoading(loading);
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserStatus;
