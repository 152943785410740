import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  Grid,
  Typography,
  Chip,
  Modal,
  IconButton,
  Tooltip,
  CircularProgress,
  Badge,
} from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { blue, red } from '@mui/material/colors';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import Download from '@components/Downloads/Download';
import BasicSelect from '@components/Selects/BasicSelect';
import ChartDesiredProgress from '@components/Activation/DashboardElectoral/ChartDesiredProgress';

// Middlewares ,Servicios, Utils
import middleware from '@middlewares/middleware';
import movilizacionServices from '@services/MovilizationServices';
import { setDiynamicColumns } from '@utils/Utilities';

// Constantes
import { COLUMNS_DASH_MOBILIZATION_ELECTORAL } from '@data/constants/Mobilization';
import {
  AMBITO_REGIONAL,
  AMBITO_DF,
  AMBITO_DL,
  AMBITO_MPAL,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
} from '@data/constants';

// Hooks
import { useSorted } from '@hooks/useSorted';
import movilizacion from '../../../services/MovilizationServices';

const TableMobilization = (props) => {
  const { data, catView, idAmbitoAgrupador, ambitoUser, idFilter } = props;

  const porcentajes = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: '0 % < 25 %' },
    { value: 2, label: '25 % < 50 %' },
    { value: 3, label: '50 % < 75 %' },
    { value: 4, label: '76 % < 100 %' },
  ];

  const [porcentaje, setPorcentaje] = useState(0);
  const [porcentajeCasilla, setPorcentajeCasilla] = useState(0);

  const [localData, setLocalData] = useState(data);
  const [ambitoAgrupador, setAmbitoAgrupador] = useState(ambitoUser);

  const [catViewFiltered, setCatViewFiltered] = useState([]);

  const [isDownload, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [orderBy, setOrderBy] = useState('');

  const [total, setTotal] = useState(10);
  const [summary, setSummary] = useState([]);
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [dataMovilization, setDataMovilization] = useState([]);

  const { handleRequestSort } = useSorted({ total: total, setData: setLocalData });

  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({ filtered: [] });
  const [loadingModal, setLoadingModal] = useState(null);

  const [value, setValue] = useState(0);
  const [desired, setdesired] = useState([]);

  const [loadingDown, setLoadingDown] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setValue(0);
    setOpenModal(false);
  };

  const handleChangePagination = (value) => {
    setLocalData((prev) => ({ ...prev, page: value.page, pageSize: value.pageSize }));
  };

  const handleDownload = async (params) => {
    try {
      setLoadingDown(true);
      const { success, message } = await movilizacionServices.exportPendingMovilization(params);

      if (!success) throw new Error(message);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoadingDown(false);
    }
  };
  const getMovilization = async (data) => {
    setLoading(true);
    try {
      const res = await movilizacion.getMovAdvanceElectoralDetails(data);
      const { results, response, message } = res;

      let newTotales = response.totales;

      if (results) {
        const modifiedData = response.data.map((item) => {
          // Eliminar el campo idTipoRentabilidad de cada objeto de datos
          const { idTipoRentabilidad, ...newItem } = item;

          if (response.totales.idTipoRentabilidad) {
            const {
              totales: { idTipoRentabilidad, ...restTotales },
            } = response;
            newTotales = restTotales;
          }

          if (newItem.Seccion) {
            const seccionValue = newItem.Seccion;
            if (
              idTipoRentabilidad !== null &&
              [1, 2, 3].includes(idTipoRentabilidad) &&
              middleware.checkMenuAction('Autorizar')
            ) {
              const backgroundColor =
                idTipoRentabilidad === 1 ? '#a5d6a7' : idTipoRentabilidad === 2 ? '#ffecb3' : '#ffccbc';
              const fontColor =
                idTipoRentabilidad === 1 ? '#2e7d32' : idTipoRentabilidad === 2 ? '#ff8f00' : '#d84315';

              return {
                ...newItem,
                PendienteMovilizar: (
                  <Tooltip title="Descargar">
                    <Badge badgeContent={item.PendienteMovilizar} color="warning" max={99999}>
                      <IconButton
                        onClick={() => handleDownload({ field: 'Seccion', value: seccionValue })}
                        disabled={loadingDown}
                      >
                        <Icon sx={{ color: red[500] }}>picture_as_pdf</Icon>
                      </IconButton>
                    </Badge>
                  </Tooltip>
                ),
                Seccion: (
                  <Chip
                    label={seccionValue}
                    style={{
                      backgroundColor: backgroundColor,
                      color: fontColor,
                      padding: '5px 10px',
                      fontWeight: 600,
                    }}
                  />
                ),
              };
            } else {
              return {
                ...newItem,
                PendienteMovilizar: (
                  <Tooltip title="Descargar">
                    <Badge badgeContent={item.PendienteMovilizar} color="warning" max={99999}>
                      <IconButton
                        onClick={() => handleDownload({ field: 'Seccion', value: seccionValue })}
                        disabled={loadingDown}
                      >
                        <Icon sx={{ color: red[500] }}>picture_as_pdf</Icon>
                      </IconButton>
                    </Badge>
                  </Tooltip>
                ),
                Seccion: <Typography>{seccionValue}</Typography>,
              };
            }
          } else {
            return newItem;
          }
        });

        const summaryValues = Object.values(newTotales);
        setSummary(['', ...summaryValues]);
        setTotal(response.total);

        handleMobilizationData({ data: modifiedData });
      } else {
        setTotal(0);
        setSummary([]);
        setColumnsDinamics([]);
        setDataMovilization([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localData.sorted.length) {
      setOrderBy(localData.sorted[0].id);
    } else setOrderBy('');
  }, [localData]);

  useEffect(() => {
    const campos_filtro = {
      2: 'idRegion',
      3: 'idDF',
      4: 'idDL',
      5: 'idMunicipio',
      6: 'idMunicipioReportes',
      7: 'idPoligono',
      8: 'Seccion',
    };

    if (campos_filtro[ambitoAgrupador]) {
      if (value) {
        setDataModal({
          filtered: [
            {
              id: campos_filtro[ambitoAgrupador],
              filter: '=',
              value: value,
            },
          ],
        });
      }
    }
  }, [ambitoAgrupador, value]);

  const handleMobilizationData = ({ data }) => {
    const columns = setDiynamicColumns({ data: data[0], columns: COLUMNS_DASH_MOBILIZATION_ELECTORAL });

    const columnas = [
      {
        id: 'Button',
        label: 'SEGUIMIENTO',
        type: 'iconButton',
        sort: false,
        align: 'center',
        onClick: (a, b, c) => {
          setValue(c.id);
          handleOpenModal();
        },
      },
      ...columns,
    ];

    setColumnsDinamics(columnas);

    // setDataMovilization(data.map((item) => (
    //   { Button: { value: <QueryStatsIcon />, onClick: ((e) => console.log(e)) }, ...item }
    // )));
    setDataMovilization(data.map((item) => ({ Button: <QueryStatsIcon />, ...item })));
  };

  useEffect(() => {
    // se asegura que ya haya cargado el ambito
    if (ambitoUser) {
      let values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
      let defaultValue = false;

      switch (idFilter) {
        case AMBITO_REGIONAL:
          values = [AMBITO_REGIONAL, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DF:
          values = [AMBITO_DF, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DL:
          values = [AMBITO_DL, AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_MPAL:
          values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_POLIGONAL:
          values = [AMBITO_POLIGONAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_SECCIONAL:
          values = [AMBITO_SECCIONAL];
          break;
        default:
          defaultValue = true;
          break;
      }

      // Si es no es default deja los items principales seleccionados
      let catalogs = [];
      if (!defaultValue) catalogs = catView.filter((item) => values.includes(item.value));
      else
        catalogs = catView.filter((item) => item.value === idAmbitoAgrupador || values.includes(item.value));

      setCatViewFiltered(catalogs);

      const agrupadorLocal = catalogs[0]?.value ?? '';
      setAmbitoAgrupador(agrupadorLocal);
      setPorcentaje(0);

      setLocalData({ ...data, page: 0, pageSize: 10, idAmbitoAgrupador: agrupadorLocal });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (ambitoUser) getMovilization(localData);
    // eslint-disable-next-line
  }, [localData]);

  // Actualiza el ambito agrupador local y el select
  useEffect(() => {
    if (ambitoUser) {
      const ambito = idAmbitoAgrupador ?? ambitoUser;
      const values = [AMBITO_MPAL, AMBITO_POLIGONAL, AMBITO_SECCIONAL];
      const filtered = catView.filter((item) => item.value === ambito || values.includes(item.value));
      setCatViewFiltered(filtered);
      setAmbitoAgrupador(filtered[0]?.value ?? '');
    }
    // eslint-disable-next-line
  }, [idAmbitoAgrupador]);

  const handleChangeAmbito = (e) => {
    const value = e.target.value;
    setAmbitoAgrupador(value);
    setLocalData({ ...localData, page: 0, pageSize: 10, idAmbitoAgrupador: value, filtered: [], sorted: [] });
    setPorcentajeCasilla(0);
    setPorcentaje(0);
  };

  const handleChangePorcentaje = (e) => {
    const value = e.target.value;
    updateOrAddFilter('PorcentajeAvance', value);
    setPorcentaje(value);
  };

  const handleChangePorcentajeCasillas = (e) => {
    const value = e.target.value;
    updateOrAddFilter('PorcentajeAvanceCasillas', value);
    setPorcentajeCasilla(value);
  };

  const updateOrAddFilter = (filterId, value) => {
    const existingFilterIndex = localData.filtered.findIndex((filter) => filter.id === filterId);

    const updatedFilters = [...localData.filtered];
    if (existingFilterIndex !== -1) {
      // Si ya existe, actualizar el valor del filtro existente
      updatedFilters[existingFilterIndex].value = value;
    } else {
      // Si no existe, agregar un nuevo filtro
      updatedFilters.push({ id: filterId, filter: '=', value: value });
    }

    setLocalData({
      ...localData,
      filtered: updatedFilters,
      page: 0,
      pageSize: 10,
      idAmbitoAgrupador: ambitoAgrupador,
    });
  };

  const movilizedChartTerritorialDesiredProgresss = async (params) => {
    setLoadingModal(true);

    try {
      const res = await movilizacion.getMovilizedChartElectoralDesiredProgress(params);
      const { results, message, response } = res;
      if (results) {
        setdesired(response);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
        customClass: {
          container: 'modal-alert',
        },
      });
      setdesired([]);
    } finally {
      setLoadingModal(false);
    }
  };

  useEffect(() => {
    if (dataModal.filtered.length > 0) {
      const { id, value } = dataModal.filtered[0];
      if (id && value) {
        movilizedChartTerritorialDesiredProgresss(dataModal);
      }
    }
    //eslint-disable-next-line
  }, [dataModal]);

  const exportMovAdvanceElectoralDetails = async () => {
    setIsDownloading(true);

    const { page, pageSize, ...params } = localData;

    try {
      const res = await movilizacion.exportMovAdvanceElectoralDetails(params);
      const { results, message } = res;

      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Card className="card-primary" sx={{ marginBottom: '0px!important' }}>
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={2}>
            <Typography variant="body2" fontWeight={700}>
              MOVILIZACIÓN
            </Typography>

            {middleware.checkMenuAction('Exportar') && (
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload}
                onClick={exportMovAdvanceElectoralDetails}
              >
                Exportar
              </Button>
            )}
          </Stack>

          {(isDownload || loadingDown) && (
            <Box marginBottom={2}>
              <Download format={loadingDown ? 'pdf' : 'xlsx'} isDownload={loadingDown || isDownload} />
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="agrupador"
                label="Agrupar por"
                options={catViewFiltered}
                value={ambitoAgrupador}
                onChange={handleChangeAmbito}
                sx={{ width: '100%' }}
                disabled={catViewFiltered?.length <= 1}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="porcentaje"
                label="Porcentaje de Avance"
                options={porcentajes}
                value={porcentaje}
                onChange={handleChangePorcentaje}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="avanceCasillas"
                label="Porcentaje Avance de Casillas"
                options={porcentajes}
                value={porcentajeCasilla}
                onChange={handleChangePorcentajeCasillas}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>

          <br />

          <CustomTable
            rows={dataMovilization}
            columns={columnsDinamics}
            total={total}
            orderBy={orderBy}
            page={data.page}
            pageSize={data.pageSize}
            isLoading={loading}
            handlePagination={handleChangePagination}
            handleSort={handleRequestSort}
            summary={summary}
            summarySettings={{ start: 0, title: 'Totales: ', align: 'center' }}
            disableCardType
          />
        </CardContent>
      </Card>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ width: 1200, bgcolor: 'background.paper', boxShadow: 24, p: 2, borderRadius: 3 }}>
          <CardContent>
            <ChartDesiredProgress desired={desired} loadingModal={loadingModal} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: blue[900],
                }}
              >
                Cerrar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default TableMobilization;
