const dataStreetLocation = {
  seccion: '',
  manzana: '',
  calle: '',
  tipo: '',
  NumInicio: '',
  NumFinal: '',
};

export { dataStreetLocation };
