import { useState } from 'react';

// Material UI
import { Grid, Stack, TextField, Button } from '@mui/material';

// Componentes
import BasicSelect from '@components/Selects/BasicSelect';
import FilterCollapse from '@components/Filters/FilterCollapse';

// Data
import { CATALOGS_PUBLICACIONES } from '@data/constants/Foca';
import { months } from '@data/constants/System';

const PublicacionesFilter = ({ handleFilter = () => {} }) => {
  const clearData = { idTipo: 0, year: '', idMonth: 0 };

  const options = [{ value: 0, label: 'TODOS' }, ...CATALOGS_PUBLICACIONES];
  const optionsMonths = [{ value: 0, label: 'TODOS' }, ...months];

  const [filter, setFilter] = useState(clearData);

  const isTypeYear = (data) => {
    const regex = /^[0-9]*$/;
    if (data.match(regex) && data.length <= 4) return true;
    else return false;
  };

  const handleLocalFilter = () => {
    let filtered = [];

    const { idTipo, year, idMonth } = filter;

    if (idTipo !== 0) filtered.push({ id: 'idTipo', filter: '=', value: idTipo });
    if (year !== '') filtered.push({ id: 'FechaPublicacion', filter: 'LIKE', value: year + '-' });
    if (idMonth !== 0) filtered.push({ id: 'FechaPublicacion', filter: 'LIKE', value: '-' + idMonth + '-' });

    handleFilter(filtered);
  };

  const handleClear = () => setFilter(clearData);

  return (
    <FilterCollapse expand={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <BasicSelect
            name="idTipo"
            label="Tipo"
            options={options}
            value={filter.idTipo}
            onChange={(e) => setFilter({ ...filter, idTipo: e.target.value })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Año"
            size="small"
            placeholder="Escribe el año"
            value={filter.year || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (isTypeYear(value)) setFilter({ ...filter, year: value });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <BasicSelect
            name="idMonth"
            label="Mes"
            options={optionsMonths}
            value={filter.idMonth}
            onChange={(e) => setFilter({ ...filter, idMonth: e.target.value })}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
        <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
          Limpiar
        </Button>

        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          onClick={handleLocalFilter}
          disabled={filter.year.length > 0 && filter.year.length < 4}
        >
          Filtrar
        </Button>
      </Stack>
    </FilterCollapse>
  );
};

export default PublicacionesFilter;
