import React, { Fragment, useRef, useState } from 'react';
import { useCatalogs } from '@hooks/useCatalogs';
import {
  Grid,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import makeAnimated from 'react-select/animated';
import FilterCollapse from '@components/Filters/FilterCollapse';
import Select from 'react-select';
import ButtonDrop from './ButtonDrop';

const FilterMecanismos = ({ setFiltros }) => {
  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: 'municipios' }],
    putDefaultOption: false,
  });
  const anchorRef = useRef(null);
  const defaultOption = { value: 0, label: 'TODOS' };
  const [filter, setFilter] = useState({
    idMunicipio: defaultOption,
    Nombre: '',
    Celular: '',
    Militante: defaultOption,
    Pregunta1: defaultOption,
    Motivo1: '',
    Motivo2: '',
    LemaEstatal: '',
    LemaNacional: '',
    PropuestaAyuntamiento: '',
    PropuestaGobernatura: '',
    PropuestaDLOC: '',
    PropuestaDFED: '',
    PropuestaSenadoPresidencia: '',
    filter4: { value: '=', label: 'Igual' },
    Pregunta4: defaultOption,
    filter5: { value: '=', label: 'Igual' },
    Pregunta5: defaultOption,
    filter6: { value: '=', label: 'Igual' },
    Pregunta6: defaultOption,
    filter7: { value: '=', label: 'Igual' },
    Pregunta7: defaultOption,
    filter8: { value: '=', label: 'Igual' },
    Pregunta8: defaultOption,
    filter9: { value: '=', label: 'Igual' },
    Pregunta9: defaultOption,
    filter10: { value: '=', label: 'Igual' },
    Pregunta10: defaultOption,
    filter11: { value: '=', label: 'Igual' },
    Pregunta11: defaultOption,
    filter12: { value: '=', label: 'Igual' },
    Pregunta12: defaultOption,
    Pregunta12opciones: [],
    Pregunta13a: defaultOption,
    filter13a: { value: '=', label: 'Igual' },
    Pregunta13b: defaultOption,
    filter13b: { value: '=', label: 'Igual' },
    Pregunta13c: defaultOption,
    filter13c: { value: '=', label: 'Igual' },
    Pregunta13d: defaultOption,
    filter13d: { value: '=', label: 'Igual' },
  });
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  };
  const calificacion = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: 'MUY MALO' },
    { value: 2, label: 'MALO' },
    { value: 3, label: 'REGULAR' },
    { value: 4, label: 'BUENO' },
    { value: 5, label: 'MUY BUENO' },
  ];
  const escala = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];
  const opciones = [
    { value: 0, label: 'TODOS' },
    {
      label:
        'a) Estructura Electoral (Representantes en OPLEs, RGs, RCs, Jurídico, Observadores, Primero en Fila, Guardianes de Sección, Recolección, etc.)',
      value: 1,
    },
    { label: 'b) Estructura Promoción (Territorial)', value: 2 },
    { label: 'c) Estructura Social (Segmentos, PPM o AJ)', value: 3 },
    {
      label: 'd) Campaña (Candidaturas, Equipo de campaña o integrante de CDM)',
      value: 4,
    },
    { label: 'e) Capacitación', value: 5 },
    { label: 'f) Ninguna', value: 6 },
  ];
  const options = [
    { value: '<', label: 'Menor' },
    { value: '>', label: 'Mayor' },
    { value: '=', label: 'Igual' },
  ];
  const handleFilter = () => {
    let filtros = [];
    if (filter.idMunicipio.value > 0) {
      filtros.push({
        id: 'idMunicipio',
        value: filter.idMunicipio.value,
        filter: '=',
      });
    }
    if (filter.Nombre.length > 0) {
      filtros.push({
        id: "CONCAT_WS('', Nombre, Paterno, Materno)",
        value: `%${filter.Nombre}%`,
        filter: 'LIKE',
      });
    }
    if (filter.Celular.length > 0) {
      filtros.push({
        id: 'Celular',
        value: `%${filter.Celular}%`,
        filter: 'LIKE',
      });
    }
    if (filter.Militante.value > 0) {
      filtros.push({
        id: 'EsMilitante',
        value: filter.Militante.value === 2 ? 0 : 1,
        filter: '=',
      });
    }
    if (filter.Pregunta1.value > 0) {
      filtros.push({
        id: 'Pregunta1',
        value: filter.Pregunta1.value,
        filter: '=',
      });
    }
    if (filter.Motivo1.length > 0) {
      filtros.push({
        id: 'Motivo1',
        value: `%${filter.Motivo1}%`,
        filter: 'LIKE',
      });
    }
    if (filter.Motivo2.length > 0) {
      filtros.push({
        id: 'Motivo2',
        value: `%${filter.Motivo2}%`,
        filter: 'LIKE',
      });
    }
    if (filter.LemaEstatal.length > 0) {
      filtros.push({
        id: 'LemaEstatal',
        value: `%${filter.LemaEstatal}%`,
        filter: 'LIKE',
      });
    }
    if (filter.LemaNacional.length > 0) {
      filtros.push({
        id: 'LemaNacional',
        value: `%${filter.LemaNacional}%`,
        filter: 'LIKE',
      });
    }
    if (filter.PropuestaAyuntamiento.length > 0) {
      filtros.push({
        id: 'PropuestaAyuntamiento',
        value: `%${filter.PropuestaAyuntamiento}%`,
        filter: 'LIKE',
      });
    }
    if (filter.PropuestaGobernatura.length > 0) {
      filtros.push({
        id: 'PropuestaGobernatura',
        value: `%${filter.PropuestaGobernatura}%`,
        filter: 'LIKE',
      });
    }
    if (filter.PropuestaDLOC.length > 0) {
      filtros.push({
        id: 'PropuestaDLOC',
        value: `%${filter.PropuestaDLOC}%`,
        filter: 'LIKE',
      });
    }
    if (filter.PropuestaDFED.length > 0) {
      filtros.push({
        id: 'PropuestaDFED',
        value: `%${filter.PropuestaDFED}%`,
        filter: 'LIKE',
      });
    }
    if (filter.PropuestaSenadoPresidencia.length > 0) {
      filtros.push({
        id: 'PropuestaSenadoPresidencia',
        value: `%${filter.PropuestaSenadoPresidencia}%`,
        filter: 'LIKE',
      });
    }
    if (filter.Pregunta4.value > 0) {
      filtros.push({
        id: 'Pregunta4',
        value: filter.Pregunta4.value,
        filter: filter.filter4.value,
      });
    }
    if (filter.Pregunta5.value > 0) {
      filtros.push({
        id: 'Pregunta5',
        value: filter.Pregunta5.value,
        filter: filter.filter5.value,
      });
    }
    if (filter.Pregunta6.value > 0) {
      filtros.push({
        id: 'Pregunta6',
        value: filter.Pregunta6.value,
        filter: filter.filter6.value,
      });
    }
    if (filter.Pregunta7.value > 0) {
      filtros.push({
        id: 'Pregunta7',
        value: filter.Pregunta7.value,
        filter: filter.filter7.value,
      });
    }
    if (filter.Pregunta8.value > 0) {
      filtros.push({
        id: 'Pregunta8',
        value: filter.Pregunta8.value,
        filter: filter.filter8.value,
      });
    }
    if (filter.Pregunta9.value > 0) {
      filtros.push({
        id: 'Pregunta9',
        value: filter.Pregunta9.value,
        filter: filter.filter9.value,
      });
    }
    if (filter.Pregunta10.value > 0) {
      filtros.push({
        id: 'Pregunta10',
        value: filter.Pregunta10.value,
        filter: filter.filter10.value,
      });
    }
    if (filter.Pregunta11.value > 0) {
      filtros.push({
        id: 'Pregunta11',
        value: filter.Pregunta11.value,
        filter: filter.filter11.value,
      });
    }
    if (filter.Pregunta12.value > 0) {
      filtros.push({
        id: 'Pregunta12',
        value: filter.Pregunta12.value,
        filter: filter.filter12.value,
      });
    }
    if (filter.Pregunta12opciones.length > 0) {
      filter.Pregunta12opciones.forEach((item) => {
        let tipo = '';
        switch (item.value) {
          case 1:
            tipo = 'Pregunta12ba';
            break;
          case 2:
            tipo = 'Pregunta12bb';
            break;
          case 3:
            tipo = 'Pregunta12bc';
            break;
          case 4:
            tipo = 'Pregunta12bd';
            break;
          case 5:
            tipo = 'Pregunta12be';
            break;
          case 6:
            tipo = 'Pregunta12bf';
            break;
          default:
            break;
        }

        filtros.push({
          id: tipo,
          value: 1,
          filter: '=',
        });
      });
    }
    if (filter.Pregunta13a.value > 0) {
      filtros.push({
        id: 'Pregunta13a',
        value: filter.Pregunta13a.value,
        filter: filter.filter13a.value,
      });
    }
    if (filter.Pregunta13b.value > 0) {
      filtros.push({
        id: 'Pregunta13b',
        value: filter.Pregunta13b.value,
        filter: filter.filter13b.value,
      });
    }
    if (filter.Pregunta13c.value > 0) {
      filtros.push({
        id: 'Pregunta13c',
        value: filter.Pregunta13c.value,
        filter: filter.filter13c.value,
      });
    }
    if (filter.Pregunta13d.value > 0) {
      filtros.push({
        id: 'Pregunta13d',
        value: filter.Pregunta13d.value,
        filter: filter.filter13d.value,
      });
    }
    setFiltros(filtros);
  };

  const clearFilter = () => {
    setFilter({
      idMunicipio: defaultOption,
      Pregunta1: defaultOption,
      Motivo1: '',
      Motivo2: '',
      LemaEstatal: '',
      LemaNacional: '',
      PropuestaAyuntamiento: '',
      PropuestaGobernatura: '',
      PropuestaDLOC: '',
      PropuestaDFED: '',
      PropuestaSenadoPresidencia: '',
      Pregunta4: defaultOption,
      Pregunta5: defaultOption,
      Pregunta6: defaultOption,
      Pregunta7: defaultOption,
      Pregunta8: defaultOption,
      Pregunta9: defaultOption,
      Pregunta10: defaultOption,
      Pregunta11: defaultOption,
      Pregunta12: defaultOption,
      Pregunta12opciones: [],
      Pregunta13a: defaultOption,
      Pregunta13b: defaultOption,
      Pregunta13c: defaultOption,
      Pregunta13d: defaultOption,
    });
  };

  return (
    <>
      <FilterCollapse>
        <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
          <Grid item xs={12} md={2}>
            <Typography variant="caption">Municipio</Typography>
            <Select
              label="Municipio"
              placeholder="Municipio"
              name="Municipio"
              menuPlacement="bottom"
              styles={selectStyles}
              components={animatedComponents}
              value={filter.idMunicipio}
              onChange={(e) => setFilter({ ...filter, idMunicipio: e })}
              //isMulti
              options={[defaultOption].concat(catalogs.municipios)}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <br />
            <TextField
              label={'Nombre'}
              value={filter.Nombre}
              onChange={(e) => setFilter({ ...filter, Nombre: e.target.value })}
              name="Nombre"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <br />
            <TextField
              label={'Celular'}
              value={filter.Celular}
              onChange={(e) => setFilter({ ...filter, Celular: e.target.value })}
              name="Celular"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="caption">Militante</Typography>
            <Select
              label="Militante"
              placeholder="Militante"
              name="Militante"
              menuPlacement="bottom"
              styles={selectStyles}
              components={animatedComponents}
              value={filter.Militante}
              onChange={(e) => setFilter({ ...filter, Militante: e })}
              //isMulti
              options={[defaultOption].concat([
                { value: 1, label: 'SI' },
                { value: 2, label: 'NO' },
              ])}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">
              Del método de selección que fue acordado conforme a la normatividad interna del PAN
            </Typography>
            <Select
              label="Valuación"
              placeholder="Valuación"
              name="valuacion"
              menuPlacement="bottom"
              styles={selectStyles}
              components={animatedComponents}
              value={filter.Pregunta1}
              onChange={(e) => setFilter({ ...filter, Pregunta1: e })}
              //isMulti
              options={calificacion}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="caption">
                  Motivos por el cual consideras tu calificación para la selección de candidaturas
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Motivo 1'}
                  value={filter.Motivo1}
                  onChange={(e) => setFilter({ ...filter, Motivo1: e.target.value })}
                  name="Motivo1"
                  sx={{ width: '100%' }}
                  type="text"
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Motivo 2'}
                  value={filter.Motivo2}
                  onChange={(e) => setFilter({ ...filter, Motivo2: e.target.value })}
                  name="Motivo2"
                  sx={{ width: '100%' }}
                  type="text"
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Lema de Campaña Estatal del Proceso Electoral Local 2023-2024
            </Typography>
            <TextField
              label={'Lema Estatal'}
              value={filter.LemaEstatal}
              name="LemaEstatal"
              onChange={(e) => setFilter({ ...filter, LemaEstatal: e.target.value })}
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Lema de Campaña Nacional del Proceso Electoral Local 2023-2024
            </Typography>
            <TextField
              label={'Lema Nacional'}
              value={filter.LemaNacional}
              onChange={(e) => setFilter({ ...filter, LemaNacional: e.target.value })}
              name="LemaNacional"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Propuesta de nuestra candidatura para el ayuntamiento en tú municipio
            </Typography>
            <TextField
              label={'Propuesta candidatura'}
              value={filter.PropuestaAyuntamiento}
              onChange={(e) => setFilter({ ...filter, PropuestaAyuntamiento: e.target.value })}
              name="PropuestaAyuntamiento"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">Propuesta de nuestra candidatura para la gobernatura</Typography>
            <TextField
              label={'Propuesta candidatura'}
              value={filter.PropuestaGobernatura}
              onChange={(e) => setFilter({ ...filter, PropuestaGobernatura: e.target.value })}
              name="PropuestaGobernatura"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Propuesta de nuestra candidatura a la diputación local en tú distrito
            </Typography>
            <TextField
              label={'Propuesta candidatura'}
              value={filter.PropuestaDLOC}
              onChange={(e) => setFilter({ ...filter, PropuestaDLOC: e.target.value })}
              name="PropuestaDLOC"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Propuesta de nuestra candidatura a la diputación federal en tú distrito
            </Typography>
            <TextField
              label={'Propuesta candidatura'}
              value={filter.PropuestaDFED}
              onChange={(e) => setFilter({ ...filter, PropuestaDFED: e.target.value })}
              name="PropuestaDFED"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Propuesta de nuestra candidatura a la presidencia o al senado
            </Typography>
            <TextField
              label={'Propuesta candidatura'}
              value={filter.PropuestaSenadoPresidencia}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  PropuestaSenadoPresidencia: e.target.value,
                })
              }
              name="PropuestaSenadoPresidencia"
              sx={{ width: '100%' }}
              type="text"
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de campaña de tú Comité Directivo Estatal?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter4}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter4: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta4"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta4}
                  onChange={(e) => setFilter({ ...filter, Pregunta4: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de campaña de tú Comité Directivo Municipal?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter5}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter5: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta5"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta5}
                  onChange={(e) => setFilter({ ...filter, Pregunta5: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la Gubernatura?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter6}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter6: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta6"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta6}
                  onChange={(e) => setFilter({ ...filter, Pregunta6: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Ayuntamiento de tu
                  municipio?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter7}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter7: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta7"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta7}
                  onChange={(e) => setFilter({ ...filter, Pregunta7: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación local de
                  tu municipio?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter8}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter8: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta8"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta8}
                  onChange={(e) => setFilter({ ...filter, Pregunta8: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación federal
                  de tu municipio?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter9}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter9: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta9"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta9}
                  onChange={(e) => setFilter({ ...filter, Pregunta9: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Presidencia de la
                  República?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter10}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter10: e })}
                />
              </Grid>
              <Grid item xs={12} lg={9}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta10"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta10}
                  onChange={(e) => setFilter({ ...filter, Pregunta10: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  ¿Cómo calificas el trabajo de quienes encabezaron la candidatura del PAN al Senado?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter11}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter11: e })}
                />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Select
                  label="Escala"
                  placeholder="Escala"
                  name="Pregunta11"
                  menuPlacement="bottom"
                  styles={selectStyles}
                  components={animatedComponents}
                  value={filter.Pregunta11}
                  onChange={(e) => setFilter({ ...filter, Pregunta11: e })}
                  //isMulti
                  options={escala}
                  isLoading={load}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="caption">
                  ¿Cómo calificas tú trabajo en el Proceso Electoral 2023-2024?
                </Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter12}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter12: e })}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Box sx={{ width: '100%', mt: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                      Calificación
                    </InputLabel>
                    <Select
                      label="Calificación"
                      placeholder="Calificación"
                      name="Pregunta12"
                      menuPlacement="bottom"
                      styles={selectStyles}
                      components={animatedComponents}
                      value={filter.Pregunta12}
                      onChange={(e) => setFilter({ ...filter, Pregunta12: e })}
                      //isMulti
                      options={escala}
                      isLoading={load}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: '100%', mt: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                      ¿En qué consistió tú participación electoral?
                    </InputLabel>
                    <Select
                      label="¿En qué consistió tú participación electoral? "
                      placeholder="¿En qué consistió tú participación electoral? "
                      name="Pregunta12Opcion"
                      menuPlacement="bottom"
                      styles={selectStyles}
                      components={animatedComponents}
                      value={filter.Pregunta12opciones}
                      onChange={(e) => setFilter({ ...filter, Pregunta12opciones: e })}
                      closeMenuOnSelect={false}
                      isMulti
                      options={opciones}
                      isLoading={load}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="caption">
                  ¿Qué factores consideras que influyeron en el resultado electoral?
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter13a}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter13a: e })}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                    Acciones del Gobierno Municipal
                  </InputLabel>
                  <Select
                    label="Acciones del Gobierno Municipal"
                    placeholder="Acciones del Gobierno Municipal"
                    name="Pregunta13a"
                    menuPlacement="bottom"
                    styles={selectStyles}
                    components={animatedComponents}
                    value={filter.Pregunta13a}
                    onChange={(e) => setFilter({ ...filter, Pregunta13a: e })}
                    //isMulti
                    options={escala}
                    isLoading={load}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter13b}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter13b: e })}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                    Acciones del Gobierno Estatal
                  </InputLabel>
                  <Select
                    label="Acciones del Gobierno Estatal"
                    placeholder="Acciones del Gobierno Estatal"
                    name="Pregunta13b"
                    menuPlacement="bottom"
                    styles={selectStyles}
                    components={animatedComponents}
                    value={filter.Pregunta13b}
                    onChange={(e) => setFilter({ ...filter, Pregunta13b: e })}
                    //isMulti
                    options={escala}
                    isLoading={load}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter13c}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter13c: e })}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                    Acciones del Gobierno Federal
                  </InputLabel>
                  <Select
                    label="Acciones del Gobierno Federal"
                    placeholder="Acciones del Gobierno Federal"
                    name="Pregunta13c"
                    menuPlacement="bottom"
                    styles={selectStyles}
                    components={animatedComponents}
                    value={filter.Pregunta13c}
                    onChange={(e) => setFilter({ ...filter, Pregunta13c: e })}
                    //isMulti
                    options={escala}
                    isLoading={load}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <ButtonDrop
                  options={options}
                  selectedIndex={filter.filter13d}
                  setSelectedIndex={(e) => setFilter({ ...filter, filter13d: e })}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={5}>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="Pregunta13c" sx={{ fontWeight: 'bold' }}>
                    Estructuras Electorales
                  </InputLabel>
                  <Select
                    label="Estructuras Electorales"
                    placeholder="Estructuras Electorales"
                    name="Pregunta13d"
                    menuPlacement="bottom"
                    styles={selectStyles}
                    components={animatedComponents}
                    value={filter.Pregunta13d}
                    onChange={(e) => setFilter({ ...filter, Pregunta13d: e })}
                    //isMulti
                    options={escala}
                    isLoading={load}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primaryDark" onClick={handleFilter}>
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};
export default FilterMecanismos;
