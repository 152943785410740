import { Button, Card, Container, Grid, Icon, Typography, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import SelectSearch from '../Selects/SelectSearch';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import FrenteAmplioServices from '@services/FrenteAmplioServices';

const FilterMovilizacion = (props) => {
  const { onChangeFilter = () => {}, catalogo = () => {}, updateAsing = () => {} } = props;

  const defaultOption = [{ value: 0, label: 'TODOS' }];

  const [filtros, setFiltros] = useState({
    municipio: { value: 0, label: 'TODOS' },
    capturista: [],
    colonia: [],
    seccion: [],
    status: { value: 'PARTICIPANTE', label: 'PARTICIPANTE' },
    Nombre: '',
  });
  const [catalogs, setCatalogs] = useState({
    municipios: [],
    capturistas: [],
    colonias: [],
    secciones: [],
    estatus: [],
  });
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  useEffect(() => {
    getCatalogs(data);
    // eslint-disable-next-line
  }, [data]);

  const getCatalogs = (data) => {
    setLoad(true);
    setData(data);
    FrenteAmplioServices.getCatalogsFA(data)
      .then((res) => {
        if (res.results) {
          let dataresult = {
            municipios: defaultOption.concat(res.response.data.municipios),
            capturistas: defaultOption.concat(res.response.data.capturistas),
            colonias: defaultOption.concat(res.response.data.colonias),
            secciones: res.response.data.secciones,
            estatus: defaultOption.concat(res.response.data.estatus),
          };
          setCatalogs(dataresult);
          catalogo(dataresult);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
          let dataresult = {
            municipios: [],
            capturistas: [],
            colonias: [],
            secciones: [],
            estatus: [],
          };
          setCatalogs(dataresult);
          catalogo(dataresult);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: 'error' });
        let dataresult = {
          municipios: [],
          capturistas: [],
          colonias: [],
          secciones: [],
          estatus: [],
        };
        setCatalogs(dataresult);
        catalogo(dataresult);
      })
      .finally(() => setLoad(false));
    // eslint-disable-next-line
  };

  const handleFilter = (values) => {
    const { municipio, capturista, colonia, seccion, status, Nombre } = values;
    let filtered = [];
    if (municipio.value === null) {
      filtered.push({
        id: 'cat_secciones.idMunicipio',
        value: 'NULL',
        filter: 'IS',
      });
    }

    if (municipio.value > 0) {
      filtered.push({
        id: 'cat_secciones.idMunicipio',
        filter: 'IN',
        value: [municipio.value],
      });
    }

    if (capturista.length > 0) {
      let valueCap = capturista.map((item) => item.value);
      filtered.push({
        id: 'compromisos_unicos_fa.idUsuarioCreo',
        filter: 'IN',
        value: valueCap,
      });
    }

    if (colonia.length > 0) {
      let valueC = colonia.map((item) => item.value);
      filtered.push({
        id: 'compromisos_unicos_fa.ColoniaVive',
        filter: 'IN',
        value: valueC,
      });
    }

    if (seccion.length > 0) {
      let s = seccion.map((item) => item.value);
      filtered.push({
        id: 'cat_secciones.Seccion',
        filter: 'IN',
        value: s,
      });
    }

    if (status.value === null) {
      filtered.push({
        id: 'compromisos_unicos_fa.DataResult',
        value: 'NULL',
        filter: 'IS',
      });
    }

    if (status.value !== null) {
      filtered.push({
        id: 'compromisos_unicos_fa.DataResult',
        filter: '=',
        value: status.value,
      });
    }

    if (Nombre.length > 0) {
      filtered.push({
        id: " CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno) ",
        filter: 'like',
        value: `%${Nombre.toUpperCase().replace(/ /g, '%')}%`,
      });
    }
    /*    if (formik.values.municipio !== "") {
        filtered.push({
          id: "R.idMunicipio",
          filter: "=",
          value: municipio,
        });
      } */

    onChangeFilter(filtered);
  };

  /*   const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }; */

  const handleClearFilter = () => {
    updateAsing(false);
    setFiltros({
      municipio: { value: 0, label: 'TODOS' },
      capturista: [],
      colonia: [],
      seccion: [],
      status: { value: 0, label: 'TODOS' },
      Nombre: '',
    });

    setData({
      page: 0,
      pageSize: 10,
      filtered: [],
      sorted: [],
    });

    onChangeFilter([]);
  };

  const handleMunicipio = (e) => {
    updateAsing(false);
    setFiltros({ ...filtros, municipio: e });
    let find = data.filtered.filter((item) => item.id !== 'cat_secciones.idMunicipio');
    let filter = find ? find : [];
    if (e.value !== 0) {
      setData({
        ...data,
        filtered: [...filter, { id: 'cat_secciones.idMunicipio', value: e.value, filter: '=' }],
      });
    } else {
      setData({ ...data, filtered: [...filter] });
    }
  };

  const handleCapturista = (e) => {
    updateAsing(false);
    setFiltros({ ...filtros, capturista: e });
    /* 
    let find = data.filtered.filter(
      (item) => item.id !== "compromisos_unicos_fa.idUsuarioCreo"
    );
    let filter = find ? find : [];
    if (e.length > 0) {
      setData({
        ...data,
        filtered: [
          ...filter,
          {
            id: "compromisos_unicos_fa.idUsuarioCreo",
            value: e,
            filter: "IN",
          },
        ],
      });
    } else {
      setData({ ...data, filtered: [...filter] });
    } */
  };

  const handleColonia = (e) => {
    updateAsing(false);
    setFiltros({ ...filtros, colonia: e });

    /*     
  let valueC = e.map((item) => item.value);
let find = data.filtered.filter(
      (item) => item.id !== "compromisos_unicos_fa.ColoniaVive"
    );
    let filter = find ? find : [];
    if (e.length > 0) {
      setData({
        ...data,
        filtered: [
          ...filter,
          {
            id: "compromisos_unicos_fa.ColoniaVive",
            value: valueC,
            filter: "IN",
          },
        ],
      });
    } else {
      setData({ ...data, filtered: [...filter] });
    } */
  };

  const handleSeccion = (e) => {
    updateAsing(false);
    setFiltros({ ...filtros, seccion: e });
  };

  const handleEstatus = (e) => {
    updateAsing(false);
    setFiltros({ ...filtros, status: e });
  };

  return (
    <Card className="card-primary">
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <SelectSearch
              multiple={false}
              placeholder="Municipio"
              name="municipio"
              closeMenuOnSelect={true}
              value={filtros.municipio}
              onChange={(e) => handleMunicipio(e)}
              options={catalogs.municipios}
              //disabled={catalogs.municipios.length === 2 ? true : false}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <SelectSearch
              multiple={true}
              placeholder="Capturistas"
              name="capturista"
              closeMenuOnSelect={true}
              value={filtros.capturista}
              onChange={(e) => handleCapturista(e)}
              options={catalogs.capturistas}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <SelectSearch
              multiple={true}
              placeholder="Colonia"
              name="colonia"
              closeMenuOnSelect={true}
              value={filtros.colonia}
              onChange={(e) => handleColonia(e)}
              options={catalogs.colonias}
              // disabled={catalogs.colonias.length === 2 ? true : false}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <SelectSearch
              multiple={true}
              placeholder="Sección"
              name="seccion"
              closeMenuOnSelect={true}
              value={filtros.seccion}
              onChange={(e) => handleSeccion(e)}
              options={catalogs.secciones}
              disabled={catalogs.secciones.length === 2 ? true : false}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <SelectSearch
              multiple={false}
              placeholder="Estatus"
              name="status"
              closeMenuOnSelect={true}
              value={filtros.status}
              onChange={(e) => handleEstatus(e)}
              options={catalogs.estatus}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              sx={{ width: '100%' }}
              label="Nombre"
              name="nombre"
              size="small"
              className="fixed-input"
              variant="outlined"
              value={filtros.Nombre.toUpperCase()}
              onChange={(e) => {
                updateAsing(false);
                setFiltros({ ...filtros, Nombre: e.target.value.toUpperCase() });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button variant="contained" color="primaryDark" onClick={(e) => handleFilter(filtros)}>
              Filtrar
            </Button>
            <Button variant="text" color="primaryDark" onClick={() => handleClearFilter()} sx={{ ml: 1 }}>
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

FilterMovilizacion.propTypes = {
  onChangeFilter: PropTypes.func,
  catalogo: PropTypes.bool,
  updateAsin: PropTypes.func,
};

export default FilterMovilizacion;
