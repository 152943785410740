import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import TreeResponsibles from './TreeResponsibles';
import useToggleChecked from './useToggleChecked';
import RollCallRgs from './RollCallRgs';
import moment from 'moment/moment';

const ListCasillas = ({
  title,
  itemUser,
  handleSave,
  data = [],
  reload,
  //resultsFalse,
}) => {
  const {
    checkedCasillas,
    setCheckedCasillas,
    handleToggle,
    handleToggleAllChecked,
    isCheckedAll,
    isChecked,
    indeterminate,
  } = useToggleChecked(data);

  const [hour, setHour] = useState(moment().format('HH:mm'));
  const [validHour, setValidHour] = useState(true);

  const handleChangeValueHour = (inputHour) => {
    const rgx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    setValidHour(rgx.test(inputHour));
    setHour(inputHour);
  };

  const handleSaveRollcall = () => {
    const _checkedCasillas = checkedCasillas
      .filter((item) => item.esPaseLista !== 1)
      .map((item) => ({
        idCasilla: item.idCasilla,
        idTipoPropietario: item.idTipoPropietario,
        idUsuario: item.idUsuario,
      }));

    handleSave({ casillas: _checkedCasillas, FechaHora: hour });
  };

  useEffect(() => {
    setCheckedCasillas([]);
  }, [data]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography textAlign={'center'} variant="body2" fontWeight={600} marginBottom={1}>
          {title}
        </Typography>

        <Typography textAlign={'center'} variant="body2" fontWeight={400} marginBottom={2}>
          {itemUser.label}
        </Typography>

        {itemUser.idPerfil && itemUser.idPerfil.some((item) => item.idPerfil === 3) ? (
          <Box marginBottom={2}>
            <RollCallRgs data={data} user={itemUser} reload={reload} />
          </Box>
        ) : null}

        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Selecciona la casilla
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedAll()}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleToggleAllChecked(e, e.target.checked)}
                indeterminate={indeterminate()}
              />
            }
            label={
              <Typography variant="subtitle2" fontWeight={600}>
                TODOS
              </Typography>
            }
          />
          <Divider />
          <TreeResponsibles data={data} isChecked={isChecked} handleToggle={handleToggle} />
        </Box>

        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Hora de pase de lista
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <TextField
            label={'* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)'}
            name="hora"
            value={hour}
            onChange={(e) => handleChangeValueHour(e.target.value)}
            size="small"
            sx={{ width: '100%', mb: 3 }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={!validHour}
            helperText={!validHour && 'Ingrese una hora válida'}
          />
        </Box>
        <Stack direction={'row'} justifyContent={'center'} marginBottom={2}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            disabled={!checkedCasillas.length > 0 || hour.length === 0 || !validHour}
            onClick={handleSaveRollcall}
          >
            Guardar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ListCasillas;
