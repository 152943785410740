import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';

const ListCasillasInstallation = ({ title, labelHora, typeCheck, handleSave, data = [] }) => {
  const [checkedsCasillas, setCheckedsCasillas] = useState([]);
  const [hour, setHour] = useState(moment().format('HH:mm'));
  const [match, setMatch] = useState(true);

  const handleToggle = (isChecked, item) => {
    const idx = checkedsCasillas.map((casilla) => casilla.idCasilla).indexOf(item.id);

    if (isChecked) return setCheckedsCasillas((prevState) => [...prevState, { idCasilla: item.id }]);

    if (!isChecked && idx > -1) {
      return setCheckedsCasillas((prevState) => {
        prevState.splice(idx, 1);
        return JSON.parse(JSON.stringify(prevState));
      });
    }
  };

  const handleToggleAllChecked = (isChecked) => {
    if (isChecked) {
      return setCheckedsCasillas(
        data.map((item) => ({
          idCasilla: item.id,
          isCheked: item[typeCheck],
        }))
      );
    } else {
      setCheckedsCasillas([]);
    }
  };

  const handleChangeValueHour = (hour) => {
    const rgx = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    setMatch(rgx.test(hour));
    setHour(hour);
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography textAlign={'center'} variant="body2" fontWeight={600} marginBottom={2}>
          {title}
        </Typography>
        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Selecciona la casilla
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                edge="center"
                checked={checkedsCasillas.length === data.length}
                disableRipple
                onChange={(e) => handleToggleAllChecked(e.target.checked)}
                indeterminate={checkedsCasillas.length !== data.length && checkedsCasillas.length > 0}
              />
            }
            label=<Typography variant="subtitle2" fontWeight={600}>
              TODOS
            </Typography>
          />
          <Divider />

          <List sx={{ width: '100%' }}>
            {data.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;

              return (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(e.target.checked, item)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          checkedsCasillas.some((casilla) => casilla.idCasilla === item.id) || item[typeCheck]
                        }
                        disabled={item[typeCheck]}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`SECCIÓN ${item.Seccion} - ${item.NombreCasilla}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Hora
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <TextField
            label={labelHora}
            name="hora"
            value={hour}
            onChange={(e) => handleChangeValueHour(e.target.value)}
            size="small"
            sx={{ width: '100%', mb: 3 }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={!match}
            helperText={!match && 'Ingrese una hora válida'}
          />
        </Box>
        <Stack direction={'row'} justifyContent={'center'}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            disabled={!checkedsCasillas.length > 0 || hour.length === 0 || !match}
            onClick={() => handleSave({ casillas: checkedsCasillas, FechaHora: hour })}
          >
            Guardar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ListCasillasInstallation;
