import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import Map from '@components/Maps/CustomComponents/GenericMap';
import {
  onEachFeature,
  highlightFeature,
  cardInfoComponent,
  elements,
} from '@components/MapsModules/Cartography/GlobalFunctions';

// Servicios y utilidades
import CartographyService from '@services/MapServices/CartographyServices';
import { setVars } from '@utils/global';

// Estilos de mapas
import { style_original_data, style_original_sub_data } from '@components/Maps/Auxiliars/ColorFeature';

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: 'FeatureCollection', features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const getEmptyMap = () => setPolygons({ type: 'FeatureCollection', features: [] });

  const handleFilterExect = () => {
    setPolygons(null);
    setPolygonsBlocks(null);

    CartographyService.getShapes({ color: 0, page: 0, pageSize: 10, filtered: [], shp: 'entidad' })
      .then((res) => {
        if (res.results) setPolygons(res.response.data);
        else Swal.fire({ title: res.message, icon: 'warning' });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });

    CartographyService.getShapes({ color: 0, page: 0, pageSize: 10, filtered: [], shp: 'region' })
      .then((res) => {
        if (res.results) setPolygonsBlocks(res.response.data);
        else Swal.fire({ title: res.message, icon: 'warning' });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });
  };

  useEffect(() => {
    setVars('filterSavedMap', {
      idRegion: '',
      idMunicipioReportes: '',
      idPoligono: '',
      Seccion: '',
    });
    getEmptyMap();
    handleFilterExect();
    // eslint-disable-next-line
  }, []);

  const handleDownloadFile = (e) => {
    CartographyService.downloadShapeCartography({ type: 'ER' })
      .then((res) => {
        if (res.success) Swal.fire({ title: '¡Descarga exitosa!', icon: 'success' });
        else Swal.fire({ title: res.error.message, icon: 'warning' });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      });
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        subData={polygonsBlocks}
        cardInfo={{
          initialValues: { title: 'INFORMACIÓN', elements: elements['entidad'] },
          button: { title: 'Descargar PDF', handleClick: handleDownloadFile },
          component: { component: cardInfoComponent, validShp: ['manzana'] },
        }}
        highlightFeature={highlightFeature}
        onEachFeature={(a, b, c, d) => onEachFeature(a, b, c, d, elements['entidad'][0].name)}
        centerByFeatures
        styleProp={{
          data: style_original_data,
          subData: style_original_sub_data,
          highlightFeature: style_original_data,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
