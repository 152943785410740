import { useState } from 'react';

//Material UI
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  styled,
  Stack,
  Divider,
  Button,
} from '@mui/material';
import {
  RemoveCircleOutline,
  InfoOutlined,
  TextDecrease,
  TextIncrease,
  InvertColors,
  InvertColorsOff,
  RestartAlt,
} from '@mui/icons-material';

// Utils
import { generateRandomString } from '@utils/global';

// Map Things
import { roundTo } from '@components/Maps/Auxiliars/MathFunctions';

const initialFontSize = 12;

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: `${initialFontSize}px`,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

const CardGeneralInfo = (props) => {
  const {
    initialValues,
    values,
    handleShowOldShape,
    compromisos = 1,
    button,
    component,
    lineFlag,
    width = 0,
  } = props;

  const validButtonShp = button?.validShp ? button.validShp.includes(values?.shp) : true;
  const invalidButtonShp = button?.invalidShp ? !button.invalidShp.includes(values?.shp) : true;
  const validComponentShp = component?.validShp ? component.validShp.includes(values?.shp) : true;
  const invalidComponentShp = component?.invalidShp ? !component.invalidShp.includes(values?.shp) : true;

  const [showCard, setShowCard] = useState(true);
  const [invertColor, setInvertColor] = useState(false);
  const [fontSize, setFontSize] = useState(initialFontSize);

  const handleFontSize = (action) => {
    if (action === 'increase') setFontSize(fontSize + 4);
    else if (fontSize > initialFontSize) setFontSize(fontSize - 4);
  };

  const handleReset = () => {
    setInvertColor(false);
    setFontSize(initialFontSize);
  };

  const handleClick = () => (button?.handleClick ? button.handleClick(values) : () => {});

  return values !== null && values !== undefined && showCard ? (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-left">
        <div
          className="leaflet-control"
          style={{
            padding: '6px 8px',
            font: '14px/16px Arial, Helvetica, sans-serif',
            background: 'rgba(255,255,255,0.8)',
            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
            borderRadius: '5px',
            marginLeft: width + 55,
          }}
        >
          <Card className="card-secondary">
            <CardHeader
              title={initialValues.title}
              style={{ textAlign: 'center', paddingBottom: 0 }}
              subheader={''}
              titleTypographyProps={{ fontSize: `${fontSize <= 20 ? fontSize + 2 : fontSize - 2}px` }}
              action={
                <IconButton onClick={() => setShowCard(false)} aria-label="Minimizar">
                  <RemoveCircleOutline sx={{ fontSize: fontSize <= 24 ? '24px' : fontSize }} />
                </IconButton>
              }
            />

            <CardContent
              sx={{
                paddingTop: lineFlag ? 0 : 2,
                paddingBottom: button || component ? 2 : 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
              }}
            >
              {lineFlag && (
                <Box
                  sx={{
                    width: '100%',
                    height: 8,
                    borderRadius: 2,
                    marginY: '3px',
                    backgroundColor:
                      compromisos === 1
                        ? values.color === '#FFFFFF'
                          ? '#0066FF'
                          : values.color === 'null'
                            ? '#000000'
                            : values.color
                        : values.color_estructura === '#FFFFFF'
                          ? '#0066FF'
                          : values.color_estructura === 'null'
                            ? '#000000'
                            : values.color_estructura,
                    opacity:
                      compromisos === 1
                        ? values.color === 'null'
                          ? 0.6
                          : 0.8
                        : values.color_estructura === 'null'
                          ? 0.6
                          : 0.8,
                  }}
                />
              )}
              {initialValues.elements &&
                initialValues.elements.map((item, index) => (
                  <Stack key={'key_' + values[item.name] + generateRandomString(5)}>
                    <Item
                      style={{
                        fontSize: `${fontSize}px`,
                        justifyContent: item.title === 'center' ? 'center' : 'left',
                      }}
                    >
                      {item.type === 'color' && (
                        <i
                          style={{
                            width: `${fontSize + 6}px`,
                            height: `${fontSize + 6}px`,
                            marginLeft: '8px',
                            marginRight: '2px',
                            opacity:
                              compromisos === 1
                                ? values.color === 'null'
                                  ? 0.5
                                  : 0.7
                                : values.color_estructura === 'null'
                                  ? 0.5
                                  : 0.7,
                            background:
                              compromisos === 1
                                ? values.color === '#FFFFFF'
                                  ? '#0066FF'
                                  : values.color === 'null'
                                    ? '#000000'
                                    : values.color
                                : values.color_estructura === '#FFFFFF'
                                  ? '#0066FF'
                                  : values.color_estructura === 'null'
                                    ? '#000000'
                                    : values.color_estructura,
                          }}
                        />
                      )}
                      {item.title !== 'center' && (
                        <b style={{ color: '#000000' }}>
                          <small>{item.title}</small>
                        </b>
                      )}
                      <b
                        style={{
                          color: invertColor
                            ? '#000'
                            : compromisos === 1
                              ? values.color === '#FFFFFF'
                                ? '#0066FF'
                                : values.color
                              : values.color_estructura === '#FFFFFF'
                                ? '#0066FF'
                                : values.color_estructura,
                        }}
                      >
                        <small>
                          {values[item.name] || values[item.name] === 0
                            ? item.type === 'text'
                              ? values[item.name]
                              : item.type === 'int'
                                ? new Intl.NumberFormat('en-EN').format(values[item.name])
                                : item.type === 'percent'
                                  ? roundTo(values[item.name], 2) + '%'
                                  : values[item.name]
                            : '--'}
                        </small>
                      </b>
                    </Item>
                    {index < initialValues.elements.length - 1 && <Divider />}
                  </Stack>
                ))}

              {handleShowOldShape && (
                <>
                  <br />
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Button
                      size="small"
                      style={{ borderRadius: '20px', fontSize: fontSize - 2 }}
                      variant="outlined"
                      onClick={handleShowOldShape}
                    >
                      Mirar Capa Anterior
                    </Button>
                  </Stack>
                </>
              )}
            </CardContent>

            {component &&
              invalidComponentShp &&
              validComponentShp &&
              component?.component(values, fontSize, invertColor)}

            {button && !button?.component && invalidButtonShp && validButtonShp ? (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Button
                  size="small"
                  sx={{ borderRadius: '20px', fontSize: `${fontSize <= 20 ? fontSize : fontSize - 4}px` }}
                  variant="outlined"
                  onClick={handleClick}
                >
                  {button?.title ?? 'Ver Detalle'}
                </Button>
              </Stack>
            ) : button?.component && !invalidButtonShp.includes(values.shp) ? (
              <>{button?.component(values, fontSize, invertColor)}</>
            ) : (
              <></>
            )}

            {/* Cambio de color y tamaño de la letra */}
            <Stack sx={{ flexDirection: 'row', gap: 1, marginTop: '6px' }}>
              <IconButton
                onClick={handleReset}
                size={fontSize <= 20 ? 'small' : 'medium'}
                aria-label="Regresar a valores iniciales"
              >
                <RestartAlt sx={{ fontSize: fontSize <= 20 ? '20px' : fontSize }} />
              </IconButton>

              <IconButton
                onClick={() => setInvertColor(!invertColor)}
                size={fontSize <= 20 ? 'small' : 'medium'}
                aria-label="Cambiar color de letra"
              >
                {invertColor ? (
                  <InvertColorsOff sx={{ fontSize: fontSize <= 20 ? '20px' : fontSize }} />
                ) : (
                  <InvertColors sx={{ fontSize: fontSize <= 20 ? '20px' : fontSize }} />
                )}
              </IconButton>

              <Box sx={{ flex: 1 }} />

              <IconButton
                onClick={() => handleFontSize('decrease')}
                disabled={fontSize <= initialFontSize}
                size={fontSize <= 20 ? 'small' : 'medium'}
                aria-label="Disminución de texto"
              >
                <TextDecrease sx={{ fontSize: fontSize <= 20 ? '20px' : fontSize }} />
              </IconButton>

              <IconButton
                onClick={() => handleFontSize('increase')}
                size={fontSize <= 20 ? 'small' : 'medium'}
                aria-label="Aumento de texto"
              >
                <TextIncrease sx={{ fontSize: fontSize <= 20 ? '20px' : fontSize }} />
              </IconButton>
            </Stack>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-bar leaflet-control" style={{ marginLeft: width + 55 }}>
          <IconButton
            aria-label="Abrir info"
            onClick={() => setShowCard(true)}
            disabled={!values}
            style={{ backgroundColor: 'white' }}
            sx={{
              height: '30px',
              width: '30px',
              borderRadius: '3px',
              '&:hover': { backgroundColor: values ? '#eeeeee!important' : 'white!important' },
            }}
          >
            <InfoOutlined sx={{ fontSize: 25, fontWeight: 200, color: '#000' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CardGeneralInfo;

/* Ejemplo de initialValues
const exampleInitialValues = {
  title: "INFORMACIÓN",
  elements: [
    {
      title: "center", // Centra el dato tipo titulo
      name: "Entidad",
      type: "text",
    },
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "Avance:",
      name: "avance",
      type: "int",
    },
    {
      title: "Porcentaje de Avance:",
      name: "avance_porcentual",
      type: "percent",
    },
    {
      title: "Municipio:",
      name: "municipio",
      type: "color",
    },
  ],
}; 

*/

/* Ejemplo de button
const button = {
  title: string
  handleClick: funcion
  validShp: array // el boton SI aparecera en estos shp (no aplica si esta vacio)
  invalidShp: array // el boton NO aparecera en estos shp (no aplica si esta vacio)
  component: funcion
},

component:
const customComponent = (values) => {
    return <Box sx={{ padding: 1 }}>Hola mundo {values.shp}</Box>;
  };

*/

/* Ejemplo de component
const component = {
  validShp: array // el componente SI aparecera en estos shp (no aplica si esta vacio)
  invalidShp: array // el componente NO aparecera en estos shp (no aplica si esta vacio)
  component: funcion
},

component:
const customComponent = (values) => {
    return <Box sx={{ padding: 1 }}>Hola mundo {values.shp}</Box>;
  };

*/

/* Ejemplo de lineFlag 
const lineFlag = bool // Sirve para deshabilitar la linea de color superior (inicialmente true)

*/

/* Ejemplo de compromisos 
const compromisos = number // Sirve para cabiar de color los textos, cambiando la referencia
                              de values.color a values.color_estructura

*/

/* Ejemplo de oldShapeFlag 
const oldShapeFlag = bool // Activa el boton para ver el poligono anterior

*/
