import React, { useEffect, useState } from 'react';
import { CircularProgress, Icon, Box, LinearProgress, CardActions, Stack, CardHeader } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';

/* 
Variables para estilizar el por dato
{
    title:"Titulo",
    label:"Domicilio",
    colorCircle:"transparent",
    icon:"person_pin_circle",
    colorIcon:"#E69138"
}
*/

function esNumeroPar(numero) {
  return numero % 2 === 0;
}

const TimelinePath = (props) => {
  const {
    data = [
      {
        label: 'Domicilio',
        subLabel: 'Titulo',
        colorCircle: 'transparent',
        icon: 'person_pin_circle',
        colorIcon: '#E69138',
        secundaryTitle: 'Hola',
      },
      {
        label: 'Domicilio',
        subLabel: 'Titulo',
        colorCircle: 'transparent',
        icon: 'person_pin_circle',
        colorIcon: '#E69138',
      },
    ],
    count = 0,
    Scroll = true,
    colorLine = '#2986CC',
    Header = true,
    colorCirculo = 'transparent',
    fetchHasMore,
    isLoading,
    hasMore,
    configParams = {
      title: '',
      icon: 'list_alt',
      height: 600,
      endMessage: 'No hay registros para mostrar',
    },
  } = props;
  const [items, setItems] = useState(data);
  const [showedItems, setShowedItems] = useState(0);
  const [config, setConfig] = useState({});

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setItems(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  return (
    <>
      {Header && (
        <CardHeader
          avatar={<Icon sx={{ fontSize: '25px' }}>{config.icon}</Icon>}
          title={config.title}
          titleTypographyProps={{ fontSize: '20px' }}
          sx={{ mb: -3 }}
        />
      )}
      <Timeline position="alternate">
        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <TimelineItem sx={{ mb: -5 }}>
              <TimelineSeparator>
                <TimelineConnector
                  sx={{
                    bgcolor: colorLine,
                    width: '5px',
                    height: '40px',
                    mb: -0.2,
                  }}
                />
                <TimelineDot variant="outlined" sx={{ borderColor: colorLine }} />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          }
        >
          {!hasMore && isLoading && <LinearProgress color="secondary" />}
          <TimelineItem sx={{ mb: -5 }}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: colorLine, mb: -0.2 }} />
            </TimelineSeparator>
            <TimelineContent></TimelineContent>
          </TimelineItem>
          {items.map((item, index) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent
                  sx={{ mt: index !== 0 ? '13%' : '8%' }}
                  variant="body2"
                  color="text.secondary"
                >
                  {item.secundaryTitle}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor: colorLine,
                      width: '5px',
                      height: '40px',
                      mb: -0.2,
                    }}
                  />
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-flex',
                      mt: -0.7,
                      mb: -0.7,
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      sx={{ color: colorLine }}
                      value={esNumeroPar(index) ? 51 : -51}
                      size={80}
                      thickness={4}
                    />
                    <TimelineDot
                      sx={{
                        position: 'absolute',
                        top: '35%',
                        left: esNumeroPar(index) ? '50%' : '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                        backgroundColor: item.colorCircle,
                        width: '70%',
                        height: '70%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon sx={{ color: item.colorIcon, fontSize: '35px' }}>{item.icon}</Icon>
                    </TimelineDot>
                  </Box>
                </TimelineSeparator>
                <TimelineContent sx={{ py: '1%', px: 2, mt: '5%' }}>
                  <Typography variant="h6" component="span">
                    {item.label}
                  </Typography>
                  <Typography>{item.subLabel}</Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </InfiniteScroll>
      </Timeline>
      <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Stack direction="row" spacing={1}>
          <Typography color={'#0D314B'} sx={{ mt: 0.2, ml: 0.1 }}>
            Mostrando
          </Typography>
          <Typography component={'subtitle1'} variant="h6" color={'#0D314B'}>
            {data.length}
          </Typography>
          <Typography color={'#0D314B'}>de</Typography>
          <Typography component={'subtitle2'} variant="h6" color={'#0D314B'} sx={{ mt: '0.5px' }}>
            {count}
          </Typography>
        </Stack>
      </CardActions>
    </>
  );
};

export default TimelinePath;
