import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import { Box, Button, Card, CardContent, Container, Stack, Typography, Icon } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Sije/Reports/Filter';
import Download from '@components/Downloads/Download';
import CustomTable from '@components/Tables/CustomTable';
import ProgressPercent from '../../components/LinearProgress/ProgressPercent';

// Data, Servicios y Utilidades
import { COLUMNS_REPORTS } from '@data/constants/Sije';
import SijeService from '@services/SijeService';
import { setDiynamicColumns } from '@utils/Utilities';
import { getColorReverse } from '@utils/Utilities';

// Hooks
import { useSorted } from '@hooks/useSorted';

const Reports = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [summary, setSummary] = useState([]);
  const [total, setTotal] = useState(10);

  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
    columnas: [],
    idAmbitoAgrupador: 5,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);

  const { handleRequestSort } = useSorted({ total: total, setData });

  const getListSijeAttendance = async (data) => {
    try {
      setIsLoading(true);
      const result = await SijeService.getListSijeAsistencia(data);
      const { response, results, message } = result;

      if (results) {
        const columns = setDiynamicColumns({ columns: COLUMNS_REPORTS, data: response.data[0] });
        setColumns(columns);
        const transform = response.data.map((item) => ({
          ...item,
          PorcentajeNoAbiertoP1: (
            <ProgressPercent
              type={'circular'}
              value={parseFloat(item.PorcentajeNoAbiertoP1)}
              fixed={1}
              customColors={getColorReverse}
              fontSize={10}
              size={40}
            />
          ),
          PorcentajeNoAbiertoP2: (
            <ProgressPercent
              type={'circular'}
              value={parseFloat(item.PorcentajeNoAbiertoP2)}
              fixed={1}
              customColors={getColorReverse}
              fontSize={10}
              size={40}
            />
          ),
          PorcentajeNoAbiertoS1: (
            <ProgressPercent
              type={'circular'}
              value={parseFloat(item.PorcentajeNoAbiertoS1)}
              fixed={1}
              customColors={getColorReverse}
              fontSize={10}
              size={40}
            />
          ),
          PorcentajeNoAbiertoS2: (
            <ProgressPercent
              type={'circular'}
              value={parseFloat(item.PorcentajeNoAbiertoS2)}
              fixed={1}
              customColors={getColorReverse}
              fontSize={10}
              size={40}
            />
          ),
        }));
        //setRows(response.data);
        setRows(transform);
        setSummary(Object.values(response.totales));
        setTotal(response.total);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
      setColumns([]);
      setRows([]);
      setSummary([]);
      setTotal(0);
    } finally {
      setIsLoading(false);
    }
  };

  const exportListAttendance = async () => {
    try {
      setIsDownloading((prev) => !prev);

      const result = await SijeService.exportSije({
        params: {
          columnas: data.columnas,
          idAmbitoAgrupador: data.idAmbitoAgrupador,
        },
        api: 'sije/export-list-sije-asistencia',
      });
      const { success } = await result;
      if (success) Swal.fire({ title: 'Descargado con éxito.', icon: 'success' });
      else throw new Error('Ocurrió un error, contacte al administrador.');
    } catch (error) {
      Swal.fire({ title: error, icon: 'warning' });
    } finally {
      setIsDownloading((prev) => !prev);
    }
  };

  const onChangeFilter = (filters) => {
    setData((prev) => ({
      ...prev,
      page: 0,
      columnas: filters.columnas,
      idAmbitoAgrupador: filters.idAmbitoAgrupador,
    }));
  };

  const handlePagination = (page) => setData({ ...data, ...page });

  useEffect(() => {
    getListSijeAttendance(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reportes" />
        <Filter onChangeFilter={onChangeFilter} />

        <Card className="card-primary">
          <CardContent>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={2}>
              <Typography variant="body2" fontWeight={700}>
                REPORTES
              </Typography>
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload || isLoading}
                onClick={exportListAttendance}
              >
                Exportar
              </Button>
            </Stack>
            {isDownload && (
              <Box marginBottom={2}>
                <Download format={'xlsx'} isDownload={isDownload} />
              </Box>
            )}

            <CustomTable
              rows={rows}
              columns={columns}
              total={total}
              summary={summary}
              page={data.page}
              pageSize={data.pageSize}
              maxHeight={500}
              stickyHeader
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleRequestSort}
              disableCardType
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Reports;
