import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  CircularProgress,
  CardHeader,
  Card,
  CardContent,
  TextField,
  Box,
  Alert,
} from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import BasicSelect from '@components/Selects/BasicSelect';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

// Servicios y Utils
import ExamplesServices from '@services/_ExamplesServices';
import CatalogServices from '@services/CatalogServices';

const Nombres = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [text, setText] = useState('');
  const [correctText, setCorrectText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMun, setIsLoadingMun] = useState(false);
  const [isLoadingCol, setIsLoadingCol] = useState(false);
  const [idMunicipio, setIdMunicipio] = useState(1);
  const [idColonia, setIdColonia] = useState(0);
  const [catalogs, setCatalogs] = useState({ municipios: [], colonias: [] });

  useEffect(() => {
    getMunicipios();
    getColonia(1);
    // eslint-disable-next-line
  }, []);

  const getMunicipios = async (e) => {
    try {
      setIsLoadingMun(true);

      const result = await CatalogServices.getCatalogs([
        { id: 'municipios', getAll: true },
        { id: 'colonias', getAll: true, filtered: [{ id: 'idMunicipio', filter: '=', value: 1 }] },
      ]);
      const { results, response, message } = result;
      if (results) {
        const data = { ...response.catalogs };
        setCatalogs(data);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingMun(false);
    }
  };

  const getCatColonias = async (idMunicipio) => {
    try {
      setIsLoadingCol(true);

      let sendValue = idMunicipio;
      if (idMunicipio > 46) sendValue = 20; // LEON

      const result = await CatalogServices.getCatalogs([
        { id: 'colonias', getAll: true, filtered: [{ id: 'idMunicipio', filter: '=', value: sendValue }] },
      ]);
      const { results, response, message } = result;
      if (results) {
        setCatalogs({ ...catalogs, ...response.catalogs });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingCol(false);
    }
  };

  const getColonia = async (value) => {
    try {
      setIsLoading(true);
      setData([]);
      setAllData([]);

      const result = await ExamplesServices.getColonia({
        page: 0,
        pageSize: 10000,
        filtered: [{ id: 'idMunicipioVive', filter: '=', value }],
        sorted: [],
      });
      const { results, response, message } = result;

      if (results) {
        const dataAPI = response.data;

        const newData = dataAPI.map((item) => ({
          ...item,
          value: item.id,
          label: item.ColoniaVive,
          checked: 0,
        }));
        setData(newData);
        setAllData(newData);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const setColonia = async (value) => {
    if (correctText === '') {
      Swal.fire({ title: 'Ingresa un nuevo nombre', icon: 'warning' });
      return;
    }

    const filtered = allData.filter((item) => item.checked === 1);

    if (filtered.length === 0) {
      Swal.fire({ title: 'Agrega elementos para editar', icon: 'warning' });
      return;
    }

    const ids = filtered.map((item) => item.id);

    const params = {
      ids: ids,
      ColoniaCorrecta: correctText,
    };

    try {
      setIsLoading(true);

      const result = await ExamplesServices.updateColonia(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });
        getColonia(idMunicipio);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (newItem, index) => () => {
    const newData = allData.map((item) => (item.value === newItem.value ? { ...item, checked: 1 } : item));

    const dataFilter = newData.filter((item) => {
      const labelLower = item.label.toLowerCase();
      const valueLower = text.toLowerCase();
      return valueLower.split('').every((char) => labelLower.includes(char));
    });

    setData(dataFilter);

    setAllData(newData);
  };

  const handleQuit = (newItem, index) => () => {
    const newData = allData.map((item) => (item.value === newItem.value ? { ...item, checked: 0 } : item));

    const dataFilter = newData.filter((item) => {
      const labelLower = item.label.toLowerCase();
      const valueLower = text.toLowerCase();
      return valueLower.split('').every((char) => labelLower.includes(char));
    });

    setData(dataFilter);

    setAllData(newData);
  };

  /* const handleText = (value) => {
    console.log("value",value);
    const newData = allData
      .filter((item) => item.checked === 0)
      .filter((item) => {
        const labelLower = item.label.toLowerCase();
        const valueLower = value.toLowerCase();
        return valueLower.split("").every((char) => labelLower.includes(char));
      });
    setData(newData);

    if (value === "") setData(allData);

    //setText(value);
  }; */

  const handleText = (value) => {
    console.log('value', value);
    const newData = allData
      .filter((item) => item.checked === 0)
      .filter((item) => {
        const labelLower = item.label.toLowerCase();
        const valueLower = value.toLowerCase();
        const regex = new RegExp(`\\b${valueLower}`);
        return regex.test(labelLower);
      });
    setData(newData);

    if (value === '') setData(allData);

    //setText(value);
  };

  const handleCorrectText = (value) => setCorrectText(value);

  return (
    <AdminLayout>
      <ActionHeader title="Editar Colonias" />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BasicSelect
                label="Municipio"
                name="idMunicipio"
                id="idMunicipio"
                options={catalogs.municipios}
                value={idMunicipio}
                onChange={(e) => {
                  getColonia(e.target.value);
                  getCatColonias(e.target.value);
                  setIdMunicipio(e.target.value);
                }}
                isLoading={isLoadingMun}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                size="small"
                label="Buscar Colonia"
                placeholder="Busca una colonia"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Enter') {
                    handleText(text);
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" onClick={(e) => handleText(text)}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}></Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AdvancedSelect
                label="Colonia"
                name="idColonia"
                id="idColonia"
                options={catalogs.colonias}
                value={idColonia}
                onChange={(e) => {
                  setIdColonia(e.value);
                  handleCorrectText(e.label);
                }}
                isLoading={isLoadingCol}
                isSearchable
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Nombre Nuevo"
                placeholder="Escribe el nombre correcto"
                value={correctText}
                onChange={(e) => handleCorrectText(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button variant="outlined" onClick={setColonia} disabled={correctText === ''}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={'Colonias a Revisar'} />
            <CardContent>
              {isLoading ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <List dense component="div" role="list" sx={{ height: '500px', overflowY: 'auto' }}>
                  {data
                    .filter((item) => item.checked === 0)
                    .map((item, index) => {
                      const labelId = `transfer-list-item-${item}-label`;

                      return (
                        <ListItemButton key={index} role="listitem" onClick={handleToggle(item, index)}>
                          <ListItemIcon>
                            <Checkbox
                              checked={item.checked === 1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={`${item.label}`} />
                        </ListItemButton>
                      );
                    })}
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader title={'Colonias con similitudes'} />
            <CardContent sx={{ pt: 0 }}>
              {isLoading ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Alert variant="outlined" severity="info" sx={{ borderColor: 'info.main', mb: 1 }}>
                    Da click en el check para regresarlo, al dar click en el elemento se rellenara por defecto
                    el campo "Nombre Nuevo"
                  </Alert>

                  <List
                    dense
                    component="div"
                    role="list"
                    sx={{ height: '100%', minHeight: '300px', overflowY: 'auto' }}
                  >
                    {allData
                      .filter((item) => item.checked === 1)
                      .map((item, index) => {
                        const labelId = `transfer-list-item-${item}-label`;

                        return (
                          <ListItemButton key={index} role="listitem">
                            <ListItemIcon>
                              <Checkbox
                                checked={item.checked === 1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                                onClick={handleQuit(item, index)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={`${item.label}`}
                              onClick={() => handleCorrectText(item.ColoniaVive)}
                            />
                          </ListItemButton>
                        );
                      })}
                  </List>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Nombres;
