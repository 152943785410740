import { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Alert,
  Radio,
  FormControlLabel,
  RadioGroup,
  Divider,
  TableHead,
  Stack,
  FormControl,
  Card,
  CardContent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

import DaySchedule from '@components/Schedules/DaySchedule';

import { WeekScheduleInterface } from '@interfaces/ScheduleInterfaces';
import { WeekScheduleSchema } from '@schemas/ScheduleSchemas';
import schedule from '@services/ScheduleServices';

const WeekSchedule = (props) => {
  const { userId, name, setLoading, setShowSearch } = props;
  const cat_days = [
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
    { value: 7, label: 'Domingo' },
  ];
  const radioOptions = [
    { value: 1, label: 'Horarios de acceso Web' },
    { value: 2, label: 'Horarios de acceso App' },
    { value: 3, label: 'Registrar excepciones Web' },
    { value: 4, label: 'Registrar excepciones App' },
  ];

  const [params, setParams] = useState({
    idTipoAcceso: 1,
    idUsuario: null,
    selectedOption: 1,
  });
  const [selectedOption, setSelectedOption] = useState(1);
  const [swalData, setSwalData] = useState({
    title: '¡Advertencia!',
    text: '',
    icon: 'warning',
  });
  const [hasResults, setHasResults] = useState(false);

  const formik = useFormik({
    initialValues: WeekScheduleInterface,
    validationSchema: WeekScheduleSchema,
    onSubmit: async (values) => {
      await handleSave(values);
    },
  });

  useEffect(() => {
    if (userId) {
      setParams({ ...params, idUsuario: userId });
    } else {
      setParams({ ...params, idUsuario: null });
      setDefaultValues();
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption) {
        case 1:
          setSwalData({
            ...swalData,
            text: 'Esta acción afectará el horario de acceso de <strong>TODOS</strong> los usuarios en el <strong>Sistema WEB</strong>.<br/><br/>¿Qué acción desea realizar?',
            confirmButtonText: 'Aplicar',
          });
          break;
        case 2:
          setSwalData({
            ...swalData,
            text: 'Esta acción afectará el horario de acceso de <strong>TODOS</strong> los usuarios en la <strong>Aplicación móvil</strong>.<br/><br/>¿Qué acción desea realizar?',
            confirmButtonText: 'Aplicar',
          });
          break;
        case 3:
        case 4:
          setSwalData({
            ...swalData,
            text: `¿Deseas modificar el horario del usuario ${name}?<br>`,
            confirmButtonText: 'Sí, continuar',
          });
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (params) {
      ApiResponse(params);
    }
    // eslint-disable-next-line
  }, [params]);

  const ApiResponse = async (params) => {
    if ([3, 4].includes(params.selectedOption) && !params.idUsuario) {
      setDefaultValues();
      return;
    }

    setLoading(true);

    try {
      const data = {
        idTipoAcceso: params.idTipoAcceso,
        idUsuario: params.idUsuario,
      };
      const result = await schedule.getSchedules(data);
      const { results, response } = result;

      if (results) {
        formik.setValues({
          idTipoAcceso: params.idTipoAcceso,
          idUsuario: params.idUsuario,
          Horarios: response.data.map((item) => ({
            idDia: item.idDia,
            AccesoTotal: item.AccesoTotal,
            HoraInicio: item.HoraInicio ? item.HoraInicio.substring(0, 5) : '',
            HoraCierre: item.HoraCierre ? item.HoraCierre.substring(0, 5) : '',
          })),
        });
        setHasResults(() => [3, 4].includes(params.selectedOption));
      } else {
        setDefaultValues();
        setHasResults(false);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
      setDefaultValues();
      setHasResults(false);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);

    if ([3, 4].includes(value)) {
      setParams({
        ...params,
        idTipoAcceso: value === 3 ? 1 : 2,
        selectedOption: value,
      });
      setShowSearch(true);
    } else if ([1, 2].includes(value)) {
      setParams({
        idTipoAcceso: value === 1 ? 1 : 2,
        selectedOption: value,
        idUsuario: null,
      });
      setShowSearch(false);
    }
  };

  const setDefaultValues = () => {
    formik.setValues({
      idTipoAcceso: params.idTipoAcceso,
      idUsuario: params.idUsuario,
      Horarios: cat_days.map((item) => ({
        idDia: item.value,
        AccesoTotal: 0,
        HoraInicio: '',
        HoraCierre: '',
      })),
    });
  };

  const handleSave = async (values) => {
    if ([3, 4].includes(params.selectedOption) && !params.idUsuario) {
      formik.setFieldError('idUsuario', 'Seleccione un usuario');
      return;
    }

    const confirmation = await Swal.fire({
      title: swalData.title,
      html: `${swalData.text}`,
      icon: swalData.icon,
      showCancelButton: true,
      confirmButtonText: swalData.confirmButtonText,
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (confirmation.isConfirmed) {
      setLoading(true);

      try {
        const result = await schedule.setSchedules(values);
        const { message, results } = result;
        if (results) {
          Swal.fire({
            title: message,
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              ApiResponse(params);
            }
          });
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    const access_type = params.idTipoAcceso === 1 ? 'web' : 'app';
    const confirmation = await Swal.fire({
      title: 'Advertencia',
      html: `Esta acción eliminará la excepción de acceso ${access_type} registrada para el usuario seleccionado.<br> ¿Qué acción desea realizar?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (confirmation.isConfirmed) {
      try {
        setLoading(true);
        const data = {
          idTipoAcceso: params.idTipoAcceso,
          idUsuario: params.idUsuario,
        };
        const result = await schedule.deleteScheduleException(data);
        const { results, message } = result;

        if (results) {
          Swal.fire({
            title: message,
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              setDefaultValues();
              setHasResults(false);
            }
          });
        } else {
          Swal.fire({
            title: message,
            icon: 'warning',
          });
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: 'warning',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Alert variant="outlined" severity="info" sx={{ mt: 2, borderColor: 'info.main' }}>
          Nota: Registra el horario de uso del sistema, amplíalo según los días necesarios. Si tienes alguna
          excepción con algún usuario y necesitas modificar su acceso respecto a la configuración
          predeterminada, utiliza la búsqueda y selección del usuario
        </Alert>
        <Grid container spacing={1} mt={1}>
          <FormControl>
            <RadioGroup
              row
              aria-label="options"
              name="options"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {radioOptions.map(({ value, label }) => (
                <Grid item xs={12} lg={3} key={value}>
                  <FormControlLabel
                    value={value}
                    control={<Radio color="primary" />}
                    label={label}
                    labelPlacement="top"
                  />
                </Grid>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Divider sx={{ mt: 1 }} />

        <Box sx={{ overflowX: 'auto', marginTop: '1%' }}>
          <Table size="small" sx={{ margin: 'auto', border: 1, borderColor: 'rgba(189, 189, 189, 0.3)' }}>
            <TableHead sx={{ backgroundColor: 'rgba(189, 189, 189, 0.3)' }}>
              <TableRow>
                <TableCell>Seleccione en caso de</TableCell>
                <TableCell>Día</TableCell>
                <TableCell colSpan={2}>Hora inicio</TableCell>
                <TableCell colSpan={2}>Hora término</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cat_days.map((item) => {
                return (
                  <DaySchedule
                    key={item.value}
                    day={item}
                    values={formik.values}
                    setValues={formik.setValues}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
        {formik.errors.idUsuario && (
          <Box sx={{ overflowX: 'auto', marginTop: '1%' }}>
            <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
              Seleccione un usuario
            </Alert>
          </Box>
        )}

        <Stack direction={'row'} mt={2} display="flex" justifyContent="center" spacing={1}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={formik.handleSubmit}
            size="small"
            style={{ marginTop: '1%' }}
            startIcon={<SaveIcon />}
          >
            Guardar
          </Button>
          {[3, 4].includes(params.selectedOption) && hasResults && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              size="small"
              style={{ marginTop: '1%' }}
              endIcon={<DeleteIcon />}
            >
              Eliminar Excepción
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default WeekSchedule;
