import { PrivilegedRoute } from '@middlewares';

import Mesa from '../../pages/Mesa/Mesa';

const MesaRoutes = [
  {
    path: '/buscar-mesa',
    element: (
      <PrivilegedRoute>
        <Mesa />
      </PrivilegedRoute>
    ),
  },
];

export default MesaRoutes;
