import { ApiExecDecrypted, downloadFile } from '@utils/ApiExec';

class MapService {
  getSections = (params) => {
    return ApiExecDecrypted(params, 'map/national/get-sections', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBlocks = (params) => {
    return ApiExecDecrypted(params, 'map/national/get-blocks', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getShapes = (params) => {
    return ApiExecDecrypted(params, 'map/national/get-shapes', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getShapesElectoral = (params) => {
    return ApiExecDecrypted(params, 'map/national/get-shapes-electoral', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  downloadShapeCartography = (params) => {
    return downloadFile({
      api: 'map/national/get-shape-cartography',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };
}

const map = new MapService();

export default map;
