import React, { useEffect, useState } from 'react';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  TableSortLabel,
  TablePagination,
  IconButton,
  Icon,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';

import movilization from '@services/MovilizationServices';
import { Delete } from '@mui/icons-material';
import middleware from '@middlewares/middleware';

const CompormisosTable = (props) => {
  const { box, setIsLoadingForm, setOpenLinearProgress /* loading */ } = props;
  const { height } = useWindowDimensions();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const cellBorderStyle = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const headerCellStyle = {
    backgroundColor: '#b1b1b1',
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const middleWares = {
    delete: middleware.checkMenuAction('Eliminar'),
  };

  useEffect(() => {
    const loadData = async () => {
      if (box) {
        await getUsersVoted(box);
      } else {
        setData([]);
      }
    };

    loadData();
    // eslint-disable-next-line
  }, [box]);

  const getUsersVoted = async (idCasilla) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setData([]);

    try {
      const result = await movilization.getBingosList({ idCasilla: 1 });
      const { results, response, message } = result;

      if (results) {
        setData(mapData(response.data));
      } else {
        Swal.fire({
          icon: 'warning',
          title: message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: error.message,
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const mapData = (data) =>
    data.map((item, index) => ({
      accion: middleWares.delete ? (
        <IconButton onClick={() => handleDeleteClick(item)} sx={{ m: 0, p: 0 }} color="error">
          <Delete />
        </IconButton>
      ) : null,
      ...item,
    }));

  const handleSort = (field) => {
    if (orderBy === field && order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }
    setOrderBy(field);
  };

  const handleDeleteClick = (item) => {
    Swal.fire({
      title: 'Advertencia!',
      text: '¿Está seguro que desea eliminar el registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'No, Cancelar',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Implementa aquí la lógica para eliminar la fila en el estado 'data'
        // setHighlightedRow(-1);
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // );
        //deleteBingo(item)
      }
    });
  };

  const sortedData = [...data].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy]?.toString().localeCompare(b[orderBy]?.toString()) || 0;
    } else {
      return b[orderBy]?.toString().localeCompare(a[orderBy]?.toString()) || 0;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStripedStyle = (index, row) => {
    return row.style && row.style.background ? row.style : { background: index % 2 ? '#E4ECFA' : 'white' };
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: 4 }}>
      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: height * 0.7 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle, width: '10px' }}>
                  ACCIÓN
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle, width: '15px' }}>
                  <TableSortLabel
                    active={orderBy === 'Seccion'}
                    direction={order}
                    onClick={() => handleSort('Seccion')}
                  >
                    SECCIÓN
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle, width: '15px' }}>
                  <TableSortLabel
                    active={orderBy === 'NombreCasilla'}
                    direction={order}
                    onClick={() => handleSort('NombreCasilla')}
                  >
                    CASILLA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle, width: '150px' }}>
                  <TableSortLabel
                    active={orderBy === 'Hora'}
                    direction={order}
                    onClick={() => handleSort('Hora')}
                  >
                    HORA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Nombre'}
                    direction={order}
                    onClick={() => handleSort('Nombre')}
                  >
                    VOTANTE
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle, width: '200px' }}>
                  <TableSortLabel
                    active={orderBy === 'Usuario'}
                    direction={order}
                    onClick={() => handleSort('Usuario')}
                  >
                    USUARIO
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length !== 0 ? (
                sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      ...getStripedStyle(index, row),
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      },
                    }}
                  >
                    <TableCell style={{ ...cellBorderStyle }}>{row.accion}</TableCell>
                    <TableCell style={{ ...cellBorderStyle }}>{row.Seccion}</TableCell>
                    <TableCell style={{ ...cellBorderStyle }}>{row.NombreCasilla}</TableCell>
                    <TableCell style={{ ...cellBorderStyle }}>{row.Hora}</TableCell>
                    <TableCell style={{ ...cellBorderStyle }}>{row.Nombre || 'Nombre del usuario'}</TableCell>
                    <TableCell style={{ ...cellBorderStyle }}>{row.Usuario}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Icon fontSize={'large'}>info</Icon>
                    <Typography>Sin Registros</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 350, 500]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}
        />
      </CardContent>
    </Card>
  );
};

export default CompormisosTable;
