import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { Grid, CardContent, Card } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import TableScroll from '@components/Structure/TableScroll';

// Servicios
import SocialServices from '@services/SocialServices';

const SeguimientoCompromisos = () => {
  const [dataCard, setDataCard] = useState({
    Total: 0,
    Avance: 0,
    PorcentajeAvance: 0,
    Pendiente: 0,
    PorcentajePendiente: 0,
    Contactado: 0,
    PorcentajeContactado: 0,
    NoLocalizado: 0,
    PorcentajeNoLocalizado: 0,
  });
  const [loadingCard, setLoadingCard] = useState(false);

  useEffect(() => {
    getCards([]);
  }, []);

  const getCards = async (filtered) => {
    try {
      setLoadingCard(true);

      const result = await SocialServices.getCardsContactoEstatus({ filtered });
      const { results, response, message } = result;

      if (results) setDataCard(response);
      else throw new Error(message);
    } catch (e) {
      setDataCard({});
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingCard(false);
    }
  };

  const handleFilter = (data) => getCards(data);

  return (
    <AdminLayout>
      <ActionHeader title={'Movilización Social'} />
      <Grid container spacing={2} sx={{ flexDirection: { sm: 'column-reverse', md: 'row' } }}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          <Card>
            <CardContent>
              <TableScroll onFilter={handleFilter} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <Grid container spacing={2}>
            {/*            <Grid item xs={12} sm={6} md={12}>
              <AdaptiveCard
                title="Participación"
                icon="people"
                config={{ percent: dataCard?.PorcentajeAvance || 0 }}
                leftConfig={{ title: "Avance", data: dataCard?.Avance || 0 }}
                rightConfig={{ title: "Total", data: dataCard?.Total || 0 }}
                fixed
                adjust
                loading={loadingCard}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={12}>
              <AdaptiveCard
                config={{
                  title: 'Contactados',
                  data: dataCard?.Contactado || 0,
                  percent: dataCard?.PorcentajeContactado || 0,
                }}
                leftConfig={{
                  title: 'Pendientes',
                  data: dataCard?.Pendiente || 0,
                  percent: dataCard?.PorcentajePendiente || 0,
                }}
                rightConfig={{
                  title: 'No localizados',
                  data: dataCard?.NoLocalizado || 0,
                  percent: dataCard?.PorcentajeNoLocalizado || 0,
                }}
                fixed
                adjust
                loading={loadingCard}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
export default SeguimientoCompromisos;
