import React, { useState, useEffect } from 'react';

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Icon,
  ListItemText,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';

//components
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import Filter from '@components/Compromisos/Filter';
import PlacementColumns from '@components/Charts/DashChart';
import BasicTable from '@components/Tables/BasicTable';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';
import CompromisoServices from '@services/CompromisoServices';
import CardStats from '@components/Card/CardStats';
//Plugins
import Swal from 'sweetalert2';
import moment from 'moment';
import { getVars } from '@utils/global';
import { numberWithCommas } from '@utils/Utilities';
import { isEmptyOrNullObject } from '@utils/validations';
import middleware from '@middlewares/middleware';

const CompromisosMunicipiosPrioritarios = () => {
  //variables
  const columns = [
    { id: 'idRegion', label: 'Región', align: 'center' },
    { id: 'Municipio', label: 'Municipio' },
    { id: 'MetaCompromisos', label: 'Meta' },
    { id: 'AvanceCompromisos', label: 'Avance', align: 'center' },
    { id: 'pavance', label: '% Avance', align: 'center' },
  ];
  const {
    user: { ambito_perfil },
  } = getVars('Token');

  const isUsuarioEstatal = ambito_perfil.some((item) => item.idAmbitoVisualizacion === 1);

  //States
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [commitments, setCommitments] = useState([]);
  const [dataChart, setDataChart] = useState({
    title: '',
    date: '',
    categories: [],
    series1: [],
    series2: [],
  });
  const [summary, setSummary] = useState({
    FechaActualizo: '',
    Lnom: 0,
    TotalCompromisos: 0,
    AvanceCompromisos: 0,
    avance: 0,
    pavance: 0,
    CapturadosHoy: '',
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 50,
    filtered: [],
    sorted: [{ id: 'M.AvanceCompromisos', value: 'DESC' }],
  });
  const [sortedData, setSortedData] = useState([]);
  const [groupByChart, setGroupByChart] = useState({
    groupBy: 'RL',
    filtered: [],
  });

  //  console.log();
  //Functions Api's
  const getCommitments = () => {
    setLoading(true);
    CompromisoServices.getListPrioritarios(data)
      .then((res) => {
        if (res.results) {
          const commitments = res.response.data.map((commitment) => {
            return {
              ...commitment,
              Municipio: (
                <ListItemText
                  primary={
                    <Typography variant="div" fontWeight={700} fontSize={15} color={commitment.Color}>
                      {`${commitment.Municipio || ''}`}
                    </Typography>
                  }
                  secondary={`Hoy ${commitment.compromisosHoy}`}
                />
              ),
              Lnom: commitment.Lnom,
              idRegion: commitment.idRegion,
              MetaCompromisos: numberWithCommas(commitment.MetaCompromisos),
              AvanceCompromisos: numberWithCommas(commitment.AvanceCompromisos),
              avance: commitment.avance,
              pavance: `${parseFloat(commitment.pavance).toFixed(2)} %`,
              compromisosHoy: commitment.compromisosHoy,
            };
          });

          const series1 = res.response.data.map((objeto) => objeto.MetaCompromisos);
          const series2 = res.response.data.map((objeto) => objeto.AvanceCompromisos);

          const categories = res.response.data.map((objeto) => objeto.Municipio);

          setDataChart({
            ...dataChart,
            title: 'Avance Municipios Prioritarios',
            date: commitments[0].FechaActualizo,
            categories,
            series1,
            series2,
          });

          setCommitments(commitments);
          setTotal(commitments.length);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };

  const CellResponsibility = (value) => {
    if (value === null) return 'Sin Responsabiliad';

    return (
      <>
        {value.split('|').map((item) => (
          <Typography>{item}</Typography>
        ))}
      </>
    );
  };

  const getSummary = () => {
    setLoading(true);
    const filtered = data.filtered.length > 0 ? data.filtered : groupByChart.filtered;
    CompromisoServices.getSummaryPrioritarios({ filtered: filtered })
      .then((res) => {
        console.log('=>res: ', res);
        if (res.results) {
          const { CapturadosHoy, TotalCompromisos, AvanceCompromisos, Lnom, FechaActualizo, pavance } =
            res.response;

          setSummary({
            FechaActualizo: moment(FechaActualizo).format('DD/MM/YYYY H:mm'),
            Lnom: Lnom !== null ? numberWithCommas(Lnom) : 0,
            TotalCompromisos: TotalCompromisos !== null ? numberWithCommas(TotalCompromisos) : 0,
            AvanceCompromisos: AvanceCompromisos !== null ? numberWithCommas(AvanceCompromisos) : 0,
            pavance,
            CapturadosHoy: CapturadosHoy !== null ? numberWithCommas(CapturadosHoy) : 0,
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: 'warning' }))
      .finally(() => setLoading(false));
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Region: 'M.idRegion',
      MetaCompromisos: 'M.MetaCompromisos',
      AvanceCompromisos: 'M.AvanceCompromisos',
      pavance: 'M.avance',
    };

    let sorted = [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...data,
        sorted: sortedData,
      };

      setData(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  useEffect(() => {
    getCommitments();
    getSummary();
    //eslint-disable-next-line
  }, [data]);

  //Functions and Handlers
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleExport = () => {
    setIsDownloading((prevState) => !prevState);

    setTimeout(() => {
      setIsDownloading((prevState) => !prevState);
      setShowMessage(true);
      setSuccessDownload(true);
    }, 3000);
  };

  const handleChangeFilter = (type) => {
    const {
      Region,
      Municipio,
      Poligono,
      Seccion,
      Tipo,
      Responsabilidad,
      Celular,
      ClaveElector,
      NombreCompleto,
      Calle,
      Colonia,
      GroupBy,
      DistritoFederal,
      DistritoLocal,
      //Ranking,
    } = type;

    let filtered = [];
    let filteredChart = [];
    let groupBy = '';

    if (Region > 0) {
      filteredChart.push({
        id: 'cat_secciones.idRegion',
        filter: '=',
        value: Region,
      });
      filtered.push({
        id: 'cat_secciones.idRegion',
        filter: '=',
        value: Region,
      });
    }
    if (Municipio > 0) {
      filteredChart.push({
        id: 'cat_secciones.idMunicipioReportes',
        filter: '=',
        value: Municipio,
      });
      filtered.push({
        id: 'cat_secciones.idMunicipioReportes',
        filter: '=',
        value: Municipio,
      });
    }
    if (Poligono.length > 0) {
      filteredChart.push({
        id: 'cat_secciones.idPoligono',
        filter: 'IN',
        value: Poligono,
      });
      filtered.push({
        id: 'cat_secciones.idPoligono',
        filter: 'IN',
        value: Poligono,
      });
    }
    if (Seccion.length > 0) {
      filteredChart.push({
        id: 'cat_secciones.Seccion',
        filter: 'IN',
        value: Seccion,
      });
      filtered.push({
        id: 'cat_secciones.Seccion',
        filter: 'IN',
        value: Seccion,
      });
    }
    if (Tipo > 0) {
      filtered.push({ id: 'p.idTipo', filter: '=', value: Tipo });
    }
    if (Responsabilidad > 0) {
      filtered.push({ id: 'up.idPerfil', filter: '=', value: Responsabilidad });
    }
    if (Celular.length > 0) {
      filtered.push({
        id: 'compromisos_unicos.Celular',
        filter: 'LIKE',
        value: Celular,
      });
    }
    if (ClaveElector.length > 0) {
      filtered.push({
        id: 'compromisos_unicos.INE',
        filter: '=',
        value: ClaveElector,
      });
    }
    if (NombreCompleto.length > 0) {
      filtered.push({
        id: ['compromisos_unicos.Nombre', 'compromisos_unicos.Paterno', 'compromisos_unicos.Materno'],
        filter: 'LIKE',
        value: NombreCompleto,
      });
    }
    if (Calle.length > 0) {
      filtered.push({
        id: 'compromisos_unicos.CalleVive',
        filter: 'LIKE',
        value: Calle,
      });
    }
    if (Colonia.length > 0) {
      filtered.push({
        id: 'compromisos_unicos.ColoniaVive',
        filter: 'LIKE',
        value: Colonia,
      });
    }
    if (GroupBy.length > 0) {
      groupBy = GroupBy;
    }
    if (DistritoFederal > 0) {
      filteredChart.push({
        id: 'cat_secciones.idDF',
        filter: '=',
        value: DistritoFederal,
      });
    }
    if (DistritoLocal > 0) {
      filteredChart.push({
        id: 'cat_secciones.idDL',
        filter: '=',
        value: DistritoLocal,
      });
    }

    setGroupByChart({
      ...groupByChart,
      groupBy: groupBy,
      filtered: filteredChart,
    });

    setData({
      ...data,
      filtered: filtered,
    });
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={'Municipios Prioritarios'} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'fact_check'}
              title={'Capturados Hoy'}
              total={summary.CapturadosHoy}
              subIcon={'calendar_today'}
              subtitle={moment().format('DD/MM/YYYY H:mm')}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'groups'}
              title={'Meta Compromisos'}
              total={summary.TotalCompromisos}
              subIcon={'sync'}
              subtitle={'Actualización'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStats
              icon={'diversity_3'}
              title={'Avance Compromisos'}
              total={summary.AvanceCompromisos}
              subIcon={'schedule'}
              subtitle={`${parseFloat(summary.pavance).toFixed(2)}%`}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            {isLoadingChart ? (
              <CircularProgress />
            ) : (
              <PlacementColumns
                titulo={{ title: dataChart.title }}
                subtitle={{
                  subtitle: moment(dataChart.date).format('DD/MM/YYYY H:mm'),
                }}
                datos={{
                  categories: dataChart.categories,
                  series: [
                    {
                      name: 'Meta',
                      color: 'rgba(0,65,165,1)',
                      data: dataChart.series1,
                      pointPadding: 0.3,
                      pointPlacement: -0.2,
                    },
                    {
                      name: 'Avance',
                      color: 'rgba(147,220,236,.9)',
                      data: dataChart.series2,
                      pointPadding: 0.4,
                      pointPlacement: -0.2,
                    },
                  ],
                }}
                loading={isLoadingChart}
              />
            )}
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            <Filter
              onChangeFilter={(e) => handleChangeFilter(e)}
              data={data}
              setData={setData}
              setGroupByChart={setGroupByChart}
              isUsuarioEstatal={isUsuarioEstatal}
            />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            {isDownload ? (
              <Download format={'xlsx'} isDownload={isDownload} />
            ) : middleware.checkMenuAction('Exportar') ? (
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: '10px' }}
                >
                  Exportar
                </Button>
              </Box>
            ) : (
              <div></div>
            )}

            {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}

            <BasicTable
              rows={commitments}
              hcolumns={columns}
              pageProp={data.page}
              pageSize={data.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              handleManualSort={handleRequestSort}
              isLoading={loading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default CompromisosMunicipiosPrioritarios;
