import React, { useState, useContext } from 'react';
import {
  InputAdornment,
  IconButton,
  Button,
  Icon,
  TextField,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { isEmptyOrInvalidString } from '@utils/validations';
import UserUpdateContext from './UserUpdateContext';
import { UserPasswordInterface } from '@data/interfaces/UserInterfaces';
import { UserPasswordSchema } from '@data/schemas/UserSchemas';

const UserPassword = (props) => {
  const { idUsuario, API } = props;
  const { setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm } = useContext(UserUpdateContext);

  const [flags, setFlags] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const checkPasswordStrength = (password) => {
    const strengthResults = [
      /[A-Z]/.test(password),
      /[0-9]/.test(password),
      /[!-/:-@[-`{-~]/.test(password),
      password.length >= 8,
    ];

    return strengthResults.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const getPasswordStrengthMessage = (strength) => {
    switch (strength) {
      case 0:
        return 'Contraseña muy débil';
      case 1:
        return 'Contraseña débil';
      case 2:
      case 3:
        return 'Contraseña medianamente segura';
      case 4:
        return 'Contraseña segura';
      default:
        return '';
    }
  };

  const formik = useFormik({
    initialValues: UserPasswordInterface,
    validationSchema: UserPasswordSchema,
    onSubmit: (values) => {
      values.id = idUsuario;
      const strength = checkPasswordStrength(values.Password);

      if (strength >= 4) {
        handleSave(values);
      } else {
        Swal.fire({
          title: 'Contraseña no cumple con los requisitos',
          html: 'La contraseña debe contener al menos 1 letra mayúscula, 1 dígito y mínimo 8 caracteres.',
          icon: 'warning',
        });
      }
    },
  });

  const handleSave = async (data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const result = await API(data);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
        }).then((res) => {
          if (res) {
            formik.resetForm();
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
        setIsSuccessForm(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
      setIsSuccessForm(false);
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleClickShowPassword = (prop) => (event) => {
    setFlags({ ...flags, [prop]: !flags[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCleanForm = (e) => {
    formik.setValues(UserPasswordInterface);
  };

  const passwordStrength = checkPasswordStrength(formik.values.Password);

  return (
    <>
      <Grid item xs={12} sm={12}>
        <TextField
          error={formik.touched.Password && !isEmptyOrInvalidString(formik.errors.Password)}
          label="Contraseña"
          helperText={formik.touched.Password && formik.errors.Password && formik.errors.Password}
          type={flags.showPassword ? 'text' : 'password'}
          name="Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Password}
          size="small"
          fullWidth
          className="fixed-input"
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          error={formik.touched.Confirm && !isEmptyOrInvalidString(formik.errors.Confirm)}
          label="Confirmar contraseña"
          helperText={formik.touched.Confirm && formik.errors.Confirm && formik.errors.Confirm}
          type={flags.showConfirmPassword ? 'text' : 'password'}
          name="Confirm"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Confirm}
          size="small"
          fullWidth
          className="fixed-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showConfirmPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showConfirmPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ width: '255px', mt: 1 }}>
        <LinearProgress
          variant="determinate"
          value={(passwordStrength / 4) * 100}
          color={passwordStrength === 4 ? 'success' : passwordStrength >= 2 ? 'warning' : 'error'}
          sx={{ height: 8 }}
        />
        <Typography variant="caption" sx={{ textAlign: 'end', mt: 1 }}>
          <strong>{getPasswordStrengthMessage(passwordStrength)}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: '5vh', textAlign: 'end' }}>
        <Button variant="outlined" size="small" onClick={handleCleanForm} startIcon={<Icon>cancel</Icon>}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primaryDark"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
          disabled={!idUsuario}
          sx={{ ml: 1 }}
        >
          Guardar
        </Button>
      </Grid>
    </>
  );
};

export default UserPassword;
