// Material UI
import { Grid, Chip } from '@mui/material';

/* const elements = [
  { title: "Sin asignar", color: "#FF5733", fontColor:"#FFFFFF" },
  { title: "Faltantes", color: "#FFC300" },
  { title: "Completadas", color: "rgba(75, 181, 67, 0.5)" },
]; */

const Colors = (props) => {
  const { elements, height = 24, spacing = 0.75 } = props;

  return (
    <Grid container rowSpacing={spacing} sx={{ width: '115px', paddingBottom: 0.5 }}>
      {elements.map((elem, index) => (
        <Grid item key={index} xs={12}>
          <Chip
            label={elem.title}
            sx={{
              width: '100%',
              height: height,
              backgroundColor: elem.color,
              fontWeight: 600,
              fontStretch: 1.2,
              color: elem.fontColor,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Colors;
