import { ApiExec, ApiExecDecrypted } from '@utils/ApiExec';

class MapService {
  getSections = (params) => {
    return ApiExecDecrypted(params, 'map/routes/get-sections', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setRoute = (params) => {
    return ApiExec(params, 'map/routes/set-route', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getRoutes = (params) => {
    return ApiExec(params, 'map/routes/get-routes', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateRoute = (params) => {
    return ApiExec(params, 'map/routes/update-route', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteRoute = (params) => {
    return ApiExec(params, 'map/routes/delete-route', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getInformationMunicipality = (params) => {
    return ApiExec(params, 'map/routes/get-information-municipality', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  ListProgress = (params) => {
    return ApiExec(params, 'map/routes/get-casillas-information', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  TotalsProgress = (params) => {
    return ApiExec(params, 'map/routes/get-casillas-totals', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getRGs = (params) => {
    return ApiExec(params, 'electoral/get-rgs-routes', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAbogados = (params) => {
    return ApiExec(params, 'electoral/get-abogados-routes', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const map = new MapService();
export default map;
