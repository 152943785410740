export const PAGINA_PPM = 1;
export const PAGINA_FOCA = 2;

export const COLUMNS_NOTICIAS = (value) => {
  const data = [
    { id: 'Tipo', label: 'Tipo', type: 'text' },
    { id: 'FechaPublicacion', label: 'Publicación', type: 'text' },
    { id: 'Titulo', label: 'Titulo', type: 'text' },
    { id: 'Descripcion', label: 'Descripción', type: 'text' },
    // { id: "Contenido", label: "Contenido", type: "text" },
    { id: 'FileNameButton', label: 'Imagen', type: 'text' },
  ];

  if (value)
    data.push({ id: 'actions', label: 'Acciones', sort: false, align: 'center', disablePadding: true });

  return data;
};
