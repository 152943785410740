import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Swal from 'sweetalert2';

import LoadingForm from '@components/LinearProgress/LoadingForm';
import VolunteerInfo from '@components/Volunteers/VolunteerInfo';
import GlobalForm from '@components/Volunteers/GlobalForm';
import VolunteerServices from '@services/VolunteerServices';
import middleware from '@middlewares/middleware';
import { TIPO_SOCIAL, TIPO_GRAL } from '@data/constants';
import { ascending } from '@utils/Utilities';

const VolunteerForm = (props) => {
  const { setView, volunteer, catalogs, onSubmit } = props;

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [catResponsibilitiesType, setCatResponsibilitiesType] = useState([]);
  const [catResponsibilities, setCatResponsibilities] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [formType, setFormType] = useState(0);
  const [insaculado, setInsaculado] = useState(0);

  useEffect(() => {
    if (catalogs) {
      setCatResponsibilitiesType(() => {
        let _array = catalogs.perfiles_hijos
          .map((item) => {
            return {
              idTipo: item.idTipo,
              Tipo: item.Tipo,
            };
          })
          .filter((item) => item.idTipo && ![TIPO_SOCIAL, TIPO_GRAL].includes(item.idTipo));

        _array = [...new Set(_array.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
        return _array.sort(ascending((val) => val.idTipo));
      });
    }
  }, [catalogs]);

  useEffect(() => {
    if (catResponsibilitiesType && catResponsibilitiesType.length > 0) {
      handleClickResponsibility(catResponsibilitiesType[0].idTipo);
    }
    //eslint-disable-next-line
  }, [catResponsibilitiesType]);

  const handleClickBackButton = () => {
    setView('table');
  };

  const handleClickResponsibility = (idTipo) => {
    setFormType(idTipo);
    setCatResponsibilities(catalogs.perfiles_hijos.filter((row) => row.idTipo === parseInt(idTipo)));
  };

  const handleSubmit = async (values) => {
    if (!volunteer.Celular || volunteer.Celular.trim().length !== 10) {
      const res = await Swal.fire({
        title: '¡Advertencia!',
        html: `<p>El voluntario no tiene un número de celular registrado; por lo que se asignará la responsabilidad sin proporcionarle acceso al sistema. Si requiere que la persona tenga acceso, favor de editar el compromiso y llenar el dato "celular"</p>`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `Continuar`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-alert',
        },
      });

      if (res.isConfirmed) {
        return await setResponsibility(values);
      }
    } else {
      return await setResponsibility(values);
    }
  };

  const setResponsibility = async (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let newData = values;
    delete newData.idPefilAmbito;

    try {
      const result = await VolunteerServices.createResponsibility(newData);
      const { results, message } = result;
      if (results) {
        onSubmit(values);
        setAssigned([...assigned, values]);
        Swal.fire({
          title: message,
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: message,
          icon: 'warning',
        });
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  return (
    <Box>
      <LoadingForm
        loadinMessage={'Enviando datos...'}
        successMessage="¡Enviado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />

      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={12} md={4} lg={4}>
          <VolunteerInfo
            handleClick={handleClickBackButton}
            volunteer={volunteer}
            assigned={assigned}
            isInsaculado={(e) => {
              setInsaculado(e);
            }}
            setIsLoading={(e) => {
              setOpenLinearProgress(e);
              setIsLoadingForm(e);
            }}
          />
        </Grid2>

        <Grid2 xs={12} sm={12} md={8} lg={8}>
          <Card className="card-primary" sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
            <CardActions sx={{ overflow: 'auto' }}>
              <Grid2 container direction="column" justifyContent="center" alignItems="center">
                <Typography component={'h6'} variant="h6" fontWeight={800} fontSize={18}>
                  AGREGAR PROPUESTAS DE RESPONSABILIDAD
                </Typography>
                {middleware.checkMenuActionId('Agregar', 7) ? (
                  <Stack spacing={2} justifyContent="center" alignItems="center">
                    <Typography variant="div">Tipo de responsabilidad</Typography>
                    <ButtonGroup size="large" variant="outlined">
                      {catResponsibilitiesType.map((item) => (
                        <Button
                          color="info"
                          key={item.idTipo}
                          onClick={(e) => handleClickResponsibility(item.idTipo)}
                          variant={item.idTipo === formType ? 'contained' : 'outlined'}
                          sx={{
                            borderWidth: '1px',
                            borderRadius: '30px',
                            pr: '23 px',
                            pl: '23 px',
                          }}
                        >
                          {item.Tipo}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Stack>
                ) : (
                  <Grid2 container mt={4} justifyContent={'center'} alignContent={'center'}>
                    <Typography component={'h6'} variant="h6" fontWeight={300} fontSize={20} color={'error'}>
                      NO cuenta con los permisos suficientes
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
            </CardActions>
            <CardContent sx={{ overflow: 'auto' }}>
              <GlobalForm
                formType={formType}
                volunteer={volunteer}
                catResponsibilities={catResponsibilities}
                catalogs={catalogs}
                onSubmit={handleSubmit}
                assigned={assigned}
                isInsaculado={insaculado}
              />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
};

VolunteerForm.propTypes = {
  setView: PropTypes.func.isRequired,
  volunteer: PropTypes.object.isRequired,
  catalogs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VolunteerForm;
