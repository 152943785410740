import React, { useState, useEffect } from 'react';

//MUI
import { Box, Button, Grid, TextField } from '@mui/material';

//Components
import InputSelect from '@components/Selects/BasicSelect';
import FilterCollapse from '@components/Filters/FilterCollapse';
import { isTypePhone } from '@utils/validations';

//catalogos
import { useCatalogs } from '@hooks/useCatalogs';

// vars token
import { getVars } from '@utils/global';
import { data } from 'browserslist';

const FilterResponsable = ({
  onChangeFilter = () => {},
  filterData = true,
  region = true,
  municipioDL = true,
  Responsabilidad = true,
  refreash,
  leonAll = false,
  typeResponsables = 2,
  notPhone = false,
  formatos = false,
  estatus = false,
  dataResponsables = () => {},
}) => {
  const { user } = getVars('Token');
  // catalogo
  const catalogsParams = [
    { id: 'regiones', getAll: false },
    { id: 'municipios_dl', getAll: false },
    {
      id: 'perfiles_hijos',
      filtered: [{ id: 'perfiles.idTipo', filter: '=', value: typeResponsables }],
      getAll: false,
    },
    {
      id: 'perfiles_visualizacion',
      filtered: [{ id: 'perfiles.idTipo', filter: '=', value: typeResponsables }],
      getAll: false,
    },
    { id: 'estatus_certificacion_ine', getAll: false },
  ];

  //variables
  const defaultOption = [{ value: 0, label: 'TODOS' }];
  const defaultLeon = [{ value: 20, label: 'LEON' }];
  //States
  const [loading, setLoading] = useState([]);
  const [catResponsable, setCatResponsables] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipalityDL, setCatMunicipalityDL] = useState([]);
  const [clean, setClean] = useState(false);
  const [flagSUper, setFlagSUper] = useState(false);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    MunicipioDL: 0,
    Responsabilidad: 0,
    Manifiesto: 0,
    Universal: 0,
    INE: 0,
    estatus: 0,
  });
  const [filter, setFilter] = useState({
    Celular: '',
    NombreCompleto: '',
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsParams) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  useEffect(() => {
    clearFilter();
    if (load === false) {
      let viewAllRG = catalogs.perfiles_visualizacion.length > 0;
      switch (user.id) {
        case 3498: /*  MR */
        case 335:
          viewAllRG = false;
          break;
        default:
          viewAllRG = catalogs.perfiles_visualizacion.length > 0;
          break;
      }
      setFlagSUper(viewAllRG);
      setCatalogFiltered(catalogs);
      setCatRegionsFilter(catalogs?.regiones ? defaultOption.concat(catalogs.regiones) : defaultOption);
      setCatMunicipalityDL(
        catalogs?.municipios_dl
          ? leonAll
            ? defaultOption.concat(catalogs.municipios_dl.concat(defaultLeon))
            : defaultOption.concat(catalogs.municipios_dl)
          : defaultOption
      );
      setCatResponsables(
        viewAllRG
          ? defaultOption.concat(catalogs.perfiles_visualizacion)
          : defaultOption.concat([
              { label: 'RC', value: 2 },
              { label: 'RG', value: 3 },
            ])
      );
      dataResponsables(
        viewAllRG
          ? catalogs.perfiles_visualizacion
          : [
              { label: 'RC', value: 2 },
              { label: 'RG', value: 3 },
            ]
      );
    }
    // eslint-disable-next-line
  }, [load]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      MunicipioDL: 0,
    });
    // selectedPolygons([]);

    if (idRegion > 0) {
      console.log(
        defaultOption.concat(catalogsFiltered.municipios_dl.filter((item) => item.idRegion === idRegion))
      );
      setCatMunicipalityDL(
        leonAll
          ? defaultOption.concat(
              catalogsFiltered.municipios_dl.filter((item) => item.idRegion === idRegion).concat(defaultLeon)
            )
          : defaultOption.concat(catalogsFiltered.municipios_dl.filter((item) => item.idRegion === idRegion))
      );
      setCatMunicipalityDL(
        defaultOption.concat(catalogsFiltered.municipios_dl.filter((item) => item.idRegion === idRegion))
      );
    } else {
      setCatMunicipalityDL(
        leonAll
          ? defaultOption.concat(catalogs.municipios_dl.concat(defaultLeon))
          : defaultOption.concat(catalogs.municipios_dl)
      );
    }
  };

  const handleChangeMunicipalityDL = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      MunicipioDL: idMunicipality,
    });
  };

  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      MunicipioDL: dataCatalog.MunicipioDL,
      Responsabilidad: dataCatalog.Responsabilidad,
      Celular: filter.Celular,
      ClaveElector: filter.ClaveElector,
      NombreCompleto: filter.NombreCompleto,
      Manifiesto: dataCatalog.Manifiesto,
      Universal: dataCatalog.Universal,
      INE: dataCatalog.INE,
      estatus: dataCatalog.estatus,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      MunicipioDL: 0,
      Responsabilidad: 0,
      Celular: '',
      NombreCompleto: '',
    };
    setCatRegionsFilter(defaultOption.concat(catalogsFiltered.regiones));
    setCatMunicipalityDL(defaultOption.concat(catalogsFiltered.municipios_dl));
    setCatResponsables(defaultOption.concat(catalogsFiltered.perfiles_hijos));

    setDataCatalog({
      ...dataCatalog,
      Region: 0,
      MunicipioDL: 0,
      Responsabilidad: 0,
      Manifiesto: 0,
      Universal: 0,
      INE: 0,
      estatus: 0,
    });

    setFilter(newFilter);
    onChangeFilter(newFilter);
  };

  return (
    <>
      <FilterCollapse expand={false} effect={refreash}>
        <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
          {region && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Región"
                options={catRegionsFilter}
                name="region"
                value={catRegionsFilter.length === 2 ? catRegionsFilter[1].value : dataCatalog.Region}
                onChange={handleChangeRegion}
                disabled={catRegionsFilter.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {municipioDL && (
            <Grid item xs={12} md={4}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityDL}
                name="municipio"
                value={
                  catalogsFiltered?.municipios_dl
                    ? catalogsFiltered?.municipios_dl.length === 1
                      ? catalogsFiltered.municipios_dl[0].value
                      : dataCatalog.MunicipioDL
                    : catMunicipalityDL[0].value
                }
                onChange={handleChangeMunicipalityDL}
                disabled={
                  catalogsFiltered?.municipios_dl
                    ? catalogsFiltered.municipios_dl.length === 1
                      ? true
                      : false
                    : true
                }
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
          {Responsabilidad && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Responsabilidad"
                options={catResponsable}
                name="Responsabilidad"
                value={catResponsable.length === 2 ? catResponsable[1].value : dataCatalog.Responsabilidad}
                onChange={(e) => setDataCatalog({ ...dataCatalog, Responsabilidad: e.target.value })}
                disabled={catResponsable.length === 2 ? true : false}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}

          {formatos && (
            <>
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Manifiesto"
                  options={[
                    ...defaultOption,
                    { value: 1, label: 'Sin Archivo' },
                    { value: 2, label: 'Con Archivo' },
                  ]}
                  name="municipio"
                  value={dataCatalog.Manifiesto}
                  onChange={(e) => {
                    setDataCatalog({
                      ...dataCatalog,
                      Manifiesto: e.target.value,
                    });
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Formato Universal"
                  options={[
                    ...defaultOption,
                    { value: 1, label: 'Sin Archivo' },
                    { value: 2, label: 'Con Archivo' },
                  ]}
                  name="municipio"
                  value={dataCatalog.Universal}
                  onChange={(e) => {
                    setDataCatalog({
                      ...dataCatalog,
                      Universal: e.target.value,
                    });
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Formato INE"
                  options={[
                    ...defaultOption,
                    { value: 1, label: 'Sin Archivo' },
                    { value: 2, label: 'Con Archivo' },
                  ]}
                  name="municipio"
                  value={dataCatalog.INE}
                  onChange={(e) => {
                    setDataCatalog({
                      ...dataCatalog,
                      INE: e.target.value,
                    });
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </>
          )}

          {estatus && (
            <Grid item xs={12} md={4} sm={2}>
              <InputSelect
                label="Estatus"
                options={defaultOption.concat(catalogs.estatus_certificacion_ine)}
                name="estatus"
                value={dataCatalog.estatus}
                onChange={(e) => {
                  setDataCatalog({
                    ...dataCatalog,
                    estatus: e.target.value,
                  });
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}
        </Grid>
        {filterData && (
          <Grid container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
            {!notPhone && (
              <Grid item xs={12} md={4}>
                <TextField
                  label="Celular"
                  size="small"
                  name="Celular"
                  value={filter.Celular}
                  //onChange={hadleChangeTextField}
                  onChange={(e) =>
                    hadleChangeTextField({
                      target: {
                        name: 'Celular',
                        value: isTypePhone(e.target.value) ? e.target.value : filter.Celular,
                      },
                    })
                  }
                  sx={{ width: '100%' }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <TextField
                label="Nombre"
                size="small"
                name="NombreCompleto"
                value={filter.NombreCompleto.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        )}

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primaryDark" onClick={searchFilter}>
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default FilterResponsable;
