// Material UI
import { IconButton } from '@mui/material';
import { ControlCamera } from '@mui/icons-material';

const ControlNavigation = (props) => {
  const { isNavigate, handleNavigation, width = 0 } = props;
  return (
    <div className="leaflet-control-container">
      <div className="leaflet-top leaflet-left">
        <div className="leaflet-bar leaflet-control" style={{ marginLeft: width + 55 }}>
          <IconButton
            aria-label="Habilitar navegación"
            onClick={handleNavigation}
            style={{ backgroundColor: 'white' }}
            sx={{
              height: '30px',
              width: '30px',
              borderRadius: '3px',
              '&:hover': { backgroundColor: '#eeeeee!important' },
            }}
          >
            {!isNavigate && (
              <div
                style={{
                  position: 'absolute',
                  transform: 'rotate(45deg)',
                  width: 24,
                  height: 2,
                  backgroundColor: '#000',
                }}
              />
            )}
            <ControlCamera style={{ fontSize: 25, color: '#000' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ControlNavigation;
