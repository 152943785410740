import ApiExec, { downloadFile } from '@utils/ApiExec';

class SocialService {
  getStruct = (params) => {
    return ApiExec(params, 'social/get-structure', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStructMunicipios = (params) => {
    return ApiExec(params, 'social/get-structure-municipios', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCoordinators = (params) => {
    return ApiExec(params, 'social/get-coordinators', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getLinks = (params) => {
    return ApiExec(params, 'social/get-links', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserCoordinators = (params) => {
    return ApiExec(params, 'social/get-user-coordinators', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getUserLinks = (params) => {
    return ApiExec(params, 'social/get-user-links', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsabilidades = (params) => {
    return ApiExec(params, 'social/get-perfiles-social', 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createStruct = (params) => {
    return ApiExec(params, `social/create`, 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateSocial = (params) => {
    return ApiExec(params, 'social/update', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteSocial = (params) => {
    return ApiExec(params, 'social/delete', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  assignCoordinators = (params) => {
    return ApiExec(params, `social/assign-coordinators`, 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  removeCoordinator = (params) => {
    return ApiExec(params, 'social/remove-coordinators', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  assignLinks = (params) => {
    return ApiExec(params, `social/assign-links`, 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  removeLinks = (params) => {
    return ApiExec(params, 'social/remove-links', 'PUT')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  catalogoStruct = (params) => {
    return ApiExec(params, 'social/get-my-struct', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  totalCompromisos = (params) => {
    return ApiExec(params, 'social/get-total-compromisos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  //compromisos

  getCompromisos = (params) => {
    return ApiExec(params, 'social/get-commitments', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosSegmento = (params) => {
    return ApiExec(params, 'social/get-commitments-users', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTotalesSocial = (params) => {
    return ApiExec(params, 'social/get-totals-social', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAgeSex = (params) => {
    return ApiExec(params, 'social/get-total-age-sex', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDiaCompromisos = (params) => {
    return ApiExec(params, 'social/get-commitments-day', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getRegionCompromisos = (params) => {
    return ApiExec(params, 'social/get-commitments-region', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTableResumen = (params) => {
    return ApiExec(params, 'social/get-goal-social', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportReportMetas = (params) => {
    return downloadFile({
      api: 'social/export-goal-social',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  exportCompromisos = (params) => {
    return downloadFile({
      api: 'social/export-commitments',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getResponsables = (params) => {
    return ApiExec(params, 'social/get-structure-responsabilities', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatsResponsables = (params) => {
    return ApiExec(params, 'social/get-stats-responsabilities', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListCommitments = (params) => {
    return ApiExec(params, 'social/get-list-commitments', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListCommitmentsSegmento = (params) => {
    return ApiExec(params, 'social/get-list-commitments-segmento', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResumenResponsable = (params) => {
    return ApiExec(params, 'social/get-resumen-responsables', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDataResponsble = (params) => {
    return ApiExec(params, 'social/get-info-structure', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateResponsible = (params) => {
    return ApiExec(params, 'social/update-propitarios', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosUsuariosStats = (params) => {
    return ApiExec(params, 'social/get-commitments-stats', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsibles = (params) => {
    return ApiExec(params, 'social/get-responsibles', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAvanceCompromisos = (params) => {
    return ApiExec(params, 'social/get-avaces-segmentos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAvanceCompromisosTotales = (params) => {
    return ApiExec(params, 'social/get-totales-segmentos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAvanceStatsCompromisos = (params) => {
    return ApiExec(params, 'social/get-avaces-stats', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMiSocial = (params) => {
    return ApiExec(params, 'social/get-mis-sociales', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMisComrpomisos = (params) => {
    return ApiExec(params, 'social/get-mis-compromisos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAmbitos = (params) => {
    return ApiExec(params, 'social/get-ambitos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAdvence = (params) => {
    return ApiExec(params, 'social/get-advance-segments', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListCompromisos = (params) => {
    return ApiExec(params, 'social/get-mi-seguimiento', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateContactoEstatus = (params) => {
    return ApiExec(params, 'social/update-contacto-estatus', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardsContactoEstatus = (params) => {
    return ApiExec(params, 'social/get-cards-contacto-estatus', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardsContactEstatusMiResponsable = (params) => {
    return ApiExec(params, 'social/get-cards-contacto-estatus-mis', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const social = new SocialService();
export default social;
