//MUI
import { Box, Typography, CircularProgress, circularProgressClasses } from '@mui/material';

const CircularProgressPorcent = ({ size, value, fontSize }) => {
  function validateRange(numero) {
    const porcent = parseInt(numero).toFixed();
    const rango = parseInt(porcent);

    if (rango === 100) {
      return 'success';
    } else if (rango >= 50 && rango < 100) {
      return 'primary';
    } else if (rango > 0 && rango < 50) {
      return 'warning';
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        mt: 0.3,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={parseInt(value)}
        //color={value === "100.00" ? "success" : "warning"}
        color={validateRange(value)}
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" fontSize={fontSize}>
          {parseFloat(value).toFixed(2)}%
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressPorcent;
