import { useState, useEffect } from 'react';

// Servicios
import ScheduleServices from '@services/ScheduleServices';

const useRefresh = ({ params = {}, localTime = null, handleRefresh = () => {}, reset = false } = {}) => {
  const [timer, setTimer] = useState(null);
  const [seconds, setSeconds] = useState(null);

  const getRefreshTime = async () => {
    try {
      const res = await ScheduleServices.getRefreshTime(params);
      const { results, response, message } = res;

      if (results) {
        setSeconds(response.ValorVariable);
        setTimer(response.ValorVariable);
      } else throw new Error(message);
    } catch (error) {
      setSeconds(5000);
      setTimer(5000);
    }
  };

  /* useEffect(() => {
    if (localTime) {
      setSeconds(localTime);
      setTimer(localTime);
    } else getRefreshTime();
    // eslint-disable-next-line
  }, []); */

  /* useEffect(() => {
    if (seconds && timer) {
      const timer = setInterval(() => {
        setTimer((prev) => prev - 1000);
      }, 1000);

      // Limpiar el intervalo para evitar memory leaks
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line
  }, [seconds]); */

  /* useEffect(() => {
    if (timer <= 0 && timer !== null) {
      if (handleRefresh) handleRefresh(); // Ejecutar las acciones
      setTimer(seconds); // Reiniciar el contador
    }
    // eslint-disable-next-line
  }, [timer, handleRefresh]);
 */
  /* useEffect(() => {
    if (reset) setTimer(seconds); // Reiniciar el contador
    // eslint-disable-next-line
  }, [reset]);
 */
  // Función para formatear los segundos en formato mm:ss
  const formatTime = (sec) => {
    const minutos = Math.floor(sec / 60000);
    const segundos = Math.floor((sec % 60000) / 1000);

    return minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
  };

  return {
    interval: seconds,
    timer,
    label: formatTime(timer),
  };
};

export default useRefresh;
