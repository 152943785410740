import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import CustomTable from '@components/Tables/CustomTable';

// Material UI
import { Icon, Button, Typography, Grid, Card, CardContent, LinearProgress, CardHeader } from '@mui/material';
import Swal from 'sweetalert2';
import AdaptiveCard from '@components/Card/AdaptiveCard';
import LastUpdatedCard from '@components/Activation/DashboardElectoral/LastUpdatedCard';
import ColumnStacked from '@components/Charts/BasiBarColumnPorcent';
import DrawerRelative from '@components/Containers/DrawerRelative';
import FloatingButton from '@components/Button/FloatingButton';
import { useNavigate } from 'react-router-dom';
import FilterMovilizacion from '../../components/Structure/FilterMovilizacion';
import social from '@services/SocialServices';
import { getVars } from '@utils/global';

const DashbordContacto = () => {
  const navigate = useNavigate();
  const { user } = getVars('Token');
  const [ambitoUser, setAmbitoUser] = useState({});
  const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] = useState(ambitoUser);
  const [catView, setCatView] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingCards, setLoadingCards] = useState();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 50,
    filtered: [],
    sorted: [],
  });
  const [dataChart, setDataChart] = useState({
    Fecha: '',
    Movilizacion: [],
  });
  const [databar, setDataBar] = useState([
    {
      name: '',
      data: {
        categories: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7'],
        series: [
          {
            name: 'Pendientes',
            data: [50, 50, 50, 50, 50, 50, 50],
          },
          {
            name: 'Contactado',
            data: [5, 0, 18, 3, 7, 2, 6],
          },
          {
            name: 'No Localizado',
            data: [5, 0, 18, 3, 7, 2, 6],
          },
        ],
        title: 'AVANCE MOVILIZACIÓN POR ',
      },
    },
  ]);
  const [dataCard, setDataCard] = useState([
    {
      name: '',
      data: [
        {
          Meta: 0,
          Contactado: 0,
          PorcentajeContactado: 0,
        },
      ],
    },
  ]);
  const [dataProgress, setDataProgress] = useState({
    title: 'Avance acumulativo POR ',
    categories: [
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
    ],
    series: [
      {
        name: 'R1',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'R2',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'R3',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'R4',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'R5',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'R6',
        data: [0, 146, 1242, 2352, 3435, 4308, 5041, 5054, 5054, 5054, 5054],
      },
      {
        name: 'R7',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const [columnsPercent, setColumnsPercent] = useState([
    /*    { id: "Region", label: "Región" },
    { id: "Municipio", label: "Municipio" }, */
    { id: 'Meta', label: 'Compromisos' },
    { id: 'Pendientes', label: 'Pendientes' },
    {
      id: 'PorcentajePendientes',
      label: '% Pendientes',
      type: 'linear',
      fixed: 3,
    },
    { id: 'Contactado', label: 'Contactado' },
    {
      id: 'PorcentajeContactado',
      label: '% Contactados',
      type: 'linear',
      fixed: 3,
    },
    { id: 'NoLocalizado', label: 'NoLocalizado' },
    {
      id: 'PorcentajeNoLocalizado',
      label: '% No Localizado',
      type: 'linear',
      fixed: 3,
    },
  ]);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);

  /*   useEffect(() => {
    let info = user.ambito_perfil.find((item) =>
        [4, 10].includes(item.idTipo)
      );
    if (info) {
      setAmbitoUser(info.idAmbito );
      setIdAmbitoAgrupadorTable({
        ...info,
        label: info.Ambito,
        value: info.idAmbito,
      });
      setParams((prev) => ({ ...prev, view: info.Ambito }));
    }
  }, []); */

  useEffect(() => {
    getCatView();
    // eslint-disable-next-line
  }, []);

  // AMbito del usuario
  const getCatView = async () => {
    try {
      const res = await social.getAmbitos();
      const { results, response, message } = res;
      if (results) {
        let newAmbito = response.data;
        const data = newAmbito.sort((a, b) => a.value - b.value);

        setCatView(data);
        setAmbitoUser(data[0]?.value);
        setIdAmbitoAgrupadorTable(data[0]);
        setParams((prev) => ({ ...prev, view: data[0]?.label }));
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const changeTypeViewFilter = (view) => {
    setIdAmbitoAgrupadorTable(catView.find((item) => item.value === view));
  };

  // apis
  useEffect(() => {
    if (params.view) {
      switch (idAmbitoAgrupadorTable.value) {
        case 1:
          setColumnsPercent([
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 2:
          setColumnsPercent([
            { id: 'Region', label: 'Región' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 3:
          setColumnsPercent([
            { id: 'DistritoFederal', label: 'Distrito Federal' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 4:
          setColumnsPercent([
            { id: 'DistritoLocal', label: 'Distrito Local' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 5:
          setColumnsPercent([
            { id: 'DistritoFederal', label: 'Distrito Federal' },
            { id: 'DistritoLocal', label: 'Distrito Local' },
            { id: 'Region', label: 'Región' },
            { id: 'Municipio', label: 'Municipio' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 8:
          setColumnsPercent([
            { id: 'DistritoFederal', label: 'Distrito Federal' },
            { id: 'DistritoLocal', label: 'Distrito Local' },
            { id: 'Region', label: 'Región' },
            { id: 'Municipio', label: 'Municipio' },
            { id: 'Seccion', label: 'Seccion' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 15:
          setColumnsPercent([
            { id: 'NombreEstructura', label: 'Social' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 16:
          setColumnsPercent([
            { id: 'Municipio', label: 'Municipio' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        case 17:
          setColumnsPercent([
            { id: 'Region', label: 'Región' },
            { id: 'Meta', label: 'Compromisos', type: 'number', fixed: 2 },
            { id: 'Pendientes', label: 'Pendientes', type: 'number', fixed: 2 },
            {
              id: 'PorcentajePendientes',
              label: '% Pendientes',
              type: 'linear',
              fixed: 3,
            },
            { id: 'Contactado', label: 'Contactado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeContactado',
              label: '% Contactados',
              type: 'linear',
              fixed: 3,
            },
            { id: 'NoLocalizado', label: 'NoLocalizado', type: 'number', fixed: 2 },
            {
              id: 'PorcentajeNoLocalizado',
              label: '% No Localizado',
              type: 'linear',
              fixed: 3,
            },
          ]);
          break;
        default:
          break;
      }

      getAdvance();
    }
  }, [params]);

  const getAdvance = () => {
    setLoading(true);
    social
      .getAdvence(params)
      .then((res) => {
        if (res.results) {
          let result = res.response.data;
          setRows(result);
          setTotal(res.response.total);

          let type =
            idAmbitoAgrupadorTable.value < 15
              ? idAmbitoAgrupadorTable.label
                  .replace(/\s/g, '')
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              : idAmbitoAgrupadorTable.value === 15
                ? 'NombreEstructura'
                : idAmbitoAgrupadorTable.value === 16
                  ? 'Municipio'
                  : 'Region';

          //   (idAmbitoAgrupadorTable.valor === 16 ? "Municipio" : (idAmbitoAgrupadorTable.valor === 17 ? "Region" : idAmbitoAgrupadorTable.valor === 15 ? "NombreSocial" :""));

          setDataBar(
            result.map((item) => {
              return {
                name: item.name,
                data: transformData(item.data, type, item.name),
              };
            })
          );

          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoading(false));

    setLoadingCards(true);
    social
      .getAdvence({ ...params, page: 0, pageSize: 99999999999999, view: 'Estado' })
      .then((res) => {
        if (res.results) {
          let result = res.response.data;
          setDataCard(result);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => setLoadingCards(false));
  };

  const transformData = (datos, variant, name) => {
    // Objeto donde almacenaremos los datos transformados
    const datosTransformados = {};

    // Iteramos sobre las claves de uno de los objetos para inicializar el objeto datosTransformados
    Object.keys(datos[0]).forEach((key) => {
      datosTransformados[key] = [];
    });

    // Iteramos sobre cada objeto en el array datos
    datos.forEach((objeto) => {
      // Iteramos sobre cada clave del objeto
      Object.keys(objeto).forEach((key) => {
        // Añadimos el valor correspondiente al array en datosTransformados
        let valor;
        if (key === variant) {
          valor = objeto[key];
        } else {
          valor = isNaN(parseFloat(objeto[key])) ? objeto[key] : parseFloat(objeto[key]);
        }

        // Añadimos el valor correspondiente al array en datosTransformados
        datosTransformados[key].push(valor);
      });
    });

    // Imprimimos los datos transformados
    return {
      categories: datosTransformados[variant] ? datosTransformados[variant] : [variant],
      series: [
        {
          name: 'Pendientes',
          data: datosTransformados['Pendientes'],
          Meta: datosTransformados['Meta'],
          color: '#BBEDF8',
        },
        {
          name: 'Contactado',
          data: datosTransformados['Contactado'],
          Meta: datosTransformados['Meta'],
          color: '#CBF8BB',
        },
        {
          name: 'No Localizado',
          data: datosTransformados['NoLocalizado'],
          Meta: datosTransformados['Meta'],
          color: '#F8C6BB',
        },
      ],
      title: idAmbitoAgrupadorTable.value < 16 ? `${variant}` : name,
    };
  };

  const handleRefresh = () => {
    setParams((prev) => ({ ...prev, page: 0, sorted: [] }));
    setDataChart((prev) => ({ ...prev }));
    setRefresh(true);
  };

  const handleSearchFilter = (filter) => {
    setParams((prev) => ({
      ...prev,
      page: 0,
      pageSize: idAmbitoAgrupadorTable.value > 14 ? 9999999999999 : 50,
      view: idAmbitoAgrupadorTable.label,
      filtered: filter.filtereds,
    }));
  };

  return (
    <AdminLayout>
      <ActionHeader title={'Dashboard Social'} isCustom={true}>
        <Button
          onClick={() => setOpenDrawer(!openDrawer)}
          aria-label="open Drawer"
          variant="contained"
          color="secondary"
          endIcon={openDrawer ? <Icon>filter_list_off</Icon> : <Icon>filter_list</Icon>}
        >
          <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
        </Button>
      </ActionHeader>

      <Grid
        container
        spacing={openDrawer ? 2 : 0}
        sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
      >
        <Grid item xs={12} md={openDrawer ? 8 : 12}>
          <Grid container spacing={2}>
            {/* <Grid
              item
              xs={12}
              md={openDrawer ? 12 : 6}
              lg={6}
              sx={{ paddingBottom: "20px" }}
            >
              <LastUpdatedCard
                dateHour={dataChart.Fecha || ""}
                handleClick={handleRefresh}
                refresh={refresh}
                columnIn={openDrawer ? "lg" : "md"}
              />
            </Grid> */}
            <Grid item xs={12} md={openDrawer ? 12 : 12} lg={12}>
              {dataCard.map((item) => {
                return (
                  <AdaptiveCard
                    title="Avance de Contacto"
                    icon="description"
                    config={{
                      percent: parseFloat(item.data[0].PorcentajeContactado) || 0,
                    }}
                    leftConfig={{
                      title: 'Meta',
                      data: parseFloat(item.data[0].Meta) || 0,
                    }}
                    rightConfig={{
                      title: 'Avance',
                      data: parseFloat(item.data[0].Contactado) || 0,
                    }}
                    fixed
                    loading={loadingCards}
                  />
                );
              })}
            </Grid>
          </Grid>
          {loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid container spacing={2}>
              {databar.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} lg={databar.length > 1 ? 6 : 12} key={index}>
                  <Card className="card-primary">
                    <CardContent>
                      <ColumnStacked
                        series={item.data.series}
                        categories={item.data.categories}
                        title={item.data.title}
                        loading={false}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {rows.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} lg={rows.length > 1 ? 6 : 12} key={index}>
                  <Card>
                    <CardHeader title={item.name} />
                    <CardContent>
                      <CustomTable
                        rows={item.data}
                        columns={columnsPercent}
                        orderBy={orderBy}
                        loading={loading}
                        total={total}
                        page={params.page}
                        pageSize={params.pageSize}
                        handlePagination={(e) => {
                          setParams({ ...params, ...e });
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {/*          <Grid item xs={12}>
            <Card className="card-primary">
              <CardContent key={`Mobilization_SOCIAL_process`}>
                <BasicLine
                  title={{
                    primary: "",
                  }}
                  legend={{
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  }}
                  series={dataProgress.series}
                  categories={dataProgress.categories}
                  initialPosition
                />
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <DrawerRelative
            anchor="right"
            open={openDrawer}
            setOpen={setOpenDrawer}
            title={'Filtrar'}
            icon="person_search"
            titleComponentPlace="under"
            className="card-primary"
            sxPaper={{ borderRadius: 3 }}
            isSticky
          >
            <FilterMovilizacion
              onSearch={handleSearchFilter}
              onClear={(e) => {
                setParams({ ...params, filtered: e });
              }}
              changeTypeView={changeTypeViewFilter}
              refresh={refresh}
              setLoading={setLoading}
              catView={catView}
              setIdFilter={setIdAmbitoAgrupadorTable}
              ambitoUser={ambitoUser}
            />
          </DrawerRelative>
        </Grid>
        {!openDrawer && (
          <FloatingButton
            onClick={() => setOpenDrawer(true)}
            label="Mostrar Filtros"
            sx={{ display: { xs: 'none', md: 'block' } }}
            icon={'filter_list'}
            color="#B159E3"
          />
        )}
      </Grid>
    </AdminLayout>
  );
};

export default DashbordContacto;
