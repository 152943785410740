import ApiExec, { downloadFile, uploadAndDownloadFile } from '@utils/ApiExec';
import { handleAttrs } from '@utils/Utilities';

//Clase para invocar a Apis del módulo de Metas
class MetasServices {
  getElectoralGoals = (params) => {
    return ApiExec(params, 'goals/get-electoral-goals', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  setElectoralGoal = (params) => {
    return ApiExec(handleAttrs(params), 'goals/set-electoral-goal', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  deleteElectoralGoal = (params) => {
    return ApiExec(params, 'goals/delete-electoral-goal', 'DELETE')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getGoalsBySection = (params) => {
    return ApiExec(params, 'goals/get-goals-by-section', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  exportGoalsBySection = (params) => {
    return downloadFile({
      api: 'goals/export-goals-by-section',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  importGoalsBySection = (params) => {
    return uploadAndDownloadFile({
      api: 'goals/import-goals-by-section',
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getGoalsTotals = (params) => {
    return ApiExec(params, 'goals/get-goals-totals', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getGoalsPercentageProgress = (params) => {
    return ApiExec(params, 'goals/get-goals-percentage-progress', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };
  getCatalogsSije = (params) => {
    return ApiExec(params, 'sije/get-catalogs-sije', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const metas = new MetasServices();
export default metas;
