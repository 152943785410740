import React from 'react';
import { Box, CardHeader, Typography, Icon, Button } from '@mui/material';

const MenuToolbar = (props) => {
  const { configToolbar } = props;
  return (
    <Box {...props}>
      <CardHeader
        /*  avatar={
           <IconButton type="button" color="primary" sx={{ p: "10px" }} aria-label="edit" onClick={configToolbar.handleEditRole}>
             <Icon>edit</Icon>
           </IconButton>
         } */
        title={<Typography variant="h6">{configToolbar.title}</Typography>}
        action={
          configToolbar.btnAdd && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<Icon>{configToolbar.icon}</Icon>}
              onClick={configToolbar.handleAdd}
            >
              Agregar
            </Button>
          )
        }
      />
    </Box>
  );
};

export default MenuToolbar;
