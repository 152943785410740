import ApiExec, { downloadFile } from '@utils/ApiExec';

class ReconocimientosServices {
  importExcelData = (params) => {
    return downloadFile({
      api: 'reconocimientos/import',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCatPlantillas = (params) => {
    return ApiExec(params, 'reconocimientos/get_cat_plantilla', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const services = new ReconocimientosServices();
export default services;
