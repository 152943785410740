// Material UI
import { Stack } from '@mui/material';

// Estilos de mapas
import { style_original_data } from '@components/Maps/Auxiliars/ColorFeature';

export const onEachFeature = (feature, layer, click, params, type_map) => {
  const properties = feature.properties;
  const shp = feature.properties.shp;
  const style = { permanent: true, direction: 'center' };
  if (properties[shp] !== undefined) {
    if (shp === 'manzana' && type_map === 'seccion') {
      layer.bindTooltip(
        (properties.manzana ? 'M:' + properties.manzana : '') +
          (properties.Localidad ? ' L' + properties.Localidad : '') +
          (properties.Prioridad ? ' P' + properties.Prioridad : ''),
        style
      );
      return true;
    }

    if (shp === 'seccion' && type_map === 'poligono') {
      layer.bindTooltip('S:' + properties.seccion, style);
      return true;
    }

    if (shp === 'seccion' && type_map === 'municipio_reportes') {
      layer.bindTooltip('S:' + properties.seccion, style);
      return true;
    }

    if (shp === 'poligono' && type_map === 'municipio_reportes') {
      layer.bindTooltip('' + properties.Poligono, style);
      return true;
    }

    /*if (shp === "municipio_reportes" && type_map === "region") {
        layer.bindTooltip("" + properties.municipio_reportes, style);
        return true;
      } */

    if (shp === 'region' && type_map === 'entidad') {
      layer.bindTooltip('' + properties.region, style);
      return true;
    }

    if (shp === 'poligono' && type_map === 'DistritoLocal') {
      layer.bindTooltip('' + properties.poligono, style);
      return true;
    }

    if (shp === 'poligono' && type_map === 'DistritoFederal') {
      layer.bindTooltip('' + properties.poligono, style);
      return true;
    }

    if (shp === 'seccion' && type_map === 'DistritoLocal') {
      layer.bindTooltip('' + properties.seccion, style);
      return true;
    }

    if (shp === 'seccion' && type_map === 'DistritoFederal') {
      layer.bindTooltip('' + properties.seccion, style);
      return true;
    }

    layer.setStyle(style_original_data);
    layer.on({ click: click });
  }
};

export const highlightFeature = (e, params) => {
  const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
  const layer = e.target;
  const properties = layer.feature.properties;

  if (selectedFeature) resetHighlight(selectedFeature);

  if (!properties[properties.shp]) resetHighlight(e);

  setFeature(layer.feature);
  setSelectedFeature(e);

  if (properties[properties.shp]) {
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
    layer.setStyle(style_original_data(layer.feature, true));
  }
};

export const cardInfoComponent = (values) => {
  const style = { width: '18px', height: '18px', float: 'left', borderRadius: '9px', opacity: 0.7 };
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <div>
        <i style={{ ...style, background: '#FF0001' }}></i>
        <b>Prioridad 1</b>
      </div>
      <div>
        <i style={{ ...style, background: '#FFD000' }}></i>
        <b>Prioridad 2</b>
      </div>
      <div>
        <i style={{ ...style, background: '#666' }}></i>
        <b>Prioridad 3</b>
      </div>
    </Stack>
  );
};

const commonElements = {
  manzana: [
    { title: 'Manzana: ', name: 'Manzana', type: 'text' },
    { title: 'Prioridad: ', name: 'PrioridadLabel', type: 'text' },
  ],
  seccion: [
    { title: 'Sección: ', name: 'seccion', type: 'text' },
    { title: 'DLOC: ', name: 'DLOC', type: 'text' },
    { title: 'DFED: ', name: 'DFED', type: 'text' },
  ],
  poligono: [{ title: 'Polígono: ', name: 'poligono', type: 'text' }],
  municipio_reportes: [{ title: 'Municipio: ', name: 'municipio_reportes', type: 'text' }],
  region: [{ title: 'Region: ', name: 'region', type: 'text' }],
  entidad: [{ title: 'Entidad: ', name: 'entidad', type: 'text' }],
};

export const elements = {
  ...commonElements,
  dfed: [{ title: 'DFED: ', name: 'dfed', type: 'text' }],
};

export const elementsDLDF = {
  ...commonElements,
  dfed: [{ title: 'DFED: ', name: 'DistritoFederal', type: 'text' }],
  dloc: [{ title: 'DLOC: ', name: 'DistritoLocal', type: 'text' }],
};
