import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';

const DraggableListItem = ({ item, index }) => {
  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ background: snapshot.isDragging ? 'rgb(235,235,235)' : '' }}
        >
          <ListItemAvatar>
            <Avatar>{item.Orden ? item.Orden : index + 1}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
