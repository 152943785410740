import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import { Grid, TextField, Button, Card, CardContent, InputAdornment } from '@mui/material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';

// Utils
import { handleAttrs } from '@utils/Utilities';
import { isEmptyOrNullObject } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';

// Data
import { EditServiciosInterface, ServiciosInterface } from '@interfaces/AportacionesInterfaces';
import { EditServiciosSchema, ServiciosSchema } from '@schemas/AportacionesSchemas';

// Middleware
import middleware from '@middlewares/middleware';

const ServiciosForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditServiciosInterface : ServiciosInterface,
    validationSchema: isEdit ? EditServiciosSchema : ServiciosSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando servicio...');

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Municipio;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      PorcentajeCargo: params.PorcentajeCargo,
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando servicio...');

      const result = await services.updateServicio(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      PorcentajeCargo: params.PorcentajeCargo,
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando servicio...');

      const result = await services.createServicio(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success' });
        formik.resetForm();
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = false;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Servicio creado con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({ formik, loadAll, isEdit, handleClose, handleDelete, data }) => {
  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  return (
    <Grid container spacing={2}>
      {/* Datos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              size="small"
              fullWidth
              name="NombreServicio"
              label="Nombre del servicio"
              placeholder="Nombre del Servicio"
              value={formik.values.NombreServicio}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('NombreServicio')?.error}
              helperText={verify('NombreServicio')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              size="small"
              fullWidth
              name="PorcentajeCargo"
              label="Porcentaje del cargo"
              placeholder="10.5"
              value={formik.values.PorcentajeCargo}
              onChange={({ target: { value, name } }) => {
                let newValue = value.replace(/[^0-9.]/g, '');
                if (/^\d{3}$/.test(newValue)) newValue = newValue.slice(0, 2) + '.' + newValue.slice(2);

                if (newValue.endsWith('.')) newValue = newValue.slice(0, -1);

                const regex = /^(?:\d{0,2}(?:\.\d{0,2})?)?$/;
                if (regex.test(newValue)) formik.handleChange({ target: { value: newValue, name } });
              }}
              disabled={loadAll}
              error={verify('PorcentajeCargo')?.error}
              helperText={verify('PorcentajeCargo')?.helperText}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              size="small"
              fullWidth
              name="Descripcion"
              label="Descripción"
              placeholder="Este servicio..."
              value={formik.values.Descripcion}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Descripcion')?.error}
              helperText={verify('Descripcion')?.helperText}
              multiline
              minRows={2}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={'end'}>
          {isEdit && (
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                Cancelar
              </Button>
            </Grid>
          )}

          {isEdit && middleware.checkMenuAction('Eliminar') && (
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                Eliminar
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={2} lg={2}>
            <Button
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
              disabled={loadAll}
              fullWidth
            >
              {isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiciosForm;
