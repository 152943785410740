import { useEffect, useState } from 'react';
import useWindowDimensions from '@hooks/useWindowDimensions';

import { orange } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import CardLayout from './CardLayout';
import SummaryChart from './SummaryChart';
import BadgeLabel from './BadgeLabel';

const IncidentsCard = ({ data, isLoading }) => {
  const { height } = useWindowDimensions();
  const [total, setTotal] = useState({
    Favorables: 0,
    Competidas: 0,
    Adversas: 0,
  });

  const [dataChart, setDataChart] = useState({
    data: [0, 0, 0],
    height: height * 0.25,
    names: ['P1', 'S1', 'S2'],
  });

  useEffect(() => {
    setTotal(data);
    setDataChart({
      ...dataChart,
      data: [
        { y: data.Favorables, color: orange[900] },
        // { y: data.Competidas, color: orange[700] },
        { y: data.Adversas, color: orange[600] },
      ],
      loading: isLoading,
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <CardLayout
      title={'INCIDENCIAS'}
      bgColor={orange[100]}
      color={orange[800]}
      icon={'warning'}
      //sx={{ height: { md: "90%" } }}
    >
      <Grid2 container>
        <Grid2 xs={12} md={7}>
          <SummaryChart data={dataChart} />
        </Grid2>
        <Grid2 xs={12} md={5}>
          <BadgeLabel total={total.Favorables} label={'Nuevas'} color={orange[900]} />
          {/*  <BadgeLabel
            total={total.Competidas}
            label={"En proceso"}
            color={orange[700]}
          /> */}
          <BadgeLabel total={total.Adversas} label={'Atendidas'} color={orange[600]} />
        </Grid2>
      </Grid2>
    </CardLayout>
  );
};

export default IncidentsCard;
