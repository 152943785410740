import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, CircularProgress } from '@mui/material';

import OficialiaServices from '@services/OficialiaServices'; // Importar el servicio

const DirectorioCDEFiltroAutocomplete = ({ name, label, value, setValue }) => {
  const [data, setData] = useState([]); // Estado para seleccionar empresa padre
  const [loading, setLoading] = useState(false); // Estado para manejar el loading al guardar
  const [errors, setErrors] = useState('');

  useEffect(() => {
    const fetchEmpresas = async () => {
      setLoading(true);
      try {
        const results = await OficialiaServices.getDirectorioRecursivo({
          page: 0,
          pageSize: 400,
          filtered: [],
          sorted: [{ id: 'tb.persona', value: 'ASC' }],
        });

        console.log('=>results: ', results);

        if (results.success) {
          // Si la respuesta es exitosa, actualizar el estado con los datos
          setData(results.response.data);
        } else {
          // Manejar el caso en que no se puedan obtener los datos
          setErrors(results.message || 'Error al obtener directorio');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener directorio:', error);
        setErrors('Error al cargar directorio');
      }
    };

    fetchEmpresas();
  }, []);

  return (
    <Box>
      {loading ? (
        <CircularProgress
          variant={loading ? 'indeterminate' : 'determinate'}
          sx={{
            color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={40}
          thickness={4}
          value={100}
        />
      ) : (
        <Autocomplete
          value={value[name] || null}
          onChange={(event, newValue) => {
            setValue({ ...value, [name]: newValue });
          }}
          onInputChange={(event, newInputValue) => {
            if (newInputValue === '') {
              setValue({ ...value, [name]: null });
            }
          }}
          options={data}
          getOptionLabel={(option) => `${option.persona} ${option.Cargo} ${option.jerarquia_empresa}` || ''}
          getOptionDisabled={(option) => option.idUsuarioSIAN === null} // Deshabilitar si idUsuarioSIAN es null
          renderOption={(props, option) => (
            <li
              {...props}
              style={{
                color: option.idUsuarioSIAN === null ? 'gray' : 'inherit', // Cambiar color si está deshabilitado
                cursor: option.idUsuarioSIAN === null ? 'not-allowed' : 'pointer', // Cambiar cursor si está deshabilitado
              }}
            >
              {`${option.persona} ${option.Cargo} ${option.jerarquia_empresa}`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label ? label : 'Para quien'}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          fullWidth
        />
      )}
    </Box>
  );
};

export default DirectorioCDEFiltroAutocomplete;
