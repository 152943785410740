import { Box, Icon, TableCell, TableRow, Typography, TableBody as TableBodyMui } from '@mui/material';
import { grey } from '@mui/material/colors';

const TableEmpty = () => {
  return (
    <TableBodyMui>
      <TableRow>
        <TableCell colSpan={4}>
          <Box display={'flex'} alignContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Icon sx={{ fontSize: '5rem', color: grey[500] }}>search_off</Icon>
            <Typography fontWeight={600} variant="h6" marginTop={1}>
              No se encontraron resultados
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableBodyMui>
  );
};

export default TableEmpty;
