import { ProtectedRoute } from '@middlewares';
import Examples from '@pages/_Examples';
import Pruebas from '@pages/_Examples/Pruebas';
import Nombres from '@pages/Nombres';

const ExamplesRoute = [
  {
    path: '/component-examples',
    element: (
      <ProtectedRoute>
        <Examples />
      </ProtectedRoute>
    ),
  },
  {
    path: '/botonPruebas',
    element: <Pruebas />,
  },
  {
    path: '/editar-colonias',
    element: <Nombres />,
  },
];

export default ExamplesRoute;
