// Material UI
import { Icon, Stack, Typography, Box, Button } from '@mui/material';
import { Update } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { grey } from '@mui/material/colors';

// Hooks
import useRefresh from '@hooks/useRefresh';

const CardInformative = (props) => {
  const { handleClick = () => {}, refresh, summaryData } = props;

  const { label } = useRefresh({ handleRefresh: handleClick, reset: refresh });

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="Subtitle2" fontWeight={600} fontSize={18}>
        {summaryData.TipoEleccion}
      </Typography>
      <Typography variant="body2">{summaryData.Ambito}</Typography>
      <Stack direction="column" spacing={1} alignItems="center" marginTop={1}>
        <Stack direction="row" spacing={1} alignItems="center" marginTop={1}>
          <Icon fontSize="small" sx={{ color: grey[600] }}>
            calendar_today
          </Icon>
          <Typography variant="body2">{summaryData.FechaHora}</Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" sx={{ my: 2, fontWeight: 'bold' }}>
        {`Próxima actualización en ${label}`}
      </Typography>
      <Button
        onClick={handleClick}
        variant="outlined"
        endIcon={<Update />}
        sx={{
          color: blue[900],
          borderColor: blue[900],
          '&:hover': {
            borderColor: blue[700],
          },
        }}
      >
        Actualizar
      </Button>
    </Box>
  );
};

export default CardInformative;
