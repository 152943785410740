import {
  Stack,
  CardContent,
  Drawer,
  Box,
  Paper,
  IconButton,
  Icon,
  Avatar,
  TextField,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@components/Card/Card';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import BasicList from '../Lists/BasicList';
import { indigo } from '@mui/material/colors';

const ListCalidadRepresentante = (props) => {
  const { state, toggleDrawer } = props;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function NewsItem(news) {
    const { item, handleDeleteItem } = news;
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Avatar sx={{ bgcolor: indigo[700] }} alt="Remy Sharp">
            P1
          </Avatar>
          <Box>
            <Typography color="inherit">{item.title}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {item.description}
            </Typography>
          </Box>
        </Stack>
        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
          fecha
        </Typography>
      </Stack>
    );
  }

  const fetchHasMore = () => {};
  const hasMore = () => {};

  return (
    <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 500 }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        //onKeyDown={toggleDrawer(false)}
      >
        <Card sx={{ mt: 8 }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={12} lg={12}>
              <Item>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <IconButton aria-label="delete" size="large" onClick={toggleDrawer(false)}>
                    <Icon>close</Icon>
                  </IconButton>
                  <Box>
                    <Typography color={'darkblue'} variant="h6">
                      Representantes
                    </Typography>
                  </Box>
                </Stack>
                <br />
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <TextField
                    label="Nombre"
                    size="small"
                    name="NombreCompleto"
                    // value={filter.NombreCompleto.toUpperCase()}
                    //  onChange={hadleChangeTextField}
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    label="Celular"
                    size="small"
                    name="NombreCompleto"
                    //  value={filter.NombreCompleto.toUpperCase()}
                    //  onChange={hadleChangeTextField}
                    sx={{ width: '100%' }}
                  />
                  <IconButton aria-label="search" size="large" color="info">
                    <Icon>search</Icon>
                  </IconButton>
                </Stack>
              </Item>
            </Grid>
            <Grid container xs={12} md={12} lg={12} spacing={4}>
              <Grid xs={12} lg={12}>
                <Item>
                  <BasicList
                    Header={false}
                    data={[
                      {
                        image: <Icon>update</Icon>,
                        title: 'ejemplo',
                        description: '9888888',
                      },
                    ]}
                    fetchHasMore={fetchHasMore}
                    totalItems={1}
                    itemComponent={NewsItem}
                    hasMore={hasMore}
                    finder={false}
                    configParams={{
                      height: 650,
                      endMessage: 'No hay más respresentantes',
                    }}
                  />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Drawer>
  );
};
export default ListCalidadRepresentante;
