import AdministrationRoutes from './AdministrationRoutes';
import Examples from './_Examples';
import AuthRoutes from './AuthRoutes';
import CompromisosRoutes from './CompromisosRoutes';
import VolunteersRoutes from './VolunteersRoutes';
import SocialRoutes from './SocialRoutes';
import MapRoutes from './MapRoutes';
import SimulacrumRoutes from './SimulacrumRoutes';
import TerritorialRoutes from './TerritorialRoutes';
import SijeRoutes from './SijeRoutes';
import EventsRoutes from './EventsRoutes';
import PrepRoutes from './PrepRoutes';
import ElectoralRoutes from './ElectoralRoutes';
import EncuestasRoutes from './EncuestasRoutes';
import RcosRoutes from './RcosRoutes';
import FrenteAmplioRoutes from './FrenteAmplioRoutes';
import ActivationRoute from './Activation';
import MesaRoutes from './MesaRoutes';
import SupportRoutes from './SupportRoutes';
import NumeraliaRoutes from './NumeraliaRoutes';
import MetasRoutes from './MetasRoutes';
import TrackingRoutes from './TrackingRoutes';
import MovilizacionRoutes from './MovilizacionRoutes';
import DiaDRoutes from './DiaDRoutes';
import BingoRoutes from './BingoRoutes';
import FocaRoutes from './FocaRoutes';
import PpmRoutes from './PpmRoutes';
import ResultadosRoutes from './Result';
import CDERoute from './CDERoutes';
import Aportaciones from './Aportaciones';
import OficiliaRoutes from './OficiliaRoutes';

const Routes = [
  ...Examples,
  ...AuthRoutes,
  ...AdministrationRoutes,
  ...OficiliaRoutes,
  ...CompromisosRoutes,
  ...VolunteersRoutes,
  ...SocialRoutes,
  ...MapRoutes,
  ...SimulacrumRoutes,
  ...TerritorialRoutes,
  ...ElectoralRoutes,
  ...SijeRoutes,
  ...EventsRoutes,
  ...PrepRoutes,
  ...EncuestasRoutes,
  ...RcosRoutes,
  ...FrenteAmplioRoutes,
  ...ActivationRoute,
  ...MesaRoutes,
  ...SupportRoutes,
  ...NumeraliaRoutes,
  ...MetasRoutes,
  ...TrackingRoutes,
  ...MovilizacionRoutes,
  ...DiaDRoutes,
  ...BingoRoutes,
  ...FocaRoutes,
  ...PpmRoutes,
  ...ResultadosRoutes,
  ...CDERoute,
  ...Aportaciones,
  ...OficiliaRoutes,
];

export { Routes };
