import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Table, TableContainer, Card, Box, Typography } from '@mui/material';

import TableBody from './TableBodyCorregidos';
import TableHeader from './TableHead';

import Swal from 'sweetalert2';
import MovilizacionServices from '@services/MovilizationServices';
import { ESTATUS_CORREGIDO } from '@data/constants/Bingo';

const BingoCorregidos = ({ params, setLoading, seccionLocation }) => {
  const tableRef = useRef(null);
  /*   const location = useLocation();
  const seccion = location.state ? location.state : null; */

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataFound, setDataFound] = useState(true);

  const getCompromisosBingosList = async (value) => {
    setLoading(true);
    try {
      const params = {
        Seccion: seccionLocation ? seccionLocation : value.Seccion,
        idEstatus: ESTATUS_CORREGIDO,
      };

      const result = await MovilizacionServices.getCompromisosBingosList(params);
      const { results, response, success, message } = result;
      if (!results && !success) throw new Error(message);

      if (results) {
        setData(response.data);
        setTotal(response.total);
        setDataFound(true);
      } else {
        setData([]);
        setDataFound(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params && !seccionLocation) {
      console.log(params);
      getCompromisosBingosList(params);
    }

    if (!params && seccionLocation !== null) {
      getCompromisosBingosList(seccionLocation);
    }
  }, [params, seccionLocation]);

  return (
    <Card className="card-primary">
      <Box m={2}>
        <TableContainer
          ref={tableRef}
          sx={{
            maxHeight: '70vh',
            height: 'auto',
            overflow: 'auto',
            borderRadius: 3,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHeader isAction={false} />
            <TableBody data={data} dataFound={dataFound} />
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: '#888888' }}>
            Mostrando {Math.min(data.length, total)} de {total}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default BingoCorregidos;
