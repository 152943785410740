import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Swal } from '@utils/alerts';

// Material UI
import { Grid } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdaptiveCard from '@components/Card/AdaptiveCard';

// Servicios
import EventsServices from '@services/EventsServices';

// Utils
import { isEmptyOrNullObject } from '@utils/validations';

const Dashboard = () => {
  const { id } = useParams();

  const [event, setEvent] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) getData(); // eslint-disable-next-line
  }, [id]);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await EventsServices.getEventosDashboard({ idEvento: id });
      const { results, response, message } = result;

      if (results) setEvent(response.data);
      else throw new Error(message);
    } catch (e) {
      setEvent({});
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    } // eslint-disable-next-line
  }, []);

  return (
    <AdminLayout key={id}>
      <ActionHeader title={`Dashboard${!isEmptyOrNullObject(event) ? ' de ' + event.Actividad : ''}`} />

      <LoadingForm
        loadinMessage={'Cargando...'}
        successMessage={'Cargado con exito!'}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <AdaptiveCard
            title="Invitados"
            icon="record_voice_over"
            config={{ data: event.TotalInvitados }}
            leftConfig={{
              title: 'Femeninos',
              data: event.InvitadosFemeninos,
              percent: (event.InvitadosFemeninos / event.TotalInvitados) * 100,
            }}
            rightConfig={{
              title: 'Masculinos',
              data: event.InvitadosMasculinos,
              percent: (event.InvitadosMasculinos / event.TotalInvitados) * 100,
            }}
            loading={isLoading}
            adjust
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AdaptiveCard
            title="Asistencia"
            icon="how_to_reg"
            config={{ data: event.TotalAsistencia }}
            leftConfig={{
              title: 'Femeninos',
              data: event.AsistieronFemeninos,
              percent: (event.AsistieronFemeninos / event.TotalAsistencia) * 100,
            }}
            rightConfig={{
              title: 'Masculinos',
              data: event.AsistieronMasculinos,
              percent: (event.AsistieronMasculinos / event.TotalAsistencia) * 100,
            }}
            loading={isLoading}
            adjust
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Dashboard;
