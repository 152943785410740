import Yup from '@utils/Yupi18n';
// import moment from "moment";
//import { VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL, VIGENCIA_INE } from "@data/constants"

//const pattern = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/g

const MapAddPolygonSchema = Yup.object({
  idUsuarioRG: Yup.number().required().integer().label('RG'),
  idUsuarioAbogado: Yup.number().required().integer().label('Abogado'),
  Description: Yup.string().optional().nullable(true).label('Descripción'),
  Casillas: Yup.array().required().min(1, 'Selecciona al menos una casilla'),
});

const MapEditPolygonSchema = Yup.object({
  id: Yup.number().required().integer().positive().label('id'),
  idUsuarioRG: Yup.number().required().integer().label('RG'),
  idUsuarioAbogado: Yup.number().required().integer().label('Abogado'),
  Description: Yup.string().optional().nullable(true).label('Descripción'),
  Casillas: Yup.array().required().min(1, 'Selecciona al menos una casilla'),
});

export { MapAddPolygonSchema, MapEditPolygonSchema };
