import { TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  backgroundColor: grey[100],
}));

const TableHeader = () => {
  return (
    <TableHead sx={{ backgroundColor: grey[100] }}>
      <TableRow>
        <TableCellStyled align="right">
          <Typography variant="subtitle2" fontWeight={600}>
            SECCIÓN
          </Typography>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            RESPONSABLE
          </Typography>
        </TableCellStyled>
        <TableCellStyled>
          <Typography variant="subtitle2" fontWeight={600}>
            RESPONSABILIDAD
          </Typography>
        </TableCellStyled>
        <TableCellStyled align="center">COMPROMISOS MOVILIZADOS</TableCellStyled>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
