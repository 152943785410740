import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Material UI
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Button,
} from '@mui/material';
import { AccountCircle, LocalPhone } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

// Utils
import { isEmptyOrNullObject, isEmptyOrInvalidArray } from '@utils/validations';
import DiaDServices from '@services/DiaDServices';

const UserInfo = (props) => {
  const { params1, setParams1, params2, setParams2, setLoading, setLoadingMessage } = props;

  const [userData1, setUserData1] = useState([]);
  const [userData2, setUserData2] = useState([]);

  useEffect(() => {
    if (!isEmptyOrNullObject(params1)) getResponsability(params1.id, setUserData1);
    //eslint-disable-next-line
  }, [params1]);

  useEffect(() => {
    if (!isEmptyOrNullObject(params2)) getResponsability(params2.id, setUserData2);
    //eslint-disable-next-line
  }, [params2]);

  const handleConfirm = (item, setParams) => {
    Swal.fire({
      title: '¿Estás seguro de que quieres eliminar?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) handleDelete(item, setParams);
    });
  };

  const handleDelete = async (item, setParams) => {
    const sendParams = { id: item.id, idCompromisoUnico: item.idCompromisoUnico };

    try {
      setLoading(true);
      setLoadingMessage('Eliminando dato...');

      const result = await DiaDServices.deleteResponsibility(sendParams);
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });
        setParams((prev) => ({ ...prev, delete: item.id }));
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const getResponsability = async (idUsuario, setUserData) => {
    try {
      setLoading(true);
      setLoadingMessage('Cargando datos...');

      const result = await DiaDServices.getResponsabilitySocial({ idUsuario });
      const { results, response, message } = result;

      if (results) setUserData(response.data);
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    console.log(params2);
    const sendParams = {
      idPerfil: 201, // fijo
      idAmbitoCargo: 12, // fijo
      idCompromisoUnico: params1.idCompromisoUnico, // Primer Lista
      AmbitoValorCargo: [params2.id], // Segunda Lista
      ResponsabilidadEtiqueta: `Movilizador Social de ${params2.NombreCompleto}`, // Segunda Lista
      idUsurioAutorizo: 1, // fijo
      idPropietario: null,
      idMunicipio: null,
      Prioridad: null,
    };

    try {
      setLoading(true);
      setLoadingMessage('Guardado datos...');

      const result = await DiaDServices.createUsuarioDiad(sendParams);
      const { results, message } = result;
      if (!isEmptyOrNullObject(params1)) getResponsability(params1.id, setUserData1);
      if (results) Swal.fire({ title: message, icon: 'success' });
      else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="card-primary" sx={{ height: '100%', marginBottom: '0 !important' }}>
      <CardContent>
        <Box textAlign="center" mt={2}>
          <Typography component={'h6'} variant="h6" fontWeight={800} fontSize={18}>
            DATOS DEL USUARIO
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {!isEmptyOrNullObject(params1) || !isEmptyOrNullObject(params2) ? (
          <>
            {!isEmptyOrNullObject(params1) && (
              <>
                <Box mb={2}>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <AccountCircle fontSize="large" style={{ color: grey[500] }} />
                    <Typography fontWeight={600} variant="h6">
                      {params1.NombreCompleto}
                    </Typography>
                  </Stack>
                </Box>
                <Box mb={2}>
                  <Stack direction="row" spacing={2} alignItems="center" ml="2vh">
                    <LocalPhone />
                    <Typography fontWeight={400} fontSize={15}>
                      {params1.Celular}
                    </Typography>
                  </Stack>
                </Box>
                <Divider sx={{ mt: 1 }} />
                {!isEmptyOrInvalidArray(userData1) ? (
                  <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List dense>
                      {userData1.map((item, index) => (
                        <ListItem disablePadding key={index}>
                          <ListItemIcon sx={{ width: '100%' }}>
                            <IconButton color="error" onClick={(e) => handleConfirm(item, setParams1)}>
                              <Icon>delete</Icon>
                            </IconButton>
                            <ListItemText
                              primary={
                                <Tooltip title={item.label} disableInteractive arrow>
                                  <Box
                                    component={'span'}
                                    sx={{
                                      width: '100%',
                                      display: 'inline-block',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'noWrap',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {item.label ?? item.ResponsabilidadEtiqueta}
                                  </Box>
                                </Tooltip>
                              }
                              secondary={
                                <span>
                                  <Tooltip title={item.responsable} disableInteractive arrow>
                                    <Box
                                      component={'span'}
                                      sx={{
                                        width: '100%',
                                        display: 'inline-block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'noWrap',
                                      }}
                                    >
                                      {item.responsable}
                                    </Box>
                                  </Tooltip>
                                  {item.fecha}
                                </span>
                              }
                            />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Typography fontWeight={600} fontSize={15} align="center" my={2}>
                    SIN CERTIFICACIONES ASIGNADAS
                  </Typography>
                )}
                <Divider sx={{ mb: 2 }} />
              </>
            )}

            {!isEmptyOrNullObject(params2) && (
              <>
                <Box mb={2}>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <AccountCircle fontSize="large" style={{ color: grey[500] }} />
                    <Typography fontWeight={600} variant="h6">
                      {params2.NombreCompleto}
                    </Typography>
                  </Stack>
                </Box>
                <Box mb={2}>
                  <Stack direction="row" spacing={2} alignItems="center" ml="2vh">
                    <LocalPhone />
                    <Typography fontWeight={400} fontSize={15}>
                      {params2.Celular}
                    </Typography>
                  </Stack>
                </Box>
                <Divider sx={{ mt: 1 }} />
                {!isEmptyOrInvalidArray(userData2) ? (
                  <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List dense>
                      {userData2.map((item, index) => (
                        <ListItem disablePadding key={index}>
                          <ListItemIcon sx={{ width: '100%' }}>
                            <IconButton color="error" onClick={(e) => handleConfirm(item, setParams2)}>
                              <Icon>delete</Icon>
                            </IconButton>
                            <ListItemText
                              primary={
                                <Tooltip title={item.label} disableInteractive arrow>
                                  <Box
                                    component={'span'}
                                    sx={{
                                      width: '100%',
                                      display: 'inline-block',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'noWrap',
                                      fontWeight: 700,
                                    }}
                                  >
                                    Ruta: {item.label}
                                  </Box>
                                </Tooltip>
                              }
                              secondary={
                                <span>
                                  <Tooltip title={item.responsable} disableInteractive arrow>
                                    <Box
                                      component={'span'}
                                      sx={{
                                        width: '100%',
                                        display: 'inline-block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'noWrap',
                                      }}
                                    >
                                      {item.responsable}
                                    </Box>
                                  </Tooltip>
                                  {item.fecha}
                                </span>
                              }
                            />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Typography fontWeight={600} fontSize={15} align="center" my={2}>
                    SIN CERTIFICACIONES ASIGNADAS
                  </Typography>
                )}
              </>
            )}

            <Box>
              <Button
                fullWidth
                variant="contained"
                color="primaryDark"
                size="small"
                startIcon={<Icon>save</Icon>}
                onClick={handleSave}
                disabled={isEmptyOrNullObject(params1) || isEmptyOrNullObject(params2)}
              >
                Guardar
              </Button>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              p: 2,
            }}
          >
            <Icon fontSize="large">info</Icon>
            <Typography variant="body1" align="center">
              Sin Información. Seleccione un usuario.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfo;
