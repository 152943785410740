import { PrivilegedRoute } from '@middlewares';
import Movilization from '@pages/Rcos/Movilization';
import Polygonal from '@pages/Rcos/Polygonal';
import Municipal from '@pages/Rcos/Municipal';
import Regional from '@pages/Rcos/Regional';
import Estatal from '@pages/Rcos/Estatal';
import RegionMunicipio from '@pages/Rcos/RegionMunicipio';
import MunicipioPolygonal from '@pages/Rcos/MunicipioPolygonal';

const SijeRoutes = [
  {
    path: '/rco-movilizacion',
    element: (
      <PrivilegedRoute>
        <Movilization />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-poligonal',
    element: (
      <PrivilegedRoute>
        <Polygonal />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-municipal',
    element: (
      <PrivilegedRoute>
        <Municipal />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-regional',
    element: (
      <PrivilegedRoute>
        <Regional />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-estatal',
    element: (
      <PrivilegedRoute>
        <Estatal />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-region-municipio',
    element: (
      <PrivilegedRoute>
        <RegionMunicipio />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/rco-municipio-poligono',
    element: (
      <PrivilegedRoute>
        <MunicipioPolygonal />
      </PrivilegedRoute>
    ),
  },
];
export default SijeRoutes;
