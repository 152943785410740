import { useEffect, useState } from 'react';
import useSearchData from '@hooks/useSearchData';

import {
  Table,
  TableContainer,
  Card,
  Typography,
  Icon,
  Button,
  CardContent,
  CardActions,
  TextField,
  Divider,
  Stack,
  Switch,
  Chip,
  Container,
  Alert,
} from '@mui/material';
import { green } from '@mui/material/colors';

import TableBody from './TableBody';
import TableEmpty from '../RollCall/TableEmpty';
import TableHeader from './TableHead';

import movilizacion from '@services/MovilizationServices';
import Swal from 'sweetalert2';
import middleware from '@middlewares/middleware';

const ListMobilization = ({ data, paramsFilter, dataFound, setMovilization, getData }) => {
  const [switchEdit, setSwitchEdit] = useState({
    checked: false,
    enabled: false,
  });
  const [editedData, setEditedData] = useState([]);
  const { filteredData, searchValue, handleSearch } = useSearchData(data, () => {}, 'NombreCompleto');

  const handleEnableInputs = (e) => {
    if (switchEdit.checked === false) {
      setSwitchEdit({ ...switchEdit, checked: e.target.checked });
      Swal.fire({
        title: '¿Está seguro de habilitar los campos?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Habilitar',
        cancelButtonText: 'No, Cancelar',
      }).then(({ isConfirmed, dismiss }) => {
        if (isConfirmed) {
          resetMobilizedBySchedule({
            Seccion: paramsFilter.seccion,
            Hora: paramsFilter.hora,
          });
        } else if (dismiss) {
          setSwitchEdit((prevState) => ({ ...prevState, checked: false }));
        }
      });
    }
  };

  const handleEditMovilizados = (idUsuario, newValue) => {
    const updatedData = editedData.map((row) =>
      row.idUsuario === idUsuario ? { ...row, movilizados: newValue } : row
    );
    setEditedData(updatedData);
  };

  const resetMobilizedBySchedule = async (body) => {
    try {
      const res = await movilizacion.resetMobilizedBySchedule(body);
      const { results, message } = res;
      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            getData({
              ...paramsFilter,
              municipio: '',
              poligono: '',
            });
          }
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  };

  const handleSaveData = () => {
    const combinedData = data.map((row) => {
      const editedRow = editedData.find((editedRow) => editedRow.idUsuario === row.idUsuario);
      return editedRow || row;
    });

    const users = combinedData.map(({ idUsuario, movilizados }) => ({
      idUsuario,
      movilizados: parseInt(movilizados),
    }));

    setMovilization({ users });
  };

  useEffect(() => {
    setEditedData([...data]);
    setSwitchEdit({ ...switchEdit, checked: false });
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (searchValue.length > 0) {
      setEditedData([...filteredData]);
    } else {
      setEditedData([...data]);
    }
  }, [searchValue]);

  return (
    <Card className="card-primary">
      <Container sx={{ p: 2 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
          <Typography fontWeight={600} variant="subtitle2">
            RESPONSABLES
          </Typography>
          <TextField
            placeholder="Buscar por nombre"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <Icon fontSize="small" color="action">
                  search
                </Icon>
              ),
              sx: { borderRadius: 2 },
            }}
            sx={{ width: '70%' }}
          />
          {middleware.checkMenuAction('Editar') &&
            (data.length > 0 && data.some((item) => item.idEstatus === 2) ? (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Switch
                  checked={switchEdit.checked}
                  onChange={handleEnableInputs}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="body2">Habilitar Edición</Typography>
              </Stack>
            ) : (
              data.length > 0 &&
              data.some((item) => item.esPaseLista === 1) && (
                <Chip
                  label="Edición Habilitada"
                  size="small"
                  sx={{ backgroundColor: green[50], color: green[900] }}
                />
              )
            ))}
        </Stack>
        <Alert
          severity="error"
          color="warning"
          sx={{
            mt: 1,
            display: data.length > 0 && data.some((item) => item.esPaseLista !== 1) ? 'flex' : 'none',
            alignItems: 'center',
          }}
        >
          Advertencia. Si el responsable tiene el símbolo{' '}
          {
            <Icon fontSize="small" color="error">
              error
            </Icon>
          }{' '}
          la edición no estará habilitada, ya que aún no ha realizado el pase de lista.
        </Alert>
        <Divider sx={{ mt: 1 }} />
      </Container>
      <CardContent>
        <TableContainer
          sx={{
            maxHeight: 440,
            borderRadius: 3,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHeader />
            {filteredData.length === 0 && searchValue.length !== 0 ? (
              <TableEmpty />
            ) : (
              <TableBody
                data={editedData}
                dataFound={dataFound}
                handleEditMovilizados={handleEditMovilizados}
              />
            )}
          </Table>
        </TableContainer>
      </CardContent>
      {data.length > 0 && (
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" color="primaryDark" onClick={() => handleSaveData({ data })}>
            Guardar
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ListMobilization;
