import { useEffect, useState } from 'react';

import { Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import BasicSelect from '@components/Selects/BasicSelect';
import AutoComplete from '@components/Selects/Autocomplete';
//import SelectSearch from "@components/Selects/SelectSearch";

import sije from '@services/SijeService';
import { CATALOG_OPTIONS } from '@data/constants/Mobilization';
import Swal from 'sweetalert2';

const Filter = ({
  catGroupTable,
  onSearch,
  onClear,
  setIdAmbitoAgrupador,
  refresh,
  setLoading,
  setData,
  setCatGroupTableFilter,
  setIdAmbitoAgrupadorChart,
  setIdAmbitoAgrupadorTable,
}) => {
  const defaultOption = { value: 0, label: 'TODOS' };
  const [typeView, setTypeView] = useState([2]);
  const [catalogs, setCatalogs] = useState({});
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    Object.keys(CATALOG_OPTIONS).forEach((key) => {
      CATALOG_OPTIONS[key.id] = [];
    });

    return newObject;
  });
  const [catView] = useState([
    { label: 'Regional', value: 2 },
    { label: 'Distrito Federal', value: 3 },
    { label: 'Distrito Local', value: 4 },
  ]);
  const [filterValue, setFilterValue] = useState({
    tipoVista: 2,
    region: 0,
    ditritoFederal: 0,
    distritoLocal: 0,
    municipio: 0,
    poligono: 0,
    seccion: defaultOption,
    color: '',
  });

  const isIncluded = (value) => typeView.includes(value);

  const getCatalogs = async () => {
    setLoadingCatalogs(true);
    setLoading((prevState) => ({
      ...prevState,
      isLoadigForm: true,
      openLinearProgress: true,
    }));

    try {
      const result = await sije.getCatalogs({ catalogs: CATALOG_OPTIONS });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    } finally {
      setLoadingCatalogs(false);
      setLoading((prevState) => ({
        ...prevState,
        isLoadigForm: false,
        openLinearProgress: false,
      }));
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeTypeView = ({ event }) => {
    const view = event.target.value;
    setIdAmbitoAgrupador((prevState) => ({
      ...prevState,
      idAmbitoAgrupador: view,
    }));
    setIdAmbitoAgrupadorChart((prevState) => ({
      ...prevState,
      filtered: [],
      idAmbitoAgrupador: view,
    }));
    setFilterValue((prevState) => ({
      ...prevState,
      tipoVista: view,
      region: 0,
      ditritoFederal: 0,
      distritoLocal: 0,
      municipio: 0,
      poligono: 0,
      seccion: defaultOption,
      color: '',
    }));
    setTypeView([view]);
    setIdAmbitoAgrupadorTable('');
    setCatGroupTableFilter(catGroupTable);
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    setData((prevState) => ({
      ...prevState,
      page: 0,
      pageSize: 10,
      filtered: [],
    }));
  };

  const handleChangeFilter = ({ name, value }) => {
    setFilterValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    setCatalogFiltered((prevState) => ({
      ...prevState,
      [filterKey]: [defaultOption, ...catalogs[filterKey].filter((item) => item[filterType] === filterValue)],
    }));
  };

  const handleChangeRegion = ({ event }) => {
    const _idRegion = event.target.value;
    if (_idRegion > 0) {
      handleCatalogFilterChange({
        filterKey: 'municipios_reportes',
        filterType: 'idRegion',
        filterValue: _idRegion,
      });
      handleCatalogFilterChange({
        filterKey: 'poligonos',
        filterType: 'idRegion',
        filterValue: _idRegion,
      });
      handleCatalogFilterChange({
        filterKey: 'secciones',
        filterType: 'idRegion',
        filterValue: _idRegion,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangeDfs = ({ event }) => {
    const _idDfs = event.target.value;
    if (_idDfs > 0) {
      const municipios_df = catalogs.municipios_df.filter((item) => item.idDF === _idDfs);
      setCatalogFiltered((prevState) => ({
        ...prevState,
        municipios_reportes: [defaultOption].concat(
          municipios_df.map((item) => ({
            value: item.idMunicipioReportes,
            label: item.Municipio,
          }))
        ),
      }));

      handleCatalogFilterChange({
        filterKey: 'poligonos',
        filterType: 'idDF',
        filterValue: _idDfs,
      });
      handleCatalogFilterChange({
        filterKey: 'secciones',
        filterType: 'idDF',
        filterValue: _idDfs,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangeDls = ({ event }) => {
    const _idDls = event.target.value;
    if (_idDls > 0) {
      handleCatalogFilterChange({
        filterKey: 'poligonos',
        filterType: 'idDL',
        filterValue: _idDls,
      });

      handleCatalogFilterChange({
        filterKey: 'secciones',
        filterType: 'idDL',
        filterValue: _idDls,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        municipio: 0,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangeMunicipality = ({ event }) => {
    const _idMunicipality = event.target.value;
    if (_idMunicipality > 0) {
      handleCatalogFilterChange({
        filterKey: 'poligonos',
        filterType: 'idMunicipioReportes',
        filterValue: _idMunicipality,
      });
      handleCatalogFilterChange({
        filterKey: 'secciones',
        filterType: 'idMunicipioReportes',
        filterValue: _idMunicipality,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        poligono: 0,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        poligono: 0,
        seccion: defaultOption,
      }));
    }
  };

  const handleChangePolygon = ({ event }) => {
    const _idPolygon = event.target.value;
    if (_idPolygon > 0) {
      handleCatalogFilterChange({
        filterKey: 'secciones',
        filterType: 'idPoligono',
        filterValue: _idPolygon,
      });

      setFilterValue((prevState) => ({
        ...prevState,
        seccion: defaultOption,
      }));
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );
      setFilterValue((prevState) => ({
        ...prevState,
        seccion: defaultOption,
      }));
    }
  };

  const handleSearch = () => {
    const filters = [
      {
        id: 'idRegion',
        key: 'region',
      },
      {
        id: 'idDF',
        key: 'ditritoFederal',
      },
      {
        id: 'idDL',
        key: 'distritoLocal',
      },
      {
        id: 'idMunicipioReportes',
        key: 'municipio',
      },
      {
        id: 'idPoligono',
        key: 'poligono',
      },
      {
        id: 'Seccion',
        key: 'seccion',
      },
      {
        id: 'color',
        key: 'color',
      },
    ];

    const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: '=',
        value: filterValue[filter.key],
      }));

    onSearch({ filtereds: filtered });
  };

  const handleClear = () => {
    setFilterValue((prevState) => ({
      ...prevState,
      tipoVista: 2,
      region: 0,
      ditritoFederal: 0,
      distritoLocal: 0,
      municipio: 0,
      poligono: 0,
      seccion: defaultOption,
      color: '',
    }));
    setTypeView([2]);
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    onClear();
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh) {
      handleClear();
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <Card className="card-primary" sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="subtitle2" fontWeight={600} marginBottom={2}>
          FILTROS
        </Typography>

        <Grid2 container>
          <Grid2 xs={12} md={4} lg={4}>
            <BasicSelect
              name="tipoVista"
              label="Agrupar por"
              options={catView}
              value={filterValue.tipoVista}
              onChange={(e) => {
                handleChangeTypeView({ event: e });
              }}
              sx={{ width: '100%' }}
            />
          </Grid2>
        </Grid2>
        <Divider sx={{ mt: 1 }} />

        <Grid2 container marginTop={1} spacing={2}>
          {typeView.includes(2) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="region"
                label="Región"
                options={catalogsFiltered.regiones}
                value={filterValue.region}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeRegion({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid2>
          )}
          {isIncluded(3) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="ditritoFederal"
                label="Distrito Federal"
                options={catalogsFiltered.dfs}
                value={filterValue.ditritoFederal}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeDfs({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid2>
          )}
          {isIncluded(4) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="distritoLocal"
                label="Distrito Local"
                options={catalogsFiltered.dls}
                value={filterValue.distritoLocal}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeDls({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid2>
          )}
          {(isIncluded(2) || isIncluded(3)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={filterValue.municipio}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangeMunicipality({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid2>
          )}

          {(isIncluded(2) || isIncluded(3) || isIncluded(4)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="poligono"
                label="Polígono"
                options={catalogsFiltered.poligonos}
                value={filterValue.poligono}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  handleChangePolygon({ event: e });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
            </Grid2>
          )}

          {(isIncluded(2) || isIncluded(3) || isIncluded(4)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <AutoComplete
                name="seccion"
                label="Sección"
                value={filterValue.seccion}
                size="small"
                data={
                  catalogsFiltered.secciones !== undefined
                    ? catalogsFiltered.secciones.map((item) => ({
                        value: item.value,
                        label: String(item.label),
                      }))
                    : []
                }
                setSelect={(e) =>
                  handleChangeFilter({
                    name: 'seccion',
                    value: e.label,
                  })
                }
                loading={loadingCatalogs}
                sx={{ width: '100%' }}
              />
              {/*<SelectSearch
                name="seccion"
                placeholder="Sección"
                multiple={false}
                closeMenuOnSelect={true}
                value={filterValue.seccion}
                size="small"
                options={
                  catalogsFiltered.secciones !== undefined
                    ? catalogsFiltered.secciones.map((item) => ({
                        value: item.value,
                        label: String(item.label),
                      }))
                    : []
                }
                onChange={(e) =>
                  handleChangeFilter({
                    name: "seccion",
                    value: e.value,
                  })
                }
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              /> */}
            </Grid2>
          )}
          {/*  {(isIncluded(2) || isIncluded(4)) && (
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="color"
                label="Color"
                options={catView}
                value={filterValue.color}
                onChange={(e) => {
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
          )} */}
        </Grid2>
        <Stack direction={'row'} spacing={1} marginTop={2} justifyContent={'flex-end'}>
          <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
            Limpiar
          </Button>
          <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
            Filtrar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Filter;
