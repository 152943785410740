import React, { useState } from 'react';
import FilterCollapse from '@components/Filters/FilterCollapse';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Button, TextField } from '@mui/material';
import Select from 'react-select';
import { isTypePhone } from '@utils/validations';
import makeAnimated from 'react-select/animated';

import { useCatalogs } from '@hooks/useCatalogs';

const FilterINE = (props) => {
  const { refreash, onChangeFilter, Estatus = true, Personal = true } = props;
  const animatedComponents = makeAnimated();
  const catalogsParams = [{ id: 'estatus_certificacion_ine', getAll: false }];
  const [filter, setFilter] = useState({
    Celular: '',
    ClaveElector: '',
    NombreCompleto: '',
    Estatus: { value: 0, label: 'TODOS' },
  });

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  };

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Celular: filter.Celular,
      ClaveElector: filter.ClaveElector,
      NombreCompleto: filter.NombreCompleto,
      Estatus: filter.Estatus.value,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    const newFilter = {
      Celular: '',
      ClaveElector: '',
      NombreCompleto: '',
      Estatus: { value: 0, label: 'TODOS' },
    };

    let sendFilter = {
      Celular: '',
      ClaveElector: '',
      NombreCompleto: '',
      Estatus: 0,
    };

    setFilter(newFilter);
    onChangeFilter(sendFilter);
  };

  return (
    <FilterCollapse expand={false} effect={refreash}>
      <Grid2 container spacing={2} marginBottom={'1rem'} marginTop={'0.5rem'}>
        {Personal && (
          <>
            <Grid2 item xs={12} md={4} lg={4}>
              <TextField
                label="Celular"
                size="small"
                name="Celular"
                value={filter.Celular}
                onChange={(e) =>
                  hadleChangeTextField({
                    target: {
                      name: 'Celular',
                      value: isTypePhone(e.target.value) ? e.target.value : filter.Celular,
                    },
                  })
                }
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 item xs={12} md={3} lg={4}>
              <TextField
                label="Nombre"
                size="small"
                name="NombreCompleto"
                value={filter?.NombreCompleto}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid2>
            <Grid2 item xs={12} md={3} lg={4}>
              <TextField
                label="Clave Elector"
                size="small"
                name="ClaveElector"
                value={filter?.ClaveElector}
                onChange={hadleChangeTextField}
                sx={{ width: '100%' }}
              />
            </Grid2>
          </>
        )}
        {Estatus && (
          <Grid2 item xs={12} md={3} lg={4}>
            <Select
              styles={selectStyles}
              menuPortalTarget={document.body}
              components={animatedComponents}
              placeholder="Estatus"
              closeMenuOnSelect={true}
              value={
                catalogs?.estatus_certificacion_ine
                  ? catalogs.estatus_certificacion_ine.find((item) => item.value === filter.Estatus)
                  : null
              }
              onChange={(e) => {
                setFilter({ ...filter, Estatus: e });
              }}
              options={[{ value: 0, label: 'Todos' }].concat(catalogs.estatus_certificacion_ine)}
            />
          </Grid2>
        )}
      </Grid2>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant="contained" color="primaryDark" onClick={searchFilter}>
          Filtrar
        </Button>
        <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
          Limpiar
        </Button>
      </Box>
    </FilterCollapse>
  );
};
export default FilterINE;
