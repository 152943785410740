import { useEffect, useState } from 'react';
import TableMovilización from '../../components/FrenteAmplio/TableMovilización';

import Swal from 'sweetalert2';
import FrenteAmplioServices from '@services/FrenteAmplioServices';
import { useSelection } from '../../hooks/use-funcion';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { Box, Container, Stack, Tooltip, Chip, Link, Card, CardContent } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { useCatalogs } from '@hooks/useCatalogs';
import FilterAMovilizar from '../../components/FrenteAmplio/FilterAMovilizar';

const MyListMove = () => {
  const catalogsParams = [{ id: 'estatus_movilizacion' }];
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const [data, setData] = useState([]);
  const [params] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const colums = [
    {
      label: '',
      id: '',
      isOnChange: true,
      align: 'center',
      width: 200,
    },
    {
      label: 'Nombre',
      id: 'Persona',
      columnAction: false,
      align: 'center',
      width: 250,
    },
    {
      label: 'Teléfono',
      id: 'Telefono',
      align: 'center',
      columnAction: false,
      width: 100,
    },
    {
      label: 'Dirección',
      id: 'DomicilioCompleto',
      align: 'center',
      columnAction: false,
      width: 250,
    },
    {
      label: 'Movilizador',
      id: 'Movilizador',
      align: 'center',
      columnAction: false,
      width: 220,
    },
    {
      label: 'Liderazgo',
      id: 'Liderazgo',
      align: 'center',
      columnAction: false,
      width: 250,
    },
    {
      label: 'Fecha Asignación',
      id: 'FechaActualizo',
      align: 'center',
      columnAction: false,
      width: 180,
    },
  ];
  const dataSelection = useSelection({
    items: data,
    Api: FrenteAmplioServices.getMyListMovilizacion,
    infinite: true,
  });
  const [loadingUpdate, setLoadingUpdate] = useState({});

  useEffect(() => {
    const list = dataSelection.data.map((item) => {
      return {
        ...item,
        valueOnchange: item.idEstatus,
        Telefono: (
          <Stack direction="column" spacing={1}>
            {item.Celular && item.Celular !== null && (
              <Tooltip title="Celular" placement="right-start">
                <Chip
                  component={Link}
                  href={`whatsapp://send?phone=52${item.Celular}&text=Hola%20*${item.NombreCompleto}*`}
                  target="_blank"
                  color={'primary'}
                  icon={<PhoneIphoneIcon />}
                  label={item.Celular}
                  variant="outlined"
                />
              </Tooltip>
            )}
            {item.Telefono && item.Telefono !== null && (
              <Tooltip title="Teléfono" placement="right-start">
                <Chip color={'primary'} icon={<LocalPhoneIcon />} label={item.Telefono} variant="outlined" />
              </Tooltip>
            )}
            {item.TelMensajes && item.TelMensajes !== null && (
              <Tooltip title="Teléfono de Recados" placement="right-start">
                <Chip
                  color={'primary'}
                  icon={<PermPhoneMsgIcon />}
                  label={item.TelMensajes}
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Stack>
        ),
      };
    });
    setData(list);
  }, [dataSelection.data]);

  const hadleUpdateStatus = (e) => {
    let send = {
      idCompromisoUnico: e.idCompromisoUnico,
      idEstatus: e.valueOnchange,
      Comentario: e.Comentario,
    };
    FrenteAmplioServices.setUpdateStatus(send)
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      })
      .finally(() => {
        setLoadingUpdate(e);
      });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={'A Movilizar'} />
        <Box>
          <FilterAMovilizar onChangeFilter={dataSelection.handleChangeFilter} />
          <Card>
            <CardContent>
              <TableMovilización
                colums={colums}
                rows={data}
                count={dataSelection.total}
                /* hasMore={dataSelection.hasMore}
              onPageChange={dataSelection.fetchHas} */
                isLoading={dataSelection.isLoading}
                optionComent={3}
                pageProp={params.page}
                pageSize={params.pageSize}
                labelOnChange="Estatus"
                showPagination={true}
                dataOnchange={catalogs.estatus_movilizacion ? catalogs.estatus_movilizacion : []}
                handleChangePagination={dataSelection.handleChangePagination}
                onDeselectAll={dataSelection.handleDeselectAll}
                onDeselectOne={dataSelection.handleDeselectOne}
                onSelectAll={dataSelection.handleSelectAll}
                onSelectOne={dataSelection.handleSelectOne}
                onChange={(e) => hadleUpdateStatus(e)}
                loadingUpdate={loadingUpdate}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};
export default MyListMove;
