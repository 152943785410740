import React, { useState, useEffect, useCallback } from 'react';

import { Box, Icon, Chip, TextField, InputAdornment } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isEmptyOrInvalidString, isNullOrUndefined, isTypePhone } from '@utils/validations';

import CatalogService from '@services/CatalogServices';

import BasicSelect from '@components/Selects/BasicSelect';
import AutoComplete from '@components/Selects/Autocomplete';

import Swal from 'sweetalert2';

const AddressForm = (props) => {
  const {
    catalogs,
    loadigCatalogs,
    errors,
    touched,
    values,
    handleChange,
    setValues,
    movile,
    domiciliotext = 'Domicilio donde vive',
    NotVialidad = false,
  } = props;

  const [catSuburbLives, setCatSuburbLives] = useState([]);
  const [catLocalitiesLives, setCatLocalitiesLives] = useState([]);
  const [isLoadingCatLocLives, setIsLoadingCatLocLives] = useState(false);

  useEffect(() => {}, [props.movil]);

  useEffect(() => {
    if (values.idMunicipioVive && parseInt(values.idMunicipioVive) > 0) {
      loadCatalogsByMunicipio(values.idMunicipioVive);
    }
    // eslint-disable-next-line
  }, [values.idMunicipioVive]);

  const loadCatalogsByMunicipio = (municipalityId) => {
    let catalogsParams = [
      {
        id: 'localidades',
        filtered: [{ id: 'idMunicipio', filter: '=', value: municipalityId }],
      },
      {
        id: 'colonias',
        filtered: [{ id: 'idMunicipio', filter: '=', value: municipalityId }],
      },
    ];

    setIsLoadingCatLocLives(true);

    if (movile) {
      CatalogService.getCatalogsPublicEvents({
        catalogs: catalogsParams,
      })
        .then((res) => {
          if (res.success && res.results) {
            setCatLocalitiesLives(res.response.catalogs.localidades);
            handleChange({
              target: {
                name: 'idLocalidadVive',
                value: res.response.catalogs.localidades.find(
                  (item) => `${item.value}`.slice(-4, item.value.length) === '0001'
                ).value,
              },
            });
            setCatSuburbLives(res.response.catalogs.colonias);
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => setIsLoadingCatLocLives(false));
    } else {
      CatalogService.getCatalogs(catalogsParams)
        .then((res) => {
          if (res.success && res.results) {
            setCatLocalitiesLives(res.response.catalogs.localidades);
            handleChange({
              target: {
                name: 'idLocalidadVive',
                value: res.response.catalogs.localidades.find(
                  (item) => `${item.value}`.slice(-4, item.value.length) === '0001'
                ).value,
              },
            });
            setCatSuburbLives(res.response.catalogs.colonias);
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: 'warning' });
        })
        .finally(() => setIsLoadingCatLocLives(false));
    }
  };

  const handleChangeMunicipalityLives = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      idMunicipioVive: value,
      idLocalidadVive: '',
      ColoniaVive: '',
    });

    loadCatalogsByMunicipio(value);
  };

  const handleChangeSuburbLives = (e) => {
    if (e.CP) {
      setValues({
        ...values,
        ColoniaVive: e.label,
        CPVive: !isNaN(parseInt(e.CP)) ? parseInt(e.CP) : '',
      });
    } else {
      setValues({
        ...values,
        ColoniaVive: e.label,
        CPVive:
          isNullOrUndefined(values.CPVive) || isNaN(parseInt(values.CPVive)) ? '' : parseInt(values.CPVive),
      });
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>home</Icon>} label={domiciliotext} />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        {!NotVialidad && (
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <BasicSelect
              error={touched.TipoVialidad && !isEmptyOrInvalidString(errors.TipoVialidad)}
              errorMessage={errors.TipoVialidad}
              name="TipoVialidad"
              label="Tipo de Vialidad *"
              options={catalogs.tipo_vialidad}
              value={catalogs.tipo_vialidad && catalogs.tipo_vialidad.length > 0 ? values.TipoVialidad : ''}
              onChange={handleChange}
              sx={{ width: '100%' }}
              isLoading={loadigCatalogs}
            />
          </Grid2>
        )}
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.CalleVive && !isEmptyOrInvalidString(errors.CalleVive)}
            label="Calle"
            helperText={touched.CalleVive && errors.CalleVive && errors.CalleVive}
            type="text"
            name="CalleVive"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.CalleVive.toUpperCase()}
            size="small"
            variant="outlined"
            className="fixed-input"
            InputProps={
              !NotVialidad && {
                startAdornment: (
                  <InputAdornment position="start">
                    {values.TipoVialidad !== '' ? values.TipoVialidad : ''}
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid2>
        <Grid2 xs={6} sm={12} md={3} lg={3}>
          <TextField
            error={touched.NumExtVive && !isEmptyOrInvalidString(errors.NumExtVive)}
            helperText={touched.NumExtVive && errors.NumExtVive && errors.NumExtVive}
            sx={{ width: '100%' }}
            required
            label="Número Ext."
            type="text"
            name="NumExtVive"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.NumExtVive && values.NumExtVive.length > 0 && values.NumExtVive.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        <Grid2 xs={6} sm={12} md={3} lg={3}>
          <TextField
            error={touched.NumIntVive && !isEmptyOrInvalidString(errors.NumIntVive)}
            helperText={touched.NumIntVive && errors.NumIntVive && errors.NumIntVive}
            sx={{ width: '100%' }}
            label="Número Int."
            type="text"
            name="NumIntVive"
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.trim(),
                },
              });
            }}
            value={values.NumIntVive && values.NumIntVive.length > 0 && values.NumIntVive.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <BasicSelect
            error={touched.idMunicipioVive && !isEmptyOrInvalidString(errors.idMunicipioVive)}
            errorMessage={errors.idMunicipioVive}
            name="idMunicipioVive"
            label="Muncipio *"
            options={catalogs.municipios}
            value={values.idMunicipioVive}
            onChange={handleChangeMunicipalityLives}
            isLoading={loadigCatalogs}
            sx={{ width: '100%' }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <BasicSelect
            error={touched.idLocalidadVive && !isEmptyOrInvalidString(errors.idLocalidadVive)}
            errorMessage={errors.idLocalidadVive}
            name="idLocalidadVive"
            label="Localidad *"
            options={catLocalitiesLives}
            value={values.idLocalidadVive}
            onChange={handleChange}
            isLoading={isLoadingCatLocLives}
            sx={{ width: '100%' }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <AutoComplete
            required
            label="Colonia*"
            name="ColoniaVive"
            autoComplete="ColoniaVive"
            value={values.ColoniaVive && values.ColoniaVive.length > 0 && values.ColoniaVive.toUpperCase()}
            id="ColoniaVive"
            size="small"
            disableUnderline={false}
            setSelect={(e) => handleChangeSuburbLives(e)}
            data={catSuburbLives}
            error={touched.ColoniaVive && !isEmptyOrInvalidString(errors.ColoniaVive)}
            helperText={touched.ColoniaVive && errors.ColoniaVive && errors.ColoniaVive}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.CPVive && !isEmptyOrInvalidString(errors.CPVive)}
            helperText={touched.CPVive && errors.CPVive && errors.CPVive}
            sx={{ width: '100%' }}
            required
            label="Código Postal"
            type="text"
            name="CPVive"
            onChange={(e) =>
              handleChange({
                target: {
                  name: 'CPVive',
                  value: isTypePhone(e.target.value) ? e.target.value : values.CPVive,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: 'CPVive',
                  value: isTypePhone(e.target.value) ? e.target.value : values.CPVive,
                },
              })
            }
            value={values.CPVive}
            variant="outlined"
            size="small"
            className="fixed-input"
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default AddressForm;
