import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useCatalogs } from '@hooks/useCatalogs';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import Toast from '../Toast';
import WebCam from '../WebCam';
import AdvancedSelect from '@components/Selects/AdvancedSelect';

import { useMobileServices } from '@hooks/MobileDiaD/MobileServices';
import { CapturaIncidenciaInterface } from '@data/interfaces/SijeInterface';
import { isEmptyOrInvalidString } from '@utils/validations';
import { capturaIncidenciaSchema } from '@data/schemas/SijeSchema';
import moment from 'moment/moment';

const FormIncidencias = ({ idCasilla, NombreCasilla, Seccion, setShowForm }) => {
  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: 'incidencias', getAll: false }],
    putDefaultOption: false,
  });

  const { loading, snackbar, setSnackbar, setIncidencia } = useMobileServices();

  const [image, setImage] = useState(null);

  const formik = useFormik({
    initialValues: CapturaIncidenciaInterface,
    validationSchema: capturaIncidenciaSchema,
    onSubmit: (values) => {
      handleSetIncidencia(values);
    },
  });

  const handleCheckActionType = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'AsentadoEnActa') {
      if (checked) {
        formik.setFieldValue('AsentadoEnActa', parseInt(value), false);
      } else {
        formik.setFieldValue('AsentadoEnActa', '', false);
      }
    } else if (name === 'esResuelto') {
      if (checked) {
        formik.setFieldValue('esResuelto', parseInt(value), false);
      } else {
        formik.setFieldValue('esResuelto', '', false);
      }
    }
  };

  const handleSetIncidencia = async (values) => {
    const params = {
      ...values,
      idStatus: values.esResuelto ? 3 : 1,
      ComoSeResolvio: values.esResuelto ? values.ComoSeResolvio : null,
      HoraSolucion: values.esResuelto ? values.HoraSolucion : null,
    };

    if (image) params.files = [{ file: image }];
    delete params.seccion;

    const results = await setIncidencia(params);

    if (results) clearForm();
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      idCasilla: idCasilla,
      HoraIncidencia: moment().format('HH:mm'),
      HoraSolucion: moment().format('HH:mm'),
    });
  }, []);

  const clearForm = () => {
    formik.setValues({
      seccion: '',
      idCasilla: '',
      idIncidencia: '',
      DescripcionIncidencia: '',
      AsentadoEnActa: 0,
      HoraIncidencia: moment().format('HH:mm'),
      esResuelto: 0,
      ComoSeResolvio: '',
      HoraSolucion: moment().format('HH:mm'),
    });
    setImage(null);
  };

  return (
    <>
      <Toast open={snackbar.show} message={snackbar.message} setOpen={setSnackbar} />

      <Container>
        <Stack direction="column">
          <Stack
            direction="row"
            alignItems={'center'}
            // justifyContent={"center"}
          >
            <IconButton onClick={() => setShowForm(false)}>
              <Icon sx={{ color: 'black' }}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography variant="body1">Reporte de Incidencias en la Casilla</Typography>
          </Stack>

          <Box textAlign={'center'} marginBottom={2} marginTop={2}>
            <Typography variant="body1" fontWeight={600}>
              Reporte de Incidencias
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Seccion}
            </Typography>

            <Typography variant="subtitle1" fontWeight={600}>
              {NombreCasilla}
            </Typography>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2" marginBottom={1}>
              Incidencia
            </Typography>
            <AdvancedSelect
              name="idIncidencia"
              label="Buscar la incidencia"
              options={catalogs.incidencias}
              value={formik.values.idIncidencia}
              onChange={(e) => {
                formik.handleChange({
                  target: { name: 'idIncidencia', value: e.value },
                });
              }}
              error={formik.touched.idIncidencia && !isEmptyOrInvalidString(formik.errors.idIncidencia)}
              errorMessage={formik.errors.idIncidencia}
              isSearchable
              isLoading={load}
            />
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">Fue asentado en el Acta de la Joranda Electoral</Typography>
            <FormGroup row sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.AsentadoEnActa && formik.values.AsentadoEnActa === 1 ? true : false
                    }
                    name="AsentadoEnActa"
                    value={1}
                    onChange={handleCheckActionType}
                  />
                }
                label={
                  <Typography variant={'body2'} fontWeight={600}>
                    Si fue Asentado
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !formik.values.AsentadoEnActa && formik.values.AsentadoEnActa === 0 ? true : false
                    }
                    name="AsentadoEnActa"
                    value={0}
                    onChange={handleCheckActionType}
                  />
                }
                label={
                  <Typography variant={'body2'} fontWeight={600}>
                    No fue Asentado
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2" marginBottom={1}>
              Hora Incidente, ejemplo: 07:30
            </Typography>
            <TextField
              label={'Hora Incidencia (Formato 24hrs)'}
              name="HoraIncidencia"
              value={formik.values.HoraIncidencia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              fullWidth
              InputProps={{
                size: 'small',
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>schedule</Icon>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.HoraIncidencia && !isEmptyOrInvalidString(formik.errors.HoraIncidencia)}
              helperText={
                formik.touched.HoraIncidencia && formik.errors.HoraIncidencia && formik.errors.HoraIncidencia
              }
            />
          </Box>

          <Box marginBottom={1}>
            <Typography variant="body2" marginBottom={1}>
              Descripción del incidente
            </Typography>
            <TextField
              label="Describa aquí lo que pasó"
              name="DescripcionIncidencia"
              value={formik.values.DescripcionIncidencia.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              multiline
              rows={3}
              fullWidth
              error={
                formik.touched.DescripcionIncidencia &&
                !isEmptyOrInvalidString(formik.errors.DescripcionIncidencia)
              }
              helperText={
                formik.touched.DescripcionIncidencia &&
                formik.errors.DescripcionIncidencia &&
                formik.errors.DescripcionIncidencia
              }
            />
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">¿Fue resuelto el incidente?</Typography>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.esResuelto && formik.values.esResuelto === 1 ? true : false}
                    name="esResuelto"
                    value={1}
                    onChange={handleCheckActionType}
                  />
                }
                label={
                  <Typography variant={'body2'} fontWeight={600}>
                    Si
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!formik.values.esResuelto && formik.values.esResuelto === 0 ? true : false}
                    name="esResuelto"
                    value={0}
                    onChange={handleCheckActionType}
                  />
                }
                label={
                  <Typography variant={'body2'} fontWeight={600}>
                    No
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          {formik.values.esResuelto === 1 && (
            <>
              <Box marginBottom={2}>
                <Typography variant="body2" marginBottom={1}>
                  Hora Solución del incidente, ejemplo: 07:30
                </Typography>
                <TextField
                  label={'Hora Solución (Formato 24hrs)'}
                  name="HoraSolucion"
                  value={formik.values.HoraSolucion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="small"
                  fullWidth
                  InputProps={{
                    size: 'small',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>schedule</Icon>
                      </InputAdornment>
                    ),
                  }}
                  error={formik.touched.HoraSolucion && !isEmptyOrInvalidString(formik.errors.HoraSolucion)}
                  helperText={
                    formik.touched.HoraSolucion && formik.errors.HoraSolucion && formik.errors.HoraSolucion
                  }
                />
              </Box>

              <Box marginBottom={2}>
                <Typography variant="body2" marginBottom={1}>
                  Describa como se resolvió el incidente
                </Typography>
                <TextField
                  label="Describa como se resolvió"
                  name="ComoSeResolvio"
                  value={formik.values.ComoSeResolvio.toUpperCase()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiline
                  rows={3}
                  error={
                    formik.touched.ComoSeResolvio && !isEmptyOrInvalidString(formik.errors.ComoSeResolvio)
                  }
                  helperText={
                    formik.touched.ComoSeResolvio &&
                    formik.errors.ComoSeResolvio &&
                    formik.errors.ComoSeResolvio
                  }
                  sx={{ width: '100%' }}
                />
              </Box>
            </>
          )}

          <WebCam label="Foto evidencia del Incidente" image={image} setImage={setImage} />

          <Button variant="contained" onClick={formik.submitForm} disabled={loading} sx={{ mb: 2 }}>
            {loading ? 'Guardando...' : 'Enviar Incidencia'}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default FormIncidencias;
