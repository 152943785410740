const OficialiaAddInterface = {
	idUsuarioRecibe: "",
	idTipoDocumento: "",
	FolioOficio: "",
	FechaOficio: "",
	FechaTermino: "",
	FechaRecibido: "",
	Asunto: "",
	SolicitaRespuesta: 0,
	idPrioiridad: 1,
	idPersonaEnvia: null,

	idParaQuien: null,

	TieneAdjuntos: 0,
	ComentarioAdjuntos: "",
	FotosAdjuntos: [],
	OficioAdjuntos: [],
};

export { OficialiaAddInterface };
