import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Mui
import { Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Swal from 'sweetalert2';

//Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import UserRoles from '@components/Users/UserRoles';
import BasicListDoble from '@components/Lists/BasicListDoble';
import ListItemCustom from '@components/Users/ListItemCustom';

//Validaciones
import { isTypePhone } from '@utils/validations';

//Services
import SupportServices from '@services/SupportServices';

const UserAssignment = () => {
  const navigate = useNavigate();

  const [isLoadigForm, setIsLoadingForm] = useState(true);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [id, setId] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState('');
  const [loadigMessage, setLoadigMessage] = useState('Cargando...');
  const [error, setError] = useState({ value: false, message: '' });

  // Por si da click en el mismo Card
  const [click, setClick] = useState(true);
  // const clickCard = () => setClick(!click);

  useEffect(() => {
    setError({ value: false, message: '' });
  }, [id]);

  const handleSubmit = async (data) => {
    const FilteredData = data.map((item) => item.id);
    const min = 1;

    if (FilteredData.length >= min) {
      setError({ value: false, message: '' });

      const params = {
        idUsuario: id,
        Perfiles: FilteredData,
      };

      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setLoadigMessage('Subiendo perfiles');

      try {
        const result = await SupportServices.saveAllowedRoles(params);
        const { results, message } = result;
        Swal.fire({
          title: message,
          icon: results ? 'success' : 'warning',
        });
      } catch (error) {
        Swal.fire({
          title: 'Ha ocurrido un error',
          text: error.message,
          icon: 'warning',
        });
      } finally {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setLoadigMessage('Cargando...');
      }
    } else {
      setError({
        value: true,
        message: `Debes seleccionar al menos ${min} perfil${min > 1 ? 'les' : ''}`,
      });
    }
  };

  const handleClick = (e) => {
    setId(e.id);
    setNombreCompleto(e.NombreCompleto);
    setClick(!click);
  };

  const handleClear = () => {
    setId(0);
    setNombreCompleto('');
  };

  return (
    <AdminLayout>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={loadigMessage}
      />
      <Container maxWidth="false">
        <ActionHeader
          title="Asignación de Perfiles"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={4} lg={4}>
            <BasicListDoble
              id="id_nuevo"
              API={SupportServices.getUsersToAssign}
              APIKeys={[
                { id: "CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)", filter: 'LIKE' },
                {
                  id: "CONCAT_WS('', usuarios.Username, usuarios.Celular, usuarios.Telefono, usuarios.TelMensajes)",
                  filter: 'LIKE',
                },
              ]}
              itemComponent={ListItemCustom}
              header={''}
              handleClick={handleClick}
              handleClear={handleClear}
              label={'Busca al usuario y selecciónalo para llevar a cabo la acción deseada.'}
              labelNote=" Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
              finder={true}
              inputValidation={{ input2: isTypePhone }}
              initialSearch={false}
              emptySearch={false}
              doubleSearch={true}
              breaks={{ sm: 12, md: 6 }}
              buttonsBreaks={{ sm: 12, md: 4 }}
              disableCardType={false}
              config={{ height: 400 }}
              clearData={true}
            />
          </Grid2>
          <Grid2 xs={12} md={8} lg={8}>
            <UserRoles
              nombreCompleto={nombreCompleto}
              change={click}
              userID={id}
              handleSubmit={handleSubmit}
              error={error}
              API={SupportServices.getAllowedRoles}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default UserAssignment;
