import { useCallback, useEffect, useState, useMemo } from 'react';
import Swal from 'sweetalert2';

export const useSelection = ({ items = [], Api, infinite }) => {
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  useEffect(() => {
    setSelected([]);
  }, [items]);

  useEffect(() => {
    if (infinite) {
      getListInfiniteScroll(params);
    } else {
      getList();
    }
  }, [params]);

  const handleSelectAll = useCallback(() => {
    setSelected([...items]);
  }, [items]);

  const handleSelectOne = useCallback((item) => {
    setSelected((prevState) => [...prevState, item]);
  }, []);

  const handleDeselectAll = useCallback(() => {
    setSelected([]);
  }, []);

  const handleDeselectOne = useCallback((item) => {
    setSelected((prevState) => prevState.filter((_item) => _item !== item));
  }, []);

  const handleApiError = (error) => {
    Swal.fire({ title: error, icon: 'error' });
    setData([]);
    setTotal(0);
  };

  const getList = () => {
    setIsLoading(true);
    if (typeof Api === 'function') {
      Api(params) // Usar params en lugar de dataSelection.data
        .then((res) => {
          if (res.results) {
            setData(res.response.data ? res.response.data : []);
            setTotal(res.response.total);
          } else {
            handleApiError(res.message);
          }
        })
        .catch((err) => {
          handleApiError(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getListInfiniteScroll = useCallback(
    (data) => {
      setIsLoading(true);
      if (typeof Api === 'function') {
        Api(params)
          .then((res) => {
            if (res.results) {
              if (res.response.data.length > 0) {
                if (params.page === 0) {
                  setData(res.response.data ? res.response.data : []);
                  setTotal(parseInt(res.response.total));
                } else {
                  setData((prevData) => [...prevData, ...res.response.data]);
                  setTotal(parseInt(res.response.total));
                }
              }
              if (params.pageSize >= parseInt(res.response.total)) {
                setHasMore(false);
              }
            } else {
              Swal.fire({ title: res.message, icon: 'warning' });
              setHasMore(false);
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: 'warning' });
            setHasMore(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      // eslint-disable-next-line
    },
    [params]
  );

  const fetchHas = () => {
    let parametro = {
      ...params,
      page: params.page + 1,
    };
    setParams(parametro);
  };

  const handleChangeFilter = (filtered) => {
    setData([]);
    setTotal(0);
    setHasMore(true);
    setParams((prevState) => ({ ...prevState, page: 0, filtered }));
  };

  const handleChangePagination = (pagination) => {
    console.log(pagination);
    setParams({ ...params, ...pagination });
  };

  // Memorizar los resultados de las selecciones para evitar re-renderizados innecesarios
  const memoizedSelected = useMemo(() => selected, [selected]);

  return {
    handleDeselectAll,
    handleDeselectOne,
    handleSelectAll,
    handleSelectOne,
    fetchHas,
    handleChangeFilter,
    handleChangePagination,
    selected: memoizedSelected,
    isLoading,
    data,
    total,
    hasMore,
  };
};
